import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import BillingCalculationByDropDown
    from '../../../components/CustomInput/CustomDropDownInput/BillingCalculationByDropDown';
import { translate } from '../../../translation/translate.utils';
import CustomNumberInput from '../../../components/CustomInput/CustomNumberInput';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import VacancyInvoiceCreationAfterDropDown
    from '../../../components/CustomInput/CustomDropDownInput/VacancyInvoiceCreationAfterDropDown';
import { IBillingSetting } from '../../../definitions/entities.definition';
import { EBillingCalculationBy } from '../../../definitions/billingConfiguration.definitions';

interface IProps {
    billingSetting: IBillingSetting;
    updateBillingSetting: (setting: Partial<IBillingSetting>) => void;
}

const BillingConfigurationTypeCustomizedForm: React.FC<IProps> = (props) => {
    const billingSetting = props.billingSetting;
    const updateBillingSetting = props.updateBillingSetting;

    return (
        <>
            <PaperRow>
                <VacancyInvoiceCreationAfterDropDown
                    value={billingSetting.invoiceCreationAfter}
                    onChange={(event) => updateBillingSetting({invoiceCreationAfter: event.target.value})}
                />
            </PaperRow>
            <PaperRow>
                <BillingCalculationByDropDown
                    value={billingSetting.customBillingCalculationBy}
                    onChange={(event) => updateBillingSetting({customBillingCalculationBy: event.target.value})}
                />
            </PaperRow>
            <PaperRow>
                <CustomNumberInput
                    required
                    value={billingSetting.customBillingCalculationFactorInvoice1}
                    onChange={(value) => updateBillingSetting({customBillingCalculationFactorInvoice1: value ? value : 0})}
                    label={translate('pages.billing.billingCalculationByFirstInvoiceValue.' + billingSetting.customBillingCalculationBy)}
                    max={billingSetting.customBillingCalculationBy === EBillingCalculationBy.CALCULATION_BY_YEAR_SALARY ? 50 : billingSetting.customBillingCalculationBy === EBillingCalculationBy.CALCULATION_BY_MONTH_SALARY ? 12 : undefined}
                />
            </PaperRow>
            <PaperRow>
                <CustomCheckboxInput
                    checked={billingSetting.customBillingSecondInvoice}
                    label={"Abschlussrechnung"}
                    onChange={() => updateBillingSetting({customBillingSecondInvoice: !billingSetting.customBillingSecondInvoice})}
                />
            </PaperRow>
            {billingSetting.customBillingSecondInvoice &&
                <PaperRow>
                    <CustomNumberInput
                        required
                        value={billingSetting.customBillingCalculationFactorInvoice2}
                        onChange={(value) => updateBillingSetting({customBillingCalculationFactorInvoice2: value ? value : 0})}
                        label={translate('pages.billing.billingCalculationByClosingInvoiceValue.' + billingSetting.customBillingCalculationBy)}
                        max={billingSetting.customBillingCalculationBy === EBillingCalculationBy.CALCULATION_BY_YEAR_SALARY ? 50 : billingSetting.customBillingCalculationBy === EBillingCalculationBy.CALCULATION_BY_MONTH_SALARY ? 12 : undefined}
                    />
                </PaperRow>
            }
        </>
    );
}
export default BillingConfigurationTypeCustomizedForm;

