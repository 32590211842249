import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { IEmployee } from '../../../../definitions/entities.definition';
import {
    setUIActivityListViewFilterAction,
    setUIActivityListViewFilterMenuAction,
    setUIActivityListViewResetFilterAction,
} from '../../../../redux/ui/ui.actions';
import TimeRangeDropDown from '../../../CustomInput/CustomDropDownInput/TimeRangeDropDown';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuActivityList: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.ui.activityListViewFilter);
    const filterMenu = useSelector((store: IStore) => store.ui.activityListViewFilterMenu);

    const submit = () => {
        dispatch(
            setUIActivityListViewFilterAction({
                fastSearch: filter.fastSearch,
                employeeId: filterMenu.employee?.id,
                vacancyId: filter.vacancyId,
                timeRange: filter.timeRange
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(setUIActivityListViewResetFilterAction());
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.employee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setUIActivityListViewFilterMenuAction({
                                employee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <TimeRangeDropDown
                    value={filterMenu.timeRange}
                    onChange={(event) =>
                        dispatch(
                            setUIActivityListViewFilterMenuAction({
                                timeRange: event.target.value
                            })
                        )
                    }
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuActivityList;
