import React from 'react';
import { connect } from 'react-redux';

class ProfilePage extends React.PureComponent {
    render() {
        return null;
    }
}

const mapState = () => ({});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(ProfilePage);
