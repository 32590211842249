import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { StyledTopBarBadge } from '../DefaultTopBarRight';
import { isOnlyShowMineModeActive } from '../../../../selectors/employee.selectors';
import { setUiShowOnlyMine } from '../../../../redux/ui/ui.actions';
import { ClientApi } from '../../../../requests/ClientApi';
import { putEmployeeShowMineRouteConfig } from '../../../../requests/routes';
import { IEmployee } from '../../../../definitions/entities.definition';
import { getToken } from '../../../../selectors/app.selectors';
import { actorRefreshTokenAction } from '../../../../redux/actor/actor.actions';

const ShowOnlyMyDataModeMenuItem: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const onlyShowMine = useSelector(isOnlyShowMineModeActive);

    const handleClick = () => {
        ClientApi.request(putEmployeeShowMineRouteConfig, {
            token,
            showOnlyMine: !onlyShowMine
        }).then((result: IEmployee) => {
            dispatch(setUiShowOnlyMine(result.onlyShowMine));
            dispatch(actorRefreshTokenAction(token));
        });
    }

    const tooltip = onlyShowMine ? 'Nur Eigene Daten anzeigen aktiv (klicken zum wechseln)' : 'Alle Daten anzeigen aktiv (klicken zum wechseln)';
    const icon = onlyShowMine ? EIcons.Person : EIcons.People;

    return <>
        <Tooltip title={tooltip} placement="bottom">
            <IconButton onClick={handleClick} style={{bottom: 5}}>
                <StyledTopBarBadge color="primary">
                    {IconFactory.getIconComponent(icon)}
                </StyledTopBarBadge>
            </IconButton>
        </Tooltip>
    </>;
}

export default React.memo(ShowOnlyMyDataModeMenuItem);
