import {
    IGetActivityListAction,
    ISetActivityPageFastSearchAction,
    ISetActivityPageFilterAction,
    ISetActivityPageFilterMenuAction,
    ISetActivityPageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IActivityPageState, preloadedStateActivityPage } from '../../definitions/activityPage.definitions';
import {
    ACTIVITY_PAGE_RESET_FILTER,
    ACTIVITY_PAGE_SET_FAST_SEARCH,
    ACTIVITY_PAGE_SET_FILTER,
    ACTIVITY_PAGE_SET_FILTER_MENU,
    ACTIVITY_PAGE_SET_SORTING_OPTION,
} from './activityOverviewPage.actions';
import { ENTITIES_GET_ACTIVITY_LIST_FULFILLED } from '../entities/entities.actions';
import { EEntities } from '../../definitions/entities.definition';

const activityOverviewPageReducer = (state = preloadedStateActivityPage, action: TReducerAction): IActivityPageState => {
    switch (action.type) {
        case ACTIVITY_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetActivityPageSortingOptionAction;
            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case ACTIVITY_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetActivityPageFastSearchAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case ACTIVITY_PAGE_RESET_FILTER: {
            return {
                ...state,
                filter: preloadedStateActivityPage.filter,
                filterMenu: preloadedStateActivityPage.filterMenu
            }
        }
        case ACTIVITY_PAGE_SET_FILTER: {
            const actionCast = action as ISetActivityPageFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload,
                },
            };
        }

        case ENTITIES_GET_ACTIVITY_LIST_FULFILLED: {
            const actionCast = action as IGetActivityListAction;

            return {
                ...state,
                initialActivitiesLoaded: (actionCast.meta.activityEntity === EEntities.Activity),
            };
        }
        case ACTIVITY_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetActivityPageFilterMenuAction;

            return {
                ...state,
                filterMenu: { ...state.filterMenu, ...actionCast.payload },
            };
        }
        default:
            break;
    }

    return state;
};

export default activityOverviewPageReducer;
