import { useDispatch, useSelector } from 'react-redux';
import React, { ReactNode } from 'react';
import {
    EEntities,
    ICandidateListModel,
    ICountry,
    IEmployee,
    ISkill,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setCandidatePageCreateBirthdayMailAction } from '../../../../redux/candidatePage/candidatePage.actions';
import { getCandidatesWithBirthdayFromStore } from '../../../../selectors/candidate.selectors';
import { translate } from '../../../../translation/translate.utils';
import { Tooltip } from '@material-ui/core';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getCandidatesWithBirthdayAction, updateCandidateAction } from '../../../../redux/entities/entities.actions';
import { renderCandidateColored } from '../../../../utils/candidateTable.utils';
import { Rating } from '@material-ui/lab';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { getToken } from '../../../../selectors/app.selectors';
import { ECellAlign, ESortingOptions } from '../../../../definitions/components.definitions';
import { setDashboardPageSortingOptionAction } from '../../../../redux/dashboardPage/dashboardPage.actions';
import { EDashboardPageTable } from '../../../../definitions/dashboardPage.definitions';
import { buildRequestObject } from '../../../../selectors/entity.selectors';
import { COLOR_BLUE } from '../../../../theme/theme';
import { getDefaultLastActivityColumn } from '../../../../utils/components.utils';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';

const BirthdayCandidatesTable: React.FC = () => {
    const dispatch = useDispatch();
    const sortedEntries = useSelector(getCandidatesWithBirthdayFromStore);
    const loading = useSelector((store: IStore) => store.ui.candidatesBirthdayLoading);
    const token = useSelector(getToken);
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingCandidateBirthdays);

    return (
        <CustomTable
            style={{margin: 5}}
            config={{
                entity: EEntities.CandidateBirthday,
                sortedEntries: sortedEntries,
                count: sortedEntries.length,
                total: sortedEntries.length,
                onReload: () => {
                    dispatch(getCandidatesWithBirthdayAction(token, buildRequestObject(sorting)));
                },
                columnConfig: {
                    sendBirthdayWishes: {
                        header: 'Vormerken',
                        property: 'sendBirthdayWishes',
                        align: ECellAlign.center,
                        disableOrder: true,
                        width: 90,
                        cellRenderer: (sendBirthdayWishes: boolean, candidate: TBrainzEntity) => {
                            const candidateCast = candidate as ICandidateListModel;

                            if (candidateCast.birthdayMailSend) {
                                return <Tooltip title={"E-Mail bereits versendet"}>
                                    {IconFactory.getIconComponent(EIcons.Done, {color: COLOR_BLUE})}
                                </Tooltip>;
                            }

                            return (<Rating
                                size={'large'}
                                name={"sendBirthdayWishes" + candidate.id}
                                value={sendBirthdayWishes ? 1 : 0}
                                max={1}
                                emptyIcon={IconFactory.getIconComponent(EIcons.CheckboxOutlined)}
                                icon={IconFactory.getIconComponent(EIcons.Checkbox)}
                                onChange={() => {
                                    dispatch(updateCandidateAction(token, {
                                        id: candidateCast.id,
                                        sendBirthdayWishes: !sendBirthdayWishes,
                                    }));
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            />);
                        },
                    },
                    top: {
                        header: 'TOP',
                        property: "topCandidate",
                        align: ECellAlign.center,
                        width: 40,
                        disableOrder: true,
                        cellRenderer: (topCandidate: boolean, candidate: TBrainzEntity) => {
                            const candidateCast = candidate as ICandidateListModel;

                            return (<Rating
                                size={"large"}
                                name={"topCandidate" + candidate.id}
                                value={topCandidate ? 1 : 0}
                                max={1}
                                onChange={() => {
                                    dispatch(updateCandidateAction(token, {
                                        id: candidateCast.id,
                                        topCandidate: !candidateCast.topCandidate
                                    }, true));
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            />);
                        },
                    },
                    country: {
                        header: IconFactory.getIconComponent(EIcons.Public),
                        property: "country",
                        disableOrder: true,
                        align: ECellAlign.right,
                        width: 30,
                        cellRenderer: (value: ICountry) => {
                            if (value === null) {
                                return (
                                    <Tooltip title={"Nicht DE-Kandidat (Kein Land gesetzt)"}>
                                        {IconFactory.getIconComponent(EIcons.Public)}
                                    </Tooltip>
                                );
                            }

                            if (value.code !== 'DE') {
                                return (
                                    <Tooltip title={"Nicht DE-Kandidat (" + value.name + ")"}>
                                        {IconFactory.getIconComponent(EIcons.Public)}
                                    </Tooltip>
                                );
                            }
                            return null;
                        }
                    },
                    salutation: {
                        header: translate('pages.candidate.properties.salutation'),
                        property: 'salutation',
                        width: 70,
                        cellRenderer: (salutation, entity: TBrainzEntity) => {
                            const candidate = entity as ICandidateListModel;

                            if (candidate.hasOpenRecruiting) {
                                return <div style={{color: 'var(--brainz-color-green)'}}>{translate('misc.' + salutation)}</div>;
                            }

                            if (candidate.hasOpenSuggestion) {
                                return <div style={{color: 'var(--brainz-color-yellow)'}}>{translate('misc.' + salutation)}</div>;
                            }

                            return translate('misc.' + salutation);
                        },
                    },
                    name: {
                        header: translate('pages.candidate.properties.name'),
                        property: 'name',
                        cellRenderer: (value, entity: TBrainzEntity) => {
                            const candidate = entity as ICandidateListModel;

                            return renderCandidateColored(candidate, value);
                        },
                    },
                    firstName: {
                        header: translate('pages.candidate.properties.firstName'),
                        property: 'firstName',
                        cellRenderer: (value, entity: TBrainzEntity) => {
                            const candidate = entity as ICandidateListModel;

                            return renderCandidateColored(candidate, value);
                        },
                    },
                    age: {
                        header: translate('pages.candidate.properties.age'),
                        property: 'age',
                        width: 70,
                        cellRenderer: (age: number) => {
                            if (age === 0) {
                                return <div>-</div>;
                            }
                            return age;
                        },
                    },
                    skills: {
                        header: translate('pages.candidate.properties.skills'),
                        property: "skills",
                        width: 55,
                        cellRenderer: (skills?: ISkill[]) => {
                            if (skills && skills.length) {
                                const skillStrings = skills.map((skill: ISkill) => {
                                    return skill.name + ", ";
                                });

                                return (
                                    <Tooltip title={skillStrings} placement="bottom">
                                        <div>{skills.length}</div>
                                    </Tooltip>
                                );
                            }

                            return <div>-</div>;
                        },
                    },
                    birthDate: {
                        header: translate('pages.candidate.properties.birthDate'),
                        property: 'birthDate',
                        cellRenderer: (birthdate: string) => {
                            return <DateCellRenderer date={birthdate}/>;
                        },
                    },
                    zipCode: {
                        header: translate('misc.zipCode'),
                        property: 'zipCode',
                        width: 90,
                        cellRenderer: (zipCode: string, candidate: TBrainzEntity): ReactNode => {
                            const candidateCast = candidate as ICandidateListModel;

                            return (
                                <Tooltip title={candidateCast.city} placement="bottom">
                                    <div>{zipCode}</div>
                                </Tooltip>
                            );
                        }
                    },
                    currentJobDescription: {
                        header: translate('pages.candidate.properties.currentJobDescription'),
                        property: 'currentJobDescription',
                        flex: 2
                    },
                    lastActivity: getDefaultLastActivityColumn(),
                    responsible: {
                        header: translate('misc.employeeShort'),
                        property: 'responsible',
                        width: 60,
                        align: ECellAlign.center,
                        cellRenderer: (employee: IEmployee) => {
                            if (!employee) {
                                return translate('misc.noInformation');
                            }
                            return <EmployeeCellRenderer employee={employee}/>;
                        },
                    },
                },
                loading: loading,
                listActionButtons: [{
                    action: (id) => dispatch(setCandidatePageCreateBirthdayMailAction(id)),
                    translationKey: "pages.candidate.createBirthdayMail",
                    icon: EIcons.EMail,
                }],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                        dispatch(setDashboardPageSortingOptionAction(
                            EDashboardPageTable.candidateBirthdays,
                            propertyToSort,
                            sortOption
                        ));
                        return;
                    }
                },
                onRowDoubleClick: (candidateId: number, entity) => {
                    const candidate = entity as ICandidateListModel;
                    dispatch(setUiEditView(EEntityView.candidate, candidate.id));
                }
            }}
        />
    );
};

export default BirthdayCandidatesTable;

