import { EActivityAction, EActivityType, IActivity } from '../definitions/entities.definition';
import { translate } from '../translation/translate.utils';

export const isActivityDeleteAble = (activity: IActivity): boolean => {
    return activity.type !== EActivityType.systemInfo && activity.type !== EActivityType.systemMail;
}

export const getActivityActionDescription = (activity: IActivity): string => {
    if (activity.type !== EActivityType.systemInfo) {
        return '';
    }
    switch (activity.action) {
        case EActivityAction.editWebVacancy: {
            const dataStrings: string[] = [];
            if (activity.data['online'] && activity.data.online.after === true) {
                dataStrings.push(translate('activityString.edit_web_vacancy_online'));
            }
            if (activity.data['online'] && activity.data.online.after === false) {
                dataStrings.push(translate('activityString.edit_web_vacancy_offline'));
            }
            if (activity.data['title']) {
                dataStrings.push(translate('activityString.edit_web_vacancy_title', [
                    activity.data.title.before,
                    activity.data.title.after,
                ]));
            }
            if (activity.data['url']) {
                dataStrings.push(translate('activityString.edit_web_vacancy_url', [
                    activity.data.url.before,
                    activity.data.url.after,
                ]));
            }

            return dataStrings.join('\n\n');
        }
        case EActivityAction.editRecruiting: {
            const dataStrings: string[] = [];
            if (activity.data['employee']) {
                dataStrings.push(translate('activityString.edit_recruiting_employee', [
                    activity.data.employee.before.fullName,
                    activity.data.employee.after.fullName,
                ]));
            }
            if (activity.data['vacancy']) {
                dataStrings.push(translate('activityString.edit_recruiting_vacancy', [
                    activity.data.vacancy.before.title,
                    activity.data.vacancy.after.title,
                ]));
            }
            if (activity.data['status']) {
                dataStrings.push(translate('activityString.edit_recruiting_status', [
                    translate('pages.recruiting.statusValues.' + activity.data.status.before),
                    translate('pages.recruiting.statusValues.' + activity.data.status.after),
                ]));
            }

            return dataStrings.join('\n\n');
        }
        case EActivityAction.editVacancy: {
            let string = '';
            if (activity.data['responsibleEmployeeFullName']) {
                string += translate('activityString.edit_vacancy_responsible_employee', [
                    activity.data.responsibleEmployeeFullName.before,
                    activity.data.responsibleEmployeeFullName.after,
                ]);
            }
            if (activity.data['completed'] && activity.data.completed.after === true) {
                string += translate('activityString.edit_vacancy_completed_true');
            }
            if (activity.data['completed'] && activity.data.completed.after === false) {
                string += translate('activityString.edit_vacancy_completed_false');
            }

            return string;
        }
        case EActivityAction.editSuggestion:
            if (activity.data['candidate']) {
                return translate('activityString.edit_suggestion_set_candidate');
            }

            if (activity.data['responsible']) {
                return translate('activityString.edit_suggestion_change_responsible', [
                    activity.data.responsible.before.fullName,
                    activity.data.responsible.after.fullName,
                ]);
            }

            return 'Aktueller Stand wurde bearbeitet';
        case EActivityAction.addCandidate: {
            let string = translate('activityString.add_candidate', [
                activity.employee.firstName + ' ' + activity.employee.name,
            ]);
            if (activity.data && activity.data['applyForVacancy']) {
                string += ' (Beworben für: ' + activity.data.applyForVacancy.title;
            }
            return string;
        }
        case EActivityAction.acceptSuggestion:
        case EActivityAction.declineSuggestion: {
            let candidateName = '';
            if (activity.recruitingSuggestion) {
                candidateName = activity.recruitingSuggestion.fullName;
            }

            return translate('activityString.' + activity.action, [
                candidateName,
                activity.employee.firstName + ' ' + activity.employee.name,
            ]);
        }
        case EActivityAction.addTodo: {
            return translate('activityString.' + activity.action);
        }
        case EActivityAction.addSuggestion: {
            return translate('activityString.' + activity.action, [
                activity.employee.firstName + ' ' + activity.employee.name,
                translate('pages.vacancy.externalCandidateSourceValues.' + activity.recruitingSuggestion?.source)
            ]);
        }
        default:
            return translate('activityString.' + activity.action, [
                activity.employee.firstName + ' ' + activity.employee.name,
            ]);
    }
}
