import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getApplicationsForVacancyFromStore,
    getVacancyPageRequestObjectForApplicationsInEditView,
} from '../../../../../selectors/application.selectors';
import { useEffect } from 'react';
import { getToken } from '../../../../../selectors/app.selectors';
import { getApplicationListForVacancyAction } from '../../../../../redux/entities/entities.actions';

export const useVacancyApplicationListHook = (vacancyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.applicationsLoading);
    const reload = useSelector((store: IStore) => store.ui.reloadApplications);

    const requestObject = useSelector(getVacancyPageRequestObjectForApplicationsInEditView);
    const applications = useSelector((store: IStore) => getApplicationsForVacancyFromStore(store, vacancyId));

    useEffect(() => {
        dispatch(getApplicationListForVacancyAction(token, vacancyId, requestObject));
    }, [vacancyId]);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getApplicationListForVacancyAction(token, vacancyId, requestObject));
        }
    }, [reload]);

    return {loading, applications, total: applications.length};
}
