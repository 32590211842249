import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { resetFormValidation } from '../../../../redux/error/error.actions';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import {
    setJobTemplatePageAddDialogOpenAction,
    setJobTemplatePageTemplateToEditAction,
} from '../../../../redux/jobTemplate/jobTemplatePage.actions';
import JobTemplateForm from './JobTemplateForm';
import { getJobTemplateAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';

const JobTemplateAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const addJobTemplate = useSelector((store: IStore) => store.jobTemplatePage.addDialogOpen);
    const editJobTemplate = useSelector((store: IStore) => Boolean(store.jobTemplatePage.templateToEdit));
    const editJobTemplateId = useSelector((store: IStore) => store.jobTemplatePage.templateToEdit);
    const initialJobTemplate = useSelector((store:IStore) => store.entities.jobTemplate.currentRecord);
    const reloadSkills = useSelector((store: IStore) => store.skillPage.reload);

    useEffect(() => {
        if (editJobTemplate && (!initialJobTemplate || reloadSkills)) {
            dispatch(getJobTemplateAction(
                token,
                editJobTemplateId
            ))
        }
    }, [editJobTemplate, initialJobTemplate, editJobTemplateId, reloadSkills]);

    const onClose = () => {
        dispatch(setJobTemplatePageAddDialogOpenAction(false));
        dispatch(setJobTemplatePageTemplateToEditAction(0));
        dispatch(resetFormValidation());
    }

    const open = addJobTemplate || editJobTemplate;

    return (
        <CustomDialog fullWidth onClose={onClose} open={open}>
            <CustomDialogHeader translationString={addJobTemplate ? "pages.jobTemplate.add" : "pages.jobTemplate.edit"} onClose={onClose} />
            <div style={{ height: "100%", width: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
                <JobTemplateForm
                    onClose={onClose}
                    initialJobTemplate={initialJobTemplate}
                />
            </div>
        </CustomDialog>
    );
}
export default JobTemplateAddEditView;

