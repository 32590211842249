import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import { IStore } from '../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getExternalSkillAction } from '../../redux/entities/entities.actions';
import { IExternalSkill } from '../../definitions/entities.definition';

export const useLoadExternalSkill = (): undefined|IExternalSkill => {
    const token = useSelector(getToken);
    const skillId = useSelector((store: IStore) => store.skillPage.externalSkillToEdit);
    const skill = useSelector((store: IStore) => store.entities.skills.currentExternalRecord);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!skill && skillId > 0) {
            dispatch(getExternalSkillAction(token, skillId));
        }
    }, [skillId]);

    return skill;
}
