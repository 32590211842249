import { deleteSuggestionAction } from '../../redux/entities/entities.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import { setVacancyPageSuggestionToDeleteAction } from '../../redux/vacancyPage/vacancyPage.actions';
import React from 'react';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { setUiCloseView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

const SuggestionDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.suggestionToDelete));
    const idToDelete = useSelector((store: IStore) => store.vacancyPage.suggestionToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setVacancyPageSuggestionToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteSuggestionAction(token, idToDelete));
                dispatch(setUiCloseView(EEntityView.suggestion, idToDelete));
                dispatch(setVacancyPageSuggestionToDeleteAction(0));
            }}
            titleTranslation={"pages.vacancy.deleteSuggestionDialog.title"}
            bodyTranslation={"pages.vacancy.deleteSuggestionDialog.body"}
        />
    );
};

export default SuggestionDeleteView;
