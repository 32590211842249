import { ESortingOptions } from '../../definitions/components.definitions';
import { IActivityFilter, IActivityFilterMenu } from '../../definitions/activityPage.definitions';
import {
    ISetActivityPageFastSearchAction,
    ISetActivityPageFilterAction,
    ISetActivityPageFilterMenuAction,
    ISetActivityPageSortingOptionAction,
} from '../../definitions/redux/actions.definition';

export const ACTIVITY_PAGE_SET_FILTER = "ACTIVITY_PAGE_SET_FILTER";
export const ACTIVITY_PAGE_RESET_FILTER = "ACTIVITY_PAGE_RESET_FILTER";
export const ACTIVITY_PAGE_SET_SORTING_OPTION = "ACTIVITY_PAGE_SET_SORTING_OPTION";
export const ACTIVITY_PAGE_SET_FAST_SEARCH = "ACTIVITY_PAGE_SET_FAST_SEARCH";
export const ACTIVITY_PAGE_SET_FILTER_MENU = "ACTIVITY_PAGE_SET_FILTER_MENU";

export const setActivityPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetActivityPageSortingOptionAction => ({
    type: ACTIVITY_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setActivityPageFilterAction = (filter: Partial<IActivityFilter>): ISetActivityPageFilterAction => ({
    type: ACTIVITY_PAGE_SET_FILTER,
    payload: filter,
});

export const resetActivityPageFilterAction = () => ({
    type: ACTIVITY_PAGE_RESET_FILTER,
});

export const setActivityPageFastSearchAction = (fastSearchValue: string): ISetActivityPageFastSearchAction => ({
    type: ACTIVITY_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setActivityPageFilterMenuAction = (filterMenu: Partial<IActivityFilterMenu>): ISetActivityPageFilterMenuAction => ({
    type: ACTIVITY_PAGE_SET_FILTER_MENU,
    payload: filterMenu,
});
