import React from 'react';
import ActivityOverviewChatPanel from './components/ActivityOverviewChatPanel';

const ActivityOverviewPage: React.FC = () => {
    return <>
        <div className={"flexContainerColumn flex1"} style={{overflow: 'hidden'}}>
            <ActivityOverviewChatPanel />
        </div>
    </>;
};

export default ActivityOverviewPage;
