import React, { useEffect } from 'react';
import ActivityDisplayMailboxMail from '../../../Shared/Activity/ActivityDisplayMailboxMail';
import { COLOR_TEXT, darkest } from '../../../../theme/theme';

interface IProps {
    mailId?: number;
    onClose: () => void;
    reload: (mailId: number) => void;
}

const OpenMailView: React.FC<IProps> = (props) => {
    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if (!props.mailId) {
                return;
            }
            const key = e.key;
            const noDialogOpen = document.getElementsByClassName('MuiDialog-root').length === 0;
            if (e.target && e.target.id === 'editableValue') {
                return;
            }
            if (key === 'Escape' && noDialogOpen) {
                props.onClose();
            }
        };

        if (props.mailId) {
            document.addEventListener('keydown', handleKeyDown, true);
            return;
        }

        return (() => {
            document.removeEventListener('keydown', handleKeyDown);
        });
    }, [props.mailId]);

    if (!props.mailId) {
        return null;
    }

    const onClose = () => {
        props.onClose();
    };

    return (
        <div style={{
            marginTop: 5,
            marginBottom: -15,
            borderLeft: '1px solid rgb(0 0 0 / 40%)',
            borderTop: '1px solid rgb(0 0 0 / 40%)',
            borderRadius: 6,
            width: 800,
            right: 0,
            top: 0,
            height: '100%',
            position: 'absolute',
            zIndex: 1202,
            background: darkest,
            boxShadow: '0 2px 7px 0 rgb(0 0 0 / 40%)',
            color: COLOR_TEXT,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <ActivityDisplayMailboxMail
                mailId={props.mailId}
                onClose={onClose}
                reload={props.reload}
            />
        </div>
    );
}
export default OpenMailView;

