import React, { useEffect } from 'react';
import {
    EEntities,
    IActivity,
    IEmployee,
    TActivityType,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../translation/translate.utils';
import { IStore } from '../../../../definitions/redux/store.definitions';
import ActivityTypeCellRenderer from '../../../../components/CustomCellRenderer/ActivityTypeCellRenderer';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import ActivityDescriptionCellRenderer from '../../../../components/CustomCellRenderer/ActivityDescriptionCellRenderer';
import {
    getActivitiesForTodoFromStore,
    getTodoActivityListRequestObject,
} from '../../../../selectors/activity.selectors';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { setUiActivityToDeleteAction, setUiActivityToEditAction } from '../../../../redux/ui/ui.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { getActivityListForTodoAction } from '../../../../redux/entities/entities.actions';
import { themeBlue } from '../../../../theme/theme';
import { setTodoActivitySortingOptionAction } from '../../../../redux/todo/todo.actions';
import { isActivityDeleteAble } from '../../../../utils/activity.utils';

interface IProps {
    todoId: number;
}

const TodoActivityList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const activities = useSelector(getActivitiesForTodoFromStore);
    const loading = useSelector((state: IStore) => state.ui.activitiesLoading);
    const activityRequestObject = useSelector(getTodoActivityListRequestObject);
    const sorting = useSelector((state: IStore) => state.todo.activitySorting);
    const currentTodoId = useSelector((state: IStore) => state.todo.todoToEdit);
    const reload = useSelector((state: IStore) => state.ui.reloadActivityGrids);
    const total = useSelector((store: IStore) => store.entities.activities.totalByTodo[currentTodoId] || 0);

    useEffect(() => {
        dispatch(getActivityListForTodoAction(
            token,
            props.todoId,
            activityRequestObject
        ));
    }, [dispatch, sorting]);

    useEffect(() => {
        if (!reload) {
            return;
        }

        dispatch(getActivityListForTodoAction(
            token,
            props.todoId,
            activityRequestObject
        ));
    }, [reload]);

    return (
        <CustomTable
            config={{
                color: themeBlue.palette.primary.main,
                entity: EEntities.TodoActivity,
                loading,
                columnConfig: {
                    type: {
                        header: translate('pages.activity.properties.type'),
                        property: "type",
                        width: 60,
                        cellRenderer: (type: TActivityType) => <ActivityTypeCellRenderer type={type} />,
                    },
                    dateTime: {
                        header: translate('pages.activity.properties.dateTime'),
                        property: "dateTime",
                        width: 170,
                        cellRenderer: (dateTime: string) => <DateTimeCellRenderer date={dateTime} />,
                    },
                    userInput: {
                        header: translate('pages.activity.properties.description'),
                        property: "userInput",
                        flex: 2,
                        cellRenderer: (value: string, activity: TBrainzEntity) => {
                            const activityCast = activity as IActivity;
                            return <ActivityDescriptionCellRenderer activity={activityCast} />;
                        },
                    },
                    employee: {
                        header: translate('misc.employeeShort'),
                        property: "employee",
                        width: 60,
                        cellRenderer: (employee: IEmployee) => {
                            return <EmployeeCellRenderer employee={employee} />;
                        },
                    },
                },
                sortedEntries: activities,
                onRowDoubleClick: (activityId: number) => dispatch(setUiActivityToEditAction(activityId)),
                listActionButtons: [{
                    action: (activityId: number) => dispatch(setUiActivityToDeleteAction(activityId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                    condition: (activity: TBrainzEntity) => {
                        const activityCast = activity as IActivity;
                        return isActivityDeleteAble(activityCast);
                    }
                }],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setTodoActivitySortingOptionAction(propertyToSort, sortOption)),
                },
                count: activities.length,
                total,
                onReload: () => {
                    dispatch(getActivityListForTodoAction(
                        token,
                        currentTodoId,
                        activityRequestObject
                    ));
                }
            }}
        />
    );
};

export default TodoActivityList;
