import { useEffect } from 'react';
import { getSuggestionsForCandidateAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getSuggestionsForCandidateFromStore } from '../../../../../selectors/recruitingSuggestion.selectors';

export const useCandidateSuggestionListHook = (candidateId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.candidateSuggestionsLoading);
    const reload = useSelector((store: IStore) => store.vacancyPage.reloadSuggestions);
    const suggestions = useSelector((store: IStore) => getSuggestionsForCandidateFromStore(store, candidateId));

    useEffect(() => {
        if (!loading) {
            dispatch(getSuggestionsForCandidateAction(token, candidateId));
        }
    }, []);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getSuggestionsForCandidateAction(token, candidateId));
        }
    }, [reload]);

    return {loading, suggestions, total: suggestions.length};
}
