import clsx from 'clsx';
import { Drawer, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import { closeUiRightDrawerAction } from '../../../redux/ui/ui.actions';
import { getMailAction, getMailListAction } from '../../../redux/entities/entities.actions';
import { ERightDrawerViews } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import OpenMailView from './Mailing/OpenMailView';
import { IMailboxFolderTreeNode } from '../../../definitions/entities.definition';
import MailHeaderWithFilter from './Mailing/MailHeaderWithFilter';
import FolderTreeView from './Mailing/FolderTreeView';
import { ClientApi } from '../../../requests/ClientApi';
import { getMailboxTreeRouteConfig } from '../../../requests/routes';
import MailList from './Mailing/MailList';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useDrawerStyles } from './Drawer.styles';

const RightDrawerMailView = () => {
    const classes = useDrawerStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) => Boolean(store.ui.rightDrawerView === ERightDrawerViews.viewMails));
    const token = useSelector((store: IStore) => store.actor.token);
    const loading = useSelector((state: IStore) => state.ui.mailsLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const [folders, setFolders] = useState<IMailboxFolderTreeNode[]>([]);
    const [reload, setReload] = useState(false);
    const [reloadFolders, setReloadFolders] = useState(false);
    const forceReload = useSelector((store: IStore) => store.mailing.reload);

    const loadMails = () => {
        dispatch(getMailListAction(token, filter));
    }

    const loadFolders = () => {
        ClientApi.request(getMailboxTreeRouteConfig, {
            token,
            employeeId: filter.employeeId
        }).then((result: IMailboxFolderTreeNode[]) => {
            setFolders(result);
        });
    }

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails();
            loadFolders();
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (forceReload && !loading) {
            loadMails();
        }
    }, [forceReload]);

    useEffect(() => {
        if (drawerOpen && !loading) {
            loadMails();
        }
        if (drawerOpen && !loading && filter.employeeId) {
            loadFolders();
        }
    }, [JSON.stringify(filter), reload])

    useEffect(() => {
        if (drawerOpen && !loading && reloadFolders) {
            loadFolders();
        }
    }, [reloadFolders])

    const [openMailId, setOpenMailId] = useState(0);

    const renderContent = () => {
        return <>
            <OpenMailView
                mailId={openMailId}
                onClose={() => setOpenMailId(0)}
                reload={(mailId: undefined|number) => {
                    setReload(true)

                    if (mailId) {
                        dispatch(getMailAction(token, mailId));
                        setOpenMailId(openMailId);
                        return;
                    }
                    setOpenMailId(0);
                }}
            />
            <div style={{display:"flex", height: "100%",width: "100%",overflow: "auto"}}>
                <DndProvider backend={HTML5Backend}>
                    <div style={{display: "flex", height: '100%', borderRight: '1px solid #444', width: 170}}>
                        {folders.length > 1 &&
                        <FolderTreeView
                            treeNodes={folders}
                            defaultSelected={folders[0].id.toString()}
                        />
                        }
                    </div>
                    <div className={"flexColumn"} style={{height: '100%'}}>
                        <MailList
                            openMail={setOpenMailId}
                            reloadFolders={() => setReloadFolders(true)}
                        />
                    </div>
                </DndProvider>
            </div>
        </>
    }

    return (
        <>
            <Drawer
                style={{marginRight: 100}}
                anchor={"right"}
                open={drawerOpen}
                variant={'persistent'}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.boxShadow]: drawerOpen,
                        [classes.widthMails]: !openMailId,
                        [classes.widthMailOpen]: openMailId
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <MailHeaderWithFilter />
                    <IconButton
                        onClick={() => {
                            if (drawerOpen) {
                                dispatch(closeUiRightDrawerAction());
                                return;
                            }
                        }}
                    >
                        {IconFactory.getIconComponent(EIcons.ChevronRight)}
                    </IconButton>
                </div>
                {drawerOpen &&
                    <>{renderContent()}</>
                }
            </Drawer>
        </>
    )
}

export default RightDrawerMailView;
