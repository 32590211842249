import React, { useEffect } from 'react';
import SearchCandidateList from './component/SearchCandidateList';
import SearchCandidateFilterPanel from './component/SearchCandidateFilterPanel';
import { EEntities } from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { getCandidateListOfVacancySearchAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { getVacancyCandidateSearchListRequestObject } from '../../../selectors/candidate.selectors';
import CustomSideFilterPanel from '../../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    setVacancyPageCandidateSearchFilterAction,
    setVacancyPageCandidateSearchFilterMenuAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { setUiSearchCandidateView } from '../../../redux/ui/ui.actions';

const SearchCandidateByVacancyView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => Boolean(store.ui.searchForVacancyId > 0 || store.ui.searchForWebVacancyId > 0));
    const vacancyId = useSelector((store: IStore) => store.ui.searchForVacancyId);
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.listModelById[vacancyId]);
    const requestObject = useSelector(getVacancyCandidateSearchListRequestObject);

    const onClose = () => {
        dispatch(setUiSearchCandidateView(0));
    }

    useEffect(() => {
        if (!vacancy) {
            return;
        }

        dispatch(setVacancyPageCandidateSearchFilterAction({
            distanceSearchCompanyLocationId: vacancy.companyLocation.id,
            distanceSearchKm: 30,
            searchForVacancyId: vacancyId,
        }));

        dispatch(setVacancyPageCandidateSearchFilterMenuAction({
            distanceSearchCompanyLocation: vacancy.companyLocation,
            distanceSearchKm: 30,
        }))
    }, [vacancyId, JSON.stringify(vacancy)]);

    useEffect(() => {
        if (!open || !vacancy) {
            return;
        }

        dispatch(getCandidateListOfVacancySearchAction(
            token,
            requestObject
        ));
    }, [open, JSON.stringify(requestObject)]);

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog fullScreen fullWidth open={open} onClose={onClose}>
            <CustomDialogHeader string={<>Interner Suchlauf für Vakanz <br/><div className={"formContent"}> {vacancy.title}</div></>} onClose={onClose}>
                <SearchCandidateFilterPanel vacancy={vacancy}/>
            </CustomDialogHeader>
            <div className={"DialogContent"}>
                <CustomSideFilterPanel entity={EEntities.VacancyCandidateSearch}>
                    <SearchCandidateList vacancyId={vacancy.id}/>
                </CustomSideFilterPanel>
            </div>
        </CustomDialog>
    </>;
};

export default SearchCandidateByVacancyView;
