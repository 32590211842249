import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EHomeOfficePossibility } from '../../../definitions/entities.definition';

interface IProps {
    onChange: (value?: EHomeOfficePossibility|null) => void;
    value?: EHomeOfficePossibility|null;
    flex?: number;
}

const HomeOfficePossibilityDropDown: React.FC<IProps> = (props) => {
    const homeOfficeDesireValues: EHomeOfficePossibility[] = [
        EHomeOfficePossibility.no,
        EHomeOfficePossibility.partially,
        EHomeOfficePossibility.complete
    ];

    let menuItems = homeOfficeDesireValues.map((homeOfficeDesire: EHomeOfficePossibility, index: number) => (
        <MenuItem key={index + 1} value={homeOfficeDesire}>
            {translate('misc.homeOfficePossibilityValue.' + homeOfficeDesire)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={-1} value={-1}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            flex={props.flex}
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(undefined);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value !== undefined && props.value !== null ? props.value : -1}
            id={"home-office-dropdown"}
            label={translate('misc.homeOfficePossibility')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default HomeOfficePossibilityDropDown;
