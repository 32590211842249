import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getHeaderBarTitle } from '../../../utils/headerBar.utils';
import { IStore } from '../../../definitions/redux/store.definitions';

const DefaultTopBarLeft: React.FC = () => {
    const currentRoute = useSelector((store: IStore) => store.ui.currentRoute);
    const recruitingTab = useSelector((store: IStore) => store.recruitingPage.activeTab);

    return <>
        <Typography style={{ color: 'var(--brainz-color-app-bar-heading)' }} variant="h6" noWrap>
            {getHeaderBarTitle(recruitingTab, currentRoute)}
        </Typography>
    </>;
}

export default React.memo(DefaultTopBarLeft)
