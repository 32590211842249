import React, { useCallback } from 'react';
import { createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import DefaultTopBarCenter from './DefaultTopBarCenter';
import DefaultTopBarLeft from './DefaultTopBarLeft';
import DefaultTopBarRight from './DefaultTopBarRight';
import { checkEditViewIsOpen } from '../../../selectors/ui.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';

export const useAppBarStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.paper
        },
        toolbar: {
            paddingRight: '10px !important'
        },
        root: {
            justifyContent: "space-between",
            flex: 1
        },
    })
);

const TopBar: React.FC = () => {
    const styles = useAppBarStyles();
    const isEditViewOpen = useSelector(checkEditViewIsOpen);
    const route = useSelector((store: IStore) => store.ui.currentRoute);

    const getCenterTopBar = useCallback(() => {
        return <DefaultTopBarCenter/>;
    }, [isEditViewOpen, route])

    return <>
        <AppBar id={"HeaderBar"} position="fixed" className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <div className={styles.root + " flexContainerRow"}>
                    <div className={"flexContainerRow flexAutoGrow alignVerticalCenter"} style={{width:200}}>
                        <DefaultTopBarLeft/>
                    </div>
                    {getCenterTopBar()}
                    <div className={'flexAutoGrow flexContainerRow alignVerticalCenter alignHorizontalRight'}>
                        <DefaultTopBarRight/>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    </>;

};

export default React.memo(TopBar);
