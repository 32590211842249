import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EInterviewType } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: null|EInterviewType;
    error?: boolean;
    label?: string;
    helperText?: string;
}

const InterviewTypeDropDown: React.FC<IProps> = (props) => {
    const types = [
        EInterviewType.phone,
        EInterviewType.personal,
        EInterviewType.online,
        EInterviewType.trial,
        EInterviewType.coding,
        EInterviewType.contract
    ];
    let menuItems = types.map((type: EInterviewType, index: number) => (
        <MenuItem key={index + 1} value={type}>
            {translate('pages.recruiting.interviewTypeValue.' + type)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value}
            id={"interview-type-dropdown"}
            error={props.error}
            helperText={props.helperText}
            label={props.label || 'Art des Interviews'}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default InterviewTypeDropDown;
