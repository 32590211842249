import { IStore } from '../definitions/redux/store.definitions';
import { INews } from '../definitions/entities.definition';

export const getNewsFromStore = (store: IStore) => {
    const newsById = store.entities.news.byId;
    const order = store.entities.news.order;
    const newsList: INews[] = [];

    order.forEach((newsId) => {
        const news = newsById[newsId];
        newsList.push(news);
    });

    return newsList;
};
