import React from 'react';
import { Typography } from '@material-ui/core';
import { translate } from '../../translation/translate.utils';
import { Variant } from '@material-ui/core/styles/createTypography';

interface IProps {
    translation?: string;
    value?: string;
    style?: object;
    variant?: Variant;
}

const CustomTypography: React.FC<IProps> = (props) => {
    const variant = props.variant || "body1";

    return (
        <Typography variant={variant} style={props.style}>
            <div className="content" dangerouslySetInnerHTML={{__html: props.translation ? translate(props.translation) : props.value}} />
        </Typography>
    );
};

export default CustomTypography;
