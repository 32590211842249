import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { ERecruitingStatus, IRecruitingListModel } from '../../../definitions/entities.definition';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { getFormattedDateString } from '../../../utils/date.utils';
import { translate } from '../../../translation/translate.utils';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import { IStore } from '../../../definitions/redux/store.definitions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setUiEditView } from '../../../redux/ui/ui.actions';

interface IProps {
    recruiting: IRecruitingListModel;
    parentView: EEntityView;
}

const RenderRecruitingListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const parentView = props.parentView;
    const recruiting = props.recruiting;
    const expandOrCollapseItems = useSelector((store: IStore) => store.ui.expandOrCollapseRecruitingListItems);
    const [showDetails, setShowDetails] = useState(expandOrCollapseItems === 'expand');
    const showAbortInformation = props.recruiting.status === 'abort';
    const showContractInformation = props.recruiting.status === ERecruitingStatus.STATUS_CONTRACT || props.recruiting.status === ERecruitingStatus.STATUS_FINISH || props.recruiting.status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD;

    useEffect(() => {
        setShowDetails(expandOrCollapseItems === 'expand');
    }, [expandOrCollapseItems])

    return <>
        <div>
            <Card
                className={styles.root} style={{cursor: 'default'}}
                onClick={() => {
                    dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
                }}
            >
                <CardHeader
                    title={
                        <>
                            <div className={"flexContainerRow"}>
                                <div className={"flex1 flexContainerRow alignVerticalCenter"}>
                                    <CandidateSourceRenderer source={recruiting.source} />&nbsp;
                                    {parentView !== EEntityView.candidate &&
                                        <> | {recruiting.candidate.fullName} | </>
                                    }
                                    {parentView === EEntityView.candidate && !recruiting.vacancy &&
                                        <>{recruiting.company.name}</>
                                    }
                                    {parentView !== EEntityView.vacancy && recruiting.vacancy &&
                                        <>{recruiting.vacancy.title}
                                            <>
                                                {parentView !== EEntityView.company &&
                                                    <>({recruiting.company.name})</>
                                                }
                                            </>
                                        </>
                                    }
                                </div>
                                <div className={"flexAutoGrow flexContainerRow alignVerticalCenter alignHorizontalRight"}>
                                    {parentView !== EEntityView.candidate &&
                                        <CandidateProfilesRenderer profiles={recruiting.candidateProfiles} />
                                    }
                                    {!showDetails &&
                                        <CustomClickableIcon
                                            tooltip={"Weitere Daten anzeigen"}
                                            onClick={() => setShowDetails(true)}
                                            icon={EIcons.Fullscreen}
                                            iconStyle={{width: '1.5em', height: '1.5em'}}
                                        />
                                    }
                                    {showDetails &&
                                        <CustomClickableIcon
                                            tooltip={"Weitere Daten ausblenden"}
                                            onClick={() => setShowDetails(false)}
                                            icon={EIcons.FullscreenExit}
                                            iconStyle={{width: '1.5em', height: '1.5em'}}
                                        />
                                    }
                                </div>
                            </div>
                            {showDetails &&
                            <>
                                <Divider style={{margin: 2, height: 1}}/>
                                <div className={"flex"}>
                                    <div className={"flexContainerRow alignVerticalCenter flex1"}>
                                        {IconFactory.getIconComponent(EIcons.Calendar)} Vorgeschlagen am: {getFormattedDateString(recruiting.created)} (Status: {translate('pages.recruiting.statusValues.' + recruiting.status)})
                                    </div>
                                </div>
                                { showAbortInformation &&
                                <>
                                    <Divider style={{margin: 2, height: 1}}/>
                                    <div className={"flexContainerRow"}>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.dateOfAbort')}:</div>
                                            <div className={"formContent"}>
                                                { props.recruiting.dateOfAbort && <DateCellRenderer date={props.recruiting.dateOfAbort} />}
                                            </div>
                                        </div>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.abortedBy')}:</div>
                                            <div className={"formContent"}>{props.recruiting.abortedBy ? translate('misc.' + props.recruiting.abortedBy) : '-'}</div>
                                        </div>
                                    </div>
                                    <div className={"formRowColumn"}>
                                        <div className={"formLabel"}>{translate('pages.recruiting.abortComment')}:</div>
                                        <div className={"formContent"}>{props.recruiting.abortComment}</div>
                                    </div>
                                </>
                                }
                                {showContractInformation &&
                                <>
                                    <Divider style={{margin: 2, height: 1}}/>
                                    <div className={"flexContainerRow"}>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.contractSigningDate')}:</div>
                                            <div className={"formContent"}>
                                                { props.recruiting.contractSigningDate && <DateCellRenderer date={props.recruiting.contractSigningDate} />}
                                            </div>
                                        </div>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.contractBeginningDate')}:</div>
                                            <div className={"formContent"}>
                                                { props.recruiting.contractBeginningDate && <DateCellRenderer date={props.recruiting.contractBeginningDate} />}
                                            </div>
                                        </div>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.trialPeriod')}:</div>
                                            <div className={"formContent"}>
                                                { props.recruiting.endOfTrialPeriod && <DateCellRenderer date={props.recruiting.endOfTrialPeriod} />}
                                                Bestanden: {props.recruiting.finishedTrialPeriod === true ? 'Ja' : props.recruiting.finishedTrialPeriod === false ? 'Nein' : 'Keine Angabe'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flexContainerRow"}>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>{translate('pages.recruiting.contractSalary')}:</div>
                                            <div className={"formContent"}>
                                                <CurrencyCellRenderer value={props.recruiting.contractSalary as number}/>
                                            </div>
                                        </div>
                                        <div className={"formRowColumn"}>
                                            <div className={"formLabel"}>Provision:</div>
                                            <div className={"formContent"}>
                                                <CurrencyCellRenderer value={props.recruiting.possibleCommission}/>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                </>
                                }

                                {!showContractInformation && recruiting.possibleCommission &&
                                <>
                                    <Divider style={{margin: 2, height: 1}}/>
                                    <div className={"formRowColumn"}>
                                        <div className={"formLabel"}>Prognose:</div>
                                        <div className={"formContent"}>
                                            <CurrencyCellRenderer value={recruiting.possibleCommission}/>
                                        </div>
                                    </div>
                                </>
                                }
                                {recruiting.comment &&
                                <>
                                    <Divider style={{margin: 2, height: 1}}/>
                                    <div className={"formRowColumn"}>
                                        <div className={"formLabel"}>Kommentar:</div>
                                        <div className={"formContent"}>
                                            <Nl2brCellRenderer value={recruiting.comment}/>
                                        </div>
                                    </div>
                                </>
                                }
                            </>
                            }
                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </div>
    </>;
}

export default RenderRecruitingListItem;
