import React from 'react';
import { ICompany, ICompanyPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CompanyAutocomplete from '../components/CompanyAutocomplete';

interface IProps {
    value?: ICompany|ICompanyPartial;
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    label?: string;
    onAdd?: () => void;
}

const LegacyCompanyAutocompleteDropDown: React.FC<IProps> = (props) => {
    return (
        <CompanyAutocomplete
            error={props.error}
            helperText={props.helperText}
            required={props.required}
            value={props.value === undefined  ? null : props.value}
            disabled={props.disabled}
            onChange={(value) => {
                //@ts-ignore
                props.onChange({}, value);
            }}
            onAdd={props.onAdd}
            label={props.label || translate('misc.company')}
        />
    );
};

export default LegacyCompanyAutocompleteDropDown;
