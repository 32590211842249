import React, { useEffect, useState } from 'react';
import {
    ESuggestionProgressStatus,
    ESuggestionStatus, IEmployeePartial,
    ISuggestionListModel,
} from '../../../definitions/entities.definition';
import { Rating } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
import { updateSuggestionAction } from '../../../redux/entities/entities.actions';
import { Card, CardHeader, Divider, Tooltip } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import ProgressStatusCellRenderer from '../../../components/CustomCellRenderer/ProgressStatusCellRenderer';
import CustomEditableValue from '../../../components/CustomInput/CustomEditableValue';
import { EIcons } from '../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { COLOR_GREEN } from '../../../theme/theme';
import { IStore } from '../../../definitions/redux/store.definitions';
import SuggestionListItemActions from './SuggestionListItemActions';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import SuggestionListRankingActionButton from './SuggestionListRankingActionButton';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import CustomClickAwayPopper from '../../../components/CustomPaper/CustomClickAwayPopper';
import { renderSuggestionContactAttempts, renderSuggestionProgressInformation } from './SuggestionProgress.utils';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import CandidateAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import VacancySuggestionChangeResponsibleView
    from '../../RecruitingPage/components/components/VacancySuggestionChangeResponsibleView';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import CustomUrlField from '../../../components/CustomInput/CustomUrlField';
import PaperRow from '../../../components/CustomPaper/PaperRow';

interface IProps {
    suggestion: ISuggestionListModel;
    parentView: EEntityView;
    rankingOptions?: {id: number, rating: number, ranking: number; fullName: string}[];
    displayStatusInformation?: boolean;
}

const RenderSuggestionListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const displayStatusInformation = props.displayStatusInformation;
    const suggestion = props.suggestion;
    const parentView = props.parentView;
    const expandOrCollapseItems = useSelector((store: IStore) => store.ui.expandOrCollapseSuggestionListItems);
    const [showDetails, setShowDetails] = useState(expandOrCollapseItems === 'expand');
    const rankingOptions = props.rankingOptions;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const [anchorEl, setAnchorEl] = React.useState<EventTarget|null>(null);
    const [phoneNumber, setPhoneNumber] = useState(suggestion.phoneNumber);
    const [name, setName] = useState(suggestion.name);
    const [firstName, setFirstName] = useState(suggestion.firstName);
    const [candidate, setCandidate] = useState(suggestion.candidate || null);
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial | null>(suggestion.suggestedByEmployee || null);
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial | null>(suggestion.responsibleEmployee || null);
    const [xingUrl, setXingUrl] = useState(suggestion.xingUrl);
    const [linkedInUrl, setLinkedInUrl] = useState(suggestion.linkedInUrl);
    const [freelancerMapUrl, setFreelancerMapUrl] = useState(suggestion.freelancerMapUrl);
    const [websiteUrl, setWebsiteUrl] = useState(suggestion.websiteUrl);
    const [githubUrl, setGithubUrl] = useState(suggestion.githubUrl);
    const [furtherUrl, setFurtherUrl] = useState(suggestion.furtherUrl);

    const [responsibleChangedViewOpen, setResponsibleChangedViewOpen] = useState(false);
    const [responsibleChanged, setResponsibleChanged] = useState(false);
    const [responsibleChangedComment, setResponsibleChangedComment] = useState('');

    useEffect(() => {
        setPhoneNumber(suggestion.phoneNumber);
        setName(suggestion.name);
        setFirstName(suggestion.firstName);
        setCandidate(suggestion.candidate || null);
        setSuggestedBy(suggestion.suggestedByEmployee || null);
        setResponsibleEmployee(suggestion.responsibleEmployee || null);
        setXingUrl(suggestion.xingUrl);
        setLinkedInUrl(suggestion.linkedInUrl);
        setFreelancerMapUrl(suggestion.freelancerMapUrl);
        setWebsiteUrl(suggestion.websiteUrl);
        setGithubUrl(suggestion.githubUrl);
        setFurtherUrl(suggestion.furtherUrl);
        setResponsibleChanged(false);
        setResponsibleChangedComment('');
    }, [suggestion])

    const [fastEditField, setFastEditField] = useState<null|'name'|'candidate'|'phoneNumber'|'profile'|'employee'>();

    useEffect(() => {
        setShowDetails(expandOrCollapseItems === 'expand');
    }, [expandOrCollapseItems])

    useEffect(() => {
        if (!responsibleEmployee) {
            return;
        }

        if (suggestion.responsibleEmployee.id !== responsibleEmployee.id && responsibleEmployee.id !== loggedInEmployee.id) {
            setResponsibleChangedViewOpen(true);
        }
    }, [responsibleEmployee]);

    const getRating = (suggestion: ISuggestionListModel) => {
        return <Rating
            size={"large"}
            value={suggestion.rating/2}
            max={3}
            icon={<StarIcon style={{ width: "1.2em", height: "1.2em", pointerEvents: "auto" }} />}
            precision={0.5}
            onChange={(event, value) => {
                if (value) {
                    dispatch(updateSuggestionAction(token, {
                        id: suggestion.id,
                        rating: value * 2
                    }));
                }
            }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        />
    }

    const saveFastEdit = () => {
        dispatch(updateSuggestionAction(token, {
            id: suggestion.id,
            name: fastEditField === 'name' ? name : undefined,
            firstName: fastEditField === 'name' ? firstName : undefined,
            linkedInUrl: fastEditField === 'profile' ? linkedInUrl : undefined,
            websiteUrl: fastEditField === 'profile' ? websiteUrl : undefined,
            githubUrl: fastEditField === 'profile' ? githubUrl : undefined,
            furtherUrl: fastEditField === 'profile' ? furtherUrl : undefined,
            xingUrl: fastEditField === 'profile' ? xingUrl : undefined,
            freelancerMapUrl: fastEditField === 'profile' ? freelancerMapUrl : undefined,
            candidateId: fastEditField === 'candidate' ? candidate?.id : undefined,
            phoneNumber: fastEditField === 'phoneNumber' ? phoneNumber : undefined,
            responsibleEmployeeId: fastEditField === 'employee' ? responsibleEmployee?.id : undefined,
            suggestedByEmployeeId: fastEditField === 'employee' ? suggestedBy?.id : undefined,
            responsibleChanged: fastEditField === 'employee' ? responsibleChanged : undefined,
            responsibleChangedComment: fastEditField === 'employee' ? responsibleChangedComment : undefined,
        }));
        handleCloseFastEditPopper();
    }

    const handleOpenFastEditPopper = (event: MouseEvent, field: 'name'|'candidate'|'phoneNumber'|'profile'|'employee') => {
        if (anchorEl) {
            handleCloseFastEditPopper();
            return;
        }
        setAnchorEl(event.currentTarget);
        setFastEditField(field);
    };
    const handleCloseFastEditPopper = () => {
        setAnchorEl(null);
        setFastEditField(null);
    };

    const renderCandidate = (suggestion: ISuggestionListModel) => {
        return <>
            <div className={"flexContainerColumn"}>
                <div className={"formLabelSmall"}>Tel.</div>
                <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                    <CustomClickableIcon
                        onClick={(event) => handleOpenFastEditPopper(event, 'phoneNumber')}
                        icon={EIcons.Phone}
                        iconStyle={{color: suggestion.phoneNumber ? COLOR_GREEN : 'unset'}}
                        tooltip={suggestion.phoneNumber ? "Telefonnummer bekannt: " + suggestion.phoneNumber : "Klicken um Telefonnummer einzutragen"}
                    />
                </div>
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn"}>
                <div className={"formLabelSmall"}>Verkn.</div>
                <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                    <CustomClickableIcon
                        onClick={(event) => handleOpenFastEditPopper(event, 'candidate')}
                        icon={EIcons.People}
                        iconStyle={{color: suggestion.candidate ? COLOR_GREEN : 'unset'}}
                        tooltip={suggestion.candidate ? "Kandidat verknüpft" : "Klicken um Kandidat zu verknüpfen"}
                    />
                </div>
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn"}>
                <div className={"formLabelSmall"}>{suggestion.name ? 'Name' : 'Referenznummer Arbeitsamt (Name fehlt)'}</div>
                <div className={"alignVerticalCenter height100"} style={{fontWeight: 500, fontStyle: 'italic'}}>
                    {suggestion.ranking > 0 && suggestion.ranking + '. '}{suggestion.fullName || suggestion.externalCandidateReference}
                    &nbsp;
                    {!suggestion.name &&
                        <CustomClickableIcon
                            tooltip={"Namen eintragen"}
                            icon={EIcons.Edit}
                            iconStyle={{width: 20, height: 20}}
                            onClick={(event) => handleOpenFastEditPopper(event, 'name')}
                        />
                    }
                </div>
            </div>
        </>;
    }

    const toggleDetails = () => {
        if (anchorEl) {
            return;
        }
        setShowDetails(!showDetails);
    }

    return <>
        <VacancySuggestionChangeResponsibleView
            onClose={() => {
                setResponsibleChanged(false);
                setResponsibleChangedViewOpen(false);
            }}
            onSave={(comment) => {
                setResponsibleChanged(true);
                setResponsibleChangedComment(comment);
            }}
            open={responsibleChangedViewOpen}
            newResponsibleName={responsibleEmployee?.fullName}
        />
        <div>
            <CustomClickAwayPopper width={fastEditField === 'profile' ? 'auto' : 500} anchorEl={anchorEl} removeAnchor={handleCloseFastEditPopper}>
                <div className={"flexContainerRow gap5"}>
                    {fastEditField === 'employee' && <>
                        <EmployeeAutocomplete
                            label={translate('pages.vacancy.suggestedByEmployee')}
                            value={suggestedBy || null}
                            size={"small"}
                            required
                            onChange={( value) => setSuggestedBy(value)}
                        />
                        <EmployeeAutocomplete
                            label={translate('pages.vacancy.properties.responsibleEmployee')}
                            value={responsibleEmployee || null}
                            size={"small"}
                            required
                            onChange={(value) => setResponsibleEmployee(value)}
                        />
                    </>}
                    {fastEditField === 'name' && <>
                        <CustomTextField
                            autoFocus
                            size={"small"}
                            value={firstName}
                            onChange={(value) => setFirstName(value)}
                            label={translate('pages.candidate.properties.firstName')}
                        />
                        <CustomTextField
                            size={"small"}
                            value={name}
                            onChange={(value) => setName(value)}
                            label={translate('pages.candidate.properties.name')}
                        />
                    </>}
                    {fastEditField === 'phoneNumber' && <>
                        <CustomTextField
                            autoFocus
                            size={"small"}
                            value={phoneNumber}
                            onChange={(value) => setPhoneNumber(value)}
                            label={translate('misc.mobileNumber')}
                        />
                    </>}
                    {fastEditField === 'candidate' && <>
                        <CandidateAutocomplete
                            autoFocus
                            required
                            value={candidate}
                            size={"small"}
                            onChange={(value) => {
                                setCandidate(value);
                            }}
                        />
                    </>}
                    {fastEditField === 'profile' && <>
                        <div style={{width: 300}}>
                            <CustomUrlField
                                value={linkedInUrl}
                                size={"small"}
                                onChange={(value) => setLinkedInUrl(value)}
                                label={'linkedIn Url'}
                            />
                            <CustomUrlField
                                value={xingUrl}
                                size={"small"}
                                onChange={(value) => setXingUrl(value)}
                                label={'Xing Url'}
                            />
                            <CustomUrlField
                                value={freelancerMapUrl}
                                size={"small"}
                                onChange={(value) => setFreelancerMapUrl(value)}
                                label={'Freelancermap Url'}
                            />
                            <CustomUrlField
                                value={websiteUrl}
                                size={"small"}
                                onChange={(value) => setWebsiteUrl(value)}
                                label={'Homepage Url'}
                            />
                            <CustomUrlField
                                value={githubUrl}
                                size={"small"}
                                onChange={(value) => setGithubUrl(value)}
                                label={'Github Url'}
                            />
                            <CustomUrlField
                                value={furtherUrl}
                                size={"small"}
                                onChange={(value) => setFurtherUrl(value)}
                                label={'Weitere Url'}
                            />
                        </div>
                    </>}
                    <CustomClickableIcon tooltip={"Speichern"} onClick={saveFastEdit} icon={EIcons.Save}/>
                    <CustomClickableIcon tooltip={"Schliessen"} onClick={handleCloseFastEditPopper} icon={EIcons.Close}/>
                </div>
            </CustomClickAwayPopper>
            <Card className={styles.root + ' hoverContainer'}>
                <CardHeader
                    title={
                        <>
                            <div className={"flexColumn"}>
                                <div className={"flex"}>
                                    <div
                                        className={anchorEl ? "flex1 flexContainerRow gap5" : "flex1 flexContainerRow cursorPointer gap5"}
                                        onClick={toggleDetails}
                                    >
                                        <div className={"flexContainerColumn"}>
                                            <div className={"formLabelSmall"}>Kontakt</div>
                                            <div className={"flexContainerColumn alignVerticalCenter alignHorizontalCenter height100"}>
                                                <ProgressStatusCellRenderer
                                                    status={suggestion.progressStatus}
                                                    showAsTooltip
                                                />
                                            </div>
                                        </div>
                                        <Divider orientation={"vertical"}/>
                                        <div className={"flexContainerColumn"}>
                                            <div className={"formLabelSmall"}>Quelle</div>
                                            <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                                                <CandidateSourceRenderer source={suggestion.source}/>
                                            </div>
                                        </div>
                                        <Divider orientation={"vertical"}/>
                                        {parentView !== EEntityView.candidate &&
                                            <>{renderCandidate(suggestion)}</>
                                        }
                                        {parentView !== EEntityView.vacancy &&
                                            <div className={"flexContainerColumn"}>
                                                <div className={"formLabelSmall"}>Vakanz</div>
                                                <div className={"alignVerticalCenter height100"}>
                                                    {suggestion.vacancy.title}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={"flex flexAutoGrow alignRight gap5"}>
                                        {displayStatusInformation &&
                                            <>
                                                <Divider orientation={"vertical"}/>
                                                <div className={"flexContainerColumn"}>
                                                    <div className={"formLabelSmall"}>Status</div>
                                                    <div className={"alignVerticalCenter height100 formContent"}>
                                                        {translate('pages.recruiting.suggestionStatusValues.' + suggestion.status)}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {renderSuggestionProgressInformation(suggestion)}
                                        <Divider orientation={"vertical"}/>
                                        <div className={"flexContainerColumn"} style={showDetails ? {width: 150} : {}}>
                                            <div className={"formLabelSmall alignLeft"}>Bewertung</div>
                                            {getRating(suggestion)}
                                        </div>
                                    </div>
                                </div>
                                {showDetails &&
                                    <>
                                    <Divider style={{height: 1}}/>
                                    <div className={"flexContainerRow flex1 gap5"}>
                                        <div className={"flexContainerColumn marginTop5 flex1"} style={{minWidth: 400}}>
                                            <div className={"formLabelSmall"}>Kommentar</div>
                                            <CustomEditableValue
                                                theme={'light'}
                                                value={suggestion.comment}
                                                rows={8}
                                                editAction={(comment) => dispatch(updateSuggestionAction(
                                                    token,
                                                    {
                                                        id: suggestion.id,
                                                        comment
                                                    }
                                                ))}
                                            >
                                                <div className={"flexContainerColumn height100 formContent"}>
                                                    <Nl2brCellRenderer value={suggestion.comment}/>
                                                </div>
                                            </CustomEditableValue>
                                        </div>
                                        {suggestion.status === ESuggestionStatus.DECLINED &&
                                            <>
                                                <div className={"flex flexAutoGrow gap5"} style={{width: 216}}>
                                                    <Divider orientation={"vertical"} style={{marginLeft: 10, height: 'auto'}}/>
                                                    <div className={"flexContainerColumn marginTop5"}>
                                                        <div className={"formLabelSmall"}>Kommentar zur Absage</div>
                                                        <CustomEditableValue
                                                            theme={'light'}
                                                            value={suggestion.declineReasonComment}
                                                            rows={8}
                                                            editAction={(declineReasonComment) => dispatch(updateSuggestionAction(
                                                                token,
                                                                {
                                                                    id: suggestion.id,
                                                                    declineReasonComment
                                                                }
                                                            ))}
                                                        >
                                                            <div className={"flexContainerColumn height100 formContent"}>
                                                                <Nl2brCellRenderer value={suggestion.declineReasonComment}/>
                                                            </div>
                                                        </CustomEditableValue>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <Divider orientation={"vertical"} style={{height: 'auto'}}/>
                                        <div className={"flexContainerColumn"} style={{width: 150}}>
                                            <div className={"formLabelSmall alignLeft"}>
                                                Profile
                                            </div>
                                            <div className={"flexContainerRow alignVerticalCenter flexWrap"}>
                                                <CustomClickableIcon
                                                    tooltip={"Profile bearbeiten"}
                                                    icon={EIcons.Edit}
                                                    iconStyle={{width: 20, height: 20}}
                                                    onClick={(event) => handleOpenFastEditPopper(event, 'profile')}
                                                />
                                                <CandidateProfilesRenderer profiles={suggestion.candidateProfiles} />
                                            </div>
                                            <Divider/>
                                            {renderSuggestionContactAttempts(suggestion.currentProgressInformation)}
                                            {suggestion.status === ESuggestionStatus.OPEN &&
                                                <>
                                                    <Divider/>
                                                    <div className={"formLabelSmall"}>Letzte Aktivität</div>
                                                    <div className={"flexContainerRow formContent marginLeft5"}>
                                                        <CustomClickableIcon
                                                            tooltip={"Historie einsehen"}
                                                            icon={EIcons.Search}
                                                            iconStyle={{width: 24, height: 24}}
                                                            onClick={() => dispatch(setUiEditView(EEntityView.suggestion, suggestion.id, 1))}
                                                        >
                                                            &nbsp;<DateDiffCellRenderer date={suggestion.lastActivity} hideTooltip />
                                                        </CustomClickableIcon>
                                                    </div>
                                                </>
                                            }
                                            <Divider/>
                                            <div className={"formLabelSmall"}>Mitarbeiter</div>
                                            <div className={"flexContainerRow formContent marginLeft5"}>
                                                <CustomClickableIcon
                                                    tooltip={"Mitarbeiter bearbeiten"}
                                                    icon={EIcons.Edit}
                                                    iconStyle={{width: 20, height: 20}}
                                                    onClick={(event) => handleOpenFastEditPopper(event, 'employee')}
                                                />
                                                &nbsp;
                                                {suggestion.responsibleEmployee.id !== suggestion.suggestedByEmployee.id
                                                    ? <div className={"flex"}>
                                                        <EmployeeCellRenderer size={"small"} extraTooltip={"Vogeschlagen von: "} employee={suggestion.suggestedByEmployee}/>
                                                        &nbsp;/&nbsp;
                                                        <EmployeeCellRenderer size={"small"} extraTooltip={"Verantwortlicher: "}employee={suggestion.responsibleEmployee}/>
                                                    </div>
                                                    : <EmployeeCellRenderer size={"small"} employee={suggestion.suggestedByEmployee}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                            {showDetails &&
                                <>
                                    <Divider />
                                    <div className={"flexContainerRow gap5 marginTop5"} onClick={toggleDetails}>
                                        <SuggestionListItemActions
                                            suggestionId={suggestion.id}
                                            parentView={parentView}
                                            candidateId={suggestion.candidate?.id}
                                            candidateName={suggestion.candidate?.fullName}
                                            vacancyId={suggestion.vacancy.id}
                                            status={suggestion.status}
                                            responsibleEmployeeId={suggestion.responsibleEmployee.id}
                                            suggestedByEmployeeId={suggestion.suggestedByEmployee.id}
                                            recruitingId={suggestion.recruiting?.id}
                                        />
                                        <SuggestionListRankingActionButton
                                            suggestion={suggestion}
                                            rankingOptions={rankingOptions}
                                        />
                                    </div>
                                </>
                            }

                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </div>
    </>;
}

export default RenderSuggestionListItem;
