import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ClientApi } from '../../requests/ClientApi';
import { getRecruitingListRouteConfig } from '../../requests/routes';
import { ERecruitingStatus } from '../../definitions/entities.definition';
import { getToken } from '../../selectors/app.selectors';

export const useLoadContracts = (tooltipOpen: boolean, candidateId?: number, companyId?: number) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
        if (!tooltipOpen) {
            return;
        }

        setLoading(true);
        ClientApi.request(getRecruitingListRouteConfig, {
            token,
            start: 0,
            limit: 100,
            orderBy: 'id',
            orderDirection: 'DESC',
            candidateId,
            companyId,
            statusArray: [
                ERecruitingStatus.STATUS_CONTRACT,
                ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD,
                ERecruitingStatus.STATUS_FINISH
            ]
        }).then((result) => {
            setLoading(false);
            setContracts(result.records);
        });
    }, [tooltipOpen]);

    return {
        contracts,
        loading
    };
}
