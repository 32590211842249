import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { connect, ConnectedProps } from 'react-redux';
import { addActivityAction, updateActivityAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import {
    EActivityType,
    EFeedbackType,
    IActivityForApi,
    ICompanyContact,
    ICompanyContactPartial,
    IEmployee,
    IEmployeePartial,
} from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import moment from 'moment';
import CompanyContactAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyContactAutocompleteDropDown';
import { setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import LegacyYesNoDropDown from '../../../components/CustomInput/CustomDropDownInput/LegacyYesNoDropDown';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    employee: IEmployee|IEmployeePartial;
    companyContact?: ICompanyContact|ICompanyContactPartial;
    dateTime?: Date;
    feedbackType?: EFeedbackType;
    feedbackCompany: string,
    feedbackCandidate: string,
    finishedTrialPeriod?: boolean
}

class FeedbackForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialActivity = props.initialActivity;

        this.state = {
            employee: initialActivity?.employee || this.props.loggedInEmployee,
            feedbackCompany: initialActivity?.feedbackCompany || '',
            feedbackCandidate: initialActivity?.feedbackCandidate || '',
            companyContact: initialActivity?.companyContact || undefined,
            dateTime: initialActivity ? new Date(initialActivity?.dateTime) : new Date(),
            feedbackType: initialActivity ? initialActivity.feedbackType : props.addData.feedbackType ? props.addData.feedbackType : EFeedbackType.default,
            finishedTrialPeriod: initialActivity && initialActivity.recruiting ? initialActivity.recruiting.finishedTrialPeriod : (this.props.addData.recruiting && this.props.addData.recruiting.finishedTrialPeriod)
        }
    }

    @autobind
    save() {
        const activity: IActivityForApi = {
            type: EActivityType.feedback,
            employeeId: this.state.employee?.id || 0,
            dateTime: moment(this.state.dateTime).format("DD.MM.Y H:mm:ss"),
            companyContactId: this.state.companyContact?.id,
            feedbackType: this.state.feedbackType,
            feedbackCompany: this.state.feedbackCompany,
            feedbackCandidate: this.state.feedbackCandidate,
            finishedTrialPeriod: this.state.finishedTrialPeriod
        };

        if (this.props.addData.add) {
            activity.module = this.props.addData.module;
            activity.candidateId = this.props.addData.candidateId;
            activity.companyId = this.props.addData.companyId;
            activity.vacancyId = this.props.addData.vacancyId;
            activity.recruitingId = this.props.addData.recruitingId;
            activity.recruitingSuggestionId = this.props.addData.suggestionId;

            this.props.addActivityAction(this.props.token, activity);
            return;
        }

        activity.id = this.props.initialActivity.id;
        this.props.updateActivityAction(this.props.token, activity);
        return;
    }

    render() {
        return (
            <div>
                <PaperRow>
                    <CustomDateTimePicker
                        label={translate('pages.activity.properties.dateTime')}
                        value={this.state.dateTime}
                        onChange={(value) => this.setState({
                            dateTime: value
                        })}
                    />
                    <LegacyEmployeeAutocompleteDropDown
                        value={this.state.employee}
                        onChange={(event, value) => this.setState({
                            employee: value
                        })}
                        error={Boolean(this.props.validationErrors.employeeId)}
                        helperText={this.props.validationErrors.employeeId}
                    />
                    <CompanyContactAutocompleteDropDown
                        value={this.state.companyContact}
                        onChange={(event, value) => this.setState({
                            companyContact: value
                        })}
                        label={translate('pages.recruiting.feedbackContactPerson')}
                        onAdd={() => {
                            if (this.props.companyId) {
                                this.props.setUiCompanyContactAddDataAction({
                                    companyId: this.props.companyId
                                });
                            }
                        }}
                        companyId={this.props.companyId}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        disabled={true}
                        label={translate('misc.action')}
                        value={translate('pages.recruiting.feedbackAction')}
                    />
                </PaperRow>
                {this.state.feedbackType === EFeedbackType.trial_end &&
                    <PaperRow>
                        <LegacyYesNoDropDown
                            onChange={(event) => {
                                const value = event.target.value;
                                this.setState({
                                    finishedTrialPeriod: value === 1 ? true : value === 0 ? false : undefined
                                })
                            }}
                            value={this.state.finishedTrialPeriod}
                            label={translate('pages.recruiting.finishedTrialPeriod')}
                            error={Boolean(this.props.validationErrors.finishedTrialPeriod)}
                            helperText={this.props.validationErrors.finishedTrialPeriod}
                        />
                    </PaperRow>
                }
                <PaperRow>
                    <CustomTextField
                        multiline
                        rows={6}
                        label={translate('pages.recruiting.feedbackCompany')}
                        value={this.state.feedbackCompany}
                        onChange={(value) => this.setState({
                            feedbackCompany: value
                        })}
                        error={Boolean(this.props.validationErrors.feedbackCompany)}
                        helperText={this.props.validationErrors.feedbackCompany}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        multiline
                        rows={6}
                        label={translate('pages.recruiting.feedbackCandidate')}
                        value={this.state.feedbackCandidate}
                        onChange={(value) => this.setState({
                            feedbackCandidate: value
                        })}
                        error={Boolean(this.props.validationErrors.feedbackCandidate)}
                        helperText={this.props.validationErrors.feedbackCandidate}
                    />
                </PaperRow>
                <CustomDialogActions
                    onClose={this.props.onClose}
                    buttons={[{
                        void: this.save,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </div>
        );
    }
}

const mapState = (store: IStore) => {
    const initialActivity = store.entities.activities.byId[store.ui.editActivityId];
    let companyId = 0;
    if (store.ui.addActivityData.add && store.ui.addActivityData.companyId) {
        companyId = store.ui.addActivityData.companyId;
    }
    if (!store.ui.addActivityData.add && initialActivity && initialActivity.company) {
        companyId = initialActivity.company.id;
    }

    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialActivity,
        addData: store.ui.addActivityData,
        companyId: companyId
    });
}

const mapDispatch = {
    updateActivityAction,
    addActivityAction,
    setUiCompanyContactAddDataAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FeedbackForm);
