import React, { useState } from 'react';
import RenderRecruitingListItem from './RenderRecruitingListItem';
import { IRecruitingListModel } from '../../../definitions/entities.definition';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { translate } from '../../../translation/translate.utils';
import { EEntityView } from '../../../definitions/ui.definitions';

interface IProps {
    status: string;
    recruitings: IRecruitingListModel[];
    parentView: EEntityView;
}

const RenderRecruitingListGroup: React.FC<IProps> = (props) => {
    const status = props.status;
    const recruitings = props.recruitings;
    const parentView = props.parentView;
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);

    return <>
        <div className={"flexContainerRow"} onClick={toggleExpand}>
            <h4 style={{margin: 5, flex: 1}}>{translate('pages.recruiting.statusValues.' + status)} ({recruitings.length})</h4>
            <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
        </div>
        {expanded &&
        <>
            {recruitings.map((recruiting) =>
                <RenderRecruitingListItem
                    key={recruiting.id}
                    parentView={parentView}
                    recruiting={recruiting}
                />
            )}
        </>
        }
    </>
}

export default React.memo(RenderRecruitingListGroup);
