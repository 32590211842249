import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import autobind from 'autobind-decorator';
import { IStore } from '../../definitions/redux/store.definitions';
import { getWebVacancyListAction } from '../../redux/entities/entities.actions';
import { getWebVacancyListRequestObject } from '../../selectors/webVacancy.selectors';
import WebVacancyList from './WebVacancyList';
import { EEntities } from '../../definitions/entities.definition';
import CustomSideFilterPanel from '../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';
import { isOnlyShowMineModeActive } from '../../selectors/employee.selectors';

class WebVacancyPage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount = () => {
        this.loadWebVacancies();
    };

    @autobind
    loadWebVacancies() {
        this.props.getWebVacancyListAction(this.props.token, this.props.requestObject);
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        const needToFetch =
            JSON.stringify(this.props.sorting) !== JSON.stringify(prevProps.sorting) ||
            JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter) ||
            (!prevProps.reload && this.props.reload) ||
            this.props.onlyShowMine !== prevProps.onlyShowMine;

        if (needToFetch) {
            this.loadWebVacancies();
        }
    }

    render() {
        return (
            <>
                <CustomSideFilterPanel entity={EEntities.WebVacancy}>
                    <WebVacancyList />
                </CustomSideFilterPanel>
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    token: store.actor.token,
    sorting: store.webVacancyPage.sorting,
    requestObject: getWebVacancyListRequestObject(store),
    filter: store.webVacancyPage.filter,
    reload: store.webVacancyPage.reload,
    onlyShowMine: isOnlyShowMineModeActive(store)
});

const mapDispatch = {
    getWebVacancyListAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(WebVacancyPage);
