import React from 'react';
import {
    IApplicationPartial,
    ICandidatePartial,
    ICompanyContactPartial,
    ICompanyPartial,
    IEmployeePartial,
    IPerson,
    ISuggestionPartial,
    IVacancyPartial,
} from '../../../definitions/entities.definition';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import CompanyContactCellRenderer from '../../../components/CustomCellRenderer/CompanyContactCellRenderer';
import CompanyCellRenderer from '../../../components/CustomCellRenderer/CompanyCellRenderer';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import SuggestionCellRenderer from '../../../components/CustomCellRenderer/SuggestionCellRenderer';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';

interface IProps {
    entity: IPerson|IVacancyPartial|ICompanyPartial|IApplicationPartial;
    type: 'candidate'|'suggestion'|'employee'|'company_contact'|'company'|'vacancy'|'application'
}
const ChatNodeAvatar: React.FC<IProps> = (props) => {
    const entity = props.entity;
    const type = props.type;

    switch (type) {
        case 'candidate':
            return <div style={{maxWidth: 100}}>
                <CandidateCellRenderer hideIcon candidate={entity as ICandidatePartial}  />
            </div>
        case 'company':
            return <div style={{maxWidth: 100}}>
                <CompanyCellRenderer hideIcon company={entity as ICompanyPartial} />
            </div>
        case 'company_contact':
            return <div style={{maxWidth: 100}}>
                <CompanyContactCellRenderer hideIcon companyContact={entity as ICompanyContactPartial} />
            </div>
        case 'employee': {
            const employee = entity as IEmployeePartial;

            if (employee.fullName.trim() === 'System') {
                return null;
            }

            return <>
                <EmployeeCellRenderer employee={entity as IEmployeePartial} />
            </>
        }
        case 'suggestion':
            return <div style={{maxWidth: 100}}>
                <SuggestionCellRenderer hideIcon recruitingSuggestion={entity as ISuggestionPartial} displayFullName />
            </div>
        case 'application': {
            const application = entity as IApplicationPartial;
            if (application.vacancy) {
                return <div style={{maxWidth: 100}}>
                    <VacancyCellRenderer hideIcon vacancy={application.vacancy} />
                </div>
            } else {
                return <UrlCellRenderer value={'Stelle öffnen'} url={application.url} />
            }
        }
        case 'vacancy':
            return <div style={{maxWidth: 100}}>
                <VacancyCellRenderer hideIcon vacancy={entity as IVacancyPartial} />
            </div>
        default:
            return <>FEHLT</>

    }
}

export default ChatNodeAvatar;
