import { ECandidateSource, ESuggestionStatus, ISuggestion, TBrainzEntity } from '../definitions/entities.definition';
import {
    setVacancyPageSuggestionToDeclineAction,
    setVacancyPageSuggestionToDeleteAction,
} from '../redux/vacancyPage/vacancyPage.actions';
import { EIcons } from '../components/Icons/IconFactory';
import { registerError } from '../redux/error/error.actions';
import { translate } from '../translation/translate.utils';
import { IListActionButtonConfig } from '../definitions/components.definitions';
import { useDispatch } from 'react-redux';
import { setRecruitingPageStartRecruitingAction } from '../redux/recruitingPage/recruitingPage.actions';

export const getSuggestionExternalUrl = (candidateSource: ECandidateSource, url?: string, reference?: string) => {
    if (candidateSource === ECandidateSource.jobcenter && reference) {
        return `https://www.arbeitsagentur.de/bewerberboerse/suche?was=${reference.trim()}&angebotsart=1`;
    }
    if (url) {
        return url;
    }

    return null;
}

export const getSuggestionListActionButtons = (): IListActionButtonConfig[] => {
    const dispatch = useDispatch();

    return [{
        action: (suggestionId: number) => dispatch(setVacancyPageSuggestionToDeclineAction(suggestionId, true)),
        translationKey: "pages.vacancy.setSuggestionToFollowup",
        icon: EIcons.AvTimer,
        condition: (suggestion: TBrainzEntity) => {
            const suggestionCast = suggestion as ISuggestion;
            return suggestionCast.status !== ESuggestionStatus.FOLLOWUP;
        }
    }, {
        action: (suggestionId: number, suggestion: TBrainzEntity) => {
            const suggestionCast = suggestion as ISuggestion;
            if (suggestionCast.candidate) {
                dispatch(setRecruitingPageStartRecruitingAction({
                    open: true,
                    candidateId: suggestionCast.candidate.id,
                    vacancyId: suggestionCast.vacancy.id
                }));
            } else {
                dispatch(registerError(translate('pages.vacancy.errorSetCandidate')));
            }
        },
        translationKey: "pages.vacancy.acceptSuggestion",
        icon: EIcons.ThumbUp,
        condition: (suggestion: TBrainzEntity) => {
            const suggestionCast = suggestion as ISuggestion;
            return suggestionCast.status !== ESuggestionStatus.ACCEPTED;
        }
    }, {
        action: (suggestionId: number) => dispatch(setVacancyPageSuggestionToDeclineAction(suggestionId, false)),
        translationKey: "pages.vacancy.declineSuggestion",
        icon: EIcons.ThumbDown,
        condition: (suggestion: TBrainzEntity) => {
            const suggestionCast = suggestion as ISuggestion;
            return suggestionCast.status !== ESuggestionStatus.DECLINED;
        }
    }, {
        action: (suggestionId: number) => dispatch(setVacancyPageSuggestionToDeleteAction(suggestionId)),
        translationKey: "tables.openDeleteView",
        icon: EIcons.Delete
    }]
}
