import React from 'react';
import { createStyles, Paper } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            minWidth: 150,
        },
    })
);

const CustomPaper: React.FC<PaperProps> = (props) => {
    const styles = useStyles();

    return <Paper className={styles.root}>{props.children}</Paper>;
};

export default CustomPaper;
