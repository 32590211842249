import React from 'react';
import { IActivity } from '../../definitions/entities.definition';
import { Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';
import styles from '../Shared/Todo/TodoCard.module.css';
import { setUiActivityToDeleteAction, setUiActivityToEditAction } from '../../redux/ui/ui.actions';
import DateTimeCellRenderer from '../../components/CustomCellRenderer/DateTimeCellRenderer';
import { useDispatch } from 'react-redux';
import EmployeeCellRenderer from '../../components/CustomCellRenderer/EmployeeCellRenderer';
import { EIcons, IconFactory } from '../../components/Icons/IconFactory';
import ActivityTypeCellRenderer from '../../components/CustomCellRenderer/ActivityTypeCellRenderer';
import CustomClickableIcon from '../../components/CustomInput/CustomClickableIcon';
import ActivityDescriptionCellRenderer from '../../components/CustomCellRenderer/ActivityDescriptionCellRenderer';

interface IProps {
    activity: IActivity;
}

const RenderActivityListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const activity = props.activity;

    return <>
        <div>
            <Card
                className={styles.root} style={{cursor: 'default'}}
                onClick={() => dispatch(setUiActivityToEditAction(activity.id))}
            >
                <CardHeader
                    title={
                        <>
                        <div className={"flexColumn gap5"}>
                            <div className={"flex"}>
                                <div className={"flex1 flexContainerRow alignVerticalCenter"}
                                     style={{cursor: 'pointer'}}
                                >
                                    <EmployeeCellRenderer employee={activity.employee} />&nbsp;
                                    <DateTimeCellRenderer date={activity.dateTime} />
                                </div>
                                <div className={"flex1 flexContainerRow alignVerticalCenter alignHorizontalRight"}>
                                    <ActivityTypeCellRenderer type={activity.type} />&nbsp;
                                    {activity.candidate
                                        ? <Tooltip title={"Aktivität mit Kandidat"}>
                                            {IconFactory.getIconComponent(EIcons.People)}
                                        </Tooltip>
                                        : <Tooltip title={"Aktivität mit Firma"}>
                                            {IconFactory.getIconComponent(EIcons.Apartment)}
                                        </Tooltip>
                                    }
                                    <CustomClickableIcon onClick={() => dispatch(setUiActivityToDeleteAction(activity.id))} icon={EIcons.Delete} />
                                </div>
                            </div>
                        </div>
                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
                <CardContent style={{paddingTop: 0, paddingBottom: 5}} onClick={() => dispatch(setUiActivityToEditAction(activity.id))}>
                    <div className={"formContent"}>
                        <ActivityDescriptionCellRenderer activity={activity} />
                    </div>
                </CardContent>
            </Card>
        </div>
    </>;
}

export default React.memo(RenderActivityListItem);
