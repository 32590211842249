import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren<any>{
    disabled?: boolean;
}

const CustomQuickFilterContainer: React.FC<IProps> = (props) => {
    const disabled = props.disabled || false;
    return <div
        className={"flexContainerRow gap5 marginTop10 flexWrap"}
        style={{opacity: disabled ? 0.5 : 1}}
    >
        {props.children}
    </div>
}

export default CustomQuickFilterContainer;
