import React, { useEffect } from 'react';
import { EEntities } from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { getCandidateListOfVacancySearchAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { getWebVacancyCandidateSearchListRequestObject } from '../../../selectors/candidate.selectors';
import CustomSideFilterPanel from '../../../components/CustomFilter/CustomSideFilter/CustomSideFilterPanel';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setUiSearchCandidateView } from '../../../redux/ui/ui.actions';
import SearchCandidateFilterPanel from './components/SearchCandidateFilterPanel';
import SearchCandidateList from './components/SearchCandidateList';
import {
    setWebVacancyPageCandidateSearchFilterAction,
    setWebVacancyPageCandidateSearchFilterMenuAction,
} from '../../../redux/webVacancyPage/webVacancyPage.actions';

const SearchCandidateByWebVacancyView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => Boolean(store.ui.searchForWebVacancyId > 0));
    const webVacancyId = useSelector((store: IStore) => store.ui.searchForWebVacancyId);
    const webVacancy = useSelector((store: IStore) => store.entities.webVacancy.byId[webVacancyId]);
    const requestObject = useSelector(getWebVacancyCandidateSearchListRequestObject);

    const onClose = () => {
        dispatch(setUiSearchCandidateView(0, 0));
    }

    useEffect(() => {
        if (!webVacancy) {
            return;
        }

        dispatch(setWebVacancyPageCandidateSearchFilterAction({
            distanceSearchKm: 30,
            searchForWebVacancyId: webVacancyId,
            distanceSearchCompanyLocationId: webVacancy.locationModels[0].id
        }));

        dispatch(setWebVacancyPageCandidateSearchFilterMenuAction({
            distanceSearchKm: 30,
            distanceSearchCompanyLocation: webVacancy.locationModels[0]
        }))
    }, [webVacancyId, JSON.stringify(webVacancy)]);

    useEffect(() => {
        if (!open || !webVacancy) {
            return;
        }

        dispatch(getCandidateListOfVacancySearchAction(
            token,
            requestObject
        ));
    }, [open, JSON.stringify(requestObject)]);

    if (!open) {
        return null;
    }

    return <>
        <CustomDialog fullScreen fullWidth open={open} onClose={onClose}>
            <CustomDialogHeader string={<>Interner Suchlauf für Stelle <br/><div className={"formContent"}> {webVacancy.title}</div></>} onClose={onClose}>
                <SearchCandidateFilterPanel webVacancy={webVacancy}/>
            </CustomDialogHeader>
            <div className={"DialogContent"}>
                <CustomSideFilterPanel entity={EEntities.VacancyCandidateSearch}>
                    <SearchCandidateList webVacancyId={webVacancyId}/>
                </CustomSideFilterPanel>
            </div>
        </CustomDialog>
    </>;
};

export default SearchCandidateByWebVacancyView;
