import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import {
    setVacancyPageAddSuggestionAction,
    setVacancyPageCloseSuggestionListViewAction,
} from '../../redux/vacancyPage/vacancyPage.actions';
import React, { useEffect } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { EEntities, ESuggestionStatus } from '../../definitions/entities.definition';
import SuggestionListViewList from './components/SuggestionListViewList';
import { getSuggestionListAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { getVacancyPageRequestObjectForSuggestionsListView } from '../../selectors/recruitingSuggestion.selectors';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import TopBarButton from '../../components/ListAndPreview/TopBarButton';
import { EIcons } from '../../components/Icons/IconFactory';
import TopBarPanel from '../../components/ListAndPreview/TopBarPanel';

interface IProps {
    vacancyId: number;
}

const SuggestionListView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const requestObject = useSelector((store: IStore) => getVacancyPageRequestObjectForSuggestionsListView(store, props.vacancyId));
    const open = useSelector((store: IStore) => store.vacancyPage.suggestionListViewOpen);
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionListViewFilter);
    const sorting = useSelector((store: IStore) => store.vacancyPage.suggestionListViewSorting);
    const reload = useSelector((store: IStore) => store.vacancyPage.reloadSuggestions);

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setVacancyPageCloseSuggestionListViewAction());
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        dispatch(getSuggestionListAction(
            token,
            requestObject
        ));
    }, [open, JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        if (!reload) {
            return;
        }

        dispatch(getSuggestionListAction(
            token,
            requestObject
        ));
    }, [reload]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog open={open} onClose={onClose} fullWidth>
                <CustomDialogHeader
                    string={translate("pages.vacancy.favorites")}
                    filterMenu={EEntities.Suggestion}
                    onClose={onClose}
                />
                {filter.status === ESuggestionStatus.OPEN &&
                    <TopBarPanel>
                        <TopBarButton
                            onClick={() => dispatch(setVacancyPageAddSuggestionAction({
                                open: true,
                                vacancyId: props.vacancyId
                            }))}
                            icon={EIcons.Add}
                            label={translate('pages.vacancy.addExternalCandidate')}
                        />
                    </TopBarPanel>
                }
                <SuggestionListViewList />
                <CustomDialogActions
                    onClose={onClose}
                />
            </CustomDialog>
        </>
    );
};

export default SuggestionListView;
