import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import moment from 'moment';
import { actorLogOutAction, actorRefreshTokenAction } from '../../redux/actor/actor.actions';
import { EStorageItem } from '../../definitions/app.definitions';

/**
 * renderless controller
 */
class RefreshTokenController extends React.PureComponent<PropsFromRedux> {
    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        const passwordChanged = prevProps.renewPassword && !this.props.renewPassword;

        if (this.props.expires || passwordChanged) {
            const token = localStorage.getItem(EStorageItem.Token);

            if (token !== null) {
                this.props.actorRefreshTokenAction(token);
            }
        }

        if (this.props.executeLogout && !prevProps.executeLogout) {
            localStorage.removeItem(EStorageItem.Token);
            this.props.actorLogOutAction();
        }
    }

    render() {
        return null;
    }
}

const mapState = (store: IStore) => ({
    expires: store.actor.data ? Boolean(moment.duration(moment(store.actor.data.expire).diff(moment())).minutes() <= 10) : false,
    renewPassword: store.ui.renewPassword,
    executeLogout: store.actor.executeLogout
});

const mapDispatch = {
    actorRefreshTokenAction,
    actorLogOutAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RefreshTokenController);
