import React from 'react';
import { EEntities, ISkill } from '../../../definitions/entities.definition';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { ECellAlign, ESortingOptions } from '../../../definitions/components.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';

interface IProps {
    skills: ISkill[];
    onReload: () => void;
    loading: boolean;
    setOrdering: (orderBy: string, orderDirection: ESortingOptions) => void;
    addSkill: (skill: ISkill) => void;
}

const SelectMultipleSkillViewSearchList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    total: props.skills.length,
                    dragAndDrop: true,
                    loading: props.loading,
                    entity: EEntities.SkillSelectSearch,
                    columnConfig: {
                        skill: {
                            header: 'Skill',
                            property: 'name'
                        },
                        usedByCandidates: {
                            width: 160,
                            property: 'usedByCandidates',
                            header: 'Anz. Zuweisungen',
                            align: ECellAlign.right,
                            cellRenderer: (value, entity) => {
                                const skill = entity as ISkill;

                                return skill.usedByCandidates + skill.usedByVacancies;
                            }
                        },
                        add: {
                            header: IconFactory.getIconComponent(EIcons.Add),
                            width: 40,
                            property: 'skill.id',
                            cellRenderer: (value: number, entity) => {
                                const skill = entity as ISkill;
                                return <CustomClickableIcon onClick={() => props.addSkill(skill)} icon={EIcons.Add} />
                            }
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSortTemp: string, sortOption: ESortingOptions) => {
                            let propertyToSort = '';
                            switch (propertyToSortTemp) {
                                case 'skillDetail.similarToJobTitle.shareInCandidates':
                                    propertyToSort = 'shareInCandidates';
                                    break;
                                case 'skillDetail.similarToJobTitle.shareInVacancies':
                                    propertyToSort = 'shareInVacancies';
                                    break;
                                case 'skillDetail.skill.name':
                                    propertyToSort = 'name';
                                    break;
                                case 'skillDetail.skill.usedByVacancies':
                                    propertyToSort = 'usedByVacancies';
                                    break;
                                case 'skillDetail.skill.usedByCandidates':
                                    propertyToSort = 'usedByCandidates';
                                    break;
                            }

                            props.setOrdering(propertyToSort, sortOption);
                        }
                    },
                    sortedEntries: props.skills,
                    onReload: props.onReload,
                }}
            />
        </>
    );
}

export default SelectMultipleSkillViewSearchList;
