import React, { PropsWithChildren, useState } from 'react';
import styles from './CustomSidePanelList.module.css';
import { Button, IconButton, List, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../Icons/IconFactory';
import CustomListSearch from './CustomListSearch';
import CustomDateRangeInputButton from '../../CustomDateRangeConfig/CustomDateRangeInputButton';
import { IDateRangeFilterConfig } from '../../../../definitions/filter.definitions';

interface IProps extends PropsWithChildren<any> {
    filterSearchEnabled?: boolean;
    dateRangeConfig?: IDateRangeFilterConfig;
    setDateRange?: () => void;
    reset?: () => void;
    resetEnabled?: boolean;
}

const CustomList: React.FC<IProps> = (props) => {
    const resetEnabled = props.resetEnabled;

    const reset = () => {
        if (props.reset) {
            props.reset();
        }
    };

    const [showSearch, setShowSearch] = useState(false);
    const toggleSearch = () => {
        setShowSearch(!showSearch);
    }

    return <>
        <div className={"flexContainerRow paddingBottom10 gap10"}>
            {props.dateRangeConfig &&
                <CustomDateRangeInputButton config={props.dateRangeConfig}/>
            }
            <Button
                disabled={!resetEnabled}
                variant="contained"
                color="secondary"
                onClick={reset}
                startIcon={IconFactory.getIconComponent(EIcons.Delete)}
            >
                Zurücksetzen
            </Button>
            { props.filterSearchEnabled &&
                <Tooltip title={"Filter suchen"}>
                    <IconButton color="primary" size={"small"} onClick={toggleSearch}>
                        {IconFactory.getIconComponent(EIcons.Search)}
                    </IconButton>
                </Tooltip>
            }
        </div>
        { (props.filterSearchEnabled && showSearch) &&
            <CustomListSearch />
        }
        <List component="nav" className={styles.list}>
            {props.children}
        </List>
    </>
}

export default React.memo(CustomList);
