import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { updateExternalSkillAction } from '../../redux/entities/entities.actions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { ISkillPartial } from '../../definitions/entities.definition';
import { setSkillPageExternalSkillToEditAction } from '../../redux/skillPage/skillPage.actions';
import CustomBackdropLoading from '../../components/CustomBackdrop/CustomBackdropLoading';
import SelectSkillInput from '../Shared/Skill/SelectSkillInput';
import { useLoadExternalSkill } from './ExternalSkillAddEditView.hook';

const SkillAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((store: IStore) => Boolean(store.skillPage.externalSkillToEdit));
    const isEditViewLoading = useSelector((store: IStore) => store.skillPage.externalSkillEditViewLoading);
    const initialSkill = useLoadExternalSkill();

    const token = useSelector((store: IStore) => store.actor.token);
    const [name, setName] = useState<string>('');
    const [skill, setSkill] = useState<undefined|ISkillPartial>();

    const onSave = () => {
        dispatch(updateExternalSkillAction(token, {
            id: initialSkill.id,
            name,
            skillId: skill ? skill.id : undefined,
        }));
    }

    useEffect(() => {
        setName(initialSkill?.name || '');
        setSkill(initialSkill?.skill || undefined);
    }, [initialSkill])

    const onClose = () => {
        setName("");
        setSkill(undefined);
        dispatch(setSkillPageExternalSkillToEditAction(0));
    }

    return (
        <CustomDialog fullWidth open={open} maxWidth={"md"} onClose={onClose}>
            <CustomDialogHeader string={"Externen Skill bearbeiten"} onClose={onClose} />
            <CustomBackdropLoading open={isEditViewLoading} />
                <div style={{ width: "100%", overflow: "auto" }}>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.skill.nameOfSkill')}
                            value={name}
                            onChange={(value) => setName(value)}
                        />
                    </PaperRow>
                    <PaperRow>
                        <SelectSkillInput
                            value={skill}
                            onChange={(skill) => {
                                setSkill(skill)
                            }}
                        />
                    </PaperRow>
                </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => onSave(),
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </CustomDialog>
    );
}

export default SkillAddEditView;
