import React from 'react';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../CustomInput/CustomDialogActions';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomDialogBody from '../CustomDialog/CustomDialogBody';
import { useDispatch, useSelector } from 'react-redux';
import { setUiLogOutDialogOpenAction } from '../../redux/ui/ui.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { actorLogOutAction } from '../../redux/actor/actor.actions';
import { EStorageItem } from '../../definitions/app.definitions';
import { translate } from '../../translation/translate.utils';

const LogoutView: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((store: IStore) => store.ui.logOutDialogOpen);
    const onClose = () => dispatch(setUiLogOutDialogOpenAction(false));

    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"misc.logOut"} onClose={onClose} />
            <CustomDialogBody>
                <CustomTypography translation={"misc.logOutBody"} />
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => {
                        localStorage.removeItem(EStorageItem.Token);
                        dispatch(actorLogOutAction());
                    },
                    label: translate('misc.logOut')
                }]}
            />
        </Dialog>
    );
};

export default LogoutView;
