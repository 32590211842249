import React from 'react';
import { EEntities, IEntitySkill, ISkillPartial } from '../../../definitions/entities.definition';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { Rating } from '@material-ui/lab';
import { sortSkills } from '../../../utils/skills.utils';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { ICustomTableColumnConfig } from '../../../definitions/components.definitions';

interface IProps {
    skills: IEntitySkill[];
    setSelectedSkills: (skills: IEntitySkill[]) => void;
    removeSkill: (skill: ISkillPartial) => void;
    setSkillRanking: boolean;
    setSkillImportant: boolean;
}

const SelectMultipleSkillViewSelectedList: React.FC<IProps> = (props) => {
    const columnConfig: ICustomTableColumnConfig = {
        importantSkill: {
            header: IconFactory.getIconComponent(EIcons.Star, {color: 'gold'}),
                width: 40,
                property: 'importantSkill',
                cellRenderer: (value: boolean, entity) => {
                const entitySkill = entity as IEntitySkill;

                return (<Rating
                    size={"large"}
                    name={"importantSkill" + entity.id}
                    value={value ? 1 : 0}
                    max={1}
                    onChange={() => {
                        props.setSelectedSkills(sortSkills([
                            ...props.skills.map((skill) => {
                                if (skill.skill.id === entitySkill.skill.id && 'importantSkill' in skill) {
                                    return {
                                        ...skill,
                                        importantSkill: !skill.importantSkill
                                    };
                                }

                                return skill;
                            })
                        ]))
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                />);
            }
        },
        ranking: {
            header: 'Ranking',
            property: 'ranking',
            width: 70,
        },
        skill: {
            header: 'Skill',
            property: 'skill.name'
        },
        remove: {
            header: IconFactory.getIconComponent(EIcons.Delete),
                width: 40,
                property: 'skill.id',
                cellRenderer: (value: number, entity) => {
                const skill = entity as IEntitySkill;
                return <CustomClickableIcon onClick={() => props.removeSkill(skill.skill)} icon={EIcons.Delete} />
            }
        }
    };

    if (!props.setSkillImportant) {
        delete columnConfig.importantSkill;
    }

    if (!props.setSkillRanking) {
        delete columnConfig.ranking;
    }

    return (
        <>
            <CustomTable
                config={{
                    dragAndDrop: true,
                    entity: EEntities.SkillSelectSelected,
                    columnConfig,
                    count: props.skills.length,
                    sortedEntries: props.skills,
                }}
            />
        </>
    );
}

export default SelectMultipleSkillViewSelectedList;
