import { useEffect } from 'react';
import { setRenewPassword } from './redux/ui/ui.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './definitions/redux/store.definitions';
import { useCurrentEmployeePasswordRenew } from './selectors/app.selectors';

export const useRenewPassword = (): void => {
    const token = useSelector((store: IStore) => store.actor.token);
    const renewPassword = useCurrentEmployeePasswordRenew();
    const dispatch = useDispatch();

    useEffect(() => {
        if (token && renewPassword) {
            dispatch(setRenewPassword());
        }
    }, [dispatch, token, renewPassword]);
}
