import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

interface IProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    padding?: boolean;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            flex: "1 1 auto",
            overflow: "auto",
            display: "flex",
            flexDirection: "column"
        },
        padding: {
            padding: 5
        }
    })
);
const CustomTabPanel: React.FC<IProps> = (props) => {
    const { children, value, index, padding, ...other } = props;
    const styles = useStyles();
    let className = '';

    if (value === index) {
        className = styles.root;
    }

    if (padding) {
        className += ' ' + styles.padding;
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

export default CustomTabPanel;
