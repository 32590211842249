import React from 'react';
import { Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { IJobTemplate } from '../../definitions/entities.definition';
import { setJobTemplatePageSetJobTemplateAction } from '../../redux/jobTemplate/jobTemplatePage.actions';
import { translate } from '../../translation/translate.utils';

interface IProps {
    jobTemplate?: IJobTemplate;
    jobTitleId?: number;
}

const JobTemplateCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    if (props.jobTemplate) {
        return (
            <> {props.jobTemplate.name} </>
        );
    }

    if (!props.jobTitleId) {
        return (
            <> {translate('misc.noInformation') }</>
        );
    }

    return (
        <Link href={'#'} onClick={() => dispatch(setJobTemplatePageSetJobTemplateAction(props.jobTitleId))}>Setzen</Link>
    );
};

export default JobTemplateCellRenderer;
