import React from 'react';
import { Link, Tooltip } from '@material-ui/core';
import { COLOR_RED } from '../../theme/theme';
import { EActivityModule, IRecruitingListModel } from '../../definitions/entities.definition';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { translate } from '../../translation/translate.utils';
import DateTimeCellRenderer from './DateTimeCellRenderer';
import DateCellRenderer from './DateCellRenderer';
import { useDispatch } from 'react-redux';
import { setUiActivityAddDataAction, setUiActivityToEditAction } from '../../redux/ui/ui.actions';

interface IProps {
    recruiting: IRecruitingListModel;
}

const RecruitingInterviewsCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const recruiting = props.recruiting;
    const interviews = recruiting.interviews;

    return <div className={"flexContainerColumn alignRight gap5"}>
        {interviews.map((interview, index) => {
            const classNameAdd = index > 0 ? 'formContent' : '';
            const warning = interview.cancelled || interview.dateTime === 'MISSING';

            return <Link
                key={interview.id}
                onClick={() => {
                    if (interview.id === 0) {
                        dispatch(setUiActivityAddDataAction({
                            add: true,
                            module: EActivityModule.recruiting,
                            companyId: recruiting.company.id,
                            vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                            candidateId: recruiting.candidate.id,
                            recruitingId: recruiting.id,
                            preSelectedEmployee: recruiting.responsibleEmployee,
                            isInterview: true,
                            contactId: recruiting.nextInterviewContact?.id,
                            interviewType: recruiting.nextInterviewType
                        }));
                    }
                    dispatch(setUiActivityToEditAction(interview.id))
                }}
                className={"flexContainerRow alignVerticalCenter " + classNameAdd}
                style={warning ? {color: COLOR_RED} : {}}
            >
                {interview.cancelled &&
                <Tooltip title={"Interview wurde abgesagt"}>{IconFactory.getIconComponent(EIcons.Cancel, {width: 16, height: 16})}</Tooltip>
                }
                VG {interview.round}:&nbsp;
                {translate('pages.recruiting.interviewTypeValue.' + interview.type)}
                &nbsp;
                {index === 0 &&
                    <>{interview.dateTime === 'MISSING' ? <><br/>Terminierung notwendig</> : <DateTimeCellRenderer date={interview.dateTime}/>}</>
                }
                {index > 0 &&
                    <>{interview.dateTime === 'MISSING' ? <><br/>Terminierung notwendig</> : <DateCellRenderer date={interview.dateTime} hideDay/>}</>
                }
            </Link>
        })}
    </div>;
};

export default RecruitingInterviewsCellRenderer;
