import { ENewsType, INews, INewsGroup, IVacancy } from '../definitions/entities.definition';
import { translate } from '../translation/translate.utils';
import { themeGreen, themeGreenLight, themeOrange } from '../theme/theme';
import { EIcons } from '../components/Icons/IconFactory';
import _ from 'underscore';

export const getNewsText = (news: INews): string => {
    let translationParams: string[] = [];
    switch (news.newsType) {
        case ENewsType.NEWS_TYPE_CANDIDATE_BIRTHDAY:
            translationParams =[
                news.candidate ? news.candidate.firstName + ' ' + news.candidate.name : '--'
            ];
            break;
        case ENewsType.NEWS_TYPE_CONTACT_PERSON_BIRTHDAY:
            translationParams =[
                news.companyContact ? news.companyContact.firstName + ' ' + news.companyContact.name : '--'
            ];
            break;
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_TODAY:
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_IN_ONE_WEEK:
        case ENewsType.NEWS_TYPE_CANDIDATE_TRIAL_PERIOD_END:
            translationParams =[
                news.recruiting ? news.recruiting.candidate.firstName + ' ' + news.recruiting.candidate.name : '--',
                news.recruiting ? news.recruiting.vacancy?.title || '--' : '--',
                news.recruiting ? news.recruiting.company.name : '--'
            ];
            break;
        case ENewsType.NEWS_TYPE_VACANCY_OFFLINE:
            translationParams =[
                (news.vacancy as IVacancy).title
            ];
            break;
    }

    return translate('pages.news.typeValues.' + news.newsType, translationParams);
}

export const getNewsColor = (newsType: ENewsType): string => {
    switch (newsType) {
        case ENewsType.NEWS_TYPE_CANDIDATE_BIRTHDAY:
            return themeGreenLight.palette.primary.main;
        case ENewsType.NEWS_TYPE_CONTACT_PERSON_BIRTHDAY:
            return themeGreen.palette.primary.main;
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_TODAY:
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_IN_ONE_WEEK:
        case ENewsType.NEWS_TYPE_CANDIDATE_TRIAL_PERIOD_END:
            return themeOrange.palette.primary.main;
        case ENewsType.NEWS_TYPE_VACANCY_OFFLINE:
            return themeOrange.palette.primary.main;
    }
}

export const getNewsIcon = (newsType: ENewsType): EIcons => {
    switch (newsType) {
        case ENewsType.NEWS_TYPE_CANDIDATE_BIRTHDAY:
        case ENewsType.NEWS_TYPE_CONTACT_PERSON_BIRTHDAY:
            return EIcons.Cake;
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_TODAY:
        case ENewsType.NEWS_TYPE_CANDIDATE_STARTS_IN_ONE_WEEK:
        case ENewsType.NEWS_TYPE_CANDIDATE_TRIAL_PERIOD_END:
            return EIcons.Work;
        case ENewsType.NEWS_TYPE_VACANCY_OFFLINE:
            return EIcons.CloudOff
    }
}

export const groupNewsByDate = (newsList: INews[]): INewsGroup => {
    return _.groupBy(newsList, function (news) {
        return news.dateTime.substr(0, 10);
    });
}

export const groupNewsByType = (newsList: INews[]): INewsGroup => {
    return _.groupBy(newsList, function (news) {
        return news.newsType;
    });
}
