export interface INewsState {
    reloadNews: boolean;
    start: number;
    limit: number;
}

export const preloadedNewsState: INewsState = {
    reloadNews: false,
    start: 0,
    limit: 100
};
