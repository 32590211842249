import React from 'react';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from './CustomClickableIcon';
import { EIcons } from '../Icons/IconFactory';
import CustomTextField from './CustomTextField';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';

interface IProps extends Omit<OutlinedTextFieldProps, "variant"> {
    onChange?: (value: any) => void;
    value?: string;
}

const CustomMailField: React.FC<IProps> = (props) => {
    return (
        <CustomTextField
            error={props.error}
            helperText={props.helperText}
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            disabled={props.disabled}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CustomClickableIcon onClick={() => window.open('mailto:' + props.value)} icon={EIcons.EMail}/>
                    </InputAdornment>
                ),
            }}
        />
    )
};

export default CustomMailField;
