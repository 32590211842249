import { ESortingOptions } from '../../definitions/components.definitions';
import { IVacancyFilter } from '../../definitions/vacancyPage.definitions';
import {
    ISetVacancyPageAddSuggestionAction,
    ISetVacancyPageAddVacancyDialogAction,
    ISetVacancyPageCandidateSearchFilterAction,
    ISetVacancyPageCandidateSearchFilterMenuAction,
    ISetVacancyPageCandidateSearchSortingOptionAction,
    ISetVacancyPageCloseSuggestionListViewAction,
    ISetVacancyPageDeclineSuggestionAction,
    ISetVacancyPageDeleteSuggestionAction,
    ISetVacancyPageEditViewActivityListFilterAction,
    ISetVacancyPageEditViewActivitySortingOptionAction,
    ISetVacancyPageFastSearchAction,
    ISetVacancyPageFilterAction,
    ISetVacancyPageResetCandidateSearchFilterAction,
    ISetVacancyPageSortingOptionAction,
    ISetVacancyPageSuggestionListSortingOptionAction,
    ISetVacancyPageSuggestionListViewFilterAction,
    ISetVacancyPageSuggestionListViewFilterMenuAction,
    ISetVacancyPageSuggestionListViewSortingOptionAction,
    ISetVacancyPageVacancyToArchiveViewAction,
    ISetVacancyPageVacancyToCopyAction,
    ISetVacancyPageVacancyToDeleteViewAction,
} from '../../definitions/redux/actions.definition';
import { IApiBaseFilter } from '../../definitions/app.definitions';
import { ICandidateFilterApiProperties, ICandidateFilterMenu } from '../../definitions/candidatePage.definitions';
import {
    IAddSuggestionViewState,
    ISuggestionFilter,
    ISuggestionFilterMenu,
} from '../../definitions/recruitingPage.definitions';

export const VACANCY_PAGE_SET_FILTER = "VACANCY_PAGE_SET_FILTER";
export const VACANCY_PAGE_SET_SORTING_OPTION = "VACANCY_PAGE_SET_SORTING_OPTION";
export const VACANCY_PAGE_SET_VACANCY_FOR_ARCHIVE_VIEW = "VACANCY_PAGE_SET_VACANCY_FOR_ARCHIVE_VIEW";
export const VACANCY_PAGE_SET_VACANCY_FOR_DELETE_VIEW = "VACANCY_PAGE_SET_VACANCY_FOR_DELETE_VIEW";
export const VACANCY_PAGE_SET_VACANCY_FOR_COPY_VIEW = "VACANCY_PAGE_SET_VACANCY_FOR_COPY_VIEW";
export const VACANCY_PAGE_SET_FAST_SEARCH = "VACANCY_PAGE_SET_FAST_SEARCH";
export const VACANCY_PAGE_SET_ADD_VACANCY_DIALOG = "VACANCY_PAGE_SET_ADD_VACANCY_DIALOG";
export const VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION = "VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION";
export const VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER = "VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER";
export const VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DECLINE = "VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DECLINE";
export const VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DELETE = "VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DELETE";
export const VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_SORTING_OPTION = "VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_SORTING_OPTION";
export const VACANCY_PAGE_SET_EDIT_VIEW_RECRUITING_SORTING_OPTION = "VACANCY_PAGE_SET_EDIT_VIEW_RECRUITING_SORTING_OPTION";
export const VACANCY_PAGE_SET_EDIT_VIEW_APPLICATION_SORTING_OPTION = "VACANCY_PAGE_SET_EDIT_VIEW_APPLICATION_SORTING_OPTION";
export const VACANCY_PAGE_SET_ADD_SUGGESTION = "VACANCY_PAGE_SET_ADD_SUGGESTION";
export const VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER = "VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER";
export const VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU = "VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU";
export const VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING = "VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING";
export const VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER = "VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER";
export const VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_OPEN = "VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_OPEN";
export const VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER = "VACANCY_PAGE_OPEN_SUGGESTION_LIST_VIEW";
export const VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER_MENU = "VACANCY_PAGE_OPEN_SUGGESTION_LIST_VIEW_MENU";
export const VACANCY_PAGE_RESET_SUGGESTION_LIST_VIEW_FILTER = "VACANCY_PAGE_RESET_SUGGESTION_LIST_VIEW_FILTER";
export const VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER = "VACANCY_PAGE_OPEN_SUGGESTION_OF_VACANCY_LIST";
export const VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER_MENU = "VACANCY_PAGE_OPEN_SUGGESTION_OF_VACANCY_LIST_MENU";
export const VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_SORTING = "VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_SORTING";
export const VACANCY_PAGE_RESET_SUGGESTION_OF_VACANCY_LIST_FILTER = "VACANCY_PAGE_RESET_SUGGESTION_OF_VACANCY_LIST_FILTER";
export const VACANCY_PAGE_CLOSE_SUGGESTION_LIST_VIEW = "VACANCY_PAGE_CLOSE_SUGGESTION_LIST_VIEW";
export const VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_SORTING_OPTION = "VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_SORTING_OPTION";
export const VACANCY_PAGE_SET_RELOAD_VACANCY = "VACANCY_PAGE_SET_RELOAD_VACANCY";
export const VACANCY_PAGE_SET_SUGGESTION_TO_ADD_ACTIVITY = "VACANCY_PAGE_SET_SUGGESTION_TO_ADD_ACTIVITY";
export const VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_ACTIVITY_LIST_FILTER = "VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_ACTIVITY_LIST_FILTER";

export const setVacancyPageCandidateSearchFilterAction = (filter: Partial<ICandidateFilterApiProperties>): ISetVacancyPageCandidateSearchFilterAction => ({
    type: VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER,
    payload: filter
});

export const setVacancyPageReloadVacancyAction = () => ({
    type: VACANCY_PAGE_SET_RELOAD_VACANCY,
});

export const setVacancyPageCandidateSearchFilterMenuAction = (filter: Partial<ICandidateFilterMenu>): ISetVacancyPageCandidateSearchFilterMenuAction => ({
    type: VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU,
    payload: filter
});


export const resetVacancyPageCandidateSearchFilterAction = (): ISetVacancyPageResetCandidateSearchFilterAction => ({
    type: VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER,
});

export const setVacancyPageCandidateSearchSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetVacancyPageCandidateSearchSortingOptionAction => ({
    type: VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setVacancyPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetVacancyPageSortingOptionAction => ({
    type: VACANCY_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setVacancyPageFilterAction = (filter: Partial<IVacancyFilter>): ISetVacancyPageFilterAction => ({
    type: VACANCY_PAGE_SET_FILTER,
    payload: filter
});

export const setVacancyPageAddVacancyDialogAction = (open: boolean, companyId?: number): ISetVacancyPageAddVacancyDialogAction => ({
    type: VACANCY_PAGE_SET_ADD_VACANCY_DIALOG,
    payload: {
        open,
        companyId
    },
});

export const setVacancyPageFastSearchAction = (fastSearchValue: string): ISetVacancyPageFastSearchAction => ({
    type: VACANCY_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setVacancyPageVacancyToArchiveAction = (vacancyId: number): ISetVacancyPageVacancyToArchiveViewAction => ({
    type: VACANCY_PAGE_SET_VACANCY_FOR_ARCHIVE_VIEW,
    payload: vacancyId,
});

export const setVacancyPageVacancyToDeleteAction = (vacancyId: number): ISetVacancyPageVacancyToDeleteViewAction => ({
    type: VACANCY_PAGE_SET_VACANCY_FOR_DELETE_VIEW,
    payload: vacancyId,
});

export const setCopyVacancyViewAction = (vacancyId: number): ISetVacancyPageVacancyToCopyAction => ({
    type: VACANCY_PAGE_SET_VACANCY_FOR_COPY_VIEW,
    payload: vacancyId,
});

export const setVacancyPageActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetVacancyPageEditViewActivitySortingOptionAction => ({
    type: VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setVacancyPageActivityListFilterAction = (filter: Partial<IApiBaseFilter>): ISetVacancyPageEditViewActivityListFilterAction => ({
    type: VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER,
    payload: filter
});


export const setVacancyPageSuggestionActivityListFilterAction = (filter: Partial<IApiBaseFilter>): ISetVacancyPageEditViewActivityListFilterAction => ({
    type: VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_ACTIVITY_LIST_FILTER,
    payload: filter
});

export const setVacancyPageAddSuggestionAction = (addState: IAddSuggestionViewState): ISetVacancyPageAddSuggestionAction => ({
    type: VACANCY_PAGE_SET_ADD_SUGGESTION,
    payload: addState,
});

export const setVacancyPageSuggestionToDeclineAction = (suggestionId: number, followup: boolean): ISetVacancyPageDeclineSuggestionAction => ({
    type: VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DECLINE,
    payload: {
        id: suggestionId,
        followup
    },
});

export const setVacancyPageSuggestionToAddActivityAction = (suggestionId: number) => ({
    type: VACANCY_PAGE_SET_SUGGESTION_TO_ADD_ACTIVITY,
    payload: suggestionId,
});

export const setVacancyPageSuggestionToDeleteAction = (suggestionId: number): ISetVacancyPageDeleteSuggestionAction => ({
    type: VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DELETE,
    payload: suggestionId,
});

export const setVacancyPageSuggestionListViewOpenAction = (filter: ISuggestionFilter): ISetVacancyPageSuggestionListViewFilterAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_OPEN,
    payload: filter,
});

export const setVacancyPageSuggestionListOfVacancyFilterAction = (filter: Partial<ISuggestionFilter>): ISetVacancyPageSuggestionListViewFilterAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER,
    payload: filter,
});

export const setVacancyPageSuggestionListOfVacancyResetFilterAction = () => ({
    type: VACANCY_PAGE_RESET_SUGGESTION_OF_VACANCY_LIST_FILTER
})

export const setVacancyPageSuggestionListOfVacancySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetVacancyPageSuggestionListSortingOptionAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setVacancyPageSuggestionListViewFilterAction = (filter: ISuggestionFilter): ISetVacancyPageSuggestionListViewFilterAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER,
    payload: filter,
});

export const setVacancyPageSuggestionListViewFilterMenuAction = (filterMenu: ISuggestionFilterMenu): ISetVacancyPageSuggestionListViewFilterMenuAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER_MENU,
    payload: filterMenu,
});

export const setVacancyPageSuggestionListViewResetFilterAction = () => ({
    type: VACANCY_PAGE_RESET_SUGGESTION_LIST_VIEW_FILTER
})

export const setVacancyPageCloseSuggestionListViewAction = (): ISetVacancyPageCloseSuggestionListViewAction => ({
    type: VACANCY_PAGE_CLOSE_SUGGESTION_LIST_VIEW,
});

export const setVacancyPageSuggestionListViewSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetVacancyPageSuggestionListViewSortingOptionAction => ({
    type: VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});
