import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: number;
}

const DeclineApplicationMailVariantDropDown: React.FC<IProps> = (props) => {
    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value}
            id={"decline-application-variant-dropdown"}
            label={'Vorlage'}
        >
            <MenuItem value={0}>
                Vorlage 1
            </MenuItem>
            <MenuItem value={1}>
                Vorlage 2
            </MenuItem>
        </CustomDropDownInput>
    );
};

export default DeclineApplicationMailVariantDropDown;
