import { IEntitySkill, ISkillPartial, ISkillTreeNode } from '../definitions/entities.definition';

export const sortSkills = (skills: IEntitySkill[]) => {
    return skills.sort((a, b) => {
        if (a.importantSkill === b.importantSkill) {
            if (a.count === b.count) {
                return 0;
            }

            return a.count < b.count ? 1 : -1;
        }

        return b.importantSkill && !a.importantSkill ? 1 : -1;
    })
}

export const getIdsFromTree = (tree: ISkillTreeNode[]) => {
    const result: string[] = [];

    tree.forEach((node) => {
        result.push(node.id.toString());
        const children = node.children || [];
        result.push(...getIdsFromTree(children))
    });

    return result;
}

export const transformTreeNodeToSkill = (treeNode: ISkillTreeNode): ISkillPartial => {
    return {
        id: treeNode.id,
        name: treeNode.text,
        keywords: treeNode.keywords
    }
}
