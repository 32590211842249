import React, { useEffect, useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import {
    ECandidateSource,
    ICandidate,
    IEmployeePartial,
    ISuggestionForApi,
} from '../../../definitions/entities.definition';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { addSuggestionAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import CandidateAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CandidateSourceDropDown from '../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import { ClientApi } from '../../../requests/ClientApi';
import { getCandidateRouteConfig } from '../../../requests/routes';
import { getDayDifference } from '../../../utils/date.utils';

interface IProps {
    onClose: () => void;
}

const AddSuggestionForCandidateForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const initialCandidateId = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.candidateId);
    const initialVacancyId = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.vacancyId);

    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial|null>(loggedInEmployee as IEmployeePartial);
    const [candidateId, setCandidateId] = useState(initialCandidateId || null);
    const [vacancyId, setVacancyId] = useState(initialVacancyId || null);
    const [source, setSource] = useState(ECandidateSource.brainz);
    const [errors, setErrors] = useState<{vacancy?: string, company?: string, companyLocation?: string, candidateId?: string, responsibleEmployee?: string, webVacancy?: string}>({});

    useEffect(() => {
        if (!candidateId) {
            return;
        }
        ClientApi.request(getCandidateRouteConfig, {
            token,
            candidateId
        }).then((candidate: ICandidate) => {
            if (getDayDifference(candidate.created) <= 30) {
                setSource(candidate.source);
                return;
            }
            setSource(ECandidateSource.brainz);
        });
    }, [candidateId]);

    const validateEmployee = (): boolean => {
        if (!responsibleEmployee) {
            setErrors({
                responsibleEmployee: 'This value should not be blank'
            });
            return false;
        }
        return true;
    }

    const validateVacancy = (): boolean => {
        if (!vacancyId) {
            setErrors({
                vacancy: 'This value should not be blank'
            });
            return false;
        }
        return true;
    }

    const onSave = () => {
        setErrors({});

        if (!candidateId) {
            setErrors({
                candidateId: 'This value should not be blank'
            });
            return
        }

        if (validateEmployee() && validateVacancy() && vacancyId && responsibleEmployee) {
            const suggestion: ISuggestionForApi = {
                suggestedByEmployeeId: loggedInEmployee.id,
                candidateId: candidateId,
                vacancyId: vacancyId,
                responsibleEmployeeId: responsibleEmployee.id,
                source
            };
            dispatch(addSuggestionAction(
                token,
                suggestion
            ));
            props.onClose();
        }
    }

    return (
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
            <PaperRow>
                <VacancyAutocomplete
                    value={vacancyId}
                    required
                    error={Boolean(errors.vacancy)}
                    helperText={errors.vacancy}
                    onChange={(value) => {
                        if (value === null) {
                            setVacancyId(null);
                            return;
                        }
                        setVacancyId(value.id);
                    }}
                />
            </PaperRow>
            <PaperRow>
                <CandidateAutocomplete
                    value={candidateId || null}
                    required
                    error={Boolean(errors.candidateId)}
                    helperText={errors.candidateId}
                    onChange={(candidate) => {
                        if (candidate) {
                            setCandidateId(candidate.id);
                            return;
                        }
                        setCandidateId(0);
                    }}
                />
                <CandidateSourceDropDown
                    value={source}
                    onChange={(event) => setSource(event.target.value)}
                />
            </PaperRow>
            <PaperRow>
                <EmployeeAutocomplete
                    required
                    label={translate('misc.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(value) => setResponsibleEmployee(
                        value
                    )}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </div>
    );
};

export default AddSuggestionForCandidateForm;
