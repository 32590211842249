import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ERecruitingAbortedBy } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ERecruitingAbortedBy;
    error?: boolean;
    helperText?: string;
    emptyValue?: string;
}

const RecruitingAbortedByDropDown: React.FC<IProps> = (props) => {
    const abortedByValues: ERecruitingAbortedBy[] = [
        ERecruitingAbortedBy.CANDIDATE,
        ERecruitingAbortedBy.COMPANY
    ];
    let menuItems = abortedByValues.map((value: ERecruitingAbortedBy, index: number) => (
        <MenuItem key={index + 1} value={value}>
            {translate('misc.' + value)}
        </MenuItem>
    ));
    menuItems = [
        <MenuItem key={0} value={0}>
            {props.emptyValue || translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value ?? 0}
            error={props.error}
            helperText={props.helperText}
            id={"recruiting-aborted-by-dropdown"}
            label={translate('pages.recruiting.abortedBy')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default RecruitingAbortedByDropDown;
