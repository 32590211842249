import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    resetCandidatePageFieldsForIgnoreValidation,
    setCandidatePageAddDialogAction,
} from '../../../redux/candidatePage/candidatePage.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { resetFormValidation } from '../../../redux/error/error.actions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import CandidateProfileDataPanel from '../../EditViews/Candidate/components/CandidateProfileDataPanel';
import { ICandidateForm, initialCandidateFormState } from '../../../definitions/candidatePage.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { createApiObject } from '../../../utils/candidate.utils';
import { addCandidateAction } from '../../../redux/entities/entities.actions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { useCreateCandidateHook } from './CandidatAddView.hooks';

const CandidateAddView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => store.candidatePage.addDialogOpen);
    const editViewLoading = useSelector((store: IStore) => store.candidatePage.editViewLoading);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);

    const [candidateForm, setCandidateForm] = useState<ICandidateForm>({
        ...initialCandidateFormState,
        responsible: loggedInEmployee
    });
    const {matchingSuggestions, duplicateCandidates} = useCreateCandidateHook(candidateForm.name, candidateForm.firstName);

    const setCandidateFormPartial = (candidateFormChange: Partial<ICandidateForm>) => {
        setCandidateForm({
            ...candidateForm,
            ...candidateFormChange
        })
    }

    const onSave = () => {
        dispatch(addCandidateAction(token, createApiObject(candidateForm, fieldsIgnoredForValidation)));
    }

    const onClose = () => {
        dispatch(resetFormValidation());
        dispatch(setCandidatePageAddDialogAction(false));
    };

    useEffect(() => {
        dispatch(resetCandidatePageFieldsForIgnoreValidation());
        dispatch(resetFormValidation());
        setCandidateForm({
            ...initialCandidateFormState,
            responsible: loggedInEmployee
        });
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <CustomDialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
            <CustomBackdropLoading open={editViewLoading} />
            <CustomDialogHeader translationString="pages.candidate.add" onClose={onClose} />
            <div style={{ marginLeft: 10, marginRight: 5, height: "100%", overflow: "auto", backgroundColor: 'var(--brainz-darker)' }}>
                <CandidateProfileDataPanel
                    addView
                    duplicateCandidates={duplicateCandidates}
                    matchingSuggestions={matchingSuggestions}
                    candidate={candidateForm}
                    setCandidate={setCandidateFormPartial}
                />
            </div>
            <CustomDialogActions
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.create')
                }]}
            />
        </CustomDialog>
    );
};

export default CandidateAddView;
