import React, { memo } from 'react';
import { Badge, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { IWebVacancyListModel } from '../../definitions/entities.definition';
import UrlCellRenderer from './UrlCellRenderer';

interface IProps {
    webVacancy: IWebVacancyListModel;
}

const WebVacancyTitleCellRenderer: React.FC<IProps> = (props) => {
    const webVacancy = props.webVacancy;
    const countFurtherLocations = webVacancy.locations.length - webVacancy.matchedLocations.length;

    return <>
        <div className={"flex"} style={webVacancy.vacancies.length > 1 ? {alignItems: 'center'} : {}}>
            {webVacancy.hide &&
                <Tooltip title={"Stelle ist nicht freigegeben"}>
                    <div className={"flex flexCentered flexAutoGrow paddingRight5"}>
                        {IconFactory.getIconComponent(EIcons.PanTool)}
                    </div>
                </Tooltip>
            }
            {webVacancy.vacancies.length === 1 &&
                <Tooltip title={"Stelle ist bereits importiert"}>
                    <div className={"flex flexCentered flexAutoGrow paddingRight5"}>
                        {IconFactory.getIconComponent(EIcons.Work)}
                    </div>
                </Tooltip>
            }
            {webVacancy.vacancies.length > 1 &&
                <Tooltip title={"Stelle ist bereits importiert"}>
                    <div className={"flex flexCentered flexAutoGrow"} style={{height: 45, paddingRight: 10}}>
                        <Badge badgeContent={webVacancy.vacancies.length} color={"primary"}>
                            {IconFactory.getIconComponent(EIcons.Work)}
                        </Badge>
                    </div>
                </Tooltip>
            }
            <div className={"flexColumn flexAutoGrow"}>
                <UrlCellRenderer value={webVacancy.title} url={webVacancy.url} />
                {(webVacancy.matchedLocations.length > 0) &&
                <div className={"formContent"}>
                    {webVacancy.matchedLocations.map((location) => location.city).join(', ')}
                    { (countFurtherLocations > 0) &&
                    <>
                        <br/> ({countFurtherLocations } weitere Standorte nicht im Radius)
                    </>
                    }
                </div>
                }
            </div>
        </div>
    </>;
};

export default memo(WebVacancyTitleCellRenderer);
