import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageTopSuggestionsViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { getSuggestionsWithTopRatingsAction } from '../../../redux/entities/entities.actions';
import TopSuggestionsTable from './tables/TopSuggestionsTable';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { buildRequestObject } from '../../../selectors/entity.selectors';

const TopSuggestionsView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const open = useSelector((store: IStore) => store.dashboardPage.topSuggestionsViewOpen);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingTopSuggestions);
    const reload = useSelector((store: IStore) => store.vacancyPage.reloadSuggestions);

    useEffect(() => {
        if (open) {
            dispatch(getSuggestionsWithTopRatingsAction(token, loggedInEmployee.id, buildRequestObject(sorting)));
        }
    }, [dispatch, open, reload, JSON.stringify(sorting)]);

    const handleClose = () => dispatch(setDashboardPageTopSuggestionsViewOpenAction(false));

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader translationString={"pages.dashboard.openTopSuggestions"} onClose={handleClose} />
                <TopSuggestionsTable />
            </CustomDialog>
        </>
    );
};

export default TopSuggestionsView;
