import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import { IInvoice } from '../definitions/entities.definition';

export const getBillingPageInvoiceListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.billingPage.sortingInvoiceList;
    const filter = store.billingPage.filterInvoiceList;

    return buildRequestObject(sorting, filter);
};

export const getRecruitingPageInvoiceListRequestObject = (): Partial<IGetRequestObject> => {
    return buildRequestObject({}, {
        start: 0,
        limit: 0
    });
};

export const getCompanyPageInvoiceListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.companyPage.invoiceSorting;
    const filter = store.companyPage.invoiceFilter;

    return buildRequestObject(sorting, filter);
};

export const getInvoiceListFromStore = (store: IStore) => {
    const order = store.entities.invoice.order;
    const invoicesById = store.entities.invoice.byId;

    return order.reduce((accumulator: IInvoice[], invoiceId) => {
        accumulator.push(invoicesById[invoiceId]);

        return accumulator;
    }, []);
};

export const getInvoiceListOfRecruitingFromStore = (store: IStore, recruitingId: number) => {
    const invoicesById = store.entities.invoice.byId;
    const invoiceIdsByRecruiting = store.entities.invoice.byRecruiting[recruitingId] || [];

    const invoices: IInvoice[] = [];

    invoiceIdsByRecruiting.forEach((recruitingId) => invoices.push(invoicesById[recruitingId]));

    return invoices;
};

export const getInvoiceListOfCompanyFromStore = (store: IStore) => {
    const companyId = store.companyPage.companyToEdit;
    const invoicesById = store.entities.invoice.byId;
    const invoiceIdsByRecruiting = store.entities.invoice.byCompany[companyId] || [];

    const invoices: IInvoice[] = [];

    invoiceIdsByRecruiting.forEach((recruitingId) => invoices.push(invoicesById[recruitingId]));

    return invoices;
};
