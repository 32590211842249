import { useCallback, useEffect, useState } from 'react';
import { ICandidateListModel, ISuggestionListModel } from '../../../definitions/entities.definition';
import { getToken } from '../../../selectors/app.selectors';
import { useSelector } from 'react-redux';
import { debounce } from '@material-ui/core';
import { ClientApi } from '../../../requests/ClientApi';
import { getCandidateDuplicatesRouteConfig } from '../../../requests/routes';

export const useCreateCandidateHook = (name: null|string, firstName: null|string) => {
    const token = useSelector(getToken);
    const [duplicateCandidates, setDuplicateCandidates] = useState<ICandidateListModel[]>([]);
    const [matchingSuggestions, setMatchingSuggestions] = useState<ISuggestionListModel[]>([]);

    const changeHandler = (callable: () => void) => {
        callable();
    }
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 500), []
    );

    useEffect(() => {
        if (!name || !firstName) {
            setDuplicateCandidates([]);
            setMatchingSuggestions([]);
            return;
        }

        debouncedChangeHandler(() => doRequest(name, firstName))
    }, [name, firstName]);

    const doRequest = (name: string, firstName: string) => {
        ClientApi.request(getCandidateDuplicatesRouteConfig, {
            token,
            firstName,
            name
        }).then((result: {candidatesWithSameName: ICandidateListModel[], matchingSuggestions: ISuggestionListModel[]}) => {
            setDuplicateCandidates(result.candidatesWithSameName);
            setMatchingSuggestions(result.matchingSuggestions);
            return;
        });
    }

    return {matchingSuggestions, duplicateCandidates}
}
