import { ESortingOptions } from '../../definitions/components.definitions';
import {
    ISetWebVacancyEditViewActiveTabAction,
    ISetWebVacancyPageCandidateSearchFilterAction,
    ISetWebVacancyPageCandidateSearchFilterMenuAction,
    ISetWebVacancyPageCandidateSearchSortingOptionAction,
    ISetWebVacancyPageEditViewActivityFastSearchAction,
    ISetWebVacancyPageEditViewActivitySortingOptionAction,
    ISetWebVacancyPageFastSearchAction,
    ISetWebVacancyPageFilterAction,
    ISetWebVacancyPageFilterMenuAction,
    ISetWebVacancyPageIdToImportAction,
    ISetWebVacancyPageSortingOptionAction,
} from '../../definitions/redux/actions.definition';
import {
    EWebVacancyEditViewTabs,
    IWebVacancyFilter,
    IWebVacancyFilterMenu,
} from '../../definitions/webVacancyPage.definitions';
import { ICandidateFilterApiProperties, ICandidateFilterMenu } from '../../definitions/candidatePage.definitions';

export const WEB_VACANCY_PAGE_SET_FILTER = "WEB_VACANCY_PAGE_SET_FILTER";
export const WEB_VACANCY_PAGE_SET_SORTING_OPTION = "WEB_VACANCY_PAGE_SET_SORTING_OPTION";
export const WEB_VACANCY_PAGE_SET_FAST_SEARCH = "WEB_VACANCY_PAGE_SET_FAST_SEARCH";
export const WEB_VACANCY_PAGE_SET_ID_TO_IMPORT = "WEB_VACANCY_PAGE_SET_ID_TO_IMPORT";
export const WEB_VACANCY_PAGE_SET_FILTER_MENU = "WEB_VACANCY_PAGE_SET_FILTER_MENU";
export const WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION = "WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION";
export const WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH = "WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH";
export const WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER = "WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER";
export const WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU = "WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU";
export const WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING = "WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING";
export const WEB_VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER = "WEB_VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER";
export const WEB_VACANCY_PAGE_SET_ACTIVE_TAB_OF_EDIT_WEB_VACANCY_VIEW = "WEB_VACANCY_PAGE_SET_ACTIVE_TAB_OF_EDIT_WEB_VACANCY_VIEW";

export const setWebVacancyEditViewActiveTabAction = (tab: EWebVacancyEditViewTabs): ISetWebVacancyEditViewActiveTabAction => ({
    type: WEB_VACANCY_PAGE_SET_ACTIVE_TAB_OF_EDIT_WEB_VACANCY_VIEW,
    payload: tab,
});

export const setWebVacancyPageActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetWebVacancyPageEditViewActivitySortingOptionAction => ({
    type: WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setWebVacancyPageActivityFastSearchAction = (fastSearchValue?: string): ISetWebVacancyPageEditViewActivityFastSearchAction => ({
    type: WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH,
    payload: fastSearchValue
});

export const setWebVacancyPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetWebVacancyPageSortingOptionAction => ({
    type: WEB_VACANCY_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setWebVacancyPageCandidateSearchFilterAction = (filter: Partial<ICandidateFilterApiProperties>): ISetWebVacancyPageCandidateSearchFilterAction => ({
    type: WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER,
    payload: filter
});

export const setWebVacancyPageCandidateSearchFilterMenuAction = (filter: Partial<ICandidateFilterMenu>): ISetWebVacancyPageCandidateSearchFilterMenuAction => ({
    type: WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU,
    payload: filter
});

export const setWebVacancyPageCandidateSearchSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetWebVacancyPageCandidateSearchSortingOptionAction => ({
    type: WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setWebVacancyPageFilterAction = (filter: Partial<IWebVacancyFilter>): ISetWebVacancyPageFilterAction => ({
    type: WEB_VACANCY_PAGE_SET_FILTER,
    payload: filter,
});

export const setWebVacancyPageFilterMenuAction = (filter: Partial<IWebVacancyFilterMenu>): ISetWebVacancyPageFilterMenuAction => ({
    type: WEB_VACANCY_PAGE_SET_FILTER_MENU,
    payload: filter
});

export const setWebVacancyPageFastSearchValueAction = (fastSearchValue: string): ISetWebVacancyPageFastSearchAction => ({
    type: WEB_VACANCY_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setWebVacancyPageIdToImportAction = (webVacancyId: number): ISetWebVacancyPageIdToImportAction => ({
    type: WEB_VACANCY_PAGE_SET_ID_TO_IMPORT,
    payload: webVacancyId,
});
