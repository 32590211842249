import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ECandidateSource, TExternalCandidateSource } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ECandidateSource;
    validValues?: ECandidateSource[];
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    size?: 'small'|'medium';
    label?: string;
}

const PortalDropDown: React.FC<IProps> = (props) => {
    const sources: TExternalCandidateSource[] = [
        ECandidateSource.linkedIn,
        ECandidateSource.xing,
        ECandidateSource.jobcenter,
        ECandidateSource.freeLancerMap,
    ];
    let menuItems = (props.validValues ? props.validValues : sources).map((source: TExternalCandidateSource, index: number) => (
        <MenuItem key={index + 1} value={source}>
            {translate('pages.vacancy.externalCandidateSourceValues.' + source)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            size={props.size}
            onChange={props.onChange}
            value={props.value || 0}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            id={"source-type-dropdown"}
            label={props.label || translate('misc.source')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default PortalDropDown;
