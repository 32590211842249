import React from 'react';
import CustomTextField from './CustomTextField';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from './CustomClickableIcon';
import { EIcons } from '../Icons/IconFactory';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';

interface IProps extends Omit<OutlinedTextFieldProps, "variant"> {
    onChange: (value?: number) => void;
    value?: number;
    label: string;
    required?: boolean;
    max?: number;
    autoFocus?: boolean;
    suffix?: string;
    onClear?: () => void;
    width?: number;
}

const CustomNumberInput: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTextField
                {...props}
                id={"number-input" + props.label}
                type={"number"}
                required={props.required}
                onChange={(value: number) => {
                    if (props.max && value > props.max) {
                        return;
                    }
                    if (!value) {
                        props.onChange(undefined);
                        return;
                    }
                    props.onChange(value);
                }}
                autoFocus={props.autoFocus}
                value={props.value}
                label={props.label}
                InputProps={{
                    startAdornment: props.onClear ? <InputAdornment style={{ color: "var(--brainz-color-text-secondary)" }} position="end">
                        <CustomClickableIcon
                            onClick={props.onClear}
                            icon={EIcons.Close}
                        />
                    </InputAdornment> : undefined ,
                    endAdornment: props.suffix ? <InputAdornment style={{ color: "var(--brainz-color-text-secondary)" }} position="end">
                        {props.suffix}</InputAdornment> : undefined
                }}
            />
        </>
    );
};

export default CustomNumberInput;
