import React from 'react';
import { ISuggestionListModel } from '../../../definitions/entities.definition';
import { Button, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import CustomContextMenu from '../../../components/CustomContextMenu/CustomContextMenu';
import { IListActionButtonConfig } from '../../../definitions/components.definitions';
import { updateSuggestionAndReloadListAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import SuggestionRating from './SuggestionRating';

interface IProps {
    suggestion: ISuggestionListModel;
    rankingOptions?: {id: number, rating: number, ranking: number, fullName: string}[];
}

const SuggestionListRankingActionButton: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const suggestion = props.suggestion;
    const rankingOptions = props.rankingOptions;

    if (!rankingOptions) {
        return null;
    }

    const menuItems = rankingOptions.map((rankingOption): IListActionButtonConfig => {
        return {
            label: <div className={"flexContainerRow"}>
                <SuggestionRating rating={rankingOption.rating} />&nbsp;
                {rankingOption.ranking + '. ' + rankingOption.fullName}
            </div>,
            action: () => dispatch(updateSuggestionAndReloadListAction(
                token, {
                    id: suggestion.id,
                    ranking: rankingOption.ranking
                }
            ))
        }
    })

    return <>
        <CustomContextMenu
            index={suggestion.id}
            entityId={suggestion.id}
            entity={suggestion}
            menuItems={menuItems}
            variant={'onClick'}
            menuHeading={(entity) => {
                const entityCast = entity as ISuggestionListModel;
                return entityCast.fullName + ' auf folgenden Rang verschieben';
            }}
        >
            <Tooltip title={"Ranking ändern öffnen"}>
                <Button variant="contained" color="secondary">
                    {IconFactory.getIconComponent(EIcons.FormatListNumbered)}
                </Button>
            </Tooltip>
        </CustomContextMenu>
    </>;
}

export default React.memo(SuggestionListRankingActionButton);
