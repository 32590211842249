import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import CustomDropDownInput from './CustomDropDownInput';
import { ETermsOfPayment } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ETermsOfPayment;
    label: string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const TermsOfPaymentDropDown: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomDropDownInput
                id={"terms-of-payment-dropdown"}
                required={props.required}
                onChange={props.onChange}
                value={props.value}
                label={props.label}
                error={props.error}

                helperText={props.helperText}
            >
                <MenuItem key={1} value={1}>
                    14 Tage
                </MenuItem>
                <MenuItem key={2} value={2}>
                    21 Tage
                </MenuItem>
            </CustomDropDownInput>
        </>
    );
};

export default TermsOfPaymentDropDown;
