import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: (value: string|number) => void;
    value?: string|number;
    values: { label: string, value: string|number }[],
    label: string;
    allowEmpty?: boolean;
}

const ValueDropDown: React.FC<IProps> = (props) => {
    let menuItems = props.values.map((valueLabel, index: number) => (
        <MenuItem key={index + 1} value={valueLabel.value}>
            { valueLabel.label }
        </MenuItem>
    ));

    if (props.allowEmpty) {
        menuItems = [
            <MenuItem key={0} value={0}>
                {translate('misc.choose')}
            </MenuItem>,
            ...menuItems,
        ];
    }

    return (
        <CustomDropDownInput
            id={"value-dropdown" + props.label}
            onChange={(event) => {
                props.onChange(event.target.value);
            }}
            value={props.value || 0}
            label={props.label}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default ValueDropDown;
