import React from 'react';
import CandidateCellRenderer from '../CustomCellRenderer/CandidateCellRenderer';
import CompanyCellRenderer from '../CustomCellRenderer/CompanyCellRenderer';
import VacancyCellRenderer from '../CustomCellRenderer/VacancyCellRenderer';
import EmployeeCellRenderer from '../CustomCellRenderer/EmployeeCellRenderer';
import {
    ICandidate,
    ICandidatePartial,
    ICompany,
    ICompanyPartial,
    IEmployee,
    IEmployeePartial,
    IRecruiting,
    IRecruitingPartial,
    ISuggestion,
    ISuggestionPartial,
    IVacancy,
    IVacancyPartial,
    IWebVacancy,
} from '../../definitions/entities.definition';
import RecruitingCellRenderer from '../CustomCellRenderer/RecruitingCellRenderer';
import WebVacancyCellRenderer from '../CustomCellRenderer/WebVacancyCellRenderer';
import SuggestionCellRenderer from '../CustomCellRenderer/SuggestionCellRenderer';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { darker } from '../../theme/theme';
import { getFormattedDateString } from '../../utils/date.utils';

interface IProps {
    candidate?: ICandidate|ICandidatePartial;
    company?: ICompany|ICompanyPartial;
    vacancy?: IVacancy|IVacancyPartial;
    employee?: IEmployee|IEmployeePartial;
    recruiting?: IRecruiting|IRecruitingPartial;
    recruitingSuggestion?: ISuggestion|ISuggestionPartial;
    webVacancy?: IWebVacancy;
    created?: string;
}

const CustomCellTopBar: React.FC<IProps> = (props) => {
    return (
        <div className={"flex gap5"}>
            {props.candidate &&
                <><CandidateCellRenderer candidate={props.candidate}/></>
            }
            {props.company &&
                <><CompanyCellRenderer company={props.company}/></>
            }
            {props.vacancy &&
            <>
                <div style={{maxWidth: 200}}>
                    <VacancyCellRenderer vacancy={props.vacancy}/>
                </div>
            </>
            }
            {props.webVacancy &&
                <>
                    <div className={"flexContainerColumn ellipseText"} style={{maxWidth: 200}}>
                        <WebVacancyCellRenderer id={props.webVacancy.id} title={props.webVacancy.title} />
                    </div>
                </>
            }
            {props.recruitingSuggestion &&
                <><SuggestionCellRenderer recruitingSuggestion={props.recruitingSuggestion}/></>
            }
            {props.recruiting &&
                <><RecruitingCellRenderer recruiting={props.recruiting}/></>
            }
            {props.employee &&
                <EmployeeCellRenderer employee={props.employee}/>
            }
            {props.created &&
            <Tooltip title={"Erstellt am"} placement="top-start">
                <Chip
                    icon={IconFactory.getIconComponent(EIcons.Calendar, {color: darker})}
                    color={"default"}
                    label={getFormattedDateString(props.created)}
                />
            </Tooltip>
            }
        </div>
    )
};

export default CustomCellTopBar;
