import { useEffect, useState } from 'react';
import { getLocationsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getCompanyPageRequestObjectForCompanyLocationsInEditView } from '../../../../../selectors/company.selectors';
import { getCompanyLocationsForCompanyEditViewFromStore } from '../../../../../selectors/companyLocation.selectors';

export const useCompanyLocationListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyLocationsLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForCompanyLocationsInEditView);
    const sorting = useSelector((store: IStore) => store.companyPage.companyLocationSorting);
    const locations = useSelector((store: IStore) => getCompanyLocationsForCompanyEditViewFromStore(store, companyId));

    useEffect(() => {
        dispatch(getLocationsForCompanyAction(token, companyId, requestObject));
        setInitialLoading(false);
    }, [companyId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getLocationsForCompanyAction(token, companyId, requestObject));
        }
    }, [JSON.stringify(sorting)]);

    return {loading, locations, total: locations.length};
}
