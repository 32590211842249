import {
    IGetDashboardPageEmployeeData,
    IGetRecruitingEvaluationDataAction,
    IGetTargetMonthListAction,
    ISetDashboardPageBirthdayCandidatesViewOpen,
    ISetDashboardPageBirthdayCompanyContactsViewOpen,
    ISetDashboardPageChartSelectionRecruitingsViewOpen,
    ISetDashboardPageFilterAction,
    ISetDashboardPageGoalBurnUpChartSettings,
    ISetDashboardPagePieChartsGroupedByCommission,
    ISetDashboardPageRecruitingsOfLast7DaysViewOpen,
    ISetDashboardPageSetChartSelection,
    ISetDashboardPageSortingOptionAction,
    ISetDashboardPageTopSuggestionsViewOpen,
    ISetDashboardPageYourAppointmentsViewOpen,
    ISetDashboardPageYourRecruitingsViewOpen,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    EDashboardPageTable,
    IDashboardPageState,
    preloadedStateDashboardPage,
} from '../../definitions/dashboardPage.definitions';
import {
    DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED,
    DASHBOARD_PAGE_SET_BIRTHDAY_CANDIDATES_VIEW_OPEN,
    DASHBOARD_PAGE_SET_BIRTHDAY_COMPANY_CONTACTS_VIEW_OPEN,
    DASHBOARD_PAGE_SET_CHART_SELECTION,
    DASHBOARD_PAGE_SET_CHART_SELECTION_RECRUITINGS_VIEW_OPEN,
    DASHBOARD_PAGE_SET_FILTER,
    DASHBOARD_PAGE_SET_GOAL_BURN_UP_CHART_SETTINGS,
    DASHBOARD_PAGE_SET_PIE_CHARTS_GROUPED_BY_COMMISSION,
    DASHBOARD_PAGE_SET_RECRUITINGS_OF_LAST_7_DAYS_VIEW_OPEN,
    DASHBOARD_PAGE_SET_SORTING,
    DASHBOARD_PAGE_SET_TOP_SUGGESTIONS_VIEW_OPEN,
    DASHBOARD_PAGE_SET_YOUR_APPOINTMENTS_VIEW_OPEN,
    DASHBOARD_PAGE_SET_YOUR_RECRUITINGS_VIEW_OPEN,
} from './dashboardPage.actions';
import {
    EGroupEvaluationGroupBy,
    ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_FULFILLED,
    ENTITIES_GET_TARGET_BY_MONTH_LIST_FULFILLED,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED,
} from '../entities/entities.actions';
import { UI_SET_SHOW_ONLY_MINE } from '../ui/ui.actions';

const dashboardPageReducer = (state = preloadedStateDashboardPage, action: TReducerAction): IDashboardPageState => {
    switch (action.type) {
        case DASHBOARD_PAGE_SET_SORTING: {
            const actionCast = action as ISetDashboardPageSortingOptionAction;

            let sortingKey = '';
            switch (actionCast.payload.table) {
                case EDashboardPageTable.topFavorites:
                    sortingKey = 'sortingTopSuggestions';
                    break;
                case EDashboardPageTable.yourRecruitings:
                    sortingKey = 'sortingEmployeeRecruitings';
                    break;
                case EDashboardPageTable.openApplications:
                    sortingKey = 'sortingOpenApplications';
                    break;
                case EDashboardPageTable.candidateBirthdays:
                    sortingKey = 'sortingCandidateBirthdays';
                    break;
                case EDashboardPageTable.recruitingsOfLast7Days:
                    sortingKey = 'sortingRecruitingOfLast7Days';
                    break;
                case EDashboardPageTable.contactPersonBirthdays:
                    sortingKey = 'sortingContactPersonBirthdays';
                    break;
            }

            return {
                ...state,
                [sortingKey]: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                }
            }
        }
        case DASHBOARD_PAGE_SET_FILTER: {
            const actionCast = action as ISetDashboardPageFilterAction;

            return {
                ...state,
                filterRecruitingOfLast7Days: actionCast.payload.filter
            }
        }
        case DASHBOARD_PAGE_SET_CHART_SELECTION: {
            const actionCast = action as ISetDashboardPageSetChartSelection;
            return {
                ...state,
                chartSelection: actionCast.payload,
            };
        }
        case DASHBOARD_PAGE_SET_TOP_SUGGESTIONS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageTopSuggestionsViewOpen;
            return {
                ...state,
                topSuggestionsViewOpen: actionCast.payload,
            };
        }
        case DASHBOARD_PAGE_SET_CHART_SELECTION_RECRUITINGS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageChartSelectionRecruitingsViewOpen;
            return {
                ...state,
                chartSelectionRecruitingsViewOpen: actionCast.payload,
            };
        }
        case DASHBOARD_PAGE_SET_GOAL_BURN_UP_CHART_SETTINGS: {
            const actionCast = action as ISetDashboardPageGoalBurnUpChartSettings;

            return {
                ...state,
                goalBurnUpChartSettings: actionCast.payload
            }
        }
        case DASHBOARD_PAGE_SET_RECRUITINGS_OF_LAST_7_DAYS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageRecruitingsOfLast7DaysViewOpen;
            return {
                ...state,
                recruitingsOfLast7DaysViewOpen: actionCast.payload,
            };
        }
        case DASHBOARD_PAGE_SET_BIRTHDAY_CANDIDATES_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageBirthdayCandidatesViewOpen;
            return {
                ...state,
                candidateBirthdayViewOpen: actionCast.payload,
            };
        }

        case DASHBOARD_PAGE_SET_BIRTHDAY_COMPANY_CONTACTS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageBirthdayCompanyContactsViewOpen;
            return {
                ...state,
                companyContactsBirthdayViewOpen: actionCast.payload,
            };
        }

        case DASHBOARD_PAGE_SET_YOUR_APPOINTMENTS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageYourAppointmentsViewOpen;
            return {
                ...state,
                yourTodayAppointmentsViewOpen: actionCast.payload,
            };
        }

        case DASHBOARD_PAGE_SET_YOUR_RECRUITINGS_VIEW_OPEN: {
            const actionCast = action as ISetDashboardPageYourRecruitingsViewOpen;
            return {
                ...state,
                yourRecruitingsViewOpen: actionCast.payload,
            };
        }
        case UI_SET_SHOW_ONLY_MINE: {
            return {
                ...state,
                reloadData: true
            }
        }
        case DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED: {
            const actionCast = action as IGetDashboardPageEmployeeData;

            return {
                ...state,
                employeeData: actionCast.payload,
                reloadData: false,
            };
        }
        case ENTITIES_GET_TARGET_BY_MONTH_LIST_FULFILLED: {
            const actionCast = action as IGetTargetMonthListAction;

            return {
                ...state,
                target: actionCast.payload
            }
        }
        case ENTITIES_GET_RECRUITING_GROUPED_EVALUATION_FULFILLED: {
            const actionCast = action as IGetRecruitingEvaluationDataAction;
            let index = '';

            switch (actionCast.meta.groupBy) {
                case EGroupEvaluationGroupBy.GROUP_BY_COMPANY:
                    index = 'byCompany';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_EMPLOYEE:
                    index = 'byEmployee';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_MONTH:
                    index = 'byMonth';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_MONTH_SUMMED_UP:
                    index = 'byMonthSummedUp';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_CANDIDATE_SOURCE:
                    index = 'byCandidateSource';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_BRANCH:
                    index = 'byBranch';
                    break;
            }

            if (actionCast.meta.lastYear) {
                return {
                    ...state,
                    groupedEvaluationDataLastYear: {
                        ...state.groupedEvaluationDataLastYear,
                        [index]: actionCast.payload
                    }
                }
            }

            return {
                ...state,
                groupedEvaluationData: {
                    ...state.groupedEvaluationData,
                    [index]: actionCast.payload
                }
            }
        }

        case ENTITIES_POST_RECRUITING_FULFILLED:
        case ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED: {
            return {
                ...state,
                reloadData: true,
            };
        }
        case DASHBOARD_PAGE_SET_PIE_CHARTS_GROUPED_BY_COMMISSION: {
            const actionCast = action as ISetDashboardPagePieChartsGroupedByCommission;
            return {
                ...state,
                contractsGroupedByCommission: actionCast.payload
            }
        }
    }
    return state;
};

export default dashboardPageReducer;
