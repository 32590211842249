import { TextField } from '@material-ui/core';
import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';

const CustomDumbTextField: React.FC<TextFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            variant={"outlined"}
            autoComplete={"off"}
            role={"presentation"}
            InputProps={{
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                role: 'presentation',
                ...props.InputProps,
            }}
        />
    );
};

export default CustomDumbTextField;
