import React from 'react';

interface IProps {
    value: string;
    label: string;
}

const CustomValueElement: React.FC<IProps> = (props) => {
    return (
        <div className={"formRowColumn"} style={{marginLeft: 5, marginTop: 5}}>
            <div style={{fontSize: 12}}>{props.label}:&nbsp;</div>
            <div style={{padding: 5}}>{props.value}</div>
        </div>
    );
}

export default CustomValueElement;
