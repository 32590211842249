import { IStore } from '../definitions/redux/store.definitions';
import { IApplication, IApplicationGroup } from '../definitions/entities.definition';
import { buildRequestObject } from './entity.selectors';
import _ from 'underscore';
import { ESortingOptions } from '../definitions/components.definitions';

export const groupApplicationsByStatus = (applications: IApplication[]): IApplicationGroup => {
    return _.groupBy(applications, function (application) {
        return application.status;
    });
}

export const getApplicationsFromStore = (store: IStore): IApplication[] => {
    const order = store.entities.applications.order;
    const applicationsById = store.entities.applications.byId;

    return order.reduce((accumulator: IApplication[], activityId) => {
        accumulator.push(applicationsById[activityId]);

        return accumulator;
    }, []);
};

export const getApplicationsRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.applicationPage.sorting,
        store.applicationPage.filter
    );
};

export const getApplicationsForVacancyFromStore = (store: IStore, id: number) => {
    const applicationsById = store.entities.applications.byId;
    const idsByVacancyId = store.entities.applications.byVacancy[id] || [];

    const applications: IApplication[] = [];

    idsByVacancyId.forEach((suggestionId) => applications.push(applicationsById[suggestionId]));

    return applications;
};

export const getApplicationsForCompanyFromStore = (store: IStore, id: number) => {
    const applicationsById = store.entities.applications.byId;
    const idsByVacancyId = store.entities.applications.byCompany[id] || [];

    const applications: IApplication[] = [];

    idsByVacancyId.forEach((suggestionId) => applications.push(applicationsById[suggestionId]));

    return applications;
};

export const getApplicationsForCandidateFromStore = (store: IStore, id: number) => {
    const applicationsById = store.entities.applications.byId;
    const idsByVacancyId = store.entities.applications.byCandidate[id] || [];

    const applications: IApplication[] = [];

    idsByVacancyId.forEach((suggestionId) => applications.push(applicationsById[suggestionId]));

    return applications;
};

export const getVacancyPageRequestObjectForApplicationsInEditView = (store: IStore) => {
    const sorting = store.vacancyPage.applicationSorting;
    const filter = { start: 0, limit: 0, fastSearch: ""};

    return buildRequestObject(sorting, filter);
};

export const getCompanyPageRequestObjectForApplicationsInEditView = (store: IStore) => {
    const sorting = store.companyPage.applicationSorting;
    const filter = { start: 0, limit: 0, fastSearch: ""};

    return buildRequestObject(sorting, filter);
};

export const getCandidatePageRequestObjectForApplicationsInEditView = (store: IStore) => {
    const sorting = {created: ESortingOptions.Descending};
    const filter = { start: 0, limit: 0, fastSearch: ""};

    return buildRequestObject(sorting, filter);
};
