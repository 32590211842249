import React, { useEffect, useState } from 'react';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import {
    EActivityModule,
    IActivity,
    ICandidate,
    ICompany,
    ISuggestion,
    IVacancy,
} from '../../../definitions/entities.definition';
import { createPortal } from 'react-dom';
import RecruitingCellRenderer from '../../../components/CustomCellRenderer/RecruitingCellRenderer';
import moment from 'moment';
import { Button } from '@material-ui/core';
import SuggestionCellRenderer from '../../../components/CustomCellRenderer/SuggestionCellRenderer';
import AssignMailView from '../../App/RightDrawer/Mailing/AssignMailView';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import CompanyCellRenderer from '../../../components/CustomCellRenderer/CompanyCellRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { setUiActivityToDeleteAction } from '../../../redux/ui/ui.actions';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import { ClientApi } from '../../../requests/ClientApi';
import { deleteMailAsReadRouteConfig } from '../../../requests/routes';
import { registerError } from '../../../redux/error/error.actions';
import { getToken } from '../../../selectors/app.selectors';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getMailAction } from '../../../redux/entities/entities.actions';
import { setMailingDetailViewClosedAction } from '../../../redux/mailing/mailing.actions';

interface IProps {
    onClose: () => void;
    mailId: number;
    reload?: (mailId: number) => void;
}

const ActivityDisplayMailboxMail: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const mail = useSelector((store: IStore) => store.entities.mail.currentRecord)
    const loading = useSelector((store: IStore) => store.mailing.loading);
    const forceReload = useSelector((store: IStore) => store.mailing.reload);

    useEffect(() => {
        if (props.mailId !== 0 && (!mail || mail.id !== props.mailId) && !loading) {
            dispatch(getMailAction(token, props.mailId));
        }
    }, [props.mailId])

    useEffect(() => {
        if (forceReload && !loading) {
            dispatch(getMailAction(token, props.mailId));
        }
    }, [forceReload])

    const displayAssignedActivities = (assignedActivities: IActivity[]) => {
        const elements: React.ReactElement[] = [];

        assignedActivities.map((activity, index) => {
            const onDelete = () => {
                dispatch(setUiActivityToDeleteAction(activity.id, true));
            }

            if (activity.module === EActivityModule.recruiting) {
                elements.push(
                    <div style={{paddingLeft: 5, paddingBottom: 5}}>
                        <RecruitingCellRenderer
                            recruiting={activity.recruiting}
                            onDelete={onDelete}
                            showCandidateName
                        /> &nbsp;
                    </div>
                );
                return;
            }

            if (activity.module === EActivityModule.recruitingSuggestion) {
                elements.push(
                    <div style={{paddingLeft: 5, paddingBottom: 5}}>
                        <SuggestionCellRenderer
                            recruitingSuggestion={activity.recruitingSuggestion as ISuggestion}
                            onDelete={onDelete}
                        /> &nbsp;
                    </div>
                );
                return;
            }

            if (activity.module === EActivityModule.candidate) {
                elements.push(
                    <div style={{paddingLeft: 5, paddingBottom: 5}}>
                        <CandidateCellRenderer
                            candidate={activity.candidate as ICandidate}
                            onDelete={onDelete}
                        /> &nbsp;
                    </div>
                );
                return;
            }

            if (activity.module === EActivityModule.company) {
                elements.push(
                    <div style={{paddingLeft: 5, paddingBottom: 5}}>
                        <CompanyCellRenderer
                            company={activity.company as ICompany}
                            onDelete={onDelete}
                        /> &nbsp;
                    </div>
                );
                return;
            }

            if (activity.module === EActivityModule.vacancy) {
                elements.push(
                    <div style={{paddingLeft: 5, paddingBottom: 5}}>
                        <VacancyCellRenderer
                            vacancy={activity.vacancy as IVacancy}
                            onDelete={onDelete}
                        /> &nbsp;
                    </div>
                );
                return;
            }
        });

        return elements;
    }

    const [assignMail, setAssignMail] = useState(0);
    const [openDeleteView, setOpenDeleteView] = useState(false);

    if (!mail) {
        return null;
    }

    const onClose = () => {
        dispatch(setMailingDetailViewClosedAction());
        props.onClose();
    }
    const elements = (mail && props.reload) ? displayAssignedActivities(mail.assignedActivities) : [];
    return (
        <>
            {props.reload &&
                <AssignMailView
                    mailId={assignMail}
                    open={assignMail > 0}
                    onClose={() => setAssignMail(0)}
                    reload={props.reload}
                />
            }
            <div style={{display: 'flex'}}>
                <div style={{padding: 5, display: 'flex', flex:1}}>
                    Von: {mail.from}&nbsp;{<CustomClickableIcon onClick={() => navigator.clipboard.writeText(mail.from)} icon={EIcons.Copy} />}
                </div>
                <div style={{padding: 5, alignItems: 'flex-end'}}>
                    {(moment(new Date(mail.dateTime))).format("DD.MM.Y H:mm:ss")}
                </div>
                &nbsp;
                {props.reload &&
                    <>
                        <CustomClickableIcon onClick={() => setOpenDeleteView(true)} icon={EIcons.Delete} />
                        <CustomClickableIcon onClick={onClose} icon={EIcons.Close} />
                        <CustomDeleteDialog
                            open={openDeleteView}
                            onClose={() => setOpenDeleteView(false)}
                            onDelete={() => {
                                ClientApi.request(deleteMailAsReadRouteConfig, {
                                    token,
                                    id: mail.id
                                }).then(() => {
                                    //@ts-ignore-next-line
                                    props.reload();
                                }).catch(() => {
                                    dispatch(registerError('Error while Deleting'));
                                })
                            }}
                            titleTranslation={"pages.mailing.deleteDialog.title"}
                            bodyTranslation={"pages.mailing.deleteDialog.body"}
                        />
                    </>
                }
                <div style={{paddingRight: 15}}>&nbsp;</div>
            </div>
            <div style={{padding: 5, display: 'flex'}}>
                An: {mail.to}&nbsp;{<CustomClickableIcon onClick={() => navigator.clipboard.writeText(mail.to)} icon={EIcons.Copy} />}
            </div>
            {props.reload && elements.length === 0 &&
                <div style={{padding:5}}>
                    <Button variant="outlined" color={"inherit"} size={"small"} onClick={() => setAssignMail(mail.id)}>Verknüpfung hinzufügen</Button>
                </div>
            }
            {props.reload && elements.length > 0 &&
                <>
                    <div style={{display: 'flex'}}>
                        <div style={{padding: 5, display: 'flex', flex:1}}>Verknüpfungen</div>
                        <div style={{padding: 5, paddingRight: 25, alignItems: 'flex-end'}}>
                            <Button variant="outlined" color={"inherit"} size={"small"} onClick={() => setAssignMail(mail.id)}>Verknüpfung hinzufügen</Button>
                        </div>
                    </div>
                    {elements}
                </>
            }
            <div style={{padding: 5}}>
                Betreff: {mail.subject}
            </div>
            <FunctionalIFrameComponent>
                <div dangerouslySetInnerHTML={{__html: mail.message as string}}/>
            </FunctionalIFrameComponent>
            <CustomDialogActions
                onClose={onClose}
            />
        </>
    );
}

//@ts-ignore
export const FunctionalIFrameComponent = ({children, ...props}) => {
    const [contentRef, setContentRef] = useState(null)
    //@ts-ignore
    const mountNode = contentRef?.contentWindow?.document?.body

    return (
        //@ts-ignore
        <iframe {...props} ref={setContentRef} style={{display: 'flex', overflow: 'auto', flex:1, width: '100%', height: '100%', border: 'none', background: '#ddd'}}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}


export default ActivityDisplayMailboxMail;
