import React, { useCallback, useEffect, useState } from 'react';
import { themeGreen } from '../../../theme/theme';
import { EEntities, ICandidateListModel } from '../../../definitions/entities.definition';
import CustomInfo from '../../../components/CustomComponent/CustomInfo';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import {
    setCandidatePageCandidateToDeleteAction,
    setCandidatePageFilterAction,
    setCandidatePageSortingOptionAction,
} from '../../../redux/candidatePage/candidatePage.actions';
import { INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { ESortingOptions, ITableHeaderCallbacks, TListAction } from '../../../definitions/components.definitions';
import CustomTable, { ICustomTableConfig } from '../../../components/CustomTable/CustomTable';
import { getDefaultColumnConfigForCandidateTable } from '../../../utils/candidateTable.utils';
import {
    getCandidateListAction,
    getCandidateListAppendAction,
    updateCandidateAction,
} from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getAllCandidatesFromStore, getCandidatePageRequestObject } from '../../../selectors/candidate.selectors';
import { getToken } from '../../../selectors/app.selectors';
import { EIcons } from '../../../components/Icons/IconFactory';
import {
    setRecruitingPageAddSuggestionAction,
    setRecruitingPageStartRecruitingAction,
} from '../../../redux/recruitingPage/recruitingPage.actions';
import { isOnlyShowMineModeActive } from '../../../selectors/employee.selectors';

const emptyText = <>
    <CustomInfo>
        <>Falls du es noch nicht wusstest, du kannst den gesamten Text jedes Lebenslaufes über die Schnellsuche durchsuchen.<br/> Du hast dabei auch die Möglichkeit nach mehreren Wörtern zu suchen in dem du sie mit AND oder OR verbindest.<br/><br/>Beispiele:<br/>
            - AND: React AND PHP<br/>
            - OR: (React OR Angular) <br/>
            - VERSCHACHTELTE ABFRAGE: (React OR Angular) AND PHP</>
    </CustomInfo>
</>;

const CandidateList: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const count = useSelector((store: IStore) => store.entities.candidates.order.length);
    const total = useSelector((store: IStore) => store.entities.candidates.total);
    const reload = useSelector((store: IStore) => store.candidatePage.reloadList);
    const sortedEntries = useSelector(getAllCandidatesFromStore);
    const start = useSelector((store: IStore) => store.candidatePage.filter.start || 0);
    const filter = useSelector((store: IStore) => store.candidatePage.filter);
    const optionalSkills = useSelector((store: IStore) => store.candidatePage.filterMenu.optionalSkills);
    const sorting = useSelector((store: IStore) => store.candidatePage.sorting);
    const initialLoaded = useSelector((store: IStore) => store.candidatePage.initialCandidatesLoaded);
    const onlyShowMine = useSelector(isOnlyShowMineModeActive);

    const [initialRendered, setInitialRendered] = useState(false);

    const loading = useSelector((store: IStore) => store.ui.candidatesLoading);
    const requestObject = useSelector((store: IStore) => getCandidatePageRequestObject(store, false));
    const requestObjectAppend = useSelector((store: IStore) => getCandidatePageRequestObject(store, true));

    const loadCandidates = (append: boolean) => {
        if (loading) {
            return;
        }
        if (append) {
            dispatch(getCandidateListAppendAction(token, requestObjectAppend));
            return;
        }
        dispatch(getCandidateListAction(token, requestObject));
    }

    useEffect(() => {
        if (start === 0) {
            return;
        }
        loadCandidates(true);
    }, [start]);

    useEffect(() => {
        if (!initialLoaded || !initialRendered) {
            return;
        }
        loadCandidates(false);
    }, [JSON.stringify(filter), JSON.stringify(sorting), onlyShowMine]);

    useEffect(() => {
        if (!initialLoaded) {
            loadCandidates(false);
        }
    }, [initialLoaded]);

    useEffect(() => {
        setInitialRendered(true);
    }, []);

    const columnConfig = getDefaultColumnConfigForCandidateTable(
        token,
        filter,
        (candidate) => {
            dispatch(updateCandidateAction(token, candidate));
        },
        false,
        optionalSkills
    )

    const onRowDblClick: TListAction = (id, entity) => {
        const candidate = entity as ICandidateListModel;
        dispatch(setUiEditView(EEntityView.candidate, candidate.id));
    };

    const scrollCallback: VoidFunction = useCallback(() => {
        if (start !== undefined && start < total) {
            dispatch(setCandidatePageFilterAction({
                start: start + INFINITE_SCROLL_INTERVAL,
                limit: INFINITE_SCROLL_INTERVAL,
            }));
        }
    }, [start, total]);

    const tableHeaderCallbacks: ITableHeaderCallbacks = {
        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
            dispatch(setCandidatePageSortingOptionAction(propertyToSort, sortOption))
    }

    const onReload = useCallback(() => {
        dispatch(getCandidateListAction(token, requestObject));
    }, [JSON.stringify(requestObject)])

    const config: ICustomTableConfig = {
        denyVerticalCenter: true,
        color: themeGreen.palette.primary.main,
        entity: EEntities.Candidate,
        columnConfig: columnConfig,
        emptyText: emptyText,
        sortedEntries: sortedEntries,
        loading: loading,
        onRowDoubleClick: onRowDblClick,
        scrollCallback: scrollCallback,
        tableHeaderCallbacks: tableHeaderCallbacks,
        onReload: onReload,
        count: count,
        total: total,
        listActionHeading: (entity) => {
            const entityCast = entity as ICandidateListModel;
            return entityCast.fullName;
        },
        listActionButtons: [{
            action: (candidateId) => dispatch(setRecruitingPageAddSuggestionAction({
                open: true,
                candidateId: candidateId
            })),
            translationKey: 'pages.candidate.addCandidateAsFavorite',
            icon: EIcons.Star,
        }, {
            action: (candidateId) => dispatch(setRecruitingPageStartRecruitingAction({
                open: true,
                candidateId: candidateId
            })),
            translationKey: 'pages.vacancy.startRecruiting',
            icon: EIcons.ThumbsUpDown,
        }, {
            action: (candidateId) => dispatch(setCandidatePageCandidateToDeleteAction(candidateId)),
            translationKey: 'pages.candidate.deleteDialog.title',
            icon: EIcons.Delete,
        }]
    }
    return <>
        <CustomTable config={config}/>
    </>
}

export default CandidateList;
