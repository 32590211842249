import React, { memo } from 'react';
import { IEmployee, IEmployeePartial } from '../../definitions/entities.definition';
import { Avatar, Tooltip } from '@material-ui/core';
import styles from './EmployeeCellRenderer.module.css';

interface IProps {
    employee: IEmployee | IEmployeePartial;
    extraTooltip?: string;
    size?: 'small'|'medium';
}

const EmployeeCellRenderer: React.FC<IProps> = (props) => {
    const size = props.size || 'medium';
    const mediumStyle = {fontSize: 12, width: 34, height: 34};
    const smallStyle = {fontSize: 12, width: 24, height: 24}

    return (
        <Tooltip title={(props.extraTooltip || '') + props.employee.firstName + ' ' + props.employee.name} placement="top-start">
            <Avatar
                className={styles['employeeChip' + props.employee.id]}
                style={size === 'medium' ? mediumStyle : smallStyle}
            >
                {props.employee.firstName.charAt(0).toUpperCase() + props.employee.name.charAt(0).toUpperCase()}
            </Avatar>
        </Tooltip>
    );
};

export default memo(EmployeeCellRenderer);
