import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import { IWebVacancyListModel } from '../definitions/entities.definition';

export const getWebVacancyListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.webVacancyPage.sorting;
    const filter = store.webVacancyPage.filter;

    return buildRequestObject(sorting, filter);
};

export const getWebVacancyListFromStore = (store: IStore) => {
    const order = store.entities.webVacancy.order;
    const webVacancyById = store.entities.webVacancy.listModelById;

    return order.reduce((accumulator: IWebVacancyListModel[], webVacancyId) => {
        accumulator.push(webVacancyById[webVacancyId]);

        return accumulator;
    }, []);
};

export const getWebVacancyListOfCompanyFromStore = (store: IStore, companyId: number) => {
    const webVacanciesByCompany = store.entities.webVacancy.byCompany[companyId];
    const webVacancies = store.entities.webVacancy.listModelById;

    const returnWebVacancies: IWebVacancyListModel[] = [];

    if (!webVacanciesByCompany) {
        return [];
    }

    webVacanciesByCompany.forEach((companyId) => returnWebVacancies.push(webVacancies[companyId]));

    return returnWebVacancies;
};

