import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getJobTemplateNameListAction } from '../../../../redux/entities/entities.actions';
import { getJobTemplateNameListFromStore } from '../../../../selectors/jobTemplate.selectors';

export const useLoadJobTemplates = () => {
    const token = useSelector(getToken);
    const jobTemplatesLoaded = useSelector((store: IStore) => store.ui.initialLoaded.jobTemplates);
    const jobTemplates = useSelector(getJobTemplateNameListFromStore);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!jobTemplatesLoaded) {
            dispatch(getJobTemplateNameListAction(token));
        }
    }, [jobTemplatesLoaded, token, dispatch]);

    return jobTemplates ;
}
