import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { useCallback, useEffect, useState } from 'react';
import { executeLoadRecordsRequest } from './Autocomplete.utils';


export const useMultipleAutocompleteHooks = (open: boolean, values: TBrainzEntity[]|number[], routeParams: object, routeConfig: IRouteConfig) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TBrainzEntity[]>([]);
    const [selectedValue, setSelectedValue] = useState<TBrainzEntity[]>([]);
    const valueIsEntity = values.length > 0 && values[0].constructor && values[0].constructor.name === 'Object';

    const loadData = useCallback(() => {
        executeLoadRecordsRequest(token, setLoading, setRecords, routeConfig, routeParams);
    }, [JSON.stringify(routeParams)]);

    useEffect(() => {
        if (open && !loading && records.length === 0) {
            loadData();
        }
    }, [open, token]);

    useEffect(() => {
        if (valueIsEntity) {
            return;
        }
        if (values.length > 0 && !loading) {
            loadData();
        }
    }, [values]);

    const fillSelectedValue = () => {
        const selectedValues: TBrainzEntity[] = [];
        values.forEach((value) => {
            const foundValue = records.find((option: TBrainzEntity) => value === option.id);
            if (foundValue) {
                selectedValues.push(foundValue);
            }
        })

        setSelectedValue(selectedValues);
    };

    useEffect(() => {
        if (valueIsEntity) {
            return;
        }
        if (values.length === 0) {
            setSelectedValue([]);
            return;
        }

        if (records.length > 0) {
            fillSelectedValue();
        }
    }, [values]);

    useEffect(() => {
        if (valueIsEntity) {
            return;
        }
        if (values.length > 0 && records.length > 0) {
            fillSelectedValue();
        }
    }, [values, records]);

    if (valueIsEntity) {
        const valueCast = values as TBrainzEntity[];
        return {
            selectedValue: valueCast,
            records,
            loading
        };
    }

    return {
        selectedValue: selectedValue,
        records,
        loading
    };
}
