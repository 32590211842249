import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageChartSelectionRecruitingsViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { getRecruitingsForChartSelectionAction } from '../../../redux/entities/entities.actions';
import ChartSelectionRecruitingList from './tables/ChartSelectionRecruitingList';
import { translate } from '../../../translation/translate.utils';

const ChartSelectionRecruitingsView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const open = useSelector((store: IStore) => store.dashboardPage.chartSelectionRecruitingsViewOpen);
    const handleClose = () => dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(false));
    const chartSelection = useSelector((store: IStore) => store.dashboardPage.chartSelection);
    const year = useSelector((store: IStore) => store.dashboardPage.chartYear);

    useEffect(() => {
        if (open) {
            dispatch(getRecruitingsForChartSelectionAction(token, chartSelection, year));
        }
    }, [dispatch, open]);

    if (!open) {
        return null;
    }

    let title = '';
    if (chartSelection.month) {
        title += translate('misc.month.' + chartSelection.month) + ' ' + year;
    } else {
        title += year;
    }
    if (chartSelection.companyName) {
        title += ' mit Firma ' + chartSelection.companyName;
    }
    if (chartSelection.employeeName) {
        title += ' von Mitarbeiter ' + chartSelection.employeeName;
    }
    if (chartSelection.branchName) {
        title += ' mit Branche ' + chartSelection.branchName;
    }
    if (chartSelection.candidateSource) {
        title += ' mit Kandidaten-Quelle ' + translate('pages.vacancy.externalCandidateSourceValues.' + chartSelection.candidateSource);
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader string={translate('pages.dashboard.recruitingsOf', [
                    title,
                ])} onClose={handleClose} />
                <ChartSelectionRecruitingList />
            </CustomDialog>
        </>
    );
};

export default ChartSelectionRecruitingsView;
