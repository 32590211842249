import React from 'react';
import {
    EEntities,
    ERecruitingType,
    ICandidate,
    IEmployee,
    IRecruiting,
    IRecruitingListModel,
    IVacancy,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import CustomTable from '../../../components/CustomTable/CustomTable';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { dark } from '../../../theme/theme';
import YesNoCellRenderer from '../../../components/CustomCellRenderer/YesNoCellRenderer';
import { ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import { useDispatch } from 'react-redux';
import { getDefaultCompanyColumn } from '../../../utils/components.utils';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';

interface IProps {
    recruitings: IRecruiting[];
    candidateOrCompany: 'candidate'|'company';
}

const RecruitingPreviewList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const columnConfig: ICustomTableColumnConfig = {
        finishedTrialPeriod: {
            header: 'Probez.',
            property: "finishedTrialPeriod",
            width: 60,
            cellRenderer: (finished: null|boolean, recruiting) => {
                if ((recruiting as IRecruiting).type === ERecruitingType.TYPE_DEFAULT) {
                    return <YesNoCellRenderer value={finished} />
                }

                return null;
            },
        },
        contractBeginningDate: {
            header: translate('pages.recruiting.contractBeginningDateShort'),
            property: "contractBeginningDate",
            width: 100,
            cellRenderer: (date: string) => {
                if (date) {
                    return (<DateCellRenderer date={date} />)
                }
                return translate('misc.noInformation');
            },
        },
        candidate: {
            header: translate('pages.activity.properties.candidate'),
            property: "candidate",
            width: 150,
            cellRenderer: (candidate: ICandidate) => (
                <CandidateCellRenderer candidate={candidate}/>
            ),
        },
        company: getDefaultCompanyColumn(),
        vacancy: {
            header: translate('pages.activity.properties.vacancy'),
            property: "vacancy",
            width: 150,
            cellRenderer: (vacancy: null | IVacancy) => {
                if (vacancy) {
                    return <VacancyCellRenderer vacancy={vacancy} />;
                }

                return null;
            },
        },
        responsibleEmployee: {
            header: translate('misc.employeeShort'),
            property: "responsibleEmployee",
            cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                const recruitingCast = recruiting as IRecruiting;

                if (recruitingCast.suggestedBy && recruitingCast.suggestedBy.id !== responsibleEmployee.id) {
                    return (
                        <>
                            <div className={"flex flexCentered"}>
                                <EmployeeCellRenderer employee={responsibleEmployee} />
                                <h1 style={{margin:0,fontWeight: "normal"}}>&nbsp;/&nbsp;</h1>
                                <EmployeeCellRenderer employee={recruitingCast.suggestedBy} />
                            </div>
                        </>
                    )
                }

                return (<EmployeeCellRenderer employee={responsibleEmployee} />);
            },
        }
    };

    if (props.candidateOrCompany === 'company') {
        delete columnConfig.company;
    }

    if (props.candidateOrCompany === 'candidate') {
        delete columnConfig.candidate;
    }

    return (
        <>
            <CustomTable
                style={{
                    maxHeight: 300
                }}
                config={{
                    entity: EEntities.Recruiting,
                    color: dark,
                    columnConfig,
                    sortedEntries: props.recruitings,
                    onRowDoubleClick: (id, entity) => {
                        const recruiting = entity as IRecruitingListModel;
                        dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
                    },
                    count: props.recruitings.length,
                    total: props.recruitings.length,
                }}
            />
        </>
    );
}

export default RecruitingPreviewList;
