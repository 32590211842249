import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../components/CustomTypography/CustomTypography';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { Dialog } from '@material-ui/core';
import { bulkUpdateVacancyInvoiceCreationVariantAction } from '../../redux/entities/entities.actions';
import { translate } from '../../translation/translate.utils';

interface IProps {
    open: boolean;
    companyId: number;
    onClose: () => void;
}

const CompanyInvoiceCreationVariantChangedView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!props.open) {
        return null;
    }

    const recalculateVacancies = () => {
        dispatch(bulkUpdateVacancyInvoiceCreationVariantAction(token, {
            companyId: props.companyId,
        }, props.onClose));
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CustomDialogHeader translationString={'pages.vacancy.invoiceCreationVariantChangedTitle'} onClose={props.onClose} />
            <CustomDialogBody>
                <CustomTypography translation={'pages.vacancy.invoiceCreationVariantChangedBody'} />
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    void: recalculateVacancies,
                    label: translate('pages.vacancy.recalculateOpen')
                }, {
                    void: props.onClose,
                    label: translate('misc.doNothing')
                }, ]}
            />
        </Dialog>
    );
};

export default CompanyInvoiceCreationVariantChangedView;
