import React from 'react';
import { IEmployee, IEmployeePartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import EmployeeAutocomplete from '../components/EmployeeAutocomplete';
import EmployeeMultipleAutocomplete from '../components/EmployeeMultipleAutocomplete';

interface IProps {
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: number|IEmployee | IEmployee[] | IEmployeePartial[] | IEmployeePartial;
    disabled?: boolean;
    label?: string;
    multiple?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const LegacyEmployeeAutocompleteDropDown: React.FC<IProps> = (props) => {
    if (props.multiple) {
        return (
            <EmployeeMultipleAutocomplete
                error={props.error}
                disabled={props.disabled}
                helperText={props.helperText}
                required={props.required}
                //@ts-ignore
                value={props.value === undefined  ? null : props.value}
                onChange={(value) => {
                    //@ts-ignore
                    props.onChange({}, value);
                }}
                label={props.label || translate('misc.employee')}
                getOptionLabel={(employee: IEmployee) => `${employee.firstName} ${employee.name}`}
            />
        );
    }

    return <>
        <EmployeeAutocomplete
            error={props.error}
            disabled={props.disabled}
            helperText={props.helperText}
            required={props.required}
            onChange={(value) => {
                //@ts-ignore
                props.onChange({}, value);
            }}
            //@ts-ignore
            value={props.value === undefined ? null : props.value}
            label={props.label || translate('misc.employee')}
        />
    </>
};

export default LegacyEmployeeAutocompleteDropDown;
