import React, { useEffect, useState } from 'react';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import {
    EActivityModule,
    EActivityType,
    EApplicationStatus,
    ICandidateDocument,
    IEmployee,
} from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    setApplicationPageDeclineAction,
    setApplicationPageEditAction,
} from '../../../redux/applicationPage/applicationPage.actions';
import CustomCellTopBar from '../../../components/ListAndPreview/CustomCellTopBar';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import ApplicationActivityList from './ApplicationActivityList';
import { getApplicationPageActivitiesRequestObject } from '../../../selectors/activity.selectors';
import { addActivityAction, getActivityListForApplicationAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import TopBarButton from '../../../components/ListAndPreview/TopBarButton';
import { EIcons } from '../../../components/Icons/IconFactory';
import moment from 'moment';
import TopBarButtonPanelWithPadding from '../../../components/ListAndPreview/TopBarButtonPanelWithPadding';
import { setUiActivityAddDataAction } from '../../../redux/ui/ui.actions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { setRecruitingPageStartRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomValueElement from '../../../components/CustomInput/CustomValueElement';
import SalutationDropDown from '../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import CustomMailField from '../../../components/CustomInput/CustomMailField';
import { ClientApi } from '../../../requests/ClientApi';
import { API_URL, getCandidateDocumentsForCandidateRouteConfig } from '../../../requests/routes';
import { Link } from '@material-ui/core';
import { inspectApplication } from '../../../utils/application.utils';

const EditApplicationView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => Boolean(store.applicationPage.applicationToEdit));
    const applicationId = useSelector((store: IStore) => store.applicationPage.applicationToEdit);
    const application = useSelector((store: IStore) => store.entities.applications.byId[applicationId]);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const activityRequestObject = useSelector(getApplicationPageActivitiesRequestObject);
    const reloadActivities = useSelector((store: IStore) => store.ui.reloadActivityGrids)

    const [responsibleEmployee, setResponsibleEmployee] = useState(application?.responsibleEmployee);
    const [documents, setDocuments] = useState([]);

    const onClose = () => {
        dispatch(setApplicationPageEditAction(0));
    }

    useEffect(() => {
        if (!application) {
            return;
        }

        setResponsibleEmployee(application.responsibleEmployee);
        ClientApi.request(getCandidateDocumentsForCandidateRouteConfig, {
            token,
            candidateId: application.candidate.id
        }).then((result: ICandidateDocument[]) => {
            setDocuments(result);
        });
    }, [application]);

    useEffect(() => {
        if (applicationId === 0) {
            return;
        }
        dispatch(getActivityListForApplicationAction(
            token,
            applicationId,
            activityRequestObject
        ));
    }, [applicationId])

    useEffect(() => {
        if (applicationId === 0 || !reloadActivities) {
            return;
        }
        dispatch(getActivityListForApplicationAction(
            token,
            applicationId,
            activityRequestObject
        ));
    }, [reloadActivities])

    if (!open) {
        return null;
    }

    if (!application) {
        return <CustomBackdropLoading open={true} />;
    }

    return (
        <CustomDialog
            fullWidth
            onClose={() => onClose()}
            open={open}
        >
            <CustomDialogHeader
                translationString={"pages.application.edit"}
                onClose={() => onClose()}
            >
                <CustomCellTopBar
                    candidate={application.candidate}
                    company={application.company}
                    vacancy={application.vacancy}
                    employee={application.responsibleEmployee}
                    recruiting={application.recruiting}
                />
            </CustomDialogHeader>
            <CustomDialogBody>
                <PaperRow>
                    <LegacyEmployeeAutocompleteDropDown
                        label={translate('pages.vacancy.properties.responsibleEmployee')}
                        value={responsibleEmployee}
                        onChange={(event, value: IEmployee) => setResponsibleEmployee(value)}
                    />
                    <CustomValueElement
                        value={translate('pages.application.statusValue.' + application.status)}
                        label={translate('pages.recruiting.status')}
                    />
                </PaperRow>
                <CustomDialogActions
                    onClose={() => onClose()}
                    buttons={[{
                        label: translate('pages.candidate.application'),
                        hidden: application.status !== EApplicationStatus.open && application.status !== EApplicationStatus.inspection,
                        options: [{
                            action: () => {
                                dispatch(setRecruitingPageStartRecruitingAction({
                                    open: true,
                                    candidateId: application.candidate.id,
                                    vacancyId: application.vacancy?.id,
                                    applicationId: applicationId
                                }));
                            },
                            label: translate("pages.application.accept"),
                            icon: EIcons.ThumbUp,
                            hidden: application.status === EApplicationStatus.open || application.status === EApplicationStatus.inspection
                        }, {
                            action: () => inspectApplication(applicationId),
                            label: translate("pages.application.inspect"),
                            icon: EIcons.Feedback,
                            hidden: application.status === EApplicationStatus.open
                        }, {
                            action: () => dispatch(setApplicationPageDeclineAction(applicationId)),
                            label: translate("pages.application.decline"),
                            icon: EIcons.ThumbDown,
                            hidden: application.status === EApplicationStatus.open || application.status === EApplicationStatus.inspection
                        }]
                    }, {
                        void: () => {
                            onClose();
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
                <PaperRow>
                    <h2>Kandidaten-Informationen</h2>
                </PaperRow>
                <PaperRow>
                    <SalutationDropDown
                        value={application.candidate.salutation}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.firstName}
                        label={translate('pages.candidate.properties.firstName')}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.name}
                        label={translate('pages.candidate.properties.name')}
                        disabled
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        value={application.candidate.zipCode}
                        label={translate('misc.zipCode')}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.city}
                        label={translate('misc.city')}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.address}
                        label={translate('misc.address')}
                        disabled
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        type="date"
                        label={translate('pages.candidate.properties.birthDate')}
                        value={application.candidate.birthDate}
                        disabled
                    />
                    <CustomMailField
                        value={application.candidate.mailAddress}
                        label={translate('pages.candidate.properties.email')}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.phoneNumber}
                        label={translate('misc.phoneNumber')}
                        disabled
                    />
                    <CustomTextField
                        value={application.candidate.mobileNumber}
                        label={translate('misc.mobileNumber')}
                        disabled
                    />
                </PaperRow>
                <PaperRow>
                    <h2>Dokumente</h2>
                </PaperRow>
                <PaperRow>
                    {documents.map((document: ICandidateDocument, index) => {
                        return (
                            <><Link
                                style={{width: '100%'}}
                                key={index}
                                onClick={() => window.open(`${API_URL}/candidate-document/${document.id}/preview?Authorization=${token}`)}
                            >
                                {translate('pages.candidateDocument.typeValue.' + document.type)}
                            </Link><br/></>
                        );
                    })}
                </PaperRow>
                <PaperRow>
                    <h2>Aktivitäten</h2>
                </PaperRow>
                <TopBarButtonPanelWithPadding>
                    <TopBarButton
                        onClick={() => {
                            dispatch(setUiActivityAddDataAction({
                                add: true,
                                module: EActivityModule.application,
                                vacancyId: application.vacancy?.id,
                                companyId: application.company?.id,
                                candidateId: application.candidate.id,
                                applicationId
                            }));
                        }}
                        icon={EIcons.Add}
                        label={translate('pages.activity.add')}
                    />
                    &nbsp;
                    <TopBarButton
                        onClick={() => {
                            dispatch(addActivityAction(
                                token, {
                                    module: EActivityModule.application,
                                    vacancyId: application.vacancy?.id,
                                    companyId: application.company?.id,
                                    candidateId: application.candidate.id,
                                    applicationId,
                                    employeeId: loggedInEmployee.id,
                                    type: EActivityType.phoneNotReached,
                                    dateTime: moment(new Date()).format("DD.MM.Y H:mm:ss")
                                }
                            ));
                        }}
                        icon={EIcons.PhoneDisabled}
                        label={translate('pages.activity.fastEntryNotReached')}
                    />
                </TopBarButtonPanelWithPadding>
                <ApplicationActivityList />
            </CustomDialogBody>
        </CustomDialog>
    );
}

export default EditApplicationView;
