import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { ReactNode } from 'react';
import MomentUtils from '@date-io/moment';
import { IEditViewState } from '../../definitions/ui.definitions';
import CustomDumbTextField from './CustomDumbTextField';
import { EIcons } from '../Icons/IconFactory';
import CustomClickableIcon from './CustomClickableIcon';

interface IProps {
    label: ReactNode|string;
    value?: null|string|Date;
    onChange: (date?: Date) => void;
    disableFuture?: boolean;
    error?: boolean;
    helperText?: ReactNode|string;
    width?: number;
    flex?: boolean;
    required?: boolean;
}

export const checkViewIsEqual = (view1: Partial<IEditViewState>, view2: Partial<IEditViewState>): boolean => {
    return view1.view === view2.view && view1.entityId === view2.entityId;
}

const CustomDatePicker: React.FC<IProps> = (props) => {
    const handleClear = () => {
        props.onChange(undefined);
    }

    const value = typeof props.value === 'string' ? new Date(props.value) : props.value;
    const style = props.flex ? {flex: 1} : {width: props.width || 200};

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disableToolbar
                autoOk
                required={props.required}
                label={props.label}
                value={value || null}
                clearable={true}
                disableFuture={props.disableFuture}
                error={props.error}
                helperText={props.error ? props.helperText : ''}
                InputProps={{
                    startAdornment: props.value ? <CustomClickableIcon onClick={handleClear} icon={EIcons.Close} /> : undefined
                }}
                style={style}
                onChange={(value: any) => {
                    if (!value) {
                        props.onChange(undefined);
                        return;
                    }

                    props.onChange(value);
                }}
                TextFieldComponent={CustomDumbTextField}
                format="DD.MM.yyyy"
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomDatePicker;
