import React, { ReactNode } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, CircularProgress, TextField } from '@material-ui/core';
import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import Chip from '@material-ui/core/Chip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useMultipleLivesearchHooks } from './CustomMultipleLivesearch.hooks';

interface IProps {
    id: string;

    value: TBrainzEntity[];
    onChange: (value: TBrainzEntity[]) => void;
    getOptionLabel: (option: TBrainzEntity) => string;

    routeConfig: IRouteConfig;
    routeParams: object;

    //autocomplete params
    label: string;
    disabled?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
}

const CustomMultipleLivesearch: React.FC<IProps> = (props) => {
    const [searchValue, setSearchValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const values = props.value;
    const routeConfig = props.routeConfig;
    const routeParams = props.routeParams;
    const label = props.label;
    const { records, loading } = useMultipleLivesearchHooks(open, searchValue, routeParams, routeConfig);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    return <>
        <Autocomplete
            id={props.id}
            className={"flex"}
            loading={loading}
            disabled={props.disabled}
            multiple
            noOptionsText={"Geben sie einen Suchbegriff ein"}
            filterOptions={(options) => [...options]}
            inputValue={searchValue}
            onInputChange={(event, value) => setSearchValue(value)}
            disableClearable={props.required}

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}

            options={records}
            getOptionSelected={(value, option) => value.id === option.id }
            value={values}
            getOptionLabel={props.getOptionLabel}

            onChange={(event, value) => {
                props.onChange(value);
            }}

            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {props.getOptionLabel(option)}
                </React.Fragment>
            )}

            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index}
                        label={props.getOptionLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }

            renderInput={(params) => <>
                <TextField
                    {...params}
                    onMouseDown={() => {
                        if (open) {
                            setOpen(false);
                        }
                    }}
                    autoComplete={"random"} //workaround to really disable autocomplete.
                    variant={"outlined"}
                    autoFocus={props.autoFocus}
                    error={props.error}
                    helperText={props.helperText}
                    required={props.required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            </>
            }
        />
    </>
}

export default React.memo(CustomMultipleLivesearch);
