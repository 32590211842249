import { ICompanyPartial, IEmployeePartial } from './entities.definition';
import { ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';

export enum EAnalyticsPeriod {
    PERIOD_YEAR = 'year',
    PERIOD_CURRENT_MONTH = 'current_month',
    PERIOD_LAST_MONTH = 'last_month',
    PERIOD_2_MONTH_AGO = '2_month_ago',
    PERIOD_LAST_WEEK = 'last_week',
    PERIOD_THIS_WEEK = 'this_week',
    PERIOD_YESTERDAY = 'yesterday',
    PERIOD_TODAY = 'today',
}

export enum EAnalyticsMetrics {
    ACTIVITY = 'activity',
    STARTED_RECRUITINGS = 'started_recruitings'
}

export interface IAnalyticsPeriodWithMetrics {
    period: EAnalyticsPeriod;
    periodLabel: string;
    periodFrom: string;
    periodTo: string;
    employeeWithMetrics: {
        [employeeId: number]: {
            employee: IEmployeePartial;
            metrics: {
                [metricTitle: string]: {
                    title: EAnalyticsMetrics;
                    total: number;
                    average: number;
                }
            }
        }
    }
}

export interface ICompanyMetricsData {
    id: number;
    company: ICompanyPartial;
    suggestedCandidates: number;
    declinedCandidatesAfterSuggestion: number;
    declinedCandidatesAfterSuggestionPercent: number;
    interviewedCandidates: number;
    interviewedCandidatesPercent: number;
    declinedRecruitingsAfterInterview: number;
    declinedRecruitingsAfterInterviewPercent: number;
    contracts: number;
    contractsPercent: number;
    failedTrialPeriod: number;
    totalEstimatedTime: number;
    averageTimeOfRecruiting: number;
}

export interface ICompanyMetricsFilter {
    dateFrom?: string;
    dateTo?: string;
}

export interface IAnalyticsEmployeePerformanceDataGroupedByPeriod {
    metricsGroupedByPeriod: IAnalyticsPeriodWithMetrics[];
}

export interface IAnalyticsPageState {
    employeePerformanceData: {
        groupedByPeriod?: IAnalyticsEmployeePerformanceDataGroupedByPeriod
    };
    companyMetrics: ICompanyMetricsData[];
    companyMetricsFilter: ICompanyMetricsFilter;
    companyMetricsSorting: ISorting
}

export const preloadedStateAnalyticsPage: IAnalyticsPageState = {
    employeePerformanceData: {
        groupedByPeriod: undefined,
    },
    companyMetrics: [],
    companyMetricsFilter: {
        dateFrom: (new Date()).getFullYear() + '-01-01',
        dateTo: (new Date()).getFullYear() + '-12-31'
    },
    companyMetricsSorting: {
        'suggestedCandidates': ESortingOptions.Ascending
    }
}
