import React from 'react';
import { useVacancyApplicationListHook } from './VacancyApplicationList.hooks';
import ApplicationList from '../../../../Shared/ApplicationList/ApplicationList';

interface IProps {
    vacancyId: number;
}

const VacancyApplicationList: React.FC<IProps> = (props) => {
    const vacancyId = props.vacancyId;
    const { loading, applications, total } = useVacancyApplicationListHook(vacancyId);

    return <>
        <ApplicationList
            applications={applications}
            loading={loading}
            total={total}
            parentView={'vacancy'}
        />
    </>;
}

export default VacancyApplicationList;
