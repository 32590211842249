import { IApiBaseFilter, ISorting } from './app.definitions';
import { ISkill } from './entities.definition';
import { ESortingOptions } from './components.definitions';

export interface IJobTitleFilter extends IApiBaseFilter {
    skillIds: number[];
    onlyWithoutJobTemplate: boolean;
}

export interface IJobTitleFilterMenu {
    skills: ISkill[];
    onlyWithoutJobTemplate: boolean;
}

export interface IJobTemplatePageState {
    setJobTemplateForJobTitle?: number;
    editViewLoading: boolean;
    sorting: ISorting;
    filter: IApiBaseFilter;
    jobTitleSorting: ISorting;
    jobTitleFilter: IJobTitleFilter;
    jobTitleFilterMenu: IJobTitleFilterMenu,
    templateToEdit: number;
    addDialogOpen: boolean;
    reloadJobTitles: boolean;
}

export const preloadedStateJobTemplatePage: IJobTemplatePageState = {
    addDialogOpen: false,
    filter: {
        start: 0,
        limit: 50
    },
    sorting: {},
    templateToEdit: 0,
    editViewLoading: false,
    jobTitleFilter: {
        start: 0,
        limit: 50,
        skillIds: [],
        onlyWithoutJobTemplate: true
    },
    jobTitleFilterMenu: {
        skills: [],
        onlyWithoutJobTemplate: true
    },
    jobTitleSorting: {
        "created": ESortingOptions.Descending
    },
    reloadJobTitles: false
}
