import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { connect, ConnectedProps } from 'react-redux';
import { addActivityAction, updateActivityAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import {
    EActivityType,
    EInterviewType,
    IActivityForApi,
    ICompanyContact,
    ICompanyContactPartial,
    IEmployee,
    IEmployeePartial,
} from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import moment from 'moment';
import Moment from 'moment';
import { setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import InterviewTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/InterviewTypeDropDown';
import { COLOR_TEXT } from '../../../theme/theme';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import CustomDatePicker from '../../../components/CustomInput/CustomDatePicker';
import CompanyContactAutocomplete
    from '../../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    interviewType: EInterviewType,
    interviewCancelled: boolean,
    feedbackCompany: string,
    feedbackCandidate: string,
    feedbackCompanyDate?: Date,
    feedbackCandidateDate?: Date,
    employee: IEmployee|IEmployeePartial;
    companyContact: ICompanyContactPartial|null;
    dateTime?: Date;
    userInput: string;
}

class InterviewForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialActivity = props.initialActivity;
        const addData = props.addData;

        this.state = {
            interviewType: initialActivity?.interviewType || addData?.interviewType || EInterviewType.phone,
            interviewCancelled: initialActivity?.interviewCancelled || false,
            feedbackCompany: initialActivity?.feedbackCompany || '',
            feedbackCandidate: initialActivity?.feedbackCandidate || '',
            feedbackCompanyDate: initialActivity && initialActivity.feedbackCompanyDate ? new Date(initialActivity.feedbackCompanyDate) : undefined,
            feedbackCandidateDate: initialActivity && initialActivity.feedbackCandidateDate ? new Date(initialActivity.feedbackCandidateDate) : undefined,
            employee: initialActivity?.employee || this.props.addData.preSelectedEmployee || this.props.loggedInEmployee,
            companyContact: initialActivity?.companyContact || addData?.contactId || undefined,
            dateTime: initialActivity ? new Date(initialActivity?.dateTime) : new Date(),
            userInput: initialActivity?.userInput || ''
        }
    }

    @autobind
    save() {
        const activity: IActivityForApi = {
            type: EActivityType.interview,
            employeeId: this.state.employee?.id || 0,
            dateTime: moment(this.state.dateTime).format("DD.MM.Y H:mm:ss"),
            feedbackCompanyDate: this.state.feedbackCompanyDate ? moment(this.state.feedbackCompanyDate).format("DD.MM.Y H:mm:ss") : undefined,
            feedbackCandidateDate: this.state.feedbackCandidateDate ? moment(this.state.feedbackCandidateDate).format("DD.MM.Y H:mm:ss") : undefined,
            companyContactId: this.state.companyContact?.id,
            interviewType: this.state.interviewType,
            feedbackCompany: this.state.feedbackCompany,
            feedbackCandidate: this.state.feedbackCandidate,
            userInput: this.state.userInput,
            interviewCancelled: this.state.interviewCancelled
        };

        if (this.props.addData.add) {
            activity.module = this.props.addData.module;
            activity.candidateId = this.props.addData.candidateId;
            activity.companyId = this.props.addData.companyId;
            activity.vacancyId = this.props.addData.vacancyId;
            activity.recruitingId = this.props.addData.recruitingId;
            activity.recruitingSuggestionId = this.props.addData.suggestionId;

            this.props.addActivityAction(this.props.token, activity);
            return;
        }

        activity.id = this.props.initialActivity.id;
        this.props.updateActivityAction(this.props.token, activity);
        return;
    }

    render() {
        const feedbackPossible = (Moment(this.state.dateTime)).isBefore(Moment());
        const feedbackNotPossibleHint = feedbackPossible ? undefined : 'Feedback erst nach dem Termin möglich';

        return (
            <>
                <PaperRow>
                    <CustomDateTimePicker
                        label={'Interview Datum & Uhrzeit'}
                        value={this.state.dateTime}
                        onChange={(value) => this.setState({
                            dateTime: value
                        })}
                    />
                    <InterviewTypeDropDown
                        value={this.state.interviewType}
                        onChange={(event) => this.setState({
                            interviewType: event.target.value
                        })}
                        error={Boolean(this.props.validationErrors.interviewType)}
                        helperText={this.props.validationErrors.interviewType}
                    />
                </PaperRow>
                <PaperRow>
                    <LegacyEmployeeAutocompleteDropDown
                        label={"Koordiniert von Mitarbeiter"}
                        value={this.state.employee}
                        onChange={(event, value) => this.setState({
                            employee: value
                        })}
                        error={Boolean(this.props.validationErrors.employeeId)}
                        helperText={this.props.validationErrors.employeeId}
                    />
                    <CompanyContactAutocomplete
                        value={this.state.companyContact}
                        onChange={(value ) => this.setState({
                            companyContact: value
                        })}
                        label={translate('pages.recruiting.interviewContactPerson')}
                        onAdd={() => {
                            if (this.props.companyId) {
                                this.props.setUiCompanyContactAddDataAction({
                                    companyId: this.props.companyId
                                });
                            }
                        }}
                        companyId={this.props.companyId}
                    />
                </PaperRow>
                {this.props.initialActivity &&
                    <>
                        <PaperRow>
                            <CustomCheckboxInput
                                checked={this.state.interviewCancelled}
                                onChange={() => this.setState((state) => ({
                                    interviewCancelled: !state.interviewCancelled
                                }))}
                                label={"Termin hat nicht statt gefunden"}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomDatePicker
                                width={300}
                                label={translate('pages.recruiting.feedbackCompanyDate')}
                                value={this.state.feedbackCompanyDate}
                                onChange={(value) => this.setState({
                                    feedbackCompanyDate: value
                                })}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomTextField
                                multiline
                                disabled={!feedbackPossible}
                                rows={6}
                                label={translate('pages.recruiting.feedbackCompany')}
                                value={this.state.feedbackCompany}
                                onChange={(value) => this.setState({
                                    feedbackCompany: value
                                })}
                                FormHelperTextProps={{
                                    style: !feedbackPossible ? {
                                        color: COLOR_TEXT
                                    }: {}
                                }}
                                error={Boolean(this.props.validationErrors.feedbackCompany)}
                                helperText={feedbackNotPossibleHint || this.props.validationErrors.feedbackCompany}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomDatePicker
                                width={300}
                                label={translate('pages.recruiting.feedbackCandidateDate')}
                                value={this.state.feedbackCandidateDate}
                                onChange={(value) => this.setState({
                                    feedbackCandidateDate: value
                                })}
                            />
                        </PaperRow>
                        <PaperRow>
                            <CustomTextField
                                multiline
                                disabled={!feedbackPossible}
                                rows={6}
                                label={translate('pages.recruiting.feedbackCandidate')}
                                value={this.state.feedbackCandidate}
                                onChange={(value) => this.setState({
                                    feedbackCandidate: value
                                })}
                                FormHelperTextProps={{
                                    style: !feedbackPossible ? {
                                        color: COLOR_TEXT
                                    }: {}
                                }}
                                error={Boolean(this.props.validationErrors.feedbackCandidate)}
                                helperText={feedbackNotPossibleHint || this.props.validationErrors.feedbackCandidate}
                            />
                        </PaperRow>
                    </>
                }
                <PaperRow>
                    <CustomTextField
                        multiline
                        rows={6}
                        label={translate('misc.internalComment')}
                        value={this.state.userInput}
                        onChange={(value) => this.setState({
                            userInput: value
                        })}
                        error={Boolean(this.props.validationErrors.userInput)}
                        helperText={this.props.validationErrors.userInput}
                    />
                </PaperRow>
                <CustomDialogActions
                    onClose={this.props.onClose}
                    buttons={[{
                        void: this.save,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </>
        );
    }
}

const mapState = (store: IStore) => {
    const initialActivity = store.entities.activities.byId[store.ui.editActivityId];
    let companyId = 0;
    if (store.ui.addActivityData.add && store.ui.addActivityData.companyId) {
        companyId = store.ui.addActivityData.companyId;
    }
    if (!store.ui.addActivityData.add && initialActivity && initialActivity.company) {
        companyId = initialActivity.company.id;
    }

    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialActivity,
        addData: store.ui.addActivityData,
        companyId: companyId
    });
}

const mapDispatch = {
    updateActivityAction,
    addActivityAction,
    setUiCompanyContactAddDataAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(InterviewForm);
