import { IErrorAction, IInfoAction } from '../../definitions/redux/actions.definition';

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_INFO = "REGISTER_INFO";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const CONSUME_ERROR = "CONSUME_ERROR";
export const CONSUME_INFO = "CONSUME_INFO";
export const CONSUME_SUCCESS = "CONSUME_SUCCESS";

export const RESET_FORM_VALIDATION = "RESET_FORM_VALIDATION";

export const registerError = (message: string): IErrorAction => ({
    type: REGISTER_ERROR,
    payload: message,
});

export const registerInfo = (message: string): IInfoAction => ({
    type: REGISTER_INFO,
    payload: message,
});

export const consumeError = () => ({
    type: CONSUME_ERROR
});

export const registerSuccess = () => ({
    type: REGISTER_SUCCESS,
});

export const consumeSuccess = () => ({
    type: CONSUME_SUCCESS,
});

export const consumeInfo = () => ({
    type: CONSUME_INFO,
});

export const resetFormValidation = () => ({
    type: RESET_FORM_VALIDATION,
});
