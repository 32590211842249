const replaceParameters = (text: string, parameters: string[]) => {
    let parameterIndex = -1;

    return text.replaceAll("%s", function () {
        parameterIndex++;
        return parameters[parameterIndex];
    });
};

export const translate = (toTranslate: string, parameters?: string[]): string => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const translations = require("./locales/de.json");

    const toTranslateSplit = toTranslate.split(".");
    let result: any = translations;

    toTranslateSplit.forEach((split) => {
        if (result[split]) {
            result = result[split];
        } else {
            result = toTranslate;
        }
    });

    if (parameters && parameters.length > 0) {
        result = replaceParameters(result, parameters);
    }

    return result;
};

