import { declineSuggestionAction } from '../../redux/entities/entities.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../components/CustomTypography/CustomTypography';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomDumbTextField from '../../components/CustomInput/CustomDumbTextField';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { setVacancyPageSuggestionToDeclineAction } from '../../redux/vacancyPage/vacancyPage.actions';
import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import SuggestionDeclineReasonDropDown
    from '../../components/CustomInput/CustomDropDownInput/SuggestionDeclineReasonDropDown';
import { ESuggestionDeclineReason } from '../../definitions/entities.definition';
import CustomNumberInput from '../../components/CustomInput/CustomNumberInput';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomCheckboxInput from '../../components/CustomInput/CustomCheckboxInput';

const SuggestionDeclineView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.suggestionToDecline.id));
    const idToDecline = useSelector((store: IStore) => store.vacancyPage.suggestionToDecline.id);
    const [additionalInput, setAdditionalInput] = useState("");
    const [followupTimeRange, setFollowupTimeRange] = useState(3);
    const [reason, setReason] = useState(ESuggestionDeclineReason.REASON_NO_INTEREST);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [createFollowup, setCreateFollowup] = useState(false);

    if (!open) {
        return null;
    }

    const onClose = () => {
        dispatch(setVacancyPageSuggestionToDeclineAction(0, false));
        setAdditionalInput('');
        setReason(ESuggestionDeclineReason.REASON_NO_INTEREST);
    }

    return (
        <>
            <CustomDialog maxWidth={"sm"} onClose={onClose} open={true}>
                <CustomDialogHeader string={translate('pages.vacancy.followupSuggestion')} onClose={onClose} />
                <CustomDialogBody>
                    <CustomTypography translation={"pages.vacancy.declineSuggestionText"} />
                <PaperRow>
                    <SuggestionDeclineReasonDropDown
                        onChange={(reason) => setReason(reason)}
                        value={reason}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomDumbTextField
                        fullWidth
                        label={translate('pages.activity.properties.userInput')}
                        value={additionalInput}
                        multiline={true}
                        onChange={(event) => setAdditionalInput(event.target.value)}
                        rows={6}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomCheckboxInput
                        checked={createFollowup}
                        onChange={() => setCreateFollowup(!createFollowup)}
                        label={"Wiedervorlage erstellen"}
                    />
                    {createFollowup &&
                        <CustomNumberInput
                            label={'In x Monaten wiedervorlegen'}
                            value={followupTimeRange}
                            onChange={(value) => setFollowupTimeRange(value)}
                        />
                    }
                </PaperRow>
                </CustomDialogBody>
                <CustomDialogActions
                    onClose={() => dispatch(setVacancyPageSuggestionToDeclineAction(0, false))}
                    buttons={[{
                        void: () => {
                            dispatch(declineSuggestionAction(
                                token,
                                idToDecline,
                                reason,
                                createFollowup,
                                '+ ' + followupTimeRange + ' month',
                                additionalInput,
                                onClose
                            ));
                            dispatch(setVacancyPageSuggestionToDeclineAction(0, false));
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
            </CustomDialog>
        </>
    );
};

export default SuggestionDeclineView;
