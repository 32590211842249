import React, { useCallback } from 'react';
import { EIcons, IconFactory } from '../../../../Icons/IconFactory';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import CustomFilterItemIcon from '../../CustomSidePanelList/CustomFilterItemIcon';

interface IProps {
    title: string;
    icon: EIcons;
    active: boolean;
    setActive: (active: boolean) => void;
    tooltip?: string;
}

const BoolFilter: React.FC<IProps> = (props) => {
    const active = props.active;
    const title = props.title;
    const icon = props.icon;
    const tooltip = props.tooltip;

    const toggle = () => {
        props.setActive(!active);
    }

    const removeFilterAction = () => {
        props.setActive(false);
    }

    const renderValue = active ? 'Aktiv' : null;

    const getTextElement = useCallback(() => {
        if (tooltip) {
            return <>
                <Tooltip title={tooltip}>
                    <ListItemText primary={title} secondary={renderValue}/>
                </Tooltip>
            </>;
        }

        return <>
            <ListItemText primary={title} secondary={renderValue}/>
        </>;
    }, [title, renderValue, tooltip]);

    return <>
        <ListItem
            button
            selected={active}
            onClick={toggle}
        >
            <CustomFilterItemIcon icon={icon} active={active} />
            {getTextElement()}
            <ListItemSecondaryAction>
                {active &&
                    <IconButton edge="end" aria-label="delete" onClick={removeFilterAction}>
                        {IconFactory.getIconComponent(EIcons.Delete, {color: 'var(--brainz-dark)'})}
                    </IconButton>
                }
            </ListItemSecondaryAction>
        </ListItem>
    </>;
};

export default React.memo(BoolFilter);
