import React from 'react';
import { ILanguage } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import LanguageMultipleAutocomplete
    from '../../../CustomInput/CustomAutocomplete/components/LanguageMultipleAutocomplete';

interface IProps {
    value: ILanguage[];
    onChange: (value: ILanguage[]) => void;
    onlyWithWebVacancies?: boolean;
}

const LanguageFilter: React.FC<IProps> = (props) => {
    const languages = props.value;
    const onlyWithWebVacancies = props.onlyWithWebVacancies;

    const onChange = (value: ILanguage[]) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange([]);
    }

    const valueRendered = languages && languages.map((skill) => skill.name).join(', ');
    const deletable = languages !== undefined && languages?.length > 0;

    return <>
        <CustomFilterItem
            title={"Sprache"}
            icon={EIcons.Public}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
            autoClose
        >
            <LanguageMultipleAutocomplete
                autoFocus
                onlyWithWebVacancies={onlyWithWebVacancies}
                value={languages}
                onChange={onChange}
            />
        </CustomFilterItem>
    </>;
};

export default React.memo(LanguageFilter);
