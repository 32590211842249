import { TReducerAction } from '../../definitions/redux/actions.definition';
import { INewsState, preloadedNewsState } from '../../definitions/news.definitions';
import { ENTITIES_GET_NEWS_LIST_FULFILLED } from '../entities/entities.actions';

const newsReducer = (state = preloadedNewsState, action: TReducerAction): INewsState => {
    switch (action.type) {
        case ENTITIES_GET_NEWS_LIST_FULFILLED: {
            return {
                ...state,
                reloadNews: false
            }
        }
    }

    return {
        ...state
    };
}

export default newsReducer;
