import React, { ReactNode, useState } from 'react';
import { COLOR_BLUE, COLOR_GREEN, COLOR_ORANGE, COLOR_RED } from '../../../../theme/theme';
import { translate } from '../../../../translation/translate.utils';
import { ICustomerRecruitingListModel } from '../../../../definitions/entities.customer.definitions';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import {
    ERecruitingAbortedBy,
    ERecruitingStatus,
    IRecruitingListModel,
} from '../../../../definitions/entities.definition';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@material-ui/lab';
import DateDiffCellRenderer from '../../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { getFormattedDateString } from '../../../../utils/date.utils';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { IconButton, Link } from '@material-ui/core';
import DisplayCustomerInquiriesView from './DisplayCustomerInquiriesView';
import { setUiActivityToEditAction } from '../../../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';

interface ITimelineEntry {
    content: ReactNode;
    title: null|ReactNode;
    dotColor: 'blue'|'orange'|'grey'|'green'|'red';
}

const getTimelineElementsOfRecruiting = (recruiting: ICustomerRecruitingListModel|IRecruitingListModel, allowOpenInterview: boolean): ITimelineEntry[] => {
    const dispatch = useDispatch();
    const interviews = recruiting.interviews;
    const elements: ITimelineEntry[] = [];
    const interviewElements: ITimelineEntry[] = interviews.map((interview, index) => {
        const actionRequired = index === 0 && recruiting.customerActionRequired;
        const warning = recruiting.status === ERecruitingStatus.STATUS_INTERVIEW && actionRequired;

        const header = <>VG{interview.round}&nbsp;{translate('pages.recruiting.interviewTypeValue.' + interview.type)}</>;
        return {
            dotColor: warning ? 'orange' : 'grey',
            content: <div className={"flexContainerColumn"}>
                {allowOpenInterview
                    ? <Link style={{color: warning ? 'orange' : undefined}} onClick={(event) => {
                        dispatch(setUiActivityToEditAction(interview.id));
                        event.stopPropagation();
                    }}>{header}</Link>
                    : header
                }

                {(interview.cancelled && index > 0) &&
                    <>(abgesagt)</>
                }
                {(actionRequired && !interview.cancelled && !interview.feedbackExists && interview.dateTime !== 'MISSING') &&
                    <>(Feedback ausstehend)</>
                }
                {(actionRequired && interview.cancelled && index === 0) &&
                    <>(abgesagt & kein neuer Termin)</>
                }
            </div>,
            title: interview.dateTime !== 'MISSING' ? <>
                {interview.cancelled
                    ? <>abgesagt, ursprünglich geplant für {getFormattedDateString(interview.dateTime)}</>
                    : <>{!interview.cancelled && index === 0 && recruiting.status === ERecruitingStatus.STATUS_INTERVIEW
                        ? <DateTimeCellRenderer date={interview.dateTime}/>
                        : <DateCellRenderer date={interview.dateTime}/>
                    }</>
                }
            </> : <>Noch kein Termin festgesetzt</>
        }
    });


    const suggestElement: ITimelineEntry = {
        title: <><DateCellRenderer date={recruiting.created}/>,&nbsp;<DateDiffCellRenderer date={recruiting.created}/></>,
        dotColor: 'blue',
        content: 'vorgeschlagen'
    }

    switch (recruiting.status) {
        case ERecruitingStatus.STATUS_CONTRACT:
        case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
        case ERecruitingStatus.STATUS_FINISH:
            return [
                {
                    title: recruiting.contractSigningDate && <DateCellRenderer date={recruiting.contractSigningDate}/>,
                    dotColor: 'green',
                    content: <>
                        {recruiting.contractBeginningDate &&
                            <div className={"flexContainerRow"}>
                                <div>Start: &nbsp;</div><DateCellRenderer date={recruiting.contractBeginningDate}/>
                            </div>
                        }

                        {recruiting.contractSalary &&
                            <div className={"flexContainerRow"}>
                                <div>Gehalt: &nbsp;</div>{recruiting.contractSalary/1000}k p.a.
                            </div>
                        }
                    </>
                },
                ...interviewElements,
                suggestElement
            ];
        case ERecruitingStatus.STATUS_ABORT:
            return [
                {
                    title: recruiting.dateOfAbort &&
                        <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                            <DateCellRenderer date={recruiting.dateOfAbort}/>
                        </div>,
                    dotColor: 'red',
                    content: <>
                        Absage von {recruiting.abortedBy === ERecruitingAbortedBy.CANDIDATE ? 'Kandidat' : 'Helsing'}<br/>
                        <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                            {translate('pages.vacancy.declineRecruitingReasonValues.' + recruiting.declineReason)}
                        </div>
                    </>
                },
                ...interviewElements,
                suggestElement
            ];
        case ERecruitingStatus.STATUS_INTERVIEW: {
            return [
                ...interviewElements,
                suggestElement
            ];
        }
        case ERecruitingStatus.STATUS_SUGGEST:
            return [{
                title: <><DateCellRenderer date={recruiting.created}/>,&nbsp;<DateDiffCellRenderer date={recruiting.created}/></>,
                dotColor: 'orange',
                content: <>vorgeschlagen<br/>(Feedback ausstehend)</>
            }]
    }

    return elements;
}

const colorMap = {
    'blue': COLOR_BLUE,
    'orange': COLOR_ORANGE,
    'grey': undefined,
    'green': COLOR_GREEN,
    'red': COLOR_RED,
}

interface IProps {
    recruiting: ICustomerRecruitingListModel|IRecruitingListModel;
    allowOpenInterview?: boolean;
}

const RecruitingStatusTimelineCellRenderer: React.FC<IProps> = (props) => {
    const allowOpenInterview = props.allowOpenInterview || false;
    const recruiting = props.recruiting;
    const timelineElements = getTimelineElementsOfRecruiting(recruiting, allowOpenInterview);
    const [expanded, setExpanded] = useState(false);
    const [displayInquiries, setDisplayInquiries] = useState(false);

    return <div className={"flexContainerColumn"} style={{width:'100%'}}>
        {recruiting.inquiries.length > 0 &&
            <div className={'formContent marginBottom10'}>
                <DisplayCustomerInquiriesView
                    inquiries={recruiting.inquiries}
                    open={displayInquiries}
                    onClose={() => setDisplayInquiries(false)}
                />
                <Link href={"#"} onClick={() => setDisplayInquiries(true)}>
                    Offene Rückfragen:&nbsp;{recruiting.inquiries.length}
                </Link>
            </div>
        }
        <Timeline style={{padding: 'unset',marginTop: 7, marginBottom: 0}} >
            {timelineElements.map((entry,index) => {
                if (!expanded && index > 0) {
                    return null;
                }

                const collapsable = index === 0 && timelineElements.length > 1;
                const isFirstElement = index+1 === timelineElements.length;

                const collapseFunction = () => {
                    if (!collapsable) {
                        return;
                    }
                    setExpanded(!expanded);
                }

                return <TimelineItem key={index} style={!expanded || isFirstElement ? {minHeight: 'auto'} : {}}>
                    <TimelineOppositeContent style={{flex: 'unset', padding: 'unset'}}>&nbsp;</TimelineOppositeContent>
                    <TimelineSeparator style={{minWidth:24}}>
                        <TimelineDot color={"grey"} style={{alignSelf: 'center', backgroundColor: colorMap[entry.dotColor]}}>
                            {entry.dotColor === 'green' && IconFactory.getIconComponent(EIcons.ThumbUp, {width: 12, height: 12})}
                            {entry.dotColor === 'red' && IconFactory.getIconComponent(EIcons.ThumbDown, {width: 12, height: 12})}
                        </TimelineDot>
                        {(!isFirstElement && expanded) &&
                            <TimelineConnector />
                        }
                    </TimelineSeparator>
                    <TimelineContent
                        className={"flexContainerRow"}
                        style={{marginTop:-10, color: index === 0 ? colorMap[entry.dotColor] : undefined}}
                    >
                        <div className={"flexContainerColumn flex1"} onClick={collapseFunction}  style={{cursor: collapsable ? 'pointer' : undefined}}>
                            <div className={"formContent"} style={{flexDirection: 'row', color: 'unset'}}>
                                {entry.title}
                            </div>
                            {entry.content}
                        </div>
                        {(index === 0 && timelineElements.length > 1) &&
                            <IconButton onClick={collapseFunction} style={{alignSelf: "flex-start"}}>
                                {IconFactory.getIconComponent(expanded ? EIcons.ExpandMore : EIcons.ExpandLess)}
                            </IconButton>
                        }
                    </TimelineContent>
                </TimelineItem>
            })}
        </Timeline>
    </div>;
};

export default RecruitingStatusTimelineCellRenderer;
