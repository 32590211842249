import { createStyles, List, ListItem, ListItemText, Tab, Tabs, withStyles } from '@material-ui/core';
import React, { ReactNode, useState } from 'react';
import { IActionTab, ITab } from '../../definitions/components.definitions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CustomTabPanel from './CustomTabPanel';

interface IProps {
    tabs: ITab[];
    actions?: IActionTab[];
    color?: string;
    onTabChange?: (tabId: number) => void;
    activeTab?: number;
    orientation?: 'horizontal' | 'vertical';
    padding?: boolean;
    roundBorder?: boolean;
    verticalWidth?: number;
    tabTopElement?: ReactNode;
}

const a11yProps = (index: any) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const CustomTabContainer: React.FC<IProps> = (props) => {
    const tabs = props.tabs.filter((tab) => tab);
    const actions = props.actions ? props.actions.filter((tab) => tab) : undefined;
    const tabTopElement = props.tabTopElement || null;

    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                flex: "1 1 auto",
                flexDirection: "column",
                overflow: "hidden",
                display: "flex",
            },
            roundBorder: {
                borderRadius: '6px 6px 0px 0px',
            },
            error: {
                color: '#f44336 !important',
                fontWeight: 600,
                backgroundColor: '#ccc'
            },
            tabs: {
                backgroundColor: props.color ?? theme.palette.primary.main,
                color: theme.palette.common.white,
            },
            rootVertical: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                height: '100%',
                flex: 1,
            },
            tabsVertical: {
                color: theme.palette.common.white,
                borderRight: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                height: '100%',
                flex: 1
            },
            tabsContainerVertical: {
                height: '100%'
            },
            actionList: {
                flex: 1,
                height: '100%',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column'
            }
        })
    );
    const styles = useStyles();
    const [value, setValue] = useState(props.activeTab || 0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        if (props.onTabChange) {
            props.onTabChange(newValue);
        }
    }

    const orientation = props.orientation || 'horizontal';

    const AntTab = withStyles((theme: Theme) =>
        createStyles({
            root: {
                minWidth: 72,
                marginRight: theme.spacing(2)
            },
            selected: {
                backgroundColor: props.color
            },
        }),
    )((props) => <Tab disableRipple {...props} />);

    return (
        <div className={(orientation === 'horizontal' ? styles.root : styles.rootVertical) + ' ' + (props.roundBorder ? styles.roundBorder : '')}>
            <Tabs
                classes={{
                    flexContainer: orientation === 'vertical' ? styles.tabsContainerVertical : ''
                }}
                orientation={orientation}
                className={orientation === 'horizontal' ? styles.tabs : styles.tabsVertical}
                value={value}
                scrollButtons="auto"
                variant={"scrollable"}
                onChange={handleChange}
                aria-label="simple tabs22"
                style={orientation === 'vertical' ? {
                    minWidth: props.verticalWidth === undefined ? 170 : props.verticalWidth,
                    maxWidth: props.verticalWidth === undefined ? 170 : props.verticalWidth
                }: undefined}
            >
                {tabs.map((tab, index) => (
                    <AntTab
                        key={index}
                        classes={{
                            //@ts-ignore
                            wrapper: tab.error ? styles.error : ''
                        }}
                        className={tab.error ? styles.error : ''}
                        label={tab.label}
                        {...a11yProps(0)}
                    />
                ))}
                {actions &&
                    <List className={styles.actionList}>
                        {actions.map((action, index) => {
                            return <>
                                <ListItem key={index}>
                                    <ListItemText onClick={action.onClick} style={{color: '#e9edef !important', cursor: 'pointer'}}>
                                        {action.label}
                                    </ListItemText>
                                </ListItem>
                            </>
                        })}
                    </List>
                }
            </Tabs>
            {tabs.map((tab, index) => (
                <CustomTabPanel
                    key={index}
                    value={value}
                    index={Number(index)}
                    padding={props.padding}
                >
                    {tabTopElement}
                    {tab.component}
                </CustomTabPanel>
            ))}
        </div>
    );
};

export default CustomTabContainer;
