import React from 'react';
import { Badge } from '@material-ui/core';
import CustomQuickFilterContainer from '../../../components/CustomFilter/CustomQuickFilter/CustomQuickFilterContainer';
import CustomQuickFilterButton from '../../../components/CustomFilter/CustomQuickFilter/CustomQuickFilterButton';
import { ICustomerAppRecruitingFilter } from '../../../definitions/customerApp.definitions';
import styles from './CompanyFilterPanel.module.css';

interface IProps {
    disabled: boolean;
    countRunning: number;
    countActionRequired: number;
    countDeclined: number;
    countContract: number;
    filter: ICustomerAppRecruitingFilter;
    setFilterAction: (filter: Partial<ICustomerAppRecruitingFilter>) => void;
}

const RecruitingPageFilterPanel: React.FC<IProps> = (props) => {
    const countRunning = props.countRunning;
    const countActionRequired = props.countActionRequired;
    const countDeclined = props.countDeclined;
    const countContract = props.countContract;
    const status = props.filter.status;
    const disabled = props.disabled;

    return <>
        <CustomQuickFilterContainer disabled={disabled}>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: 'running'})}
                isActive={status === 'running'}
            >
                Laufend &nbsp;&nbsp;&nbsp; <Badge color="primary" className={styles.badgeGrey} badgeContent={countRunning.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: 'actionRequired'})}
                isActive={status === 'actionRequired'}
            >
                mit Handlungsbedarf &nbsp;&nbsp;&nbsp; <Badge color="primary" className={styles.badgeOrange} badgeContent={countActionRequired.toString()}/>&nbsp;
            </CustomQuickFilterButton>
            <div className={"flexContainerColumn gap5"}>
                <CustomQuickFilterButton
                    disabled={disabled}
                    onClick={() => props.setFilterAction({status: 'declined'})}
                    isActive={status === 'declined'}
                >
                    Absagen &nbsp;&nbsp;&nbsp; <Badge color="primary" className={styles.badgeRed}  badgeContent={countDeclined.toString()}/>&nbsp;
                </CustomQuickFilterButton>
                {countDeclined > 100 &&
                    <>
                        <CustomQuickFilterButton
                            disabled={disabled}
                            onClick={() => props.setFilterAction({status: 'declined'})}
                            isActive={false}
                        >
                            Alle Absagen &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={countDeclined.toString()}/>&nbsp;
                        </CustomQuickFilterButton>
                        <CustomQuickFilterButton
                            disabled={disabled}
                            onClick={() => props.setFilterAction({status: 'declined'})}
                            isActive={false}
                        >
                            Von Kandidat &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={1}/>&nbsp;
                        </CustomQuickFilterButton>
                        <CustomQuickFilterButton
                            disabled={disabled}
                            onClick={() => props.setFilterAction({status: 'declined'})}
                            isActive={false}
                        >
                            Von Helsing &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={1}/>&nbsp;
                        </CustomQuickFilterButton>
                    </>
                }
            </div>
            <CustomQuickFilterButton
                disabled={disabled}
                onClick={() => props.setFilterAction({status: 'contract'})}
                isActive={status === 'contract'}
            >
                Eingestellt &nbsp;&nbsp;&nbsp; <Badge color="primary" className={styles.badgeGreen} badgeContent={countContract.toString()}/>&nbsp;
            </CustomQuickFilterButton>
        </CustomQuickFilterContainer>
    </>;
}

export default RecruitingPageFilterPanel;
