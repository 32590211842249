import { useEffect } from 'react';
import { getRecruitingsForCandidateAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getCandidatePageRequestObjectForRecruitingsInEditView,
    getRecruitingsForCandidate,
} from '../../../../../selectors/recruiting.selectors';

export const useCandidateRecruitingListHook = (candidateId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.candidateRecruitingsLoading);
    const reload = useSelector((store: IStore) => store.recruitingPage.reloadAllRecruitings || store.recruitingPage.reloadSuggestedRecruitings);

    const requestObject = useSelector(getCandidatePageRequestObjectForRecruitingsInEditView);
    const recruitings = useSelector((store: IStore) => getRecruitingsForCandidate(store, candidateId));

    useEffect(() => {
        if (!loading) {
            dispatch(getRecruitingsForCandidateAction(token, candidateId, requestObject));
        }
    }, []);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getRecruitingsForCandidateAction(token, candidateId, requestObject));
        }
    }, [reload]);

    return {loading, recruitings, total: recruitings.length};
}
