import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { ERecruitingPageTabs } from '../../../../definitions/recruitingPage.definitions';
import FastSearchPanelVacancyList from './components/FastSearchPanelVacancyList';
import FastSearchPanelRecruitingList from './components/FastSearchPanelRecruitingList';

const FastSearchPanelRecruitingPage: React.FC = () => {
    const recruitingTab = useSelector((store: IStore) => store.recruitingPage.activeTab);

    if (recruitingTab === ERecruitingPageTabs.tabVacancies) {
        return <FastSearchPanelVacancyList />;
    }

    return <FastSearchPanelRecruitingList />;
}

export default FastSearchPanelRecruitingPage;
