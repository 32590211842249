import CustomDumbTextField from '../../../../components/CustomInput/CustomDumbTextField';
import { translate } from '../../../../translation/translate.utils';
import { CircularProgress, debounce, InputAdornment, Switch } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import React, { useCallback, useState } from 'react';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { setMailingFilterAction } from '../../../../redux/mailing/mailing.actions';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import IconButton from '@material-ui/core/IconButton';
import { ClientApi } from '../../../../requests/ClientApi';
import { fetchMailboxRouteConfig } from '../../../../requests/routes';
import { registerError } from '../../../../redux/error/error.actions';
import { getMailListAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';

const MailHeaderWithFilter = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const filter = useSelector((store: IStore) => store.mailing.filter);
    const [fastSearch, setFastSearch] = useState(filter.fastSearch);
    const user = useSelector(getLoggedInEmployeeFromStore);
    const showEmployeeFilter = user.id === 1 || user.id === 2;
    const [fetchingMails, setFetchingMails] = useState(false);

    const changeHandler = (fastSearch: string) => {
        dispatch(setMailingFilterAction({fastSearch}))
    };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const loadMails = () => {
        dispatch(getMailListAction(token, filter));
    }

    const fetchMails = () => {
        setFetchingMails(true);
        ClientApi.request(fetchMailboxRouteConfig, {
            token
        }).then(() => {
            loadMails();
            setFetchingMails(false);
        }).catch(() => {
            dispatch(registerError('Fetching mailbox failed'));
            setFetchingMails(false);
        })
    }

    return (
        <>
            <h2 style={{display: 'flex', justifyContent: 'center', width: 160, verticalAlign: 'middle', alignItems: 'center', textAlign: 'center', borderRight: '1px solid rgba(0, 0, 0, 0.12)'}}>
                E-Mails
                {fetchingMails && <>&nbsp;<CircularProgress size={30} color={"primary"} /></> }
                {!fetchingMails &&
                    <IconButton onClick={fetchMails}>
                        {IconFactory.getIconComponent(EIcons.Download)}
                    </IconButton>
                }
            </h2>
            <div className={"flexColumn"}>
                <div className={"flex flexCentered"}>
                    <CustomDumbTextField
                        style={{width: showEmployeeFilter ? 320 : 480}}
                        label={translate('misc.fastSearch')}
                        value={fastSearch}
                        onChange={(event) => {
                            setFastSearch(event.target.value);
                            debouncedChangeHandler(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {IconFactory.getIconComponent(EIcons.Search)}
                                </InputAdornment>
                            ),
                        }}
                    />
                    {showEmployeeFilter &&
                        <LegacyEmployeeAutocompleteDropDown
                            value={filter.employeeId}
                            label={translate('misc.employee')}
                            onChange={(event, value) => dispatch(setMailingFilterAction({employeeId: value.id}))}
                        />
                    }
                </div>
                <div className={"flex flexCentered"} style={{position: 'absolute', top: 75}}>
                    <Switch
                        color={"default"}
                        checked={filter.onlyNotAssigned}
                        onChange={() => dispatch(setMailingFilterAction({onlyNotAssigned: !filter.onlyNotAssigned}))}
                    />
                    <div style={{backgroundColor: 'var(--brainz-color-orange)', height: 15}}>&nbsp;</div>&nbsp;Nur ohne Verknüpfung
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Switch
                        color={"default"}
                        checked={filter.onlyUnread}
                        onChange={() => dispatch(setMailingFilterAction({onlyUnread: !filter.onlyUnread}))}
                    />
                    <div style={{backgroundColor: '#006edc', height: 15}}>&nbsp;</div>&nbsp;Nur nicht gelesen
                </div>
            </div>
        </>
    );
}

export default MailHeaderWithFilter;
