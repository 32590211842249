import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { updateCandidateAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { setCloseChangeRecruitingResponsibleWindowAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../../components/CustomTypography/CustomTypography';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../translation/translate.utils';
import { Dialog } from '@material-ui/core';

const RecruitingAlertChangeResponsibleView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.recruitingPage.alertChangeRecruitingResponsible?.alert));
    const data = useSelector((store: IStore) => store.recruitingPage.alertChangeRecruitingResponsible);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const onSave = () => {
        if (!data || !data.candidate || !data.possibleNewResponsible) {
            return;
        }
        dispatch(updateCandidateAction(
            token, {
                id: data.candidate.id,
                responsibleId: data.possibleNewResponsible.id
            }, false
        ));
        dispatch(setCloseChangeRecruitingResponsibleWindowAction());
    };

    const onClose = () => {
        dispatch(setCloseChangeRecruitingResponsibleWindowAction());
    }

    return (
        <Dialog open={open} onClose={onClose} style={{zIndex: 9999 }}>
            <CustomDialogHeader string={translate('pages.recruiting.changeResponsibleDialog.title')} onClose={onClose} />
            <CustomDialogBody>
                <CustomTypography value={translate('pages.recruiting.changeResponsibleDialog.body', [
                    data?.currentResponsible?.fullName || '',
                    data?.possibleNewResponsible?.fullName || ''
                ])} />
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.saveAndClose')
                }, {
                    void: onClose,
                    label: translate('pages.recruiting.changeResponsibleDialog.abort')
                }]}
            />
        </Dialog>
    );
};

export default RecruitingAlertChangeResponsibleView;
