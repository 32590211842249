import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import {
    ERecruitingStatus,
    IRecruiting,
    IRecruitingGroup,
    IRecruitingListModel,
} from '../definitions/entities.definition';
import { getLoggedInEmployeeFromStore } from './employee.selectors';
import _ from 'underscore';

export const groupRecruitingsByStatus = (recruitings: IRecruitingListModel[]): IRecruitingGroup => {
    return _.groupBy(recruitings, function (recruiting) {
        return recruiting.status;
    });
}

export const getRecruitingsWithStatus = (store: IStore, status: ERecruitingStatus): IRecruitingListModel[] => {
    const recruitingsById = store.entities.recruiting.listModelById;

    let order: number[] = [];

    switch (status) {
        case ERecruitingStatus.STATUS_SUGGEST:
            order = store.entities.recruiting.orderSuggest;
            break;
        case ERecruitingStatus.STATUS_INTERVIEW:
            order = store.entities.recruiting.orderInterview;
            break;
        case ERecruitingStatus.STATUS_CONTRACT:
            order = store.entities.recruiting.orderContract;
            break;
        case ERecruitingStatus.STATUS_ABORT:
            order = store.entities.recruiting.orderAbort;
            break;
        case ERecruitingStatus.STATUS_FINISH:
            order = store.entities.recruiting.orderFinish;
            break;
        case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
            order = store.entities.recruiting.orderTrialPeriodFailed;
            break;
    }

    return order.reduce((accumulator: IRecruitingListModel[], recruitingId: number) => {
        const recruiting = recruitingsById[recruitingId];
        accumulator.push(recruiting);

        return accumulator;
    }, []);
};

export const getRecruitingPageRequestObjectForRecruitingListView = (store: IStore) => {
    const sorting = store.recruitingPage.recruitingListViewSorting;
    const filter = store.recruitingPage.recruitingListViewFilter;
    filter.start = 0;
    filter.limit = 0;

    return buildRequestObject(sorting, filter);
};

export const getRecruitingsForListViewFromStore = (store: IStore): IRecruitingListModel[]  => {
    const order = store.entities.recruiting.orderListView;
    const recruitingsById = store.entities.recruiting.listModelById;

    return order.reduce((accumulator: IRecruitingListModel[], suggestionId) => {
        accumulator.push(recruitingsById[suggestionId]);

        return accumulator;
    }, []);
};

export const getRecruitingsForVacancy = (store: IStore, id: number) => {
    const recruitingsById = store.entities.recruiting.listModelById;
    const recruitingIdsByVacancyId = store.entities.recruiting.byVacancy[id] || [];

    const recruitings: IRecruitingListModel[] = [];

    recruitingIdsByVacancyId.forEach((recruitingId) => recruitings.push(recruitingsById[recruitingId]));

    return recruitings;
};

export const getRecruitingsForChartSelection = (store: IStore) => {
    const recruitingsById = store.entities.recruiting.listModelById;
    const order = store.entities.recruiting.orderChartSelection;

    return order.reduce((accumulator: IRecruiting[], recruitingId: number) => {
        const recruiting = recruitingsById[recruitingId];
        accumulator.push(recruiting);

        return accumulator;
    }, []);
};

export const getRecruitingsForCandidate = (store: IStore, candidateId: number) => {
    const recruitingsById = store.entities.recruiting.listModelById;
    const recruitingIdsByCandidateId = store.entities.recruiting.byCandidate[candidateId] || [];

    const recruitings: IRecruitingListModel[] = [];

    recruitingIdsByCandidateId.forEach((recruitingId) => recruitings.push(recruitingsById[recruitingId]));

    return recruitings;
};

export const getRecruitingsForCompany = (store: IStore, companyId: number) => {
    const recruitingsById = store.entities.recruiting.listModelById;
    const recruitingIdsByCompanyId = store.entities.recruiting.byCompany[companyId] || [];

    const recruitings: IRecruitingListModel[] = [];

    recruitingIdsByCompanyId.forEach((recruitingId) => recruitings.push(recruitingsById[recruitingId]));

    return recruitings;
};

export const getRecruitingsForEmployee = (store: IStore) => {
    const currentEmployee = getLoggedInEmployeeFromStore(store);
    const recruitingsById = store.entities.employeeRecruiting.byId;
    const recruitingIdsByEmployeeId = store.entities.employeeRecruiting.byEmployee[currentEmployee.id] || [];

    const recruitings: IRecruiting[] = [];

    recruitingIdsByEmployeeId.forEach((recruitingId) => recruitings.push(recruitingsById[recruitingId]));

    return recruitings;
};

export const getRecruitingsOfLast7Days = (store: IStore) => {
    const recruitingsById = store.entities.recruiting.listModelById;
    const order = store.entities.recruiting.orderLast7Days;

    return order.reduce((accumulator: IRecruitingListModel[], recruitingId: number) => {
        const recruiting = recruitingsById[recruitingId];
        accumulator.push(recruiting);

        return accumulator;
    }, []);
};

export const getVacancyPageRequestObjectForRecruitingsInEditView = (store: IStore) => {
    const sorting = store.vacancyPage.recruitingSorting;
    const filter = { start: 0, limit: 0, fastSearch: ""};

    return buildRequestObject(sorting, filter);
};

export const getCandidatePageRequestObjectForRecruitingsInEditView = (store: IStore) => {
    const sorting = store.candidatePage.recruitingSorting;
    const filter = store.candidatePage.filterRecruitingList;

    return buildRequestObject(sorting, filter);
};

export const getCompanyPageRequestObjectForRecruitingsInEditView = (store: IStore) => {
    const sorting = store.companyPage.recruitingSorting;
    const filter = store.companyPage.filterRecruitingList;

    return buildRequestObject(sorting, filter);
};

export const getRecruitingPageRequestObject = (store: IStore, status: ERecruitingStatus): Partial<IGetRequestObject> => {
    const filter = store.recruitingPage.filterRecruitingsGlobal;
    let sorting = {};

    switch (status) {
        case ERecruitingStatus.STATUS_SUGGEST:
            sorting = store.recruitingPage.sortingTabRecruitingsSuggest;
            filter.start = store.recruitingPage.filterTabRecruitingsSuggest.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsSuggest.limit;
            break;
        case ERecruitingStatus.STATUS_INTERVIEW:
            sorting = store.recruitingPage.sortingTabRecruitingsInterview;
            filter.start = store.recruitingPage.filterTabRecruitingsInterview.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsInterview.limit;
            break;
        case ERecruitingStatus.STATUS_CONTRACT:
            sorting = store.recruitingPage.sortingTabRecruitingsContract;
            filter.start = store.recruitingPage.filterTabRecruitingsContract.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsContract.limit;
            break;
        case ERecruitingStatus.STATUS_ABORT:
            sorting = store.archivePage.sortingTabRecruitingsAbort;
            filter.start = store.archivePage.filterTabRecruitingsAbort.start;
            filter.limit = store.archivePage.filterTabRecruitingsAbort.limit;
            filter.fastSearch = store.archivePage.filterTabRecruitingsAbort.fastSearch;
            break;
        case ERecruitingStatus.STATUS_FINISH:
            sorting = store.archivePage.sortingTabRecruitingsFinish;
            filter.start = store.archivePage.filterTabRecruitingsFinish.start;
            filter.limit = store.archivePage.filterTabRecruitingsFinish.limit;
            filter.fastSearch = store.archivePage.filterTabRecruitingsFinish.fastSearch;
            break;
        case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
            sorting = store.archivePage.sortingTabRecruitingsTrialNotFinished;
            filter.start = store.archivePage.filterTabRecruitingsTrialNotFinished.start;
            filter.limit = store.archivePage.filterTabRecruitingsTrialNotFinished.limit;
            filter.fastSearch = store.archivePage.filterTabRecruitingsTrialNotFinished.fastSearch;
            break;
    }

    return buildRequestObject(sorting, filter);
};
