export enum EBillingCalculationBy {
    CALCULATION_BY_YEAR_SALARY = 'CALCULATION_BY_YEAR_SALARY',
    CALCULATION_BY_MONTH_SALARY = 'CALCULATION_BY_MONTH_SALARY',
    CALCULATION_BY_FIX = 'CALCULATION_BY_FIX'
}

export enum EBillingType {
    BILLING_TYPE_CUSTOMIZE = 'BILLING_TYPE_CUSTOMIZE',
    BILLING_TYPE_TEMPLATE = 'BILLING_TYPE_TEMPLATE',
    BILLING_TYPE_HEADHUNTING = 'BILLING_TYPE_HEADHUNTING',
    BILLING_TYPE_PROJECT = 'BILLING_TYPE_PROJECT'
}
