import React from 'react';
import { ISkill } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { useLoadSkills } from './SkillAutocompleteDropDown.hook';
import CustomAutocompleteDropDown from './CustomAutocompleteDropDown';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: ISkill;
    label?: string;
}

const SkillAutocompleteDropDown: React.FC<IProps> = (props) => {
    const skills =  useLoadSkills();

    return (
        <CustomAutocompleteDropDown
            defaultValue={props.value}
            options={skills}
            getOptionLabel={(value: ISkill) => value.name}
            onChange={props.onChange}
            label={props.label ? props.label : translate('pages.candidate.properties.skills')}
        />
    );
};

export default SkillAutocompleteDropDown;
