/**
 * Custom Async Action Creator für maximale modularität bei Error handling
 *
 * @param actionType der Action type ohne suffix
 * @param request arrow function die ClienApi.request() returned
 */
import { IFile } from '../definitions/app.definitions';

export const asyncActionCreator = (
    actionType: string,
    request: () => any,
    meta?: any,
    successCallback?: (response: any) => void
) => async (dispatch: any) =>
{
    const pendingActionType = `${actionType}_PENDING`;
    const fulfilledActionType = `${actionType}_FULFILLED`;
    const rejectedActionType = `${actionType}_REJECTED`;

    dispatch({ type: pendingActionType, meta });

    request()
        .then((response: any) => {
            if (successCallback) {
                successCallback(response);
            }
            dispatch({
                type: fulfilledActionType,
                payload: response,
                meta,
            });
        })
        .catch((error: any) => {
            console.log(rejectedActionType);
            console.log(error);
            dispatch({
                type: rejectedActionType,
                payload: { errorMessage: error },
            });
        });
};

export const downloadFile = (result: IFile) => {
    const linkSource = `data:${result.fileType};base64,${result.fileContent}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = result.fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
