import React from 'react';
import { ICompanyContact, ICompanyContactPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CustomAutocompleteDropDown from './CustomAutocompleteDropDown';
import { useLoadContactsOfCompany } from './CompanyContactAutocompleteDropDown.hook';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: null|ICompanyContact|ICompanyContactPartial;
    label?: string;
    onAdd?: () => void;
    companyId?: number;
    disabled?: boolean;
}

const CompanyContactAutocompleteDropDown: React.FC<IProps> = (props) => {
    let contacts: ICompanyContact[] = [];

    if (props.companyId) {
        contacts = useLoadContactsOfCompany(props.companyId);
    }

    return (
        <CustomAutocompleteDropDown
            onAdd={props.onAdd ? () => props.onAdd?.() : undefined}
            options={contacts}
            disabled={props.disabled}
            defaultValue={props.value}
            onChange={props.onChange}
            label={props.label || translate('misc.companyContact')}
            getOptionLabel={(contact: ICompanyContact) => `${contact.firstName} ${contact.name}`}
        />
    );
};

export default CompanyContactAutocompleteDropDown;
