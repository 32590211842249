import React, { ReactNode } from 'react';
import { ECellAlign } from '../../definitions/components.definitions';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


interface IProps {
    entity: EEntities;
    record: TBrainzEntity;
    value: any;
    flex?: number;
    width?: number;
    cellRenderer?: (value: any, record: TBrainzEntity) => ReactNode;
    align?: ECellAlign;
    property: string;
    denyVerticalCenter: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        borderRight: "1px solid transparent",
    }
}));

const CustomTableCell: React.FC<IProps> = (props) => {
    let value = props.value;
    let flexConfig = "1";
    const styles = useStyles();

    if (props.flex) {
        flexConfig = props.flex.toString();
    } else if (props.width) {
        flexConfig = `0 0 ${props.width}px`;
    }

    if (props.cellRenderer && props.record) {
        value = props.cellRenderer(value, props.record);
    }

    return (
        <div
            className={
                clsx({
                    [styles.root]: true,
                    ['tableCell']: true,
                    ['flexContainerRow']: true,
                    ['alignVerticalCenter']: !props.denyVerticalCenter,
                    ['alignHorizontalLeft']: props.align === undefined || props.align === ECellAlign.left,
                    ['alignHorizontalCenter']: props.align !== undefined && props.align === ECellAlign.center,
                    ['alignHorizontalRight']: props.align !== undefined && props.align === ECellAlign.right
                })
            }
            style={{
                flex: flexConfig,
            }}
        >
            {value}
        </div>
    );
};

export default CustomTableCell;
