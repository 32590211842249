import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EApplicationStatus } from '../../../definitions/entities.definition';
import CustomCheckboxInput from '../CustomCheckboxInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: EApplicationStatus[];
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const ApplicationStatusMultipleDropDown: React.FC<IProps> = (props) => {
    const applicationStatuses: EApplicationStatus[] = [
        EApplicationStatus.open,
        EApplicationStatus.inspection,
        EApplicationStatus.declined,
        EApplicationStatus.recruiting,
    ];
    const menuItems = applicationStatuses.map((status: EApplicationStatus, index: number) => (
        <MenuItem key={index + 1} value={status}>
            <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(status) > -1)} />
            {translate('pages.application.statusValue.' + status)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            disabled={props.disabled}
            onChange={props.onChange}
            value={props.value}
            id={"application-status-dropdown"}
            error={props.error}
            multiple
            helperText={props.helperText}
            label={translate('pages.candidate.applicationStatus')}
            renderValue={(selected) =>
                selected
                    .map((status: EApplicationStatus, index) => <>{translate('pages.application.statusValue.' + status)}{ props.value && props.value.length - 1 === index ? '' : ', '}</>)
            }
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default ApplicationStatusMultipleDropDown;
