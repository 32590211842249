import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { translate } from '../../translation/translate.utils';
import CustomTabContainer from '../../components/CustomTabContainer/CustomTabContainer';
import VacancyArchivedList from './components/VacancyArchivedList';
import { IStore } from '../../definitions/redux/store.definitions';
import { setArchivePageActiveTabAction } from '../../redux/archivePage/archivePage.actions';
import { EArchivePageTabs } from '../../definitions/archivePage.definitions';
import { getRecruitingPageRequestObject, getRecruitingsWithStatus } from '../../selectors/recruiting.selectors';
import { EEntities, ERecruitingStatus } from '../../definitions/entities.definition';
import RecruitingList from '../RecruitingPage/components/RecruitingList';
import { getAllRecruitingAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { themeDark } from '../../theme/theme';

class ArchivePage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        if (this.props.globalFilter !== prevProps.globalFilter ||
            this.props.sortingAbort !== prevProps.sortingAbort ||
            JSON.stringify(this.props.filterAbort) !== JSON.stringify(prevProps.filterAbort)
        ) {
            this.props.getAllRecruitingAction(
                this.props.token,
                this.props.requestObjectStatusAbort,
                ERecruitingStatus.STATUS_ABORT
            );
        }

        if (this.props.globalFilter !== prevProps.globalFilter ||
            this.props.sortingFinish !== prevProps.sortingFinish ||
            JSON.stringify(this.props.filterFinish) !== JSON.stringify(prevProps.filterFinish)
        ) {
            this.props.getAllRecruitingAction(
                this.props.token,
                this.props.requestObjectStatusFinish,
                ERecruitingStatus.STATUS_FINISH
            );
        }

        if (this.props.globalFilter !== prevProps.globalFilter ||
            this.props.sortingTrialPeriodFailed !== prevProps.sortingTrialPeriodFailed ||
            JSON.stringify(this.props.filterTrialPeriodFailed) !== JSON.stringify(prevProps.filterTrialPeriodFailed)
        ) {
            this.props.getAllRecruitingAction(
                this.props.token,
                this.props.requestObjectStatusTrialPeriodFailed,
                ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD
            );
        }
    }

    render() {
        return (
            <>
                <CustomTabContainer
                    color={themeDark.palette.primary.main}
                    activeTab={this.props.activeTab}
                    onTabChange={(tabId: EArchivePageTabs) => this.props.setArchivePageActiveTabAction(tabId)}
                    tabs={[{
                        label: translate('pages.vacancy.title'),
                        component: (<VacancyArchivedList/>),
                    }, {
                        label: translate('pages.recruiting.statusValues.finish'),
                        component: (
                            <RecruitingList
                                status={ERecruitingStatus.STATUS_FINISH}
                                recruitings={this.props.recruitingsStatusFinish}
                                entity={EEntities.RecruitingsFinish}
                            />
                        ),
                    }, {
                        label: translate('pages.recruiting.statusValues.abort'),
                        component: (
                            <RecruitingList
                                status={ERecruitingStatus.STATUS_ABORT}
                                recruitings={this.props.recruitingsStatusAbort}
                                entity={EEntities.RecruitingsAbort}
                            />
                        ),
                    }, {
                        label: translate('pages.recruiting.statusValues.failed_trial_period'),
                        component: (
                            <RecruitingList
                                status={ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD}
                                recruitings={this.props.recruitingsStatusTrialPeriodFailed}
                                entity={EEntities.RecruitingsTrialPeriodFailed}
                            />
                        ),
                    }]}
                />
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    token: getToken(store),
    activeTab: store.archivePage.activeTab,
    recruitingsStatusAbort: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_ABORT),
    requestObjectStatusAbort: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_ABORT),
    recruitingsStatusTrialPeriodFailed: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD),
    requestObjectStatusTrialPeriodFailed: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD),
    recruitingsStatusFinish: getRecruitingsWithStatus(store, ERecruitingStatus.STATUS_FINISH),
    requestObjectStatusFinish: getRecruitingPageRequestObject(store, ERecruitingStatus.STATUS_FINISH),
    sortingAbort: store.archivePage.sortingTabRecruitingsAbort,
    sortingTrialPeriodFailed: store.archivePage.sortingTabRecruitingsTrialNotFinished,
    sortingFinish: store.archivePage.sortingTabRecruitingsFinish,
    filterAbort: store.archivePage.filterTabRecruitingsAbort,
    filterTrialPeriodFailed: store.archivePage.filterTabRecruitingsTrialNotFinished,
    filterFinish: store.archivePage.filterTabRecruitingsFinish,
    globalFilter: store.recruitingPage.filterRecruitingsGlobal,

});
const mapDispatch = {
    getAllRecruitingAction,
    setArchivePageActiveTabAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ArchivePage);
