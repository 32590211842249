import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { resetFormValidation } from '../../../../redux/error/error.actions';
import {
    setBillingPageAddInvoiceDialogAction,
    setBillingPageInvoiceToEditAction,
    setBillingPageReloadInvoiceListAction,
} from '../../../../redux/billingPage/billingPage.actions';
import { useLoadInvoice, useRecalculate } from './InvoiceAddEditView.hook';
import CustomCellTopBar from '../../../../components/ListAndPreview/CustomCellTopBar';
import InvoiceItemsView from './InvoiceItemsView';
import InvoiceFooterView from './InvoiceFooterView';
import InvoiceHeaderView from './InvoiceHeaderView';
import { translate } from '../../../../translation/translate.utils';
import { IInvoiceForApi } from '../../../../definitions/entities.definition';
import { addInvoiceAction, updateInvoiceAction } from '../../../../redux/entities/entities.actions';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { getToken } from '../../../../selectors/app.selectors';
import moment from 'moment';
import { API_URL } from '../../../../requests/routes';
import InvoicePaymentRemindView from './InvoicePaymentRemindView';
import InvoiceDunningLetterView from './InvoiceDunningLetterView';
import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import { EDocumentStatus } from '../../../../definitions/billingPage.definitions';
import { useDrawerStyles } from '../../../App/RightDrawer/Drawer.styles';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';

const InvoiceAddEditView: React.FC = () => {
    const drawerClasses = useDrawerStyles();
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const addInvoice = useSelector((store: IStore) => Boolean(store.billingPage.addInvoice));
    const editInvoice = useSelector((store: IStore) => Boolean(store.billingPage.invoiceToEdit));
    const invoiceId = useSelector((store: IStore) => store.billingPage.invoiceToEdit);
    const invoice = useSelector((state: IStore) => state.entities.invoice.byId[invoiceId]);
    const invoiceForm = useSelector((state: IStore) => state.billingPage.invoiceForm);
    const invoiceFormItems = useSelector((state: IStore) => state.billingPage.invoiceForm.items);

    useLoadInvoice();
    useRecalculate();

    const onClose = () => {
        dispatch(setBillingPageInvoiceToEditAction(0));
        dispatch(setBillingPageAddInvoiceDialogAction(false));
        dispatch(resetFormValidation());
    }

    const open = addInvoice || editInvoice;

    const onSave = (closeWindow: boolean) => {
        const invoiceForApi: IInvoiceForApi = {
            number: invoiceForm.numberDisabled ? undefined : invoiceForm.number,
            date: moment(invoiceForm.date).format("Y-MM-DD"),
            dueDate: moment(invoiceForm.dueDate).format("Y-MM-DD"),
            companyId: invoiceForm.company?.id,
            companyLocationId: invoiceForm.companyLocation?.id,
            companyContactId: invoiceForm.companyContact ? invoiceForm.companyContact.id : null,
            companyName: invoiceForm.companyName,
            address: invoiceForm.address,
            addressLine2: invoiceForm.addressLine2,
            zipCode: invoiceForm.zipCode,
            city: invoiceForm.city,
            discountInPercent: invoiceForm.discountInPercent,
            discountSum: invoiceForm.discountSum,
            comment: invoiceForm.comment,
            items: invoiceFormItems,
            termsOfPaymentId: invoiceForm.termsOfPaymentId,
            remindOfPaymentCreated: invoiceForm.remindOfPaymentCreated,
            remindOfPaymentDate: invoiceForm.remindOfPaymentDate ? moment(invoiceForm.remindOfPaymentDate).format("Y-MM-DD") : null,
            remindOfPaymentDueDate: invoiceForm.remindOfPaymentDueDate ? moment(invoiceForm.remindOfPaymentDueDate).format("Y-MM-DD") : null
        };

        if (editInvoice) {
            invoiceForApi.id = invoice.id;
            dispatch(updateInvoiceAction(token, invoiceForApi, closeWindow));
        } else {
            dispatch(addInvoiceAction(token, invoiceForApi));
        }

        return;
    }

    return <>
        <Drawer
            id={"entity-edit-view-drawer"}
            anchor={"right"}
            variant={"persistent"}
            classes={{paper: drawerClasses.drawerOpen + ' ' + drawerClasses.entityView + ' ' + drawerClasses.boxShadow + ' ' + drawerClasses.drawerBringToTop}}
            open={open}
            PaperProps={{ style: { width: 900 }}}
        >
            <div className={'flexAutoGrow flexContainerRow marginTop5'}>
                <Typography style={{ marginLeft: 10, minWidth: 400, fontSize: 16, color: 'var(--brainz-color-app-bar-heading)' }} variant="h6" noWrap>
                    {translate(addInvoice ? "pages.billing.addInvoice" : "pages.billing.editInvoice")}
                    <div className={"formContent flexContainerRow alignVerticalCenter"}>
                        { invoice && <CustomCellTopBar
                            company={invoice.company}
                            vacancy={invoice.vacancy}
                            candidate={invoice.recruiting?.candidate}
                            recruiting={invoice.recruiting}
                        /> }
                    </div>
                </Typography>
                <div className={'flex1 flexContainerRow flexRight marginTop5'}>
                    <Tooltip title={"Datensatz schliessen"}>
                        <IconButton onClick={onClose}>
                            {IconFactory.getIconComponent(EIcons.Close)}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div style={{ display:"flex", marginTop:-3, flexDirection: "column", height: "calc(100% - 70px)", width: "100%"}}>
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <InvoiceHeaderView formState={invoiceForm} addInvoice={addInvoice}/>
                    {invoice && (invoice.isRemindAble || invoice.remindOfPaymentCreated) &&
                        <InvoicePaymentRemindView formState={invoiceForm} />
                    }
                    <InvoiceDunningLetterView invoice={invoice} />
                    <InvoiceItemsView items={invoiceFormItems} />
                    <InvoiceFooterView formState={invoiceForm} />
                </div>
                <CustomDialogActions
                    onClose={onClose}
                    buttons={[{
                        void: () => {
                            window.open(`${API_URL}/invoice/${invoiceId}/print?Authorization=${token}`);
                            dispatch(setBillingPageReloadInvoiceListAction(true));
                        },
                        label: translate('misc.print'),
                        hidden: addInvoice
                    }, {
                        void: () => onSave(false),
                        label: translate('misc.buttons.save'),
                        hidden: invoice?.status === EDocumentStatus.closed
                    }, {
                        void: () => onSave(true),
                        label: translate('misc.buttons.saveAndClose'),
                        hidden: addInvoice || invoice?.status === EDocumentStatus.closed
                    }]}
                />
            </div>
        </Drawer>
    </>;
}
export default InvoiceAddEditView;

