import React, { useEffect } from 'react';
import { translate } from '../../translation/translate.utils';
import CustomTabContainer from '../../components/CustomTabContainer/CustomTabContainer';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getInvoiceListAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { getBillingPageInvoiceListRequestObject } from '../../selectors/billing.selectors';
import InvoiceList from './components/InvoiceList';
import InvoiceGenerationDialog from './components/InvoiceGenerationDialog';
import AddPaymentDialog from './components/components/AddPaymentDialog';
import BillingPageDashboard from './components/BillingPageDashboard';
import { EBillingPageTabs } from '../../definitions/billingPage.definitions';
import { setBillingPageActiveTabAction } from '../../redux/billingPage/billingPage.actions';
import { themePurple } from '../../theme/theme';

const BillingPage: React.FC = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector((store: IStore) => store.billingPage.activeTab);
    const getInvoiceListRequestObject = useSelector(getBillingPageInvoiceListRequestObject);
    const token = useSelector(getToken);
    const invoiceSorting = useSelector((store: IStore) => JSON.stringify(store.billingPage.sortingInvoiceList));
    const invoiceFilter = useSelector((store: IStore) => JSON.stringify(store.billingPage.filterInvoiceList));
    const reloadInvoiceList = useSelector((store: IStore) => store.billingPage.reloadInvoiceList);

    useEffect(() => {
        dispatch(getInvoiceListAction(token, getInvoiceListRequestObject))
    }, [dispatch, invoiceSorting, invoiceFilter]);

    useEffect(() => {
        if (reloadInvoiceList) {
            dispatch(getInvoiceListAction(token, getInvoiceListRequestObject))
        }
    }, [reloadInvoiceList]);

    return (
        <>
            <InvoiceGenerationDialog />
            <AddPaymentDialog />
            <div className={"marginTop5 marginLeft5 flexContainerColumn flex1"} style={{overflow: 'hidden'}}>
                <CustomTabContainer
                    color={themePurple.palette.primary.main}
                    activeTab={activeTab}
                    onTabChange={(tabId: EBillingPageTabs) =>dispatch(setBillingPageActiveTabAction(tabId))}
                    tabs={[{
                        label: translate('pages.dashboard.title'),
                        component: (<BillingPageDashboard />),
                    }, {
                        label: translate('pages.billing.invoices'),
                        component: (<InvoiceList />),
                    }]}
                />
            </div>
        </>
    );
}

export default BillingPage;
