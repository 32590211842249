import React, { memo } from 'react';
import { ICandidatePartial, ICandidateProfiles } from '../../definitions/entities.definition';
import CandidateProfilesRenderer from './CandidateProfilesRenderer';
import { API_URL } from '../../requests/routes';
import { useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';

interface IProps {
    candidate: ICandidatePartial;
    profiles?: ICandidateProfiles;
}

const CandidateCellRendererPopup: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);

    return <div>
        <b>{props.candidate.fullName}</b><br/>
        {props.profiles?.photoId &&
            <img width={200} src={API_URL + "/candidate-document/" + props.profiles?.photoId + "/preview?Authorization=" + token}/>
        }
        <div className={"flexContainerRow"}>
            <CandidateProfilesRenderer profiles={props.profiles} />
        </div>
    </div>
};

export default memo(CandidateCellRendererPopup);
