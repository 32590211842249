import { EIcons } from '../Icons/IconFactory';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import CustomTextField from './CustomTextField';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from './CustomClickableIcon';
import styles from './CustomEditableValue.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { setForceExitFastEditAction } from '../../redux/ui/ui.actions';

interface IProps {
    value?: any;
    editAction?: (value?: any) => void;
    rows?: number
    theme?: 'dark'|'light'
    inputType?: 'text'|'number'
    input?: ReactNode;
    inputStyle?: CSSProperties;
}

const CustomEditableValue: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const initialValue = props.value;
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(props.value);
    const forceExit = useSelector((store: IStore) => store.ui.forceExitFastEdit);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            const key = e.key;
            if (key === 'Escape') {
                setEditMode(false);
                setValue(props.value);
            }
        };

        const handleOutsideClick = (e: any) => {
            //@ts-ignore-next-line
            if (e.target.id === 'editableValue' ||
                (e.target.className && typeof e.target.className === 'string' && e.target.className.search('MuiOutlinedInput-input') !== -1) ||
                e.target.getAttribute('role') === 'option' ||
                e.target.nodeName === 'path' ||
                e.target.nodeName === 'INPUT' ||
                e.target.nodeName === 'svg') {
                return;
            }
            setEditMode(false);
        };

        document.addEventListener("mousedown", handleOutsideClick);
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousedown', handleOutsideClick);
        }
    });

    useEffect(() => {
        if (forceExit) {
            setEditMode(false);
            dispatch(setForceExitFastEditAction(false));
        }
    }, [forceExit])

    const onSave = () => {
        if (initialValue !== value && props.editAction) {
            props.editAction(value);
        }
        setEditMode(false);
    }

    if (!editMode) {
        return <div
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setEditMode(true)
            }}
            className={styles.root + ' ' + styles[props.theme || 'dark']}
        >
            {props.value
                ? <>{props.children}</>
                : <div className={"formContent" + ' ' + styles[props.theme || 'dark']}>
                    <i>Hier klicken zum eintragen</i>
                </div>
            }
        </div>;
    }

    if (props.input) {
        return (
            <div
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                style={{width: '100%'}}
                className={"flexContainerRow alignVerticalCenter flex1"}
            >
                {props.input}
                {props.editAction &&
                    <CustomClickableIcon onClick={onSave} icon={EIcons.Save}/>
                }
            </div>
        );
    }

    return <>
        <CustomTextField
            type={props.inputType || 'text'}
            id={"editableValue"}
            InputProps={{
                autoFocus: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <CustomClickableIcon onClick={onSave} icon={EIcons.Save}/>
                    </InputAdornment>
                )
            }}
            multiline={Boolean(props.rows && props.rows > 1)}
            rows={props.rows || 1}
            value={value}
            onChange={(value) => setValue(value)}
        />
    </>
}

export default CustomEditableValue;
