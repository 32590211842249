import React from 'react';
import { IVacancy, IVacancyPartial } from '../../../../definitions/entities.definition';
import VacancyAutocomplete from '../components/VacancyAutocomplete';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: IVacancy|IVacancyPartial;
    label?: string;
    companyId: number;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
}

const LegacyCompanyVacancyAutocompleteDropDown: React.FC<IProps> = (props) => {
    return (
        <VacancyAutocomplete
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            value={props.value || null}
            onChange={(value) => {
                //@ts-ignore
                props.onChange({}, value);
            }}
            label={props.label}
            companyId={props.companyId}
        />
    );
}

export default LegacyCompanyVacancyAutocompleteDropDown;
