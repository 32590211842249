import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import { ICompany } from '../../../../definitions/entities.definition';
import {
    setBillingPageInvoiceListFilterAction,
    setBillingPageInvoiceListFilterMenuAction,
} from '../../../../redux/billingPage/billingPage.actions';
import LegacyCompanyAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import { preloadedInvoiceFilter, preloadedInvoiceFilterMenu } from '../../../../definitions/billingPage.definitions';
import DocumentStatusDropDown from '../../../CustomInput/CustomDropDownInput/DocumentStatusDropDown';
import moment from 'moment';
import CustomDatePicker from '../../../CustomInput/CustomDatePicker';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuInvoice: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.billingPage.filterInvoiceList);
    const filterMenu = useSelector((store: IStore) => store.billingPage.filterMenuInvoiceList);

    const submit = () => {
        dispatch(
            setBillingPageInvoiceListFilterAction({
                start: filter.start,
                limit: filter.limit,
                dateFrom: filterMenu.dateFrom,
                dateTo: filterMenu.dateTo,
                status: filterMenu.status,
                fastSearch: filter.fastSearch,
                companyId: filterMenu.company ? filterMenu.company.id : undefined
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem className={"gap5"}>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(setBillingPageInvoiceListFilterAction(preloadedInvoiceFilter));
                        dispatch(setBillingPageInvoiceListFilterMenuAction(preloadedInvoiceFilterMenu));
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <DocumentStatusDropDown
                    label={"Status"}
                    value={filterMenu.status}
                    onChange={(event) => dispatch(setBillingPageInvoiceListFilterMenuAction({status: event.target.value}))}
                />
            </MenuItem>
            <MenuItem>
                <LegacyCompanyAutocompleteDropDown
                    value={filterMenu.company}
                    onChange={(event, company: ICompany) => dispatch(setBillingPageInvoiceListFilterMenuAction({company}))}
                />
            </MenuItem>
            <MenuItem className={"gap5"}>
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateFrom')}
                    value={filterMenu.dateFrom}
                    onChange={(value) => dispatch(setBillingPageInvoiceListFilterMenuAction({ dateFrom: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
                <CustomDatePicker
                    flex
                    label={translate('pages.activity.filter.dateTo')}
                    value={filterMenu.dateTo}
                    onChange={(value) => dispatch(setBillingPageInvoiceListFilterMenuAction({ dateTo: value ? moment(value).format('YYYY-MM-DD') : value }))}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuInvoice;
