import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { setUiCompanyContactAddDataAction } from '../../../redux/ui/ui.actions';
import CompanyContactForm from './CompanyContactForm';
import { resetFormValidation } from '../../../redux/error/error.actions';
import { Dialog } from '@material-ui/core';

const CompanyContactAddView: React.FC = () => {
    const dispatch = useDispatch();
    const addCompanyContact = useSelector((store: IStore) => Boolean(store.ui.addCompanyContactData.companyId));

    const onClose = () => {
        dispatch(setUiCompanyContactAddDataAction({companyId: 0}));
        dispatch(resetFormValidation());
    }

    if (!addCompanyContact) {
        return null
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth onClose={onClose}>
            <CustomDialogHeader translationString={"pages.companyContact.add"} onClose={onClose} />
            <CompanyContactForm onClose={onClose} />
        </Dialog>
    );
}
export default CompanyContactAddView;

