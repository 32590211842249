import { createStyles, FormControlLabel, makeStyles, Radio } from '@material-ui/core';
import React from 'react';
import { translate } from '../../translation/translate.utils';
import { COLOR_TEXT } from '../../theme/theme';

interface IProps {
    checked: boolean;
    onChange?: (event: any, value: boolean) => void;
    labelTranslation?: string;
    label?: string;
    withoutMargin?: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: "var(--brainz-color-text-secondary)",
        },
        rootWithoutMargin: {
            color: "var(--brainz-color-text-secondary)",
        },
        checkbox: {
            color: COLOR_TEXT,
            "&$checked": {
                color: COLOR_TEXT,
            },
        },
    })
);

const CustomRadioInput: React.FC<IProps> = (props) => {
    const styles = useStyles();

    return (
        <FormControlLabel
            control={<Radio
                color={"primary"}
                className={styles.checkbox}
                checked={props.checked}
                onChange={props.onChange}
            />}
            label={props.label ? props.label : props.labelTranslation ? translate(props.labelTranslation) : ''}
            className={props.withoutMargin ? styles.rootWithoutMargin : styles.root}
        />
    );
};

export default CustomRadioInput;
