import React from 'react';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../../../definitions/components.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    ECandidateSource,
    EEntities,
    IEmployee,
    IVacancyOrWebVacancy,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import {
    addSuggestionAction,
    getVacanciesForCandidateSearchAction,
} from '../../../../redux/entities/entities.actions';
import { themeGreenLight } from '../../../../theme/theme';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCandidatePageVacancySearchFilterAction,
    setCandidatePageVacancySearchSortingOptionAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import { getDefaultCompanyColumn, INFINITE_SCROLL_INTERVAL } from '../../../../utils/components.utils';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getToken } from '../../../../selectors/app.selectors';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import {
    getAllVacanciesForCandidateFromStore,
    getCandidatePageVacancySearchListRequestObject,
} from '../../../../selectors/vacancy.selectors';
import { translate } from '../../../../translation/translate.utils';
import WebVacancyCellRenderer from '../../../../components/CustomCellRenderer/WebVacancyCellRenderer';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import DateDiffCellRenderer from '../../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';
import { ISkillMatchResult } from '../../../../definitions/matching.definitions';
import MatchingSkillsCellRenderer from '../../../../components/CustomCellRenderer/MatchingSkillsCellRenderer';
import { Tooltip } from '@material-ui/core';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';

interface IProps {
    candidateId: number;
}

const SearchVacancyList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const candidates = useSelector(getAllVacanciesForCandidateFromStore);
    const total = useSelector((store: IStore) => store.entities.vacancyAndWebVacancy.totalCandidateSearch);
    const loading = useSelector((store: IStore) => store.ui.candidateVacancySearchLoading);
    const filter = useSelector((store: IStore) => store.candidatePage.filterVacancyList);
    const requestObject = useSelector(getCandidatePageVacancySearchListRequestObject);

    const columnConfig: ICustomTableColumnConfig = {
        vacancy: {
            header: translate('misc.vacancy'),
            property: "title",
            orderByProperty: 'vacancy',
            width: 300,
            cellRenderer: (title: string, entity: TBrainzEntity) => {
                const matchModel = entity as IVacancyOrWebVacancy;

                let hintElement = null;

                if (matchModel.isSuggested) {
                    hintElement = <>
                        <Tooltip title={"Kandidat ist hier bereits als Favorit hinterlegt"}>
                            {IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: 'var(--brainz-color-yellow)'})}
                        </Tooltip>
                        &nbsp;
                    </>;
                }
                if (matchModel.isRecruiting) {
                    hintElement = <>
                        <Tooltip title={"Kandidat ist hier bereits im Prozess"}>
                            {IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: 'var(--brainz-color-green)'})}
                        </Tooltip>
                        &nbsp;
                    </>;
                }

                if (matchModel.webVacancy) {
                    return <>
                        {hintElement}
                        <WebVacancyCellRenderer
                            id={matchModel.webVacancy.id}
                            title={matchModel.webVacancy.title}
                        />
                    </>
                }

                if (matchModel.vacancy) {
                    return <>
                        {hintElement}
                        <VacancyCellRenderer vacancy={matchModel.vacancy}/>
                    </>
                }

                return null;
            }
        },
        company: getDefaultCompanyColumn(),
        locations: {
            header: translate('pages.webVacancy.locations'),
            property: "id",
            orderByProperty: 'locations',
            width: 200,
            disableOrder: true,
            cellRenderer: (value: number, entity: TBrainzEntity) => {
                const entityCast = entity as IVacancyOrWebVacancy;

                if (entityCast.distanceLocation) {
                    return entityCast.distanceLocation;
                }

                if (entityCast.webVacancy) {
                    return entityCast.webVacancy.locations.join(', ');
                }

                return entityCast.vacancy?.companyLocation.city;
            }
        },
        responsibleEmployee: {
            header: translate('misc.employeeShort'),
            property: "responsibleEmployee",
            width: 60,
            align: ECellAlign.center,
            cellRenderer: (employee: IEmployee) => {
                return <EmployeeCellRenderer employee={employee} />;
            },
        },
        onlineSince: {
            header: translate('pages.webVacancy.onlineSince'),
            property: "created",
            width: 120,
            align: ECellAlign.right,
            cellRenderer: (created: string) => <DateDiffCellRenderer date={created} beforeText={'Seit'} />,
        },
        distanceAndDuration: {
            header: translate('pages.candidate.properties.distance'),
            property: "distanceText",
            orderByProperty: 'distance',
            width: 120,
            cellRenderer: (value: string, entity: TBrainzEntity) => {
                const entityCast = entity as IVacancyOrWebVacancy;

                return <>
                    { entityCast.durationText }
                    <br/>
                    { entityCast.distanceText }
                </>
            }
        },
        matchedSkills: {
            disableOrder: true,
            header: translate('pages.candidate.matchedSkills'),
            property: "matchedSkills",
            flex: 1,
            cellRenderer: (matchResult: ISkillMatchResult) => {
                if (!matchResult.matches || matchResult.matches.length === 0) {
                    return <>Keine Matches</>;
                }
                return <MatchingSkillsCellRenderer skills={matchResult.matches} />;
            },
        },
    }

    if (!filter.distance) {
        delete columnConfig.distanceText;
        delete columnConfig.durationText;
    }

    if (!filter.optionalSkillIds || filter.optionalSkillIds.length === 0) {
        delete columnConfig.matchedOptionalSkillIds;
    }

    return (
        <CustomTable
            config={{
                color: themeGreenLight.palette.primary.main,
                entity: EEntities.CandidateVacancySearch,
                loading,
                columnConfig: columnConfig,
                sortedEntries: candidates,
                onRowDoubleClick: (id, entity) => {
                    const entityCast = entity as IVacancyOrWebVacancy;
                    if (entityCast.vacancy) {
                        dispatch(setUiEditView(EEntityView.vacancy, entityCast.vacancy.id));
                        return
                    }

                    if (entityCast.webVacancy) {
                        dispatch(setUiEditView(EEntityView.webVacancy, entityCast.webVacancy.id));
                        return
                    }
                },
                listActionButtons: [{
                    translationKey: "pages.vacancy.startRecruiting",
                    icon: EIcons.ThumbsUpDown,
                    action: (id, entity: TBrainzEntity) => {
                        const entityCast = entity as IVacancyOrWebVacancy;

                        if (entityCast.webVacancy) {
                            dispatch(setRecruitingPageStartRecruitingAction({
                                open: true,
                                candidateId: props.candidateId,
                                webVacancyId: entityCast.webVacancy.id
                            }))
                            return;
                        }

                        if (entityCast.vacancy) {
                            dispatch(setRecruitingPageStartRecruitingAction({
                                open: true,
                                candidateId: props.candidateId,
                                vacancyId: entityCast.vacancy.id
                            }))
                        }
                        return;
                    },
                }, {
                    condition: (entity) => {
                        const entityCast = entity as IVacancyOrWebVacancy;
                        return Boolean(entityCast.vacancy);
                    },
                    action: (id, entity: TBrainzEntity) => {
                        const entityCast = entity as IVacancyOrWebVacancy;

                        if (!entityCast.vacancy) {
                            return;
                        }

                        dispatch(addSuggestionAction(token, {
                            candidateId: props.candidateId,
                            source: ECandidateSource.brainz,
                            vacancyId: entityCast.vacancy?.id,
                            suggestedByEmployeeId:loggedInEmployee.id,
                            responsibleEmployeeId: loggedInEmployee.id
                        }));
                    },
                    translationKey: "pages.vacancy.suggestCandidate",
                    icon: EIcons.Suggestion
                }],
                count: candidates.length,
                total: total,
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCandidatePageVacancySearchSortingOptionAction(propertyToSort, sortOption)),
                },
                scrollCallback: () => {
                    if (filter.start !== undefined && filter.limit < total) {
                        dispatch(setCandidatePageVacancySearchFilterAction({
                            start: 0,
                            limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                        }));
                    }
                },
                onReload: () => {
                    dispatch(getVacanciesForCandidateSearchAction(
                        token,
                        requestObject
                    ));
                }
            }}
        />
    )
};

export default SearchVacancyList;
