import { ISetMailingFilterAction, TReducerAction } from '../../definitions/redux/actions.definition';
import { IMailingState, preloadedMailingState } from '../../definitions/mailing.definitions';
import {
    ENTITIES_GET_MAIL_FULFILLED,
    ENTITIES_GET_MAIL_LIST_PENDING,
    ENTITIES_GET_MAIL_PENDING,
    ENTITIES_GET_MAIL_REJECTED,
} from '../entities/entities.actions';
import { MAILING_SET_FILTER, MAILING_SET_RELOAD } from './mailing.actions';

const mailingReducer = (state = preloadedMailingState, action: TReducerAction): IMailingState => {
    switch (action.type) {
        case ENTITIES_GET_MAIL_LIST_PENDING: {
            return {
                ...state,
                reload: false
            }
        }
        case ENTITIES_GET_MAIL_PENDING: {
            return {
                ...state,
                loading: true
            }
        }
        case ENTITIES_GET_MAIL_REJECTED:
        case ENTITIES_GET_MAIL_FULFILLED: {
            return {
                ...state,
                loading: false
            }
        }
        case MAILING_SET_RELOAD: {
            return {
                ...state,
                reload: true
            }
        }
        case MAILING_SET_FILTER: {
            const actionCast = action as ISetMailingFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload
                }
            }
        }
    }

    return {
        ...state
    };
}

export default mailingReducer;
