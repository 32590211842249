import { IApiBaseFilter, ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import { IBranch, ICompanyPartial, IEmployeePartial, ILanguage, ISkill } from './entities.definition';
import {
    ICandidateFilterApiProperties,
    ICandidateFilterMenu,
    preloadedCandidatePageFilter,
    preloaededCandidatePageFilterMenu,
} from './candidatePage.definitions';
import { EDateRangeMode, IDateRangeFilterApiProperties } from './filter.definitions';

export interface IWebVacancyFilter extends Partial<IApiBaseFilter> {
    responsibleEmployeeId?: number;
    branchIds?: number[];
    companyIds?: number[];
    skillIds?: number[];
    onlyWithContracts?: boolean;
    location?: string;
    searchForCandidateId?: number;
    distance?: number;
    radius?: number;
    languageId?: number;
    onlyPartTime?: boolean;
    remote?: boolean;
    showHidden: boolean;
    onlyEqualToVacancyTitles?: boolean,
    dateRangeFilter: IDateRangeFilterApiProperties;
}

export interface IWebVacancyFilterMenu {
    responsibleEmployee: IEmployeePartial|null;
    branches: IBranch[];
    companies: ICompanyPartial[];
    skills: ISkill[];
    onlyWithContracts?: boolean;
    onlyEqualToVacancyTitles?: boolean;
    location?: string;
    radius?: number;
    distance?: number;
    languages: ILanguage[];
    onlyPartTime?: boolean;
    remote?: boolean;
    showHidden: boolean;
    dateRangeFilter: IDateRangeFilterApiProperties;
}

export enum EWebVacancyEditViewTabs {
    data,
    candidateSearch,
    activityList
}

export interface IWebVacancyPageState {
    activeTabWebVacancyEditView: EWebVacancyEditViewTabs;
    sorting: ISorting;
    filter: IWebVacancyFilter;
    filterMenu: IWebVacancyFilterMenu;
    importId: number;
    reload: boolean;
    activitySorting: ISorting;
    activityFastSearch?: string;
    activityToDelete: number;
    sortingCandidateList: ISorting;
    filterCandidateList: ICandidateFilterApiProperties,
    filterMenuCandidateList: ICandidateFilterMenu,
}

export const preloadedStateWebVacancyDateRangeFilter: IDateRangeFilterApiProperties = {
    property: 'onlineSince',
    mode: EDateRangeMode.all,
    dateFrom: undefined,
    dateTo: undefined,
    lastDateRangeCount: undefined
}

export const preloadedStateWebVacancyPage: IWebVacancyPageState = {
    activeTabWebVacancyEditView: EWebVacancyEditViewTabs.data,
    filter: {
        start: 0,
        limit: 50,
        companyIds: [],
        skillIds: [],
        responsibleEmployeeId: 0,
        branchIds: [],
        fastSearch: '',
        location: '',
        radius: 30,
        showHidden: false,
        dateRangeFilter: preloadedStateWebVacancyDateRangeFilter
    },
    filterMenu: {
        companies: [],
        responsibleEmployee: null,
        skills: [],
        branches: [],
        languages: [],
        location: '',
        radius: 30,
        showHidden: false,
        dateRangeFilter: preloadedStateWebVacancyDateRangeFilter
    },
    sorting: {
        'onlineSince': ESortingOptions.Descending
    },
    importId: 0,
    reload: false,
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    activityToDelete: 0,
    sortingCandidateList: {},
    filterCandidateList: preloadedCandidatePageFilter,
    filterMenuCandidateList: preloaededCandidatePageFilterMenu
}
