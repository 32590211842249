import { Badge, Button } from '@material-ui/core';
import React from 'react';
import { EEntities } from '../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../Icons/IconFactory';
import CustomFilterMenuCompany from './Menu/CustomFilterMenuCompany';
import CustomFilterMenuActivity from './Menu/CustomFilterMenuActivity';
import { useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getFilterMenuBadgeCount } from '../../../selectors/entity.selectors';
import CustomFilterRecruitingsOfLast7Days from './Menu/CustomFilterRecruitingsOfLast7Days';
import CustomFilterMenuInvoice from './Menu/CustomFilterMenuInvoice';
import CustomFilterMenuRecruiting from './Menu/CustomFilterMenuRecruiting';
import CustomFilterMenuSkillTree from './Menu/CustomFilterMenuSkillTree';
import CustomFilterMenuJobTitle from './Menu/CustomFilterMenuJobTitle';
import CustomFilterMenuCandidateVacancySearch from './Menu/CustomFilterMenuCandidateVacancySearch';
import CustomFilterMenuApplication from './Menu/CustomFilterMenuApplication';
import CustomFilterMenuSuggestion from './Menu/CustomFilterMenuSuggestion';
import CustomFilterMenuActivityList from './Menu/CustomFilterMenuActivityList';

interface IProps {
    entity: EEntities;
}

const CustomFilterMenu: React.FC<IProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterMenuCount = useSelector((store: IStore) => getFilterMenuBadgeCount(store, props.entity));

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let menuToRender = null;

    if (props.entity === EEntities.Company) {
        menuToRender = <CustomFilterMenuCompany anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.Application) {
        menuToRender = <CustomFilterMenuApplication anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.Invoice) {
        menuToRender = <CustomFilterMenuInvoice anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.CandidateVacancySearch) {
        menuToRender = <CustomFilterMenuCandidateVacancySearch anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.RecruitingsOfLast7Days) {
        menuToRender = <CustomFilterRecruitingsOfLast7Days anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.ActivityList) {
        menuToRender = <CustomFilterMenuActivityList anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.Activity) {
        menuToRender = <CustomFilterMenuActivity anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.Suggestion) {
        menuToRender = <CustomFilterMenuSuggestion anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.RecruitingOfCandidate || props.entity === EEntities.RecruitingOfCompany) {
        menuToRender = <CustomFilterMenuRecruiting anchorEl={anchorEl} removeAnchor={handleClose} entity={props.entity}/>;
    }

    if (props.entity === EEntities.Skill) {
        menuToRender = <CustomFilterMenuSkillTree anchorEl={anchorEl} removeAnchor={handleClose} />;
    }

    if (props.entity === EEntities.JobTitleOverview || props.entity === EEntities.JobTitleSelectSearch) {
        menuToRender = <CustomFilterMenuJobTitle
            anchorEl={anchorEl}
            removeAnchor={handleClose}
        />;
    }

    return (
        <>
            {menuToRender &&
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                    startIcon={IconFactory.getIconComponent(EIcons.Filter)}
                >
                    Filter &nbsp;&nbsp;&nbsp; <Badge color="primary" badgeContent={filterMenuCount}/>
                </Button>
            }
            {menuToRender}
        </>
    );
}

export default CustomFilterMenu;
