import React from 'react';

interface IProps {
    value?: string;
}

const Nl2brCellRenderer: React.FC<IProps> = (props) => {
    const nl2br = (text: string) => {
        return text.split("\n").map(function (item, key) {
            return (
                <span key={key} style={{ marginBottom: "5px" }}>
                    {item}
                    <br />
                </span>
            );
        });
    };

    if (props.value) {
        return <div>{nl2br(props.value)}</div>;
    }

    return null;
};

export default Nl2brCellRenderer;
