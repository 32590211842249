import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import { ISkill } from '../../../../definitions/entities.definition';
import {
    resetJobTemplateJobTitleFilterAction,
    setJobTemplatePageJobTitleFilterAction,
    setJobTemplatePageJobTitleFilterMenuAction,
} from '../../../../redux/jobTemplate/jobTemplatePage.actions';
import LegacySkillAutocompleteMultipleDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacySkillAutocompleteMultipleDropDown';
import CustomCheckboxInput from '../../../CustomInput/CustomCheckboxInput';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuJobTitle: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.jobTemplatePage.jobTitleFilter);
    const filterMenu = useSelector((store: IStore) => store.jobTemplatePage.jobTitleFilterMenu);

    const submit = () => {
        dispatch(
            setJobTemplatePageJobTitleFilterAction({
                fastSearch: filter.fastSearch,
                start: filter.start,
                limit: filter.limit,
                skillIds: filterMenu.skills.map(({ id }) => id),
                onlyWithoutJobTemplate: filterMenu.onlyWithoutJobTemplate
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(resetJobTemplateJobTitleFilterAction());
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <LegacySkillAutocompleteMultipleDropDown
                    value={filterMenu.skills}
                    onChange={(skills: ISkill[]) => {
                        dispatch(setJobTemplatePageJobTitleFilterMenuAction({skills}))
                    }}
                />
            </MenuItem>
            <MenuItem>
                <CustomCheckboxInput
                    label={"Nur ohne Berufsgruppen anzeigen"}
                    checked={filterMenu.onlyWithoutJobTemplate}
                    onChange={() => {
                        dispatch(setJobTemplatePageJobTitleFilterMenuAction({
                            onlyWithoutJobTemplate: !filterMenu.onlyWithoutJobTemplate
                        }))
                    }}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuJobTitle;
