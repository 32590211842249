import { IStore } from '../definitions/redux/store.definitions';
import { IEmployee, IEmployeePartial } from '../definitions/entities.definition';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import { TRole } from '../definitions/actor.definitions';
import { useSelector } from 'react-redux';
import { getLoggedInUserFromStore } from './actor.selectors';

export const isOnlyShowMineModeActive = (store: IStore) => {
    return store.ui.showOnlyMyDataActive;
}

export const getLoggedInEmployeeFromStore = (store: IStore): IEmployeePartial => {
    if (store.actor.data && store.actor.data.employee) {
        return store.actor.data.employee;
    }

    //@todo solve better
    return {
        id: 0,
        name: '',
        firstName: '',
        fullName: '',
        mailAddress: ''
    };
};

export const userHasRole = (role: TRole): boolean => {
    const loggedInUser = useSelector(getLoggedInUserFromStore);

    return loggedInUser.roles.indexOf(role) >= 0;
}

export const userHasRole2 = (role: TRole, store: IStore): boolean => {
    const loggedInUser = getLoggedInUserFromStore(store);

    return loggedInUser.roles.indexOf(role) >= 0;
}

export const getEmployeeListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.administrationPage.employeeSorting;
    const filter = store.administrationPage.employeeFilter;

    return buildRequestObject(sorting, filter);
};

export const getEmployeeListFromStore = (store: IStore) => {
    const order = store.entities.employees.order;
    const employeesById = store.entities.employees.byId;

    return order.reduce((accumulator: IEmployee[], employeeId) => {
        accumulator.push(employeesById[employeeId]);

        return accumulator;
    }, []);
};
