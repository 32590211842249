import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getToken } from '../../../../selectors/app.selectors';
import CompanyDocumentsList from '../../../EditViews/Company/components/components/CompanyDocumentsList';
import ListAndPreview from '../../../../components/ListAndPreview/ListAndPreview';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';
import {
    setCompanyPageDocumentToDeleteAction,
    setCompanyPageDocumentToEditAction,
} from '../../../../redux/companyPage/companyPage.actions';
import { deleteCompanyDocumentAction } from '../../../../redux/entities/entities.actions';
import { translate } from '../../../../translation/translate.utils';
import CompanyDocumentForm from './components/CompanyDocumentForm';
import CompanyDocumentUpload from './components/CompanyDocumenUpload';

interface IProps {
    companyId: number;
}

const CompanyDocumentPanel: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const dispatch = useDispatch();
    const documentToDelete = useSelector((store: IStore) => store.companyPage.documentToDelete);
    const token = useSelector(getToken);
    const id = useSelector((store: IStore) => store.companyPage.currentDocumentId);
    const documents = useSelector((store: IStore) => store.entities.companyDocuments.byId);
    const initialDocument = documents[id];

    let toRender = (
        <>
            <CompanyDocumentUpload companyId={companyId}/>
            <ListAndPreview
                previewOpen={Boolean(initialDocument)}
                list={<CompanyDocumentsList  companyId={companyId}/>}
                preview={
                    <CompanyDocumentForm
                        initialDocument={initialDocument}
                        onClose={() => dispatch(setCompanyPageDocumentToEditAction(0))}
                    />
                }
            />
        </>
    );

    if (documentToDelete) {
        toRender = (
            <>
                <CustomDialogBody>
                    <CustomTypography translation={"pages.companyDocument.deleteDialog.body"} />
                </CustomDialogBody>
                <CustomDialogActions
                    onClose={() => dispatch(setCompanyPageDocumentToDeleteAction(0))}
                    buttons={[{
                        void: () => {
                            dispatch(deleteCompanyDocumentAction(token, documentToDelete));
                            dispatch(setCompanyPageDocumentToDeleteAction(0));
                        },
                        label: translate('misc.buttons.delete')
                    }]}
                />
            </>
        );
    }

    return toRender;
};

export default CompanyDocumentPanel;
