import React from 'react';
import { EIcons } from '../../../components/Icons/IconFactory';
import { COLOR_BLUE, COLOR_GREEN, COLOR_ORANGE, COLOR_RED, COLOR_YELLOW } from '../../../theme/theme';
import CustomDashboardTextPaper from '../../../components/CustomPaper/CustomDashboardTextPaper';
import { ERoutes } from '../../../definitions/app.definitions';
import { ERecruitingPageTabs } from '../../../definitions/recruitingPage.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setRecruitingPageActiveTabAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import {
    setDashboardPageBirthdayCandidatesViewOpenAction,
    setDashboardPageBirthdayCompanyContactsViewOpenAction,
    setDashboardPageChartSelectionAction,
    setDashboardPageChartSelectionRecruitingsViewOpenAction,
    setDashboardPageRecruitingsOfLast7DaysViewOpenAction,
    setDashboardPageTopSuggestionsViewOpenAction,
    setDashboardPageYourRecruitingsViewOpenAction,
} from '../../../redux/dashboardPage/dashboardPage.actions';
import BirthdayCandidatesView from './BirthdayCandidatesView';
import BirthdayCompanyContactsView from './BirthdayCompanyContactsView';
import EmployeeRecruitingsView from './EmployeeRecruitingsView';
import ChartSelectionRecruitingsView from './ChartSelectionRecruitingsView';
import TopSuggestionsView from './TopSuggestionsView';
import RecruitingsOfLast7DaysView from './RecruitingsOfLast7DaysView';
import { isOnlyShowMineModeActive } from '../../../selectors/employee.selectors';

const EmployeeDataView: React.FC = () => {
    const dispatch = useDispatch();
    const employeeData = useSelector((store: IStore) => store.dashboardPage.employeeData);
    const onlyShowMine = useSelector(isOnlyShowMineModeActive);

    const textCommission = onlyShowMine ? 'Dein Ø Vermittlungswert' : 'Ø Vermittlungswert';
    const textTopSuggestions = onlyShowMine ? 'Deine Top Favoriten' : 'Top Favoriten';
    const textOpenRecruitings = onlyShowMine ? 'Deine laufenden Prozesse' : 'Laufende Prozesse';
    const textWebVacancies = onlyShowMine ? 'Neue Stellen deiner Kunden' : 'Neue Stellen';
    const textVacancies = onlyShowMine ? 'Deine aktiven Vakanzen' : 'Vakanzen';
    const textRecruitingsLast7Days = onlyShowMine ? 'Deine Vorschläge der letzten 7 Tage' : 'Vorschläge der letzten 7 Tage';
    const textApplications = onlyShowMine ? 'Bewerbungen' : 'Bewerbungen';
    const textCandidateBirthDates = onlyShowMine ? 'Geburtstage von deinen Kandidaten' : 'Geburtstage von Kandidaten';
    const textContactPersonBirthDates = onlyShowMine ? 'Geburtstage von deinen Ansprechpartnern' : 'Geburtstage von Ansprechpartnern';

    return (
        <>
            <CustomDashboardTextPaper
                icon={EIcons.Flag}
                headline={textCommission + (new Date()).getFullYear() + " / " + ((new Date()).getFullYear() - 1)}
                iconBackgroundColor={COLOR_YELLOW}
            >
                <span style={{cursor: 'pointer'}} onClick={() => {
                    dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(true));
                    dispatch(setDashboardPageChartSelectionAction({
                        year: (new Date()).getFullYear(),
                        noProjects: true
                    }));
                }}>
                    {employeeData.averageCommission.toLocaleString("de-DE") + ' €'}
                </span>
                &nbsp;/&nbsp;
                <span style={{cursor: 'pointer'}} onClick={() => {
                    dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(true));
                    dispatch(setDashboardPageChartSelectionAction({
                        year: ((new Date()).getFullYear() - 1),
                        noProjects: true
                    }));
                }}>
                    {employeeData.averageCommissionLastYear.toLocaleString("de-DE") + ' €'}
                </span>
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Star}
                headline={textTopSuggestions}
                iconBackgroundColor={COLOR_ORANGE}
                onClickAction={() =>
                    dispatch(setDashboardPageTopSuggestionsViewOpenAction(true))
                }
            >
                {employeeData.countTopSuggestions}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.ThumbsUpDown}
                headline={textOpenRecruitings}
                iconBackgroundColor={COLOR_ORANGE}
                onClickAction={() =>
                    dispatch(setDashboardPageYourRecruitingsViewOpenAction(true))
                }
            >
                {employeeData.countOpenRecruitings}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Work}
                headline={textWebVacancies}
                iconBackgroundColor={COLOR_RED}
                route={ERoutes.WebVacancies}
            >
                {employeeData.countNewWebVacancies}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Work}
                headline={textVacancies}
                iconBackgroundColor={COLOR_ORANGE}
                route={ERoutes.Recruitings}
                onClickAction={() =>
                    dispatch(setRecruitingPageActiveTabAction(ERecruitingPageTabs.tabVacancies))
                }
            >
                {employeeData.countActiveVacancies}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.ThumbsUpDown}
                headline={textRecruitingsLast7Days}
                iconBackgroundColor={COLOR_ORANGE}
                onClickAction={() =>
                    dispatch(setDashboardPageRecruitingsOfLast7DaysViewOpenAction(true))
                }
            >
                {employeeData.countRecruitingsOfLast7Days}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Work}
                headline={textApplications}
                iconBackgroundColor={COLOR_ORANGE}
                route={ERoutes.Applications}
            >
                {employeeData.countOpenApplications}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Cake}
                headline={textCandidateBirthDates}
                iconBackgroundColor={COLOR_GREEN}
                onClickAction={() =>
                    dispatch(setDashboardPageBirthdayCandidatesViewOpenAction(true))
                }
            >
                {employeeData.countBirthdayCandidates}
            </CustomDashboardTextPaper>
            <CustomDashboardTextPaper
                icon={EIcons.Cake}
                headline={textContactPersonBirthDates}
                iconBackgroundColor={COLOR_BLUE}
                onClickAction={() =>
                    dispatch(setDashboardPageBirthdayCompanyContactsViewOpenAction(true))
                }
            >
                {employeeData.countBirthdayCompanyContacts}
            </CustomDashboardTextPaper>
            <BirthdayCandidatesView/>
            <BirthdayCompanyContactsView/>
            <EmployeeRecruitingsView/>
            <ChartSelectionRecruitingsView/>
            <TopSuggestionsView/>
            <RecruitingsOfLast7DaysView/>
        </>
    );
}

export default EmployeeDataView;
