import React, { PropsWithChildren, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    parent: {
        position: "absolute",
        width: 500,
        height: 100,
        backgroundColor: "var(--brainz-darker)",
        zIndex: 0,
        opacity: 0.5,
    },
    backdrop: {
        boxSizing: "border-box",
        position: "absolute",
        margin: '0 auto',
        textAlign: 'center',
    }
});

interface IProps extends PropsWithChildren<any>{
    open: boolean;
}

const CustomBackdropLoadingInsideDiv: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const open = props.open;

    useEffect(() => {
        if (open) {
            const overlays = document.getElementsByClassName('divOverlay');
            for (let index = 0; index < overlays.length; index++) {
                const filterElement = overlays[index];
                //@ts-ignore
                filterElement.style.width = filterElement.parentElement.offsetWidth + 'px';
                //@ts-ignore
                filterElement.style.height = filterElement.parentElement.offsetHeight + 'px';
            }
        }
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <div className={classes.parent + " flex flexCentered divOverlay"}>
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit"  size={50}/>
            </Backdrop>
        </div>
    );
}

export default React.memo(CustomBackdropLoadingInsideDiv);
