export interface IMailingFilter {
    onlyNotAssigned?: boolean,
    onlyUnread?: boolean,
    fastSearch?: string;
    employeeId?: number;
    folderId?: number;
}

export const mailingFilterInitialState: IMailingFilter = {
    onlyNotAssigned: false,
    fastSearch: '',
}

export interface IMailingState {
    filter: IMailingFilter;
    paging: {
        start: number;
        limit: number;
    },
    loading: boolean,
    reload: boolean
}

export const preloadedMailingState: IMailingState = {
    filter: mailingFilterInitialState,
    paging: {
        start: 0,
        limit: 50
    },
    loading: false,
    reload: false
};
