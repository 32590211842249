import { useEffect } from 'react';
import { getSuggestionsForVacancyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getSuggestionsForVacancyFromStore,
    getVacancyPageRequestObjectForSuggestionsInEditView,
} from '../../../../../selectors/recruitingSuggestion.selectors';

export const useVacancySuggestionListHook = (vacancyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.vacancySuggestionsLoading);

    const suggestions = useSelector((store: IStore) => getSuggestionsForVacancyFromStore(store, vacancyId));
    const requestObject = useSelector(getVacancyPageRequestObjectForSuggestionsInEditView);
    const forceReload = useSelector((store: IStore) => store.vacancyPage.reloadSuggestions);
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionOfVacancyFilter);

    useEffect(() => {
        if (!loading) {
            dispatch(getSuggestionsForVacancyAction(token, vacancyId, requestObject));
        }
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        if (forceReload && !loading) {
            dispatch(getSuggestionsForVacancyAction(token, vacancyId, requestObject));
        }
    }, [forceReload]);

    return {loading, suggestions};
}
