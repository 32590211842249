import { IStore } from '../definitions/redux/store.definitions';
import { ICompanyLocation } from '../definitions/entities.definition';

export const getCompanyLocationsForCompanyEditViewFromStore = (store: IStore, companyId: number) => {
    const locationsById = store.entities.companyLocations.byId;
    const locationIdsByCompanyId = store.entities.companyLocations.byCompany[companyId] || [];

    const locations: ICompanyLocation[] = [];

    locationIdsByCompanyId.forEach((locationId) => locations.push(locationsById[locationId]));

    return locations;
};

export const getCompanyLocationsForDropdownFromStore = (store: IStore, companyId: number) => {
    const locationsById = store.entities.companyLocations.byId;
    const locationIdsByCompanyId = store.entities.companyLocations.byCompanyForDropdown[companyId] || [];

    const locations: ICompanyLocation[] = [];

    locationIdsByCompanyId.forEach((locationId) => locations.push(locationsById[locationId]));

    return locations;
};

export const getAllCompanyLocationsFromStore = (store: IStore) => {
    const order = store.entities.companyLocations.order;
    const locationsById = store.entities.companyLocations.byId;

    return order.reduce((accumulator: ICompanyLocation[], candidateId) => {
        accumulator.push(locationsById[candidateId]);

        return accumulator;
    }, []);
};
