import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EHomeOfficeDesire } from '../../../definitions/entities.definition';

interface IProps {
    onChange: (value?: EHomeOfficeDesire|null) => void;
    value?: EHomeOfficeDesire|null;
}

const HomeOfficeDesireDropDown: React.FC<IProps> = (props) => {
    const homeOfficeDesireValues: EHomeOfficeDesire[] = [
        EHomeOfficeDesire.no,
        EHomeOfficeDesire.nice_to_have,
        EHomeOfficeDesire.partially,
        EHomeOfficeDesire.complete
    ];

    let menuItems = homeOfficeDesireValues.map((homeOfficeDesire: EHomeOfficeDesire, index: number) => (
        <MenuItem key={index + 1} value={homeOfficeDesire}>
            {translate('misc.homeOfficeDesireValue.' + homeOfficeDesire)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={-1} value={-1}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(undefined);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value !== undefined && props.value !== null ? props.value : -1}
            id={"home-office-dropdown"}
            label={translate('misc.homeOfficeDesire')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default HomeOfficeDesireDropDown;
