import React, { memo, ReactNode } from 'react';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    ICustomTableColumnConfig,
    IListActionButtonConfig,
    TListAction,
} from '../../definitions/components.definitions';
import CustomBackdropLoadingInsideDiv from '../CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CustomDetectScrolledToEndDiv from '../CustomDetectScrolledToEnd/CustomDetectScrolledToEndDiv';
import _ from 'underscore';
import CustomTableBodyGroupedRenderGroup from './CustomTableBodyGroupedRenderGroup';

interface IProps {
    loading?: boolean;
    listActionButtons?: IListActionButtonConfig[];
    listActionHeading?: (entity: TBrainzEntity) => string;
    columnConfig: ICustomTableColumnConfig;
    sortedEntries: TBrainzEntity[];
    emptyText?: string|ReactNode;
    scrollCallback?: () => void;
    onDoubleClick?: TListAction;
    onClick?: TListAction;
    entity: EEntities;
    denyVerticalCenter?: boolean;
    groupBy: (entity: TBrainzEntity) => string;
    groupHeaderExtraInformation?: (entities: TBrainzEntity[]) => ReactNode;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: "100%",
            overflowY: "scroll",
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        },
    })
);

export const TABLE_BODY_ID = "tableBody-";

const CustomTableBodyGrouped: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const id = `${TABLE_BODY_ID}${props.entity}`;
    const loading = props.loading || false;

    const entitiesGrouped = _.groupBy(props.sortedEntries, function (entity) {
        return props.groupBy(entity);
    });

    return <>
        <CustomDetectScrolledToEndDiv id={id} className={styles.root} loading={loading} scrollCallback={props.scrollCallback}>
            {loading &&
                <CustomBackdropLoadingInsideDiv open/>
            }
            {Object.keys(entitiesGrouped).map((header) => {
                const suggestions = entitiesGrouped[header];
                return <>
                    <CustomTableBodyGroupedRenderGroup
                        entity={props.entity}
                        onDoubleClick={props.onDoubleClick}
                        onClick={props.onClick}
                        listActionHeading={props.listActionHeading}
                        columnConfig={props.columnConfig}
                        sortedEntries={suggestions}
                        listActionButtons={props.listActionButtons}
                        header={header}
                        denyVerticalCenter={props.denyVerticalCenter}
                        groupHeaderExtraInformation={props.groupHeaderExtraInformation}
                    />
                </>
            })}
        </CustomDetectScrolledToEndDiv>
    </>
}

export default memo(CustomTableBodyGrouped);
