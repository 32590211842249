import React, { useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { IActivity } from '../../../definitions/entities.definition';
import LegacyEmployeeAutocompleteDropDown
    from '../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { translate } from '../../../translation/translate.utils';
import ActivityTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/ActivityTypeDropDown';
import CustomDateTimePicker from '../../../components/CustomInput/CustomDateTimePicker';
import { createPortal } from 'react-dom';

interface IProps {
    onClose: () => void;
    activity: IActivity
}

const ActivityDisplaySystemMail: React.FC<IProps> = (props: IProps) => {
    return (
        <div>
            <PaperRow>
                <CustomDateTimePicker
                    label={translate('pages.activity.properties.dateTime')}
                    value={new Date(props.activity.dateTime)}
                />
                <ActivityTypeDropDown
                    value={props.activity.type}
                    disabled
                />
                <LegacyEmployeeAutocompleteDropDown
                    value={props.activity.employee}
                    disabled
                />
            </PaperRow>
            <PaperRow>
                <FunctionalIFrameComponent>
                    <div dangerouslySetInnerHTML={{__html: props.activity.userInput as string}}/>
                </FunctionalIFrameComponent>
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
            />
        </div>
    );
}

//@ts-ignore
export const FunctionalIFrameComponent = ({children, ...props}) => {
    const [contentRef, setContentRef] = useState(null)
    //@ts-ignore
    const mountNode = contentRef?.contentWindow?.document?.body

    return (
        //@ts-ignore
        <iframe {...props} ref={setContentRef} style={{flex:1, height: '500px', border: 'none', background: 'white'}}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}


export default ActivityDisplaySystemMail;
