import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: 7|14|30;
    error?: boolean;
    helperText?: string;
}

const TimeRangeDropDown: React.FC<IProps> = (props) => {
    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value ?? 0}
            error={props.error}
            helperText={props.helperText}
            id={"time-range-dropdown"}
            label={'Zeitspanne'}
        >
            <MenuItem key={0} value={0}>
                {translate('misc.choose')}
            </MenuItem>
            <MenuItem key={7} value={7}>
                7 Tage
            </MenuItem>
            <MenuItem key={14} value={14}>
                14 Tage
            </MenuItem>
            <MenuItem key={30} value={30}>
                30 Tage
            </MenuItem>,
        </CustomDropDownInput>
    );
};

export default TimeRangeDropDown;
