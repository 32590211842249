import { ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';

export interface ITodoState {
    addTodo: boolean;
    todoToEdit: number;
    todoToDelete: number;
    reloadTodos: boolean;
    activitySorting: ISorting;
    employeeDueTodoCollapsedState: {
        [group: string]: boolean
    }
}

export const preloadedTodoState: ITodoState = {
    addTodo: false,
    todoToEdit: 0,
    todoToDelete: 0,
    reloadTodos: false,
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    employeeDueTodoCollapsedState: {}
}
