import React from 'react';
import { setDashboardPageEmployeeDataAction } from '../../redux/dashboardPage/dashboardPage.actions';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getLoggedInEmployeeFromStore } from '../../selectors/employee.selectors';
import GoalBurnUpChartView from './components/GoalBurnUpChartView';
import EmployeeDataView from './components/EmployeeDataView';
import SuccessActivityList from './components/tables/SuccessActivityList';
import { getActivityListForSuccessListAction } from '../../redux/entities/entities.actions';

class DashboardPage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount = () => {
        this.props.setDashboardPageEmployeeDataAction(this.props.token);
        this.props.getActivityListForSuccessListAction(this.props.token);
    };

    componentDidUpdate() {
        if (this.props.reloadData) {
            this.props.setDashboardPageEmployeeDataAction(this.props.token);
            this.props.getActivityListForSuccessListAction(this.props.token);
        }
    }

    render() {
        return (
            <>
                <div className={"flex"}>
                    <div className={"flexColumn flexCentered overflow"}>
                        <div className={"flex gap10"}>
                            <div className={"flexColumn gap10 marginTop10 marginBottom10"}>
                                <GoalBurnUpChartView/>
                                <SuccessActivityList />
                            </div>
                            <div style={{width: 350}}>
                                <EmployeeDataView/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    token: store.actor.token,
    loggedInEmployee: getLoggedInEmployeeFromStore(store),
    reloadData: store.dashboardPage.reloadData
});

const mapDispatch = {
    setDashboardPageEmployeeDataAction,
    getActivityListForSuccessListAction
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DashboardPage);
