import React from 'react';
import {
    EEntities,
    ICompanyContactPartial,
    IEmployee,
    ISuccessActivity,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../translation/translate.utils';
import { IStore } from '../../../../definitions/redux/store.definitions';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import { getToken } from '../../../../selectors/app.selectors';
import { getActivityListForSuccessListAction } from '../../../../redux/entities/entities.actions';
import { themeOrange } from '../../../../theme/theme';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';
import { ECellAlign } from '../../../../definitions/components.definitions';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import CompanyContactCellRenderer from '../../../../components/CustomCellRenderer/CompanyContactCellRenderer';

const SuccessActivityList: React.FC = () => {
    const dispatch = useDispatch();
    const activities = useSelector((store: IStore) => store.entities.activities.successActivities);
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);

    return (
        <CustomTable
            style={{maxHeight: 360, padding: 0, border: '4px solid var(--brainz-darkest)', borderLeft: '4px solid var(--brainz-darkest)', borderRadius: 6}}
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.SuccessActivity,
                loading,
                columnConfig: {
                    dateTime: {
                        header: translate('pages.activity.properties.dateTime'),
                        property: "dateTime",
                        width: 150,
                        cellRenderer: (dateTime: string) => <DateTimeCellRenderer date={dateTime} />,
                    },
                    text: {
                        header: 'News', //translate('pages.activity.properties.description'),
                        property: "text",
                        flex: 2
                    },
                    employee: {
                        header: translate('misc.employeeShort') + ' | AP',
                        property: "employee",
                        align: ECellAlign.center,
                        width: 140,
                        cellRenderer: (employee: null|IEmployee, entity) => {
                            const entityCast = entity as ISuccessActivity;

                            if (!employee) {
                                return <CompanyContactCellRenderer companyContact={entityCast.createdByUser.person as ICompanyContactPartial} />;
                            }
                            return <EmployeeCellRenderer employee={employee} />;
                        },
                    }
                },
                sortedEntries: activities,
                onRowDoubleClick: (activityId: number, entity) => {
                    const entityCast = entity as ISuccessActivity;
                    if (entityCast.recruitingSuggestion) {
                        dispatch(setUiEditView(EEntityView.suggestion, entityCast.recruitingSuggestion.id));
                        return;
                    }
                    if (entityCast.recruiting) {
                        dispatch(setUiEditView(EEntityView.recruiting, entityCast.recruiting.id));
                        return;
                    }
                    if (entityCast.vacancy) {
                        dispatch(setUiEditView(EEntityView.vacancy, entityCast.vacancy.id));
                        return;
                    }
                },
                count: activities.length,
                onReload: () => {
                    dispatch(getActivityListForSuccessListAction(
                        token
                    ));
                }
            }}
        />
    );
};

export default SuccessActivityList;
