import React from 'react';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { deleteCompanyContactAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { setCompanyPageCompanyContactToDeleteAction } from '../../../redux/companyPage/companyPage.actions';

const CompanyContactDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.companyPage.companyContactToDelete));
    const deleteCompanyContactId = useSelector((store: IStore) => store.companyPage.companyContactToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const onDelete = () => {
        dispatch(deleteCompanyContactAction(
            token,
            deleteCompanyContactId
        ));
        dispatch(setCompanyPageCompanyContactToDeleteAction(0));
    };

    const onClose = () => {
        dispatch(setCompanyPageCompanyContactToDeleteAction(0));
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={onClose}
            onDelete={onDelete}
            titleTranslation={"pages.companyContact.deleteDialog.title"}
            bodyTranslation={"pages.companyContact.deleteDialog.body"}
        />
    );
};

export default CompanyContactDeleteView;
