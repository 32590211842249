import React, { useState } from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { IDunningLetter, IInvoice } from '../../../../definitions/entities.definition';
import DunningLetterAddEditView from './DunningLetterAddEditView';
import CustomButton from '../../../../components/CustomInput/CustomButton';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { Link } from '@material-ui/core';

interface IProps {
    invoice: IInvoice;
}

const InvoicePaymentRemindView: React.FC<IProps> = (props) => {
    const [addDunningLetter, setAddDunningLetter] = useState(false);
    const [editDunningLetter, setEditDunningLetter] = useState<IDunningLetter|null>(null);
    const onClose = () => {
        setEditDunningLetter(null);
        setAddDunningLetter(false);
    }

    if (!props.invoice) {
        return null;
    }

    const createDunningLetterLabel = props.invoice.dunningLetters.length === 2 ? 'Letzte Mahnung erstellen' : props.invoice.dunningLetters.length === 1 ? '2. Mahnung erstellen' : '1. Mahnung erstellen';

    return (
        <>
            <DunningLetterAddEditView
                invoiceId={props.invoice.id}
                editDunningLetter={editDunningLetter}
                addDunningLetter={addDunningLetter}
                openSum={props.invoice.dunningLetters.length > 0 ? (props.invoice.dunningLetters[props.invoice.dunningLetters.length-1] as IDunningLetter).dunningSum : props.invoice.openToPay}
                onClose={onClose}
            />
            <PaperRow>
                {props.invoice.isAbleForDunning &&
                    <div style={{width: 300}}>
                        <CustomButton
                            label={createDunningLetterLabel}
                            icon={EIcons.Add}
                            onClick={() => setAddDunningLetter(true)}
                        />
                    </div>
                }
            </PaperRow>
            <PaperRow>
                {props.invoice.dunningLetters.map((dunningLetter, index) => {
                    const editDunningLetterLabel = dunningLetter.level === 3 ? 'Letzte Mahnung einsehen' : dunningLetter.level === 2 ? '2. Mahnung einsehen' : '1. Mahnung einsehen';

                    return (
                        <div key={index} style={{width:'100%'}}>
                            <Link key={index} onClick={() => setEditDunningLetter(dunningLetter)} variant={"body1"}>
                                {editDunningLetterLabel}
                            </Link>
                        </div>
                    );
                })}
            </PaperRow>
        </>
    );
}

export default InvoicePaymentRemindView;
