import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            height: '100%',
            overflow: 'auto'
        },
    })
);

const CustomDialogBody: React.FC = (props) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
};

export default CustomDialogBody;
