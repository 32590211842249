import {
    ERecruitingAbortedBy,
    ERecruitingStatus,
    ICompanyPartial,
    IEmployeePartial,
    TBrainzEntity,
} from './entities.definition';
import { ReactNode } from 'react';
import { EIcons } from '../components/Icons/IconFactory';
import { Omit } from '@material-ui/core';

export type TSetFilterValueAction = (value: any) => void;
export type TFilterAction = (filterMenu: IFilterMenuEntry[]) => void;

/**
 * DATE RANGE FILTER
 */
export enum EDateRangeMode {
    all,
    lastDays,
    lastWeeks,
    lastMonth,
    year,
    range
}

export interface IDateRangeFilterFieldConfig {
    label: string;
    property: string;
}

export interface IDateRangeFilterConfig {
    filter: IDateRangeFilterApiProperties;
    setFilter: (dateRangeFilter: IDateRangeFilterApiProperties) => void;
    filterByFields: IDateRangeFilterFieldConfig[];
    reset: () => void;
    resetEnabled: boolean;
}

export interface IDateRangeFilterApiProperties {
    property: string;
    mode: EDateRangeMode;
    lastDateRangeCount?: number;
    dateFrom?: string;
    dateTo?: string;
    year?: number;
}

/**
 * RECRUITING INFORMATION FILTER
 */
export enum ERecruitingInformationFilterType {
    neverSuggested,
    suggested,
    aborted,
    withContract
}

export interface IRecruitingInformationFilterMenu {
    type: ERecruitingInformationFilterType;
    dateRange: IDateRangeFilterApiProperties;
    companies: ICompanyPartial[];
    employees: IEmployeePartial[];
    abortedBy?: ERecruitingAbortedBy;
    abortedAfter?: ERecruitingStatus.STATUS_SUGGEST|ERecruitingStatus.STATUS_INTERVIEW;
    finishedTrialPeriod?: boolean;
}

export interface IRecruitingInformationFilterApiProperties {
    type: ERecruitingInformationFilterType;
    dateRange: IDateRangeFilterApiProperties;
    companyIds: number[];
    employeeIds: number[];
    abortedBy?: ERecruitingAbortedBy;
    abortedAfter?: ERecruitingStatus.STATUS_SUGGEST|ERecruitingStatus.STATUS_INTERVIEW;
    finishedTrialPeriod?: boolean;
}

/**
 * FILTER PANEL
 */

export interface IFilterMenuEntry {
    type: 'number'|'string'|'custom';
    property: string;
    value?: null|number|string|TBrainzEntity;
    label?: string;
}

export interface IFilterPanelConfig {
    type: TFilterPanelType;
    filterAction: TFilterAction;
    resetAction: () => void;
    setValue: TSetFilterValueAction;
    useDispatch: boolean;
    filterMenuValues: [];
    filterApiValues: [];
    filters: TFilterType[];
}

interface IFilterTypeBaseConfig {
    property: string;
    label: string;
    value: any;
}

export interface IFilterTypeCustomFilter extends Partial<Omit<IFilterTypeBaseConfig, 'label'>> {
    filterComponent?: (filterMenu: IFilterTypeCustomFilter, setValue: TSetFilterValueAction) => ReactNode;
}

export interface IFilterTypeQuickFilter extends IFilterTypeBaseConfig {
    icon: EIcons;
    tooltip?: string;
    value: boolean;
}

export enum EFilterPanelType {
    sidePanel,
    popupMenu
}

export type TFilterType = IFilterTypeQuickFilter|IFilterTypeCustomFilter;
export type TFilterPanelType = EFilterPanelType.sidePanel|EFilterPanelType.popupMenu;


