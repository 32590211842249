import { ECalendarView, ICalendarFilter, ICalendarTodoFilter } from '../../definitions/calendarPage.definitions';
import { Moment } from 'moment';
import {
    ISetCalendarPageAppointmentAction,
    ISetCalendarPageCalendarFilterAction,
    ISetCalendarPageDateAction,
    ISetCalendarPageNavigationOpen,
    ISetCalendarPageOpenTodoViewOpen,
    ISetCalendarPageTodoFilterAction,
    ISetCalendarPageViewAction,
    ISetCalendarPageWeekViewScrollToAction,
} from '../../definitions/redux/actions.definition';

export const CALENDAR_PAGE_SET_VIEW = "CALENDAR_PAGE_SET_VIEW";
export const CALENDAR_PAGE_SET_CALENDAR_APPOINTMENT = "CALENDAR_PAGE_SET_CALENDAR_APPOINTMENT";
export const CALENDAR_PAGE_SET_WEEK_VIEW_SCROLL_TO = "CALENDAR_PAGE_SET_WEEK_VIEW_SCROLL_TO";
export const CALENDAR_PAGE_SET_DATE = "CALENDAR_PAGE_SET_DATE";
export const CALENDAR_PAGE_SET_CALENDAR_NAVIGATION_OPEN = "CALENDAR_PAGE_SET_CALENDAR_NAVIGATION_OPEN";
export const CALENDAR_PAGE_SET_OPEN_TODO_VIEW_OPEN = "CALENDAR_PAGE_SET_OPEN_TODO_VIEW_OPEN";
export const CALENDAR_PAGE_SET_CALENDAR_FILTER = "CALENDAR_PAGE_SET_CALENDAR_FILTER";
export const CALENDAR_PAGE_SET_TODO_FILTER = "CALENDAR_PAGE_SET_TODO_FILTER";

export const setCalendarPageView = (view: ECalendarView): ISetCalendarPageViewAction => ({
    type: CALENDAR_PAGE_SET_VIEW,
    payload: view,
});

export const setCalendarPageCalendarFilter = (filter: Partial<ICalendarFilter>): ISetCalendarPageCalendarFilterAction => ({
    type: CALENDAR_PAGE_SET_CALENDAR_FILTER,
    payload: filter,
});

export const setCalendarPageTodoViewFilter = (filter: Partial<ICalendarTodoFilter>): ISetCalendarPageTodoFilterAction => ({
    type: CALENDAR_PAGE_SET_TODO_FILTER,
    payload: filter,
});

export const setCalendarPageNavigationOpen = (open: boolean): ISetCalendarPageNavigationOpen => ({
    type: CALENDAR_PAGE_SET_CALENDAR_NAVIGATION_OPEN,
    payload: open,
});

export const setCalendarPageOpenTodoViewOpen = (open: boolean): ISetCalendarPageOpenTodoViewOpen => ({
    type: CALENDAR_PAGE_SET_OPEN_TODO_VIEW_OPEN,
    payload: open,
});

export const setCalendarPageAppointment = (moment?: Moment): ISetCalendarPageAppointmentAction => ({
    type: CALENDAR_PAGE_SET_CALENDAR_APPOINTMENT,
    payload: moment,
});

export const setCalendarWeekViewScrollTo = (scrollEnabled: boolean): ISetCalendarPageWeekViewScrollToAction => ({
    type: CALENDAR_PAGE_SET_WEEK_VIEW_SCROLL_TO,
    payload: scrollEnabled,
});

export const setCalendarDate = (date: string): ISetCalendarPageDateAction => ({
    type: CALENDAR_PAGE_SET_DATE,
    payload: date,
});
