import React, { memo, ReactNode } from 'react';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import CustomTableRow from './CustomTableRow';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    ICustomTableColumnConfig,
    IListActionButtonConfig,
    TListAction,
} from '../../definitions/components.definitions';
import { createCustomTableCellsHook } from '../../utils/components.utils';
import CustomBackdropLoadingInsideDiv from '../CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CustomDetectScrolledToEndDiv from '../CustomDetectScrolledToEnd/CustomDetectScrolledToEndDiv';

interface IProps {
    loading?: boolean;
    listActionButtons?: IListActionButtonConfig[];
    listActionHeading?: (entity: TBrainzEntity) => string;
    columnConfig: ICustomTableColumnConfig;
    sortedEntries: TBrainzEntity[];
    emptyText?: string|ReactNode;
    scrollCallback?: () => void;
    onDoubleClick?: TListAction;
    onClick?: TListAction;
    entity: EEntities;
    denyVerticalCenter?: boolean;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: "100%",
            overflowY: "scroll",
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        },
    })
);

export const TABLE_BODY_ID = "tableBody-";

const CustomTableBody: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const id = `${TABLE_BODY_ID}${props.entity}`;
    const loading = props.loading || false;

    return <>
        <CustomDetectScrolledToEndDiv id={id} className={styles.root} loading={loading} scrollCallback={props.scrollCallback}>
            {loading &&
                <CustomBackdropLoadingInsideDiv open/>
            }
            {props.sortedEntries.map((entry: TBrainzEntity, index) => {
                const coloring = index % 2 === 0;

                const cells = createCustomTableCellsHook(
                    props.denyVerticalCenter || false,
                    props.entity,
                    props.columnConfig,
                    entry
                );

                return (
                    <CustomTableRow
                        index={index}
                        entity={props.entity}
                        onDoubleClick={props.onDoubleClick}
                        onClick={props.onClick}
                        entry={entry}
                        key={index}
                        coloring={coloring}
                        listActionButtons={props.listActionButtons}
                        listActionHeading={props.listActionHeading}
                    >
                        {cells}
                    </CustomTableRow>
                );
            })}
        </CustomDetectScrolledToEndDiv>
    </>
}

export default memo(CustomTableBody);
