import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { TPartTimeFullTime } from '../../../definitions/entities.definition';

interface IProps {
    onChange: (value?: TPartTimeFullTime|null) => void;
    value: null|TPartTimeFullTime;
}

const PartTimeFullTimeDropDown: React.FC<IProps> = (props) => {
    const partTimeValues: TPartTimeFullTime[] = [
        "FULL_TIME",
        "PART_TIME_20_HOURS",
        "PART_TIME_25_HOURS",
        "PART_TIME_30_HOURS",
        "PART_TIME_35_HOURS"
    ];

    let menuItems = partTimeValues.map((partTimeFullTimeValue: TPartTimeFullTime, index: number) => (
        <MenuItem key={index + 1} value={partTimeFullTimeValue}>
            {translate('pages.candidate.properties.partTimeFullTimeValue.' + partTimeFullTimeValue)}
        </MenuItem>
    ));
    menuItems = [
        <MenuItem key={-1} value={-1}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(undefined);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value !== undefined && props.value !== null ? props.value : -1}
            id={"part-time-full-time-dropdown"}
            label={translate('pages.candidate.properties.partTimeFullTime')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default PartTimeFullTimeDropDown;
