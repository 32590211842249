import React, { ReactNode, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { setUiEditView } from './redux/ui/ui.actions';
import { useDispatch } from 'react-redux';
import { EEntityView } from './definitions/ui.definitions';


interface IProps {
    view: EEntityView;
    component: ReactNode;
}
const AppSharedEditView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const view = props.view;
    const component = props.component;

    interface properties {id: number, title: string}
    const params = match.params as properties;

    useEffect(() => {
        dispatch(setUiEditView(view, Number(params.id)))
    }, [view])

    return <>
        {component}
    </>
}

export default AppSharedEditView;
