import {
    IResetSkillPageFilterAction,
    ISetSkillPageExternalSkillToEditAction,
    ISetSkillPageFilterAction,
    ISetSkillPageFilterMenuAction,
    ISetSkillPageSkillToDeleteAction,
    ISetSkillPageSkillToEditAction,
} from '../../definitions/redux/actions.definition';
import { ISkillTreeFilter, ISkillTreeFilterMenu } from '../../definitions/skillPage.definitions';

export const SKILL_PAGE_RESET_FILTER = "SKILL_PAGE_RESET_FILTER";
export const SKILL_PAGE_SET_FILTER = "SKILL_PAGE_SET_FILTER";
export const SKILL_PAGE_SET_FILTER_MENU = "SKILL_PAGE_SET_FILTER_MENU";
export const SKILL_PAGE_SET_SKILL_TO_EDIT = "SKILL_PAGE_SET_SKILL_TO_EDIT";
export const SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT = "SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT";
export const SKILL_PAGE_SET_SKILL_TO_DELETE = "SKILL_PAGE_SET_SKILL_TO_DELETE";

export const resetSkillPageFilterAction = (): IResetSkillPageFilterAction => ({
    type: SKILL_PAGE_RESET_FILTER,
});

export const setSkillPageFilterAction = (filter: Partial<ISkillTreeFilter>): ISetSkillPageFilterAction => ({
    type: SKILL_PAGE_SET_FILTER,
    payload: filter
});

export const setSkillPageFilterMenuAction = (filter: Partial<ISkillTreeFilterMenu>): ISetSkillPageFilterMenuAction => ({
    type: SKILL_PAGE_SET_FILTER_MENU,
    payload: filter
});

export const setSkillPageExternalSkillToEditAction = (skillId: number): ISetSkillPageExternalSkillToEditAction => ({
    type: SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT,
    payload: skillId,
});

export const setSkillPageSkillToEditAction = (skillId: number): ISetSkillPageSkillToEditAction => ({
    type: SKILL_PAGE_SET_SKILL_TO_EDIT,
    payload: skillId,
});

export const setSkillPageTemplateToDeleteAction = (jobTemplateId: number): ISetSkillPageSkillToDeleteAction => ({
    type: SKILL_PAGE_SET_SKILL_TO_DELETE,
    payload: jobTemplateId,
});

export const setSkillPageTemplateToReplaceAction = (jobTemplateId: number): ISetSkillPageSkillToDeleteAction => ({
    type: SKILL_PAGE_SET_SKILL_TO_DELETE,
    payload: jobTemplateId,
});
