import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { Dialog, Typography } from '@material-ui/core';
import GiphyView from '../../../components/CustomGiphy/GiphyView';
import { translate } from '../../../translation/translate.utils';
import { setUiCongratulationViewOpenAction } from '../../../redux/ui/ui.actions';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { resetRecruitingStatusChangedToContractAction } from '../../../redux/entities/entities.actions';
import { COLOR_TEXT, themeOrange } from '../../../theme/theme';

const CongratulationView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.ui.openCongratulation));
    const dispatch = useDispatch();
    const tags = [
        'awesome',
        'high five',
        'you are the best'
    ];
    const tagIndex = Math.floor(Math.random() * 3);

    const onClose = () => {
        dispatch(setUiCongratulationViewOpenAction(false));
        dispatch(resetRecruitingStatusChangedToContractAction());
    }

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"motivation.title"} onClose={onClose} />
            <CustomDialogBody>
                <Typography align="center" style={{ color: COLOR_TEXT }} variant="h6" noWrap>
                    {translate('motivation.1')}
                </Typography>
                <CustomTabContainer
                    color={themeOrange.palette.primary.main}
                    tabs={[{
                        label: 'GIF',
                        component: (
                            <div style={{ alignContent: "center" }}>
                                <GiphyView tag={tags[tagIndex]} />
                            </div>
                        )
                    }, {
                        label: 'CAT',
                        component: (
                            <div style={{overflow: "hidden"}}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/CAyWN9ba9J8?start=60&autoplay=1"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        )
                    }]}
                />
            </CustomDialogBody>
            <CustomDialogActions onClose={onClose}/>
        </Dialog>
    );
};

export default CongratulationView;
