import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import CustomDropDownInput from './CustomDropDownInput';
import { EDocumentStatus } from '../../../definitions/billingPage.definitions';
import CustomCheckboxInput from '../CustomCheckboxInput';
import { translate } from '../../../translation/translate.utils';

interface IProps {
    onChange: TMouseEventFunction;
    value?: EDocumentStatus[];
    label: string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const DocumentStatusDropDown: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomDropDownInput
                multiple
                id={"document-status-dropdown"}
                required={props.required}
                onChange={props.onChange}
                value={props.value}
                label={props.label}
                renderValue={(selected) =>
                    selected
                        .map((status: EDocumentStatus, index) => <>{translate('pages.billing.documentStatusValues.' + status)}{ props.value && props.value.length - 1 === index ? '' : <br/>}</>)
                }
            >
                <MenuItem key={EDocumentStatus.open} value={EDocumentStatus.open}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.open) > -1)} />
                    Offen
                </MenuItem>
                <MenuItem key={EDocumentStatus.send} value={EDocumentStatus.send}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.send) > -1)} />
                    Verschickt
                </MenuItem>
                <MenuItem key={EDocumentStatus.remind} value={EDocumentStatus.remind}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.remind) > -1)} />
                    Zahlungserinnerung
                </MenuItem>
                <MenuItem key={EDocumentStatus.first_dunning} value={EDocumentStatus.first_dunning}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.first_dunning) > -1)} />
                    1. Mahnung
                </MenuItem>
                <MenuItem key={EDocumentStatus.second_dunning} value={EDocumentStatus.second_dunning}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.second_dunning) > -1)} />
                    2. Mahnung
                </MenuItem>
                <MenuItem key={EDocumentStatus.last_dunning} value={EDocumentStatus.last_dunning}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.last_dunning) > -1)} />
                    Letzte Mahnung
                </MenuItem>
                <MenuItem key={EDocumentStatus.cancelled} value={EDocumentStatus.cancelled}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.cancelled) > -1)} />
                    Storniert
                </MenuItem>
                <MenuItem key={EDocumentStatus.closed} value={EDocumentStatus.closed}>
                    <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(EDocumentStatus.closed) > -1)} />
                    Abgeschlossen/Bezahlt
                </MenuItem>
            </CustomDropDownInput>
        </>
    );
};

export default DocumentStatusDropDown;
