import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    EEntities,
    ERecruitingType,
    ICandidate,
    IEmployee,
    IRecruiting,
    IRecruitingListModel,
    IVacancy,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../translation/translate.utils';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import { getRecruitingsForChartSelection } from '../../../../selectors/recruiting.selectors';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { getToken } from '../../../../selectors/app.selectors';
import { getRecruitingsForChartSelectionAction } from '../../../../redux/entities/entities.actions';
import { themeOrange } from '../../../../theme/theme';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import { ECellAlign } from '../../../../definitions/components.definitions';
import CurrencyCellRenderer from '../../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { Tooltip } from '@material-ui/core';
import { getDefaultCompanyColumn, getDefaultCreatedColumn } from '../../../../utils/components.utils';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';

const ChartSelectionRecruitingList: React.FC = () => {
    const dispatch = useDispatch();
    const recruitings = useSelector(getRecruitingsForChartSelection);
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.chartSelectionRecruitingsLoading);
    const chartSelection = useSelector((state: IStore) => state.dashboardPage.chartSelection);
    const chartYear = useSelector((state: IStore) => state.dashboardPage.chartYear);

    return (
        <CustomTable
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.VacancyRecruiting,
                loading,
                columnConfig: {
                    type: {
                        header: '#',
                        property: 'type',
                        width: 40,
                        disableOrder: true,
                        cellRenderer: (type: ERecruitingType) => {
                            if (type !== ERecruitingType.TYPE_PROJECT) {
                                return (
                                    <Tooltip title={"Festanstellung"}>
                                        {IconFactory.getIconComponent(EIcons.Work)}
                                    </Tooltip>
                                );
                            }

                            return (
                                <Tooltip title={"Projekt"}>
                                    {IconFactory.getIconComponent(EIcons.AvTimer)}
                                </Tooltip>
                            );
                        }
                    },
                    contractSigningDate: {
                        header: translate('pages.recruiting.contractSigningDateShort'),
                        property: "contractSigningDate",
                        width: 180,
                        align: ECellAlign.center,
                        cellRenderer: (date: string) => {
                            if (date) {
                                return (<DateCellRenderer date={date} />)
                            }
                            return translate('misc.noInformation');
                        },
                    },
                    status: {
                        header: translate('pages.recruiting.status'),
                        property: "status",
                        width: 230,
                        cellRenderer: (status: string) => {
                            return translate('pages.recruiting.statusValues.' + status);
                        },
                    },
                    candidate: {
                        header: translate('pages.activity.properties.candidate'),
                        property: "candidate",
                        flex: 1,
                        cellRenderer: (candidate: ICandidate) => {
                            return <CandidateCellRenderer candidate={candidate} />;
                        },
                    },
                    company: getDefaultCompanyColumn(),
                    vacancy: {
                        header: translate('pages.activity.properties.vacancy'),
                        property: 'vacancy',
                        flex: 1,
                        cellRenderer: (vacancy: null | IVacancy) => {
                            if (vacancy) {
                                return <VacancyCellRenderer vacancy={vacancy}/>;
                            }

                            return null;
                        },
                    },
                    possibleCommission: {
                        header: translate('pages.vacancy.possibleCommission'),
                        property: "possibleCommission",
                        width: 100,
                        align: ECellAlign.right,
                        cellRenderer: (commission: number) => {
                            if (commission) {
                                return (<CurrencyCellRenderer value={commission} />)
                            }
                            return '-';
                        },
                    },
                    billedCommission: {
                        header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.recruiting.billedCommission')}} />,
                        property: "billedCommission",
                        width: 100,
                        align: ECellAlign.right,
                        cellRenderer: (commission: number) => {
                            if (commission) {
                                return (<CurrencyCellRenderer value={commission} />)
                            }
                            return '-';
                        },
                    },
                    openCommission: {
                        header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.recruiting.openCommission')}} />,
                        property: "openCommission",
                        width: 100,
                        align: ECellAlign.right,
                        cellRenderer: (commission: number) => {
                            if (commission) {
                                return (<CurrencyCellRenderer value={commission} />)
                            }
                            return '-';
                        },
                    },
                    totalCommission: {
                        header: translate('pages.recruiting.commissionShort'),
                        property: "totalCommission",
                        width: 100,
                        align: ECellAlign.right,
                        cellRenderer: (commission: number) => {
                            if (commission) {
                                return (<CurrencyCellRenderer value={commission} />)
                            }
                            return '-';
                        },
                    },
                    created: getDefaultCreatedColumn(),
                    responsibleEmployee: {
                        header: translate('misc.employeeShort'),
                        property: "responsibleEmployee",
                        width: 100,
                        cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                            const recruitingCast = recruiting as IRecruiting;

                            if (recruitingCast.suggestedBy && recruitingCast.suggestedBy.id !== responsibleEmployee.id) {
                                return (
                                    <div className={"flex flexCentered"}>
                                        <EmployeeCellRenderer employee={responsibleEmployee} />
                                        <h1 style={{margin:0,fontWeight: "normal"}}>&nbsp;/&nbsp;</h1>
                                        <EmployeeCellRenderer employee={recruitingCast.suggestedBy} />
                                    </div>
                                )
                            }

                            return (<EmployeeCellRenderer employee={responsibleEmployee} />);
                        },
                    },
                },
                sortedEntries: recruitings,
                onRowDoubleClick: (recruitingId: number, entity) => {
                    const recruiting = entity as IRecruitingListModel;
                    dispatch(setUiEditView(EEntityView.recruiting, recruiting.id));
                },
                count: recruitings.length,
                total: recruitings.length,
                onReload: () => {
                    dispatch(getRecruitingsForChartSelectionAction(
                        token,
                        chartSelection,
                        chartYear
                    ));
                }
            }}
        />
    );
};

export default ChartSelectionRecruitingList;
