import React from 'react';
import { EIcons, IconFactory } from '../../../Icons/IconFactory';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';

interface IProps {
    removeFilterAction: () => void;
    active: boolean;
}

const CustomFilterItemRemoveIcon: React.FC<IProps> = (props) => {
    const active = props.active;
    //@ts-ignore
    const removeFilterAction = (event) => {
        props.removeFilterAction();
        event.stopPropagation();
    }

    return <>
        <ListItemSecondaryAction>
            {active &&
                <IconButton edge="end" aria-label="delete" onClick={removeFilterAction}>
                    {IconFactory.getIconComponent(EIcons.Delete, {color: 'var(--brainz-dark)'})}
                </IconButton>
            }
        </ListItemSecondaryAction>
    </>
}

export default React.memo(CustomFilterItemRemoveIcon);
