import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { useCallback, useEffect, useState } from 'react';
import { executeLoadRecordsRequest } from './Autocomplete.utils';

export const useAutocompletHooks = (
    open: boolean,
    value: TBrainzEntity|null|number,
    routeParams: object,
    routeConfig: IRouteConfig,
    reload?: boolean,
    reloadCallback?: () => void,
    loadCallback?: () => void
) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TBrainzEntity[]>([]);
    const [entityValue, setEntityValue] = useState<null|TBrainzEntity>(null);
    const valueIsEntity = value && value.constructor && value.constructor.name === 'Object';

    const loadData = useCallback(() => {
        executeLoadRecordsRequest(token, setLoading, setRecords, routeConfig, routeParams, loadCallback);
    }, [JSON.stringify(routeParams)]);

    useEffect(() => {
        if (reload === true && reloadCallback) {
            loadData();
            reloadCallback();
        }
    }, [reload]);

    useEffect(() => {
        if (open && !loading && records.length === 0) {
            loadData();
        }
    }, [open, token]);

    useEffect(() => {
        if (valueIsEntity) {
            return;
        }
        if (value && !loading) {
            loadData();
        }
    }, [value]);

    const fillSelectedValue = () => {
        const foundValue = records.find((option: TBrainzEntity) => value === option.id);
        setEntityValue(foundValue || null);
    };

    useEffect(() => {
        if (value === null) {
            setEntityValue(null);
            return;
        }

        if (!valueIsEntity && records.length > 0) {
            fillSelectedValue();
        }
    }, [value]);

    useEffect(() => {
        if (!valueIsEntity && value && records.length > 0) {
            fillSelectedValue();
        }
    }, [value, records]);

    return {
        calculatedEntityValue: entityValue,
        records,
        loading
    };
}
