import React, { CSSProperties } from 'react';
import CustomClickableIcon from '../CustomInput/CustomClickableIcon';
import { EIcons } from '../Icons/IconFactory';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

interface IProps {
    onReload?: () => void;
    count?: number;
    totalCount?: number;
    text?: string;
    style?: CSSProperties;
    loading?: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        footer: {
            display: "flex",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            color: theme.palette.common.white,
            height: 30,
            minHeight: 30,
            paddingLeft: 5
        },
    })
);

const CustomTableFooter: React.FC<IProps> = (props) => {
    const styles = useStyles();

    if (!props.onReload && !props.count) {
        return null;
    }

    let string = '';
    if (props.totalCount && props.count) {
        string = `${props.count} / ${props.totalCount}`;
    }
    if (props.totalCount && !props.count) {
        string = props.totalCount.toString();
    }
    if (props.count && !props.totalCount) {
        string = props.count.toString();
    }

    return (
        <div className={styles.footer} style={props.style}>
            {props.loading &&
                <>
                    <CircularProgress color="inherit" size={20}/>
                    <div style={{width: 8}}></div>
                </>
            }
            {(props.onReload && !props.loading) &&
                <CustomClickableIcon
                    onClick={() => {
                        if (props.onReload) {
                            props.onReload()
                        }
                    }}
                    icon={EIcons.Refresh}
                />
            }
            { string }
            <>
                {string !== '' &&
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                }
                { props.text }
            </>
        </div>
    );
};

export default CustomTableFooter;
