import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { Dialog, Typography } from '@material-ui/core';
import GiphyView from '../../../components/CustomGiphy/GiphyView';
import { translate } from '../../../translation/translate.utils';
import { setUiEncouragementOpenAction } from '../../../redux/ui/ui.actions';
import { resetRecruitingStatusChangedToAbortAction } from '../../../redux/entities/entities.actions';
import { COLOR_TEXT } from '../../../theme/theme';

const EncouragementView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.ui.openEncouragement));
    const dispatch = useDispatch();
    const tags = [
        'next time',
        'keep fighting',
        'not your fault'
    ];
    const tagIndex = Math.floor(Math.random() * 3);

    const onClose = () => {
        dispatch(setUiEncouragementOpenAction(false));
        dispatch(resetRecruitingStatusChangedToAbortAction());
    }

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"motivation.titleEncouragement"} onClose={onClose} />
            <CustomDialogBody>
                <Typography align="center" style={{ color: COLOR_TEXT }} variant="h6" noWrap>
                    {translate('motivation.1')}
                </Typography>
                <GiphyView tag={tags[tagIndex]} />
            </CustomDialogBody>
            <CustomDialogActions onClose={onClose}/>
        </Dialog>
    );
};

export default EncouragementView;
