import React, { useEffect, useState } from 'react';
import {
    ECandidateSource, ESuggestionAction,
    IEmployee,
    IEmployeePartial,
    IVacancyPartial,
    TSalutation,
} from '../../../../definitions/entities.definition';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { addSuggestionAction } from '../../../../redux/entities/entities.actions';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import { IStore } from '../../../../definitions/redux/store.definitions';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomBackdropLoading from '../../../../components/CustomBackdrop/CustomBackdropLoading';
import { getSuggestionExternalUrl } from '../../../../utils/suggestion.utils';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import CandidateAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomRadioGroupInput from '../../../../components/CustomInput/CustomRadioGroupInput';
import { getDayDifference } from '../../../../utils/date.utils';
import { useCreateSuggestionForVacancyHook } from './AddSuggestionForVacancyForm.hooks';
import { resetFormValidation } from '../../../../redux/error/error.actions';
import {
    setCandidatePageAddDialogAction,
    setCandidatePageCvToImportAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';
import CandidateInformationColumnCellRenderer
    from '../../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import RenderSuggestionListItem from '../../../Shared/SuggestionList/RenderSuggestionListItem';
import { getEditViewUrl } from '../../../../utils/ui.utils';
import { Card, CardHeader } from '@material-ui/core';
import styles from '../../../Shared/Todo/TodoCard.module.css';
import CandidateSourceRenderer from '../../../../components/CustomCellRenderer/CandidateSourceRenderer';
import CustomButton from '../../../../components/CustomInput/CustomButton';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import PortalDropDown from '../../../../components/CustomInput/CustomDropDownInput/PortalDropDown';
import moment from 'moment';

interface IProps {
    onClose: () => void;
    vacancy: IVacancyPartial
}

const AddSuggestionForVacancyForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancy = props.vacancy;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const errors = useSelector(getValidationErrorsFromStore);
    const importCandidate = useSelector((store: IStore) => store.ui.importCandidate);
    const [addType, setAddType] = useState<'candidate'|'newCandidate'>('newCandidate');
    const [salutation, setSalutation] = useState<TSalutation>('mr');
    const [candidateId, setCandidateId] = useState(0);
    const [name, setName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [externalCandidateReference, setExternalCandidateReference] = useState<string>("");
    const [externalCandidateUrl, setExternalCandidateUrl] = useState<string>("");
    const [source, setSource] = useState(ECandidateSource.jobcenter);
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial|undefined>();
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial|undefined>();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [email, setEmail] = useState<string>("");
    const [addAction, setAddAction] = useState(false);
    const addActions = [ESuggestionAction.ACTION_MESSAGE_MAIL, ESuggestionAction.ACTION_MESSAGE_PORTAL, ESuggestionAction.ACTION_MESSAGE_MOBILE, ESuggestionAction.ACTION_LETTER_SEND, ESuggestionAction.ACTION_CALL, ESuggestionAction.ACTION_CALL_NOT_REACHED];
    const [userInput, setUserInput] = useState("");
    const [action, setAction] = useState<ESuggestionAction>();

    useEffect(() => {
        setResponsibleEmployee(vacancy?.responsibleEmployee);
        setSuggestedBy(loggedInEmployee);
    }, [dispatch]);

    const onSave = () => {
        dispatch(addSuggestionAction(token, {
            vacancyId: vacancy.id,
            candidateId,
            salutation,
            name,
            firstName,
            externalCandidateUrl,
            externalCandidateReference,
            source: source,
            phoneNumber,
            suggestedByEmployeeId: suggestedBy ? suggestedBy.id : undefined,
            responsibleEmployeeId: responsibleEmployee ? responsibleEmployee.id : undefined,
            contactAction: addAction ? action : undefined,
            contactUserInput: addAction ? userInput : undefined
        }));
        return;
    }

    const externalUrl = getSuggestionExternalUrl(source, externalCandidateUrl, externalCandidateReference);
    const {matchingSuggestions, duplicateCandidates} = useCreateSuggestionForVacancyHook(name, firstName, externalCandidateReference);

    return <>
        <div style={{ marginRight:10, marginLeft: 10, overflow: "auto", backgroundColor: 'var(--brainz-darker)' }}>
            <CustomBackdropLoading open={importCandidate} />
            <PaperRow>
                <CustomRadioGroupInput
                    value={addType}
                    //@ts-ignore
                    onChange={(type) => {setAddType(type)}}
                    states={[{
                        value: 'candidate',
                        label: 'Kandidat aus Datenbank auswählen'
                    }, {
                        value: 'newCandidate',
                        label: 'Favorit händisch anlegen'
                    }]}
                />
            </PaperRow>
            { addType === 'candidate' &&
                <PaperRow>
                    <CandidateAutocomplete
                        value={candidateId}
                        onChange={(value) => {
                            if (value) {
                                setCandidateId(value.id)
                                if (getDayDifference(value.created) <= 30) {
                                    setSource(value.source);
                                    return;
                                }
                                setSource(ECandidateSource.brainz);
                            } else {
                                setCandidateId(0);
                            }
                        }}
                    />
                    <CandidateSourceDropDown
                        value={source}
                        onChange={(event) => setSource(event.target.value)}
                    />
                </PaperRow>
            }
            {addType === 'newCandidate' &&
                <>
                    <PaperRow>
                        <CandidateSourceDropDown
                            value={source}
                            onChange={(event) => setSource(event.target.value)}
                        />
                        {source === ECandidateSource.jobcenter &&
                            <CustomUrlField
                                flex={2}
                                required
                                url={externalUrl}
                                label={translate('pages.vacancy.externalCandidateReference')}
                                value={externalCandidateReference}
                                onChange={(value) => setExternalCandidateReference(value)}
                                error={Boolean(errors.externalCandidateReference)}
                                helperText={errors.externalCandidateReference}
                            />
                        }
                        {(source !== ECandidateSource.recommendation && source !== ECandidateSource.jobcenter && source !== ECandidateSource.brainz && source !== ECandidateSource.further) &&
                            <>
                                <CustomUrlField
                                    flex={2}
                                    required
                                    label={translate('misc.url')}
                                    value={externalCandidateUrl}
                                    onChange={(value) => setExternalCandidateUrl(value)}
                                    error={Boolean(errors.externalCandidateUrl)}
                                    helperText={errors.externalCandidateUrl}
                                />
                            </>
                        }
                    </PaperRow>
                    <PaperRow>
                        <div className={"flexContainerRow gap10 flex1"}>
                            <SalutationDropDown
                                // @ts-ignore-next-line
                                onChange={(event: any) => setSalutation(event.target.value)}
                                value={salutation}
                                required={true}
                            />
                            <CustomTextField
                                value={firstName}
                                onChange={(value) => setFirstName(value)}
                                label={translate('pages.candidate.properties.firstName')}
                            />
                        </div>
                        <CustomTextField
                            value={name}
                            onChange={(value) => setName(value)}
                            label={translate('pages.candidate.properties.name')}
                        />
                    </PaperRow>
                    {duplicateCandidates.length > 0 &&
                    <>
                        <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen/gleicher Referenz</h4>
                        <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Kandidaten um diesen auszuwählen.</div>
                        <div className={"flexContainerRow flexWrap flex1 gap10"}>
                            {duplicateCandidates.map((candidate) => <>
                                <div
                                    className={"flexContainerRow CandidateInformationPanel"}
                                    onClick={() => {
                                        setAddType('candidate');
                                        setCandidateId(candidate.id);
                                        setName('');
                                        setFirstName('');
                                        setPhoneNumber('');
                                        setEmail('');
                                        setExternalCandidateReference('');
                                    }}
                                >
                                    <CandidateInformationColumnCellRenderer
                                        key={candidate.id}
                                        showDetails
                                        token={token}
                                        candidate={candidate}
                                    />
                                </div>
                            </>
                            )}
                        </div>
                    </>
                    }
                    {matchingSuggestions.length > 0 &&
                    <>
                        <h4 style={{margin: 5}}>Vorschläge mit gleichen Namen/gleicher Referenz</h4>
                        <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Favoriten um seine Daten zu übernehmen.</div>
                        {matchingSuggestions.map((suggestion) => <>
                                <div className={"flexContainerRow flexAutoGrow"}>
                                    <Card className={styles.root + ' hoverContainer'} style={{cursor: 'default'}}>
                                        <CardHeader
                                            title={
                                                <>
                                                    <div className={"flexColumn gap5"}>
                                                        <div className={"flex"}>
                                                            <div
                                                                className={"flex1 flexContainerRow alignVerticalCenter"}
                                                                style={{border: '0px solid red', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    setAddType('newCandidate');
                                                                    setCandidateId(0);
                                                                    setName(suggestion.name || '');
                                                                    setFirstName(suggestion.firstName|| '');
                                                                    setSource(suggestion.source);
                                                                    setExternalCandidateUrl(suggestion.externalCandidateUrl|| '');
                                                                    setPhoneNumber(suggestion.phoneNumber);
                                                                    setEmail(suggestion.phoneNumber);
                                                                }}
                                                            >
                                                                <CandidateSourceRenderer source={suggestion.source}/>&nbsp;
                                                                {suggestion.fullName || suggestion.externalCandidateReference}<br/>
                                                                {suggestion.vacancy.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            titleTypographyProps={{
                                                variant: "body2"
                                            }}
                                        />
                                    </Card>
                                </div>
                            </>
                        )}
                    </>
                    }
                    <PaperRow>
                        <CustomTextField
                            value={phoneNumber}
                            onChange={(value) => setPhoneNumber(value)}
                            label={translate('misc.mobileNumber')}
                        />
                        <CustomTextField
                            value={email}
                            onChange={(value) => setEmail(value)}
                            label={translate('misc.email')}
                        />
                    </PaperRow>
                </>
            }
            <PaperRow>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.suggestedByEmployee')}
                    value={suggestedBy}
                    onChange={(event, value: IEmployee) => setSuggestedBy(value)}
                />
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(event, value: IEmployee) => setResponsibleEmployee(value)}
                />
            </PaperRow>
            <PaperRow>
                <CustomCheckboxInput
                    onChange={() => setAddAction(!addAction)}
                    checked={addAction}
                    label={"Favorit wurde bereits kontaktiert"}
                />
            </PaperRow>
            {addAction &&
                <>
                    <div className={"flexContainerColumn marginLeft5"}>
                        <div className={"flexContainerRow marginLeft5"} style={{flexWrap: 'wrap'}}>
                            {addActions.map((actionValue: ESuggestionAction, index: number) => {
                                return (
                                    <div key={index} style={{width: 140}}>
                                        <CustomCheckboxInput
                                            checked={action === actionValue}
                                            onChange={() => setAction(actionValue)}
                                            label={translate('pages.activity.suggestionActionValueShort.' + actionValue)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.activity.properties.userInput')}
                            value={userInput}
                            multiline={true}
                            onChange={(value) => setUserInput(value)}
                            rows={10}
                        />
                    </PaperRow>
                </>
            }
        </div>
        <CustomDialogActions
            onClose={props.onClose}
            buttons={[{
                void: onSave,
                label: translate('misc.buttons.saveAndClose')
            }]}
        />
    </>;
};

export default AddSuggestionForVacancyForm;
