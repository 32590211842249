import React, { useCallback, useState } from 'react';
import { ISkill, ISkillPartial } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import PaperRow from '../../../CustomPaper/PaperRow';
import { EIcons } from '../../../Icons/IconFactory';
import SkillMultipleAutocomplete from '../../../CustomInput/CustomAutocomplete/components/SkillMultipleAutocomplete';
import { objectsAreEqual } from '../../../../utils/application.utils';

interface IProps {
    skills: ISkill[]|ISkillPartial[];
    optionalSkills: ISkill[]|ISkillPartial[];
    onChange: (skills: ISkill[]|ISkillPartial[], optionalSkills: ISkill[]|ISkillPartial[]) => void;
}

const ComplexeSkillFilter: React.FC<IProps> = (props) => {
    const [skills, setSkills] = useState(props.skills);
    const [optionalSkills, setOptionalSkills] = useState(props.optionalSkills);

    const submit = () => {
        props.onChange(skills, optionalSkills);
    }

    const skillRenderer = (title: string, skills: ISkill[]|ISkillPartial[]) => {
        if (skills.length === 0) {
            return null;
        }

        if (skills.length <= 2) {
            return <>
                <b>{title}</b><br/>
                {skills.map((skill) => skill.name).join(', ')}
            </>
        }

        return <>
            <b>{title} ({skills.length})</b>
        </>
    }

    const renderValue = useCallback(() => {
        if (!deletable) {
            return null;
        }
        return <>
            {skillRenderer('Must have Skills', props.skills)}
            {(props.skills.length > 0 && props.optionalSkills.length > 0) &&
                <div style={{height: 5}}>&nbsp;</div>
            }
            {skillRenderer('Optionale Skills', props.optionalSkills)}
        </>
    }, [props.skills, props.optionalSkills]);

    const resetFilter = () => {
        props.onChange([], []);
    }

    const dataConsistent = objectsAreEqual(skills, props.skills) && objectsAreEqual(optionalSkills, props.optionalSkills);
    const deletable = props.skills.length > 0 || props.optionalSkills.length > 0;

    return <>
        <CustomFilterItem
            title={"Skills"}
            icon={EIcons.Skill}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            showDialog
            submit={submit}
            dataConsistent={dataConsistent}
        >
            <PaperRow>
                <SkillMultipleAutocomplete
                    autoFocus
                    label={"Must Have Skills"}
                    value={skills}
                    onChange={setSkills}
                />
            </PaperRow>
            <PaperRow>
                <SkillMultipleAutocomplete
                    label={"Optionale Skills"}
                    value={optionalSkills}
                    onChange={setOptionalSkills}
                />
            </PaperRow>
            <PaperRow>
                <div className={"flex"}>
                    <b>Skill-Filter-Kombinationen (In der Entwicklung)</b>
                </div>
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(ComplexeSkillFilter);
