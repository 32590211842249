import { IGoalBurnUpChartData } from '../definitions/dashboardPage.definitions';
import { useSelector } from 'react-redux';
import { IStore } from '../definitions/redux/store.definitions';
import { getChartDataByGroupId } from './dashboard.utils';
import { IInvoiceGoalBurnUpChartData } from '../definitions/billingPage.definitions';

export const getInvoiceBurnupChartData = () : IGoalBurnUpChartData[] => {
    const chartDataByMonth = useSelector((state: IStore) => state.billingPage.groupedEvaluationData.byMonth);
    const chartDataByMonthSummedUp = useSelector((state: IStore) => state.billingPage.groupedEvaluationData.byMonthSummedUp);
    const chartDataByTarget = useSelector((state: IStore) => state.dashboardPage.target);

    const burnUpChartDataRecords: IInvoiceGoalBurnUpChartData[] = [];
    if (chartDataByMonth && chartDataByMonthSummedUp && chartDataByTarget) {
        burnUpChartDataRecords.push({
            targetCommission: 0,
            totalCommission: 0,
            monthTotalCommission: 0,
            month: 0
        });

        for (let x=1; x<=12; x++) {
            const targetData = getChartDataByGroupId(chartDataByTarget, x);
            const totalData = getChartDataByGroupId(chartDataByMonthSummedUp, x);
            const totalMonthData = getChartDataByGroupId(chartDataByMonth, x);

            burnUpChartDataRecords.push({
                targetCommission: targetData?.commission,
                totalCommission: totalData?.commission,
                monthTotalCommission: totalMonthData?.commission,
                month: x
            });
        }
    }

    return burnUpChartDataRecords;
}

