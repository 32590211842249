import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EEntities, IWebVacancy } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { debounce } from '@material-ui/core';
import { setVacancyPageCandidateSearchFilterAction } from '../../../../redux/vacancyPage/vacancyPage.actions';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';

interface IProps {
    webVacancy: IWebVacancy;
}

const SearchCandidateFilterPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [fastSearch, setFastSearch] = useState(props.webVacancy.filter);

    const changeHandler = (value: string) => {
        dispatch(setVacancyPageCandidateSearchFilterAction({fastSearch: value}));
    };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const configuration: IAppBarConfig = {
        entity: EEntities.VacancyCandidateSearch,
        value: fastSearch ?? '',
        emptyValue: translate('pages.candidate.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => {
            setFastSearch(value);
            debouncedChangeHandler(value);
        },
        filterPanelType: EFilterPanelType.sidePanel
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default SearchCandidateFilterPanel;
