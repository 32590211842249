import { useEffect } from 'react';
import { getRecruitingsForVacancyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getRecruitingsForVacancy,
    getVacancyPageRequestObjectForRecruitingsInEditView,
} from '../../../../../selectors/recruiting.selectors';

export const useVacancyRecruitingListHook = (vacancyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.vacancyRecruitingsLoading);

    const requestObject = useSelector(getVacancyPageRequestObjectForRecruitingsInEditView);
    const forceReload = useSelector((store: IStore) => store.vacancyPage.reloadRecruitingsOfVacancy);
    const recruitings = useSelector((store: IStore) => getRecruitingsForVacancy(store, vacancyId));

    useEffect(() => {
        if (!loading) {
            dispatch(getRecruitingsForVacancyAction(token, vacancyId, requestObject));
        }
    }, [forceReload]);

    return {loading, recruitings, total: recruitings.length};
}
