import { Drawer, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import { closeUiRightDrawerAction } from '../../../redux/ui/ui.actions';
import { getNewsListAction, putNewsReadTimestampAction } from '../../../redux/entities/entities.actions';
import NewsList from './News/NewsList';
import { ERightDrawerViews } from '../../../definitions/ui.definitions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { useDrawerStyles } from './Drawer.styles';

const RightDrawerNewsView = () => {
    const classes = useDrawerStyles();
    const dispatch = useDispatch();
    const drawerOpen = useSelector((store: IStore) => Boolean(store.ui.rightDrawerView === ERightDrawerViews.viewNews));
    const reloadNews = useSelector((store: IStore) => store.news.reloadNews);
    const token = useSelector((store: IStore) => store.actor.token);
    const start = useSelector((store: IStore) => store.news.start);
    const limit = useSelector((store: IStore) => store.news.limit);
    const loading = useSelector((state: IStore) => state.ui.newsLoading);

    useEffect(() => {
        if (drawerOpen && !loading) {
            dispatch(getNewsListAction(token, start, limit));
            dispatch(putNewsReadTimestampAction(token));
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (reloadNews && !loading) {
            dispatch(getNewsListAction(token, start, limit));
        }
    }, [reloadNews]);

    return (
        <Drawer
            anchor={"right"}
            open={drawerOpen}
            variant={'persistent'}
            classes={{paper: classes.drawerOpen + ' ' + classes.defaultWidth + ' ' + classes.boxShadow}}
        >
            <div className={classes.toolbar}>
                <div className={"flex flexCentered"}>
                    <h2>Neuigkeiten</h2>
                </div>
                <IconButton
                    onClick={() => {
                        if (drawerOpen) {
                            dispatch(closeUiRightDrawerAction());
                            return;
                        }
                    }}
                >
                    {IconFactory.getIconComponent(EIcons.ChevronRight)}
                </IconButton>
            </div>
            { drawerOpen &&
                <NewsList />
            }
        </Drawer>
    );
}

export default RightDrawerNewsView;
