import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ERecruitingDocumentType } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value: string;
}
const RecruitingDocumentTypeDropDown: React.FC<IProps> = (props) => {
    const types = Object.values(ERecruitingDocumentType);

    let menuItems = types.map((type: ERecruitingDocumentType, index: number) => (
        <MenuItem key={index + 1} value={type}>
            {translate('pages.recruitingDocument.typeValue.' + type)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value}
            id={"recruiting-document-type-dropdown"}
            label={translate('pages.recruitingDocument.properties.type')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default RecruitingDocumentTypeDropDown;
