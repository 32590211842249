import React from 'react';
import CustomDrawerItem from '../../components/CustomDrawer/CustomDrawerItem';
import { ERoutes } from '../../definitions/app.definitions';
import { EIcons } from '../../components/Icons/IconFactory';
import { COLOR_BLUE } from '../../theme/theme';
import { setUiCurrentRouteAction } from '../../redux/ui/ui.actions';
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Drawer } from '@material-ui/core';

const LeftDrawerNavigation: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const onClick = (route: ERoutes) => {
        dispatch(setUiCurrentRouteAction(route));
        history.push(route);
    };

    return <>
        <Drawer variant="permanent">
            <List className={"AppNavigation gap10"}>
                <CustomDrawerItem
                    currentRoute={history.location.pathname}
                    route={ERoutes.Recruitings}
                    onClick={onClick}
                    translation={"customerApp.titleRecruiting"}
                    icon={EIcons.ThumbsUpDown}
                    color={COLOR_BLUE}
                    customKey={2}
                />
            </List>
        </Drawer>
    </>
}

export default React.memo(LeftDrawerNavigation);
