import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ERecruitingInformationFilterType } from '../../../definitions/filter.definitions';

interface IProps {
    onChange: (value?: ERecruitingInformationFilterType) => void;
    value?: ERecruitingInformationFilterType;
}

const RecruitingInformationTypeDropDown: React.FC<IProps> = (props) => {
    const modes: ERecruitingInformationFilterType[] = [
        ERecruitingInformationFilterType.neverSuggested,
        ERecruitingInformationFilterType.suggested,
        ERecruitingInformationFilterType.aborted,
        ERecruitingInformationFilterType.withContract
    ];

    let menuItems = modes.map((mode: ERecruitingInformationFilterType, index: number) => (
        <MenuItem key={index + 1} value={mode}>
            {translate('misc.recruitingFilterType.' + mode)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={""} value={""}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === "") {
                    props.onChange();
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value === undefined ? "" : props.value}
            id={"recruiting-information-type-dropdown"}
            label={"Prozess-Kriterien"}
            required
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default RecruitingInformationTypeDropDown;
