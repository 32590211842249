import React from 'react';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import { useDispatch } from 'react-redux';
import TopBarButton from '../../../../components/ListAndPreview/TopBarButton';
import { setUiActivityAddDataAction } from '../../../../redux/ui/ui.actions';
import { EActivityModule, ITodo } from '../../../../definitions/entities.definition';
import TopBarPanel from '../../../../components/ListAndPreview/TopBarPanel';
import TodoActivityList from './TodoActivityList';

interface IProps {
    todo: ITodo;
}

const TodoActivityTab: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    return <>
        <TopBarPanel>
            <TopBarButton
                onClick={() => dispatch(setUiActivityAddDataAction({
                    add: true,
                    module: EActivityModule.todo,
                    todoId: props.todo.id,
                    candidateId: props.todo.candidate?.id,
                    companyId: props.todo.company?.id,
                    vacancyId: props.todo.vacancy?.id,
                    recruitingId: props.todo.recruiting?.id
                }))}
                icon={EIcons.Add}
                label={translate('pages.activity.add')}
            />
        </TopBarPanel>
        <TodoActivityList todoId={props.todo.id} />
    </>;
};

export default TodoActivityTab;
