import React, { useState } from 'react';
import { createStyles, Tooltip } from '@material-ui/core';
import ContractsCountElement from './components/ContractsCountElement';
import { useLoadContracts } from './ContractsCountCellRenderer.hook';
import RecruitingPreviewList from '../../Pages/RecruitingPage/components/RecruitingPreviewList';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    count: number;
    companyId?: number;
    candidateId?: number;
}


const useStyles = makeStyles(
    createStyles({
        tooltip: {
            width: 620,
            maxWidth: 620,
            maxHeight: 300
        },
        popper: {
            maxHeight: 300
        }
    })
);

const ContractsCountCellRenderer: React.FC<IProps> = (props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {contracts, loading} = useLoadContracts(tooltipOpen, props.candidateId, props.companyId);

    const content = <RecruitingPreviewList recruitings={contracts} candidateOrCompany={props.candidateId ? 'candidate' : 'company'}/>
    const styles = useStyles();

    if (props.count === 0) {
        return null;
    }

    return <>
        <Tooltip
            placement={"right"}
            classes={styles}
            interactive
            title={content}
            onOpen={() => setTooltipOpen(true)}
            onClose={() => setTooltipOpen(false)}
        >
            <div style={{marginRight: 10}}>
                <ContractsCountElement count={props.count} />
            </div>
        </Tooltip>
    </>;
};

export default React.memo(ContractsCountCellRenderer);
