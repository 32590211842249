import React from 'react';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { translate } from '../../../translation/translate.utils';
import { ITodo } from '../../../definitions/entities.definition';
import TodoActivityTab from './components/TodoActivityTab';
import TodoEditForm from './components/TodoEditForm';
import { themeBlue } from '../../../theme/theme';

interface IProps {
    todo: ITodo;
    onClose: () => void;
}

const TodoEditViewBody: React.FC<IProps> = (props) => {
    return (
        <CustomTabContainer
            color={themeBlue.palette.primary.main}
            tabs={[{
                label: translate('misc.dataTabTitle'),
                component: (
                    <>
                        <TodoEditForm todo={props.todo} onClose={props.onClose} />
                    </>
                )
            }, {
                label: translate('misc.activities'),
                component: <TodoActivityTab todo={props.todo}/>,
            }]}
        />
    );
};

export default TodoEditViewBody;
