import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBillingPageInvoiceGenerationDialogAction,
    setBillingPageReloadInvoiceListAction,
} from '../../../redux/billingPage/billingPage.actions';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../translation/translate.utils';
import { ClientApi } from '../../../requests/ClientApi';
import { getBillableRecruitingListRouteConfig, postInvoiceListRouteConfig } from '../../../requests/routes';
import { getToken } from '../../../selectors/app.selectors';
import { IBillableData, IRecruitingPartial, IVacancyPartial } from '../../../definitions/entities.definition';
import InvoiceGenerationList from './components/InvoiceGenerationList';

const InvoiceGenerationDialog: React.FC = () => {
    const open = useSelector((store: IStore) => store.billingPage.generateViewOpen);
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [invoices, setInvoices] = useState<IBillableData[]>([]);
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        dispatch(setBillingPageInvoiceGenerationDialogAction(false));
    };

    const load = () => {
        setLoading(true);
        ClientApi.request(getBillableRecruitingListRouteConfig, {
            token
        }).then((result) => {
            setLoading(false);
            setInvoices(result.records)
        });
    }

    const save = () => {
        const recruitingIds: number[] = invoices.filter((invoice) => invoice.recruiting)
                                            .map(({ recruiting }) => {
                                                const recruitingCast = recruiting as IRecruitingPartial;
                                                return recruitingCast.id
                                            });
        const vacancyIds: number[] = invoices.filter((invoice) => invoice.vacancy)
            .map(({ vacancy }) => {
                const vacancyCast = vacancy as IVacancyPartial;
                return vacancyCast.id
            });

        ClientApi.request(postInvoiceListRouteConfig, {
            token,
            recruitingIds,
            vacancyIds
        }).then((result) => {
            dispatch(setBillingPageReloadInvoiceListAction(true));
            onClose();
        });
    }

    useEffect(() => {
        if (open) {
            load()
        }
    }, [dispatch, open]);

    return (
        <CustomDialog fullWidth open={open} onClose={onClose}>
            <CustomDialogHeader translationString="pages.billing.invoiceGeneration" onClose={onClose} />
            <InvoiceGenerationList
                invoices={invoices}
                onReload={load}
                loading={loading}
            />
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => save(),
                    label: translate('pages.billing.createInvoices')
                }]}
            />
        </CustomDialog>
    );
};

export default InvoiceGenerationDialog;
