import {
    IGetWebVacancyAction,
    ISetWebVacancyEditViewActiveTabAction,
    ISetWebVacancyPageCandidateSearchFilterAction,
    ISetWebVacancyPageCandidateSearchFilterMenuAction,
    ISetWebVacancyPageCandidateSearchSortingOptionAction,
    ISetWebVacancyPageEditViewActivityFastSearchAction,
    ISetWebVacancyPageEditViewActivitySortingOptionAction,
    ISetWebVacancyPageFastSearchAction,
    ISetWebVacancyPageFilterAction,
    ISetWebVacancyPageFilterMenuAction,
    ISetWebVacancyPageIdToImportAction,
    ISetWebVacancyPageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IWebVacancyPageState, preloadedStateWebVacancyPage } from '../../definitions/webVacancyPage.definitions';
import {
    WEB_VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER,
    WEB_VACANCY_PAGE_SET_ACTIVE_TAB_OF_EDIT_WEB_VACANCY_VIEW,
    WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER,
    WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU,
    WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING,
    WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH,
    WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    WEB_VACANCY_PAGE_SET_FAST_SEARCH,
    WEB_VACANCY_PAGE_SET_FILTER,
    WEB_VACANCY_PAGE_SET_FILTER_MENU,
    WEB_VACANCY_PAGE_SET_ID_TO_IMPORT,
    WEB_VACANCY_PAGE_SET_SORTING_OPTION,
} from './webVacancyPage.actions';
import {
    ENTITIES_GET_WEB_VACANCY_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_REJECTED,
    ENTITIES_POST_VACANCY_FULFILLED,
    ENTITIES_PUT_VACANCY_FULFILLED,
    ENTITIES_PUT_WEB_VACANCY_FULFILLED,
} from '../entities/entities.actions';
import { preloadedStateCandidatePage } from '../../definitions/candidatePage.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';

const webVacancyPageReducer = (state = preloadedStateWebVacancyPage, action: TReducerAction): IWebVacancyPageState => {
    switch (action.type) {
        case WEB_VACANCY_PAGE_SET_ACTIVE_TAB_OF_EDIT_WEB_VACANCY_VIEW: {
            const actionCast = action as ISetWebVacancyEditViewActiveTabAction;

            return {
                ...state,
                activeTabWebVacancyEditView: actionCast.payload
            }
        }
        case ENTITIES_PUT_WEB_VACANCY_FULFILLED: {
            return {
                ...state,
                reload: true
            }
        }
        case ENTITIES_GET_WEB_VACANCY_LIST_REJECTED:
        case ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED: {
            return {
                ...state,
                reload: false
            }
        }
        case ENTITIES_GET_WEB_VACANCY_FULFILLED: {
            const actionCast = action as IGetWebVacancyAction;

            return {
                ...state,
                filterCandidateList: {
                    ...state.filterCandidateList,
                    distanceSearchCompanyLocationId: actionCast.payload.locationModels.length === 1 ? actionCast.payload.locationModels[0].id : undefined,
                    distanceSearchKm: actionCast.payload.locationModels.length === 1 ? 30 : undefined,
                    languageIds: actionCast.payload.languages.map((language) => language.id),
                    searchForWebVacancyId: actionCast.payload.id,
                    fastSearch: actionCast.payload.filter
                },
                sortingCandidateList: {
                    'lastActivity': ESortingOptions.Descending
                },
                filterMenuCandidateList: {
                    ...state.filterMenuCandidateList,
                    distanceSearchCompanyLocation: actionCast.payload.locationModels.length === 1 ? actionCast.payload.locationModels[0] : undefined,
                    distanceSearchKm: actionCast.payload.locationModels.length === 1 ? 30 : undefined,
                    languages: actionCast.payload.languages
                }
            };
        }
        case WEB_VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER: {
            return {
                ...state,
                filterCandidateList: {
                    ...preloadedStateCandidatePage.filter,
                    distanceSearchCompanyLocationId: state.filterCandidateList.distanceSearchCompanyLocationId,
                    distanceSearchKm: state.filterCandidateList.distanceSearchKm,
                    searchForWebVacancyId: state.filterCandidateList.searchForWebVacancyId
                },
                filterMenuCandidateList: {
                    ...preloadedStateCandidatePage.filterMenu,
                    distanceSearchCompanyLocation: state.filterMenuCandidateList.distanceSearchCompanyLocation,
                    distanceSearchKm: state.filterMenuCandidateList.distanceSearchKm,
                },
            };
        }
        case WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER: {
            const actionCast = action as ISetWebVacancyPageCandidateSearchFilterAction;

            return {
                ...state,
                filterCandidateList: {
                    ...state.filterCandidateList,
                    ...actionCast.payload
                }
            }
        }
        case WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU: {
            const actionCast = action as ISetWebVacancyPageCandidateSearchFilterMenuAction;

            return {
                ...state,
                filterMenuCandidateList: {
                    ...state.filterMenuCandidateList,
                    ...actionCast.payload
                }
            }
        }
        case WEB_VACANCY_PAGE_SET_FILTER: {
            const actionCast = action as ISetWebVacancyPageFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload
                },
            }
        }
        case WEB_VACANCY_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetWebVacancyPageFilterMenuAction;

            const retState = {
                ...state,
                filterMenu: {
                    ...state.filterMenu,
                    ...actionCast.payload
                },
            }

            return retState;
        }
        case WEB_VACANCY_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetWebVacancyPageSortingOptionAction;

            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case WEB_VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING: {
            const actionCast = action as ISetWebVacancyPageCandidateSearchSortingOptionAction;

            return {
                ...state,
                sortingCandidateList: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            }
        }
        case WEB_VACANCY_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetWebVacancyPageFastSearchAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    fastSearch: actionCast.payload
                },
            }
        }
        case WEB_VACANCY_PAGE_SET_ID_TO_IMPORT: {
            const actionCast = action as ISetWebVacancyPageIdToImportAction;

            return {
                ...state,
                importId: actionCast.payload
            }
        }
        case WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetWebVacancyPageEditViewActivitySortingOptionAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case WEB_VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH: {
            const actionCast = action as ISetWebVacancyPageEditViewActivityFastSearchAction;
            return {
                ...state,
                activityFastSearch: actionCast.payload
            };
        }

        case ENTITIES_POST_VACANCY_FULFILLED:
        case ENTITIES_PUT_VACANCY_FULFILLED: {
            return {
                ...state,
                importId: 0
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default webVacancyPageReducer;
