import {
    IGetAnalyticsPageCompanyMetricsData,
    IGetAnalyticsPageEmployeePerformanceData,
    ISetAnalyticsPageCompanyMetricsSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IAnalyticsPageState, preloadedStateAnalyticsPage } from '../../definitions/analyticsPage.definitions';
import {
    ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_FULFILLED,
    ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_FULFILLED,
    ANALYTICS_PAGE_SET_METRICS_VIEW_SORTING_OPTION,
} from './analyticsPage.actions';

const analyticsPageReducer = (state = preloadedStateAnalyticsPage, action: TReducerAction): IAnalyticsPageState => {
    switch (action.type) {
        case ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_FULFILLED: {
            const actionCast = action as IGetAnalyticsPageEmployeePerformanceData;

            return {
                ...state,
                employeePerformanceData: {
                    groupedByPeriod: actionCast.payload
                }
            };
        }
        case ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_FULFILLED: {
            const actionCast = action as IGetAnalyticsPageCompanyMetricsData;

            return {
                ...state,
                companyMetrics: actionCast.payload
            };
        }
        case ANALYTICS_PAGE_SET_METRICS_VIEW_SORTING_OPTION: {
            const actionCast = action as ISetAnalyticsPageCompanyMetricsSortingOptionAction;
            return {
                ...state,
                companyMetricsSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        default:
            return {
                ...state
            }
    }
}


export default analyticsPageReducer;
