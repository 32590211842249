import React from 'react';
import { ICompanyPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import CustomAutocomplete from '../CustomAutocomplete';
import { getCompanyListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';

interface IProps {
    value: ICompanyPartial|number|null;
    onChange: (value: ICompanyPartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    onAdd?: () => void;
}

const CompanyAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const onAdd = props.onAdd;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICompanyPartial);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ICompanyPartial).name;
    }

    return <CustomAutocomplete
        id={"company-autocomplete"}
        label={props.label || translate('misc.company')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCompanyListRouteConfig}
        getOptionLabel={getOptionLabel}
        onAdd={onAdd}
        routeParams={{
            orderBy: 'name',
            orderDirection: 'DESC',
            start: 0,
            limit: 0
        }}
    />;
}

export default React.memo(CompanyAutocomplete);
