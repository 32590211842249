import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PolicyIcon from '@material-ui/icons/Policy';
import CloseIcon from '@material-ui/icons/Close';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PersonIcon from '@material-ui/icons/Person';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import CancelIcon from '@material-ui/icons/Cancel';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import GroupIcon from '@material-ui/icons/Group';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AddIcon from '@material-ui/icons/Add';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ChatIcon from '@material-ui/icons/Chat';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HomeIcon from '@material-ui/icons/Home';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PanToolIcon from '@material-ui/icons/PanTool';
import LoopIcon from '@material-ui/icons/Loop';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import FaceIcon from '@material-ui/icons/Face';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WorkIcon from '@material-ui/icons/Work';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HistoryIcon from '@material-ui/icons/History';
import SuperVisorIcon from '@material-ui/icons/SupervisorAccount';
import RefreshIcon from '@material-ui/icons/Refresh';
import CalendarIcon from '@material-ui/icons/Event';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReplayIcon from '@material-ui/icons/Replay';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/Remove';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CakeIcon from '@material-ui/icons/Cake';
import FlagIcon from '@material-ui/icons/Flag';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CopyIcon from '@material-ui/icons/FileCopy';
import ToolIcon from '@material-ui/icons/Build';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import PublicIcon from '@material-ui/icons/Public';
import TodayIcon from '@material-ui/icons/Today';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddCommentIcon from '@material-ui/icons/AddComment';
import CommentIcon from '@material-ui/icons/Comment';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import CheckIcon from '@material-ui/icons/Check';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import SaveIcon from '@material-ui/icons/Save';
import LinkIcon from '@material-ui/icons/Link';
import EuroIcon from '@material-ui/icons/Euro';
import PrintIcon from '@material-ui/icons/Print';
import LaunchIcon from '@material-ui/icons/Launch';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import LabelIcon from '@material-ui/icons/Label';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ListIcon from '@material-ui/icons/List';
import TreeIcon from '@material-ui/icons/AccountTree';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import FeedbackIcon from '@material-ui/icons/Feedback';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import PhotoIcon from '@material-ui/icons/Photo';
import { AccountBox } from '@material-ui/icons';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import BarChartIcon from '@material-ui/icons/BarChart';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddBoxIcon from '@material-ui/icons/AddBox';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SortIcon from '@material-ui/icons/Sort';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WcIcon from '@material-ui/icons/Wc';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ShareIcon from '@material-ui/icons/Share';
import ArchiveIcon from '@material-ui/icons/Archive';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PageviewIcon from '@material-ui/icons/Pageview';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import ComputerIcon from '@material-ui/icons/Computer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
export enum EIcons {
    Pageview,
    Share,
    AddIcCall,
    ScreenShare,
    StopScreenShare,
    EmojiPeople,
    SaveAlt,
    FiberManualRecord,
    Sort,
    DriveEta,
    TextFields,
    Attachment,
    AddBox,
    Filter,
    MoreIcon,
    Close,
    Cancel,
    ArrowLeft,
    ArrowRight,
    ArrowDown,
    ArrowUp,
    BarChart,
    Add,
    Chat,
    Delete,
    Copy,
    Home,
    Grill,
    Star,
    StarBorder,
    InsertChart,
    Inbox,
    ExpandMore,
    Fullscreen,
    FullscreenExit,
    ExpandLess,
    Menu,
    ChevronRight,
    Minimize,
    SmartPhone,
    ChevronLeft,
    Loop,
    PanTool,
    Link,
    Logout,
    People,
    RecentActors,
    Feedback,
    Apartment,
    Settings,
    Face,
    EMail,
    Label,
    Bookmark,
    Bookmarks,
    Phone,
    LinkedIn,
    Photo,
    Info,
    ThumbsUpDown,
    ThumbUp,
    ThumbDown,
    Work,
    Download,
    Dashboard,
    History,
    Supervisor,
    VisibilityOff,
    Visibility,
    Refresh,
    Calendar,
    DateRange,
    Skill,
    BuildOutlined,
    MenuBook,
    AccountBox,
    Money,
    Print,
    Replay,
    Play,
    AppointmentAccept,
    AppointmentDecline,
    Done,
    Warning,
    Edit,
    Search,
    Archive,
    Remove,
    FindInPage,
    Suggestion,
    Cake,
    Flag,
    Notification,
    New,
    Dot,
    Public,
    Today,
    FolderShared,
    Checkbox,
    CheckboxOutlined,
    Assign,
    AddComment,
    Comment,
    PhoneDisabled,
    Signal,
    KeyboardArrowUp,
    Person,
    KeyboardArrowDown,
    TrendingUp,
    Check,
    DragHandle,
    Save,
    Launch,
    EmojiEvents,
    CloudOff,
    CloudDownload,
    AvTimer,
    List,
    Tree,
    Keyboard,
    KeyboardHide,
    CheckCircle,
    RadioButtonUnchecked,
    Send,
    TrendingDown,
    TrendingFlat,
    LocalLibrary,
    TrackChanges,
    Policy,
    LocationOn,
    Wc,
    EmojiEmotions,
    EmojiEmotionsOutlined,
    Group,
    Computer,
    FormatListNumbered,
    ContactSupport
}

export class IconFactory {
    static getIconComponentUnstyled = (icon: EIcons) => {
        return this.getIconComponent(icon, {});
    }

    static getIconComponent = (icon: EIcons, style?: object) => {
        if (!style) {
            style = { color: 'var(--brainz-color-text-secondary)', width: "1em", height: "1em" };
        }
        switch (icon) {
            case EIcons.ContactSupport:
                return <ContactSupportIcon style={style} />;
            case EIcons.Computer:
                return <ComputerIcon style={style} />;
            case EIcons.FormatListNumbered:
                return <FormatListNumberedIcon style={style} />;
            case EIcons.ScreenShare:
                return <ScreenShareIcon style={style} />;
            case EIcons.StopScreenShare:
                return <StopScreenShareIcon style={style} />;
            case EIcons.SmartPhone:
                return <SmartphoneIcon style={style} />;
            case EIcons.EmojiEmotions:
                return <EmojiEmotionsIcon style={style} />;
            case EIcons.EmojiEmotionsOutlined:
                return <EmojiEmotionsOutlinedIcon style={style} />;
            case EIcons.Group:
                return <GroupIcon style={style} />;
            case EIcons.Wc:
                return <WcIcon style={style} />;
            case EIcons.LinkedIn:
                return <LinkedInIcon style={style} />;
            case EIcons.LocationOn:
                return <LocationOnIcon style={style} />;
            case EIcons.Policy:
                return <PolicyIcon style={style} />;
            case EIcons.Fullscreen:
                return <FullscreenIcon style={style} />;
            case EIcons.FullscreenExit:
                return <FullscreenExitIcon style={style} />;
            case EIcons.LocalLibrary:
                return <LocalLibraryIcon style={style} />;
            case EIcons.InsertChart:
                return <InsertChartIcon style={style} />;
            case EIcons.BarChart:
                return <BarChartIcon style={style} />;
            case EIcons.Sort:
                return <SortIcon style={style} />;
            case EIcons.SaveAlt:
                return <SaveAltIcon style={style} />;
            case EIcons.AddIcCall:
                return <AddIcCallIcon style={style} />;
            case EIcons.EmojiPeople:
                return <EmojiPeopleIcon style={style} />;
            case EIcons.Share:
                return <ShareIcon style={style} />;
            case EIcons.TextFields:
                return <TextFieldsIcon style={style} />;
            case EIcons.RecentActors:
                return <RecentActorsIcon style={style} />;
            case EIcons.FiberManualRecord:
                return <FiberManualRecordIcon style={style} />;
            case EIcons.DriveEta:
                return <DriveEtaIcon style={style} />;
            case EIcons.FolderShared:
                return <FolderSharedIcon style={style} />;
            case EIcons.Attachment:
                return <AttachmentIcon style={style} />;
            case EIcons.Send:
                return <SendIcon style={style} />;
            case EIcons.RadioButtonUnchecked:
                return <RadioButtonUncheckedIcon style={style} />;
            case EIcons.Feedback:
                return <FeedbackIcon style={style} />;
            case EIcons.CheckCircle:
                return <CheckCircleIcon style={style} />;
            case EIcons.KeyboardHide:
                return <KeyboardHideIcon style={style} />;
            case EIcons.Keyboard:
                return <KeyboardIcon style={style} />;
            case EIcons.List:
                return <ListIcon style={style} />;
            case EIcons.Tree:
                return <TreeIcon style={style} />;
            case EIcons.Bookmark:
                return <BookmarkIcon style={style} />;
            case EIcons.Bookmarks:
                return <BookmarksIcon style={style} />;
            case EIcons.Label:
                return <LabelIcon style={style} />;
            case EIcons.AvTimer:
                return <AvTimerIcon style={style} />;
            case EIcons.CloudOff:
                return <CloudOffIcon style={style} />;
            case EIcons.CloudDownload:
                return <CloudDownloadIcon style={style} />;
            case EIcons.EmojiEvents:
                return <EmojiEventsIcon style={style} />;
            case EIcons.VisibilityOff:
                return <VisibilityOffIcon style={style} />;
            case EIcons.Visibility:
                return <VisibilityIcon style={style} />;
            case EIcons.Launch:
                return <LaunchIcon style={style} />;
            case EIcons.Print:
                return <PrintIcon style={style} />;
            case EIcons.Save:
                return <SaveIcon style={style} />;
            case EIcons.DragHandle:
                return <DragHandleIcon style={style} />;
            case EIcons.Link:
                return <LinkIcon style={style} />;
            case EIcons.Check:
                return <CheckIcon style={style} />;
            case EIcons.TrendingUp:
                return <TrendingUpIcon style={style} />;
            case EIcons.TrendingFlat:
                return <TrendingFlatIcon style={style} />;
            case EIcons.TrendingDown:
                return <TrendingDownIcon style={style} />;
            case EIcons.KeyboardArrowUp:
                return <KeyboardArrowUpIcon style={style} />;
            case EIcons.KeyboardArrowDown:
                return <KeyboardArrowDownIcon style={style} />;
            case EIcons.PhoneDisabled:
                return <PhoneDisabledIcon style={style} />;
            case EIcons.Signal:
                return <SignalCellular4BarIcon style={style} />;
            case EIcons.Logout:
                return <LogoutIcon style={style} />;
            case EIcons.AddComment:
                return <AddCommentIcon style={style} />;
            case EIcons.Comment:
                return <CommentIcon style={style} />;
            case EIcons.Assign:
                return <AssignmentTurnedInIcon style={style} />;
            case EIcons.Checkbox:
                return <CheckBoxIcon style={style} />;
            case EIcons.CheckboxOutlined:
                return <CheckBoxOutlineBlankIcon style={style} />;
            case EIcons.Dot:
                return <FiberManualRecordIcon style={style} />;
            case EIcons.Today:
                return <TodayIcon style={style} />;
            case EIcons.Public:
                return <PublicIcon style={style} />;
            case EIcons.Filter:
                return <FilterListIcon style={style} />;
            case EIcons.MoreIcon:
                return <MoreHorizIcon style={style} />;
            case EIcons.Copy:
                return <CopyIcon style={style} />;
            case EIcons.Close:
                return <CloseIcon style={style} />;
            case EIcons.Cancel:
                return <CancelIcon style={style} />;
            case EIcons.Minimize:
                return <MinimizeIcon style={style} />;
            case EIcons.ArrowDown:
                return <ArrowDropDownIcon style={style} />;
            case EIcons.ArrowLeft:
                return <ArrowLeftIcon style={style} />;
            case EIcons.ArrowRight:
                return <ArrowRightIcon style={style} />;
            case EIcons.ArrowUp:
                return <ArrowDropUpIcon style={style} />;
            case EIcons.AddBox:
                return <AddBoxIcon style={style} />;
            case EIcons.Add:
                return <AddIcon style={style} />;
            case EIcons.Chat:
                return <ChatIcon style={style} />;
            case EIcons.Delete:
                return <DeleteIcon style={style} />;
            case EIcons.Home:
                return <HomeIcon style={style} />;
            case EIcons.Grill:
                return <OutdoorGrillIcon style={style} />;
            case EIcons.Star:
                return <StarIcon style={style} />;
            case EIcons.StarBorder:
                return <StarBorderIcon style={style} />;
            case EIcons.Inbox:
                return <InboxIcon style={style} />;
            case EIcons.ExpandLess:
                return <ExpandLess style={style} />;
            case EIcons.ExpandMore:
                return <ExpandMore style={style} />;
            case EIcons.Menu:
                return <MenuIcon style={style} />;
            case EIcons.ChevronLeft:
                return <ChevronLeftIcon style={style} />;
            case EIcons.ChevronRight:
                return <ChevronRightIcon style={style} />;
            case EIcons.Loop:
                return <LoopIcon style={style} />;
            case EIcons.PanTool:
                return <PanToolIcon style={style} />;
            case EIcons.People:
                return <PeopleAltIcon style={style} />;
            case EIcons.Person:
                return <PersonIcon style={style} />;
            case EIcons.Apartment:
                return <ApartmentIcon style={style} />;
            case EIcons.Settings:
                return <SettingsIcon style={style} />;
            case EIcons.Face:
                return <FaceIcon style={style} />;
            case EIcons.EMail:
                return <EmailIcon style={style} />;
            case EIcons.Photo:
                return <PhotoIcon style={style} />;
            case EIcons.Phone:
                return <PhoneIcon style={style} />;
            case EIcons.Info:
                return <InfoOutlinedIcon style={style} />;
            case EIcons.ThumbsUpDown:
                return <ThumbsUpDownIcon style={style} />;
            case EIcons.Work:
                return <WorkIcon style={style} />;
            case EIcons.Download:
                return <CloudDownloadIcon style={style} />;
            case EIcons.Dashboard:
                return <DashboardIcon style={style} />;
            case EIcons.History:
                return <HistoryIcon style={style} />;
            case EIcons.Supervisor:
                return <SuperVisorIcon style={style} />;
            case EIcons.Refresh:
                return <RefreshIcon style={style} />;
            case EIcons.DateRange:
                return <DateRangeIcon style={style} />;
            case EIcons.Calendar:
                return <CalendarIcon style={style} />;
            case EIcons.Skill:
                return <ToolIcon style={style} />;
            case EIcons.BuildOutlined:
                return <BuildOutlinedIcon style={style} />;
            case EIcons.MenuBook:
                return <MenuBookIcon style={style} />;
            case EIcons.Money:
                return <EuroIcon style={style} />;
            case EIcons.Replay:
                return <ReplayIcon style={style} />;
            case EIcons.ThumbUp:
                return <ThumbUpIcon style={style} />;
            case EIcons.ThumbDown:
                return <ThumbDownIcon style={style} />;
            case EIcons.Play:
                return <PlayArrowIcon style={style} />;
            case EIcons.AppointmentAccept:
                return <EventAvailableIcon style={style} />;
            case EIcons.AppointmentDecline:
                return <EventBusyIcon style={style} />;
            case EIcons.Done:
                return <DoneIcon style={style} />;
            case EIcons.Warning:
                return <WarningIcon style={style} />;
            case EIcons.Edit:
                return <EditIcon style={style} />;
            case EIcons.Archive:
                return <ArchiveIcon style={style} />;
            case EIcons.Pageview:
                return <PageviewIcon style={style} />;
            case EIcons.Search:
                return <SearchIcon style={style}/>;
            case EIcons.Remove:
                return <RemoveIcon style={style} />;
            case EIcons.FindInPage:
                return <FindInPageIcon style={style} />;
            case EIcons.Suggestion:
                return <AssignmentIndIcon style={style} />;
            case EIcons.Cake:
                return <CakeIcon style={style} />;
            case EIcons.Flag:
                return <FlagIcon style={style} />;
            case EIcons.Notification:
                return <NotificationsIcon style={style} />;
            case EIcons.New:
                return <FiberNewIcon style={style} />;
            case EIcons.AccountBox:
                return <AccountBox style={style} />;
            case EIcons.TrackChanges:
                return <TrackChangesIcon style={style} />;

            default:
                return <>MISSING ICON: {icon}</>
                //throw Error(`The icon with enum id: ${icon} is not configured.`);
        }
    };
}
