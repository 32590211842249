import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ESuggestionStatus } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ESuggestionStatus;
}

const SuggestionStatusDropDown: React.FC<IProps> = (props) => {
    const statusValues: ESuggestionStatus[] = [
        ESuggestionStatus.OPEN,
        ESuggestionStatus.DECLINED,
        ESuggestionStatus.ACCEPTED
    ];
    let menuItems = statusValues.map((status: ESuggestionStatus, index: number) => (
        <MenuItem key={index + 1} value={status}>
            {translate('pages.recruiting.suggestionStatusValues.' + status)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            id={"suggestion-status-dropdown"}
            label={translate('misc.status')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default SuggestionStatusDropDown;
