import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { setUiCompanyContactToEditAction } from '../../../redux/ui/ui.actions';
import CompanyContactForm from './CompanyContactForm';
import { resetFormValidation } from '../../../redux/error/error.actions';
import { getCompanyContactAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';

const CompanyContactEditView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const editCompanyContact = useSelector((store: IStore) => Boolean(store.ui.editCompanyContactId));
    const companyContactId = useSelector((store: IStore) => store.ui.editCompanyContactId);
    const companyContact = useSelector((store: IStore) => store.entities.companyContacts.byId[store.ui.editCompanyContactId])

    const onClose = () => {
        dispatch(setUiCompanyContactToEditAction(0));
        dispatch(resetFormValidation());
    }

    useEffect(() => {
        if (editCompanyContact && !companyContact) {
            dispatch(getCompanyContactAction(token, companyContactId))
        }
    }, [editCompanyContact, companyContact])

    if (!editCompanyContact || !companyContact) {
        return null;
    }

    return (
        <CustomDialog fullWidth maxWidth={"md"} onClose={onClose} open={true}>
            <CustomDialogHeader translationString={"pages.companyContact.edit"} onClose={onClose} />
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <CompanyContactForm onClose={onClose} />
            </div>
        </CustomDialog>
    );
}
export default CompanyContactEditView;

