import React, { useState } from 'react';
import PaperRow from '../../../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyDocumentAction } from '../../../../../redux/entities/entities.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import { ICompanyDocument, ICompanyDocumentForApi } from '../../../../../definitions/entities.definition';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import CompanyDocumentTypeDropDown
    from '../../../../../components/CustomInput/CustomDropDownInput/CompanyDocumentTypeDropDown';


interface IProps {
    onClose: () => void;
    initialDocument: ICompanyDocument;
}

const CompanyDocumentForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const companyId = useSelector((store: IStore) => store.companyPage.companyToEdit);
    const [displayFileName, setDisplayFileName] = useState(props.initialDocument.displayFileName);
    const [type, setType] = useState(props.initialDocument.type);

    const onSave = () => {
        const document: ICompanyDocumentForApi = {
            id: props.initialDocument.id,
            displayFileName,
            companyId,
            type,
        };

        dispatch(updateCompanyDocumentAction(token, document));
        props.onClose();
        return;
    };

    return (
        <>
            <PaperRow>
                <CustomTextField
                    label={translate('pages.companyDocument.properties.displayFileName')}
                    value={displayFileName}
                    onChange={(value) => setDisplayFileName(value)}
                />

                <CompanyDocumentTypeDropDown value={type} onChange={(event) => setType(event.target.value)} />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </>
    );
};

export default CompanyDocumentForm;
