import React from 'react';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import { ICustomerInquiry } from '../../../../definitions/entities.customer.definitions';
import { Divider, Link } from '@material-ui/core';
import { getFormattedDateString } from '../../../../utils/date.utils';
import Nl2brCellRenderer from '../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { setTodoToEditAction } from '../../../../redux/todo/todo.actions';
import { useDispatch } from 'react-redux';

interface IProps {
    inquiries: ICustomerInquiry[];
    open: boolean;
    onClose: () => void;
}

const DisplayInquiriesView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const open = props.open;
    const inquiries = props.inquiries;

    const onClose = () => {
        props.onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomDialogHeader string={"Offene Rückfragen an Peagle"} onClose={onClose} />
            <CustomDialogBody>
                <div className={"flexContainerColumn paddingLeft10"}>
                    {inquiries.map((inquiry) => {
                        return <>
                            <div className={'formLabelSmall marginTop5'}>
                                Rückfrage vom {getFormattedDateString(inquiry.date)}
                                {inquiry.id && <>&nbsp; <Link onClick={() => dispatch(setTodoToEditAction(inquiry.id as number))}>[öffnen]</Link></>}
                                <br/>
                            </div>
                            <div className={'formContent'}>
                                <Nl2brCellRenderer value={inquiry.inquiry} />
                            </div>
                            <Divider orientation={"horizontal"} style={{width:'100%'}}/>
                        </>
                    })}
                </div>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
            />
        </CustomDialog>
    );
};

export default DisplayInquiriesView;
