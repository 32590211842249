import React, { useEffect, useState } from 'react';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    setBillingPageAddPaymentForInvoiceAction,
    setBillingPageReloadInvoiceListAction,
} from '../../../../redux/billingPage/billingPage.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { translate } from '../../../../translation/translate.utils';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { ClientApi } from '../../../../requests/ClientApi';
import { postPaymentRouteConfig } from '../../../../requests/routes';
import { getToken } from '../../../../selectors/app.selectors';

const AddPaymentDialog: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => Boolean(store.billingPage.addPaymentForInvoiceId));
    const invoice = useSelector((store: IStore) => store.entities.invoice.byId[store.billingPage.addPaymentForInvoiceId]);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if (invoice) {
            setAmount(invoice.openToPay);
        }
    }, [invoice]);

    const onClose = () => {
        dispatch(setBillingPageAddPaymentForInvoiceAction(0));
    }

    const save = () => {
        ClientApi.request(postPaymentRouteConfig, {
            token,
            invoiceId: invoice.id,
            sum: amount
        }).then((result) => {
            dispatch(setBillingPageReloadInvoiceListAction(true));
            onClose();
        });
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogHeader translationString="pages.billing.addPayment" onClose={onClose} />
            <PaperRow>
                <CustomTextField
                    label={'Summe'}
                    type={"number"}
                    value={amount}
                    onChange={(value) => setAmount(value)}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => save(),
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </Dialog>
    );
}

export default AddPaymentDialog;
