import React from 'react';
import { EIcons, IconFactory } from '../../../Icons/IconFactory';
import { ListItemIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

interface IProps {
    icon: EIcons;
    active: boolean;
}

const CustomFilterItemIcon: React.FC<IProps> = (props) => {
    const active = props.active;
    const icon = props.icon;
    const theme = useTheme();

    const getIcon = () => {
        if (active) {
            return IconFactory.getIconComponent(icon, {color: theme.palette.primary.main});
        }

        return IconFactory.getIconComponent(icon, {color: 'var(--brainz-most-darkest)'});
    };

    return <>
        <ListItemIcon>
            {getIcon()}
        </ListItemIcon>
    </>
}

export default React.memo(CustomFilterItemIcon);
