import React from 'react';
import { useVacancyRecruitingListHook } from './VacancyRecruitingList.hooks';
import { getRecruitingsForVacancyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { getVacancyPageRequestObjectForRecruitingsInEditView } from '../../../../../selectors/recruiting.selectors';
import RecruitingList from '../../../../Shared/RecruitingList/RecruitingList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    vacancyId: number;
}

const VacancyRecruitingList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingRequestObject = useSelector(getVacancyPageRequestObjectForRecruitingsInEditView);
    const vacancyId = props.vacancyId;

    const { loading, recruitings, total} = useVacancyRecruitingListHook(vacancyId);

    return <>
        <RecruitingList
            recruitings={recruitings}
            total={total}
            loading={loading}
            parentView={EEntityView.vacancy}
            onReload={() => {
                dispatch(getRecruitingsForVacancyAction(token, vacancyId, recruitingRequestObject));
            }}
        />
    </>
}

export default VacancyRecruitingList;
