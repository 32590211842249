import React, { useCallback, useEffect, useState } from 'react';
import { ISkill, ISkillPartial, ISkillTreeNode } from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { ClientApi } from '../../../requests/ClientApi';
import { getSkillTreeRouteConfig } from '../../../requests/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDumbTextField from '../../../components/CustomInput/CustomDumbTextField';
import { debounce } from '@material-ui/core';
import { getIdsFromTree, transformTreeNodeToSkill } from '../../../utils/skills.utils';
import SkillTreeView from './SkillTreeView';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';

interface IProps {
    open: boolean;
    value?: ISkill|ISkillPartial;
    onChange: (skill?: ISkill|ISkillPartial) => void;
    onClose: () => void;
}

const SelectSkillView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [fastSearch, setFastSearch] = useState('');
    const [treeNodes, setTreeNodes] = useState<ISkillTreeNode[]>([]);
    const [expandedTreeNodes, setExpandedTreeNodes] = useState<string[]>([]);
    const [selectedSkill, setSelectedSkill] = useState(props.value);

    const load = () => {
        setLoading(true);
        ClientApi.request(getSkillTreeRouteConfig, {
            token,
            filterFastSearch: fastSearch
        }).then((result: {tree: ISkillTreeNode[]}) => {
            setLoading(false);
            setTreeNodes(result.tree);
        });
    }

    useEffect(() => {
        if (fastSearch.length >= 3) {
            setExpandedTreeNodes(getIdsFromTree(treeNodes));
            return;
        }
        setExpandedTreeNodes([]);
    }, [treeNodes])

    useEffect(() => {
        if (props.open) {
            load()
        }
    }, [dispatch, props.open, fastSearch]);

    const changeHandler = (callable: () => void) => {
        callable();
    }

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const onClose = () => {
        props.onClose();
        setFastSearch('');
    }

    return (
        <CustomDialog fullWidth open={props.open} onClose={onClose} maxWidth={"sm"}>
            <CustomBackdropLoading open={loading} />
            <CustomDialogHeader translationString={'misc.selectSkills'} onClose={onClose} />
            <PaperRow>
                <CustomDumbTextField
                    label={translate('misc.fastSearch')}
                    style={{flex: 1}}
                    onChange={(event) => {
                        debouncedChangeHandler(() => setFastSearch(event.target.value));
                    }}
                />
            </PaperRow>
            <SkillTreeView
                initialExpanded={expandedTreeNodes}
                treeNodes={treeNodes}
                onSelect={(skillNode) => setSelectedSkill(transformTreeNodeToSkill(skillNode))}
            />
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => {
                        props.onChange(selectedSkill);
                        onClose();
                    },
                    color: 'secondary',
                    label: translate('misc.useSkill'),
                }]}
            />
        </CustomDialog>
    );
}

export default SelectSkillView
