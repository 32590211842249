import React from 'react';
import { EEntities, IJobTitle } from '../../../definitions/entities.definition';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';

interface IProps {
    jobTitles: IJobTitle[];
    setSelectedJobTitles: (jobTitles: IJobTitle[]) => void;
    removeJobTitle: (jobTitle: IJobTitle) => void;
}

const SelectJobTitleViewSelectedList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    dragAndDrop: true,
                    entity: EEntities.JobTitleSelectSelected,
                    columnConfig: {
                        title: {
                            header: 'Job-Titel',
                            property: 'title'
                        },
                        remove: {
                            header: IconFactory.getIconComponent(EIcons.Delete),
                            width: 40,
                            property: 'id',
                            cellRenderer: (value: number, entity) => {
                                const jobTitle = entity as IJobTitle;
                                return <CustomClickableIcon onClick={() => props.removeJobTitle(jobTitle)} icon={EIcons.Delete} />
                            }
                        }
                    },
                    count: props.jobTitles.length,
                    sortedEntries: props.jobTitles,
                }}
            />
        </>
    );
}

export default SelectJobTitleViewSelectedList;
