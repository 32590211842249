import React from 'react';
import { withRouter } from 'react-router';
import { ERoutes } from '../../definitions/app.definitions';
import { connect } from 'react-redux';
import { setUiCurrentRouteAction } from '../../redux/ui/ui.actions';

class NavigationControllerCustomer extends React.PureComponent<any> {
    componentDidMount() {
        this.props.history.push(ERoutes.Recruitings);
        this.props.setUiCurrentRoute(ERoutes.Recruitings);
    }

    render() {
        return null;
    }
}

const mapDispatch = {
    setUiCurrentRoute: setUiCurrentRouteAction,
};
const connected = connect(null, mapDispatch)(withRouter(NavigationControllerCustomer));
export default connected;
