import { ICandidateForApi, ICandidateListModel } from '../../definitions/entities.definition';
import React from 'react';
import { Avatar, Tooltip } from '@material-ui/core';
import { API_URL } from '../../requests/routes';
import { translate } from '../../translation/translate.utils';
import { Rating } from '@material-ui/lab';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import CandidateProfilesRenderer from './CandidateProfilesRenderer';
import { renderCandidateColored } from '../../utils/candidateTable.utils';
import { getFormattedDateString } from '../../utils/date.utils';
import ContractsCountCellRenderer from './ContractsCountCellRenderer';
import { COLOR_RED } from '../../theme/theme';

interface IProps {
    token: string;
    candidate: ICandidateListModel;
    showDetails?: boolean;
    updateCandidate?: (candidate: Partial<ICandidateForApi>) => void,
}

const CandidateInformationColumnCellRenderer: React.FC<IProps> = (props) => {
    const candidate = props.candidate;
    const updateCandidate = props.updateCandidate;
    const showDetails = props.showDetails || false;
    const token = props.token;

    return <>
        {candidate.candidateProfiles.photoId
            ?
            <div className={'flexContainerColumn paddingRight5'} style={{maxHeight: 60, marginTop: 10}}>
                <Avatar
                    style={{width: 50, height: 50}}
                    src={API_URL + "/candidate-image/" + candidate.candidateProfiles.photoId + "/preview?Authorization=" + token}
                />
            </div>
            :
            <div className={'flexContainerColumn paddingRight5'} style={{maxHeight: 60, marginTop: 10}}>
                <Avatar style={{width: 50, height: 50}}/>
            </div>
        }
        <div className={'flexContainerColumn'}>
            <div style={{paddingLeft: 0}}>
                {renderCandidateColored(candidate, translate('misc.' + candidate.salutation) + ' ' + candidate.fullName)}
                {showDetails && (candidate.zipCode || candidate.city || candidate.address) &&
                    <div className={'formContent'}>{candidate.zipCode} {candidate.city}, {candidate.address}</div>
                }
                {showDetails && candidate.birthDate &&
                    <div className={'formContent'}>Geboren am: {getFormattedDateString(candidate.birthDate)}</div>
                }
                {candidate.currentJobDescription &&
                    <div className={'formContent'}>{candidate.currentJobDescription}</div>
                }
            </div>
            <div className={'flex flexCenterOnlyChildren gap5'}>
                <ContractsCountCellRenderer count={candidate.totalContracts} candidateId={candidate.id}/>
                {(!candidate.country || candidate.country.code !== 'DE') &&
                    <Tooltip title={'Nicht DE-Kandidat (' + (candidate.country ? candidate.country.name : 'nicht gesetzt')+ ')'}>
                        {IconFactory.getIconComponent(EIcons.Public)}
                    </Tooltip>
                }
                <Tooltip title={"Uninterssanter Kandidat"}>
                    <Rating
                        name={"unattractive" + candidate.id}
                        size={'large'}
                        value={candidate.unattractive ? 1 : 0}
                        max={1}
                        disabled={updateCandidate === undefined}
                        icon={IconFactory.getIconComponent(EIcons.ThumbDown, {color: COLOR_RED, width: 28, height: 28})}
                        emptyIcon={IconFactory.getIconComponent(EIcons.ThumbDown, {color: 'rgba(0, 0, 0, 0.26)', width: 28, height: 28})}
                        onChange={() => {
                            if (!updateCandidate) {
                                return;
                            }
                            updateCandidate({
                                id: candidate.id,
                                unattractive: !candidate.unattractive,
                            });
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Tooltip>
                <Tooltip title={"TOP Kandidat"}>
                    <Rating
                        name={"topCandidate" + candidate.id}
                        size={'large'}
                        value={candidate.topCandidate ? 1 : 0}
                        max={1}
                        disabled={updateCandidate === undefined}
                        icon={IconFactory.getIconComponent(EIcons.Star, {color: '#ffb400', width: 28, height: 28})}
                        emptyIcon={IconFactory.getIconComponent(EIcons.Star, {color: 'rgba(0, 0, 0, 0.26)', width: 28, height: 28})}
                        onChange={() => {
                            if (!updateCandidate) {
                                return;
                            }
                            updateCandidate({
                                id: candidate.id,
                                topCandidate: !candidate.topCandidate,
                            });
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Gute Verbindung / Gute Kommunikation"}>
                    <Rating
                        name={"topInCommunication" + candidate.id}
                        size={'large'}
                        value={candidate.topInCommunication ? 1 : 0}
                        max={1}
                        disabled={updateCandidate === undefined}
                        icon={IconFactory.getIconComponent(EIcons.EmojiEmotions, {color: '#ffb400', width: 26, height: 26})}
                        emptyIcon={IconFactory.getIconComponent(EIcons.EmojiEmotions, {color: 'rgba(0, 0, 0, 0.26)', width: 26, height: 26})}
                        onChange={() => {
                            if (!updateCandidate) {
                                return;
                            }
                            updateCandidate({
                                id: candidate.id,
                                topInCommunication: !candidate.topInCommunication,
                            });
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Hohes Potential / Großes Talent"}>
                    <Rating
                        name={"topInPotential" + candidate.id}
                        size={'large'}
                        value={candidate.topInPotential ? 1 : 0}
                        max={1}
                        disabled={updateCandidate === undefined}
                        icon={IconFactory.getIconComponent(EIcons.TrendingUp, {color: '#ffb400', width: 26, height: 26})}
                        emptyIcon={IconFactory.getIconComponent(EIcons.TrendingUp, {color: 'rgba(0, 0, 0, 0.26)', width: 26, height: 26})}
                        onChange={() => {
                            if (!updateCandidate) {
                                return;
                            }
                            updateCandidate({
                                id: candidate.id,
                                topInPotential: !candidate.topInPotential,
                            });
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Tooltip>
            </div>
            <div className={'flex flexCenterOnlyChildren gap5'}>
                <CandidateProfilesRenderer profiles={candidate.candidateProfiles}/>
            </div>
        </div>
    </>;
}

export default React.memo(CandidateInformationColumnCellRenderer);
