import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
    ECandidateSource,
    EEntities,
    IActivity,
    ICandidate,
    IEmployee,
    ISuggestion,
    IVacancy,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { translate } from '../../../../translation/translate.utils';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import {
    getSuggestionsWithTopRatingsAction,
    updateSuggestionAction,
} from '../../../../redux/entities/entities.actions';
import { Rating } from '@material-ui/lab';
import EmployeeCellRenderer from '../../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { getToken } from '../../../../selectors/app.selectors';
import { getTopSuggestionsFromStore } from '../../../../selectors/recruitingSuggestion.selectors';
import { themeOrange } from '../../../../theme/theme';
import StarIcon from '@material-ui/icons/Star';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import Nl2brCellRenderer from '../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import UrlCellRenderer from '../../../../components/CustomCellRenderer/UrlCellRenderer';
import ActivityVacancyCellRenderer from '../../../Shared/Activity/ActivityVacancyCellRenderer';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import { setDashboardPageSortingOptionAction } from '../../../../redux/dashboardPage/dashboardPage.actions';
import { EDashboardPageTable } from '../../../../definitions/dashboardPage.definitions';
import { buildRequestObject } from '../../../../selectors/entity.selectors';
import { getSuggestionListActionButtons } from '../../../../utils/suggestion.utils';
import { getDefaultCompanyColumn, getDefaultLastActivityColumn } from '../../../../utils/components.utils';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EEntityView } from '../../../../definitions/ui.definitions';

const TopSuggestionsTable: React.FC = () => {
    const dispatch = useDispatch();
    const sortedEntries = useSelector(getTopSuggestionsFromStore);
    const loading = useSelector((store: IStore) => store.ui.topSuggestionsLoading);
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingTopSuggestions);

    return (
        <CustomTable
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.TopSuggestions,
                loading,
                columnConfig: {
                    rating: {
                        header: translate('pages.recruiting.rating'),
                        property: "rating",
                        width: 120,
                        cellRenderer: (rating: number, suggestion: TBrainzEntity) => {
                            const suggestionEntity = suggestion as ISuggestion;

                            return <Rating
                                size={"large"}
                                value={rating/2}
                                max={3}
                                icon={<StarIcon style={{ width: "1.2em", height: "1.2em", pointerEvents: "auto" }} />}
                                precision={0.5}
                                onChange={(event, value) => {
                                    if (value) {
                                        dispatch(updateSuggestionAction(token, {
                                            id: suggestionEntity.id,
                                            rating: value * 2
                                        }));
                                    }
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            />;
                        }
                    },
                    company: getDefaultCompanyColumn(),
                    vacancy: {
                        header: translate('pages.activity.properties.vacancy'),
                        property: "vacancy",
                        flex: 1,
                        cellRenderer: (vacancy: null | IVacancy, activity: TBrainzEntity) => {
                            return <ActivityVacancyCellRenderer activity={activity as IActivity} />
                        },
                    },
                    candidate: {
                        header: translate('pages.activity.properties.candidate'),
                        property: "candidate",
                        width: 220,
                        cellRenderer: (candidate: ICandidate, suggestion: TBrainzEntity) => {
                            const suggestionCast = suggestion as ISuggestion;
                            if (candidate) {
                                return <CandidateCellRenderer candidate={candidate} />;
                            }

                            return (
                                <>
                                    { suggestionCast.fullName }
                                    { suggestionCast.source === ECandidateSource.jobcenter && suggestionCast.externalCandidateReference && '(' + suggestionCast.externalCandidateReference + ')' }
                                    <br/>
                                    { translate('misc.source') + ': ' + translate('pages.vacancy.externalCandidateSourceValues.' + suggestionCast.source)}<br/>
                                </>
                            );
                        },
                    },
                    phoneNumber: {
                        header: translate('misc.mobileNumber'),
                        property: "phoneNumber",
                        flex: 1,
                        cellRenderer: (phoneNumber: string, suggestion: TBrainzEntity) => {
                            const suggestionCast = suggestion as ISuggestion;

                            return suggestionCast.phoneNumber
                        },
                    },
                    homepageUrl: {
                        header: translate('misc.url'),
                        property: "externalCandidateUrl",
                        width: 80,
                        cellRenderer: (url: string, entity: TBrainzEntity) => {
                            const suggestionCast = entity as ISuggestion;
                            if (!suggestionCast.candidate && suggestionCast.source === ECandidateSource.jobcenter) {
                                return <UrlCellRenderer value={"Öffnen"} url={`https://www.arbeitsagentur.de/suche?was=${suggestionCast.externalCandidateReference}&angebotsart=1`} />
                            }
                            if (url) {
                                return <UrlCellRenderer value={"Öffnen"} url={url} />
                            }

                            return null;
                        },
                    },
                    suggestedByEmployee: {
                        header: translate('pages.vacancy.suggestedByEmployee'),
                        property: "suggestedByEmployee",
                        width: 160,
                        cellRenderer: (employee: IEmployee) => {
                            return <EmployeeCellRenderer employee={employee} />;
                        },
                    },
                    callAppointmentAt: {
                        header: translate('pages.recruiting.suggestionAppointment'),
                        property: "callAppointmentAt",
                        width: 200,
                        cellRenderer: (callAppointmentAt?: string) => {
                            if (callAppointmentAt) {
                                return <DateTimeCellRenderer date={callAppointmentAt} />;
                            }

                            return translate('misc.noInformation');
                        },
                    },
                    lastActivity: getDefaultLastActivityColumn(),
                },
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                        dispatch(setDashboardPageSortingOptionAction(
                            EDashboardPageTable.topFavorites,
                            propertyToSort,
                            sortOption
                        ));
                        return;
                    }
                },
                sortedEntries: sortedEntries,
                onRowDoubleClick: (suggestionId: number) => {
                    dispatch(setUiEditView(EEntityView.suggestion, suggestionId));
                },
                listActionButtons: getSuggestionListActionButtons(),
                count: sortedEntries.length,
                total: sortedEntries.length,
                onReload: () => {
                    dispatch(getSuggestionsWithTopRatingsAction(
                        token,
                        loggedInEmployee.id,
                        buildRequestObject(sorting)
                    ));
                }
            }}
        />
    );
};

export default TopSuggestionsTable;

