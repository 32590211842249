import React from 'react';
import { withRouter } from 'react-router';
import { ERoutes } from '../../definitions/app.definitions';
import { connect } from 'react-redux';
import { setUiCurrentRouteAction } from '../../redux/ui/ui.actions';

class NavigationController extends React.PureComponent<any> {
    componentDidMount() {
        if (this.props.history.location.pathname === '' || this.props.history.location.pathname === '/') {
            this.props.history.push(ERoutes.Dashboard);
            this.props.setUiCurrentRoute(ERoutes.Dashboard);
            return
        }

        this.props.history.push(this.props.history.location.pathname);
        this.props.setUiCurrentRoute(this.props.history.location.pathname);
    }

    render() {
        return null;
    }
}

const mapDispatch = {
    setUiCurrentRoute: setUiCurrentRouteAction,
};
const connected = connect(null, mapDispatch)(withRouter(NavigationController));
export default connected;
