import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomTypography from '../../components/CustomTypography/CustomTypography';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import React from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { Dialog } from '@material-ui/core';
import { setRecruitingPageCloseAssignCandidateToSuggestionViewAction } from '../../redux/recruitingPage/recruitingPage.actions';
import PaperRow from '../../components/CustomPaper/PaperRow';
import AssignCandidateToSuggestionTable from './components/AssignCandidateToSuggestionTable';
import { updateSuggestionAndReloadListAction } from '../../redux/entities/entities.actions';

const AssignCandidateToSuggestionView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.recruitingPage.assignCandidateToSuggestionData.recruitingSuggestionId));
    const recruitingSuggestionId = useSelector((store: IStore) => store.recruitingPage.assignCandidateToSuggestionData.recruitingSuggestionId);
    const candidates = useSelector((store: IStore) => store.recruitingPage.assignCandidateToSuggestionData.candidates)
    const selectedCandidate = useSelector((store: IStore) => store.recruitingPage.assignCandidateToSuggestionData.selectedCandidate);

    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    const onSave = () => {
        dispatch(updateSuggestionAndReloadListAction(token, {
            id: recruitingSuggestionId,
            candidateId: selectedCandidate?.id,
        }));
        onClose();
    }

    const onClose = () => {
        dispatch(setRecruitingPageCloseAssignCandidateToSuggestionViewAction());
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
                <CustomDialogHeader string={translate('pages.recruiting.assignCandidateDialog.title')} onClose={onClose} />
                <CustomDialogBody>
                    <CustomTypography translation={"pages.recruiting.assignCandidateDialog.body"} />
                    <PaperRow>
                        <AssignCandidateToSuggestionTable candidates={candidates}/>
                    </PaperRow>
                </CustomDialogBody>
                <CustomDialogActions
                    buttons={[{
                        void: onSave,
                        disabled: selectedCandidate === undefined,
                        label: translate('pages.recruiting.assignCandidateDialog.save')
                    }, {
                        void: onClose,
                        label: translate('pages.recruiting.assignCandidateDialog.abort')
                    }]}
                />
            </Dialog>
        </>
    );
};

export default AssignCandidateToSuggestionView;
