import React, { useEffect } from 'react';
import { Badge, Button } from '@material-ui/core';
import { EIcons, IconFactory } from '../../Icons/IconFactory';
import CustomDateRangePopper from './CustomDateRangePopper';
import { IDateRangeFilterConfig } from '../../../definitions/filter.definitions';
import styles from './CustomDateRange.module.css';

interface IProps {
    config: IDateRangeFilterConfig;
}

const CustomDateRangeInputButton: React.FC<IProps> = (props) => {
    const config = props.config;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenFilter = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            const key = e.key;
            const noInputOpen = document.getElementsByClassName('MuiAutocomplete-popper').length === 0;
            if (e.target && e.target.id === 'editableValue') {
                return;
            }
            if (key === 'Escape' && anchorEl && noInputOpen) {
                handleCloseFilter();
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);

        return (() => {
            document.removeEventListener('keydown', handleKeyDown);
        });
    }, [Boolean(anchorEl)]);

    return <>
        <Button
            style={{flex: 1}}
            variant="contained"
            color="secondary"
            onClick={handleOpenFilter}
            startIcon={IconFactory.getIconComponent(EIcons.Sort)}
        >
            Zeitraum
            {config.resetEnabled &&
                <Badge color="primary" badgeContent={"Aktiv"} className={styles.dateRangeBadge}/>
            }
        </Button>
        <CustomDateRangePopper
            removeAnchor={handleCloseFilter}
            anchorEl={anchorEl}
            config={config}
        />
    </>
}

export default React.memo(CustomDateRangeInputButton);
