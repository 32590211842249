import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import PaperRow from '../../components/CustomPaper/PaperRow';
import { Dialog } from '@material-ui/core';
import { setRecruitingPageAddSuggestionAction } from '../../redux/recruitingPage/recruitingPage.actions';
import AddSuggestionForCandidateForm from './components/AddSuggestionForCandidateForm';

const AddSuggestionForCandidateView: React.FC = () => {
    const open = useSelector((store: IStore) => store.recruitingPage.addSuggestionView.open);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setRecruitingPageAddSuggestionAction({
            open: false,
            candidateId: undefined,
            vacancyId: undefined
        }));
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} maxWidth={"xs"} fullWidth onClose={onClose}>
            <CustomDialogHeader translationString="pages.candidate.addCandidateAsFavorite" onClose={onClose} />
            <PaperRow>
                <AddSuggestionForCandidateForm onClose={onClose}/>
            </PaperRow>
        </Dialog>
    );
};

export default AddSuggestionForCandidateView;
