import React, { useEffect, useState } from 'react';
import VacancySuggestionPanel from './component/VacancySuggestionPanel';
import VacancyApplicationPanel from './component/VacancyApplicationPanel';
import { getVacancyAction, updateVacancyAction } from '../../../redux/entities/entities.actions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { themeOrange } from '../../../theme/theme';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import VacancyActivityChatPanel from './component/VacancyActivityChatPanel';
import VacancyRecruitingPanel from './component/VacancyRecruitingPanel';
import { ITab } from '../../../definitions/components.definitions';
import { getTabCountLabel } from '../../../utils/ui.utils';
import VacancyEditForm from './component/VacancyEditForm';
import CustomInfo from '../../../components/CustomComponent/CustomInfo';

interface IProps {
    id: number;
}

const VacancyView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.id;
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const [activeTab, setActiveTab] = useState(0);

    const applicationsCount = useSelector((store: IStore) => store.entities.applications.totalByVacancy[vacancyId]);
    const recruitingsCount = useSelector((store: IStore) => store.entities.recruiting.totalByVacancy[vacancyId]);
    const suggestionsCount = useSelector((store: IStore) => store.entities.recruitingSuggestion.totalByVacancy[vacancyId]);

    useEffect(() => {
        if (vacancyId) {
            dispatch(getVacancyAction(token, vacancyId, true));
        }
    }, [applicationsCount, recruitingsCount, suggestionsCount])

    const vacancyLoaded = vacancyId > 0 && vacancy !== undefined && vacancyId === vacancy.id;
    useEffect(() => {
        if (vacancyId > 0 && (!vacancy || vacancy.id !== vacancyId)) {
            dispatch(getVacancyAction(token, vacancyId, false));
        }
    }, [vacancyId]);

    if (vacancyId === 0) {
        return null;
    }

    if (!vacancyLoaded || !vacancy) {
        return <>
            <div className={"flexContainerRow flex"} style={{width: 700}}>
                <CustomBackdropLoadingInsideDiv open={true} />
            </div>
        </>;
    }

    const completedHint = vacancy.completed ? <div className={"formRowColumn flexAutoGrow"}>
        <CustomInfo
            buttonConfig={{
                text: 'Aktivieren',
                action: () => dispatch(updateVacancyAction(
                    token,
                    {
                        id: vacancy.id,
                        completed: false
                    }
                ))
            }}
        >Vakanz befindet sich im Archiv</CustomInfo>
    </div> : null;


    const tabs: ITab[] = [{
        label: <>Favoriten {getTabCountLabel(
            vacancy.countSuggestions,
            vacancy.countSuggestionsWithStatusOpen
        )}</>,
        component: <>
            {completedHint}
            <VacancySuggestionPanel vacancyId={vacancyId} archived={vacancy.completed}/>
        </>
    }, {
        label: <div className={"flexContainerRow"}>Prozesse {getTabCountLabel(
            vacancy.countRecruitings,
            vacancy.countRecruitingsWithStatusOpen,
            vacancy.countRecruitingsWithStatusContract
        )}</div>,
        component: <>
            {completedHint}
            <VacancyRecruitingPanel vacancyId={vacancyId}/>
        </>
    }, {
        label: 'Stammdaten',
        component: <>
            {completedHint}
            <VacancyEditForm vacancyId={vacancyId}  />
        </>
    }, {
        label: <>Bewerbungen {getTabCountLabel(
            vacancy.countApplications,
            vacancy.countApplicationsWithStatusOpen
        )}</>,
        component: <>
            {completedHint}
            <VacancyApplicationPanel vacancyId={vacancyId}/>
        </>
    }, {
        label: 'Historie',
        component: <>
            {completedHint}
            <VacancyActivityChatPanel vacancyId={vacancyId}/>
        </>
    }];

    const width = 900;

    return <>
        <div className={'flexContainerColumn flex padding5'} style={{width}}>
            <CustomTabContainer
                color={themeOrange.palette.primary.main}
                activeTab={activeTab}
                onTabChange={(tab) => {
                    setActiveTab(tab)
                }}
                verticalWidth={180}
                roundBorder
                tabs={tabs}
            />
        </div>
    </>;
};

export default React.memo(VacancyView);
