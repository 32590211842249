import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import { ISuggestionGroup, ISuggestionListModel } from '../definitions/entities.definition';
import _ from 'underscore';

export const groupSuggestionsByStatus = (suggestions: ISuggestionListModel[]): ISuggestionGroup => {
    return _.groupBy(suggestions, function (suggestion) {
        return suggestion.status;
    });
}

export const groupSuggestionsByProgressStatus = (suggestions: ISuggestionListModel[]): ISuggestionGroup => {
    return _.groupBy(suggestions, function (suggestion) {
        return suggestion.progressStatus;
    });
}

export const getSuggestionsForVacancyFromStore = (store: IStore, id: number) => {
    const suggestionsById = store.entities.recruitingSuggestion.listModelById;
    const suggestionIdsByVacancyId = store.entities.recruitingSuggestion.byVacancy[id] || [];

    const recruitingSuggestions: ISuggestionListModel[] = [];

    suggestionIdsByVacancyId.forEach((suggestionId) => {
        if (suggestionsById[suggestionId]) {
            recruitingSuggestions.push(suggestionsById[suggestionId])
        }
    });

    return recruitingSuggestions;
};

export const getSuggestionsForCandidateFromStore = (store: IStore, id: number) => {
    const suggestionsById = store.entities.recruitingSuggestion.listModelById;
    const suggestionIdsByVacancyId = store.entities.recruitingSuggestion.byCandidate[id] || [];

    const recruitingSuggestions: ISuggestionListModel[] = [];

    suggestionIdsByVacancyId.forEach((suggestionId) => {
        if (suggestionsById[suggestionId]) {
            recruitingSuggestions.push(suggestionsById[suggestionId])
        }
    });

    return recruitingSuggestions;
};

export const getSuggestionsForListViewFromStore = (store: IStore): ISuggestionListModel[]  => {
    const order = store.entities.recruitingSuggestion.orderListView;
    const suggestionsById = store.entities.recruitingSuggestion.listModelById;

    return order.reduce((accumulator: ISuggestionListModel[], suggestionId) => {
        accumulator.push(suggestionsById[suggestionId]);

        return accumulator;
    }, []);
};

export const getTopSuggestionsFromStore = (store: IStore): ISuggestionListModel[] => {
    const order = store.entities.recruitingSuggestion.orderTop;
    const suggestionsById = store.entities.recruitingSuggestion.listModelById;

    return order.reduce((accumulator: ISuggestionListModel[], suggestionId) => {
        accumulator.push(suggestionsById[suggestionId]);

        return accumulator;
    }, []);
};

export const getVacancyPageRequestObjectForSuggestionsInEditView = (store: IStore) => {
    const sorting = store.vacancyPage.suggestionOfVacancySorting;
    const fastSearch = store.vacancyPage.suggestionOfVacancyFilter.fastSearch;
    if (fastSearch !== undefined && fastSearch.trim() !== '') {
        return buildRequestObject(sorting, {
            fastSearch,
            start: 0,
            limit: 0
        });
    }

    return buildRequestObject(sorting, {
        ...store.vacancyPage.suggestionOfVacancyFilter,
        start: 0,
        limit: 0
    });
};

export const getVacancyPageRequestObjectForSuggestionsListView = (store: IStore, vacancyId: number) => {
    const sorting = store.vacancyPage.suggestionListViewSorting;
    const filter = store.vacancyPage.suggestionListViewFilter;
    filter.start = 0;
    filter.limit = 0;
    filter.vacancyId = vacancyId;

    return buildRequestObject(sorting, filter);
};

export const getRecruitingPageSuggestionListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.recruitingPage.suggestionSorting;
    const filter = store.recruitingPage.filterTabSuggestion;

    return buildRequestObject(sorting, filter);
};
