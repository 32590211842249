import React from 'react';
import { IRecruitingListModel } from '../../../definitions/entities.definition';
import { groupRecruitingsByStatus } from '../../../selectors/recruiting.selectors';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import { EEntityView } from '../../../definitions/ui.definitions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import RenderRecruitingListGroup from './RenderRecruitingListGroup';

interface IProps {
    total: number;
    recruitings: IRecruitingListModel[];
    loading: boolean;
    parentView: EEntityView;
    onReload: () => void;
}

const RecruitingList: React.FC<IProps> = (props) => {
    const total = props.total;
    const recruitings = props.recruitings;
    const recruitingGroups = groupRecruitingsByStatus(recruitings);
    const loading = props.loading;
    const parentView = props.parentView;

    return <>
        <div className={"flexColumn flex1"}>
            <div className={"overflow flex1 marginTop10 flexContainerColumn gap5"}>
                <CustomBackdropLoadingInsideDiv open={loading} />
                {total === 0 &&
                    <>Keine Daten vorhanden</>
                }
                {Object.keys(recruitingGroups).map((status) => {
                    const recruitings = recruitingGroups[status];
                    return <>
                        <RenderRecruitingListGroup
                            key={status}
                            status={status}
                            recruitings={recruitings}
                            parentView={parentView}
                        />
                    </>
                })}
            </div>
            <CustomTableFooter
                style={{height: 35}}
                text={'Prozesse: ' + total}
                onReload={props.onReload}
            />
        </div>
    </>
}

export default React.memo(RecruitingList);
