import React from 'react';
import { EEntities, IJobTitle } from '../../../definitions/entities.definition';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { IJobTitleFilter } from '../../../definitions/jobTemplatePage.definitions';

interface IProps {
    jobTitles: IJobTitle[];
    total: number;
    onReload: () => void;
    loading: boolean;
    addJobTitle: (jobTitle: IJobTitle) => void;
    filter: IJobTitleFilter;
    setFilter: (filter: IJobTitleFilter) => void;
}

const SelectJobTitleViewSearchList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    dragAndDrop: true,
                    loading: props.loading,
                    entity: EEntities.JobTitleSelectSearch,
                    columnConfig: {
                        title: {
                            header: 'Job-Titel',
                            property: 'title'
                        },
                        add: {
                            header: IconFactory.getIconComponent(EIcons.Add),
                            width: 40,
                            property: 'skill.id',
                            cellRenderer: (value: number, entity) => {
                                const jobTitle = entity as IJobTitle;
                                return <CustomClickableIcon onClick={() => props.addJobTitle(jobTitle)} icon={EIcons.Add} />
                            }
                        }
                    },
                    scrollCallback: () => {
                        if (props.filter.start !== undefined && props.filter.limit < props.total) {
                            props.setFilter({
                                ...props.filter,
                                start: 0,
                                limit: props.filter.limit + INFINITE_SCROLL_INTERVAL,
                            });
                        }
                    },
                    sortedEntries: props.jobTitles,
                    onReload: props.onReload,
                    count: props.jobTitles.length,
                    total: props.total
                }}
            />
        </>
    );
}

export default SelectJobTitleViewSearchList;
