import React from 'react';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    ECandidateSource,
    EEntities,
    ICandidateListModel,
    TBrainzEntity,
} from '../../../../definitions/entities.definition';
import {
    addSuggestionAction,
    getCandidateListOfVacancySearchAction,
    updateCandidateAction,
} from '../../../../redux/entities/entities.actions';
import {
    getAllCandidatesForVacancyFromStore,
    getVacancyCandidateSearchListRequestObject,
} from '../../../../selectors/candidate.selectors';
import {
    setVacancyPageCandidateSearchFilterAction,
    setVacancyPageCandidateSearchSortingOptionAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import { themeGreen } from '../../../../theme/theme';
import { getDefaultColumnConfigForCandidateTable } from '../../../../utils/candidateTable.utils';
import { useDispatch, useSelector } from 'react-redux';
import { INFINITE_SCROLL_INTERVAL } from '../../../../utils/components.utils';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getToken } from '../../../../selectors/app.selectors';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { EEntityView } from '../../../../definitions/ui.definitions';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';

interface IProps {
    vacancyId: number;
}

const SearchCandidateList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const vacancyId = props.vacancyId;
    const token = useSelector(getToken);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const candidates = useSelector(getAllCandidatesForVacancyFromStore);
    const total = useSelector((store: IStore) => store.entities.candidates.totalVacancySearch);
    const loading = useSelector((store: IStore) => store.ui.candidatesLoading);
    const filter = useSelector((store: IStore) => store.vacancyPage.filterCandidateList);
    const filterMenu = useSelector((store: IStore) => store.vacancyPage.filterMenuCandidateList);
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const requestObject = useSelector(getVacancyCandidateSearchListRequestObject);

    const columnConfig = getDefaultColumnConfigForCandidateTable(
        token,
        filter,
        (candidate) => {
            dispatch(updateCandidateAction(token, candidate));
        },
        true,
        filterMenu.optionalSkills,
        vacancyId
    )

    return (
        <CustomTable
            config={{
                denyVerticalCenter: true,
                color: themeGreen.palette.primary.main,
                entity: EEntities.VacancyCandidateSearch,
                loading,
                columnConfig: columnConfig,
                sortedEntries: candidates,
                onRowDoubleClick: (candidateId, entity) => {
                    const candidate = entity as ICandidateListModel;
                    dispatch(setUiEditView(EEntityView.candidate, candidate.id));
                },
                listActionButtons: [{
                    translationKey: "pages.vacancy.startRecruiting",
                    icon: EIcons.ThumbsUpDown,
                    action: (id, entity: TBrainzEntity) => {
                        const entityCast = entity as ICandidateListModel;

                        if (!vacancy) {
                            return;
                        }

                        dispatch(setRecruitingPageStartRecruitingAction({
                            open: true,
                            candidateId: entityCast.id,
                            vacancyId: vacancy.id
                        }))
                        return;
                    },
                }, {
                    action: (id, entity: TBrainzEntity) => {
                        const candidateListModel = entity as ICandidateListModel;

                        dispatch(addSuggestionAction(token, {
                            candidateId: candidateListModel.id,
                            source: ECandidateSource.brainz,
                            vacancyId: vacancyId,
                            suggestedByEmployeeId:loggedInEmployee.id,
                            responsibleEmployeeId: loggedInEmployee.id
                        }));
                    },
                    translationKey: "pages.vacancy.suggestCandidate",
                    icon: EIcons.Suggestion
                }],
                count: candidates.length,
                total: total,
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setVacancyPageCandidateSearchSortingOptionAction(propertyToSort, sortOption)),
                },
                scrollCallback: () => {
                    if (filter.start !== undefined && filter.limit < total) {
                        dispatch(setVacancyPageCandidateSearchFilterAction({
                            start: 0,
                            limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                        }));
                    }
                },
                onReload: () => {
                    dispatch(getCandidateListOfVacancySearchAction(
                        token,
                        requestObject
                    ));
                }
            }}
        />
    )
};

export default SearchCandidateList;
