import {
    ERecruitingPageTabs,
    EVacancyEditViewTabs,
    IRecruitingFilter,
    IRecruitingFilterMenu,
    ISuggestionFilter,
    IStartRecruitingViewState, IAddSuggestionViewState,
} from '../../definitions/recruitingPage.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';
import {
    ICloseRecruitingPageChangeRecruitingResponsibleWindowAction,
    ISetRecruitingPageActiveTabAction, ISetRecruitingPageAddSuggestionAction,
    ISetRecruitingPageAssignCandidateToSuggestionViewClosedAction,
    ISetRecruitingPageCloseRecruitingListViewAction,
    ISetRecruitingPageCreateCongratulationMailAction,
    ISetRecruitingPageDocumentSortingOptionAction,
    ISetRecruitingPageDocumentToDeleteAction,
    ISetRecruitingPageDocumentToEditAction,
    ISetRecruitingPageEditViewActivityListFilterAction,
    ISetRecruitingPageFilterAction,
    ISetRecruitingPageRecruitingListViewFilterAction,
    ISetRecruitingPageRecruitingListViewFilterMenuAction,
    ISetRecruitingPageRecruitingToDeleteAction,
    ISetRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction,
    ISetRecruitingPageSortingOptionAction,
    ISetRecruitingPageStartRecruitingAction,
    ISetRecruitingPageSuggestionFilterAction,
    ISetVacancyEditViewActiveTabAction,
} from '../../definitions/redux/actions.definition';
import { ICandidatePartial } from '../../definitions/entities.definition';
import { IApiBaseFilter } from '../../definitions/app.definitions';

export const RECRUITING_PAGE_SET_FAST_SEARCH = "RECRUITING_PAGE_SET_FAST_SEARCH";
export const RECRUITING_PAGE_SET_FILTER = "RECRUITING_PAGE_SET_FILTER";
export const RECRUITING_PAGE_SET_SUGGESTION_FILTER = "RECRUITING_PAGE_SET_SUGGESTION_FILTER";
export const RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION = "RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION";
export const RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER = "RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER";
export const RECRUITING_PAGE_SET_ACTIVE_TAB = "RECRUITING_PAGE_SET_ACTIVE_TAB";
export const RECRUITING_PAGE_SET_ACTIVE_TAB_OF_EDIT_VACANCY_VIEW = "RECRUITING_PAGE_SET_ACTIVE_TAB_OF_EDIT_VACANCY_VIEW";
export const RECRUITING_PAGE_SET_SUGGESTION_TO_DECLINE = "RECRUITING_PAGE_SET_SUGGESTION_TO_DECLINE";
export const RECRUITING_PAGE_SET_SORTING_OPTION = "RECRUITING_PAGE_SET_SORTING_OPTION";
export const RECRUITING_PAGE_SET_RECRUITING_TO_DELETE = "RECRUITING_PAGE_SET_RECRUITING_TO_DELETE";
export const RECRUITING_PAGE_START_RECRUITING = "RECRUITING_PAGE_START_RECRUITING";
export const RECRUITING_PAGE_ADD_SUGGESTION = "RECRUITING_PAGE_ADD_SUGGESTION";
export const RECRUITING_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT = "RECRUITING_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT";
export const RECRUITING_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE = "RECRUITING_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE";
export const RECRUITING_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION = "RECRUITING_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION";
export const RECRUITING_PAGE_CLOSE_CHANGE_RECRUITING_RESPONSIBLE_WINDOW = "RECRUITING_PAGE_CLOSE_CHANGE_RECRUITING_RESPONSIBLE_WINDOW";
export const RECRUITING_PAGE_SET_CREATE_CONGRATULATION_MAIL = "RECRUITING_PAGE_SET_CREATE_CONGRATULATION_MAIL";
export const RECRUITING_PAGE_CLOSE_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW = "RECRUITING_PAGE_CLOSE_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW";
export const RECRUITING_PAGE_SELECT_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW = "RECRUITING_PAGE_SELECT_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW";
export const RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_OPEN = "RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_OPEN";
export const RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER = "RECRUITING_PAGE_OPEN_RECRUITING_LIST_VIEW";
export const RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER_MENU = "RECRUITING_PAGE_OPEN_RECRUITING_LIST_VIEW_MENU";
export const RECRUITING_PAGE_SET_RECRUITING_LIST_RESET_FILTER = "RECRUITING_PAGE_SET_RECRUITING_LIST_RESET_FILTER";
export const RECRUITING_PAGE_CLOSE_RECRUITING_LIST_VIEW = "RECRUITING_PAGE_CLOSE_RECRUITING_LIST_VIEW";
export const RECRUITING_PAGE_RELOAD_RECRUITING = "RECRUITING_PAGE_RELOAD_RECRUITING";
export const RECRUITING_PAGE_SET_DECLINE_RECRUITING = "RECRUITING_PAGE_SET_DECLINE_RECRUITING";
export const RECRUITING_PAGE_SET_CONTRACT_RECRUITING = "RECRUITING_PAGE_SET_CONTRACT_RECRUITING";

export const setRecruitingPageCloseAssignCandidateToSuggestionViewAction = (): ISetRecruitingPageAssignCandidateToSuggestionViewClosedAction => ({
    type: RECRUITING_PAGE_CLOSE_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW
});

export const setRecruitingPageRecruitingToContractAction = (recruitingId: number) => ({
    type: RECRUITING_PAGE_SET_CONTRACT_RECRUITING,
    payload: recruitingId
});

export const setRecruitingPageRecruitingToDeclineAction = (recruitingId: number) => ({
    type: RECRUITING_PAGE_SET_DECLINE_RECRUITING,
    payload: recruitingId
});

export const setRecruitingPageReloadRecruitingAction = () => ({
    type: RECRUITING_PAGE_RELOAD_RECRUITING,
});

export const setRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction = (candidate: ICandidatePartial): ISetRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction => ({
    type: RECRUITING_PAGE_SELECT_ASSIGN_CANDIDATE_TO_SUGGESTION_VIEW,
    payload: candidate
});

export const setRecruitingPageCreateCongratulationMailAction = (id: number): ISetRecruitingPageCreateCongratulationMailAction => ({
    type: RECRUITING_PAGE_SET_CREATE_CONGRATULATION_MAIL,
    payload: id,
});

export const setRecruitingPageStartRecruitingAction = (data: IStartRecruitingViewState): ISetRecruitingPageStartRecruitingAction => ({
    type: RECRUITING_PAGE_START_RECRUITING,
    payload: data,
});

export const setRecruitingPageAddSuggestionAction = (data: IAddSuggestionViewState): ISetRecruitingPageAddSuggestionAction => ({
    type: RECRUITING_PAGE_ADD_SUGGESTION,
    payload: data,
});

export const setRecruitingPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetRecruitingPageSortingOptionAction => ({
    type: RECRUITING_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setRecruitingPageRecruitingToDeleteAction = (recruitingId: number): ISetRecruitingPageRecruitingToDeleteAction => ({
    type: RECRUITING_PAGE_SET_RECRUITING_TO_DELETE,
    payload: recruitingId,
});

export const setRecruitingPageFilterAction = (filter: IRecruitingFilter, useGlobal: boolean): ISetRecruitingPageFilterAction => ({
    type: RECRUITING_PAGE_SET_FILTER,
    payload: {
        filter,
        useGlobal
    },
});

export const setRecruitingPageSuggestionFilterAction = (filter: ISuggestionFilter): ISetRecruitingPageSuggestionFilterAction => ({
    type: RECRUITING_PAGE_SET_SUGGESTION_FILTER,
    payload: filter,
});

export const setRecruitingPageActiveTabAction = (tab: ERecruitingPageTabs): ISetRecruitingPageActiveTabAction => ({
    type: RECRUITING_PAGE_SET_ACTIVE_TAB,
    payload: tab,
});

export const setVacancyEditViewActiveTabAction = (tab: EVacancyEditViewTabs): ISetVacancyEditViewActiveTabAction => ({
    type: RECRUITING_PAGE_SET_ACTIVE_TAB_OF_EDIT_VACANCY_VIEW,
    payload: tab,
});

export const setRecruitingPageFastSearchAction = (fastSearchValue: string) => ({
    type: RECRUITING_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setRecruitingPageSuggestionToDeclineAction = (suggestionId: number) => ({
    type: RECRUITING_PAGE_SET_SUGGESTION_TO_DECLINE,
    payload: suggestionId,
});
export const setRecruitingPageActivityListFilterAction = (filter: Partial<IApiBaseFilter>): ISetRecruitingPageEditViewActivityListFilterAction => ({
    type: RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER,
    payload: filter
});

export const setRecruitingPageActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions) => ({
    type: RECRUITING_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setRecruitingPageDocumentToEditAction = (documentId: number): ISetRecruitingPageDocumentToEditAction => ({
    type: RECRUITING_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    payload: documentId,
});

export const setRecruitingPageDocumentToDeleteAction = (documentId: number): ISetRecruitingPageDocumentToDeleteAction => ({
    type: RECRUITING_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE,
    payload: documentId,
});

export const setRecruitingPageDocumentSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetRecruitingPageDocumentSortingOptionAction => ({
    type: RECRUITING_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCloseChangeRecruitingResponsibleWindowAction = (): ICloseRecruitingPageChangeRecruitingResponsibleWindowAction => ({
    type: RECRUITING_PAGE_CLOSE_CHANGE_RECRUITING_RESPONSIBLE_WINDOW,
});

export const setRecruitingPageRecruitingListViewFilterAction = (filter: IRecruitingFilter): ISetRecruitingPageRecruitingListViewFilterAction => ({
    type: RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER,
    payload: filter,
});

export const setRecruitingPageRecruitingListViewOpenAction = (filter: IRecruitingFilter): ISetRecruitingPageRecruitingListViewFilterAction => ({
    type: RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_OPEN,
    payload: filter,
});

export const setRecruitingPageRecruitingListViewFilterMenuAction = (filterMenu: IRecruitingFilterMenu): ISetRecruitingPageRecruitingListViewFilterMenuAction => ({
    type: RECRUITING_PAGE_SET_RECRUITING_LIST_VIEW_FILTER_MENU,
    payload: filterMenu,
});

export const setRecruitingPageRecruitingListViewResetFilterAction = () => ({
    type: RECRUITING_PAGE_SET_RECRUITING_LIST_RESET_FILTER
})

export const setRecruitingPageCloseRecruitingListViewAction = (): ISetRecruitingPageCloseRecruitingListViewAction => ({
    type: RECRUITING_PAGE_CLOSE_RECRUITING_LIST_VIEW,
});
