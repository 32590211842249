import React, { useState } from 'react';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import {
    ICandidateLanguage,
    ICandidateListModel,
    IEmployeePartial,
    IEntitySkill,
    ISkillPartial,
    ISuggestionListModel,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import CustomNumberInput from '../../../../components/CustomInput/CustomNumberInput';
import YearsOfWorkExperienceDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/YearsOfWorkExperienceDropDown';
import HomeOfficeDesireDropDown from '../../../../components/CustomInput/CustomDropDownInput/HomeOfficeDesireDropDown';
import PartTimeFullTimeDropDown from '../../../../components/CustomInput/CustomDropDownInput/PartTimeFullTimeDropDown';
import NoticePeriodDropDown from '../../../../components/CustomInput/CustomDropDownInput/NoticePeriodDropDown';
import YesNoShowAllDropDown from '../../../../components/CustomInput/CustomDropDownInput/YesNoShowAllDropDown';
import moment from 'moment';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomMailField from '../../../../components/CustomInput/CustomMailField';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import CountryAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { ICandidateForm } from '../../../../definitions/candidatePage.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setCandidatePageToggleFieldForIgnoreValidation } from '../../../../redux/candidatePage/candidatePage.actions';
import { API_URL } from '../../../../requests/routes';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import CandidateInformationColumnCellRenderer
    from '../../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import { EEntityView } from '../../../../definitions/ui.definitions';
import YearOrMonthDropDown from '../../../../components/CustomInput/CustomDropDownInput/YearOrMonthDropDown';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import CandidateLevelDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateLevelDropDown';
import EducationLevelDropDown from '../../../../components/CustomInput/CustomDropDownInput/EducationLevelDropDown';
import { sortSkills } from '../../../../utils/skills.utils';
import CandidateSkillElement from './components/CandidateSkillElement';
import { Card, CardHeader, Chip } from '@material-ui/core';
import CandidateEditLanguagesView from './components/CandidateEditLanguagesView';
import { getEditViewUrl } from '../../../../utils/ui.utils';
import styles from '../../../Shared/Todo/TodoCard.module.css';
import CandidateSourceRenderer from '../../../../components/CustomCellRenderer/CandidateSourceRenderer';
import SkillAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/SkillAutocomplete';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';

interface IProps {
    candidateId?: number;
    addView?: boolean;
    candidate: ICandidateForm;
    setCandidate: (candidateForm: Partial<ICandidateForm>) => void;
    changedFieldsByCvParser?: string[];
    duplicateCandidates?: ICandidateListModel[];
    matchingSuggestions?: ISuggestionListModel[];
}

const CandidateProfileDataPanel: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const candidate = props.candidate;
    const changedFieldsByCvParser = props.changedFieldsByCvParser || [];
    const dispatch = useDispatch();
    const errors = useSelector(getValidationErrorsFromStore);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);
    const duplicateCandidates = props.duplicateCandidates || [];
    const matchingSuggestions = props.matchingSuggestions || [];
    const [salaryAsRange, setSalaryAsRange] = useState(Boolean(candidate.expectedSalaryTo > 0));
    const [editLanguagesViewOpen, setEditLanguagesViewOpen] = useState(false);

    const onRemoveSkill = (skill: ISkillPartial) => {
        props.setCandidate({
            skills: candidate.skills.filter((candidateSkill: IEntitySkill) => !candidateSkill.skill || skill.id !== candidateSkill.skill.id)
        })
    };

    const onRemoveLanguage = (removeId: number) => {
        props.setCandidate({
            languages: candidate.languages.filter((candidateLanguage: ICandidateLanguage) => removeId !== candidateLanguage.id)
        })
    };

    const onSetTopSkill = (skill: ISkillPartial) => {
        props.setCandidate({
            skills: candidate.skills.map((candidateSkill: IEntitySkill) => {
                if (candidateSkill.skill && candidateSkill.skill.id === skill.id) {
                    return {
                        ...candidateSkill,
                        importantSkill: !candidateSkill.importantSkill
                    }
                }
                return candidateSkill;
            })
        })
    }

    return (
        <>
            <CandidateEditLanguagesView
                onClose={() => setEditLanguagesViewOpen(false)}
                open={editLanguagesViewOpen}
                languages={candidate.languages}
                setLanguages={(languages) => props.setCandidate({languages: languages})}
                candidateId={candidateId}
            />
            <CustomValidationErrors translationKey={'candidate'}/>
            <div className={"flexContainerColumn gap5 padding10 overflow"}>
                {candidate.imagePath &&
                    <div className={"flexContainerRow flex1 flexVerticalCentered"}>
                        <img
                            src={API_URL + "/parsed-image/preview?file=" + candidate.imagePath + "&Authorization=" + token}
                            height={200}
                        />
                        <CustomClickableIcon
                            tooltip={"Bild entfernen"}
                            onClick={() => props.setCandidate({imagePath: undefined})}
                            icon={EIcons.Delete}
                        />
                    </div>
                }
                <h4 style={{margin:5}}>Besonderheiten</h4>
                <div className={"flexContainerRow flex1 gap5"}>
                    <CustomCheckboxInput
                        labelTranslation={"pages.candidate.properties.topCandidate"}
                        checked={candidate.topCandidate}
                        onChange={() => props.setCandidate({topCandidate: !candidate.topCandidate})}
                    />
                    <CustomCheckboxInput
                        label={<>Gute Verbindung /<br/>Gute Kommunikation</>}
                        checked={candidate.topInCommunication}
                        onChange={() => props.setCandidate({topInCommunication: !candidate.topInCommunication})}
                    />
                    <CustomCheckboxInput
                        label={<>Hohes Potential/<br/>Großes Talent</>}
                        checked={candidate.topInPotential}
                        onChange={() => props.setCandidate({topInPotential: !candidate.topInPotential})}
                    />
                </div>
                <h4 style={{margin:5}}>Persönliche Daten</h4>
                <div className={"flexContainerRow flex1 gap10"}>
                    <SalutationDropDown
                        onChange={(event: any) => props.setCandidate({salutation: event.target.value})}
                        value={candidate.salutation}
                        required={true}
                        error={Boolean(errors.salutation)}
                    />
                    <CustomTextField
                        label={translate('pages.companyContact.properties.title')}
                        value={candidate.title}
                        width={100}
                        onChange={(value) => props.setCandidate({title: value})}
                    />
                    <CustomTextField
                        error={Boolean(errors.firstName)}
                        required={true}
                        value={candidate.firstName}
                        onChange={(value) => props.setCandidate({firstName: value})}
                        label={translate('pages.candidate.properties.firstName')}
                    />
                    <CustomTextField
                        error={Boolean(errors.name)}
                        required={true}
                        value={candidate.name}
                        onChange={(value) => props.setCandidate({name: value})}
                        label={translate('pages.candidate.properties.name')}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <CustomTextField
                            required
                            helperText={<>
                                <CustomCheckboxInput
                                    checked={fieldsIgnoredForValidation.includes('zipCode')}
                                    label={"ohne " + translate('misc.zipCode') + " speichern"}
                                    onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('zipCode'))}
                                />
                            </>}
                            value={candidate.zipCode}
                            onChange={(value) => props.setCandidate({zipCode: value})}
                            label={changedFieldsByCvParser.includes('zipCode') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('misc.zipCode')}</b> : translate('misc.zipCode')}
                            error={Boolean(errors.zipCode) && !fieldsIgnoredForValidation.includes('zipCode')}
                            width={100}
                        />
                        <CustomTextField
                            required
                            helperText={<>
                                <CustomCheckboxInput
                                    checked={fieldsIgnoredForValidation.includes('city')}
                                    label={"ohne " + translate('misc.city') + " speichern"}
                                    onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('city'))}
                                />
                            </>}
                            label={changedFieldsByCvParser.includes('city') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('misc.city')}</b> : translate('misc.city')}
                            value={candidate.city}
                            onChange={(value) => props.setCandidate({city: value})}
                            error={Boolean(errors.city) && !fieldsIgnoredForValidation.includes('city')}
                        />
                    </div>
                    <CustomTextField
                        required
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('address')}
                                label={"ohne " + translate('misc.address') + " speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('address'))}
                            />
                        </>}
                        value={candidate.address}
                        onChange={(value) => props.setCandidate({address: value})}
                        label={changedFieldsByCvParser.includes('address') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('misc.address')}</b> : translate('misc.address')}
                        error={Boolean(errors.address) && !fieldsIgnoredForValidation.includes('address')}
                    />
                </div>
                {duplicateCandidates.length > 0 &&
                    <>
                        <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen</h4>
                        <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Kandidaten um diesen in einem neuem Fenster zu öffnen.</div>
                        <div className={"flexContainerRow flexWrap flex1 gap10"}>
                            {duplicateCandidates.map((candidate) => <>
                                <div
                                    className={"flexContainerRow CandidateInformationPanel"}
                                    onClick={() => {
                                        window.open(getEditViewUrl({
                                            view: EEntityView.candidate,
                                            entityId: candidate.id
                                        }, candidate.fullName))
                                    }}
                                >
                                    <CandidateInformationColumnCellRenderer
                                        key={candidate.id}
                                        showDetails
                                        token={token}
                                        candidate={candidate}
                                    />
                                </div>
                            </>
                            )}
                        </div>
                    </>
                }
                {matchingSuggestions.length > 0 &&
                <>
                    <h4 style={{margin: 5}}>Nicht zugeordnete Vorschläge mit gleichen Namen</h4>
                    <div
                        className={'formContent'}
                        style={{marginLeft: 5}}
                    >
                        Die Vorschläge werden nach dem speichern automatisch mit dem Kandidaten verknüpft.
                    </div>
                        {matchingSuggestions.map((suggestion) => <>
                                <div className={"flexContainerRow flexAutoGrow"}>
                                    <Card className={styles.root + ' hoverContainer'} style={{cursor: 'default'}}>
                                        <CardHeader
                                            title={
                                                <>
                                                    <div className={"flexColumn gap5"}>
                                                        <div className={"flex"}>
                                                            <div
                                                                className={"flex1 flexContainerRow alignVerticalCenter"}
                                                                style={{border: '0px solid red', cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    window.open(getEditViewUrl({
                                                                        view: EEntityView.suggestion,
                                                                        entityId: suggestion.id
                                                                    }, suggestion.fullName || null))
                                                                }}>
                                                                <CandidateSourceRenderer source={suggestion.source}/>&nbsp;
                                                                {suggestion.fullName || suggestion.externalCandidateReference}<br/>
                                                                {suggestion.vacancy.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            titleTypographyProps={{
                                                variant: "body2"
                                            }}
                                        />
                                    </Card>
                                </div>
                            </>
                        )}
                </>
                }
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomMailField
                        required
                        value={candidate.mailAddress}
                        onChange={(value) => props.setCandidate({mailAddress: value})}
                        label={changedFieldsByCvParser.includes('mailAddress') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.email')}</b> : translate('pages.candidate.properties.email')}
                        error={Boolean(errors.mailAddress) && !fieldsIgnoredForValidation.includes('mailAddress')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('mailAddress')}
                                label={"ohne " + translate('pages.candidate.properties.email') + " speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('mailAddress'))}
                            />
                        </>}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomTextField
                        required
                        value={candidate.mobileNumber}
                        onChange={(value) => props.setCandidate({mobileNumber: value})}
                        label={changedFieldsByCvParser.includes('mobileNumber') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('misc.mobileNumber')}</b> : translate('misc.mobileNumber')}
                        error={Boolean(errors.mobileNumber) && !fieldsIgnoredForValidation.includes('mobileNumber')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('mobileNumber')}
                                label={"ohne " + translate('misc.mobileNumber') + " speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('mobileNumber'))}
                            />
                        </>}
                    />
                    <CustomTextField
                        value={candidate.phoneNumber}
                        onChange={(value) => props.setCandidate({phoneNumber: value})}
                        label={'Abweichende Telefonnummer'}
                        error={Boolean(errors.phoneNumber)}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CountryAutocomplete
                        value={candidate.countryId}
                        required
                        error={Boolean(errors.countryId)}
                        onChange={(country) => {
                            if (!country) {
                                props.setCandidate({countryId: null});
                                return;
                            }
                            props.setCandidate({countryId: country.id})
                        }}
                    />
                    <CustomDatePicker
                        required
                        label={changedFieldsByCvParser.includes('birthDate') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.birthDate')}</b> : translate('pages.candidate.properties.birthDate')}
                        value={candidate.birthDate ? new Date(candidate.birthDate) : undefined}
                        onChange={(value) => {
                            if (!value) {
                                props.setCandidate({birthDate: undefined});
                                return;
                            }
                            props.setCandidate({birthDate: moment(value).format("Y-MM-DD")});
                        }}
                        error={Boolean(errors.birthDate) && !fieldsIgnoredForValidation.includes('birthDate')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('birthDate')}
                                label={"ohne " + translate('pages.candidate.properties.birthDate') + " speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('birthDate'))}
                            />
                        </>}
                    />
                </div>
                <h4 style={{margin:5}}>Urls</h4>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomUrlField
                        required
                        error={Boolean(errors.linkedInUrl) && !fieldsIgnoredForValidation.includes('linkedInUrl')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('linkedInUrl')}
                                label={"ohne LinkedIn Url speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('linkedInUrl'))}
                            />
                        </>}
                        value={candidate.linkedInUrl}
                        onChange={(value) => props.setCandidate({linkedInUrl: value})}
                        label={changedFieldsByCvParser.includes('birthDate') ? <b style={{color: 'var(--brainz-color-green)'}}>LinkedIn Url</b> : 'LinkedIn Url'}
                    />
                    <CustomUrlField
                        value={candidate.xingUrl}
                        onChange={(value) => props.setCandidate({xingUrl: value})}
                        label={'Xing Url'}
                        error={Boolean(errors.xingUrl)}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomUrlField
                        value={candidate.freelancerMapUrl}
                        onChange={(value) => props.setCandidate({freelancerMapUrl: value})}
                        label={'Freelancermap Url'}
                        error={Boolean(errors.freelancerMapUrl)}
                    />
                    <CustomUrlField
                        value={candidate.websiteUrl}
                        onChange={(value) => props.setCandidate({websiteUrl: value})}
                        label={'Website Url'}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomUrlField
                        value={candidate.githubUrl}
                        onChange={(value) => props.setCandidate({githubUrl: value})}
                        label={'Github Url'}
                    />
                    <CustomUrlField
                        value={candidate.furtherUrl}
                        onChange={(value) => props.setCandidate({furtherUrl: value})}
                        label={'Weitere Url'}
                    />
                </div>
                <h4 style={{margin:5}}>Berufliche Informationen</h4>
                <CustomTextField
                    required
                    label={translate('pages.candidate.properties.currentJobDescription')}
                    error={Boolean(errors.currentJobDescription)}
                    value={candidate.currentJobDescription}
                    onChange={(value) => props.setCandidate({currentJobDescription: value})}
                />
                <CustomTextField
                    label={translate('pages.candidate.properties.desiredJobDescription')}
                    value={candidate.desiredJobDescription}
                    onChange={(value) => props.setCandidate({desiredJobDescription: value})}
                />
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomDatePicker
                        label={translate('pages.candidate.properties.wishDateOfStart')}
                        value={candidate.wishDateOfStart ? new Date(candidate.wishDateOfStart) : undefined}
                        onChange={(value) => {
                            if (!value) {
                                props.setCandidate({wishDateOfStart: undefined});
                                return;
                            }
                            props.setCandidate({wishDateOfStart: moment(value).format("Y-MM-DD")});
                        }}
                        flex
                    />
                    <CustomNumberInput
                        required
                        label={"Anz. Arbeitgeber"}
                        error={Boolean(errors.numberOfEmployers) && !fieldsIgnoredForValidation.includes('numberOfEmployers')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('numberOfEmployers')}
                                label={"ohne Anz. Arbeitgeber speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('numberOfEmployers'))}
                            />
                        </>}
                        value={candidate.numberOfEmployers}
                        onChange={(value) => props.setCandidate({numberOfEmployers: value})}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CandidateLevelDropDown
                        onChange={(level) => props.setCandidate({level})}
                        // @ts-ignore-next-line
                        value={candidate.level}
                        required
                        error={Boolean(errors.level) && !fieldsIgnoredForValidation.includes('level')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('level')}
                                label={"ohne Erfahrungs-Grad speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('level'))}
                            />
                        </>}
                    />
                    <EducationLevelDropDown
                        onChange={(educationLevel) => props.setCandidate({educationLevel})}
                        // @ts-ignore-next-line
                        value={candidate.educationLevel}
                        required
                        error={Boolean(errors.educationLevel) && !fieldsIgnoredForValidation.includes('educationLevel')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('educationLevel')}
                                label={"ohne Bildungs-Grad speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('educationLevel'))}
                            />
                        </>}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <YearOrMonthDropDown
                        onChange={(value) => props.setCandidate({salaryUnit: value})}
                        value={candidate.salaryUnit}
                    />
                    <CustomNumberInput
                        value={candidate.expectedSalary}
                        suffix={candidate.salaryUnit === 'year' ? " k €" : " €"}
                        onChange={(value) => props.setCandidate({expectedSalary: value})}
                        label={salaryAsRange ? 'von' : translate('pages.candidate.salary')}
                        max={candidate.salaryUnit === 'year' ? 999 : undefined}
                    />
                    {salaryAsRange ?
                        <CustomNumberInput
                            value={candidate.expectedSalaryTo}
                            onClear={() => {
                                setSalaryAsRange(false);
                                props.setCandidate({expectedSalaryTo: undefined})
                            }}
                            suffix={candidate.salaryUnit === 'year' ? " k €" : " €"}
                            onChange={(value) => props.setCandidate({expectedSalaryTo: value})}
                            label={translate('pages.candidate.salaryTo')}
                            max={candidate.salaryUnit === 'year' ? 999 : undefined}
                        />
                        :
                        <CustomCheckboxInput
                            checked={salaryAsRange}
                            label={<>Gehaltsspanne<br/>angeben</>}
                            onChange={() => setSalaryAsRange(true)}
                        />
                    }
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <NoticePeriodDropDown
                        onChange={(event) => {
                            if (event.target.value === 0) {
                                props.setCandidate({noticePeriod: undefined});
                                return;
                            }
                            props.setCandidate({noticePeriod: event.target.value});
                        }}
                        // @ts-ignore-next-line
                        value={candidate.noticePeriod}
                    />
                    <YearsOfWorkExperienceDropDown
                        required
                        onChange={(event) => props.setCandidate({yearsOfWorkExperience: event.target.value})}
                        // @ts-ignore-next-line
                        value={candidate.yearsOfWorkExperience}
                        error={Boolean(errors.yearsOfWorkExperience) && !fieldsIgnoredForValidation.includes('yearsOfWorkExperience')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('yearsOfWorkExperience')}
                                label={"ohne Arbeitserfahrung speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('yearsOfWorkExperience'))}
                            />
                        </>}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <HomeOfficeDesireDropDown
                        onChange={(homeOfficeDesire) => props.setCandidate({homeOfficeDesire})}
                        // @ts-ignore-next-line
                        value={candidate.homeOfficeDesire}
                    />
                    <PartTimeFullTimeDropDown
                        onChange={(partTimeFullTime) =>
                            props.setCandidate({partTimeFullTime})
                        }
                        // @ts-ignore-next-line
                        value={candidate.partTimeFullTime}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <div className={"flex1 flexContainerRow"}>
                        <YesNoShowAllDropDown
                            allText={translate('misc.choose')}
                            onChange={(value) => props.setCandidate({hasDriverLicense: value})}
                            value={candidate.hasDriverLicense}
                            label={translate('pages.candidate.properties.hasDriverLicense')}
                        />
                    </div>
                    <div className={"flex1"}>
                        <CustomCheckboxInput
                            labelTranslation={"pages.candidate.properties.leader"}
                            checked={candidate.leader}
                            onChange={() => props.setCandidate({leader: !candidate.leader})}
                        />
                        <CustomCheckboxInput
                            labelTranslation={"pages.candidate.properties.freelancer"}
                            checked={candidate.freelancer}
                            onChange={() => props.setCandidate({freelancer: !candidate.freelancer})}
                        />
                    </div>
                </div>
                <div className={"flexContainerColumn flex1 gap10"}>
                    {(Boolean(errors.skills) && !fieldsIgnoredForValidation.includes('skills')) &&
                        <CustomCheckboxInput
                            checked={fieldsIgnoredForValidation.includes('skills')}
                            onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('skills'))}
                            label={
                                <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne Skills speichern</span>
                            }
                        />
                    }
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                        <h4 style={{margin: 5}}>Skills ({candidate.skills.length})</h4>
                    </div>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"} style={{width: 300}}>
                        <SkillAutocomplete
                            label={"Skill hinzufügen"}
                            onChange={(skill) => {
                                if (!skill) {
                                    return;
                                }

                                const filterSkills = candidate.skills.filter((candidateSkill) => candidateSkill.skill.id === skill.id);

                                props.setCandidate({skills: [
                                    ...candidate.skills, {
                                        skill: skill,
                                        id: - (skill.id),
                                        count: 0,
                                        importantSkill: false,
                                        addedByParser: true,
                                        identifier: 'candidate'
                                    }
                                ]})
                            }}
                            clearOnSelect
                            value={null}
                        />
                    </div>
                    <div className={"flexContainerRow flexAutoGrow overflow "} style={{alignItems: 'start'}}>
                        <div className={"flexContainerRow flexAutoGrow gap5 flexWrap"}>
                            {sortSkills(candidate.skills).map((candidateSkill, index) =>
                                <CandidateSkillElement
                                    key={index}
                                    skill={candidateSkill}
                                    onRemove={onRemoveSkill}
                                    onSetTop={onSetTopSkill}
                                />
                            )}
                        </div>
                    </div>
                    <div className={"flexContainerRow flexAutoGrow gap10"}>
                        {(Boolean(errors.languages) && !fieldsIgnoredForValidation.includes('languages')) &&
                        <>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('languages')}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('languages'))}
                                label={
                                    <span
                                        style={{color: '#f44336'}}
                                        className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}
                                    >
                                    Kandidat ohne Sprachen speichern
                                </span>
                                }/>
                        </>
                        }
                    </div>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow gap5 flexWrap"}>
                        <h4 style={{margin: 5}}>Sprachen ({candidate.languages.length})</h4>
                        <CustomClickableIcon
                            onClick={() => setEditLanguagesViewOpen(true)}
                            tooltip={"Sprache hinzufügen"}
                            icon={EIcons.AddBox}
                        />
                    </div>
                    <div className={"flexContainerRow flexAutoGrow gap5 flexWrap"}>
                        {candidate.languages.map((candidateLanguage, index) =>
                            <Chip
                                key={index}
                                color={candidateLanguage.addedByParser ? 'primary' : 'default'}
                                onClick={() => setEditLanguagesViewOpen(true)}
                                label={<> {candidateLanguage.language.name} ({translate('misc.languageLevelValue.' + candidateLanguage.level)})</>}
                                onDelete={() => onRemoveLanguage(candidateLanguage.id)}
                            />
                        )}
                    </div>
                </div>
                <h4 style={{margin: 5}}>Fragebogen</h4>
                <CustomTextField
                    value={candidate.typus}
                    label={translate('pages.candidate.properties.typus')}
                    onChange={(value) => props.setCandidate({typus: value})}
                />
                <CustomTextField
                    value={candidate.proudOf}
                    multiline
                    rows={3}
                    label={translate('pages.candidate.properties.proudOf')}
                    onChange={(value) => props.setCandidate({proudOf: value})}
                />
                <CustomTextField
                    value={candidate.thoughtsAboutTeamAndAppreciation}
                    rows={3}
                    multiline
                    label={translate('pages.candidate.properties.thoughtsAboutTeamAndAppreciation')}
                    onChange={(value) => props.setCandidate({thoughtsAboutTeamAndAppreciation: value})}
                />
                <CustomTextField
                    value={candidate.flexibility}
                    rows={3}
                    multiline
                    label={translate('pages.candidate.properties.flexibility')}
                    onChange={(value) => props.setCandidate({flexibility: value})}
                />
                <CustomTextField
                    multiline
                    rows={3}
                    value={candidate.moveWillingness}
                    onChange={(value) => props.setCandidate({moveWillingness: value})}
                    label={translate('pages.candidate.properties.moveWillingness')}
                />
                <h4 style={{margin: 5}}>Interne Daten</h4>
                <div className={"flexContainerRow flex1 gap10"}>
                    <LegacyEmployeeAutocompleteDropDown
                        required
                        label={translate('misc.responsibleEmployee')}
                        value={candidate.responsible}
                        onChange={(event, value: IEmployeePartial) => props.setCandidate({responsible: value})}
                        error={Boolean(errors.responsibleId)}
                    />
                    <CandidateSourceDropDown
                        required
                        value={candidate.source}
                        onChange={(event) => props.setCandidate({source: event.target.value})}
                        error={Boolean(errors.source)}
                    />
                    <CustomDatePicker
                        label={<>Wiederlage<br/>am</>}
                        width={140}
                        value={candidate.followupDate ? new Date(candidate.followupDate) : undefined}
                        onChange={(value) => {
                            if (!value) {
                                props.setCandidate({followupDate: undefined})
                                return;
                            }
                            props.setCandidate({followupDate: moment(value).format("Y-MM-DD")})
                        }}
                        error={Boolean(errors.followupDate)}
                    />
                </div>
                {(candidate.followupDate !== null && candidate.followupDate !== undefined) &&
                <CustomTextField
                    multiline
                    rows={4}
                    value={candidate.followupComment}
                    onChange={(value) => props.setCandidate({followupComment: value})}
                    label={'Kommentar zur Wiedervorlage'}
                />
                }
                <CustomTextField
                    multiline
                    rows={8}
                    value={candidate.comment}
                    onChange={(value) => props.setCandidate({comment: value})}
                    label={translate('misc.comment')}
                />
                {props.addView &&
                    <div className={"flexContainerRow flex1 gap10"}>
                        <div className={"flexContainerRow flex1 gap10"}>
                            <VacancyAutocomplete
                                required
                                label={translate('pages.candidate.appliedFor')}
                                error={Boolean(errors.applicationVacancyId) && !fieldsIgnoredForValidation.includes('applicationVacancyId')}
                                helperText={<>
                                    <CustomCheckboxInput
                                        checked={fieldsIgnoredForValidation.includes('applicationVacancyId')}
                                        label={"ohne Bewerbung speichern"}
                                        onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('applicationVacancyId'))}
                                    />
                                </>}
                                value={candidate.applicationVacancy}
                                onChange={(applicationVacancy) => props.setCandidate({applicationVacancy})}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default CandidateProfileDataPanel;
