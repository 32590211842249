import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { setUiToggleTopDrawer } from '../../../../redux/ui/ui.actions';

const GlobalSearchMenuItem: React.FC = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setUiToggleTopDrawer());
    };

    return <>
        <Tooltip title={'Globale Suche'} placement="bottom">
            <IconButton onClick={handleClick}>
                {IconFactory.getIconComponent(EIcons.Search, {color: 'var(--brainz-color-app-bar-right-drawer-icons)', fontSize: '2.1875rem'})}
            </IconButton>
        </Tooltip>
    </>;
}

export default React.memo(GlobalSearchMenuItem);
