import {
    ISetTodoAddDialogOpenAction,
    ISetTodoSortingAction,
    ISetTodoToDeleteAction,
    ISetTodoToEditAction,
    IToggleDueTodoHeaderAction,
} from '../../definitions/redux/actions.definition';
import { ESortingOptions } from '../../definitions/components.definitions';

export const TODO_SET_ADD_DIALOG_OPEN = "TODO_SET_ADD_DIALOG_OPEN";
export const TODO_SET_TODO_TO_EDIT = "TODO_SET_TODO_TO_EDIT";
export const TODO_SET_TODO_TO_DELETE = "TODO_SET_TODO_TO_DELETE";
export const TODO_SET_ACTIVITY_SORTING = "TODO_SET_ACTIVITY_SORTING";
export const TODO_TOGGLE_DUE_TODO_HEADER = "TODO_TOGGLE_DUE_TODO_HEADER";

export const setTodoAddDialogOpenAction = (open: boolean): ISetTodoAddDialogOpenAction => ({
    type: TODO_SET_ADD_DIALOG_OPEN,
    payload: open
});

export const toggleDueHeaderAction = (header: string): IToggleDueTodoHeaderAction => ({
    type: TODO_TOGGLE_DUE_TODO_HEADER,
    payload: header
});

export const setTodoToEditAction = (todoId: number): ISetTodoToEditAction => ({
    type: TODO_SET_TODO_TO_EDIT,
    payload: todoId
});

export const setTodoToDeleteAction = (todoId: number): ISetTodoToDeleteAction => ({
    type: TODO_SET_TODO_TO_DELETE,
    payload: todoId
});

export const setTodoActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetTodoSortingAction => ({
    type: TODO_SET_ACTIVITY_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});
