import { ClientApi } from '../../requests/ClientApi';
import {
    changePasswordRouteConfig,
    loginRouteConfig,
    loginTokenRouteConfig,
    refreshTokenRouteConfig,
} from '../../requests/routes';
import { asyncActionCreator } from '../../utils/actions.utils';

export const ACTOR_LOGIN = "ACTOR_LOGIN";
export const ACTOR_LOGIN_PENDING = "ACTOR_LOGIN_PENDING";
export const ACTOR_LOGIN_FULFILLED = "ACTOR_LOGIN_FULFILLED";
export const ACTOR_LOGIN_REJECTED = "ACTOR_LOGIN_REJECTED";

export const ACTOR_REFRESH_TOKEN = "ACTOR_REFRESH_TOKEN";
export const ACTOR_REFRESH_TOKEN_FULFILLED = "ACTOR_REFRESH_TOKEN_FULFILLED";
export const ACTOR_REFRESH_TOKEN_REJECTED = "ACTOR_REFRESH_TOKEN_REJECTED";

export const ACTOR_LOGOUT = "ACTOR_LOGOUT";
export const ACTOR_LOGOUT_EXPIRE = "ACTOR_LOGOUT_EXPIRE";

export const ACTOR_CHANGE_PASSWORD = "ACTOR_CHANGE_PASSWORD";
export const ACTOR_CHANGE_PASSWORD_FULFILLED = "ACTOR_CHANGE_PASSWORD_FULFILLED";

export const actorLogin = (login: string, password: string) =>
    asyncActionCreator(ACTOR_LOGIN, () => ClientApi.request(loginRouteConfig, { login, password }));

export const actorLogOutAction = () => ({
    type: ACTOR_LOGOUT,
});

export const actorLogOutExpireAction = () => ({
    type: ACTOR_LOGOUT_EXPIRE,
});

export const actorTokenLoginAction = (token: string) => ({
    type: ACTOR_LOGIN,
    payload: ClientApi.request(loginTokenRouteConfig, { token }),
});

export const actorRefreshTokenAction = (token: string) =>
    asyncActionCreator(ACTOR_REFRESH_TOKEN, () => ClientApi.request(refreshTokenRouteConfig, { token }));

export const actorChangePassword = (
    token: string,
    data: {
        userId: number;
        userEmail: string;
        currentPassword: string;
        newPassword: string;
        newPasswordConfirmation: string;
    }
) => asyncActionCreator(ACTOR_CHANGE_PASSWORD, () => ClientApi.request(changePasswordRouteConfig, { token, ...data }));
