import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { useCallback, useEffect, useState } from 'react';
import { executeLoadRecordsRequest } from './Autocomplete.utils';

export const useMultipleLivesearchHooks = (open: boolean, searchValue: string, routeParams: object, routeConfig: IRouteConfig) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TBrainzEntity[]>([]);

    const loadData = useCallback(() => {
        executeLoadRecordsRequest(token, setLoading, setRecords, routeConfig, {
            ...routeParams,
            filterFastSearch: searchValue
        });
    }, [JSON.stringify(routeParams), searchValue]);

    useEffect(() => {
        if (open && !loading && searchValue.length > 2) {
            loadData();
            return;
        }
        setRecords([]);
    }, [open, token, searchValue]);

    return {
        records,
        loading
    };
}
