import {
    ISetCalendarPageAppointmentAction,
    ISetCalendarPageCalendarFilterAction,
    ISetCalendarPageDateAction,
    ISetCalendarPageNavigationOpen,
    ISetCalendarPageOpenTodoViewOpen,
    ISetCalendarPageTodoFilterAction,
    ISetCalendarPageViewAction,
    ISetCalendarPageWeekViewScrollToAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { ICalendarState, preloadedCalendarState } from '../../definitions/calendarPage.definitions';
import {
    CALENDAR_PAGE_SET_CALENDAR_APPOINTMENT,
    CALENDAR_PAGE_SET_CALENDAR_FILTER,
    CALENDAR_PAGE_SET_CALENDAR_NAVIGATION_OPEN,
    CALENDAR_PAGE_SET_DATE,
    CALENDAR_PAGE_SET_OPEN_TODO_VIEW_OPEN,
    CALENDAR_PAGE_SET_TODO_FILTER,
    CALENDAR_PAGE_SET_VIEW,
    CALENDAR_PAGE_SET_WEEK_VIEW_SCROLL_TO,
} from './calendar.actions';
import { ENTITIES_POST_TODO_FULFILLED } from '../entities/entities.actions';

const calendarReducer = (state = preloadedCalendarState, action: TReducerAction): ICalendarState => {
    switch (action.type) {
        case ENTITIES_POST_TODO_FULFILLED: {
            return {
                ...state,
                appointment: undefined
            }
        }
        case CALENDAR_PAGE_SET_CALENDAR_FILTER: {
            const actionCast = action as ISetCalendarPageCalendarFilterAction;

            return {
                ...state,
                calendarFilter: {
                    ...state.calendarFilter,
                    ...actionCast.payload
                }
            }
        }
        case CALENDAR_PAGE_SET_TODO_FILTER: {
            const actionCast = action as ISetCalendarPageTodoFilterAction;

            return {
                ...state,
                todoFilter: {
                    ...state.todoFilter,
                    ...actionCast.payload
                }
            }
        }
        case CALENDAR_PAGE_SET_DATE: {
            const actionCast = action as ISetCalendarPageDateAction;

            return {
                ...state,
                currentDate: actionCast.payload,
            };
        }
        case CALENDAR_PAGE_SET_CALENDAR_APPOINTMENT: {
            const actionCast = action as ISetCalendarPageAppointmentAction;

            return {
                ...state,
                appointment: actionCast.payload,
            };
        }
        case CALENDAR_PAGE_SET_VIEW: {
            const actionCast = action as ISetCalendarPageViewAction;

            return {
                ...state,
                view: actionCast.payload,
            };
        }
        case CALENDAR_PAGE_SET_WEEK_VIEW_SCROLL_TO: {
            const actionCast = action as ISetCalendarPageWeekViewScrollToAction;

            return {
                ...state,
                scrollEnabled: actionCast.payload,
            };
        }
        case CALENDAR_PAGE_SET_CALENDAR_NAVIGATION_OPEN: {
            const actionCast = action as ISetCalendarPageNavigationOpen;

            return {
                ...state,
                calendarNavigationOpen: actionCast.payload,
            };
        }
        case CALENDAR_PAGE_SET_OPEN_TODO_VIEW_OPEN: {
            const actionCast = action as ISetCalendarPageOpenTodoViewOpen;

            return {
                ...state,
                openTodoViewOpen: actionCast.payload,
            };
        }
        default:
            return state;
    }
};

export default calendarReducer;
