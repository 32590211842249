import {
    IResetJobTemplatePageJobTitleFilterAction,
    ISetJobTemplatePageAddDialogAction,
    ISetJobTemplatePageFilterAction,
    ISetJobTemplatePageJobTitleFilterAction,
    ISetJobTemplatePageJobTitleFilterMenuAction,
    ISetJobTemplatePageJobTitleSortingOptionAction,
    ISetJobTemplatePageSetJobTemplateAction,
    ISetJobTemplatePageSortingOptionAction,
    ISetJobTemplatePageTemplateToEditAction,
} from '../../definitions/redux/actions.definition';
import { ESortingOptions } from '../../definitions/components.definitions';
import { IApiBaseFilter } from '../../definitions/app.definitions';
import { IJobTitleFilter, IJobTitleFilterMenu } from '../../definitions/jobTemplatePage.definitions';

export const JOB_TEMPLATE_PAGE_SET_SORTING = "JOB_TEMPLATE_PAGE_SET_SORTING";
export const JOB_TEMPLATE_PAGE_SET_JOB_TITLE_SORTING = "JOB_TEMPLATE_PAGE_SET_JOB_TITLE_SORTING";
export const JOB_TEMPLATE_PAGE_SET_FILTER = "JOB_TEMPLATE_PAGE_SET_FILTER";
export const JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER = "JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER";
export const JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER_MENU = "JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER_MENU";
export const JOB_TEMPLATE_PAGE_RESET_JOB_TITLE_FILTER = "JOB_TEMPLATE_PAGE_RESET_JOB_TITLE_FILTER";
export const JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT = "JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT";
export const JOB_TEMPLATE_PAGE_SET_ADD_DIALOG = "JOB_TEMPLATE_PAGE_SET_ADD_DIALOG";
export const JOB_TEMPLATE_PAGE_SET_JOB_TEMPLATE = "JOB_TEMPLATE_PAGE_SET_JOB_TEMPLATE";


export const setJobTemplatePageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetJobTemplatePageSortingOptionAction => ({
    type: JOB_TEMPLATE_PAGE_SET_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setJobTemplatePageSetJobTemplateAction = (jobTitleId?: number): ISetJobTemplatePageSetJobTemplateAction => ({
    type: JOB_TEMPLATE_PAGE_SET_JOB_TEMPLATE,
    payload: {
        jobTitleId,
    }
});

export const setJobTemplatePageFilterAction = (filter: Partial<IApiBaseFilter>): ISetJobTemplatePageFilterAction => ({
    type: JOB_TEMPLATE_PAGE_SET_FILTER,
    payload: filter
});

export const resetJobTemplateJobTitleFilterAction = (): IResetJobTemplatePageJobTitleFilterAction => ({
    type: JOB_TEMPLATE_PAGE_RESET_JOB_TITLE_FILTER,
});

export const setJobTemplatePageJobTitleFilterAction = (filter: Partial<IJobTitleFilter>): ISetJobTemplatePageJobTitleFilterAction => ({
    type: JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER,
    payload: filter
});

export const setJobTemplatePageJobTitleFilterMenuAction = (filter: Partial<IJobTitleFilterMenu>): ISetJobTemplatePageJobTitleFilterMenuAction => ({
    type: JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER_MENU,
    payload: filter
});

export const setJobTemplatePageJobTitleSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetJobTemplatePageJobTitleSortingOptionAction => ({
    type: JOB_TEMPLATE_PAGE_SET_JOB_TITLE_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setJobTemplatePageTemplateToEditAction = (jobTemplateId: number): ISetJobTemplatePageTemplateToEditAction => ({
    type: JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT,
    payload: jobTemplateId,
});

export const setJobTemplatePageAddDialogOpenAction = (open: boolean): ISetJobTemplatePageAddDialogAction => ({
    type: JOB_TEMPLATE_PAGE_SET_ADD_DIALOG,
    payload: open,
});

