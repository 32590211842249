import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../selectors/app.selectors';
import { deleteRecruitingAction } from '../../redux/entities/entities.actions';
import { setRecruitingPageRecruitingToDeleteAction } from '../../redux/recruitingPage/recruitingPage.actions';
import { setUiCloseView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

const RecruitingDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.recruitingPage.recruitingToDelete));
    const idToDelete = useSelector((store: IStore) => store.recruitingPage.recruitingToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setRecruitingPageRecruitingToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteRecruitingAction(token, idToDelete));
                dispatch(setUiCloseView(EEntityView.recruiting, idToDelete));
                dispatch(setRecruitingPageRecruitingToDeleteAction(0));
            }}
            titleTranslation={"pages.recruiting.deleteDialog.title"}
            bodyTranslation={"pages.recruiting.deleteDialog.body"}
        />
    );
};

export default RecruitingDeleteView;
