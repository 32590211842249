import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { useEffect, useState } from 'react';
import { ClientApi } from '../../../../requests/ClientApi';
import { getRecruitingListRouteConfig } from '../../../../requests/routes';
import { IRecruiting } from '../../../../definitions/entities.definition';

export const useLoadOpenRecruitings = () => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [recruitings, setRecruitings] = useState<IRecruiting[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loading && recruitings.length === 0) {
            setLoading(true);
            ClientApi.request(getRecruitingListRouteConfig, {
                token,
                orderBy: 'id',
                orderDirection: 'DESC',
                start: 0,
                limit: 100,
                onlyOpen: true
            }).then((result: {records: IRecruiting[], total: number }) => {
                setRecruitings(result.records);
                setLoading(false);
            });
        }
    }, [loading, token, dispatch]);

    return recruitings;
}
