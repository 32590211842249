import React, { useCallback, useEffect, useState } from 'react';
import {
    EEntities,
    IEntitySkill,
    ISkill,
    ISkillDetail,
    ISkillPartial,
    ISkillTreeNode,
} from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { ClientApi } from '../../../requests/ClientApi';
import { getSkillDetailsListConfig, getSkillTreeRouteConfig } from '../../../requests/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import CustomDumbTextField from '../../../components/CustomInput/CustomDumbTextField';
import { debounce } from '@material-ui/core';
import SelectMultipleSkillViewSearchList from './SelectMultipleSkillViewSearchList';
import { ESortingOptions } from '../../../definitions/components.definitions';
import TopBarButton from '../../../components/ListAndPreview/TopBarButton';
import { setCreateNewEntityDialogAction } from '../../../redux/ui/ui.actions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import TopBarPanel from '../../../components/ListAndPreview/TopBarPanel';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import SelectMultipleSkillViewSelectedList from './SelectMultipleSkillViewSelectedList';
import { getIdsFromTree, sortSkills, transformTreeNodeToSkill } from '../../../utils/skills.utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SkillTreeView from './SkillTreeView';

interface IProps {
    open: boolean;
    onClose: () => void;
    suggestForJobTitle?: string;
    initialSkills?: IEntitySkill[];
    setSkills: (skills: IEntitySkill[]) => void;
    setSkillRanking?: boolean;
    setSkillImportant?: boolean;
}

const SelectMultipleSkillView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [suggestForJobTitle, setSuggestForJobTitle] = useState(props.suggestForJobTitle);
    const [fastSearch, setFastSearch] = useState('');
    const [orderBy, setOrderBy] = useState('shareInCandidates');
    const [orderDirection, setOrderDirection] = useState(ESortingOptions.Ascending);
    const [skills, setSkills] = useState<ISkill[]>([]);
    const [selectedSkills, setSelectedSkills] = useState(props.initialSkills || []);
    const [selectMode, setSelectMode] = useState<'tree'|'list'>('list');
    const [treeNodes, setTreeNodes] = useState<ISkillTreeNode[]>([]);
    const [expandedTreeNodes, setExpandedTreeNodes] = useState<string[]>([]);

    useEffect(() => {
        setSelectedSkills(props.initialSkills || []);
    }, [props.initialSkills])

    const changeOrdering = (orderBy: string, orderDirection: ESortingOptions) => {
        setOrderBy(orderBy);
        setOrderDirection(orderDirection);
    }

    const loadList = () => {
        ClientApi.request(getSkillDetailsListConfig, {
            token,
            suggestForJobTitle,
            filterFastSearch: fastSearch,
            orderBy,
            orderDirection,
        }).then((result: ISkillDetail[]) => {
            setLoading(false);
            const selectedSkillIds = selectedSkills.map(({ skill }) => skill.id);

            setSkills(
                result
                    .filter((skill) => selectedSkillIds.indexOf(skill.skill.id) === -1)
                    .map((skill) => skill.skill)
            );
        });
    }

    const loadTree = () => {
        ClientApi.request(getSkillTreeRouteConfig, {
            token,
            suggestForJobTitle,
            filterFastSearch: fastSearch,
        }).then((result: {tree: ISkillTreeNode[]}) => {
            setLoading(false);
            setTreeNodes(result.tree);
        });
    }

    useEffect(() => {
        if (fastSearch.length >= 3) {
            setExpandedTreeNodes(getIdsFromTree(treeNodes));
            return;
        }
        setExpandedTreeNodes([]);
    }, [treeNodes])

    const load = () => {
        setLoading(true);
        if (selectMode === 'tree') {
            loadTree();
            return;
        }
        loadList();
    }

    useEffect(() => {
        if (props.open) {
            load()
        }
    }, [dispatch, selectMode, props.open, fastSearch, suggestForJobTitle, orderBy, orderDirection]);

    const changeHandler = (callable: () => void) => {
        callable();
    }

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const addSkill = (skill: ISkillPartial) => {
        const skillToAdd: IEntitySkill = {
            skill,
            id: - (skill.id),
            importantSkill: false,
            addedByParser: false,
            count: 0
        }

        setSelectedSkills(sortSkills([
            ...selectedSkills,
            skillToAdd
        ]));
        setSkills(skills.filter((skill) => skill.id !== skillToAdd.skill.id));
    }

    const removeSkill = (skillToRemove: ISkillPartial) => {
        setSelectedSkills(selectedSkills.filter((skill) => skill.skill.id !== skillToRemove.id));
    }

    const onDrop = (dropResult: DropResult) => {
        if (dropResult.source.droppableId === EEntities.SkillSelectSearch.toString()) {
            addSkill(skills[dropResult.source.index]);
            return;
        }

        const skillToRemove = selectedSkills[dropResult.source.index];
        removeSkill(skillToRemove.skill);
    }

    const onClose = () => {
        props.onClose();
        setFastSearch('');
    }

    return (
        <CustomDialog fullWidth open={props.open} onClose={onClose}>
            <CustomDialogHeader translationString={'misc.selectSkills'} onClose={onClose} />
            <TopBarPanel>
                <TopBarButton
                    onClick={() => dispatch(setCreateNewEntityDialogAction(EEntities.Skill))}
                    icon={EIcons.Add}
                    label={"Neuen Skill hinzufügen"}
                />
            </TopBarPanel>
            <div className={"flex flexAutoGrow padding5"}>
                <ToggleButtonGroup
                    style={{height:58, marginTop: 7}}
                    value={selectMode}
                    exclusive
                    onChange={(event, value) => {
                        if (value !== null) {
                            setSelectMode(value)
                        }
                    }}>
                    <ToggleButton value="list">
                        {IconFactory.getIconComponent(EIcons.List)}
                    </ToggleButton>
                    <ToggleButton value="tree">
                        {IconFactory.getIconComponent(EIcons.Tree)}
                    </ToggleButton>
                </ToggleButtonGroup>
                &nbsp;
                <CustomDumbTextField
                    label={translate('misc.fastSearch')}
                    style={{flex: 1}}
                    onChange={(event) => {
                        debouncedChangeHandler(() => setFastSearch(event.target.value));
                    }}
                />
                &nbsp;
                <CustomDumbTextField
                    label={"Matching zu Job-Titel/n"}
                    helperText={"(*Genutzt Kandidaten/Vakanzen mit ähnlichen Job-Titel)"}
                    style={{flex: 2}}
                    defaultValue={props.suggestForJobTitle}
                    onChange={(event) => {
                        debouncedChangeHandler(() => setSuggestForJobTitle(event.target.value));
                    }}
                />
            </div>
            <div style ={{
                display: "flex",
                flex: "1 1 0%",
                overflow: "hidden",
                padding: 10
            }}>
                <DragDropContext onDragEnd={onDrop}>
                    { selectMode === 'tree'
                        ?
                            <SkillTreeView
                                initialExpanded={expandedTreeNodes}
                                selected={selectedSkills.map((skill) => skill.skill.id.toString())}
                                treeNodes={treeNodes}
                                selectable
                                onSelect={(node) => addSkill(transformTreeNodeToSkill(node))}
                                onDeselect={(node) => removeSkill(transformTreeNodeToSkill(node))}
                            />
                        :
                            <SelectMultipleSkillViewSearchList
                                onReload={load}
                                loading={loading}
                                skills={skills || []}
                                setOrdering={changeOrdering}
                                addSkill={addSkill}
                            />
                    }

                    <div style={{padding: 5}}>&nbsp;</div>
                    <SelectMultipleSkillViewSelectedList
                        skills={selectedSkills || []}
                        setSelectedSkills={setSelectedSkills}
                        removeSkill={removeSkill}
                        setSkillRanking={props.setSkillRanking || false}
                        setSkillImportant={props.setSkillImportant || false}
                    />
                </DragDropContext>
            </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    hidden: (fastSearch === '' && suggestForJobTitle === ''),
                    color: 'primary',
                    label: 'Alle ' + skills.length + ' Skills übernehmen',
                    void: () => {
                        const addSkills: IEntitySkill[] = [];
                        skills.forEach((skill) => {
                            addSkills.push({
                                skill,
                                id: - (skill.id),
                                importantSkill: false,
                                addedByParser: false,
                                count: skill.usedByCandidates
                            })
                        });
                        setSelectedSkills(sortSkills([
                            ...selectedSkills,
                            ...addSkills
                        ]));
                        setSkills([]);
                    }
                }, {
                    void: () => {
                        props.setSkills(selectedSkills);
                        onClose();
                    },
                    color: 'secondary',
                    label: translate('matching.useSkills'),
                }]}
            />
        </CustomDialog>
    );
}

export default SelectMultipleSkillView
