import React from 'react';
import '../../App.css';
import '../../Flex.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ERoutes } from '../../definitions/app.definitions';
import { useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import LogoutView from '../../components/Login/LogoutView';
import moment from 'moment';
import 'moment/locale/de';
import { useRenewPassword } from '../../App.hooks';
import BasePage from '../../Pages/App/BasePage';
import theme from '../../theme/theme';
import { ThemeProvider } from '@material-ui/core';
import ReduxDelegator from '../../redux/ReduxDelegator';
import CustomSnackbar from '../../components/CustomSnackbar/CustomSnackbar';
import RefreshTokenController from '../../components/Login/RefreshTokenController';
import TopBar from './TopBar';
import LeftDrawerNavigation from './LeftDrawerNavigation';
import RecruitingPage from '../RecruitingPage/RecruitingPage';
import HeartBeatControllerCustomer from '../../components/HeartBeatController/HeartBeatControllerCustomer';
import NavigationControllerCustomer from '../../components/NavigationController/NavigationControllerCustomer';

moment.locale('de');

const AppLoggedIn: React.FC = () => {
    useRenewPassword();

    const applicationHasError = useSelector((store: IStore) => store.error.hasError);
    const applicationHasInfo = useSelector((store: IStore) => store.error.hasInfo);
    const errorMessage = useSelector((store: IStore) => store.error.errorMessage);
    const infoMessage = useSelector((store: IStore) => store.error.infoMessage);
    const applicationHasSuccess = useSelector((store: IStore) => store.error.success);

    return (
        <>
            <div className="App">
                <ErrorBoundary>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <TopBar/>
                            <LeftDrawerNavigation/>
                            <BasePage>
                                <Switch>
                                    <Route path={ERoutes.Recruitings} render={() => <RecruitingPage/>}/>
                                </Switch>
                            </BasePage>
                            <HeartBeatControllerCustomer/>
                            <NavigationControllerCustomer/>
                            <LogoutView/>
                        </Router>
                        <CustomSnackbar
                            hasSuccess={applicationHasSuccess}
                            hasError={applicationHasError}
                            hasInfo={applicationHasInfo}
                            message={errorMessage !== '' ? errorMessage : infoMessage}
                        />
                        <ReduxDelegator/>
                        <RefreshTokenController/>
                    </ThemeProvider>
                </ErrorBoundary>
            </div>
        </>
    );
};

export default AppLoggedIn;
