import {
    IChangeTodoOrder,
    IChangeVacancyOrder,
    IChangeVacancySuggestionOrder,
    IDeleteActivityAction,
    IDeleteCandidateAction,
    IDeleteCandidateDocumentAction,
    IDeleteCandidateProfessionAction,
    IDeleteCInvoiceAction,
    IDeleteCompanyAction,
    IDeleteCompanyContactAction,
    IDeleteCompanyDocumentAction,
    IDeleteCompanyLocationAction,
    IDeleteRecruitingAction,
    IDeleteRecruitingDocumentAction,
    IDeleteSuggestionAction,
    IDeleteTodoAction,
    IDeleteVacancyAction,
    IGetActivityAction,
    IGetActivityChatListAction,
    IGetActivityListAction,
    IGetApplicationListAction,
    IGetBrancheListAction,
    IGetCandidateAction,
    IGetCandidateDocumentsAction,
    IGetCandidateListAction,
    IGetCandidateListAppendAction,
    IGetCompanyAction,
    IGetCompanyContactAction,
    IGetCompanyContactListAction,
    IGetCompanyContactsForCompanyDropdownAction,
    IGetCompanyDocumentsAction,
    IGetCompanyListAction,
    IGetCompanyLocationsAction,
    IGetEmployeeListAction,
    IGetExternalCandidateAction,
    IGetExternalSkillAction,
    IGetInvoiceAction,
    IGetInvoiceListAction,
    IGetJobTemplateAction,
    IGetJobTemplateListAction,
    IGetJobTemplateNameListAction,
    IGetJobTitleListAction,
    IGetMailAction,
    IGetMailAddressesAction,
    IGetMailListAction,
    IGetNewsListAction,
    IGetRecruitingAction,
    IGetRecruitingDocumentsAction,
    IGetRecruitingListAction,
    IGetRecruitingListOfEmployeeAction,
    IGetSkillAction,
    IGetSkillListAction,
    IGetSkillTreeAction,
    IGetSuccessActivityListAction,
    IGetSuggestionAction,
    IGetSuggestionListAction,
    IGetTodoAction,
    IGetTodoListAction,
    IGetVacancyAction,
    IGetVacancyAndWebVacancyListForCandidateSearchAction,
    IGetVacancyListAction,
    IGetWebVacancyAction,
    IGetWebVacancyListAction,
    IPostActivityAction,
    IPostCandidateAction,
    IPostCandidateDocumentAction,
    IPostCandidateProfessionAction,
    IPostCompanyAction,
    IPostCompanyContactAction,
    IPostCompanyDocumentAction,
    IPostCompanyLocationAction,
    IPostEmployeeAction,
    IPostJobTemplateAction,
    IPostRecruitingAction,
    IPostRecruitingDocumentAction,
    IPostSkillAction,
    IPostSuggestionAction,
    IPostVacancyAction,
    IPutActivityAction,
    IPutCandidateAction,
    IPutCandidateDocumentAction,
    IPutCompanyAction,
    IPutCompanyContactAction,
    IPutCompanyDocumentAction,
    IPutCompanyLocationAction,
    IPutEmployeeAction,
    IPutInvoiceAction,
    IPutJobTemplateAction,
    IPutMailMarkAsReadAction,
    IPutRecruitingAction,
    IPutRecruitingDocumentAction,
    IPutSkillAction,
    IPutSuggestionAcceptAction,
    IPutSuggestionAction,
    IPutSuggestionDeclineAction,
    IPutTodoAction,
    IPutVacancyAction,
    ISetAdministrationPageEmployeeToEditAction,
    ISetCandidatePageAddDialogAction,
    ISetJobTemplatePageTemplateToEditAction,
    ISetSkillPageExternalSkillToEditAction,
    ISetSkillPageSkillToEditAction,
    ISetTodoToEditAction,
    ISetVacancyPageEditSuggestionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    ENTITIES_CHANGE_TODO_ORDER,
    ENTITIES_CHANGE_VACANCY_ORDER,
    ENTITIES_CHANGE_VACANCY_SUGGESTION_ORDER,
    ENTITIES_DELETE_ACTIVITY_FULFILLED,
    ENTITIES_DELETE_CANDIDATE_DOCUMENT_FULFILLED,
    ENTITIES_DELETE_CANDIDATE_FULFILLED,
    ENTITIES_DELETE_CANDIDATE_PROFESSION_FULFILLED,
    ENTITIES_DELETE_COMPANY_CONTACT_FULFILLED,
    ENTITIES_DELETE_COMPANY_DOCUMENT_FULFILLED,
    ENTITIES_DELETE_COMPANY_FULFILLED,
    ENTITIES_DELETE_COMPANY_LOCATION_FULFILLED,
    ENTITIES_DELETE_INVOICE_FULFILLED,
    ENTITIES_DELETE_RECRUITING_DOCUMENT_FULFILLED,
    ENTITIES_DELETE_RECRUITING_FULFILLED,
    ENTITIES_DELETE_SUGGESTION_FULFILLED,
    ENTITIES_DELETE_TODO_FULFILLED,
    ENTITIES_DELETE_VACANCY_FULFILLED,
    ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED,
    ENTITIES_GET_ACTIVITY_FULFILLED,
    ENTITIES_GET_ACTIVITY_LIST_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_FULFILLED,
    ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_BRANCH_LIST_FULFILLED,
    ENTITIES_GET_CANDIDATE_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_FULFILLED,
    ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED,
    ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED,
    ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED,
    ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED,
    ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED,
    ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED,
    ENTITIES_GET_COMPANY_FULFILLED,
    ENTITIES_GET_COMPANY_LIST_FULFILLED,
    ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_FULFILLED,
    ENTITIES_GET_DUE_TODO_LIST_FULFILLED,
    ENTITIES_GET_EMPLOYEE_LIST_FULFILLED,
    ENTITIES_GET_EXTERNAL_CANDIDATE_FULFILLED,
    ENTITIES_GET_EXTERNAL_SKILL_FULFILLED,
    ENTITIES_GET_INVOICE_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED,
    ENTITIES_GET_JOB_TEMPLATE_FULFILLED,
    ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED,
    ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED,
    ENTITIES_GET_JOB_TITLE_LIST_FULFILLED,
    ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_FULFILLED,
    ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED,
    ENTITIES_GET_MAIL_ADDRESSES_FULFILLED,
    ENTITIES_GET_MAIL_FULFILLED,
    ENTITIES_GET_MAIL_LIST_FULFILLED,
    ENTITIES_GET_NEWS_LIST_FULFILLED,
    ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED,
    ENTITIES_GET_SKILL_FULFILLED,
    ENTITIES_GET_SKILL_LIST_FULFILLED,
    ENTITIES_GET_SKILL_TREE_FULFILLED,
    ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED,
    ENTITIES_GET_SUGGESTION_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED,
    ENTITIES_GET_TODO_FULFILLED,
    ENTITIES_GET_TODO_LIST_FULFILLED,
    ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED,
    ENTITIES_GET_VACANCY_FULFILLED,
    ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED,
    ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED,
    ENTITIES_POST_ACTIVITY_FULFILLED,
    ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED,
    ENTITIES_POST_CANDIDATE_FULFILLED,
    ENTITIES_POST_CANDIDATE_PROFESSION_FULFILLED,
    ENTITIES_POST_COMPANY_CONTACT_FULFILLED,
    ENTITIES_POST_COMPANY_DOCUMENT_FULFILLED,
    ENTITIES_POST_COMPANY_FULFILLED,
    ENTITIES_POST_COMPANY_LOCATION_FULFILLED,
    ENTITIES_POST_EMPLOYEE_FULFILLED,
    ENTITIES_POST_JOB_TEMPLATE_FULFILLED,
    ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_POST_SKILL_FULFILLED,
    ENTITIES_POST_SUGGESTION_FULFILLED,
    ENTITIES_POST_TODO_FULFILLED,
    ENTITIES_POST_VACANCY_FULFILLED,
    ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_ACTIVITY_FULFILLED,
    ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED,
    ENTITIES_PUT_CANDIDATE_FULFILLED,
    ENTITIES_PUT_CANDIDATE_PROFESSION_FULFILLED,
    ENTITIES_PUT_COMPANY_CONTACT_FULFILLED,
    ENTITIES_PUT_COMPANY_DOCUMENT_FULFILLED,
    ENTITIES_PUT_COMPANY_FULFILLED,
    ENTITIES_PUT_COMPANY_LOCATION_FULFILLED,
    ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED,
    ENTITIES_PUT_EMPLOYEE_FULFILLED,
    ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED,
    ENTITIES_PUT_INVOICE_FULFILLED,
    ENTITIES_PUT_JOB_TEMPLATE_FULFILLED,
    ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED,
    ENTITIES_PUT_RECRUITING_FULFILLED,
    ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED,
    ENTITIES_PUT_SKILL_FULFILLED,
    ENTITIES_PUT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_TODO_FULFILLED,
    ENTITIES_PUT_TODO_ORDER_FULFILLED,
    ENTITIES_PUT_VACANCY_FULFILLED,
    ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_ABORT,
    ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_CONTRACT,
    ENTITIES_SET_MAIL_READ_FULFILLED,
} from './entities.actions';
import {
    EEntities,
    ERecruitingStatus,
    ICandidateDocument,
    ICandidateListModel,
    ICompanyDocument,
    IEntitiesById,
    IEntitiesState,
    IRecruitingDocument,
    ISkillTreeNode,
    ISuggestionListModel,
    ITodo,
    IVacancyListModel,
    preloadedStateEntities,
} from '../../definitions/entities.definition';
import {
    mapEntitiesToId,
    mapEntityIdsToApplicationId,
    mapEntityIdsToCandidateId,
    mapEntityIdsToCompanyId,
    mapEntityIdsToEmployeeId,
    mapEntityIdsToRecruitingId,
    mapEntityIdsToSuggestionId,
    mapEntityIdsToTodoId,
    mapEntityIdsToVacancyId,
    mapEntityIdsToWebVacancyId,
} from '../../utils/entities.utils';
import { CANDIDATE_PAGE_SET_ADD_DIALOG } from '../candidatePage/candidatePage.actions';
import { ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT } from '../administrationPage/administrationPage.actions';
import { TODO_SET_TODO_TO_EDIT } from '../todo/todo.actions';
import Moment from 'moment';
import { candidateToListModel } from '../../utils/candidate.utils';
import { SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT, SKILL_PAGE_SET_SKILL_TO_EDIT } from '../skillPage/skillPage.actions';
import { JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT } from '../jobTemplate/jobTemplatePage.actions';
import { MAILING_CLOSE_DETAIL_VIEW } from '../mailing/mailing.actions';

const entitiesReducer = (state = preloadedStateEntities, action: TReducerAction): IEntitiesState => {
    switch (action.type) {
        case ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_CONTRACT: {
            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    statusChangedToContract: false,
                },
            };
        }

        case ENTITIES_RESET_RECRUITING_STATUS_CHANGED_TO_ABORT: {
            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    statusChangedToAbort: false,
                },
            };
        }
        /**
         * MAIL ADDRESSES
         */
        case ENTITIES_GET_MAIL_ADDRESSES_FULFILLED: {
            const actionCast = action as IGetMailAddressesAction;

            return {...state, mailAddresses: actionCast.payload};
        }

        /**
         * MAILS ENTITY
         */
        case MAILING_CLOSE_DETAIL_VIEW: {
            return {
                ...state,
                mail: {
                    ...state.mail,
                    currentRecord: undefined
                },
            };
        }
        case ENTITIES_GET_MAIL_FULFILLED: {
            const actionCast = action as IGetMailAction;

            return {
                ...state,
                mail: {
                    ...state.mail,
                    byId: {
                        ...state.mail.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                    currentRecord: actionCast.payload
                },
            };
        }
        case ENTITIES_GET_MAIL_LIST_FULFILLED: {
            const actionCast = action as IGetMailListAction;
            const mailsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                mail: {
                    ...state.mail,
                    byId: {
                        ...state.mail.byId,
                        ...mailsById
                    },
                    order: Object.values(actionCast.payload.records).map((mail) => mail.id),
                    total: actionCast.payload.total
                },
            };
        }

        case ENTITIES_SET_MAIL_READ_FULFILLED: {
            const actionCast = action as IPutMailMarkAsReadAction;

            return {
                ...state,
                mail: {
                    ...state.mail,
                    byId: {
                        ...state.mail.byId,
                        [actionCast.payload.id]: actionCast.payload
                    },
                }
            }
        }

        /**
         * NEWS ENTITY
         */
        case ENTITIES_GET_NEWS_LIST_FULFILLED: {
            const actionCast = action as IGetNewsListAction;

            const newsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                news: {
                    ...state.news,
                    byId: newsById,
                    order: Object.values(actionCast.payload.records).map((news) => news.id),
                },
            };
        }

        /**
         * CHANGE ORDERS
         */
        case ENTITIES_CHANGE_VACANCY_SUGGESTION_ORDER: {
            const actionCast = action as IChangeVacancySuggestionOrder;

            const newState = {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    byVacancy: {
                        ...state.recruitingSuggestion.byVacancy,
                        [actionCast.payload.vacancyId]: actionCast.payload.newOrder,
                    },
                },
            };

            actionCast.payload.newOrder.forEach((value, index) => {
                newState.recruitingSuggestion.listModelById[value].ranking = index + 1;
            });

            return newState;
        }

        case ENTITIES_CHANGE_VACANCY_ORDER: {
            const actionCast = action as IChangeVacancyOrder;

            const newState = {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    order: actionCast.payload.newOrder
                },
            };

            actionCast.payload.newOrder.forEach((value, index) => {
                newState.vacancies.listModelById[value].ranking = index + 1;
            });

            return newState;
        }

        case ENTITIES_CHANGE_TODO_ORDER: {
            const actionCast = action as IChangeTodoOrder;

            const newState = {
                ...state,
                todo: {
                    ...state.todo,
                    orderOfLoggedInEmployee: actionCast.payload.newOrder,
                },
            };

            let ranking = 0;
            actionCast.payload.newOrder.forEach((value, index) => {
                newState.todo.byId[value].ranking = ranking;
                ranking ++;
            });

            return newState;
        }

        /**
         * TODO ENTITY
         */
        case ENTITIES_PUT_TODO_FULFILLED: {
            const actionCast = action as IPutTodoAction;

            return {
                ...state,
                todo: {
                    ...state.todo,
                    currentRecord: actionCast.meta.closeEditView ? undefined : actionCast.payload,
                    byId: {
                        ...state.todo.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_PUT_TODO_ORDER_FULFILLED: {
            const actionCast = action as IPutTodoAction;

            return {
                ...state,
                todo: {
                    ...state.todo,
                    byId: {
                        ...state.todo.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_DELETE_TODO_FULFILLED: {
            const actionCast = action as IDeleteTodoAction;

            return {
                ...state,
                todo: {
                    ...state.todo,
                    orderOfLoggedInEmployee: state.todo.orderOfLoggedInEmployee.filter((id) => id !== actionCast.meta),
                    withoutDueByEmployee: Object.values(state.todo.withoutDueByEmployee).filter((todo) => todo.id !== actionCast.meta)
                }
            };
        }

        case ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED: {
            const actionCast = action as IGetTodoListAction;
            const todosById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                todo: {
                    ...state.todo,
                    byId: {
                        ...state.todo.byId,
                        ...todosById,
                    },
                    orderOfLoggedInEmployee: Object.values(actionCast.payload.records).map((todo: ITodo) => todo.id),
                },
            };
        }

        case ENTITIES_GET_TODO_FULFILLED: {
            const actionCast = action as IGetTodoAction;

            return {
                ...state,
                todo: {
                    ...state.todo,
                    currentRecord: actionCast.payload,
                },
            };
        }

        case ENTITIES_POST_TODO_FULFILLED: {
            return {
                ...state,
                todo: {
                    ...state.todo,
                    currentRecord: undefined,
                },
            };
        }

        case ENTITIES_GET_TODO_LIST_FULFILLED: {
            const actionCast = action as IGetTodoListAction;
            const todosById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                todo: {
                    ...state.todo,
                    byId: {
                        ...state.todo.byId,
                        ...todosById,
                    },
                    withoutDueByEmployee: mapEntityIdsToEmployeeId(actionCast.payload.records),
                },
            };
        }

        case TODO_SET_TODO_TO_EDIT: {
            const actionCast = action as ISetTodoToEditAction;

            if (actionCast.payload === 0) {
                return {
                    ...state,
                    todo: {
                        ...state.todo,
                        currentRecord: undefined,
                    },
                };
            }

            return state;
        }

        case ENTITIES_GET_DUE_TODO_LIST_FULFILLED: {
            const actionCast = action as IGetTodoListAction;
            const todosById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                todo: {
                    ...state.todo,
                    byId: {
                        ...state.todo.byId,
                        ...todosById,
                    },
                },
            };
        }

        /***
         * ACTIVITY ENTITY
         */
        case ENTITIES_GET_ACTIVITY_FULFILLED: {
            const actionCast = action as IGetActivityAction;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    byId: {
                        ...state.activities.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_GET_SUCCESS_ACTIVITY_LIST_FULFILLED: {
            const actionCast = action as IGetSuccessActivityListAction;
            return {
                ...state,
                activities: {
                    ...state.activities,
                    successActivities: actionCast.payload
                },
            };
        }
        case ENTITIES_GET_ACTIVITY_CHAT_LIST_FULFILLED: {
            const actionCast = action as IGetActivityChatListAction;

            switch (actionCast.meta.activityEntity) {
                case EEntities.Activity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            global: actionCast.payload
                        }
                    }
                }
                case EEntities.RecruitingActivity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            byRecruiting: {
                                ...state.activityChat.byRecruiting,
                                [actionCast.meta.entityId]: actionCast.payload,
                            },
                        },
                    };
                }
                case EEntities.VacancyActivity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            byVacancy: {
                                ...state.activityChat.byVacancy,
                                [actionCast.meta.entityId]: actionCast.payload,
                            },
                        },
                    };
                }
                case EEntities.CandidateActivity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            byCandidate: {
                                ...state.activityChat.byCandidate,
                                [actionCast.meta.entityId]: actionCast.payload,
                            },
                        },
                    };
                }
                case EEntities.SuggestionActivity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            bySuggestion: {
                                ...state.activityChat.bySuggestion,
                                [actionCast.meta.entityId]: actionCast.payload,
                            },
                        },
                    };
                }
                case EEntities.CompanyActivity: {
                    return {
                        ...state,
                        activityChat: {
                            ...state.activityChat,
                            byCompany: {
                                ...state.activityChat.byCompany,
                                [actionCast.meta.entityId]: actionCast.payload,
                            },
                        },
                    };
                }
            }
            return {
                ...state
            }
        }
        case ENTITIES_GET_ACTIVITY_LIST_FULFILLED: {
            const actionCast = action as IGetActivityListAction;
            const activitiesById = mapEntitiesToId(actionCast.payload.records);

            switch (actionCast.meta.activityEntity) {
                case EEntities.ActivityList: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            orderListView: Object.values(actionCast.payload.records).map((activity) => activity.id),
                            totalTimeOrderListView: actionCast.payload.totalTime
                        },
                    };
                }
                case EEntities.Activity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            order: Object.values(actionCast.payload.records).map((activity) => activity.id),
                            total: actionCast.payload.total,
                        },
                    };
                }
                case EEntities.SuggestionActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            bySuggestion: mapEntityIdsToSuggestionId(actionCast.payload.records),
                            totalBySuggestion: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.Application: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byApplications: mapEntityIdsToApplicationId(actionCast.payload.records),
                            totalByApplication: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.TodoActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byTodo: mapEntityIdsToTodoId(actionCast.payload.records),
                            totalByTodo: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.VacancyActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byVacancy: mapEntityIdsToVacancyId(actionCast.payload.records),
                            totalByVacancy: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.WebVacancyActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byWebVacancy: mapEntityIdsToWebVacancyId(actionCast.payload.records),
                            totalByWebVacancy: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.CompanyActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byCompany: mapEntityIdsToCompanyId(actionCast.payload.records),
                            totalByCompany: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.RecruitingActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byRecruiting: mapEntityIdsToRecruitingId(actionCast.payload.records),
                            totalByRecruiting: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
                case EEntities.CandidateActivity: {
                    return {
                        ...state,
                        activities: {
                            ...state.activities,
                            byId: {
                                ...state.activities.byId,
                                ...activitiesById,
                            },
                            byCandidate: mapEntityIdsToCandidateId(actionCast.payload.records),
                            totalByCandidate: {
                                [actionCast.meta.entityId]: actionCast.payload.total,
                            },
                        },
                    };
                }
            }

            return state;
        }
        case ENTITIES_DELETE_ACTIVITY_FULFILLED: {
            const actionCast = action as IDeleteActivityAction;
            const activityIdToDelete = actionCast.meta.activityId;
            const activitiesById = {...state.activities.byId};
            const activityData = activitiesById[activityIdToDelete];
            delete activitiesById[activityIdToDelete];
            const newState = {
                ...state,
                byId: activitiesById,
                order: state.activities.order.filter((activityId) => activityId !== activityIdToDelete),
            };

            if (!activityData) {
                return newState;
            }

            const candidateId = activityData.candidate ? activityData.candidate.id : 0;
            const candidateActivities = newState.activities.byCandidate[candidateId];
            if (candidateActivities) {
                newState.activities.byCandidate[candidateId] = candidateActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const recruitingId = activityData.recruiting ? activityData.recruiting.id : 0;
            const recruitingActivities = newState.activities.byRecruiting[recruitingId];
            if (recruitingActivities) {
                newState.activities.byRecruiting[recruitingId] = recruitingActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const companyId = activityData.company ? activityData.company.id : 0;
            const companyActivities = newState.activities.byCompany[companyId];
            if (companyActivities) {
                newState.activities.byCompany[companyId] = companyActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const vacancyId = activityData.vacancy ? activityData.vacancy.id : 0;
            const vacancyActivities = newState.activities.byVacancy[vacancyId];
            if (vacancyActivities) {
                newState.activities.byVacancy[vacancyId] = vacancyActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const webVacancyId = activityData.webVacancy ? activityData.webVacancy.id : 0;
            const webVacancyActivities = newState.activities.byWebVacancy[vacancyId];
            if (webVacancyActivities) {
                newState.activities.byWebVacancy[webVacancyId] = webVacancyActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const suggestionId = activityData.recruitingSuggestion ? activityData.recruitingSuggestion.id : 0;
            const suggestionActivities = newState.activities.bySuggestion[suggestionId];
            if (suggestionActivities) {
                newState.activities.bySuggestion[suggestionId] = suggestionActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            const todoId = activityData.todo ? activityData.todo.id : 0;
            const todoActivities = newState.activities.byTodo[todoId];
            if (todoActivities) {
                newState.activities.byTodo[todoId] = todoActivities.filter((activityId) => activityId !== activityIdToDelete);
            }

            return newState;
        }

        case ENTITIES_POST_ACTIVITY_FULFILLED: {
            const actionCast = action as IPostActivityAction;

            const today = Moment();
            const date = Moment(actionCast.payload.dateTime);
            const daysDifference = today.diff(date, 'days');
            const dateTime = daysDifference < 0 ? actionCast.payload.created : actionCast.payload.dateTime;

            const newState = {
                ...state,
            };

            if (actionCast.payload.candidate) {
                const candidateId = actionCast.payload.candidate.id;
                if (newState.candidates.listModelById[candidateId]) {
                    newState.candidates.listModelById[candidateId].lastActivity = dateTime;
                }
            }

            if (actionCast.payload.vacancy) {
                const vacancyId = actionCast.payload.vacancy.id;
                if (newState.vacancies.listModelById[vacancyId]) {
                    newState.vacancies.listModelById[vacancyId].lastActivity = dateTime;
                }
            }

            if (actionCast.payload.company) {
                const companyId = actionCast.payload.company.id;
                if (newState.companies.byId[companyId]) {
                    newState.companies.byId[companyId].lastActivity = dateTime;
                }
            }

            if (actionCast.payload.recruiting) {
                const recruitingId = actionCast.payload.recruiting.id;
                if (newState.recruiting.listModelById[recruitingId]) {
                    newState.recruiting.listModelById[recruitingId].lastActivity = dateTime;
                }
            }

            return newState;
        }

        case ENTITIES_PUT_ACTIVITY_FULFILLED: {
            const actionCast = action as IPutActivityAction;
            const newById = {
                ...state.activities.byId,
                [actionCast.payload.id]: actionCast.payload,
            };

            return {
                ...state,
                activities: {
                    ...state.activities,
                    byId: newById,
                },
            };
        }

        /**
         * INVOICE ENTITY
         */
        case ENTITIES_GET_INVOICE_LIST_FULFILLED: {
            const actionCast = action as IGetInvoiceListAction;
            const invoicesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((invoice) => invoice.id);

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    total: actionCast.payload.total,
                    sum: actionCast.payload.sum,
                    byId: {
                        ...state.invoice.byId,
                        ...invoicesById,
                    },
                    order,
                },
            };
        }
        case ENTITIES_GET_INVOICE_FULFILLED: {
            const actionCast = action as IGetInvoiceAction;

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    byId: {
                        ...state.invoice.byId,
                        [actionCast.payload.id]: actionCast.payload
                    }
                },
            };
        }

        case ENTITIES_DELETE_INVOICE_FULFILLED: {
            const actionCast = action as IDeleteCInvoiceAction;
            const candidateToDelete = actionCast.meta;
            const newById = {...state.invoice.byId};
            delete newById[candidateToDelete];

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    byId: newById,
                    total: state.invoice.total-1,
                    order: state.invoice.order.filter((candidateId) => candidateId !== candidateToDelete)
                },
            };
        }
        case ENTITIES_GET_INVOICE_LIST_OF_RECRUITING_FULFILLED: {
            const actionCast = action as IGetInvoiceListAction;
            const invoicesById = mapEntitiesToId(actionCast.payload.records);
            const invoicesByRecruiting = mapEntityIdsToRecruitingId(actionCast.payload.records);

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    byId: {
                        ...invoicesById,
                        ...state.invoice.byId,
                    },
                    byRecruiting: invoicesByRecruiting,
                },
            };
        }
        case ENTITIES_GET_INVOICE_LIST_OF_COMPANY_FULFILLED: {
            const actionCast = action as IGetInvoiceListAction;
            const invoicesById = mapEntitiesToId(actionCast.payload.records);
            const invoicesByCompany = mapEntityIdsToCompanyId(actionCast.payload.records);

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    byId: {
                        ...invoicesById,
                        ...state.invoice.byId,
                    },
                    byCompany: invoicesByCompany,
                },
            };
        }

        case ENTITIES_PUT_INVOICE_FULFILLED: {
            const actionCast = action as IPutInvoiceAction;

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    byId: {
                        ...state.invoice.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }

        /**
         * APPLICATION ENTITY
         */
        case ENTITIES_GET_APPLICATION_LIST_FULFILLED: {
            const actionCast = action as IGetApplicationListAction;
            const applicationsById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((candidateListModel) => candidateListModel.id);

            return {
                ...state,
                applications: {
                    ...state.applications,
                    total: actionCast.payload.total,
                    byId: {
                        ...state.applications.byId,
                        ...applicationsById,
                    },
                    order,
                },
            };
        }

        case ENTITIES_GET_APPLICATION_LIST_BY_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetApplicationListAction;
            const applicationsById = mapEntitiesToId(actionCast.payload.records);
            const applicationsByCandidate = mapEntityIdsToCandidateId(actionCast.payload.records);

            return {
                ...state,
                applications: {
                    ...state.applications,
                    byId: {
                        ...state.applications.byId,
                        ...applicationsById,
                    },
                    byCandidate: {
                        ...state.applications.byCandidate,
                        ...applicationsByCandidate
                    },
                    totalByCandidate: {
                        [actionCast.meta.candidateId as number]: actionCast.payload.total
                    },
                },
            };
        }

        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED: {
            const actionCast = action as IGetApplicationListAction;
            const applicationsById = mapEntitiesToId(actionCast.payload.records);
            const applicationsByVacancy = mapEntityIdsToVacancyId(actionCast.payload.records);

            return {
                ...state,
                applications: {
                    ...state.applications,
                    byId: {
                        ...state.applications.byId,
                        ...applicationsById,
                    },
                    byVacancy: {
                        ...state.applications.byVacancy,
                        ...applicationsByVacancy
                    },
                    totalByVacancy: {
                        [actionCast.meta.vacancyId as number]: actionCast.payload.total
                    },
                },
            };
        }

        case ENTITIES_GET_APPLICATION_LIST_BY_COMPANY_FULFILLED: {
            const actionCast = action as IGetApplicationListAction;
            const applicationsById = mapEntitiesToId(actionCast.payload.records);
            const applicationsByCompany = mapEntityIdsToCompanyId(actionCast.payload.records);

            return {
                ...state,
                applications: {
                    ...state.applications,
                    total: actionCast.payload.total,
                    byId: {
                        ...state.applications.byId,
                        ...applicationsById,
                    },
                    byCompany: applicationsByCompany,
                    totalByCompany: {
                        [actionCast.meta.companyId as number]: actionCast.payload.total
                    },
                },
            };
        }

        /***
         * CANDIDATE ENTITY
         */
        case ENTITIES_GET_CANDIDATE_LIST_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidatesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((candidateListModel) => candidateListModel.id);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    total: actionCast.payload.total,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...candidatesById,
                    },
                    order,
                },
            };
        }

        case ENTITIES_GET_CANDIDATE_LIST_APPEND_FULFILLED: {
            const actionCast = action as IGetCandidateListAppendAction;
            const candidatesById = mapEntitiesToId(actionCast.payload.records) as IEntitiesById<ICandidateListModel>;

            const newCandidatesById = {
                ...candidatesById,
                ...state.candidates.listModelById,
            };

            const newOrder = Object.values(actionCast.payload.records).map(
                (candidateListModel: ICandidateListModel) => candidateListModel.id,
            );

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    total: actionCast.payload.total,
                    listModelById: newCandidatesById,
                    order: [...state.candidates.order, ...newOrder],
                },
            };
        }

        case ENTITIES_POST_CANDIDATE_FULFILLED: {
            const actionCast = action as IPostCandidateAction;
            const order = state.candidates.order;
            order.unshift(actionCast.payload.id);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        [actionCast.payload.id]: candidateToListModel(actionCast.payload),
                    },
                    order,
                },
            };
        }

        case ENTITIES_DELETE_CANDIDATE_FULFILLED: {
            const actionCast = action as IDeleteCandidateAction;
            const candidateToDelete = actionCast.meta;
            const newById = {...state.candidates.listModelById};
            delete newById[candidateToDelete];

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: newById,
                    order: state.candidates.order.filter((candidateId) => candidateId !== candidateToDelete),
                    orderBirthday: state.candidates.orderBirthday.filter((candidateId) => candidateId !== candidateToDelete),
                    orderNew: state.candidates.orderNew.filter((candidateId) => candidateId !== candidateToDelete),
                    orderCreatedNew: state.candidates.orderCreatedNew.filter((candidateId) => candidateId !== candidateToDelete),
                },
            };
        }
        case ENTITIES_DELETE_CANDIDATE_PROFESSION_FULFILLED: {
            const actionCast = action as IDeleteCandidateProfessionAction;

            if (!state.candidates.byId[0]) {
                return {
                    ...state
                }
            }
            const professions = state.candidates.byId[0].professions;
            delete professions[actionCast.meta.indexToDelete];

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    currentRecord: {
                        ...state.candidates.currentRecord,
                        professions
                    }
                },
            };
        }
        case ENTITIES_POST_CANDIDATE_PROFESSION_FULFILLED:
        case ENTITIES_PUT_CANDIDATE_PROFESSION_FULFILLED: {
            const actionCast = action as IPostCandidateProfessionAction;

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    byId: {
                        ...state.candidates.byId,
                        [actionCast.payload.id]: actionCast.payload
                    },
                    listModelById: {
                        ...state.candidates.listModelById,
                        [actionCast.payload.id]: candidateToListModel(actionCast.payload, state.candidates.listModelById[actionCast.payload.id]),
                    },
                }
            }
        }
        case ENTITIES_PUT_CANDIDATE_FULFILLED: {
            const actionCast = action as IPutCandidateAction;
            const newState = {...state};

            // update candidate
            newState.candidates = {
                ...state.candidates,
                byId: {
                    ...state.candidates.byId,
                    [actionCast.payload.id]: actionCast.payload,
                },
                listModelById: {
                    ...state.candidates.listModelById,
                    [actionCast.payload.id]: candidateToListModel(actionCast.payload, state.candidates.listModelById[actionCast.payload.id]),
                },
            };

            return newState;
        }
        case ENTITIES_GET_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetCandidateAction;

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    byId: {
                        ...state.candidates.byId,
                        [actionCast.payload.id]: actionCast.meta.onlyUpdateCounts && state.candidates.byId[actionCast.payload.id] ? {
                            ...state.candidates.byId[actionCast.payload.id],
                            countDocuments: actionCast.payload.countDocuments,
                            countApplications: actionCast.payload.countApplications,
                            countApplicationsWithStatusOpen: actionCast.payload.countApplicationsWithStatusOpen,
                            countRecruitings: actionCast.payload.countRecruitings,
                            countRecruitingsWithStatusContract: actionCast.payload.countRecruitingsWithStatusContract,
                            countRecruitingsWithStatusOpen: actionCast.payload.countRecruitingsWithStatusOpen,
                            countSuggestions: actionCast.payload.countSuggestions,
                            countSuggestionsWithStatusOpenOpen: actionCast.payload.countSuggestionsWithStatusOpenOpen
                        } : actionCast.payload
                    }
                },
            };
        }
        case ENTITIES_GET_EXTERNAL_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetExternalCandidateAction;

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    externalCandidateInformation: actionCast.payload,
                },
            };
        }

        /**
         * CANDIDATE DOCUMENT ENTITY
         */
        case ENTITIES_GET_DOCUMENTS_FOR_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetCandidateDocumentsAction;
            const documentsById = mapEntitiesToId(actionCast.payload);
            const documentsByCandidate = mapEntityIdsToCandidateId(actionCast.payload);

            return {
                ...state,
                candidateDocuments: {
                    byId: documentsById,
                    byCandidate: documentsByCandidate,
                },
            };
        }
        case ENTITIES_DELETE_CANDIDATE_DOCUMENT_FULFILLED: {
            const actionCast = action as IDeleteCandidateDocumentAction;

            const documentIdToDelete = actionCast.meta.candidateDocumentId;
            const newById = {...state.candidateDocuments.byId};
            delete newById[documentIdToDelete];
            const newByCandidate = mapEntityIdsToCandidateId(Object.values(newById));

            return {
                ...state,
                candidateDocuments: {
                    byId: newById,
                    byCandidate: newByCandidate,
                },
            };
        }
        case ENTITIES_POST_CANDIDATE_DOCUMENT_FULFILLED: {
            const actionCast = action as IPostCandidateDocumentAction;
            const newState = {
                ...state,
            };

            actionCast.payload.forEach((candidateDocument: ICandidateDocument) => {
                newState.candidateDocuments.byId[candidateDocument.id] = candidateDocument;

                if (!newState.candidateDocuments.byCandidate[candidateDocument.candidate.id]) {
                    newState.candidateDocuments.byCandidate[candidateDocument.candidate.id] = [candidateDocument.id];
                } else {
                    newState.candidateDocuments.byCandidate[candidateDocument.candidate.id].unshift(candidateDocument.id);
                }

                const listItemCandidates = {
                    ...newState.candidates.listModelById[candidateDocument.candidate.id],
                };

                if (listItemCandidates) {
                    listItemCandidates.latestDocumentCvId = candidateDocument.id;

                    newState.candidates.listModelById = {
                        ...newState.candidates.listModelById,
                        [candidateDocument.candidate.id]: listItemCandidates,
                    };
                }
            });

            return newState;
        }
        case ENTITIES_PUT_CANDIDATE_DOCUMENT_FULFILLED: {
            const actionCast = action as IPutCandidateDocumentAction;
            const documentsById = {
                ...state.candidateDocuments.byId,
                [actionCast.payload.id]: actionCast.payload,
            };
            const documentsByCandidate = mapEntityIdsToCandidateId(Object.values(documentsById));

            return {
                ...state,
                candidateDocuments: {
                    byId: documentsById,
                    byCandidate: documentsByCandidate,
                },
            };
        }

        /**
         * COMPANY DOCUMENT ENTITY
         */
        case ENTITIES_GET_DOCUMENTS_FOR_COMPANY_FULFILLED: {
            const actionCast = action as IGetCompanyDocumentsAction;
            const documentsById = mapEntitiesToId(actionCast.payload);
            const documentsByCompany = mapEntityIdsToCompanyId(actionCast.payload);

            return {
                ...state,
                companyDocuments: {
                    byId: documentsById,
                    byCompany: documentsByCompany,
                },
            };
        }
        case ENTITIES_DELETE_COMPANY_DOCUMENT_FULFILLED: {
            const actionCast = action as IDeleteCompanyDocumentAction;

            const documentIdToDelete = actionCast.meta.companyDocumentId;
            const newById = {...state.companyDocuments.byId};
            delete newById[documentIdToDelete];
            const newByCompany = mapEntityIdsToCompanyId(Object.values(newById));

            return {
                ...state,
                companyDocuments: {
                    byId: newById,
                    byCompany: newByCompany,
                },
            };
        }
        case ENTITIES_POST_COMPANY_DOCUMENT_FULFILLED: {
            const actionCast = action as IPostCompanyDocumentAction;
            const newState = {
                ...state,
            };

            actionCast.payload.forEach((companyDocument: ICompanyDocument) => {
                newState.companyDocuments.byId[companyDocument.id] = companyDocument;

                if (!newState.companyDocuments.byCompany[companyDocument.company.id]) {
                    newState.companyDocuments.byCompany[companyDocument.company.id] = [companyDocument.id];
                } else {
                    newState.companyDocuments.byCompany[companyDocument.company.id].unshift(companyDocument.id);
                }
            });

            return newState;
        }
        case ENTITIES_PUT_COMPANY_DOCUMENT_FULFILLED: {
            const actionCast = action as IPutCompanyDocumentAction;
            const documentsById = {
                ...state.companyDocuments.byId,
                [actionCast.payload.id]: actionCast.payload,
            };
            const documentsByCompany = mapEntityIdsToCompanyId(Object.values(documentsById));

            return {
                ...state,
                companyDocuments: {
                    byId: documentsById,
                    byCompany: documentsByCompany,
                },
            };
        }

        /**
         * RECRUITING DOCUMENT ENTITY
         */
        case ENTITIES_GET_DOCUMENTS_FOR_RECRUITING_FULFILLED: {
            const actionCast = action as IGetRecruitingDocumentsAction;
            const documentsById = mapEntitiesToId(actionCast.payload);
            const documentsByRecruiting = mapEntityIdsToRecruitingId(actionCast.payload);

            return {
                ...state,
                recruitingDocuments: {
                    byId: documentsById,
                    byRecruiting: documentsByRecruiting,
                },
            };
        }
        case ENTITIES_DELETE_RECRUITING_DOCUMENT_FULFILLED: {
            const actionCast = action as IDeleteRecruitingDocumentAction;

            const documentIdToDelete = actionCast.meta.recruitingDocumentId;
            const newById = {...state.recruitingDocuments.byId};
            delete newById[documentIdToDelete];
            const newByRecruiting = mapEntityIdsToRecruitingId(Object.values(newById));

            return {
                ...state,
                recruitingDocuments: {
                    byId: newById,
                    byRecruiting: newByRecruiting,
                },
            };
        }
        case ENTITIES_POST_RECRUITING_DOCUMENT_FULFILLED: {
            const actionCast = action as IPostRecruitingDocumentAction;
            const newState = {
                ...state,
            };

            actionCast.payload.forEach((recruitingDocument: IRecruitingDocument) => {
                newState.recruitingDocuments.byId[recruitingDocument.id] = recruitingDocument;

                if (!newState.recruitingDocuments.byRecruiting[recruitingDocument.recruiting.id]) {
                    newState.recruitingDocuments.byRecruiting[recruitingDocument.recruiting.id] = [recruitingDocument.id];
                } else {
                    newState.recruitingDocuments.byRecruiting[recruitingDocument.recruiting.id].unshift(recruitingDocument.id);
                }
            });

            return newState;
        }
        case ENTITIES_PUT_RECRUITING_DOCUMENT_FULFILLED: {
            const actionCast = action as IPutRecruitingDocumentAction;
            const documentsById = {
                ...state.recruitingDocuments.byId,
                [actionCast.payload.id]: actionCast.payload,
            };
            const documentsByRecruiting = mapEntityIdsToRecruitingId(Object.values(documentsById));

            return {
                ...state,
                recruitingDocuments: {
                    byId: documentsById,
                    byRecruiting: documentsByRecruiting,
                },
            };
        }


        /**
         * VACANCY ENTITY
         */
        case ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED: {
            const actionCast = action as IGetVacancyListAction;
            const vacanciesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((vacancy) => vacancy.id);

            return {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    pinChanged: false,
                    totalOpen: actionCast.payload.total,
                    listModelById: {
                        ...state.vacancies.listModelById,
                        ...vacanciesById,
                    },
                    order: order,
                },
                recruiting: {
                    ...state.recruiting,
                    totalSuggest: actionCast.payload.totalRecruitingsStatusSuggested,
                    totalInterview: actionCast.payload.totalRecruitingsStatusInterview,
                    totalContract: actionCast.payload.totalRecruitingsStatusContract
                }
            };
        }
        case ENTITIES_GET_ARCHIVED_VACANCY_LIST_FULFILLED: {
            const actionCast = action as IGetVacancyListAction;
            const vacanciesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((vacancy) => vacancy.id);

            return {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    totalArchived: actionCast.payload.total,
                    listModelById: {
                        ...state.vacancies.listModelById,
                        ...vacanciesById,
                    },
                    orderArchived: order,
                },
            };
        }

        case ENTITIES_GET_VACANCY_LIST_FOR_CANDIDATE_SEARCH_FULFILLED: {
            const actionCast = action as IGetVacancyAndWebVacancyListForCandidateSearchAction;
            const vacanciesById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                vacancyAndWebVacancy: {
                    ...state.vacancyAndWebVacancy,
                    byId: {
                        ...state.vacancyAndWebVacancy.byId,
                        ...vacanciesById
                    },
                    orderCandidateSearch: Object.values(actionCast.payload.records).map((candidate) => candidate.id),
                    totalCandidateSearch: actionCast.payload.total
                },
            };
        }

        case ENTITIES_GET_VACANCY_LIST_OF_COMPANY_FULFILLED: {
            const actionCast = action as IGetVacancyListAction;
            const vacanciesById = mapEntitiesToId(actionCast.payload.records);
            const vacanciesByCompany = mapEntityIdsToCompanyId(actionCast.payload.records);

            return {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    listModelById: {
                        ...vacanciesById,
                        ...state.vacancies.listModelById,
                    },
                    byCompany: vacanciesByCompany,
                },
            };
        }

        case ENTITIES_POST_VACANCY_FULFILLED: {
            const actionCast = action as IPostVacancyAction;

            const vacancyListModel: IVacancyListModel = {
                ...actionCast.payload,
                countContracts: 0,
                countOpenFavorites: 0,
                countProcesses: 0,
                countAbortedProcesses: 0,
                countOpenProcesses: 0,
            };

            const newState = {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    byId: {
                        ...state.vacancies.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                    listModelById: {
                        ...state.vacancies.listModelById,
                        [actionCast.payload.id]: vacancyListModel
                    },
                },
            };

            if (actionCast.payload.webVacancy && newState.webVacancy.listModelById[actionCast.payload.webVacancy.id]) {
                newState.webVacancy.listModelById[actionCast.payload.webVacancy.id].vacancies = [actionCast.payload];
            }

            if (newState.vacancies.byCompany[actionCast.payload.company.id] === undefined) {
                newState.vacancies.byCompany[actionCast.payload.company.id] = [actionCast.payload.id];
            } else {
                newState.vacancies.byCompany[actionCast.payload.company.id].unshift(actionCast.payload.id);
            }

            newState.vacancies.order.unshift(actionCast.payload.id);

            return newState;
        }

        case ENTITIES_PUT_VACANCY_FULFILLED: {
            const actionCast = action as IPutVacancyAction;
            const vacancyListModel = state.vacancies.listModelById[actionCast.payload.id];
            const newState = {...state};

            if (vacancyListModel === undefined) {
                return  newState;
            }

            newState.vacancies = {
                ...newState.vacancies,
                byId: {
                    ...state.vacancies.byId,
                    [actionCast.payload.id]: actionCast.payload
                },
                listModelById: {
                    ...state.vacancies.listModelById,
                    [actionCast.payload.id]: {
                        ...state.vacancies.listModelById[actionCast.payload.id],
                        ...actionCast.payload
                    },
                },
            };

            //INS ARCHIV VERSCHOBEN
            if (!vacancyListModel.completed && actionCast.payload.completed) {
                newState.vacancies.totalArchived += 1;
                newState.vacancies.totalOpen -= 1;
                newState.vacancies.order = newState.vacancies.order.filter((vacancyId) => vacancyId !== actionCast.payload.id);
            }

            //WIEDER AKTIVIERT
            if (vacancyListModel.completed && !actionCast.payload.completed) {
                newState.vacancies.totalArchived -= 1;
                newState.vacancies.totalOpen += 1;
                newState.vacancies.orderArchived = newState.vacancies.orderArchived.filter((vacancyId) => vacancyId !== actionCast.payload.id);
                newState.vacancies.order.unshift(actionCast.payload.id);
            }

            if (vacancyListModel.pin !== actionCast.payload.pin) {
                newState.vacancies.pinChanged = true;
            }

            return newState;
        }
        case ENTITIES_DELETE_VACANCY_FULFILLED: {
            const actionCast = action as IDeleteVacancyAction;
            const vacanciesById = state.vacancies.listModelById;
            const vacancyToDelete = actionCast.meta;

            delete vacanciesById[vacancyToDelete];

            return {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    listModelById: vacanciesById,
                },
            };
        }
        case ENTITIES_GET_VACANCY_FULFILLED: {
            const actionCast = action as IGetVacancyAction;

            return {
                ...state,
                vacancies: {
                    ...state.vacancies,
                    byId: {
                        ...state.vacancies.byId,
                        [actionCast.payload.id]: actionCast.meta.onlyUpdateCounts && state.vacancies.byId[actionCast.payload.id] ? {
                            ...state.vacancies.byId[actionCast.payload.id],
                            countApplications: actionCast.payload.countApplications,
                            countApplicationsWithStatusOpen: actionCast.payload.countApplicationsWithStatusOpen,
                            countRecruitings: actionCast.payload.countRecruitings,
                            countRecruitingsWithStatusContract: actionCast.payload.countRecruitingsWithStatusContract,
                            countRecruitingsWithStatusOpen: actionCast.payload.countRecruitingsWithStatusOpen,
                            countSuggestions: actionCast.payload.countSuggestions,
                            countSuggestionsWithStatusOpen: actionCast.payload.countSuggestionsWithStatusOpen,
                            countSuggestionsWithStatusAccepted: actionCast.payload.countSuggestionsWithStatusAccepted,
                            countSuggestionsWithStatusDeclined: actionCast.payload.countSuggestionsWithStatusDeclined,
                        } : actionCast.payload
                    }
                },
            };
        }

        /**
         * RECRUITING SUGGESTION ENTITY
         */
        case ENTITIES_GET_SUGGESTION_FULFILLED: {
            const actionCast = action as IGetSuggestionAction;

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    byId: {
                        ...state.recruitingSuggestion.byId,
                        [actionCast.payload.id]: actionCast.payload
                    },
                },
            };
        }
        case ENTITIES_DELETE_SUGGESTION_FULFILLED: {
            const actionCast = action as IDeleteSuggestionAction;
            const suggestionsById = state.recruitingSuggestion.listModelById;
            const suggestionIdToDelete = actionCast.meta;
            delete suggestionsById[suggestionIdToDelete];

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: suggestionsById,
                    orderListView: state.recruitingSuggestion.orderListView.filter((companyId) => companyId !== suggestionIdToDelete),
                },
            };
        }
        case ENTITIES_GET_SUGGESTION_LIST_WITH_TOP_RATINGS_FULFILLED: {
            const actionCast = action as IGetSuggestionListAction;
            const suggestionsById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((suggestion) => suggestion.id);

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        ...suggestionsById,
                    },
                    orderTop: order,
                },
            };
        }

        case ENTITIES_GET_SUGGESTION_LIST_FULFILLED: {
            const actionCast = action as IGetSuggestionListAction;
            const suggestionsById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((suggestion) => suggestion.id);

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        ...suggestionsById,
                    },
                    orderListView: order,
                },
            };
        }

        case ENTITIES_GET_SUGGESTION_LIST_FOR_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetSuggestionListAction;
            const suggestionsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        ...suggestionsById,
                    },
                    byCandidate: {
                        ...state.recruitingSuggestion.byCandidate,
                        [actionCast.meta.candidateId as number]: actionCast.payload.records.map((suggestion) => suggestion.id),
                    },
                    totalByCandidate: {
                        [actionCast.meta.candidateId as number]: actionCast.payload.total
                    },
                },
            };
        }

        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED: {
            const actionCast = action as IGetSuggestionListAction;
            const suggestionsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        ...suggestionsById,
                    },
                    byVacancy: {
                        ...state.recruitingSuggestion.byVacancy,
                        [actionCast.meta.vacancyId as number]: actionCast.payload.records.map((suggestion) => suggestion.id),
                    },
                    totalByVacancy: {
                        [actionCast.meta.vacancyId as number]: actionCast.payload.total
                    },
                },
            };
        }

        case ENTITIES_POST_SUGGESTION_FULFILLED: {
            const actionCast = action as IPostSuggestionAction;

            const listModel: ISuggestionListModel = {
                ...actionCast.payload.recruitingSuggestion
            };

            const newState = {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    currentRecord: undefined,
                    byId: {
                        ...state.recruitingSuggestion.listModelById,
                        [actionCast.payload.recruitingSuggestion.id]: listModel
                    },
                },
            };

            if (newState.vacancies.listModelById[actionCast.payload.recruitingSuggestion.vacancy.id]) {
                newState.vacancies = {
                    ...state.vacancies,
                    listModelById: {
                        ...state.vacancies.listModelById,
                        [actionCast.payload.recruitingSuggestion.vacancy.id]: {
                            ...state.vacancies.listModelById[actionCast.payload.recruitingSuggestion.vacancy.id],
                            countOpenFavorites: state.vacancies.listModelById[actionCast.payload.recruitingSuggestion.vacancy.id].countOpenFavorites + 1,
                        },
                    },
                }
            }

            if (actionCast.payload.recruitingSuggestion.candidate && newState.candidates.listModelById[actionCast.payload.recruitingSuggestion.candidate.id]) {
                newState.candidates = {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        [actionCast.payload.recruitingSuggestion.candidate.id]: {
                            ...state.candidates.listModelById[actionCast.payload.recruitingSuggestion.candidate.id],
                            hasOpenSuggestion: true
                        }
                    }
                }
            }

            return newState;
        }

        case ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED: {
            const actionCast = action as IPutSuggestionAcceptAction;
            const vacancyId = actionCast.payload.recruitingSuggestion.vacancy.id;

            const listModel: ISuggestionListModel = {
                ...actionCast.payload.recruitingSuggestion
            };

            const newState = {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    byId: {
                        ...state.recruitingSuggestion.listModelById,
                        [actionCast.payload.recruitingSuggestion.id]: listModel
                    },
                },
            };

            if (newState.vacancies.listModelById[vacancyId]) {
                newState.vacancies.listModelById[vacancyId].countProcesses += 1;
            }

            return newState;
        }

        case ENTITIES_PUT_REOPEN_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED: {
            const actionCast = action as IPutSuggestionDeclineAction;

            const listModel: ISuggestionListModel = {
                ...actionCast.payload
            };

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        [actionCast.payload.id]: listModel
                    },
                },
            };
        }
        case ENTITIES_PUT_SUGGESTION_FULFILLED: {
            const actionCast = action as IPutSuggestionAction;

            const listModel: ISuggestionListModel = {
                ...state.recruitingSuggestion.listModelById[actionCast.payload.recruitingSuggestion.id],
                ...actionCast.payload.recruitingSuggestion
            };

            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    byId: {
                        ...state.recruitingSuggestion.byId,
                        [actionCast.payload.recruitingSuggestion.id]: actionCast.payload.recruitingSuggestion
                    },
                    listModelById: {
                        ...state.recruitingSuggestion.listModelById,
                        [actionCast.payload.recruitingSuggestion.id]: listModel
                    },
                },
            };
        }

        /**
         * COMPANY ENTITY
         */
        case ENTITIES_GET_COMPANY_LIST_FULFILLED: {
            const actionCast = action as IGetCompanyListAction;
            const companiesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((company) => company.id);

            return {
                ...state,
                companies: {
                    ...state.companies,
                    total: actionCast.payload.total,
                    byId: {
                        ...state.companies.byId,
                        ...companiesById,
                    },
                    [actionCast.meta ? 'order' : 'orderAll']: order,
                },
            };
        }
        case ENTITIES_DELETE_COMPANY_FULFILLED: {
            const actionCast = action as IDeleteCompanyAction;
            const companiesById = state.companies.byId;
            const companyToDelete = actionCast.meta;
            delete companiesById[companyToDelete];

            return {
                ...state,
                companies: {
                    ...state.companies,
                    total: state.companies.total - 1,
                    byId: companiesById,
                    order: state.companies.order.filter((companyId) => companyId !== companyToDelete),
                    orderAll: state.companies.orderAll.filter((companyId) => companyId !== companyToDelete),
                },
            };
        }
        case ENTITIES_GET_COMPANY_FULFILLED: {
            const actionCast = action as IGetCompanyAction;

            return {
                ...state,
                companies: {
                    ...state.companies,
                    byId: {
                        ...state.companies.byId,
                        [actionCast.payload.id]: actionCast.payload
                    }
                },
            };
        }
        case ENTITIES_POST_COMPANY_FULFILLED: {
            const actionCast = action as IPostCompanyAction;
            const order = state.companies.order;
            order.unshift(actionCast.payload.id);

            return {
                ...state,
                companies: {
                    ...state.companies,
                    byId: {
                        ...state.companies.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                    order,
                },
            };
        }
        case ENTITIES_PUT_COMPANY_FULFILLED: {
            const actionCast = action as IPutCompanyAction;

            return {
                ...state,
                companies: {
                    ...state.companies,
                    byId: {
                        ...state.companies.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }

        /**
         * COMPANY CONTACT ENTITY
         */
        case ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_COMPANY_FULFILLED: {
            const actionCast = action as IGetCompanyContactListAction;
            const contactsById = mapEntitiesToId(actionCast.payload);
            const contactsByCompanyID = mapEntityIdsToCompanyId(actionCast.payload);

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: {
                        ...state.companyContacts.byId,
                        ...contactsById,
                    },
                    byCompany: {
                        ...state.companyContacts.byCompany,
                        ...contactsByCompanyID,
                    },
                },
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_FULFILLED: {
            const actionCast = action as IGetCompanyContactAction;

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: {
                        ...state.companyContacts.byId,
                        [actionCast.payload.id]: actionCast.payload
                    }
                },
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_FOR_DROPDOWN_FULFILLED: {
            const actionCast = action as IGetCompanyContactsForCompanyDropdownAction;

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byCompanyForDropdown: {
                        ...state.companyContacts.byCompanyForDropdown,
                        [actionCast.meta.companyId]: actionCast.payload
                    },
                },
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITH_BIRTHDAY_FULFILLED: {
            const actionCast = action as IGetCompanyContactListAction;
            const contactsById = mapEntitiesToId(actionCast.payload);
            const order = Object.values(actionCast.payload).map((contact) => contact.id);

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: {
                        ...state.companyContacts.byId,
                        ...contactsById,
                    },
                    orderBirthday: order,
                },
            };
        }
        case ENTITIES_GET_COMPANY_CONTACT_LIST_WITHOUT_BIRTH_DATE_FULFILLED: {
            const actionCast = action as IGetCompanyContactListAction;
            const contactsById = mapEntitiesToId(actionCast.payload);
            const order = Object.values(actionCast.payload).map((contact) => contact.id);

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: {
                        ...state.companyContacts.byId,
                        ...contactsById,
                    },
                    orderNoBirthdate: order,
                },
            };
        }
        case ENTITIES_POST_COMPANY_CONTACT_FULFILLED: {
            const actionCast = action as IPostCompanyContactAction;

            const newState = {
                ...state,
            };

            if (newState.companyContacts.byCompany[actionCast.payload.company.id] === undefined) {
                newState.companyContacts.byCompany[actionCast.payload.company.id] = [actionCast.payload.id];
            } else {
                newState.companyContacts.byCompany[actionCast.payload.company.id].unshift(actionCast.payload.id);
            }

            if (newState.companyContacts.byCompanyForDropdown[actionCast.payload.company.id] === undefined) {
                newState.companyContacts.byCompanyForDropdown[actionCast.payload.company.id] = [actionCast.payload];
            } else {
                newState.companyContacts.byCompanyForDropdown[actionCast.payload.company.id].unshift(actionCast.payload);
            }

            newState.companyContacts.byId = {
                ...newState.companyContacts.byId,
                [actionCast.payload.id]: actionCast.payload,
            };

            return newState;
        }
        case ENTITIES_PUT_COMPANY_CONTACT_FULFILLED: {
            const actionCast = action as IPutCompanyContactAction;

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: {
                        ...state.companyContacts.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_DELETE_COMPANY_CONTACT_FULFILLED: {
            const actionCast = action as IDeleteCompanyContactAction;
            const contactsById = {...state.companyContacts.byId};
            const contactData = contactsById[actionCast.meta.contactId];
            const companyIdOfContact = contactData.company.id;
            const contactIdToDelete = actionCast.meta.contactId;

            delete contactsById[contactIdToDelete];

            const orderingOfCompany = state.companyContacts.byCompany[companyIdOfContact];
            const orderingOfCompanyInDropdown = state.companyContacts.byCompanyForDropdown[companyIdOfContact];

            return {
                ...state,
                companyContacts: {
                    ...state.companyContacts,
                    byId: contactsById,
                    orderNoBirthdate: state.companyContacts.orderNoBirthdate.filter((contactId) => contactId !== contactIdToDelete),
                    orderBirthday: state.companyContacts.orderBirthday.filter((contactId) => contactId !== contactIdToDelete),
                    byCompany: {
                        ...state.companyContacts.byCompany,
                        [companyIdOfContact]: orderingOfCompany ? orderingOfCompany.filter((contactId) => contactId !== contactIdToDelete) : [],
                    },
                    byCompanyForDropdown: {
                        ...state.companyContacts.byCompanyForDropdown,
                        [companyIdOfContact]: orderingOfCompanyInDropdown ? orderingOfCompanyInDropdown.filter((contactId) => contactId !== contactIdToDelete) : [],
                    },
                },
            };
        }

        /**
         * COMPANY LOCATION
         */
        case ENTITIES_GET_COMPANY_LOCATION_LIST_FULFILLED: {
            const actionCast = action as IGetCompanyLocationsAction;
            const companyLocationsById = mapEntitiesToId(actionCast.payload);
            const order = Object.values(actionCast.payload).map((location) => location.id);

            return {
                ...state,
                companyLocations: {
                    ...state.companyLocations,
                    byId: {
                        ...state.companyLocations.byId,
                        ...companyLocationsById,
                    },
                    order,
                },
            };
        }

        case ENTITIES_GET_LOCATIONS_FOR_COMPANY_DROPDOWN_FULFILLED: {
            const actionCast = action as IGetCompanyLocationsAction;
            const companyLocationsById = mapEntitiesToId(actionCast.payload);
            const locationsByCompanyID = mapEntityIdsToCompanyId(actionCast.payload);

            return {
                ...state,
                companyLocations: {
                    ...state.companyLocations,
                    byId: {
                        ...state.companyLocations.byId,
                        ...companyLocationsById,
                    },
                    byCompanyForDropdown: {
                        ...state.companyLocations.byCompanyForDropdown,
                        ...locationsByCompanyID,
                    },
                },
            };
        }

        case ENTITIES_GET_LOCATIONS_FOR_COMPANY_FULFILLED: {
            const actionCast = action as IGetCompanyLocationsAction;
            const locationsById = mapEntitiesToId(actionCast.payload);
            const locationsByCompanyID = mapEntityIdsToCompanyId(actionCast.payload);

            return {
                ...state,
                companyLocations: {
                    ...state.companyLocations,
                    byId: {
                        ...state.companyLocations.byId,
                        ...locationsById,
                    },
                    byCompany: {
                        ...state.companyLocations.byCompany,
                        ...locationsByCompanyID,
                    },
                },
            };
        }
        case ENTITIES_POST_COMPANY_LOCATION_FULFILLED: {
            const actionCast = action as IPostCompanyLocationAction;

            const newState = {
                ...state,
            };

            newState.companyLocations.order.unshift(actionCast.payload.id);

            if (newState.companyLocations.byCompany[actionCast.payload.company.id] === undefined) {
                newState.companyLocations.byCompany[actionCast.payload.company.id] = [actionCast.payload.id];
            } else {
                newState.companyLocations.byCompany[actionCast.payload.company.id].unshift(actionCast.payload.id);
            }

            if (newState.companyLocations.byCompanyForDropdown[actionCast.payload.company.id] === undefined) {
                newState.companyLocations.byCompanyForDropdown[actionCast.payload.company.id] = [actionCast.payload.id];
            } else {
                newState.companyLocations.byCompanyForDropdown[actionCast.payload.company.id].unshift(actionCast.payload.id);
            }

            newState.companyLocations.byId = {
                ...newState.companyLocations.byId,
                [actionCast.payload.id]: actionCast.payload,
            };

            return newState;
        }
        case ENTITIES_PUT_COMPANY_LOCATION_FULFILLED: {
            const actionCast = action as IPutCompanyLocationAction;

            return {
                ...state,
                companyLocations: {
                    ...state.companyLocations,
                    byId: {
                        ...state.companyLocations.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_DELETE_COMPANY_LOCATION_FULFILLED: {
            const actionCast = action as IDeleteCompanyLocationAction;
            const locationsById = {...state.companyLocations.byId};
            delete locationsById[actionCast.meta.locationId];
            const locationsByCompany = mapEntityIdsToCompanyId(Object.values(locationsById));

            return {
                ...state,
                companyLocations: {
                    ...state.companyLocations,
                    byId: locationsById,
                    byCompany: locationsByCompany,
                },
            };
        }

        /**
         * WEB VACANCY ENTITY
         */
        case ENTITIES_GET_WEB_VACANCY_FULFILLED: {
            const actionCast = action as IGetWebVacancyAction;

            return {
                ...state,
                webVacancy: {
                    ...state.webVacancy,
                    currentRecord: actionCast.payload,
                    byId: {
                        ...state.webVacancy.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_GET_WEB_VACANCY_LIST_FULFILLED: {
            const actionCast = action as IGetWebVacancyListAction;
            const byId = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((employee) => employee.id);

            return {
                ...state,
                webVacancy: {
                    ...state.webVacancy,
                    listModelById: {
                        ...state.webVacancy.listModelById,
                        ...byId,
                    },
                    order,
                    total: actionCast.payload.total,
                },
            };
        }

        case ENTITIES_GET_WEB_VACANCY_LIST_OF_COMPANY_FULFILLED: {
            const actionCast = action as IGetWebVacancyListAction;
            const byId = mapEntitiesToId(actionCast.payload.records);
            const byCompany = mapEntityIdsToCompanyId(actionCast.payload.records);

            return {
                ...state,
                webVacancy: {
                    ...state.webVacancy,
                    byCompany: {
                        ...state.webVacancy.byCompany,
                        ...byCompany,
                    },
                    listModelById: {
                        ...state.webVacancy.listModelById,
                        ...byId,
                    },
                    total: actionCast.payload.total,
                },
            };
        }

        /**
         * EMPLOYEE ENTITY
         */
        case ENTITIES_POST_EMPLOYEE_FULFILLED: {
            const actionCast = action as IPostEmployeeAction;
            const order = state.employees.order;
            order.unshift(actionCast.payload.id);

            return {
                ...state,
                employees: {
                    ...state.employees,
                    byId: {
                        ...state.employees.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                    order,
                },
            };
        }
        case ENTITIES_PUT_EMPLOYEE_FULFILLED: {
            const actionCast = action as IPutEmployeeAction;

            return {
                ...state,
                employees: {
                    ...state.employees,
                    currentRecord: undefined,
                    byId: {
                        ...state.employees.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_GET_EMPLOYEE_LIST_FULFILLED: {
            const actionCast = action as IGetEmployeeListAction;
            const employeesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((employee) => employee.id);

            return {
                ...state,
                employees: {
                    byId: employeesById,
                    order,
                    total: actionCast.payload.total,
                },
            };
        }

        /**
         * JOB TEMPLATE ENTITY
         */
        case JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT: {
            const actionCast = action as ISetJobTemplatePageTemplateToEditAction;

            if (actionCast.payload === 0) {
                return {
                    ...state,
                    jobTemplate: {
                        ...state.jobTemplate,
                        currentRecord: undefined,
                    },
                };
            }

            return {
                ...state,
            };
        }
        case ENTITIES_GET_JOB_TEMPLATE_FULFILLED: {
            const actionCast = action as IGetJobTemplateAction;

            return {
                ...state,
                jobTemplate: {
                    ...state.jobTemplate,
                    currentRecord: actionCast.payload,
                },
            };
        }
        case ENTITIES_POST_JOB_TEMPLATE_FULFILLED: {
            const actionCast = action as IPostJobTemplateAction;
            const order = state.jobTemplate.order;
            order.unshift(actionCast.payload.id);

            return {
                ...state,
                jobTemplate: {
                    ...state.jobTemplate,
                    byId: {
                        ...state.jobTemplate.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                    order,
                },
            };
        }
        case ENTITIES_PUT_JOB_TEMPLATE_FULFILLED: {
            const actionCast = action as IPutJobTemplateAction;

            return {
                ...state,
                jobTemplate: {
                    ...state.jobTemplate,
                    currentRecord: undefined,
                    byId: {
                        ...state.jobTemplate.byId,
                        [actionCast.payload.id]: actionCast.payload,
                    },
                },
            };
        }
        case ENTITIES_GET_JOB_TITLE_LIST_FULFILLED: {
            const actionCast = action as IGetJobTitleListAction;
            const jobTitlesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((jobTitle) => jobTitle.id);

            return {
                ...state,
                jobTitle: {
                    ...state.jobTitle,
                    byId: jobTitlesById,
                    order,
                    total: actionCast.payload.total,
                },
            };
        }
        case ENTITIES_GET_JOB_TEMPLATE_LIST_FULFILLED: {
            const actionCast = action as IGetJobTemplateListAction;
            const jobTemplatesById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((jobTemplate) => jobTemplate.id);

            return {
                ...state,
                jobTemplate: {
                    ...state.jobTemplate,
                    byId: jobTemplatesById,
                    order,
                    total: actionCast.payload.total,
                },
            };
        }
        case ENTITIES_GET_JOB_TEMPLATE_NAME_LIST_FULFILLED: {
            const actionCast = action as IGetJobTemplateNameListAction;

            return {
                ...state,
                jobTemplate: {
                    ...state.jobTemplate,
                    names: actionCast.payload,
                },
            };
        }
        /**
         * BRANCHES ENTITY
         */
        case ENTITIES_GET_BRANCH_LIST_FULFILLED: {
            const actionCast = action as IGetBrancheListAction;
            const branchesByid = mapEntitiesToId(actionCast.payload);

            return {
                ...state,
                branches: {
                    byId: branchesByid,
                    array: actionCast.payload,
                },
            };
        }

        /**
         * SKILLS ENTITY
         */
        case SKILL_PAGE_SET_SKILL_TO_EDIT: {
            const actionCast = action as ISetSkillPageSkillToEditAction;

            if (actionCast.payload === 0) {
                return {
                    ...state,
                    skills: {
                        ...state.skills,
                        currentRecord: undefined
                    },
                };
            }
            return {
                ...state,
            };
        }
        case SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT: {
            const actionCast = action as ISetSkillPageExternalSkillToEditAction;

            if (actionCast.payload === 0) {
                return {
                    ...state,
                    skills: {
                        ...state.skills,
                        currentExternalRecord: undefined
                    },
                };
            }
            return {
                ...state,
            };
        }
        case ENTITIES_GET_EXTERNAL_SKILL_FULFILLED: {
            const actionCast = action as IGetExternalSkillAction;

            return {
                ...state,
                skills: {
                    ...state.skills,
                    currentExternalRecord: actionCast.payload
                },
            };
        }
        case ENTITIES_GET_SKILL_FULFILLED: {
            const actionCast = action as IGetSkillAction;

            return {
                ...state,
                skills: {
                    ...state.skills,
                    currentRecord: actionCast.payload
                },
            };
        }
        case ENTITIES_GET_SKILL_LIST_FULFILLED: {
            const actionCast = action as IGetSkillListAction;


            return {
                ...state,
                skills: {
                    ...state.skills,
                    array: actionCast.payload,
                },
            };
        }
        case ENTITIES_GET_SKILL_TREE_FULFILLED: {
            const actionCast = action as IGetSkillTreeAction;

            return {
                ...state,
                skills: {
                    ...state.skills,
                    tree: actionCast.payload.tree,
                    treeTotal: actionCast.payload.total
                },
            };
        }
        case ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED: {
            return {
                ...state,
                recruitingSuggestion: {
                    ...state.recruitingSuggestion,
                    currentRecord: undefined
                }
            };
        }
        case ENTITIES_PUT_SKILL_FULFILLED: {
            const actionCast = action as IPutSkillAction;

            const updateItem = (node: ISkillTreeNode): ISkillTreeNode => {
                if (node.id === actionCast.payload.id) {
                    return {
                        ...node,
                        text: actionCast.payload.name,
                        hasKeywords: actionCast.payload.keywords !== '' && actionCast.payload.keywords !== null,
                        countUsed: actionCast.payload.usedByCandidates + actionCast.payload.usedByVacancies,
                        keywords: actionCast.payload.keywords
                    }
                }

                return {
                    ...node,
                    children: node.children.map((node) => updateItem(node))
                };
            }

            return {
                ...state,
                skills: {
                    ...state.skills,
                    currentRecord: actionCast.meta.closeEditView ? undefined : state.skills.currentRecord,
                    array: [
                        ...state.skills.array,
                        actionCast.payload,
                    ],
                    tree: [
                        ...state.skills.tree.map((item) => updateItem(item))
                    ]
                },
            };
        }
        case ENTITIES_POST_SKILL_FULFILLED: {
            const actionCast = action as IPostSkillAction;

            return {
                ...state,
                skills: {
                    ...state.skills,
                    currentRecord: undefined,
                    array: [
                        ...state.skills.array,
                        actionCast.payload,
                    ],
                },
            };
        }

        /**
         * RECRUITING ENTITY
         */
        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);
            const recruitingsByVacancy = mapEntityIdsToVacancyId(actionCast.payload.records);

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    byVacancy: {
                        ...state.recruiting.byVacancy,
                        ...recruitingsByVacancy
                    },
                    totalByVacancy: {
                        [actionCast.meta?.vacancyId as number]: actionCast.payload.total
                    },
                },
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_OF_LAST_7_DAYS_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    orderLast7Days: Object.values(actionCast.payload.records).map((recruiting) => recruiting.id),
                },
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_FOR_CHART_SELECTION_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    orderChartSelection: Object.values(actionCast.payload.records).map((recruiting) => recruiting.id),
                },
            };
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_COMPANY_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);
            const recruitingsByCompany = mapEntityIdsToCompanyId(actionCast.payload.records);

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    totalByCompany: {
                        [actionCast.meta.companyId as number]: actionCast.payload.total
                    },
                    byCompany: recruitingsByCompany,
                },
            };
        }

        case ENTITIES_GET_RECRUITING_LIST_BY_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);
            const recruitingsByCandidate = mapEntityIdsToCandidateId(actionCast.payload.records);

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    totalByCandidate: {
                        [actionCast.meta.candidateId as number]: actionCast.payload.total
                    },
                    byCandidate: recruitingsByCandidate,
                },
            };
        }

        case ENTITIES_GET_RECRUITING_LIST_FULFILLED: {
            const actionCast = action as IGetRecruitingListAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);
            const order = Object.values(actionCast.payload.records).map((recruiting) => recruiting.id);

            const status = actionCast.meta?.status;
            let orderKey = 'orderListView';
            let totalKey = 'totalListView';

            switch (status) {
                case ERecruitingStatus.STATUS_SUGGEST:
                    orderKey = 'orderSuggest';
                    totalKey = 'totalSuggest';
                    break;
                case ERecruitingStatus.STATUS_INTERVIEW:
                    orderKey = 'orderInterview';
                    totalKey = 'totalInterview';
                    break;
                case ERecruitingStatus.STATUS_CONTRACT:
                    orderKey = 'orderContract';
                    totalKey = 'totalContract';
                    break;
                case ERecruitingStatus.STATUS_ABORT:
                    orderKey = 'orderAbort';
                    totalKey = 'totalAbort';
                    break;
                case ERecruitingStatus.STATUS_FINISH:
                    orderKey = 'orderFinish';
                    totalKey = 'totalFinish';
                    break;
                case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
                    orderKey = 'orderTrialPeriodFailed';
                    totalKey = 'totalTrialPeriodFailed';
                    break;
            }

            const returnState = {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    listModelById: {
                        ...state.recruiting.listModelById,
                        ...recruitingsById,
                    },
                    [orderKey]: order,
                    [totalKey]: actionCast.payload.total,
                },
            };

            if (status === ERecruitingStatus.STATUS_CONTRACT) {
                returnState.recruiting.openCommissionSumContract = actionCast.payload.openCommissionSum;
            }

            return returnState;
        }

        case ENTITIES_GET_RECRUITING_LIST_BY_EMPLOYEE_FULFILLED: {
            const actionCast = action as IGetRecruitingListOfEmployeeAction;
            const recruitingsById = mapEntitiesToId(actionCast.payload.records);
            const recruitingsByEmployee = mapEntityIdsToEmployeeId(actionCast.payload.records);

            return {
                ...state,
                employeeRecruiting: {
                    ...state.employeeRecruiting,
                    byId: recruitingsById,
                    byEmployee: recruitingsByEmployee,
                },
            };
        }

        case ENTITIES_GET_RECRUITING_FULFILLED: {
            const actionCast = action as IGetRecruitingAction;

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    byId: {
                        ...state.recruiting.byId,
                        [actionCast.payload.id]: actionCast.payload
                    },
                },
            };
        }

        case ENTITIES_POST_RECRUITING_FULFILLED: {
            const actionCast = action as IPostRecruitingAction;
            const recruiting = actionCast.payload.recruiting;
            const vacancyId = recruiting.vacancy ? recruiting.vacancy.id : 0;
            const candidateId = recruiting.candidate.id;

            const newState = {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    byId: {
                        ...state.recruiting.listModelById,
                        [recruiting.id]: recruiting,
                    },
                },
            };

            if (vacancyId > 0 && newState.vacancies.listModelById[vacancyId]) {
                newState.vacancies.listModelById[vacancyId].countProcesses += 1;
            }

            const application = actionCast.payload.recruiting.application;
            if (application) {
                newState.applications.byId[application.id] = application;
            }

            if (newState.candidates.orderNew.indexOf(candidateId) > -1) {
                const index = state.candidates.orderNew.indexOf(candidateId);
                delete newState.candidates.orderNew[index];
            }

            if (newState.candidates.listModelById[actionCast.payload.recruiting.candidate.id]) {
                newState.candidates = {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        [actionCast.payload.recruiting.candidate.id]: {
                            ...state.candidates.listModelById[actionCast.payload.recruiting.candidate.id],
                            hasOpenRecruiting: true
                        }
                    }
                }
            }

            return newState;
        }
        case ENTITIES_PUT_RECRUITING_FULFILLED: {
            const actionCast = action as IPutRecruitingAction;
            const recruiting = actionCast.payload.recruiting;
            const recruitingBefore = state.recruiting.listModelById[recruiting.id];

            const newState = {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    byId: {
                        ...state.recruiting.listModelById,
                        [recruiting.id]: recruiting,
                    },
                },
            };

            if (recruitingBefore && recruitingBefore.status !== recruiting.status) {
                if (recruiting.status === ERecruitingStatus.STATUS_CONTRACT) {
                    newState.recruiting.statusChangedToContract = true;
                }
                if (recruiting.status === ERecruitingStatus.STATUS_ABORT) {
                    newState.recruiting.statusChangedToAbort = true;
                }
            }

            return newState;
        }

        case ENTITIES_DELETE_RECRUITING_FULFILLED: {
            const actionCast = action as IDeleteRecruitingAction;
            const recruitingToDelete = actionCast.meta;
            const newById = {...state.recruiting.listModelById};
            const recruitingData = newById[recruitingToDelete];
            delete newById[recruitingToDelete];

            const newState = {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    byId: newById,
                    orderContract: state.recruiting.orderContract.filter((recruitingId) => recruitingId !== recruitingToDelete),
                    orderAbort: state.recruiting.orderAbort.filter((recruitingId) => recruitingId !== recruitingToDelete),
                    orderInterview: state.recruiting.orderInterview.filter((recruitingId) => recruitingId !== recruitingToDelete),
                    orderSuggest: state.recruiting.orderSuggest.filter((recruitingId) => recruitingId !== recruitingToDelete),
                },
            };

            const vacancyId = recruitingData.vacancy ? recruitingData.vacancy.id : 0;
            const vacancyRecruitings = newState.recruiting.byVacancy[vacancyId];
            if (vacancyRecruitings) {
                newState.recruiting.byVacancy[vacancyId] = vacancyRecruitings.filter((recruitingId) => recruitingId !== recruitingToDelete);
            }

            const candidateRecruitings = newState.recruiting.byVacancy[recruitingData.candidate.id];
            if (candidateRecruitings) {
                newState.recruiting.byCandidate[recruitingData.candidate.id] = candidateRecruitings.filter((recruitingId) => recruitingId !== recruitingToDelete);
            }

            const companyRecruitings = newState.recruiting.byCompany[recruitingData.candidate.id];
            if (companyRecruitings) {
                newState.recruiting.byCompany[recruitingData.company.id] = companyRecruitings.filter((recruitingId) => recruitingId !== recruitingToDelete);
            }

            return newState;
        }

        /**
         * RESET CURRENT EDIT RECORD
         */
        case ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT: {
            const actionCast = action as ISetAdministrationPageEmployeeToEditAction;

            if (actionCast.payload === 0) {
                return {
                    ...state,
                    employees: {
                        ...state.employees,
                        currentRecord: undefined,
                    },
                };
            }

            return state;
        }
        case CANDIDATE_PAGE_SET_ADD_DIALOG: {
            const actionCast = action as ISetCandidatePageAddDialogAction;

            if (!actionCast.payload) {
                return {
                    ...state,
                    candidates: {
                        ...state.candidates,
                        externalCandidateInformation: undefined,
                    },
                };
            }

            return {
                ...state,
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_OPEN_APPLICATION_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidates = actionCast.payload.records;
            const byId = mapEntitiesToId(candidates);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...byId,
                    },
                    orderNew: Object.values(candidates).map((candidate) => candidate.id),
                },
            };
        }
        case ENTITIES_GET_CANDIDATES_OF_WEB_VACANCY_SEARCH_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidates = actionCast.payload.records;
            const byId = mapEntitiesToId(candidates);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...byId,
                    },
                    orderWebVacancySearch: Object.values(candidates).map((candidate) => candidate.id),
                    totalWebVacancySearch: actionCast.payload.total
                },
            };
        }
        case ENTITIES_GET_CANDIDATES_OF_VACANCY_SEARCH_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidates = actionCast.payload.records;
            const byId = mapEntitiesToId(candidates);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...byId,
                    },
                    orderVacancySearch: Object.values(candidates).map((candidate) => candidate.id),
                    totalVacancySearch: actionCast.payload.total
                },
            };
        }
        case ENTITIES_GET_CANDIDATES_CREATED_NEW_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidates = actionCast.payload.records;
            const byId = mapEntitiesToId(candidates);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...byId,
                    },
                    orderCreatedNew: Object.values(candidates).map((candidate) => candidate.id),
                },
            };
        }
        case ENTITIES_GET_CANDIDATES_WITH_BIRTHDAY_FULFILLED: {
            const actionCast = action as IGetCandidateListAction;
            const candidates = actionCast.payload.records;
            const byId = mapEntitiesToId(candidates);

            return {
                ...state,
                candidates: {
                    ...state.candidates,
                    listModelById: {
                        ...state.candidates.listModelById,
                        ...byId,
                    },
                    orderBirthday: Object.values(candidates).map((candidate) => candidate.id),
                },
            };
        }
        default:
            break;
    }

    return state;
};

export default entitiesReducer;
