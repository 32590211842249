import {Workbox} from 'workbox-window';
import {EStorageItem} from "../definitions/app.definitions";

async function isPushNotificationSubscribed() {
    let swReg;
    if (isPushNotificationAvailable()) {
        swReg = await navigator.serviceWorker.getRegistration();
    }
    return (swReg !== undefined);
}

export default async function askForPushNotification() {
    if (!isPushNotificationAvailable()) {
        return;
    }

    if (window.Notification.permission === "default") {
        await window.Notification.requestPermission();
    }
    if (Notification.permission === 'granted') {
        //maybe check and save subscription
    }
}

function isPushNotificationAvailable() {
    if (window.isSecureContext) {
        // running in secure context - check for available Push-API
        return (('serviceWorker' in navigator) &&
            ('PushManager' in window) &&
            ('Notification' in window));
    }
    return false;
}

export function registerServiceWorker() {
    const wb = new Workbox('sw.js');
    wb.addEventListener('installed', event => {
        if (event.isUpdate) {
            console.log("SW UPDATED");
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
            });
        }
    });
    wb.addEventListener('message' , event => {
        const { action } = event.data;
        const port = event.ports[0];

        if(action === 'getAuthTokenHeader') {
            port.postMessage({
                authHeader: localStorage.getItem(EStorageItem.Token)
            })
        } else {
            port.postMessage({
                error: 'Unknown request',
            })
        }
    })
    wb.register();
}
