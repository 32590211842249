import React from 'react';
import { useCandidateSuggestionListHook } from './CandidateSuggestionList.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestionsForCandidateAction } from '../../../../../redux/entities/entities.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import SuggestionList from '../../../../Shared/SuggestionList/SuggestionList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    candidateId: number;
}

const CandidateSuggestionList: React.FC<IProps> = (props) => {
    const candidateId = props.candidateId;
    const { loading, suggestions, total} = useCandidateSuggestionListHook(candidateId);
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    return <>
        <SuggestionList
            suggestions={suggestions}
            total={total}
            loading={loading}
            parentView={EEntityView.candidate}
            onReload={() => {
                dispatch(getSuggestionsForCandidateAction(token, candidateId));
            }}
        />
    </>
}

export default React.memo(CandidateSuggestionList);
