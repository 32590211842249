import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setApplicationPageFastSearchAction } from '../../../../redux/applicationPage/applicationPage.actions';

const FastSearchPanelApplicationPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchApplications = useSelector((store: IStore) => store.applicationPage.filter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Application,
        value: fastSearchApplications ?? '',
        emptyValue: translate('pages.candidate.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setApplicationPageFastSearchAction(value)),
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelApplicationPage;
