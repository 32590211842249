import React from 'react';
import { IBranch } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { useLoadBranches } from './BranchAutocompleteDropDown.hook';
import CustomMultipleAutocompleteDropDown from './CustomMultipleAutocompleteDropDown';

interface IProps {
    onChange: (value: any) => void;
    value?: IBranch[];
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const BranchAutocompleteDropDown: React.FC<IProps> = (props) => {
    const branches = useLoadBranches();

    return (
        <CustomMultipleAutocompleteDropDown
            value={props.value}
            options={branches}
            getOptionLabel={(value: IBranch) => value.name}
            onChange={props.onChange}
            error={props.error}
            helperText={props.helperText}
            label={translate('pages.company.properties.branches')}
        />
    );
};

export default BranchAutocompleteDropDown;
