import { ERoutes } from '../definitions/app.definitions';
import { translate } from '../translation/translate.utils';
import { ERecruitingPageTabs } from '../definitions/recruitingPage.definitions';

export const getHeaderBarTitle = (recruitingPageTab: ERecruitingPageTabs, route?: string) => {
    if (!route) {
        return null;
    }

    switch ('/' + route.replace(/^\/([^\\/]*).*$/, '$1')) {
        case ERoutes.Companies:
        case ERoutes.Company:
            return translate('pages.company.title');
        case ERoutes.Dashboard:
            return translate('pages.dashboard.title');
        case ERoutes.Candidates:
        case ERoutes.Candidate:
            return translate('pages.candidate.title');
        case ERoutes.ActivityOverview:
            return translate('pages.activityOverview.title');
        case ERoutes.Recruiting:
        case ERoutes.Vacancy:
        case ERoutes.Recruitings: {
            if (recruitingPageTab === ERecruitingPageTabs.tabVacancies) {
                return translate('pages.vacancy.title');
            }
            return translate('pages.recruiting.title');
        }
        case ERoutes.Applications: {
            return translate('pages.application.title');
        }
        case ERoutes.Billing:
            return translate('pages.billing.title');
        case ERoutes.Profile:
            return translate('pages.profile.title');
        case ERoutes.Archive:
            return translate('pages.archive.archive');
        case ERoutes.Administration:
            return translate('pages.administration.title');
        case ERoutes.WebVacancies:
        case ERoutes.WebVacancy:
            return translate('pages.webVacancy.title');
        case ERoutes.Analyse:
            return translate('pages.analytics.pageTitle');
        case ERoutes.Calendar:
            return translate('pages.calendar.title');
        default:
            return "CHECK headerBar.utils.tsx";
    }
};
