import { Button, createStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { COLOR_TEXT, COLOR_TEXT_SECONDARY, darkest } from '../../theme/theme';

interface IProps {
    onClick: VoidFunction;
    label?: string;
    icon?: EIcons;
    color?: "primary" | "secondary";
    withoutPadding?: boolean;
    tooltip?: string;
    disabled?: boolean;
    fullWidth?: boolean;
}

const CustomButton: React.FC<IProps> = (props) => {
    const fullWidth = props.fullWidth;
    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                height: 56,
                backgroundColor: COLOR_TEXT,
                "&:hover": {
                    backgroundColor: COLOR_TEXT_SECONDARY,
                },
            },
            secondary: {
                height: 56,
                backgroundColor: theme.palette.secondary.main
            },
        })
    );
    const styles = useStyles();
    const color = props.color || "primary";
    let styleToUse = styles.root;

    if (color === "secondary") {
        styleToUse = styles.secondary;
    }

    const button = (
        <Button
            className={styleToUse}
            disabled={props.disabled}
            onClick={props.onClick}
            variant="contained"
            size={"small"}
            color={color}
            style={fullWidth ? {width: '100%'} : undefined}
        >
            {props.icon ? IconFactory.getIconComponent(props.icon, {color: darkest}) : ""}
            {props.label ? props.label : ""}
        </Button>
    );

    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>
                <div>{button}</div>
            </Tooltip>
        );
    }
    return button;
};

export default CustomButton;
