import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getAllSkillsAction } from '../../../../redux/entities/entities.actions';

export const useLoadSkills = () => {
    const token = useSelector(getToken);
    const skillsLoaded = useSelector((store: IStore) => store.ui.initialLoaded.skills);
    const skills = useSelector((store: IStore) => store.entities.skills.array);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!skillsLoaded) {
            dispatch(getAllSkillsAction(token))
        }
    }, [skillsLoaded, token, dispatch]);

    return skills;
}
