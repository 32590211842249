import { IApiBaseFilter, ISorting } from './app.definitions';
import {
    ECandidateLevel,
    ECandidateSource,
    EEducationLevel,
    EHomeOfficeDesire,
    ICandidateLanguage,
    ICandidateSkill,
    ICompanyLocation,
    ICompanyLocationPartial,
    ICvParseResult,
    IEmployeePartial,
    ILanguage,
    ISkill,
    IVacancyPartial,
    TPartTimeFullTime,
    TSalutation,
} from './entities.definition';
import { ESortingOptions } from './components.definitions';
import { IRecruitingFilter, IRecruitingFilterMenu } from './recruitingPage.definitions';
import { FileObject } from 'material-ui-dropzone';
import { IWebVacancyFilter, IWebVacancyFilterMenu } from './webVacancyPage.definitions';
import {
    EDateRangeMode,
    IDateRangeFilterApiProperties,
    IRecruitingInformationFilterApiProperties,
    IRecruitingInformationFilterMenu,
} from './filter.definitions';

export const preloadedCandidatePageDateRangeFilter: IDateRangeFilterApiProperties = {
    property: 'created',
    mode: EDateRangeMode.all,
    dateFrom: undefined,
    dateTo: undefined,
    lastDateRangeCount: undefined
}

export const preloadedCandidatePageFilter: ICandidateFilterApiProperties = {
    start: 0,
    limit: 50,
    skillIds: [],
    languageIds: [],
    optionalSkillIds: [],
    dateRangeFilter: preloadedCandidatePageDateRangeFilter,
    homeOfficeDesire: []
}

export const preloaededCandidatePageFilterMenu: ICandidateFilterMenu = {
    skills: [],
    optionalSkills: [],
    languages: [],
    onlyFollowups: false,
    responsibleEmployee: null,
    dateRangeFilter: preloadedCandidatePageDateRangeFilter,
    homeOfficeDesire: []
}

export const preloadedStateCandidatePage: ICandidatePageState = {
    reloadList: false,
    formFieldsIgnoredForValidation: [],
    recruitingSorting: {
        'created': ESortingOptions.Descending
    },
    initialCandidatesLoaded: false,
    editViewLoading: false,
    sorting: {
        'candidate.created': ESortingOptions.Descending
    },
    filter: preloadedCandidatePageFilter,
    filterMenu: preloaededCandidatePageFilterMenu,
    birthdayWishMissing: false,
    addDialogOpen: false,
    candidateToDelete: 0,
    currentDocumentId: 0,
    documentSorting: {},
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    activityToDelete: 0,
    filterRecruitingList: {
        start: 0,
        limit: 50,
    },
    filterMenuRecruitingList: {},
    activityFilter: {
        start: 0,
        limit: 50
    },
    sortingVacancyList: {
        'created': ESortingOptions.Descending
    },
    filterVacancyList: {
        start: 0,
        limit: 50,
        showHidden: false
    },
    filterMenuVacancyList: {
        showHidden: false
    },
    createBirthdayMailForCandidate: 0
};

export enum ECandidateEditViewTabs {
    data,
    professions,
    vacancySearch,
    recruitingList,
    activityList,
    documentList
}

export interface ICandidateForm {
    name: string,
    firstName: string,
    address: string,
    freelancer: boolean,
    zipCode: string,
    city: string,
    salutation: TSalutation,
    title: string,
    mailAddress: string,
    xingUrl: null|string,
    linkedInUrl: null|string,
    websiteUrl: null|string,
    githubUrl: null|string,
    furtherUrl: null|string,
    phoneNumber: string,
    mobileNumber: string,
    birthDate: null|string,
    countryId: null|number,
    salaryUnit: 'year'|'month',
    expectedSalary: number,
    expectedSalaryTo: number,
    homeOfficeDesire: EHomeOfficeDesire|null,
    noticePeriod: null|string,
    leader: boolean,
    yearsOfWorkExperience: null|string,
    desiredJobDescription: string,
    currentJobDescription: string,
    hasDriverLicense?: boolean,
    wishDateOfStart: string,
    partTimeFullTime: null|TPartTimeFullTime;
    skills: ICandidateSkill[];
    languages: ICandidateLanguage[],
    comment: string;
    responsible?: IEmployeePartial;
    topCandidate: boolean;
    topInCommunication: boolean;
    moveWillingness: string;
    typus: string;
    proudOf: string;
    thoughtsAboutTeamAndAppreciation: string;
    flexibility: string;
    freelancerMapUrl: null|string;
    newsletterEnabled: boolean;
    source: ECandidateSource;
    followupDate?: string;
    imagePath?: string;
    followupComment: string;
    applicationVacancy: null|IVacancyPartial;
    topInPotential: boolean;
    numberOfEmployers?: number;
    level?: ECandidateLevel,
    educationLevel?: EEducationLevel
}

export const initialCandidateFormState: ICandidateForm = {
    address: '',
    city: '',
    firstName: '',
    leader: false,
    birthDate: null,
    comment: '',
    countryId: 55,
    currentJobDescription: '',
    mailAddress: '',
    desiredJobDescription: '',
    expectedSalary: 0,
    expectedSalaryTo: 0,
    flexibility: '',
    followupComment: '',
    freelancer: false,
    homeOfficeDesire: null,
    linkedInUrl: '',
    mobileNumber: '',
    moveWillingness: '',
    name: '',
    topCandidate: false,
    newsletterEnabled: true,
    noticePeriod: null,
    partTimeFullTime: "FULL_TIME",
    phoneNumber: '',
    proudOf: '',
    salutation: 'mr',
    source: ECandidateSource.homepage,
    thoughtsAboutTeamAndAppreciation: '',
    title: '',
    topInCommunication: false,
    typus: '',
    websiteUrl: '',
    githubUrl: '',
    furtherUrl: '',
    wishDateOfStart: '',
    xingUrl: '',
    yearsOfWorkExperience: null,
    zipCode: '',
    languages: [],
    skills: [],
    freelancerMapUrl: '',
    salaryUnit: 'year',
    applicationVacancy: null,
    topInPotential: false
}

export interface ICandidatePageState {
    reloadList: boolean;
    formFieldsIgnoredForValidation: string[];
    importFromCvParser?: ICvParseResult;
    createBirthdayMailForCandidate: number;
    importCvFile?: FileObject;
    activeTabCandidateEditView?: ECandidateEditViewTabs,
    recruitingSorting: ISorting;
    initialCandidatesLoaded: boolean;
    editViewLoading: boolean;
    sorting: ISorting;
    filter: ICandidateFilterApiProperties;
    filterMenu: ICandidateFilterMenu;
    birthdayWishMissing: boolean;
    candidateToDelete: number;
    addDialogOpen: boolean;
    currentDocumentId: number;
    documentSorting: ISorting;
    activitySorting: ISorting;
    activityFilter: IApiBaseFilter;
    activityToDelete: number;
    filterRecruitingList: IRecruitingFilter;
    filterMenuRecruitingList: IRecruitingFilterMenu;
    sortingVacancyList: ISorting;
    filterVacancyList: Partial<IWebVacancyFilter>,
    filterMenuVacancyList: Partial<IWebVacancyFilterMenu>,
}

export interface ICandidateFilterApiProperties extends Partial<IApiBaseFilter> {
    topCandidate?: boolean;
    topInCommunication?: boolean;
    salutation?: string;
    skillIds: number[];
    optionalSkillIds: number[];
    leader?: boolean;
    yearsOfWorkExperience?: string;
    distanceSearchCompanyLocationId?: number;
    distanceSearchKm?: number;
    ageFrom?: number;
    ageTo?: number;
    responsibleEmployeeId?: number;
    jobDescription?: string;
    salaryFrom?: number;
    salaryTo?: number;
    languageIds: number[];
    searchForVacancyId?: number;
    searchForWebVacancyId?: number;
    onlyFollowups?: boolean;
    source?: ECandidateSource;
    isFreelancer?: boolean;
    hasDriverLicense?: boolean;
    homeOfficeDesire: EHomeOfficeDesire[];
    dateRangeFilter: IDateRangeFilterApiProperties;
    recruitingFilter?: IRecruitingInformationFilterApiProperties;
}

export interface ICandidateFilterMenu {
    topCandidate?: boolean;
    topInCommunication?: boolean;
    hasDriverLicense?: boolean;
    salutation?: TSalutation;
    skills: ISkill[];
    optionalSkills: ISkill[];
    leader?: boolean;
    yearsOfWorkExperience?: string;
    homeOfficeDesire: EHomeOfficeDesire[];
    distanceSearchCompanyLocation?: ICompanyLocation|ICompanyLocationPartial;
    distanceSearchKm?: number;
    jobDescription?: string;
    ageFrom?: number;
    ageTo?: number;
    responsibleEmployee: null|IEmployeePartial;
    onlyFollowups: boolean;
    salaryFrom?: number;
    salaryTo?: number;
    languages: ILanguage[]
    source?: ECandidateSource;
    isFreelancer?: boolean;
    dateRangeFilter: IDateRangeFilterApiProperties;
    recruitingFilter?: IRecruitingInformationFilterMenu;
}
