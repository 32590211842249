import { MenuItem } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { TWorkExperience } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: string;
    chooseLabel?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
}

const YearsOfWorkExperienceDropDown: React.FC<IProps> = (props) => {
    const workExperienceValues: TWorkExperience[] = ["less_than_2", "up_to_5", "more_than_5", "more_than_10"];

    let menuItems = workExperienceValues.map((workExperience: TWorkExperience, index: number) => (
        <MenuItem key={index + 1} value={workExperience}>
            {translate('misc.workExperienceValue.' + workExperience)}
        </MenuItem>
    ));
    menuItems = [
        <MenuItem key={0} value={0}>
            {props.chooseLabel ?? translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            id={"years-of-work-experience-dropdown"}
            label={translate('misc.workExperience')}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default YearsOfWorkExperienceDropDown;
