import React from 'react';
import { EInvoiceType } from '../../definitions/billingPage.definitions';
import { Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    invoiceType: EInvoiceType;
}

const InvoiceTypeCellRenderer: React.FC<IProps> = (props) => {

    switch (props.invoiceType) {
        case EInvoiceType.MANUAL_INVOICE:
        case EInvoiceType.DEFAULT_CLOSING_INVOICE:
        case EInvoiceType.HEADHUNTING_CLOSING_INVOICE:
            return (
                <Tooltip title={"Abschluss-Rechnung"}>
                    {IconFactory.getIconComponent(EIcons.CheckCircle)}
                </Tooltip>
            );
        case EInvoiceType.DEFAULT_DOWN_INVOICE:
        case EInvoiceType.HEADHUNTING_FIRST_INVOICE:
        case EInvoiceType.HEADHUNTING_SECOND_INVOICE:
        case EInvoiceType.DEFAULT_INVOICE:
            return (
                <Tooltip title={"Abschlags-Rechnung"}>
                    {IconFactory.getIconComponent(EIcons.RadioButtonUnchecked)}
                </Tooltip>
            );
        case EInvoiceType.PROJECT_INVOICE: {
            return (
                <Tooltip title={"Projekt-Rechnung"}>
                    {IconFactory.getIconComponent(EIcons.AvTimer)}
                </Tooltip>
            );
        }

    }
};

export default InvoiceTypeCellRenderer;
