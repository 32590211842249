import React, { useState } from 'react';
import { IApplication } from '../../../definitions/entities.definition';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { translate } from '../../../translation/translate.utils';
import RenderApplicationListItem from './RenderApplicationListItem';

interface IProps {
    status: string;
    applications: IApplication[];
    parentView: 'candidate'|'vacancy'|'company';
}

const RenderApplicationListGroup: React.FC<IProps> = (props) => {
    const status = props.status;
    const applications = props.applications;
    const parentView = props.parentView;
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);

    return <>
        <div className={"flexContainerRow"} onClick={toggleExpand}>
            <h4 style={{margin: 5, flex: 1}}>{translate('pages.application.statusValue.' + status)} ({applications.length})</h4>
            <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
        </div>
        {expanded &&
        <>
            {applications.map((application) =>
                <RenderApplicationListItem
                    key={application.id}
                    parentView={parentView}
                    application={application}
                />
            )}
        </>
        }
    </>
}

export default React.memo(RenderApplicationListGroup);
