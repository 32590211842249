import React from 'react';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../CustomInput/CustomDialogActions';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomDialogBody from '../CustomDialog/CustomDialogBody';
import { useDispatch, useSelector } from 'react-redux';
import { setUiLogOutExpireDialog } from '../../redux/ui/ui.actions';
import { IStore } from '../../definitions/redux/store.definitions';

const LogoutExpireView: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((store: IStore) => store.ui.expireModal);
    const onClose = () => dispatch(setUiLogOutExpireDialog());

    return (
        <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"misc.logOut"} onClose={onClose} />
            <CustomDialogBody>
                <CustomTypography translation={"misc.logOutExpire"} />
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
            />
        </Dialog>
    );
};

export default LogoutExpireView;
