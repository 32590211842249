import React from 'react';
import './App.css';
import { useSelector } from 'react-redux';
import { IStore } from './definitions/redux/store.definitions';
import 'moment/locale/de';
import AppNotLoggedIn from './AppNotLoggedIn';
import AppLoggedIn from './Pages/App/AppLoggedIn';
import AppLoggedInCustomer from './PagesCustomer/App/AppLoggedIn';

const App = () => {
    const token = useSelector((store: IStore) => store.actor.token);
    const employee = useSelector((store: IStore) => store.actor.data?.employee);
    const companyContact = useSelector((store: IStore) => store.actor.data?.companyContact);

    if (token && employee) {
        return <>
            <AppLoggedIn />
        </>;
    }

    if (token && companyContact) {
        return <>
            <AppLoggedInCustomer />
        </>;
    }

    return <>
        <AppNotLoggedIn />
    </>
};

export default App;
