import React, { useState } from 'react';
import { ICompanyLocation, ICompanyLocationPartial } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import CompanyLocationAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/Legacy/CompanyLocationAutocompleteDropDown';
import { translate } from '../../../../translation/translate.utils';
import CustomNumberInput from '../../../CustomInput/CustomNumberInput';
import PaperRow from '../../../CustomPaper/PaperRow';
import { EIcons } from '../../../Icons/IconFactory';

interface IProps {
    companyLocation?: ICompanyLocationPartial;
    distance?: number;
    onChange: (company?: ICompanyLocationPartial, distance?: number) => void;
    companyId?: number;
}

const DistanceToCompanyFilter: React.FC<IProps> = (props) => {
    const [companyLocation, setCompanyLocation] = useState(props.companyLocation);
    const [distance, setDistance] = useState(props.distance);
    const companyId = props.companyId;

    const dataConsistent = JSON.stringify(companyLocation) === JSON.stringify(props.companyLocation) && JSON.stringify(distance) === JSON.stringify(props.distance);

    const submit = () => {
        props.onChange(companyLocation, distance);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const deletable = props.companyLocation !== undefined && props.distance !== undefined && props.distance > 0;
    const renderValue = () => {
        if (deletable) {
            //@ts-ignore
            return <>max. {distance} km Fahrtweg bis {props.companyLocation.zipCode} {props.companyLocation.city}</>
        }

        return null;
    }

    return <>
        <CustomFilterItem
            title={"Adresse"}
            icon={EIcons.LocationOn}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            showDialog
            submit={submit}
            dataConsistent={dataConsistent}
            width={400}
        >
            <PaperRow>
                <CompanyLocationAutocompleteDropDown
                    autoFocus
                    onChange={(event, value: ICompanyLocation) => setCompanyLocation(value)}
                    value={companyLocation}
                    companyId={companyId}
                    filterByCompany={Boolean(companyId)}
                />
            </PaperRow>
            <PaperRow>
                <CustomNumberInput
                    value={distance}
                    onChange={setDistance}
                    label={translate('pages.candidate.filter.distanceInKm')}
                />
            </PaperRow>
        </CustomFilterItem>
    </>
};

export default React.memo(DistanceToCompanyFilter);
