import React from 'react';
import { getHoursStringByMinutes } from '../../utils/date.utils';

interface IProps {
    minutes?: number;
}

const MinutesCellRenderer: React.FC<IProps> = (props) => {
    return (
        <>{getHoursStringByMinutes(props.minutes)}</>
    );
}

export default MinutesCellRenderer;
