import React from 'react';
import { ESuggestionStatus } from '../../../definitions/entities.definition';
import { Button, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { useDispatch } from 'react-redux';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import {
    setVacancyPageSuggestionToAddActivityAction,
    setVacancyPageSuggestionToDeclineAction,
    setVacancyPageSuggestionToDeleteAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { registerError } from '../../../redux/error/error.actions';
import { translate } from '../../../translation/translate.utils';
import { setRecruitingPageStartRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import { COLOR_ORANGE } from '../../../theme/theme';

interface IProps {
    suggestionId: number;
    recruitingId?: number;
    status: ESuggestionStatus;
    candidateId?: number;
    candidateName?: string;
    vacancyId: number;
    parentView: EEntityView;
    suggestedByEmployeeId: number;
    responsibleEmployeeId: number;
}

const SuggestionListItemActions: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const suggestionId = props.suggestionId;
    const recruitingId = props.recruitingId;
    const status = props.status;
    const candidateId = props.candidateId;
    const candidateName = props.candidateName;
    const vacancyId = props.vacancyId;
    const parentView = props.parentView;
    const suggestedByEmployeeId = props.suggestedByEmployeeId;
    const responsibleEmployeeId = props.responsibleEmployeeId;
    const openCandidateOnEdit = candidateId && candidateName && parentView !== EEntityView.candidate;

    return <>
        <Tooltip title={openCandidateOnEdit ? "Kandidat bearbeiten" : "Stammdaten bearbeiten"}>
            <Button
                variant="contained"
                color="secondary"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (openCandidateOnEdit) {
                        dispatch(setUiEditView(EEntityView.candidate, candidateId));
                        return;
                    }
                    dispatch(setUiEditView(
                        EEntityView.suggestion,
                        suggestionId,
                        parentView === EEntityView.candidate ? 1 : 0
                    ))
                }}
            >
                {IconFactory.getIconComponent(EIcons.Edit)}
            </Button>
        </Tooltip>
        <Button
            variant="contained"
            color="secondary"
            size={"small"}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(setVacancyPageSuggestionToAddActivityAction(suggestionId))
            }}
        >
            {IconFactory.getIconComponent(EIcons.Add)}
        </Button>
        {status === ESuggestionStatus.OPEN &&
            <>
                <Tooltip title={"Kandidat vorschlagen"}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            if (candidateId) {
                                dispatch(setRecruitingPageStartRecruitingAction({
                                    open: true,
                                    candidateId,
                                    vacancyId,
                                    suggestedByEmployeeId,
                                    responsibleEmployeeId
                                }));
                            } else {
                                dispatch(registerError(translate('pages.vacancy.errorSetCandidate')));
                            }
                        }}
                    >
                        {IconFactory.getIconComponent(EIcons.ThumbUp)}
                    </Button>
                </Tooltip>
                <Tooltip title={"Favorit ablehnen"}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(setVacancyPageSuggestionToDeclineAction(suggestionId, false))
                        }}
                    >
                        {IconFactory.getIconComponent(EIcons.ThumbDown)}
                    </Button>
                </Tooltip>
            </>
        }
        <Tooltip title={"Favorit löschen"}>
            <Button
                variant="contained"
                color="secondary"
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(setVacancyPageSuggestionToDeleteAction(suggestionId))
                }}
            >
                {IconFactory.getIconComponent(EIcons.Delete)}
            </Button>
        </Tooltip>
        {parentView !== EEntityView.vacancy &&
            <Tooltip title={"Vakanz öffnen"}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        dispatch(setUiEditView(EEntityView.vacancy, vacancyId))
                    }}
                >
                    {IconFactory.getIconComponent(EIcons.Work)}
                </Button>
            </Tooltip>
        }
        {(recruitingId && candidateName ) &&
        <Tooltip title={"Prozess öffnen"}>
            <Button
                variant="contained"
                color="primary"
                style={{backgroundColor: COLOR_ORANGE}}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(setUiEditView(EEntityView.recruiting, recruitingId))
                }}
            >
                {IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: 'var(--brainz-dark)'})}
            </Button>
        </Tooltip>
        }
    </>;
}

export default SuggestionListItemActions;
