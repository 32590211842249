import React, { useState } from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { useSelector } from 'react-redux';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { getToken, getValidationErrorsFromStore } from '../../selectors/app.selectors';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import { translate } from '../../translation/translate.utils';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { EInterviewType, ICompanyContactPartial } from '../../definitions/entities.definition';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import CustomBackdropLoadingInsideDiv from '../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { ClientApi } from '../../requests/ClientApi';
import { putAcceptRecruitingRouteConfig } from '../../requests/routes.customer';
import InterviewTypeDropDown from '../../components/CustomInput/CustomDropDownInput/InterviewTypeDropDown';
import CompanyContactAutocomplete
    from '../../components/CustomInput/CustomAutocomplete/components/CompanyContactAutocomplete';

interface IProps {
    recruitingId: number;
    open: boolean;
    onClose: () => void;
    callback: () => void;
}

const AcceptRecruitingView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const open = props.open;
    const recruitingId = props.recruitingId;

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [followingInterviewType, setFollowingInterviewType] = useState<EInterviewType|null>(null);
    const [contactPerson, setContactPerson] = useState<ICompanyContactPartial|null>(null);
    const [validationErrors, setValidationErrors] = useState({followingInterviewType: '', contactPersonId: ''});

    const onClose = () => {
        props.onClose();
    };

    const save = () => {
        setLoading(true);
        ClientApi.request(putAcceptRecruitingRouteConfig, {
            token,
            recruitingId,
            followingInterviewType,
            comment,
            contactPersonId:  contactPerson?.id
        }).then((result) => {
            setLoading(false);
            props.callback();
            onClose();
        }).catch((result) => {
            if (result && result.includes("validationErrors: ")) {
                setValidationErrors(JSON.parse(result.replace("validationErrors: ", "")));
            }
            setLoading(false);
        });
    }

    if (!open) {
        return null;
    }

    return (
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            <CustomDialogHeader string={"Kandidat zusagen"} onClose={onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <InterviewTypeDropDown
                        value={followingInterviewType}
                        onChange={(event) => setFollowingInterviewType(event.target.value)}
                        label={"Art des nächsten Interviews"}
                        error={Boolean(validationErrors.followingInterviewType)}
                        helperText={validationErrors.followingInterviewType}
                    />
                </PaperRow>
                <PaperRow>
                    <CompanyContactAutocomplete
                        onChange={(value) => setContactPerson(value)}
                        value={contactPerson}
                        customerPortal
                        label={"nächstes Interview mit Ansprechpartner"}
                        error={Boolean(validationErrors.contactPersonId)}
                        helperText={validationErrors.contactPersonId}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        multiline
                        label={"aktuelles Feedback (optional)"}
                        value={comment}
                        onChange={(value) => setComment(value)}
                        rows={7}
                    />
                </PaperRow>
            </CustomDialogBody>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => save(),
                    label: translate('misc.buttons.save')
                }]}
            />
        </CustomDialog>
    );
};

export default AcceptRecruitingView;
