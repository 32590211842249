import React, { useState } from 'react';
import { Chip } from '@material-ui/core';
import { ISkillPartial, IVacancySkill } from '../../../../definitions/entities.definition';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { COLOR_YELLOW } from '../../../../theme/theme';
import { useDispatch } from 'react-redux';
import { setSkillPageSkillToEditAction } from '../../../../redux/skillPage/skillPage.actions';

interface IProps {
    skill: IVacancySkill;
    onRemove?: (skill: ISkillPartial) => void;
    onSetImportant?: (skill: ISkillPartial) => void;
}

const VacancySkillElement: React.FC<IProps> = (props) => {
    const [onHover, setOnHover] = useState(false);
    const dispatch = useDispatch();

    const starIcon = <StarIcon onMouseOut={() => setOnHover(false)} style={{color: COLOR_YELLOW}} />;
    const starBorderIcon = <StarBorderIcon onMouseOver={() => setOnHover(true)}  />;

    return <>
        <Chip
            icon={onHover || props.skill.importantSkill ? starIcon : starBorderIcon }
            label={props.skill.skill.name}
            onClick={(event) => {
                //@ts-ignore
                if (event.nativeEvent.layerX <= 30 && props.onSetImportant) {
                    props.onSetImportant(props.skill.skill);
                    return;
                }
                dispatch(setSkillPageSkillToEditAction(props.skill.skill.id));
            }}
            onMouseOut={() => setOnHover(false)}
            onDelete={props.onRemove ? () => props.onRemove(props.skill.skill) : undefined}
        />
    </>
}

export default VacancySkillElement
