import React, { useEffect, useState } from 'react';
import { ClientApi } from '../../requests/ClientApi';
import { getRandomGifUrl } from '../../requests/routes';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { IconButton } from '@material-ui/core';

interface IProps {
    tag: string;
}

const CustomDrawerItem: React.FC<IProps> = (props) => {
    const [giphyUrl, setGiphyUrl] = useState('');

    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (!giphyUrl || reload) {
            setReload(false);
            ClientApi.request(getRandomGifUrl, {
                apiKey: "buEyuQHL8bnAFV0Rjy8Y1dE5tUqeGxDb",
                tag: props.tag,
            }).then((value) => {
                setGiphyUrl(value.data.images.original.url);
            });
        }
    }, [giphyUrl, reload]);

    if (!giphyUrl) {
        return null;
    }

    return (
        <div style={{textAlign:"center"}}>
            <img src={giphyUrl} alt={"giphy"}/>
            <br/>
            <IconButton onClick={() => setReload(true)}>{IconFactory.getIconComponent(EIcons.Refresh)}</IconButton>
        </div>
    );
};

export default CustomDrawerItem;
