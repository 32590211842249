import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { IconButton, ListItemIcon, ListItemSecondaryAction, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { setUiEditView, setUiRemovePinnedView } from '../../../../redux/ui/ui.actions';
import { StyledTopBarBadge } from '../DefaultTopBarRight';
import { EEntityView } from '../../../../definitions/ui.definitions';

const PinnedViewsMenuItem: React.FC = () => {
    const dispatch = useDispatch();
    const pinnedViews = useSelector((store: IStore) => store.ui.pinnedViews);
    const countPinnedViews = useSelector((store: IStore) => store.ui.pinnedViews.length);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (countPinnedViews === 0) {
        return null;
    }

    const getViewIcon = (view: EEntityView) => {
        switch (view) {
            case EEntityView.vacancy:
                return IconFactory.getIconComponent(EIcons.Work, {color: 'var(--brainz-color-orange)'});
            case EEntityView.company:
                return IconFactory.getIconComponent(EIcons.Apartment, {color: 'var(--brainz-color-blue)'});
            case EEntityView.suggestion:
                return IconFactory.getIconComponent(EIcons.Star, {color: 'var(--brainz-color-orange)'});
            case EEntityView.recruiting:
                return IconFactory.getIconComponent(EIcons.ThumbsUpDown, {color: 'var(--brainz-color-orange)'});
            case EEntityView.candidate:
                return IconFactory.getIconComponent(EIcons.People, {color: 'var(--brainz-color-green)'});
            case EEntityView.webVacancy:
                return IconFactory.getIconComponent(EIcons.CloudDownload, {color: 'var(--brainz-color-red)'});
        }

        return null;
    }

    return <>
        <Tooltip title={'Gepinnte Fenster'} placement="bottom">
            <IconButton onClick={handleClick} style={{bottom: 5}}>
                <StyledTopBarBadge color="primary" badgeContent={countPinnedViews}>
                    {IconFactory.getIconComponent(EIcons.Bookmarks, {color: 'var(--brainz-color-app-bar-right-drawer-icons)'})}
                </StyledTopBarBadge>
            </IconButton>
        </Tooltip>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: -40,
                horizontal: 'right',
            }}
        >
            {pinnedViews.map((view) => (
                <MenuItem  className={"AppAccountMenuItem"} key={view.view + "_" + view.entityId} onClick={() => {
                    dispatch(setUiEditView(view.view, view.entityId));
                    handleClose();
                }}>
                    <ListItemIcon>
                        {getViewIcon(view.view)}
                    </ListItemIcon>
                    {view.title}
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => {
                            dispatch(setUiRemovePinnedView(view.view, view.entityId));
                        }}>
                            {IconFactory.getIconComponent(EIcons.Delete, {color: 'var(--brainz-dark)'})}
                        </IconButton>
                    </ListItemSecondaryAction>
                </MenuItem>
            ))}
        </Menu>
    </>;
}

export default React.memo(PinnedViewsMenuItem);
