import React, { PropsWithChildren } from 'react';
import PaperRow from './PaperRow';

const PaperRowWithScrollContainer: React.FC<PropsWithChildren<any>> = (props) => {
    return (
        <div style={{ height: "100%", width: "100%", overflow: "auto"}}>
            <PaperRow>
                {props.children}
            </PaperRow>
        </div>
    );
}

export default PaperRowWithScrollContainer;
