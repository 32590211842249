import React from 'react';
import Chip from '@material-ui/core/Chip';
import { IBranch } from '../../definitions/entities.definition';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
    branches: IBranch[];
}

const BranchesCellRenderer: React.FC<IProps> = (props) => {
    const useOutlinedInputStyles = makeStyles((theme) => ({
        colorPrimary: {
            backgroundColor: theme.palette.primary.main,
            color: 'var(--brainz-darkest)'
        },
    }));
    const inputStyles = useOutlinedInputStyles();

    const chipElements = props.branches.map((branch: IBranch, index: number) => (
        <div key={index} style={{ float: "left" }}>
            <Chip key={index} color="primary" classes={inputStyles} label={branch.name} />
            &nbsp;
        </div>
    ));

    return <div>{chipElements}</div>;
};

export default BranchesCellRenderer;
