import { IApiBaseFilter, ISorting } from './app.definitions';


export enum EAdministrationPageTabs {
    employee,
    skill,
    jobTemplate,
    jobTitle
}
export interface IAdministrationPageState {
    activeTab: EAdministrationPageTabs;
    editViewLoading: boolean;
    employeeSorting: ISorting;
    employeeFilter: IApiBaseFilter;
    employeeToEdit: number;
    addEmployeeDialogOpen: boolean;
}

export const preloadedStateAdministrationPage: IAdministrationPageState = {
    activeTab: EAdministrationPageTabs.employee,
    addEmployeeDialogOpen: false,
    employeeFilter: {
        start: 0,
        limit: 50
    },
    employeeSorting: {},
    employeeToEdit: 0,
    editViewLoading: false,
}
