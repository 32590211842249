import React from 'react';
import { IRecruiting, IRecruitingPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CustomAutocompleteDropDown from './CustomAutocompleteDropDown';
import { useLoadOpenRecruitings } from './OpenRecruitingAutocompleteDropDown.hook';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: IRecruiting|IRecruitingPartial;
    onlyWithUrl?: boolean;
    label?: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const OpenRecruitingAutocompleteDropDown: React.FC<IProps> = (props) => {
    const recruitings = useLoadOpenRecruitings();

    return (
        <CustomAutocompleteDropDown
            options={recruitings}
            disabled={props.disabled}
            required={props.required}
            defaultValue={props.value}
            onChange={props.onChange}
            error={props.error}
            helperText={props.helperText}
            label={props.label || translate('misc.recruiting')}
            getOptionLabel={(recruiting: IRecruiting) => {
                return `${recruiting.candidate.fullName} bei ${recruiting.company.name}`;
            }}
        />
    );
};

export default OpenRecruitingAutocompleteDropDown;
