import React from 'react';
import InvoiceBurnUpChartView from './components/InvoiceBurnUpChartView';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import InvoicesPieChartView from './components/InvoicesPieChartView';

const BillingPageDashboard: React.FC = () => {
    return (
        <>
            <div className={"flex1 overflow"}>
                <CustomBackdropLoading open={false}/>
                <div className={"flexColumn"}>
                    <div className={"flex flexCentered"}>
                        <div className={"flexContainerColumn padding5 gap5"} style={{minWidth: 800}}>
                            <InvoiceBurnUpChartView/>
                            <InvoicesPieChartView/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BillingPageDashboard;
