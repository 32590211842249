import React from 'react';
import { setInterval } from 'timers';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { buildTimeForDate } from '../../utils/calendar.utils';
import moment from 'moment';
import { actorLogOutExpireAction } from '../../redux/actor/actor.actions';
import { EStorageItem } from '../../definitions/app.definitions';
import { getNotifyUnreadCountAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';

let autoLogoutTimer: undefined|NodeJS.Timeout = undefined;
let timer: undefined|NodeJS.Timeout = undefined;

/**
 * HeartBeatController
 *
 * hier kannst du (Danielbubu) in der DidMount wie hier erstmalig implementiert verschiedene intervalle erstellen
 * für jedes intervall dann einfach die passende klassenmethode schreiben und fertig :+1:
 */
class HeartBeatController extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount() {
        timer = setInterval(this.tick, 600000);
        autoLogoutTimer = setInterval(this.autoLogout, 5000);
        this.tick();
    }

    tick = () => {
        this.props.getNotifyUnreadCountAction(this.props.token);
    };

    autoLogout = () => {
        if (timer) {
            clearTimeout(timer);
        }
        if (autoLogoutTimer) {
            clearTimeout(autoLogoutTimer);
        }

        const date = new Date();
        const dateString = date.toISOString().split("T")[0];
        const currentMoment = moment(`${dateString}T${buildTimeForDate(date)}`);
        const expireMoment = moment(this.props.expire.replace(" ", "T"));

        const needToLogout = currentMoment.isAfter(expireMoment);

        if (needToLogout) {
            this.props.actorLogOutExpireAction();
            localStorage.removeItem(EStorageItem.Token);
            window.location.reload();
        }
    };

    render() {
        return null;
    }
}

const mapState = (store: IStore) => ({
    expire: store.actor.data ? store.actor.data.expire : '',
    token: getToken(store),
    currentRoute: store.ui.currentRoute
});

const mapDispatch = {
    actorLogOutExpireAction,
    getNotifyUnreadCountAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(HeartBeatController);
