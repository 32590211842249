import { IJwtData } from './app.definitions';

export const ROLE_USER = 'ROLE_USER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_RECRUITER = 'ROLE_RECRUITER';
export const ROLE_BILLING = 'ROLE_BILLING';

export type TRole = typeof ROLE_ADMIN | typeof ROLE_RECRUITER | typeof ROLE_BILLING;

export interface IActorState {
    loggedIn: boolean;
    token: string;
    data?: IJwtData;
    executeLogout: boolean;
}

export const preloadedStateActor: IActorState = {
    loggedIn: false,
    token: '',
    executeLogout: false
};
