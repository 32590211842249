import React, { useState } from 'react';
import { EEntities, IBillingSetting } from '../../../definitions/entities.definition';
import { EBillingType } from '../../../definitions/billingConfiguration.definitions';
import { Link } from '@material-ui/core';
import BillingConfigurationEditView from './BillingConfigurationEditView';

interface IProps {
    billingSetting: IBillingSetting;
    entityId: number;
    entity: EEntities.VacancyList|EEntities.Company|EEntities.Recruiting;
}

const BillingConfigurationElement: React.FC<IProps> = (props) => {
    const [configurationViewOpen, setConfigurationViewOpen] = useState(false);
    let text = '';
    switch (props.billingSetting.billingType) {
        case EBillingType.BILLING_TYPE_TEMPLATE:
            if (props.billingSetting.billingVariantTemplate) {
                text = 'Vorlage: ' + props.billingSetting.billingVariantTemplate.title;
            }
            break;
        case EBillingType.BILLING_TYPE_CUSTOMIZE:
            text = 'Individuelle Einstellung';
            break;
        case EBillingType.BILLING_TYPE_HEADHUNTING:
            text = 'Headhunting';
            break;
        case EBillingType.BILLING_TYPE_PROJECT:
            text = 'Projekt';
            break;
        default:
            text = 'Keine Angabe';
            break;
    }
    return (
        <>
            <BillingConfigurationEditView
                open={configurationViewOpen}
                onClose={() => setConfigurationViewOpen(false)}
                billingSetting={props.billingSetting}
                entityId={props.entityId}
                entity={props.entity}
            />
            <div className={"flex flexCentered"}>
                <Link onClick={() => setConfigurationViewOpen(true)}>Rechnungsstellung bearbeiten (Aktuell: {text})</Link>
            </div>
        </>
    );
}

export default BillingConfigurationElement;
