import React from 'react';
import { ICompanyPartial } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import CompanyMultipleAutocomplete
    from '../../../CustomInput/CustomAutocomplete/components/CompanyMultipleAutocomplete';

interface IProps {
    value: ICompanyPartial[];
    onChange: (value: ICompanyPartial[]) => void;
    onlyWithWebVacancies?: boolean;
}

const CompaniesFilter: React.FC<IProps> = (props) => {
    const value = props.value;
    const onlyWithWebVacancies = props.onlyWithWebVacancies;

    const onChange = (value: ICompanyPartial[]) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange([]);
    }

    const deletable = value.length > 0;
    const valueRendered = (deletable) ? value.map((company) => company.name).join(', ') : null;

    return <>
        <CustomFilterItem
            title={translate('misc.company')}
            icon={EIcons.Apartment}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
            autoClose
        >
            <CompanyMultipleAutocomplete
                autoFocus
                label={translate('misc.company')}
                value={value}
                onChange={(value: ICompanyPartial[]) => onChange(value)}
                onlyWithWebVacancies={onlyWithWebVacancies}
            />
        </CustomFilterItem>
    </>;
};

export default React.memo(CompaniesFilter);
