import React, { useEffect } from 'react';
import { translate } from '../../translation/translate.utils';
import CustomTabContainer from '../../components/CustomTabContainer/CustomTabContainer';
import EmployeeList from './components/EmployeeList';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEmployeeListAction,
    getJobTemplateListAction,
    getJobTitleListAction,
    getSkillTreeAction,
} from '../../redux/entities/entities.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { getEmployeeListRequestObject } from '../../selectors/employee.selectors';
import EmployeeAddEditView from './components/components/EmployeeAddEditView';
import JobTemplateList from './components/JobTemplateList';
import { getJobTemplateListRequestObject, getJobTitleListRequestObject } from '../../selectors/jobTemplate.selectors';
import JobTemplateAddEditView from './components/components/JobTemplateAddEditView';
import { EAdministrationPageTabs } from '../../definitions/administrationPage.definitions';
import { setAdministrationPageActiveTabAction } from '../../redux/administrationPage/administrationPage.actions';
import SkillTreeTab from './components/SkillTreeTab';
import JobTitleList from './components/JobTitleList';
import { themeDark } from '../../theme/theme';

const AdministrationPage: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const activeTab = useSelector((store: IStore) => store.administrationPage.activeTab);

    const employeeSorting = useSelector((store: IStore) => store.administrationPage.employeeSorting);
    const employeeRequestObject = useSelector(getEmployeeListRequestObject);
    const employeeFilter = useSelector((store: IStore) => store.administrationPage.employeeFilter);

    const jobTemplateSorting = useSelector((store: IStore) => store.jobTemplatePage.sorting);
    const jobTemplateRequestObject = useSelector(getJobTemplateListRequestObject);
    const jobTemplateFilter = useSelector((store: IStore) => store.jobTemplatePage.filter);

    const jobTitleSorting = useSelector((store: IStore) => store.jobTemplatePage.jobTitleSorting);
    const jobTitleRequestObject = useSelector(getJobTitleListRequestObject);
    const jobTitleFilter = useSelector((store: IStore) => store.jobTemplatePage.jobTitleFilter);
    const jobTitleReload = useSelector((store: IStore) => store.jobTemplatePage.reloadJobTitles);

    const skillTreeFilter = useSelector((store: IStore) => store.skillPage.filter);
    const reloadSkillTree = useSelector((store: IStore) => store.skillPage.reloadTree);

    useEffect(() => {
        dispatch(getEmployeeListAction(token, employeeRequestObject))
    }, [dispatch, employeeSorting, employeeFilter]);

    useEffect(() => {
        dispatch(getJobTemplateListAction(token, jobTemplateRequestObject))
    }, [dispatch, jobTemplateSorting, jobTemplateFilter]);

    useEffect(() => {
        dispatch(getJobTitleListAction(token, jobTitleRequestObject))
    }, [dispatch, jobTitleSorting, jobTitleFilter, jobTitleReload]);

    useEffect(() => {
        dispatch(getSkillTreeAction(token, skillTreeFilter))
    }, [dispatch, skillTreeFilter, reloadSkillTree]);

    return (
        <>
            <div className={"marginTop5 marginLeft5 flexContainerColumn flex1"}>
                <CustomTabContainer
                    color={themeDark.palette.primary.main}
                    activeTab={activeTab}
                    onTabChange={(tabId: EAdministrationPageTabs) => dispatch(setAdministrationPageActiveTabAction(tabId))}
                    tabs={[{
                        label: translate('pages.administration.employees'),
                        component: (<EmployeeList />),
                    }, {
                        label: translate('misc.skills'),
                        component: (<SkillTreeTab />),
                    }, {
                        label: translate('pages.jobTemplate.title'),
                        component: (<JobTemplateList />),
                    }, {
                        label: translate('misc.jobTitles'),
                        component: (<JobTitleList />),
                    }]}
                />
            </div>
            <EmployeeAddEditView />
            <JobTemplateAddEditView />
        </>
    );
}

export default AdministrationPage;
