import { ESortingOptions } from '../../definitions/components.definitions';
import {
    ISetAdministrationPageActiveTabAction,
    ISetAdministrationPageEmployeeToEditAction,
    ISetAdministrationPageFastSearchAction,
    ISetAdministrationPageFilterAction,
    ISetAdministrationPageSortingOptionAction,
} from '../../definitions/redux/actions.definition';
import { IApiBaseFilter } from '../../definitions/app.definitions';
import { EAdministrationPageTabs } from '../../definitions/administrationPage.definitions';

export const ADMINISTRATION_PAGE_SET_FILTER = "ADMINISTRATION_PAGE_SET_FILTER";
export const ADMINISTRATION_PAGE_SET_ACTIVE_TAB = "ADMINISTRATION_PAGE_SET_ACTIVE_TAB";
export const ADMINISTRATION_PAGE_SET_SORTING_OPTION = "ADMINISTRATION_PAGE_SET_SORTING_OPTION";
export const ADMINISTRATION_PAGE_SET_FAST_SEARCH = "ADMINISTRATION_PAGE_SET_FAST_SEARCH";
export const ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT = "ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT";
export const ADMINISTRATION_PAGE_SET_ADD_DIALOG = "ADMINISTRATION_PAGE_SET_ADD_DIALOG";

export const setAdministrationPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetAdministrationPageSortingOptionAction => ({
    type: ADMINISTRATION_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setAdministrationPageActiveTabAction = (tab: EAdministrationPageTabs): ISetAdministrationPageActiveTabAction => ({
    type: ADMINISTRATION_PAGE_SET_ACTIVE_TAB,
    payload: tab,
});

export const setAdministrationPageFilterAction = (filter: Partial<IApiBaseFilter>): ISetAdministrationPageFilterAction => ({
    type: ADMINISTRATION_PAGE_SET_FILTER,
    payload: filter,
});

export const setAdministrationPageFastSearchValueAction = (fastSearchValue: string): ISetAdministrationPageFastSearchAction => ({
    type: ADMINISTRATION_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setAdministrationPageEmployeeToEditAction = (employeeId: number): ISetAdministrationPageEmployeeToEditAction => ({
    type: ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT,
    payload: employeeId,
});

export const setAdministrationPageAddDialogOpenAction = (open: boolean) => ({
    type: ADMINISTRATION_PAGE_SET_ADD_DIALOG,
    payload: open,
});
