import { IStore } from '../definitions/redux/store.definitions';
import { ICompanyDocument } from '../definitions/entities.definition';
import { buildRequestObject } from './entity.selectors';

export const getDocumentsForCompany = (store: IStore, companyId: number) => {
    const documentsById = store.entities.companyDocuments.byId;
    const documentIdsByCompanyId = store.entities.companyDocuments.byCompany[companyId] || [];

    const documents: ICompanyDocument[] = [];

    documentIdsByCompanyId.forEach((documentId) => documents.push(documentsById[documentId]));

    return documents;
};

export const getCompanyPageRequestObjectForDocumentsInEditView = (store: IStore) => {
    const sorting = store.companyPage.documentSorting;
    const filter = { start: 0, limit: 50, fastSearch: "" };

    return buildRequestObject(sorting, filter);
};
