import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsFromStore } from '../../../../selectors/news.selectors';
import { INews } from '../../../../definitions/entities.definition';
import NewsElement from './NewsElement';
import { groupNewsByDate, groupNewsByType } from '../../../../utils/news.utils';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import { getNewsListAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import PaperRowWithScrollContainer from '../../../../components/CustomPaper/PaperRowScrollContainer';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getFormattedDateWithDayString } from '../../../../utils/date.utils';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';

const NewsList: React.FC = () => {
    const records = useSelector(getNewsFromStore);
    const recordsGroupedByDate = groupNewsByDate(records);
    const token = useSelector(getToken);
    const start = useSelector((store: IStore) => store.news.start);
    const limit = useSelector((store: IStore) => store.news.limit);
    const loading = useSelector((state: IStore) => state.ui.newsLoading);
    const dispatch = useDispatch();

    return (
        <>
            <PaperRowWithScrollContainer>
                {Object.keys(recordsGroupedByDate).map((dateTime) => {
                    const recordsGroupedByType = groupNewsByType(recordsGroupedByDate[dateTime]);

                    return Object.keys(recordsGroupedByType).map((newsType) => {
                        const records = recordsGroupedByType[newsType];

                        return <>
                            <div style={{width: "100%", paddingBottom: 10, textAlign: "right", marginRight: 5}}>
                                <CustomTypography
                                    variant={'body2'}
                                    value={getFormattedDateWithDayString(dateTime, false)}
                                />
                            </div>
                            {
                                records.map((news: INews) => <NewsElement key={news.id} news={news} />)
                            }
                        </>;
                    });


                })}
            </PaperRowWithScrollContainer>
            <CustomTableFooter
                loading={loading}
                onReload={() => {
                    dispatch(getNewsListAction(token, start, limit));
                }}
            />
        </>
    );
}

export default NewsList;
