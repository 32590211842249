import { EApplicationStatus, EEntities } from '../definitions/entities.definition';
import { IStore } from '../definitions/redux/store.definitions';
import { ESortingOptions } from '../definitions/components.definitions';
import { ERoutes, IApiBaseFilter, ISorting } from '../definitions/app.definitions';
import { ICompanyFilter } from '../definitions/companyPage.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { IVacancyFilter } from '../definitions/vacancyPage.definitions';
import { ICandidateFilterApiProperties } from '../definitions/candidatePage.definitions';
import { IActivityFilter } from '../definitions/activityPage.definitions';
import { IRecruitingFilter } from '../definitions/recruitingPage.definitions';
import { isArray } from 'util';
import { EDocumentStatus } from '../definitions/billingPage.definitions';
import { EDateRangeMode } from '../definitions/filter.definitions';

export const getSortingOptionFromEntity = (store: IStore, entity: EEntities, propertyToSearch: string): ESortingOptions => {
    let sortingState = {} as ISorting;

    switch (entity) {
        case EEntities.Application: {
            sortingState = store.applicationPage.sorting;
            break;
        }
        case EEntities.VacancyApplication: {
            sortingState = store.vacancyPage.applicationSorting;
            break;
        }
        case EEntities.Company: {
            sortingState = store.companyPage.sorting;
            break;
        }
        case EEntities.Suggestion: {
            sortingState = store.vacancyPage.suggestionListViewSorting;
            break;
        }
        case EEntities.CompanyMetrics: {
            sortingState = store.analyticsPage.companyMetricsSorting;
            break;
        }
        case EEntities.Activity: {
            sortingState = store.activityPage.sorting;
            break;
        }
        case EEntities.ActivityList: {
            sortingState = store.ui.activityListViewSorting;
            break;
        }
        case EEntities.Candidate: {
            sortingState = store.candidatePage.sorting;
            break;
        }
        case EEntities.VacancyCandidateSearch: {
            sortingState = store.vacancyPage.sortingCandidateList;
            break;
        }
        case EEntities.WebVacancyCandidateSearch: {
            sortingState = store.webVacancyPage.sortingCandidateList;
            break;
        }
        case EEntities.CandidateVacancySearch: {
            sortingState = store.candidatePage.sortingVacancyList;
            break;
        }
        case EEntities.Invoice: {
            sortingState = store.billingPage.sortingInvoiceList;
            break;
        }
        case EEntities.JobTitleOverview: {
            sortingState = store.jobTemplatePage.jobTitleSorting;
            break;
        }
        case EEntities.JobTemplate: {
            sortingState = store.jobTemplatePage.sorting;
            break;
        }
        case EEntities.Employee: {
            sortingState = store.administrationPage.employeeSorting;
            break;
        }
        case EEntities.VacancySuggestion: {
            sortingState = store.vacancyPage.suggestionSorting;
            break;
        }
        case EEntities.VacancyList: {
            sortingState = store.vacancyPage.vacancyListSorting;
            break;
        }
        case EEntities.WebVacancy: {
            sortingState = store.webVacancyPage.sorting;
            break;
        }
        case EEntities.VacancyArchive: {
            sortingState = store.archivePage.sortingVacancyList;
            break;
        }
        case EEntities.CompanyLocation: {
            sortingState = store.companyPage.companyLocationSorting;
            break;
        }
        case EEntities.CompanyActivity: {
            sortingState = store.companyPage.activitySorting;
            break;
        }
        case EEntities.VacancyActivity: {
            sortingState = store.vacancyPage.activitySorting;
            break;
        }
        case EEntities.SuggestionActivity: {
            sortingState = store.vacancyPage.suggestionActivitySorting;
            break;
        }
        case EEntities.TodoActivity: {
            sortingState = store.todo.activitySorting;
            break;
        }
        case EEntities.RecruitingActivity: {
            sortingState = store.recruitingPage.activitySorting;
            break;
        }
        case EEntities.CompanyVacancy: {
            sortingState = store.companyPage.companyVacancySorting;
            break;
        }
        case EEntities.CandidateActivity: {
            sortingState = store.candidatePage.activitySorting;
            break;
        }
        case EEntities.WebVacancyActivity: {
            sortingState = store.webVacancyPage.activitySorting;
            break;
        }
        case EEntities.CandidateDocument: {
            sortingState = store.candidatePage.documentSorting;
            break;
        }
        case EEntities.RecruitingDocument: {
            sortingState = store.recruitingPage.documentSorting;
            break;
        }
        case EEntities.CompanyContact: {
            sortingState = store.companyPage.companyContactSorting;
            break;
        }
        case EEntities.VacancyRecruiting: {
            sortingState = store.vacancyPage.recruitingSorting;
            break;
        }
        case EEntities.CandidateRecruiting: {
            sortingState = store.candidatePage.recruitingSorting;
            break;
        }
        case EEntities.CompanyRecruiting: {
            sortingState = store.companyPage.recruitingSorting;
            break;
        }
        case EEntities.RecruitingsSuggest: {
            sortingState = store.recruitingPage.sortingTabRecruitingsSuggest;
            break;
        }
        case EEntities.RecruitingsInterview: {
            sortingState = store.recruitingPage.sortingTabRecruitingsInterview;
            break;
        }
        case EEntities.RecruitingsContract: {
            sortingState = store.recruitingPage.sortingTabRecruitingsContract;
            break;
        }
        case EEntities.RecruitingsAbort: {
            sortingState = store.archivePage.sortingTabRecruitingsAbort;
            break;
        }
        case EEntities.RecruitingsFinish: {
            sortingState = store.archivePage.sortingTabRecruitingsFinish;
            break;
        }
        case EEntities.EmployeeRecruiting: {
            sortingState = store.dashboardPage.sortingEmployeeRecruitings;
            break;
        }
        case EEntities.RecruitingsOfLast7Days: {
            sortingState = store.dashboardPage.sortingRecruitingOfLast7Days;
            break;
        }
        case EEntities.CandidateBirthday: {
            sortingState = store.dashboardPage.sortingCandidateBirthdays;
            break;
        }
        case EEntities.CompanyContactBirthday: {
            sortingState = store.dashboardPage.sortingContactPersonBirthdays;
            break;
        }
        case EEntities.CandidateOpenApplication: {
            sortingState = store.dashboardPage.sortingOpenApplications;
            break;
        }
        case EEntities.TopSuggestions: {
            sortingState = store.dashboardPage.sortingTopSuggestions;
            break;
        }
        case EEntities.RecruitingsTrialPeriodFailed: {
            sortingState = store.archivePage.sortingTabRecruitingsTrialNotFinished;
            break;
        }
        default:
            break;
    }

    const result = sortingState[propertyToSearch];

    if (!result) {
        return ESortingOptions.None;
    }

    return result;
};

export const getFilterMenuBadgeCount = (store: IStore, entity: EEntities) => {
    const getFilterCount = (filterOriginal: Partial<IApiBaseFilter>) => {
        const filter = {
            ...filterOriginal
        };

        delete filter.start;
        delete filter.limit;
        delete filter.fastSearch;

        if (filter.dateRangeFilter && filter.dateRangeFilter.mode === EDateRangeMode.all) {
            delete filter.dateRangeFilter;
        }

        const filterArr = Object.values(filter).filter((value: any) => {
            if (value === undefined) {
                return false;
            }
            const isValueArray = value.constructor && value.constructor.name === "Array";
            const isValueObject = value.constructor && value.constructor.name === "Object";

            if (isValueArray && value.length === 0) {
                return false;
            }

            if (isValueObject) {
                let objectEmpty = true;

                Object.values(value).forEach((valueFromObj: any) => {
                    if (valueFromObj) {
                        objectEmpty = false;
                    }
                });

                return !objectEmpty;
            }

            return Boolean(value);
        });

        return filterArr.length;
    }

    switch (entity) {
        case EEntities.Application: {
            const filter = {
                ...store.applicationPage.filter,
            };

            if (JSON.stringify(filter.status) === JSON.stringify([EApplicationStatus.open, EApplicationStatus.inspection])) {
                delete filter.status;
            }

            return getFilterCount(filter);
        }
        case EEntities.CandidateVacancySearch: {
            const filter = {
                ...store.candidatePage.filterVacancyList,
            };
            delete filter.searchForCandidateId;

            return getFilterCount(filter);
        }
        case EEntities.VacancyCandidateSearch: {
            const filter = {
                ...store.vacancyPage.filterCandidateList,
            };
            delete filter.distanceSearchCompanyLocationId;
            delete filter.searchForVacancyId;

            return getFilterCount(filter);
        }
        case EEntities.WebVacancyCandidateSearch: {
            const filter = {
                ...store.webVacancyPage.filterCandidateList,
            };
            delete filter.distanceSearchCompanyLocationId;
            delete filter.searchForWebVacancyId;

            return getFilterCount(filter);
        }
        case EEntities.Company: {
            const filter = {
                ...store.companyPage.filter,
            };

            if (filter.isCustomer === true) {
                delete filter.isCustomer;
            }

            return getFilterCount(filter);
        }
        case EEntities.Suggestion: {
            const filter = {
                ...store.vacancyPage.suggestionListViewFilter,
            };

            return getFilterCount(filter);
        }
        case EEntities.Candidate: {
            const filter = {
                ...store.candidatePage.filter,
            };
            delete filter.distanceSearchCompanyLocationId;

            return getFilterCount(filter);
        }

        case EEntities.WebVacancy: {
            const filter = {
                ...store.webVacancyPage.filter,
            };
            delete filter.radius;

            delete filter.dateFrom;
            delete filter.dateTo;
            delete filter.lastDateRangeCount;
            delete filter.dateRangeProperty;
            if (filter.dateRangeMode === EDateRangeMode.all) {
                delete filter.dateRangeMode;
            }

            return getFilterCount(filter);
        }

        case EEntities.Invoice: {
            const filter = {
                ...store.billingPage.filterInvoiceList,
            };

            if (JSON.stringify(filter.status) === JSON.stringify([EDocumentStatus.open, EDocumentStatus.send, EDocumentStatus.remind, EDocumentStatus.first_dunning, EDocumentStatus.second_dunning, EDocumentStatus.last_dunning])) {
                delete filter.status;
            }

            return getFilterCount(filter);
        }

        case EEntities.VacancyList: {
            const filter = {
                ...store.vacancyPage.vacancyListFilter,
            };

            return getFilterCount(filter);
        }

        case EEntities.RecruitingOfCompany: {
            const filter = {
                ...store.companyPage.filterRecruitingList,
            };

            return getFilterCount(filter);
        }

        case EEntities.RecruitingOfCandidate: {
            const filter = {
                ...store.candidatePage.filterRecruitingList,
            };

            return getFilterCount(filter);
        }

        case EEntities.Skill: {
            const filter = {
                ...store.skillPage.filterMenu,
            };
            delete filter.usedCountTo;
            delete filter.usedCountFrom;

            return getFilterCount(filter);
        }
        default:
            return;
    }
};

export const getFilterMenuBadgeCount2 = (store: IStore, route: ERoutes) => {
    const filterEmptyValues = (filterMenu: { [key: string]: any }) =>
        filterMenu.filter((value: any) => {
            if (value) {
                if (value.length === 0) {
                    return false;
                }
            }

            if (typeof value === "object" && !isArray(value)) {
                let objectEmpty = true;

                Object.values(value).forEach((valueFromObj: any) => {
                    if (valueFromObj) {
                        objectEmpty = false;
                    }
                });

                return !objectEmpty;
            }

            return Boolean(value);
        });

    switch (route) {
        case ERoutes.Recruitings: {
            const filter = {
                ...store.vacancyPage.vacancyListFilter,
            };

            const filtered = filterEmptyValues(filter);

            return filtered.length;
        }
        default:
            return;
    }
};

export const buildRequestObject = (
    sorting: ISorting,
    filter: IApiBaseFilter | ICompanyFilter | ICandidateFilterApiProperties | IVacancyFilter | IActivityFilter | IRecruitingFilter = {}
) => {
    let requestObject: Partial<IGetRequestObject> = {};

    const orderByProperty = Object.keys(sorting)[0] || "";
    const orderBy = orderByProperty ? orderByProperty : "";
    const orderDirection = sorting[orderByProperty] || "NONE";
    const filterFastSearch = filter.fastSearch || "";

    const detailFilter = Object.entries(filter).reduce((accumulator, currentKeyValue) => {
        const currentKey = currentKeyValue[0];
        const currentValue = currentKeyValue[1];

        if (currentValue === "" || currentValue === undefined) {
            return accumulator;
        }

        if (currentKey === "start" || currentKey === "limit" || currentKey === "fastSearch") {
            return accumulator;
        }

        return { ...accumulator, [currentKey]: currentValue };
    }, {});

    requestObject = {
        orderBy,
        orderDirection,
        filterFastSearch,
        start: filter.start,
        limit: filter.limit,
        ...detailFilter,
    };

    return requestObject;
};
