import React, { memo } from 'react';
import { ICompany, ICompanyContactPartial, ICompanyPartial } from '../../definitions/entities.definition';
import { useDispatch } from 'react-redux';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import styles from './CompanyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

interface IProps {
    company: ICompany|ICompanyPartial;
    companyContact?: ICompanyContactPartial;
    onDelete?: () => void;
    hideIcon?: boolean;
}

const CompanyCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const company = props.company;
    const companyContact = props.companyContact;
    const hideIcon = props.hideIcon || false;

    const label = <>
        <div className={"flexContainerColumn"}>
            <div className={"flex1 ellipseText"}>
                {company.short ? company.short : company.name.substr(0, 5)}
            </div>
            {companyContact &&
                <>
                    <div className={"formContent ellipseText"} style={{display: 'unset', color: 'var(--brainz-darker)',fontSize: 10}}>{companyContact.fullName}</div>
                </>
            }
        </div>
    </>

    const tooltip = <>
        {company.name}
        {companyContact &&
            <>({companyContact.fullName})</>
        }
    </>

    return (
        <Tooltip title={tooltip} placement="top-start">
            <Chip
                key={company.id}
                icon={hideIcon ? undefined : IconFactory.getIconComponent(EIcons.Apartment, {color: company.isCustomer ? darker : 'white'})}
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                color="primary"
                label={label}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.company, company.id));
                }}
            />
        </Tooltip>
    );
};

export default memo(CompanyCellRenderer);
