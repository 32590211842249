import React from 'react';
import { TreeItemProps } from '@material-ui/lab/TreeItem/TreeItem';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { ISkillTreeNode } from '../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { setSkillPageSkillToEditAction } from '../../../redux/skillPage/skillPage.actions';
import { Checkbox, Tooltip } from '@material-ui/core';
import { updateSkillAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';

//@ts-ignore
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

interface IProps extends TreeItemProps {
    node: ISkillTreeNode;
    selectable?: boolean;
    selected: boolean;
    onCheck?: (treeNode: ISkillTreeNode) => void;
    onUncheck?: (treeNode: ISkillTreeNode) => void;
}

const SkillTreeItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const classes = useTreeItemStyles();
    const {...other} = props;

    return (
        <>
            <TreeItem
                onClick={() => {
                    if (props.selectable || !props.onCheck) {
                        return;
                    }

                    props.onCheck(props.node);
                }}
                onDoubleClick={(event) => {
                    if (props.node.children.length === 0) {
                        dispatch(setSkillPageSkillToEditAction(props.node.id))
                    }
                }}
                label={
                    <div className={classes.labelRoot}>
                        { props.selectable &&
                            <Checkbox
                                checked={props.selected}
                                onChange={(event) => {
                                    if (!props.onCheck || !props.onUncheck) {
                                        return;
                                    }
                                    if (props.selected) {
                                        props.onUncheck(props.node);
                                        return;
                                    }

                                    props.onCheck(props.node);
                                }}
                            />
                        }
                        &nbsp;
                        { !props.node.hasKeywords ?
                            <CustomClickableIcon
                                onClick={() => {
                                    const words = props.node.text.split(" ");
                                    dispatch(updateSkillAction(token, {
                                        id: props.node.id,
                                        keywords: words[words.length - 1]
                                    }, false, false));
                                }}
                                icon={EIcons.KeyboardHide}
                                color={'white'}
                            />
                                :
                            <Tooltip title={props.node.keywords}>
                                { IconFactory.getIconComponent(
                                    EIcons.Keyboard , {
                                        color: props.node.generateMatchingCache ? 'red' : 'green'
                                    })
                                }
                            </Tooltip>
                        }
                        &nbsp;
                        <Typography variant="body2">
                            <CustomClickableIcon
                                onClick={() => dispatch(setSkillPageSkillToEditAction(props.node.id))}
                                icon={EIcons.Edit}
                            />
                        </Typography>
                        <Typography variant="body2" className={classes.labelText}>
                            {props.node.text}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {props.node.children.length > 0 ? `${props.node.countUsed} (${props.node.childrenCountUsed})` : props.node.countUsed}
                        </Typography>
                    </div>
                }
                classes={{
                    root: classes.root,
                    content: classes.content,
                    group: classes.group,
                    label: classes.label,
                }}
                {...other}
            />
        </>
    );
}

export default SkillTreeItem;
