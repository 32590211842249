import { MenuItem } from '@material-ui/core';
import React from 'react';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: (string: 'month'|'year') => void;
    value: 'month'|'year';
}

const YearOrMonthDropDown: React.FC<IProps> = (props) => {
    const menuItems = [
        <MenuItem key={1} value={'year'}>
            Jahresgehalt
        </MenuItem>,
        <MenuItem key={2} value={'month'}>
            Monatsgehalt
        </MenuItem>,
    ];

    return (
        <CustomDropDownInput
            required
            onChange={(event) => props.onChange(event.target.value)}
            value={props.value}
            id={"year-month-rating-dropdown"}
            label={'Gehaltsangabe in'}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default YearOrMonthDropDown;
