export interface ISkillTreeFilter {
    filterFastSearch?: string;
    usedCountFrom?: number;
    usedCountTo?: number;
    onlyWithUsages?: boolean;
    onlyWithoutUsages?: boolean;
    onlyWithKeywords?: boolean;
    onlyWithoutKeywords?: boolean;
}

export interface ISkillTreeFilterMenu {
    usedCountFrom?: number;
    usedCountTo?: number;
    usageFilter?: 'with'|'without'|'range';
    keywordsFilter?: 'with'|'without';
}

export interface ISkillPageState {
    editViewLoading: boolean;
    externalSkillEditViewLoading: boolean;
    filter: ISkillTreeFilter;
    filterMenu: ISkillTreeFilterMenu;
    skillToEdit: number;
    externalSkillToEdit: number;
    skillToDelete: number;
    addDialogOpen: boolean;
    reload: boolean;
    reloadTree: boolean;
}

export const preloadedStateSkillPage: ISkillPageState = {
    addDialogOpen: false,
    externalSkillEditViewLoading: false,
    filter: {},
    filterMenu: {},
    skillToEdit: 0,
    externalSkillToEdit: 0,
    skillToDelete: 0,
    editViewLoading: false,
    reload: false,
    reloadTree: false,
}
