import { asyncActionCreator } from '../../utils/actions.utils';
import { ClientApi } from '../../requests/ClientApi';
import { getCompanyMetricsDataRouteConfig, getEmployeePerformanceDataRouteConfig } from '../../requests/routes';
import { IGetRequestObject } from '../../definitions/requests.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';
import { ISetAnalyticsPageCompanyMetricsSortingOptionAction } from '../../definitions/redux/actions.definition';

export const ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA = "ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA";
export const ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_FULFILLED = "ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_FULFILLED";
export const ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_PENDING = "ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_PENDING";
export const ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_REJECTED = "ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA_REJECTED";
export const ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA = "ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA";
export const ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_FULFILLED = "ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_FULFILLED";
export const ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_PENDING = "ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_PENDING";
export const ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_REJECTED = "ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA_REJECTED";
export const ANALYTICS_PAGE_SET_METRICS_VIEW_SORTING_OPTION = "ANALYTICS_PAGE_SET_METRICS_VIEW_SORTING_OPTION";

export const getAnalyticsPageEmployeePerformanceDataAction = (token: string) =>
    asyncActionCreator(ANALYTICS_PAGE_GET_EMPLOYEE_PERFORMANCE_DATA, () =>
        ClientApi.request(getEmployeePerformanceDataRouteConfig, { token })
    );

export const getAnalyticsPageCompanyMetricsDataAction = (token: string, parameters?: Partial<IGetRequestObject>) =>
    asyncActionCreator(ANALYTICS_PAGE_GET_COMPANY_METRICS_DATA, () =>
        ClientApi.request(getCompanyMetricsDataRouteConfig, {
            token,
            ...parameters
        })
    );

export const setAnalyticsPageCompanyMetricsSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetAnalyticsPageCompanyMetricsSortingOptionAction => ({
    type: ANALYTICS_PAGE_SET_METRICS_VIEW_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});
