import React from 'react';
import ApplicationList from '../../../../Shared/ApplicationList/ApplicationList';
import { useCompanyApplicationListHook } from './CompanyApplicationList.hooks';

interface IProps {
    companyId: number;
}

const CompanyApplicationList: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const { loading, applications, total } = useCompanyApplicationListHook(companyId);

    return <>
        <ApplicationList
            applications={applications}
            loading={loading}
            total={total}
            parentView={'company'}
        />
    </>;
}

export default CompanyApplicationList;
