import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EDateRangeMode } from '../../../definitions/filter.definitions';

interface IProps {
    onChange: (value: EDateRangeMode) => void;
    value?: EDateRangeMode;
    label?: string;
}

const DateRangeModeDropDown: React.FC<IProps> = (props) => {
    const modes: EDateRangeMode[] = [
        EDateRangeMode.all,
        EDateRangeMode.lastDays,
        EDateRangeMode.lastWeeks,
        EDateRangeMode.lastMonth,
        EDateRangeMode.year,
        EDateRangeMode.range
    ];

    const menuItems = modes.map((mode: EDateRangeMode, index: number) => (
        <MenuItem key={index + 1} value={mode}>
            {translate('misc.dateRangeFilterMode.' + mode)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(EDateRangeMode.all);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value || EDateRangeMode.all}
            id={"date-selection-mode-dropdown"}
            label={props.label || "Zeitraum"}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default DateRangeModeDropDown;
