import { createStyles, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../translation/translate.utils';
import { EEntities } from '../../definitions/entities.definition';
import CustomFilterMenu from '../CustomFilter/CustomPopupFilterMenu/CustomFilterMenu';

interface IProps {
    translationString?: string;
    string?: ReactNode;
    onClose: () => void;
    filterMenu?: EEntities;
}

const useStyles = makeStyles(
    createStyles({
        header: {
            marginRight: 20
        }
    })
);

const CustomDialogHeader: React.FC<IProps> = (props) => {
    const classes = useStyles();

    return (
        <DialogTitle>
            <div className={"flex flexCentered"}>
                <Typography style={{ color: 'var(--brainz-color-app-bar-heading)' }} variant="h6" noWrap className={classes.header}>
                    {props.string ? props.string : props.translationString ? translate(props.translationString) : ''}
                </Typography>
                { props.filterMenu &&
                    <div style={{marginTop:-15,marginLeft:15}}><CustomFilterMenu entity={props.filterMenu}/></div>
                }
                <div className={"flex flexCentered flexRight"}>
                    {props.children}
                    <IconButton onClick={props.onClose}>
                        {IconFactory.getIconComponent(EIcons.Close)}
                    </IconButton>
                </div>
            </div>
        </DialogTitle>
    );
};

export default CustomDialogHeader;
