import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ECellAlign } from '../../../definitions/components.definitions';
import {
    EEntities,
    ICandidateListModel,
    ICandidatePartial,
    IEmployee,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { updateCandidateAction } from '../../../redux/entities/entities.actions';
import { Rating } from '@material-ui/lab';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { renderCandidateColored } from '../../../utils/candidateTable.utils';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { getToken } from '../../../selectors/app.selectors';
import { setRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import { getDefaultLastActivityColumn } from '../../../utils/components.utils';

interface IProps{
    candidates: ICandidatePartial[];
}

const AssignCandidateToSuggestionTable: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    return (
        <CustomTable
            style={{margin: 5}}
            config={{
                entity: EEntities.CandidateBirthday,
                sortedEntries: props.candidates,
                count: props.candidates.length,
                columnConfig: {
                    top: {
                        header: 'TOP',
                        property: "topCandidate",
                        width: 55,
                        cellRenderer: (topCandidate: boolean, candidate: TBrainzEntity) => {
                            const candidateCast = candidate as ICandidateListModel;

                            return (<Rating
                                size={"large"}
                                name={"topCandidate" + candidate.id}
                                value={topCandidate ? 1 : 0}
                                max={1}
                                onChange={() => {
                                    dispatch(updateCandidateAction(token, {
                                        id: candidateCast.id,
                                        topCandidate: !candidateCast.topCandidate
                                    }));
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            />);
                        },
                    },
                    name: {
                        header: translate('pages.candidate.properties.name'),
                        property: 'name',
                        cellRenderer: (value, entity: TBrainzEntity) => {
                            const candidate = entity as ICandidateListModel;

                            return renderCandidateColored(candidate, value);
                        },
                    },
                    firstName: {
                        header: translate('pages.candidate.properties.firstName'),
                        property: 'firstName',
                        cellRenderer: (value, entity: TBrainzEntity) => {
                            const candidate = entity as ICandidateListModel;

                            return renderCandidateColored(candidate, value);
                        },
                    },
                    age: {
                        header: translate('pages.candidate.properties.age'),
                        property: 'age',
                        width: 70,
                        cellRenderer: (age: number) => {
                            if (age === 0) {
                                return <div>-</div>;
                            }
                            return age;
                        },
                    },
                    zipCode: {
                        header: translate('misc.zipCode'),
                        property: 'zipCode',
                        width: 90
                    },
                    currentJobDescription: {
                        header: translate('pages.candidate.properties.currentJobDescription'),
                        property: 'currentJobDescription',
                        flex: 2
                    },
                    lastActivity: getDefaultLastActivityColumn(),
                    responsible: {
                        header: translate('misc.employeeShort'),
                        property: 'responsible',
                        width: 60,
                        align: ECellAlign.center,
                        cellRenderer: (employee: IEmployee) => {
                            if (!employee) {
                                return translate('misc.noInformation');
                            }
                            return <EmployeeCellRenderer employee={employee}/>;
                        },
                    },
                },
                onRowClick: (candidateId: number, entity) => {
                    dispatch(setRecruitingPageSelectCandidateForAssignCandidateToSuggestionViewAction(entity as ICandidatePartial))
                }
            }}
        />
    );
};

export default AssignCandidateToSuggestionTable;

