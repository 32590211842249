import { IStore } from '../definitions/redux/store.definitions';
import { IPerson, IUser } from '../definitions/entities.definition';

export const getLoggedInNameInformation = (store: IStore): IPerson => {
    if (store.actor.data && store.actor.data.employee) {
        return {
            name: store.actor.data.employee.name,
            firstName: store.actor.data.employee.firstName,
            fullName: store.actor.data.employee.fullName,
            mailAddress: store.actor.data.employee.mailAddress
        };
    }

    if (store.actor.data && store.actor.data.companyContact) {
        return {
            name: store.actor.data.companyContact.name,
            firstName: store.actor.data.companyContact.firstName,
            fullName: store.actor.data.companyContact.fullName,
            mailAddress: store.actor.data.companyContact.mailAddress
        };
    }

    //@todo solve better
    return {
        name: '',
        firstName: '',
        fullName: '',
        mailAddress: ''
    };
};


export const getLoggedInUserFromStore = (store: IStore): IUser => {
    if (store.actor.data) {
        return store.actor.data.user;
    }

    //@todo solve better
    return {
        id: 0,
        hideUser: true,
        login: '',
        renewPassword: false,
        roles: []
    };
};
