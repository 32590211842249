import {
    IPostVacancyAction,
    ISetCloseViewAction,
    ISetUiCurrentRouteAction,
    ISetVacancyPageAddActivityForSuggestionAction,
    ISetVacancyPageAddSuggestionAction,
    ISetVacancyPageAddVacancyDialogAction,
    ISetVacancyPageApplicationSortingOptionAction,
    ISetVacancyPageCandidateSearchFilterAction,
    ISetVacancyPageCandidateSearchFilterMenuAction,
    ISetVacancyPageCandidateSearchSortingOptionAction,
    ISetVacancyPageDeclineSuggestionAction,
    ISetVacancyPageDeleteSuggestionAction,
    ISetVacancyPageEditViewActivityListFilterAction,
    ISetVacancyPageEditViewActivitySortingOptionAction,
    ISetVacancyPageFastSearchAction,
    ISetVacancyPageFilterAction,
    ISetVacancyPageRecruitingSortingOptionAction,
    ISetVacancyPageSortingOptionAction,
    ISetVacancyPageSuggestionListSortingOptionAction,
    ISetVacancyPageSuggestionListViewFilterAction,
    ISetVacancyPageSuggestionListViewFilterMenuAction,
    ISetVacancyPageSuggestionListViewSortingOptionAction,
    ISetVacancyPageSuggestionSortingOptionAction,
    ISetVacancyPageVacancyToArchiveViewAction,
    ISetVacancyPageVacancyToCopyAction,
    ISetVacancyPageVacancyToDeleteViewAction,
    ISetWebVacancyPageIdToImportAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IVacancyPageState, preloadedStateVacancyPage } from '../../definitions/vacancyPage.definitions';
import {
    VACANCY_PAGE_CLOSE_SUGGESTION_LIST_VIEW,
    VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER,
    VACANCY_PAGE_RESET_SUGGESTION_LIST_VIEW_FILTER,
    VACANCY_PAGE_RESET_SUGGESTION_OF_VACANCY_LIST_FILTER,
    VACANCY_PAGE_SET_ADD_SUGGESTION,
    VACANCY_PAGE_SET_ADD_VACANCY_DIALOG,
    VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER,
    VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU,
    VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING,
    VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER,
    VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    VACANCY_PAGE_SET_EDIT_VIEW_APPLICATION_SORTING_OPTION,
    VACANCY_PAGE_SET_EDIT_VIEW_RECRUITING_SORTING_OPTION,
    VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_ACTIVITY_LIST_FILTER,
    VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_SORTING_OPTION,
    VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DECLINE,
    VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DELETE,
    VACANCY_PAGE_SET_FAST_SEARCH,
    VACANCY_PAGE_SET_FILTER,
    VACANCY_PAGE_SET_RELOAD_VACANCY,
    VACANCY_PAGE_SET_SORTING_OPTION,
    VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER,
    VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER_MENU,
    VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_OPEN,
    VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_SORTING_OPTION,
    VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER,
    VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER_MENU,
    VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_SORTING,
    VACANCY_PAGE_SET_SUGGESTION_TO_ADD_ACTIVITY,
    VACANCY_PAGE_SET_VACANCY_FOR_ARCHIVE_VIEW,
    VACANCY_PAGE_SET_VACANCY_FOR_COPY_VIEW,
    VACANCY_PAGE_SET_VACANCY_FOR_DELETE_VIEW,
} from './vacancyPage.actions';
import {
    ENTITIES_DELETE_ACTIVITY_FULFILLED,
    ENTITIES_DELETE_SUGGESTION_FULFILLED,
    ENTITIES_DELETE_VACANCY_FULFILLED,
    ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED,
    ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_SUGGESTION_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED,
    ENTITIES_GET_SUGGESTION_LIST_FULFILLED,
    ENTITIES_GET_SUGGESTION_PENDING,
    ENTITIES_GET_VACANCY_FULFILLED,
    ENTITIES_GET_VACANCY_PENDING,
    ENTITIES_POST_RECRUITING_FULFILLED,
    ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED,
    ENTITIES_POST_SUGGESTION_FULFILLED,
    ENTITIES_POST_VACANCY_FULFILLED,
    ENTITIES_POST_VACANCY_PENDING,
    ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED,
    ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED,
    ENTITIES_PUT_SUGGESTION_FULFILLED,
    ENTITIES_PUT_SUGGESTION_REJECTED,
    ENTITIES_PUT_VACANCY_FULFILLED,
    ENTITIES_PUT_VACANCY_PENDING,
} from '../entities/entities.actions';
import { WEB_VACANCY_PAGE_SET_ID_TO_IMPORT } from '../webVacancyPage/webVacancyPage.actions';
import { UI_SET_CLOSE_VIEW, UI_SET_CURRENT_ROUTE } from '../ui/ui.actions';
import { ERoutes } from '../../definitions/app.definitions';
import { preloadedStateCandidatePage } from '../../definitions/candidatePage.definitions';
import { ISuggestionFilterMenu } from '../../definitions/recruitingPage.definitions';
import { EEntityView } from '../../definitions/ui.definitions';

const vacancyPageReducer = (state = preloadedStateVacancyPage, action: TReducerAction): IVacancyPageState => {
    switch (action.type) {
        case UI_SET_CURRENT_ROUTE: {
            const actionCast = action as ISetUiCurrentRouteAction;

            if (actionCast.payload !== ERoutes.Recruitings) {
                return {
                    ...state,
                    vacancyListFilter: {
                        fastSearch: '',
                        start: 0,
                        limit: 50,
                        responsibleEmployeeId: 0
                    }
                }
            }

            return {
                ...state
            }
        }
        case ENTITIES_DELETE_SUGGESTION_FULFILLED: {
            return {
                ...state,
                reloadSuggestions: true
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_OPEN: {
            const actionCast = action as ISetVacancyPageSuggestionListViewFilterAction;

            return {
                ...state,
                suggestionListViewOpen: true,
                suggestionListViewFilter: {
                    ...actionCast.payload
                },
                suggestionOfVacancyFilter: {
                    ...actionCast.payload
                },
                suggestionListViewFilterMenu: actionCast.payload as ISuggestionFilterMenu
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER: {
            const actionCast = action as ISetVacancyPageSuggestionListViewFilterAction;

            return {
                ...state,
                suggestionListViewFilter: actionCast.payload
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER: {
            const actionCast = action as ISetVacancyPageSuggestionListViewFilterAction;

            return {
                ...state,
                suggestionOfVacancyFilter: {
                    ...state.suggestionOfVacancyFilter,
                    ...actionCast.payload
                }
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_FILTER_MENU: {
            const actionCast = action as ISetVacancyPageSuggestionListViewFilterMenuAction;

            return {
                ...state,
                suggestionOfVacancyFilterMenu: {
                    ...state.suggestionOfVacancyFilterMenu,
                    ...actionCast.payload
                }
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_FILTER_MENU: {
            const actionCast = action as ISetVacancyPageSuggestionListViewFilterMenuAction;

            return {
                ...state,
                suggestionListViewFilterMenu: actionCast.payload
            }
        }
        case VACANCY_PAGE_RESET_SUGGESTION_LIST_VIEW_FILTER: {
            return {
                ...state,
                suggestionListViewFilter: {},
                suggestionListViewFilterMenu: {}
            }
        }
        case VACANCY_PAGE_RESET_SUGGESTION_OF_VACANCY_LIST_FILTER: {
            return {
                ...state,
                suggestionOfVacancyFilter: {},
                suggestionOfVacancyFilterMenu: {}
            }
        }
        case VACANCY_PAGE_CLOSE_SUGGESTION_LIST_VIEW: {
            return {
                ...state,
                suggestionListViewOpen: false,
                suggestionListViewFilter: {},
                suggestionOfVacancyFilter: {},
                suggestionListViewFilterMenu: {}
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_OF_VACANCY_LIST_SORTING: {
            const actionCast = action as ISetVacancyPageSuggestionListSortingOptionAction;

            return {
                ...state,
                suggestionOfVacancySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            }
        }
        case VACANCY_PAGE_SET_SUGGESTION_LIST_VIEW_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageSuggestionListViewSortingOptionAction;

            return {
                ...state,
                suggestionListViewSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            }
        }
        case VACANCY_PAGE_SET_CANDIDATE_SEARCH_SORTING: {
            const actionCast = action as ISetVacancyPageCandidateSearchSortingOptionAction;

            return {
                ...state,
                sortingCandidateList: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            }
        }
        case ENTITIES_GET_RECRUITING_LIST_BY_VACANCY_FULFILLED: {
            return {
                ...state,
                reloadRecruitingsOfVacancy: false
            }
        }
        case VACANCY_PAGE_SET_ADD_SUGGESTION: {
            const actionCast = action as ISetVacancyPageAddSuggestionAction;
            return {
                ...state,
                addSuggestionView: actionCast.payload
            };
        }
        case VACANCY_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageSortingOptionAction;

            return {
                ...state,
                vacancyListSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case VACANCY_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetVacancyPageFastSearchAction;

            return {
                ...state,
                vacancyListFilter: {
                    ...state.vacancyListFilter,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case VACANCY_PAGE_SET_FILTER: {
            const actionCast = action as ISetVacancyPageFilterAction;

            return {
                ...state,
                vacancyListFilter: {
                    ...state.vacancyListFilter,
                    ...actionCast.payload
                },
            };
        }
        case VACANCY_PAGE_SET_ADD_VACANCY_DIALOG: {
            const actionCast = action as ISetVacancyPageAddVacancyDialogAction;
            return {
                ...state,
                addVacancyDialogOpen: actionCast.payload.open,
                addVacancyDialogForCompanyId: actionCast.payload.open ? actionCast.payload.companyId : undefined
            };
        }
        case ENTITIES_POST_VACANCY_FULFILLED: {
            const actionCast = action as IPostVacancyAction;

            return {
                ...state,
                editViewLoading: false,
                addVacancyDialogOpen: false,
                copyVacancyId: 0,
                suggestionListViewFilter: {},
                suggestionOfVacancyFilter: {},
            };
        }
        case ENTITIES_PUT_VACANCY_FULFILLED: {
            return {
                ...state,
                editViewLoading: false,
                initialSuggestionsLoaded: false,
                copyVacancyId: 0,
                suggestionToEdit: 0,
                addSuggestionView: {
                    open: false
                },
            };
        }
        case VACANCY_PAGE_SET_VACANCY_FOR_ARCHIVE_VIEW: {
            const actionCast = action as ISetVacancyPageVacancyToArchiveViewAction;
            return {
                ...state,
                vacancyToArchive: actionCast.payload,
            };
        }
        case VACANCY_PAGE_SET_VACANCY_FOR_DELETE_VIEW: {
            const actionCast = action as ISetVacancyPageVacancyToDeleteViewAction;
            return {
                ...state,
                vacancyToDelete: actionCast.payload,
            };
        }
        case WEB_VACANCY_PAGE_SET_ID_TO_IMPORT: {
            const actionCast = action as ISetWebVacancyPageIdToImportAction;

            if (actionCast.payload > 0) {
                return {
                    ...state,
                    addVacancyDialogOpen: true
                };
            }

            return {
                ...state
            }
        }
        case VACANCY_PAGE_SET_VACANCY_FOR_COPY_VIEW: {
            const actionCast = action as ISetVacancyPageVacancyToCopyAction;

            if (actionCast.payload > 0) {
                return {
                    ...state,
                    copyVacancyId: actionCast.payload,
                    addVacancyDialogOpen: true
                };
            }

            return {
                ...state
            }
        }
        case ENTITIES_GET_OPEN_VACANCY_LIST_FULFILLED: {
            return {
                ...state,
                initialOpenVacanciesLoaded: true,
            };
        }
        case ENTITIES_DELETE_VACANCY_FULFILLED: {
            return {
                ...state,
                vacancyToArchive: 0,
                initialSuggestionsLoaded: false,
                copyVacancyId: 0,
                suggestionToEdit: 0,
                addSuggestionView: {
                    open: false
                },
            };
        }

        /**
         * DATENSATZ ZUM BEARBEITEN LADEN JA/NEIN
         */
        case ENTITIES_PUT_VACANCY_PENDING:
        case ENTITIES_POST_VACANCY_PENDING:
        case ENTITIES_GET_VACANCY_PENDING: {
            return {
                ...state,
                editViewLoading: true
            };
        }
        case VACANCY_PAGE_SET_RELOAD_VACANCY: {
            return {
                ...state,
                reloadVacancy: true
            }
        }
        case UI_SET_CLOSE_VIEW: {
            const actionCast = action as ISetCloseViewAction;

            if (actionCast.payload.view === EEntityView.vacancy) {
                return {
                    ...state,
                    suggestionOfVacancyFilter: preloadedStateVacancyPage.suggestionOfVacancyFilter,
                    activityFilter: preloadedStateVacancyPage.activityFilter,
                    suggestionActivityFilter: preloadedStateVacancyPage.suggestionActivityFilter
                }
            }

            return {
                ...state
            }
        }
        case ENTITIES_GET_VACANCY_FULFILLED: {
            return {
                ...state,
                reloadVacancy: false,
                editViewLoading: false,
            };
        }
        case VACANCY_PAGE_RESET_CANDIDATE_SEARCH_FILTER: {
            return {
                ...state,
                filterCandidateList: {
                    ...preloadedStateCandidatePage.filter,
                    distanceSearchCompanyLocationId: state.filterCandidateList.distanceSearchCompanyLocationId,
                    distanceSearchKm: state.filterCandidateList.distanceSearchKm,
                    searchForVacancyId: state.filterCandidateList.searchForVacancyId,
                },
                filterMenuCandidateList: {
                    ...preloadedStateCandidatePage.filterMenu,
                    distanceSearchCompanyLocation: state.filterMenuCandidateList.distanceSearchCompanyLocation,
                    distanceSearchKm: state.filterMenuCandidateList.distanceSearchKm,
                },
            };
        }
        case VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER: {
            const actionCast = action as ISetVacancyPageCandidateSearchFilterAction;

            return {
                ...state,
                filterCandidateList: {
                    ...state.filterCandidateList,
                    ...actionCast.payload
                }
            }
        }
        case VACANCY_PAGE_SET_CANDIDATE_SEARCH_FILTER_MENU: {
            const actionCast = action as ISetVacancyPageCandidateSearchFilterMenuAction;

            return {
                ...state,
                filterMenuCandidateList: {
                    ...state.filterMenuCandidateList,
                    ...actionCast.payload
                }
            }
        }

        case ENTITIES_GET_SUGGESTION_PENDING: {
            return {
                ...state,
                suggestionEditViewLoading: true,
            };
        }
        case ENTITIES_GET_SUGGESTION_FULFILLED: {
            return {
                ...state,
                suggestionEditViewLoading: false,
            };
        }

        /**
         * ACTIVITY
         */
        case VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageEditViewActivitySortingOptionAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case VACANCY_PAGE_SET_EDIT_VIEW_ACTIVITY_LIST_FILTER: {
            const actionCast = action as ISetVacancyPageEditViewActivityListFilterAction;
            return {
                ...state,
                activityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }
        case VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_ACTIVITY_LIST_FILTER: {
            const actionCast = action as ISetVacancyPageEditViewActivityListFilterAction;
            return {
                ...state,
                suggestionActivityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }


        /**
         * SUGGESTIONS
         */
        case VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DELETE: {
            const actionCast = action as ISetVacancyPageDeleteSuggestionAction;
            return {
                ...state,
                suggestionToDelete: actionCast.payload,
            };
        }
        case VACANCY_PAGE_SET_SUGGESTION_TO_ADD_ACTIVITY: {
            const actionCast = action as ISetVacancyPageAddActivityForSuggestionAction;
            return {
                ...state,
                suggestionToAddAction: actionCast.payload,
            };
        }
        case VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_TO_DECLINE: {
            const actionCast = action as ISetVacancyPageDeclineSuggestionAction;
            return {
                ...state,
                suggestionToDecline: actionCast.payload,
            };
        }
        case ENTITIES_DELETE_ACTIVITY_FULFILLED:
        case ENTITIES_POST_SUGGESTION_ACTIVITY_FULFILLED: {
            return {
                ...state,
                reloadSuggestions: true
            }
        }
        case ENTITIES_POST_SUGGESTION_FULFILLED: {
            return {
                ...state,
                addSuggestionView: {
                    open: false
                },
                suggestionToEdit: 0,
                reloadSuggestions: true
            }
        }
        case ENTITIES_PUT_SUGGESTION_AND_RELOAD_LIST_FULFILLED: {
            return {
                ...state,
                addSuggestionView: {
                    open: false
                },
                suggestionToEdit: 0,
                reloadSuggestions: true
            }
        }
        case ENTITIES_PUT_SUGGESTION_REJECTED:
            return {
                ...state,
                reloadSuggestions: true
            }
        case ENTITIES_POST_RECRUITING_FULFILLED:
        case ENTITIES_PUT_DECLINE_SUGGESTION_FULFILLED:
        case ENTITIES_PUT_ACCEPT_SUGGESTION_FULFILLED: {
            return {
                ...state,
                reloadRecruitingsOfVacancy: true,
                reloadSuggestions: true
            }
        }
        case ENTITIES_PUT_SUGGESTION_FULFILLED: {
            return {
                ...state,
                addSuggestionView: {
                    open: false
                },
                suggestionToEdit: 0,
            }
        }
        case ENTITIES_GET_SUGGESTION_LIST_FULFILLED:
        case ENTITIES_GET_SUGGESTION_LIST_FOR_VACANCY_FULFILLED: {
            return {
                ...state,
                reloadSuggestions: false
            }
        }

        case VACANCY_PAGE_SET_EDIT_VIEW_SUGGESTION_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageSuggestionSortingOptionAction;

            return {
                ...state,
                suggestionSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }

        case VACANCY_PAGE_SET_EDIT_VIEW_APPLICATION_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageApplicationSortingOptionAction;

            return {
                ...state,
                applicationSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }

        case VACANCY_PAGE_SET_EDIT_VIEW_RECRUITING_SORTING_OPTION: {
            const actionCast = action as ISetVacancyPageRecruitingSortingOptionAction;

            return {
                ...state,
                recruitingSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        default:
            break;
    }

    return state;
};

export default vacancyPageReducer;
