import React from 'react';
import { ILanguage } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import LanguageAutocomplete from '../components/LanguageAutocomplete';

interface IProps {
    value?: ILanguage;
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const LegacyLanguageAutocompleteDropDown: React.FC<IProps> = (props) => {
    return <>
        <LanguageAutocomplete
            error={props.error}
            helperText={props.helperText}
            required={props.required}
            value={props.value ? props.value : null}
            onChange={(value: ILanguage|null) => {
                //@ts-ignore
                props.onChange({}, value as ILanguage)
            }}
            label={translate('misc.language')}
        />
    </>
};

export default LegacyLanguageAutocompleteDropDown;
