import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../../../selectors/app.selectors';
import { deleteTodoAction } from '../../../../redux/entities/entities.actions';
import { setTodoToDeleteAction, setTodoToEditAction } from '../../../../redux/todo/todo.actions';

const TodoDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.todo.todoToDelete));
    const idToDelete = useSelector((store: IStore) => store.todo.todoToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setTodoToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteTodoAction(token, idToDelete));
                dispatch(setTodoToDeleteAction(0));
                dispatch(setTodoToEditAction(0));
            }}
            titleTranslation={"pages.todo.deleteDialog.title"}
            bodyTranslation={"pages.todo.deleteDialog.body"}
        />
    );
};

export default TodoDeleteView;
