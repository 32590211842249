import { IStore } from '../definitions/redux/store.definitions';
import { ITodo } from '../definitions/entities.definition';
import Moment from 'moment';

export const getTodosOfLoggedInEmployeeFromStore = (store: IStore) => {
    const todosById = store.entities.todo.byId;
    const order = store.entities.todo.orderOfLoggedInEmployee;
    const todos: ITodo[] = [];

    order.forEach((todoId) => {
        const todo = todosById[todoId];
        todos.push(todo);
    });

    return todos;
};

export const getDueTodosOfLoggedInEmployeeFromStore = (store: IStore) => {
    const todosById = store.entities.todo.byId;
    const order = store.entities.todo.orderOfLoggedInEmployee;
    const todos: ITodo[] = [];

    order.forEach((todoId) => {
        const todo = todosById[todoId];
        todos.push(todo);
    });

    return todos;
};

export const getTodosWithoutDueFromStore = (store: IStore) => {
    const employee = store.calendarPage.todoFilter.employeeId;
    const todosById = store.entities.todo.byId;
    const todosByEmployee = store.entities.todo.withoutDueByEmployee[employee] || [];
    const todos: ITodo[] = [];

    todosByEmployee.forEach((todoId) => todos.push(todosById[todoId]));

    return todos;
};

export const getTodosForCalendarView = (store: IStore) => {
    const todosById = store.entities.todo.byId;
    const calendarFilter = store.calendarPage.calendarFilter;

    const todos = Object.values(todosById);
    return todos.filter((todo) => {
        if (!todo.dueDate) {
            return false;
        }
        if (calendarFilter.employeeIds) {
            return calendarFilter.employeeIds.indexOf(todo.employee.id) > -1
        }

        return true;
    });
};

export const getOverdueTodosForCalendar = (store: IStore) => {
    const todos = getTodosForCalendarView(store);

    return todos.filter((todo) => {
        return !todo.completed && Moment(todo.dueDateTo).isSameOrBefore(Moment());
    })
}
