import {
    IActorLoginAction,
    IActorRefreshTokenAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { IActorState, preloadedStateActor } from '../../definitions/actor.definitions';
import { ACTOR_LOGIN_FULFILLED, ACTOR_LOGIN_REJECTED, ACTOR_REFRESH_TOKEN_FULFILLED } from './actor.actions';
import jwt_decode from 'jwt-decode';
import { IJwtData } from '../../definitions/app.definitions';

const actorReducer = (state = preloadedStateActor, action: TReducerAction): IActorState => {
    switch (action.type) {
        case ACTOR_LOGIN_REJECTED: {
            return {
                ...state,
                executeLogout: true
            }
        }
        case ACTOR_LOGIN_FULFILLED: {
            const actionCast = action as IActorLoginAction;
            const token = actionCast.payload.token.replace("Bearer ", "");

            const data = jwt_decode(token) as IJwtData;

            return {
                ...state,
                loggedIn: true,
                token: actionCast.payload.token,
                data,
            };
        }
        case ACTOR_REFRESH_TOKEN_FULFILLED: {
            const actionCast = action as IActorRefreshTokenAction;
            const token = actionCast.payload.token.replace("Bearer ", "");

            const data = jwt_decode(token) as IJwtData;

            return {
                ...state,
                token: actionCast.payload.token,
                data,
            };
        }
        default:
            return state;
    }
};

export default actorReducer;
