import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CandidateRecruitingList from './components/CandidateRecruitingList';
import { setRecruitingPageStartRecruitingAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import FastSearchPanelRecruitingList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelRecruitingList';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiExpandCollapseRecruitingListItem } from '../../../../redux/ui/ui.actions';

interface IProps {
    candidateId: number;
}

const CandidateRecruitingPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const candidateId = props.candidateId;
    const collapseOrExpandAll = useSelector((store: IStore) => store.ui.expandOrCollapseRecruitingListItems);

    return <>
        <div className={"flexColumn padding5"}>
            <FastSearchPanelRecruitingList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                actionButtons={[{
                    action: () => dispatch(setRecruitingPageStartRecruitingAction({
                        open: true,
                        candidateId
                    })),
                    tooltip: "Kandidat vorschlagen",
                    icon: EIcons.Add
                }, {
                    action: () => dispatch(setUiExpandCollapseRecruitingListItem(collapseOrExpandAll === 'expand' ? 'collapse' : 'expand')),
                    tooltip: collapseOrExpandAll === 'expand' ? 'Alle zuklappen' : 'Alle aufklappen',
                    icon: collapseOrExpandAll === 'expand' ? EIcons.FullscreenExit : EIcons.Fullscreen
                }]}
            />
            <CandidateRecruitingList candidateId={candidateId}/>
        </div>
    </>;
}

export default React.memo(CandidateRecruitingPanel);
