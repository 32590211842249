import React from 'react';
import { IInvoiceFormState } from '../../../../definitions/billingPage.definitions';
import { translate } from '../../../../translation/translate.utils';

interface IProps {
    formState: IInvoiceFormState
}

const InvoiceFooterView: React.FC<IProps> = (props) => {
    const formState = props.formState;

    return (
        <div className={"flex flexCentered"}>
            <div className={"flex flexCentered"}>
                &nbsp;
            </div>
            <div style={{width: 150, fontWeight: "bold"}}>
                { translate('pages.billing.totalNet')}: <br/>
                { translate('pages.billing.vat')}: <br/>
                { translate('pages.billing.vatSum')}: <br/>
                { translate('pages.billing.totalGross')}: <br/>
            </div>
            <div style={{width: 70, textAlign: "right", paddingRight: 25}}>
                {formState.totalNet.toLocaleString("de-DE")} € <br/>
                {formState.vat}.00 % <br/>
                {formState.vatSum.toLocaleString("de-DE")} € <br/>
                {formState.totalGross.toLocaleString("de-DE")} € <br/>
            </div>
        </div>
    );
}

export default InvoiceFooterView;
