import React from 'react';
import { EEntities, IWebVacancyListModel } from '../../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../../translation/translate.utils';
import {
    setCompanyPageCompanyContactSortingOptionAction,
    setCompanyPageCompanyContactToDeleteAction,
} from '../../../../../redux/companyPage/companyPage.actions';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { ECellAlign, ESortingOptions } from '../../../../../definitions/components.definitions';
import { setUiCompanyContactToEditAction } from '../../../../../redux/ui/ui.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import { getWebVacancyListOfCompanyAction } from '../../../../../redux/entities/entities.actions';
import { COLOR_BLUE } from '../../../../../theme/theme';
import { useCompanyWebVacancyListHook } from './CompanyWebVacancyList.hooks';
import DateDiffCellRenderer from '../../../../../components/CustomCellRenderer/DateDiffCellRenderer';
import WebVacancyTitleCellRenderer from '../../../../../components/CustomCellRenderer/WebVacancyTitleCellRenderer';

interface IProps {
    companyId: number;
}

const CompanyWebVacancyList: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const { loading, webVacancies, total} = useCompanyWebVacancyListHook(companyId);

    return (
        <CustomTable
            config={{
                color: COLOR_BLUE,
                entity: EEntities.WebVacancy,
                loading,
                columnConfig: {
                    title: {
                        header: translate('pages.vacancy.properties.title') + ' / ' + translate('pages.webVacancy.locations'),
                        property: "title",
                        cellRenderer: (title: string, entity) => {
                            const webVacancy = entity as IWebVacancyListModel;
                            return <WebVacancyTitleCellRenderer webVacancy={webVacancy} />
                        }
                    },
                    dateOfPublication: {
                        header: translate('pages.webVacancy.onlineSince'),
                        property: "dateOfPublication",
                        width: 120,
                        align: ECellAlign.right,
                        cellRenderer: (created: string) => <DateDiffCellRenderer date={created}  beforeText={"seit"} />,
                    },
                },
                sortedEntries: webVacancies,
                onRowDoubleClick: (contactId: number) => dispatch(setUiCompanyContactToEditAction(contactId)),
                listActionButtons: [
                    {
                        action: (contactId: number) => dispatch(setCompanyPageCompanyContactToDeleteAction(contactId)),
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    },
                ],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCompanyPageCompanyContactSortingOptionAction(propertyToSort, sortOption)),
                },
                total: total,
                onReload: () => {
                    dispatch(getWebVacancyListOfCompanyAction(
                        token,
                        companyId
                    ));
                }
            }}
        />
    );
};

export default CompanyWebVacancyList;
