import React from 'react';
import { EEntities, IRecruitingDocument } from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../translation/translate.utils';
import { getToken } from '../../../../selectors/app.selectors';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../definitions/components.definitions';
import { themeOrange } from '../../../../theme/theme';
import {
    getDocumentsForRecruiting,
    getRecruitingPageRequestObjectForDocumentsInEditView,
} from '../../../../selectors/recruitingDocument.selectors';
import {
    setRecruitingPageDocumentSortingOptionAction,
    setRecruitingPageDocumentToDeleteAction,
} from '../../../../redux/recruitingPage/recruitingPage.actions';
import {
    downloadRecruitingDocument,
    getDocumentsForRecruitingAction,
} from '../../../../redux/entities/entities.actions';
import { API_URL } from '../../../../requests/routes';
import { IStore } from '../../../../definitions/redux/store.definitions';

interface IProps {
    recruitingId: number;
}

const RecruitingDocumentsList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const recruitingId = props.recruitingId;

    const documents = useSelector((store: IStore) => getDocumentsForRecruiting(store, recruitingId));
    const token = useSelector(getToken);
    const documentsRequestObject = useSelector(getRecruitingPageRequestObjectForDocumentsInEditView);

    return (
        <CustomTable
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.RecruitingDocument,
                columnConfig: {
                    fileName: {
                        header: translate('pages.candidateDocument.properties.fileName'),
                        property: "displayFileName",
                    },
                },
                sortedEntries: documents,
                listActionButtons: [
                    {
                        action: (documentId: number) => dispatch(setRecruitingPageDocumentToDeleteAction(documentId)),
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    },
                    {
                        action: (documentId: number) => downloadRecruitingDocument(token, documentId),
                        translationKey: "tables.startDownload",
                        icon: EIcons.Download,
                    },
                    {
                        action: (documentId: number, document) => {
                            const recruitingDocument = document as IRecruitingDocument;
                            window.open(`${API_URL}/recruiting-document/${recruitingDocument.id}/preview?Authorization=${token}`)
                        },
                        translationKey: "misc.openPreview",
                        icon: EIcons.FindInPage,
                    }
                ],
                onRowDoubleClick: (documentId: number, document) => {
                    const recruitingDocument = document as IRecruitingDocument;
                    window.open(`${API_URL}/recruiting-document/${recruitingDocument.id}/preview?Authorization=${token}`)
                },
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setRecruitingPageDocumentSortingOptionAction(propertyToSort, sortOption)),
                },
                count: documents.length,
                total: documents.length,
                onReload: () => {
                    dispatch(getDocumentsForRecruitingAction(
                        token,
                        recruitingId,
                        documentsRequestObject
                    ));
                }
            }}
        />
    );
};

export default RecruitingDocumentsList;
