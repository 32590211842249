import React from 'react';
import { ICompanyLocation, ICompanyLocationPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CustomAutocompleteDropDown from './CustomAutocompleteDropDown';
import { useLoadAllLocations, useLoadLocationsOfCompany } from './CompanyLocationAutocompleteDropDown.hook';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: null|ICompanyLocation|ICompanyLocationPartial) => void;
    value?: null|ICompanyLocation|ICompanyLocationPartial;
    label?: string;
    error?: boolean;
    helperText?: string;
    onAdd?: () => void;
    companyId?: null|number;
    filterByCompany?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
}

const CompanyLocationAutocompleteDropDown: React.FC<IProps> = (props) => {
    let locations: ICompanyLocation[] = [];

    if (props.filterByCompany && props.companyId) {
        locations = useLoadLocationsOfCompany(props.companyId);
    } else if (!props.filterByCompany) {
        locations = useLoadAllLocations();
    }

    return (
        <CustomAutocompleteDropDown
            onAdd={props.onAdd ? () => props.onAdd?.() : undefined}
            error={props.error}
            autoFocus
            helperText={props.helperText}
            options={locations}
            required={props.required}
            disabled={props.disabled}
            defaultValue={props.value}
            onChange={props.onChange}
            label={props.label || translate('pages.companyLocation.companyLocation')}
            getOptionLabel={
                (location: ICompanyLocation) => {
                    if (!props.companyId) {
                        return `${location.company.name} ${location.zipCode} ${location.city}, ${location.address}`
                    }
                    return `${location.zipCode} ${location.city}, ${location.address}`
                }
            }
        />
    );
};

export default CompanyLocationAutocompleteDropDown;
