import { IRouteConfig } from '../definitions/requests.definitions';

let apiUrl = '';
if (process.env.NODE_ENV === 'development') {
    apiUrl = 'http://localhost:8000';
}
if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging') {
        apiUrl = 'https://api-staging.br4inz.de';
    } else {
        apiUrl = 'https://api.br4inz.de';
    }
}
export const API_URL = apiUrl;

/**
 * RECRUITING ROUTES
 */
export const getCustomerRecruitingListRouteConfig: IRouteConfig = {
    url: `${API_URL}/customer/recruitings`,
    method: 'GET',
    optionalParameter: [
        'statusFilter',
        'filterFastSearch'
    ],
}

export const getCustomerContactListRouteConfig: IRouteConfig = {
    url: `${API_URL}/customer/company-contacts`,
    method: 'GET'
}

export const putDeclineRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/customer/recruiting/%recruitingId%/decline`,
    method: 'PUT',
    parameter: ['recruitingId'],
};

export const putAcceptRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/customer/recruiting/%recruitingId%/accept`,
    method: 'PUT',
    parameter: ['recruitingId'],
};

export const putInquireForRecruitingRouteConfig: IRouteConfig = {
    url: `${API_URL}/customer/recruiting/%recruitingId%/inquire`,
    method: 'PUT',
    parameter: ['recruitingId'],
};
