import React, { useEffect } from 'react';
import InvoiceBurnUpChart from './InvoiceBurnUpChart';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    EGroupEvaluationGroupBy,
    getInvoiceGroupedEvaluationAction,
    getTargetByMonthListAction,
} from '../../../../redux/entities/entities.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import CustomDashboardWidgetPaper from '../../../../components/CustomPaper/CustomDashboardWidgetPaper';
import { translate } from '../../../../translation/translate.utils';
import { COLOR_ORANGE } from '../../../../theme/theme';
import { getInvoiceBurnupChartData } from '../../../../utils/billing.utils';

const InvoiceBurnUpChartView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const year = useSelector((state: IStore) => state.dashboardPage.chartYear);
    const chartData = getInvoiceBurnupChartData();

    useEffect(() => {
        dispatch(getInvoiceGroupedEvaluationAction(
            token,
            year,
            EGroupEvaluationGroupBy.GROUP_BY_MONTH
        ));
        dispatch(getInvoiceGroupedEvaluationAction(
            token,
            year,
            EGroupEvaluationGroupBy.GROUP_BY_MONTH_SUMMED_UP
        ));
        dispatch(getTargetByMonthListAction(
            token,
            year
        ));
    }, [dispatch]);

    return (
        <>
            <CustomDashboardWidgetPaper
                icon={EIcons.Flag}
                headline={translate('pages.dashboard.burnUpGoalChart')}
                iconBackgroundColor={COLOR_ORANGE}
            >
                <div style={{flex: 1}}>
                    <InvoiceBurnUpChart chartData={chartData} />
                </div>
            </CustomDashboardWidgetPaper>
        </>
    );
}

export default InvoiceBurnUpChartView;
