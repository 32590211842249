import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import RightDrawerEntityView from './RightDrawerEntityView';

const RightDrawerEntityViewContainer: React.FC = () => {
    const editViews = useSelector((store: IStore) => store.ui.openViews);

    return <>
        {editViews.slice(0).reverse().map((editView, index) => <RightDrawerEntityView
            key={editView.view + '_' + editView.entityId}
            editView={editView}
            open={index === editViews.length - 1}
        /> )}
    </>
}

export default RightDrawerEntityViewContainer;
