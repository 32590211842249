import React from 'react';
import { ITodo } from '../../../definitions/entities.definition';
import { Card, CardHeader } from '@material-ui/core';
import styles from './TodoCard.module.css';
import { useDispatch } from 'react-redux';
import CustomTypography from '../../../components/CustomTypography/CustomTypography';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import CompanyCellRenderer from '../../../components/CustomCellRenderer/CompanyCellRenderer';
import { executeTodoAction, getTodoCardDueDate, getTodoDescription } from '../../../utils/todo.utils';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import { setTodoToDeleteAction } from '../../../redux/todo/todo.actions';
import CompanyContactCellRenderer from '../../../components/CustomCellRenderer/CompanyContactCellRenderer';

interface IProps {
    todo: ITodo;
    formatDueDate?: 'time'|'datetime';
}

const TodoCard: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    const tooltip = (
        <div className={"flexContainerRow alignVerticalCenter gap5"}>
            {props.todo.candidate && <CandidateCellRenderer candidate={props.todo.candidate} /> }
            {props.todo.company && <CompanyCellRenderer company={props.todo.company} /> }
            {props.todo.createdByUser && props.todo.companyContact && <CompanyContactCellRenderer companyContact={props.todo.companyContact} /> }
            {props.todo.dueDate && <CustomTypography
                variant={'body2'}
                value={getTodoCardDueDate(props.todo.dueDate, props.formatDueDate, props.todo.flexibleTime)}
            />}
            <CustomClickableIcon onClick={() => dispatch(setTodoToDeleteAction(props.todo.id))} icon={EIcons.Delete} />
        </div>
    );

    const onClick = () => {
        executeTodoAction(props.todo, dispatch);
    }

    return (
        <>
            <Card className={styles.root} onClick={onClick}>
                <CardHeader
                    title={getTodoDescription(props.todo)}
                    action={tooltip}
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </>
    )
}

export default TodoCard;
