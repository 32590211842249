import React from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import VacancyInvoiceCreationAfterDropDown
    from '../../../components/CustomInput/CustomDropDownInput/VacancyInvoiceCreationAfterDropDown';
import { IBillingSetting } from '../../../definitions/entities.definition';
import BillingVariantTemplateDropDown
    from '../../../components/CustomInput/CustomDropDownInput/BillingVariantTemplateDropDown';

interface IProps {
    billingSetting: IBillingSetting;
    updateBillingSetting: (setting: Partial<IBillingSetting>) => void;
}

const BillingConfigurationTypeTemplateForm: React.FC<IProps> = (props) => {
    const billingSetting = props.billingSetting;
    const updateBillingSetting = props.updateBillingSetting;

    return (
        <>
            <PaperRow>
                <VacancyInvoiceCreationAfterDropDown
                    value={billingSetting.invoiceCreationAfter}
                    onChange={(event) => updateBillingSetting({invoiceCreationAfter: event.target.value})}
                />
            </PaperRow>
            <PaperRow>
                <BillingVariantTemplateDropDown
                    value={billingSetting.billingVariantTemplate?.id || 2}
                    onChange={(billingVariantTemplate) => updateBillingSetting({billingVariantTemplate})}
                />
            </PaperRow>
        </>
    );
}
export default BillingConfigurationTypeTemplateForm;

