import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EEntities, IJobTemplate, IJobTitle } from '../../../definitions/entities.definition';
import { getDefaultCreatedColumn, INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { ESortingOptions } from '../../../definitions/components.definitions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { getJobTitleListAction, updateJobTitleAction } from '../../../redux/entities/entities.actions';
import { getJobTitleListFromStore, getJobTitleListRequestObject } from '../../../selectors/jobTemplate.selectors';
import {
    setJobTemplatePageJobTitleFilterAction,
    setJobTemplatePageJobTitleSortingOptionAction,
} from '../../../redux/jobTemplate/jobTemplatePage.actions';
import { translate } from '../../../translation/translate.utils';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import JobTemplateCellRenderer from '../../../components/CustomCellRenderer/JobTemplateCellRenderer';

const JobTitleList: React.FC = () => {
    const dispatch = useDispatch();
    const jobTemplates = useSelector(getJobTitleListFromStore);
    const loading = useSelector((store: IStore) => store.ui.jobTitlesLoading);
    const filter = useSelector((store: IStore) => store.jobTemplatePage.jobTitleFilter);
    const totalRecords = useSelector((store: IStore) => store.entities.jobTitle.total);
    const requestObject = useSelector(getJobTitleListRequestObject);
    const token = useSelector(getToken);

    const ignoreJobTitle = (jobTitle: IJobTitle) => {
        dispatch(updateJobTitleAction(token, {
            id: jobTitle.id,
            ignore: true
        }))
    }

    return (
        <>
            <CustomTable
                config={{
                    entity: EEntities.JobTitleOverview,
                    columnConfig: {
                        title: {
                            header: 'Job-Titel',
                            property: "title",
                        },
                        jobTemplate: {
                            header: translate('pages.jobTemplate.jobTemplate'),
                            property: "jobTemplate",
                            width: 150,
                            cellRenderer: (jobTemplate: IJobTemplate, entity) => {
                                const jobTitle = entity as IJobTitle;

                                return (
                                    <JobTemplateCellRenderer jobTitleId={jobTitle.id} jobTemplate={jobTemplate} />
                                );
                            },
                        },
                        created: getDefaultCreatedColumn(),
                        remove: {
                            header: IconFactory.getIconComponent(EIcons.Delete),
                            width: 40,
                            property: 'id',
                            cellRenderer: (value: number, entity) => {
                                const jobTitle = entity as IJobTitle;
                                return <CustomClickableIcon onClick={() => ignoreJobTitle(jobTitle)} icon={EIcons.Delete} />
                            }
                        }
                    },
                    sortedEntries: jobTemplates,
                    loading: loading,
                    scrollCallback: () => {
                        if (filter.limit && filter.limit < totalRecords) {
                            dispatch(setJobTemplatePageJobTitleFilterAction({
                                start: 0,
                                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                            }));
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setJobTemplatePageJobTitleSortingOptionAction(propertyToSort, sortOption)),
                    },
                    onReload: () => {
                        dispatch(getJobTitleListAction(token, requestObject));
                    },
                    count: jobTemplates.length,
                    total: totalRecords
                }}
            />
        </>
    );
}

export default JobTitleList;
