import { TSetSortingFunction } from './redux/actions.definition';
import { IChangeEvent, IKeyDownEvent } from './app.definitions';
import React, { ReactNode } from 'react';
import { EIcons } from '../components/Icons/IconFactory';
import { TBrainzEntity } from './entities.definition';

export interface ITableHeaderCallbacks {
    setSorting: TSetSortingFunction;
}

export type TListAction = (entityId: number, entity: TBrainzEntity, index: number) => void;

export interface IListActionButtonConfig {
    action?: TListAction;
    condition?: (entity: TBrainzEntity) => boolean;
    translationKey?: string;
    label?: ReactNode;
    icon?: EIcons;
}

export enum EExecuteSave {
    save,
    saveAndClose,
    nothing
}

export interface IAppBarActionButtonConfig {
    action: () => void;
    icon: EIcons;
    tooltip?: string;
}

export enum ESortingOptions {
    Ascending = 'ASC',
    Descending = 'DESC',
    None = 'NONE',
}

export interface ICustomPaperInput {
    labelTranslation: string;
    multiline?: boolean;
    rows?: number;
    value: any;
    onKeyDown?: (event: IKeyDownEvent) => void;
    onChange: (event: IChangeEvent) => void;
    type?: React.InputHTMLAttributes<unknown>['type'];
    style?: React.CSSProperties;
}

export enum ECellAlign {
    left,
    center,
    right
}

export interface ICustomTableColumn {
    header: string | EIcons | ReactNode;
    property: string;
    orderByProperty?: string;
    cellRenderer?: (value: any, record: TBrainzEntity) => ReactNode;
    flex?: number;
    width?: number;
    disableOrder?: boolean;
    align?: ECellAlign;
    editable?: boolean;
}

export interface ISplitButtonConfig {
    label: string;
    action: () => void;
    icon?: EIcons;
    hidden?: boolean;
}

export interface ICustomTableColumnConfig {
    [propertyKey: string]: ICustomTableColumn;
}
export interface IActionTab {
    label: ReactNode;
    onClick: () => void;
}
export interface ITab {
    label: ReactNode;
    component: ReactNode;
    hidden?: boolean;
    error?: boolean;
}

export interface ICustomSpeedDialAction {
    name: string;
    icon: ReactNode;
    onClick: () => void;
}

export enum ECustomTypographyVariant {
    Link = 'link',
    Mail = 'mail',
}

export enum ECalendarDays {
    MONDAY = 'Montag',
    TUESDAY = 'Dienstag',
    WEDNESDAY = 'Mittwoch',
    THURSDAY = 'Donnerstag',
    FRIDAY = 'Freitag',
    SATURDAY = 'Samstag',
    SUNDAY = 'Sonntag',
}
