import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import VacancyEditForm from '../EditViews/Vacancy/component/VacancyEditForm';
import { setVacancyPageAddVacancyDialogAction } from '../../redux/vacancyPage/vacancyPage.actions';
import { resetFormValidation } from '../../redux/error/error.actions';
import { setWebVacancyPageIdToImportAction } from '../../redux/webVacancyPage/webVacancyPage.actions';
import { getWebVacancyAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { Dialog } from '@material-ui/core';

const VacancyAddView: React.FC = () => {
    const open = useSelector((store: IStore) => store.vacancyPage.addVacancyDialogOpen);
    const addForCompanyId = useSelector((store: IStore) => store.vacancyPage.addVacancyDialogForCompanyId);
    const token = useSelector(getToken);
    const importWebVacancyId = useSelector((store: IStore) => store.webVacancyPage.importId);
    const importWebVacancy = useSelector((store: IStore) => store.entities.webVacancy.currentRecord);
    const dispatch = useDispatch();

    useEffect(() => {
        if (importWebVacancyId && !importWebVacancy) {
            dispatch(getWebVacancyAction(
                token,
                importWebVacancyId
            ))
        }
    }, [importWebVacancyId, importWebVacancy]);

    if (importWebVacancyId && !importWebVacancy) {
        return null;
    }

    const onClose = () => {
        dispatch(resetFormValidation());
        dispatch(setVacancyPageAddVacancyDialogAction(false));
        dispatch(setWebVacancyPageIdToImportAction(0));
    };

    return (
        <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString={"pages.vacancy.add"} onClose={onClose} />
            <VacancyEditForm importFromWebVacancy={importWebVacancy} addForCompanyId={addForCompanyId} />
        </Dialog>
    );
};

export default VacancyAddView;
