import React from 'react';
import { COLOR_ORANGE } from '../../../../theme/theme';
import { ArgumentAxis, Chart, LineSeries, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Axis, BarSeries, EventTracker, ScatterSeries, SplineSeries, ValueScale } from '@devexpress/dx-react-chart';
import { IInvoiceGoalBurnUpChartData } from '../../../../definitions/billingPage.definitions';

interface IProps {
    chartData: IInvoiceGoalBurnUpChartData[],
}

const InvoiceBurnUpChart: React.FC<IProps> = (props) => {
    return (
        <>
            <Chart data={props.chartData} height={400}>
                <ValueScale name={"targetCommission"} />
                <ValueScale name={"totalCommission"} />

                <ArgumentAxis labelComponent={(labelProps: Axis.LabelProps) => <ValueAxis.Label {...labelProps} style={{fill: 'var(--brainz-color-text-secondary)'}}/>}/>

                <LineSeries valueField={"targetCommission"} argumentField="month" scaleName={"totalCommission"} />
                <SplineSeries valueField={"totalCommission"} argumentField="month" scaleName={"totalCommission"} color={COLOR_ORANGE}/>
                <ScatterSeries valueField={"totalCommission"} argumentField={"month"} scaleName={"totalCommission"} color={COLOR_ORANGE}/>

                <BarSeries valueField={"monthTotal"} argumentField="month" scaleName={"totalCommission"} barWidth={10} />

                <ValueAxis
                    scaleName={"totalCommission"}
                    showGrid={false}
                    showLine
                    showTicks
                    labelComponent={(labelProps: Axis.LabelProps) => <ValueAxis.Label {...labelProps} style={{fill: 'var(--brainz-color-text-secondary)'}}/>}
                />
                <EventTracker/>
                <Tooltip contentComponent={(contentProps) => {
                    const data = props.chartData[contentProps.targetItem.point];

                    const monthValue = data.monthTotalCommission ? data.monthTotalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                    const totalValue = data.totalCommission ? data.totalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                    const targetValue = data.targetCommission ? data.targetCommission.toLocaleString("de-DE") + ' €' : '0 €';

                    return (
                        <>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Monat:</td>
                                        <td align={"right"}>{monthValue}</td>
                                    </tr>
                                    <tr>
                                        <td>Ist:</td>
                                        <td align={"right"}>{totalValue}</td>
                                    </tr>
                                    <tr>
                                        <td>Soll:</td>
                                        <td align={"right"}>{targetValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )
                }}/>
            </Chart>
        </>

    );
}

export default InvoiceBurnUpChart;
