import React, { ReactNode } from 'react';
import { IVacancyPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getVacancyListRouteConfig, getVacancyRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';

interface IProps {
    value: IVacancyPartial|number|null;
    onChange: (value: IVacancyPartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    companyId?: number;
    onlyWithUrl?: boolean;
}

const VacancyAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as IVacancyPartial);
    }

    const getOptionLabel = (record: TBrainzEntity) => {
        const vacancy = record as IVacancyPartial;

        if (props.companyId) {
            return vacancy.title;
        }

        return `${vacancy.title} (${vacancy.company.name}, ${vacancy.companyLocation.city})`
    }

    return <CustomLivesearch
        id={"vacancy-autocomplete"}
        label={props.label || translate('misc.vacancy')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getVacancyListRouteConfig}
        entityRouteConfig={getVacancyRouteConfig}
        entityRouteParam={"vacancyId"}
        getOptionLabel={getOptionLabel}
        loadAll
        routeParams={{
            orderBy: 'title',
            orderDirection: 'DESC',
            start: 0,
            limit: 50,
            companyId: props.companyId,
            completed: false,
            onlyWithUrl: props.onlyWithUrl
        }}
    />;
}

export default React.memo(VacancyAutocomplete);
