import { useEffect } from 'react';
import { getInvoiceAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setBillingPageCalculateSumFooterAction,
    setBillingPageInvoiceFormAction,
} from '../../../../redux/billingPage/billingPage.actions';
import { IInvoiceItemForm } from '../../../../definitions/billingPage.definitions';

export const useLoadInvoice = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const invoiceId = useSelector((store: IStore) => store.billingPage.invoiceToEdit);
    const invoice = useSelector((state: IStore) => state.entities.invoice.byId[invoiceId]);
    const currentInvoice = useSelector((state: IStore) => state.billingPage.invoiceForm);

    useEffect(() => {
        if (!invoice && invoiceId) {
            dispatch(getInvoiceAction(
                token,
                invoiceId
            ));
            return;
        }

        if (invoice && !currentInvoice.id) {
            const items: IInvoiceItemForm[] = [];
            invoice.items.map((item) => {
                items.push({
                    id: item.id,
                    ranking: item.ranking,
                    comment: item.comment,
                    amount: item.amount,
                    price: item.price,
                    title: item.title,
                    priceTotal: item.priceTotal
                })
            });

            dispatch(setBillingPageInvoiceFormAction({
                id: invoice.id,
                date: new Date(invoice.date),
                dueDate: new Date(invoice.dueDate),
                company: invoice.company,
                companyLocation: invoice.companyLocation,
                companyContact: invoice.companyContact,
                companyName: invoice.companyName,
                address: invoice.address,
                addressLine2: invoice.addressLine2,
                zipCode: invoice.zipCode,
                city: invoice.city,
                totalNet: invoice.totalNet,
                discountInPercent: invoice.discountInPercent,
                discountSum: invoice.discountSum,
                totalNetAfterDiscount: invoice.totalNetAfterDiscount,
                vat: invoice.vat,
                vatSum: invoice.vatSum,
                totalGross: invoice.totalGross,
                comment: invoice.comment,
                openToPay: invoice.openToPay,
                number: invoice.number,
                termsOfPaymentId: invoice.termsOfPayment.id,
                remindOfPaymentCreated: invoice.remindOfPaymentCreated,
                remindOfPaymentDate: invoice.remindOfPaymentDate ? new Date(invoice.remindOfPaymentDate) : null,
                remindOfPaymentDueDate: invoice.remindOfPaymentDueDate ? new Date(invoice.remindOfPaymentDueDate) : null,
                items
            }));
        }
    }, [invoiceId, invoice, dispatch]);
}


export const useRecalculate = () => {
    const dispatch = useDispatch();
    const recalculateInvoiceForm = useSelector((state: IStore) => state.billingPage.recalculateInvoiceForm);

    useEffect(() => {
        if (recalculateInvoiceForm) {
            dispatch(setBillingPageCalculateSumFooterAction());
        }
    }, [recalculateInvoiceForm])
}

