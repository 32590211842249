import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageBirthdayCompanyContactsViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import {
    getContactsWithBirthdayAction,
    getContactsWithoutBirthDateAction,
} from '../../../redux/entities/entities.actions';
import BirthdayCompanyContactsTable from './tables/BirthdayCompanyContactsTable';
import { translate } from '../../../translation/translate.utils';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import NoBirthdateCompanyContactsTable from './tables/NoBirthdateCompanyContactsTable';
import { buildRequestObject } from '../../../selectors/entity.selectors';
import { themeYellow } from '../../../theme/theme';

const BirthdayCompanyContactsView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const open = useSelector((store: IStore) => store.dashboardPage.companyContactsBirthdayViewOpen);
    const handleClose = () => dispatch(setDashboardPageBirthdayCompanyContactsViewOpenAction(false));
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingContactPersonBirthdays);

    useEffect(() => {
        if (open) {
            dispatch(getContactsWithBirthdayAction(token, buildRequestObject(sorting)));
            dispatch(getContactsWithoutBirthDateAction(token));
        }
    }, [dispatch, open, JSON.stringify(sorting)]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader translationString={"pages.dashboard.companyContactsWithBirthday"} onClose={handleClose} />
                <CustomTabContainer
                    color={themeYellow.palette.primary.main}
                    tabs={[{
                        label: translate('pages.dashboard.hasBirthday'),
                        component: <BirthdayCompanyContactsTable/>,
                    }, {
                        label: translate('pages.dashboard.withoutBirthdate'),
                        component: <NoBirthdateCompanyContactsTable />,
                    }]}
                />
            </CustomDialog>
        </>
    );
};

export default BirthdayCompanyContactsView;
