import React from 'react';
import {
    EEntities,
    IBillableData,
    ICandidatePartial,
    IRecruitingPartial,
    IVacancy,
} from '../../../../definitions/entities.definition';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { EInvoiceType } from '../../../../definitions/billingPage.definitions';
import InvoiceTypeCellRenderer from '../../../../components/CustomCellRenderer/InvoiceTypeCellRenderer';
import RecruitingCellRenderer from '../../../../components/CustomCellRenderer/RecruitingCellRenderer';
import { translate } from '../../../../translation/translate.utils';
import CurrencyCellRenderer from '../../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { ECellAlign } from '../../../../definitions/components.definitions';
import PinRating from '../../../../components/CustomRating/PinRating';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';

interface IProps {
    invoices: IBillableData[];
    onReload: () => void;
    loading: boolean;
}

const InvoiceGenerationList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    loading: props.loading,
                    entity: EEntities.BillableRecruiting,
                    columnConfig: {
                        checkbox: {
                            header: '#',
                            property: 'recruiting.id',
                            width: 50,
                            cellRenderer: (id: number) => {
                                return (
                                    <PinRating
                                        value={true}
                                        onChangePin={() => {
                                            console.log("JO");
                                        }}
                                        tooltip={translate('pages.vacancy.pin')}
                                    />
                                );
                            }
                        },
                        recruiting: {
                            width: 200,
                            header: 'Prozess',
                            property: 'recruiting',
                            cellRenderer: (recruiting: IRecruitingPartial) => {
                                if (!recruiting) {
                                    return (
                                        <>
                                            {translate('misc.noInformation')}
                                        </>
                                    )
                                }
                                return <RecruitingCellRenderer recruiting={recruiting} />
                            }
                        },
                        candidate: {
                            width: 200,
                            header: translate('pages.activity.properties.candidate'),
                            property: 'recruiting.candidate',
                            cellRenderer: (candidate: ICandidatePartial) => {
                                if (!candidate) {
                                    return (
                                        <>
                                            {translate('misc.noInformation')}
                                        </>
                                    )
                                }
                                return <CandidateCellRenderer candidate={candidate} />
                            }
                        },
                        vacancy: {
                            header: translate('pages.activity.properties.vacancy'),
                            property: "recruiting.vacancy",
                            cellRenderer: (vacancy: null | IVacancy, entity) => {
                                const billableData = entity as IBillableData;
                                if (billableData.vacancy) {
                                    return <VacancyCellRenderer vacancy={billableData.vacancy} />
                                }
                                if (!vacancy) {
                                    return (
                                        <>
                                            {translate('pages.candidate.initiativeApplication')}
                                        </>
                                    );
                                }
                                return <VacancyCellRenderer vacancy={vacancy} />
                            },
                        },
                        invoiceType: {
                            header: translate('pages.billing.invoiceType'),
                            property: 'invoiceType',
                            width: 200,
                            cellRenderer: (invoiceType: EInvoiceType) => {
                                return <InvoiceTypeCellRenderer invoiceType={invoiceType} />
                            }
                        },
                        shouldDate: {
                            header: translate('pages.billing.shouldDate'),
                            property: 'shouldDate',
                            width: 120,
                            cellRenderer: (date: string) => {
                                return <DateCellRenderer date={date} />
                            }
                        },
                        totalNet: {
                            align: ECellAlign.right,
                            header: translate('pages.billing.totalNet'),
                            property: 'totalNet',
                            width: 120,
                            cellRenderer: (totalNet: number) => {
                                return (<CurrencyCellRenderer value={totalNet} />)
                            },
                        }
                    },
                    sortedEntries: props.invoices,
                    onReload: props.onReload,
                    count: props.invoices.length,
                    total: props.invoices.length
                }}
            />
        </>
    );
}

export default InvoiceGenerationList;
