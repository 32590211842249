import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { IBillingVariantTemplate } from '../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { ClientApi } from '../../../requests/ClientApi';
import { getBillingVariantTemplateListRouteConfig } from '../../../requests/routes';
import { getToken } from '../../../selectors/app.selectors';
import { mapEntitiesToId } from '../../../utils/entities.utils';

interface IProps {
    onChange: (value: IBillingVariantTemplate) => void;
    value?: number;
}

const BillingVariantTemplateDropDown: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [templates, setTemplates] = useState<IBillingVariantTemplate[]>([]);
    const [templatesById, setTemplatesById] = useState<IBillingVariantTemplate[]>([]);

    useEffect(() => {
        ClientApi.request(getBillingVariantTemplateListRouteConfig, {
            token
        }).then((result: IBillingVariantTemplate[]) => {
            setTemplates(result);
            setTemplatesById(mapEntitiesToId(result));
        });
    }, [dispatch]);

    let menuItems = templates.map((template: IBillingVariantTemplate, index: number) => (
        <MenuItem key={index + 1} value={template.id}>
            {template.title}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            id={"billing-variant-template-dropdown"}
            required
            onChange={(event) => {
                props.onChange(templatesById[event.target.value]);
            }}
            value={props.value ?? 0}
            label={translate('pages.vacancy.invoiceCreationVariant')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default BillingVariantTemplateDropDown;
