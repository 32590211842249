import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import {
    getCompanyLocationListAction,
    getLocationsForCompanyDropdownAction,
} from '../../../../redux/entities/entities.actions';
import {
    getAllCompanyLocationsFromStore,
    getCompanyLocationsForDropdownFromStore,
} from '../../../../selectors/companyLocation.selectors';

export const useLoadLocationsOfCompany = (companyId: number) => {
    const token = useSelector(getToken);
    const locations = useSelector((store: IStore) => getCompanyLocationsForDropdownFromStore(store, companyId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyId) {
            dispatch(getLocationsForCompanyDropdownAction(token, companyId));
        }
    }, [companyId, token, dispatch]);

    return locations;
}

export const useLoadAllLocations = () => {
    const token = useSelector(getToken);
    const locations = useSelector((store: IStore) => {
        return getAllCompanyLocationsFromStore(store);
    });
    const allLocationsLoaded = useSelector((store: IStore) => store.ui.initialLoaded.companyLocations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allLocationsLoaded) {
            dispatch(getCompanyLocationListAction(token));
        }
    }, [allLocationsLoaded, token, dispatch]);

    return locations;
}
