import React, { ReactNode, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { TBrainzEntity } from '../../../definitions/entities.definition';
import { IRouteConfig } from '../../../definitions/requests.definitions';
import CustomTextField from '../CustomTextField';
import { useAutocompletHooks } from './CustomAutocomplete.hooks';
import { getOptionLabel } from './Autocomplete.utils';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';

interface IProps extends Partial<AutocompleteRenderInputParams> {
    value: TBrainzEntity|number|null;
    onChange: (value: TBrainzEntity|null) => void;
    getOptionLabel: (option: TBrainzEntity) => string;

    endAdornment?: ReactNode;
    routeConfig: IRouteConfig;
    routeParams: object;
    onAdd?: () => void;
    disableClearable?: boolean;
    loadCallback?: () => void;

    reload?: {
        reload: boolean;
        reloadFinishedCallback: () => void
    },

    //autocomplete params
    label: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    groupBy?: (option: TBrainzEntity) => string;
}

const CustomAutocomplete: React.FC<IProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const value = props.value;
    const routeConfig = props.routeConfig;
    const routeParams = props.routeParams;
    const label = props.label;
    const size = props.size || 'medium';
    const disableClearable = props.disableClearable;
    const reload = props.reload ? props.reload.reload : undefined;
    const reloadCallback = props.reload ? props.reload.reloadFinishedCallback : undefined;
    const loadCallback = props.reload ? props.loadCallback : undefined;

    const valueIsEntity: boolean = value !== null && value.constructor && value.constructor.name === 'Object';
    const [entityValue, setEntityValue] = useState<TBrainzEntity|null>(valueIsEntity ? value as null|TBrainzEntity : null);

    const { calculatedEntityValue, records, loading } = useAutocompletHooks(
        open,
        value,
        routeParams,
        routeConfig,
        reload,
        reloadCallback,
        loadCallback
    );

    useEffect(() => {
        if (!valueIsEntity) {
            setEntityValue(calculatedEntityValue);
            if (calculatedEntityValue) {
                props.onChange(calculatedEntityValue);
            }
        }
    }, [calculatedEntityValue]);

    useEffect(() => {
        if (valueIsEntity) {
            setEntityValue(value as TBrainzEntity|null);
        }
    }, [value])

    return <>
        <Autocomplete
            id={props.id}
            className={"flex"}
            loading={loading}
            disabled={props.disabled}
            groupBy={props.groupBy}

            size={size}
            disableClearable={disableClearable || props.required}

            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}

            options={records.length > 0 ? records : (entityValue !== null) ? [entityValue] : []}
            value={entityValue}

            getOptionSelected={(value: TBrainzEntity, option: TBrainzEntity) => value.id === option.id}
            getOptionLabel={(record) => getOptionLabel(record, props.getOptionLabel)}
            onChange={(event, value) => {
                props.onChange(value);
            }}

            renderInput={(params) => <>
                <CustomTextField
                    {...params}
                    onMouseDown={() => {
                        if (open) {
                            setOpen(false);
                        }
                    }}
                    autoFocus={props.autoFocus}
                    error={props.error}
                    helperText={props.helperText}
                    required={props.required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                {props.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    onAddNewEntryCallback={props.onAdd}
                />
            </>
            }
        />
    </>
}

export default React.memo(CustomAutocomplete);
