import React from 'react';
import { TreeItemProps } from '@material-ui/lab/TreeItem/TreeItem';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { IMailboxFolderTreeNode } from '../../../../definitions/entities.definition';
import { useDrop } from 'react-dnd';

//@ts-ignore
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
}));

interface IProps extends TreeItemProps {
    node: IMailboxFolderTreeNode;
    onSelect2: (node: IMailboxFolderTreeNode) => void;
}

const FolderTreeItem: React.FC<IProps> = (props) => {
    const classes = useTreeItemStyles();
    const {...other} = props;

    //@ts-ignore-next-line
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'mail',
        drop: () => ({
            folderId: props.node.id,
        }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [props.node]);

    const isActive = canDrop && isOver
    let backgroundColor = '';
    if (isActive) {
        backgroundColor = '#56585B';
    }

    return (
        <>
                <TreeItem
                    draggable
                    onClick={() => {
                        props.onSelect2(props.node);
                    }}
                    label={
                        <div
                            ref={drop}
                            style={{cursor: "pointer", backgroundColor}}
                            className={"flex"}
                        >
                            <div className={classes.labelRoot}>
                                <Typography variant="body2" className={classes.labelText}>
                                    {props.node.text}
                                    {props.node.countUnread > 0 &&
                                        <> ({props.node.countUnread})</>
                                    }
                                </Typography>
                            </div>
                        </div>
                    }
                    classes={{
                        root: classes.root,
                        content: classes.content,
                        group: classes.group,
                        label: classes.label,
                    }}
                    {...other}
                />
        </>
    );
}

export default FolderTreeItem;
