import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    resetCompanyPageFilterAction,
    setCompanyPageFilterAction,
    setCompanyPageFilterMenuAction,
} from '../../../../redux/companyPage/companyPage.actions';
import { translate } from '../../../../translation/translate.utils';
import EmployeeSizeDropDown from '../../../CustomInput/CustomDropDownInput/EmployeeSizeDropDown';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import BranchAutocompleteDropDown from '../../../CustomInput/CustomAutocomplete/Legacy/BranchAutocompleteDropDown';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { IEmployee } from '../../../../definitions/entities.definition';
import YesNoShowAllDropDown from '../../../CustomInput/CustomDropDownInput/YesNoShowAllDropDown';
import AbcRatingDropDown from '../../../CustomInput/CustomDropDownInput/AbcRatingDropDown';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "350px",
            },
        },
    })
);

const CustomFilterMenuCompany: React.FC<IProps> = (props) => {
    const filterMenu = useSelector((store: IStore) => store.companyPage.filterMenu);
    const filter = useSelector((store: IStore) => store.companyPage.filter);
    const styles = useStyles();
    const dispatch = useDispatch();

    const submit = () => {
        dispatch(
            setCompanyPageFilterAction({
                fastSearch: filter.fastSearch,
                start: filter.start,
                limit: filter.limit,
                employeeSizeCategory: filterMenu.employeeSizeCategory,
                branchIds: filterMenu.branches?.map(({ id }) => id),
                responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
                isCustomer: filterMenu.isCustomer,
                rating: filterMenu.rating > 0 ? filterMenu.rating : undefined
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={() => submit()} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(resetCompanyPageFilterAction());
                        props.removeAnchor();
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.responsibleEmployee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setCompanyPageFilterMenuAction({
                                responsibleEmployee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <AbcRatingDropDown
                    onChange={(event: any) => {
                        dispatch(
                            setCompanyPageFilterMenuAction({
                                rating: event.target.value,
                            })
                        );
                    }}
                    value={filterMenu.rating}
                />
            </MenuItem>
            <MenuItem>
                <YesNoShowAllDropDown
                    onChange={(value) =>
                        dispatch(
                            setCompanyPageFilterMenuAction({
                                isCustomer: value,
                            })
                        )
                    }
                    yesText={'Nur Kunden anzeigen'}
                    noText={'Nur nicht Kunden anzeigen'}
                    allText={'Alle Firmen anzeigen'}
                    value={filterMenu.isCustomer}
                    label={'Kunden'}
                />
            </MenuItem>
            <MenuItem>
                <BranchAutocompleteDropDown
                    value={filterMenu.branches}
                    onChange={(value) => dispatch(setCompanyPageFilterMenuAction({ branches: value }))}
                />
            </MenuItem>
            <MenuItem>
                <EmployeeSizeDropDown
                    onChange={(event: any) =>
                        dispatch(
                            setCompanyPageFilterMenuAction({
                                employeeSizeCategory: event.target.value,
                            })
                        )
                    }
                    value={filterMenu.employeeSizeCategory as number}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuCompany;
