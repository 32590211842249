import React, { ReactNode } from 'react';
import { ICompanyContactPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getCompanyContactListRouteConfig, getCompanyContactRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../CustomClickableIcon';
import { EIcons } from '../../../Icons/IconFactory';
import { getCustomerContactListRouteConfig } from '../../../../requests/routes.customer';

interface IProps {
    value: ICompanyContactPartial|number|null;
    onChange: (value: ICompanyContactPartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    companyId?: number;
    customerPortal?: boolean;
    onAdd?: () => void;
}

const CompanyContactAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const customerPortal = props.customerPortal || false;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICompanyContactPartial);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ICompanyContactPartial).fullName;
    }

    return <CustomLivesearch
        id={"company-contact-autocomplete"}
        label={props.label || translate('misc.companyContact')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={customerPortal ? getCustomerContactListRouteConfig : getCompanyContactListRouteConfig}
        entityRouteConfig={getCompanyContactRouteConfig}
        entityRouteParam={"contactId"}
        getOptionLabel={getOptionLabel}
        startAdornment={props.onAdd ? (
            <InputAdornment position="end">
                <CustomClickableIcon onClick={props.onAdd} icon={EIcons.Add}/>
            </InputAdornment>
        ) : undefined}
        loadAll
        routeParams={{
            orderBy: 'title',
            orderDirection: 'DESC',
            start: 0,
            limit: 50,
            companyIds: props.companyId ? [props.companyId] : undefined,
            online: true
        }}
    />;
}

export default CompanyContactAutocomplete;

