import React, { PropsWithChildren, ReactNode } from 'react';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { ERoutes } from '../../definitions/app.definitions';
import { useHistory } from 'react-router-dom';
import { setUiCurrentRouteAction } from '../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';
import { COLOR_TEXT, dark } from '../../theme/theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
            fontSize: ".875rem",
            background: "var(--brainz-darkest)",
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            borderRadius: "6px",
            flexDirection: "column",
            paddingBottom: "10px",
        },
        rootLight: {
            width: "100%",
            fontSize: ".875rem",
            background: "var(--brainz-darker)",
            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
            borderRadius: "6px",
            flexDirection: "column",
            paddingBottom: "10px",
        },
        innerRoot: {
            textAlign: "right",
            color: COLOR_TEXT,
            margin: "0 15px",
            height: "55px",
        },
        headline: {
            color: "var(--brainz-color-text-secondary)",
            margin: "0",
            fontSize: "14px",
            paddingTop: "10px",
        },
        information: {
            color: "var(--brainz-color-text)",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: 300,
            fontSize: "1.825em",
        },
        icon: {
            width: "46px",
            height: "46px",
            float: "left",
            padding: "15px",
            marginTop: "-20px",
            marginRight: "15px",
            borderRadius: "3px",
        },
    })
);

interface IProps extends PropsWithChildren<any> {
    icon?: EIcons;
    headline: ReactNode|string;
    iconBackgroundColor?: string;
    iconColor?: string;
    onClickAction?: () => void;
    route?: ERoutes;
    design?: 'dark'|'light';
}

const CustomDashboardTextPaper: React.FC<IProps> = (props: IProps) => {
    const styles = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const design = props.design || 'dark';

    return (
        <div
            style={{
                marginTop: props.icon ? 30 : undefined,
                cursor: props.onClickAction || props.route ? 'pointer' : undefined
            }}
            className={design === 'light' ? styles.rootLight : styles.root }
            onClick={() => {
                if (props.onClickAction) {
                    props.onClickAction()
                }
                if (props.route) {
                    dispatch(setUiCurrentRouteAction(props.route));
                    history.push(props.route);
                }
            }}
        >
            <div className={styles.innerRoot}>
                {props.icon &&
                    <div className={styles.icon} style={{ background: props.iconBackgroundColor ? props.iconBackgroundColor : COLOR_TEXT }}>
                        {IconFactory.getIconComponent(props.icon, {
                            color: props.iconColor ? props.iconColor : 'white',
                            width: "2em",
                            height: "2em",
                        })}
                    </div>
                }
                <p className={styles.headline}>{props.headline}</p>
                <h3 className={styles.information}>{props.children}</h3>
            </div>
        </div>
    );
};

export default CustomDashboardTextPaper;
