import { ICustomerAppRecruitingFilter } from '../../definitions/customerApp.definitions';

export const CUSTOMER_APP_SET_RECRUITING_FILTER = "CUSTOMER_APP_SET_RECRUITING_FILTER";

export type TCustomerAppReducerAction = ISetCustomerAppRecruitingFilterAction;

export interface ISetCustomerAppRecruitingFilterAction {
    type: typeof CUSTOMER_APP_SET_RECRUITING_FILTER;
    payload: Partial<ICustomerAppRecruitingFilter>;
}
export const setCustomerAppRecruitingFilterAction = (filter: Partial<ICustomerAppRecruitingFilter>): ISetCustomerAppRecruitingFilterAction => ({
    type: CUSTOMER_APP_SET_RECRUITING_FILTER,
    payload: filter
});
