import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: boolean;
    label: string;
    hideChoose?: boolean;
    error?: boolean;
    helperText?: string;
    yesText?: string;
    noText?: string;
    required?: boolean;
}

const LegacyYesNoDropDown: React.FC<IProps> = (props) => {
    let selectedValue = -1;
    if (props.value === true) {
        selectedValue = 1;
    }
    if (props.value === false) {
        selectedValue = 0;
    }
    return (
        <>
            <CustomDropDownInput
                id={"years-no-dropdown" + props.label}
                onChange={props.onChange}
                value={selectedValue}
                label={props.label}
                error={props.error}
                helperText={props.helperText}
                required={props.required}
            >
                {!props.hideChoose &&
                    <MenuItem key={0} value={-1}>
                        {translate('misc.choose')}
                    </MenuItem>
                }
                <MenuItem key={1} value={1}>
                    {props.yesText ?? translate('misc.yes')}
                </MenuItem>
                <MenuItem key={2} value={0}>
                    {props.noText ?? translate('misc.no')}
                </MenuItem>
            </CustomDropDownInput>
        </>
    );
};

export default LegacyYesNoDropDown;
