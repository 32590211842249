import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import { IStore } from '../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getSkillAction } from '../../redux/entities/entities.actions';
import { ISkill } from '../../definitions/entities.definition';

export const useLoadSkill = (): undefined|{skill: ISkill, hasChildren: boolean} => {
    const token = useSelector(getToken);
    const skillId = useSelector((store: IStore) => store.skillPage.skillToEdit);
    const skill = useSelector((store: IStore) => store.entities.skills.currentRecord);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!skill && skillId > 0) {
            dispatch(getSkillAction(token, skillId));
        }
    }, [skillId]);

    return skill;
}
