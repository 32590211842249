import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ELanguageLevel } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ELanguageLevel;
}

const LanguageLevelDropDown: React.FC<IProps> = (props) => {
    const languageLevels: ELanguageLevel[] = [
        ELanguageLevel.unknown,
        ELanguageLevel.basic,
        ELanguageLevel.good,
        ELanguageLevel.fluent,
        ELanguageLevel.mother_language
    ];
    const menuItems = languageLevels.map((languageLevel: ELanguageLevel, index: number) => (
        <MenuItem key={index + 1} value={languageLevel}>
            {translate('misc.languageLevelValue.' + languageLevel)}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            id={"language-level-dropdown"}
            label={'Niveau'}
            required
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default LanguageLevelDropDown;
