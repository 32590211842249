import React from 'react';
import { EEntities } from '../../../../../definitions/entities.definition';
import { translate } from '../../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../../definitions/ui.definitions';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { setArchivePageSearchAction } from '../../../../../redux/archivePage/archivePage.actions';

const FastSearchPanelArchivVacancyList: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchVacancyArchive = useSelector((store: IStore) => store.archivePage.filterVacancyList.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.VacancyArchive,
        value: fastSearchVacancyArchive ?? '',
        emptyValue: translate('pages.vacancy.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setArchivePageSearchAction(value)),
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelArchivVacancyList;
