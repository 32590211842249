import React, { useCallback } from 'react';
import { ERoutes } from '../../../definitions/app.definitions';
import { useHistory } from 'react-router';
import FastSearchPanelRecruitingPage from './FastSearchPanel/FastSearchPanelRecruitingPage';
import FastSearchPanelCandidatePage from './FastSearchPanel/FastSearchPanelCandidatePage';
import FastSearchPanelApplicationPage from './FastSearchPanel/FastSearchPanelApplicationPage';
import FastSearchPanelBillingPage from './FastSearchPanel/FastSearchPanelBillingPage';
import FastSearchPanelCompanyPage from './FastSearchPanel/FastSearchPanelCompanyPage';
import FastSearchPanelActivityPage from './FastSearchPanel/FastSearchPanelActivityPage';
import FastSearchPanelAdministrationPage from './FastSearchPanel/FastSearchPanelAdministrationPage';
import FastSearchPanelWebVacancyPage from './FastSearchPanel/FastSearchPanelWebVacancyPage';
import FastSearchPanelArchivePage from './FastSearchPanel/FastSearchPanelArchivePage';

const DefaultTopBarCenter: React.FC = () => {
    const history = useHistory();
    const currentRoute = history.location.pathname as ERoutes;

    const renderFastSearchPanel = useCallback((route: ERoutes) => {
        switch (route) {
            case ERoutes.ActivityOverview:
                return <FastSearchPanelActivityPage />
            case ERoutes.Recruitings:
                return <FastSearchPanelRecruitingPage />
            case ERoutes.Candidates:
                return <FastSearchPanelCandidatePage />
            case ERoutes.Applications:
                return <FastSearchPanelApplicationPage />
            case ERoutes.Billing:
                return <FastSearchPanelBillingPage />
            case ERoutes.Companies:
                return <FastSearchPanelCompanyPage />
            case ERoutes.Administration:
                return <FastSearchPanelAdministrationPage />
            case ERoutes.WebVacancies:
                return <FastSearchPanelWebVacancyPage />
            case ERoutes.Archive:
                return <FastSearchPanelArchivePage />
        }

        return null;
    }, [currentRoute]);

    return <>
        {renderFastSearchPanel(currentRoute)}
    </>
}

export default DefaultTopBarCenter;
