import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getBranchListAction } from '../../../../redux/entities/entities.actions';

export const useLoadBranches = () => {
    const token = useSelector(getToken);
    const branchesLoaded = useSelector((store: IStore) => store.ui.initialLoaded.branches);
    const branches = useSelector((store: IStore) => store.entities.branches.array);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!branchesLoaded) {
            dispatch(getBranchListAction(token));
        }
    }, [branchesLoaded, token, dispatch]);

    return branches.sort((a, b) => a.name.localeCompare(b.name));
}
