import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import {
    setCandidatePageAddDialogAction,
    setCandidatePageFastSearchAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';

const FastSearchPanelCandidatePage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchCandidate = useSelector((store: IStore) => store.candidatePage.filter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Candidate,
        value: fastSearchCandidate ?? '',
        emptyValue: translate('pages.candidate.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setCandidatePageFastSearchAction(value)),
        actionButtons: [{
            action: () => dispatch(setCandidatePageAddDialogAction(true)),
            icon: EIcons.AddBox,
            tooltip: 'Neuen Kandidaten anlegen'
        }],
        filterPanelType: EFilterPanelType.sidePanel,
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelCandidatePage;
