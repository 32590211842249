import React from 'react';

interface IProps {
    value?: null|number;
    roundedToThousand?: boolean;
}

const CurrencyCellRenderer: React.FC<IProps> = (props) => {
    const roundedToThousand = props.roundedToThousand;
    return <> {(props.value || 0).toLocaleString("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: roundedToThousand ? 0 : 2 })} </>;
}

export default React.memo(CurrencyCellRenderer);
