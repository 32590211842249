import React, { useState } from 'react';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployeeAction, updateEmployeeAction } from '../../../../redux/entities/entities.actions';
import { IEmployeeForApi } from '../../../../definitions/entities.definition';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../../translation/translate.utils';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';

interface IProps {
    onClose: () => void;
}

const EmployeeForm: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const validationErrors = useSelector(getValidationErrorsFromStore);
    const initialEmployee = useSelector((store: IStore) => store.entities.employees.byId[store.administrationPage.employeeToEdit]);
    const addEmployee = useSelector((store: IStore) => store.administrationPage.addEmployeeDialogOpen);

    const [name, setName] = useState(initialEmployee?.name || '');
    const [firstName, setFirstName] = useState(initialEmployee?.firstName || '');
    const [address, setAddress] = useState(initialEmployee?.address || '');
    const [zipCode, setZipCode] = useState(initialEmployee?.zipCode || '');
    const [city, setCity] = useState(initialEmployee?.city || '');
    const [salutation, setSalutation] = useState(initialEmployee?.salutation || 'mr');
    const [phoneNumber, setPhoneNumber] = useState(initialEmployee?.phoneNumber || '');
    const [mobileNumber, setMobileNumber] = useState(initialEmployee?.mobileNumber || '');
    const [email, setEmail] = useState(initialEmployee?.user.login || '');
    const [newPassword, setNewPassword] = useState('');
    const [renewPassword, setRenewPassword] = useState<boolean>(initialEmployee ? initialEmployee.user.renewPassword : true);
    const [comment, setComment] = useState(initialEmployee?.comment || '');
    const [title, setTitle] = useState(initialEmployee?.title || '');

    const save = () => {
        const employee: IEmployeeForApi = {
            name,
            firstName,
            address,
            zipCode,
            city,
            salutation,
            phoneNumber,
            mobileNumber,
            email,
            newPassword,
            renewPassword,
            comment,
            title
        };

        if (addEmployee) {
            dispatch(addEmployeeAction(token, employee));
            return;
        }

        employee.id = initialEmployee.id;
        dispatch(updateEmployeeAction(token, employee));
        return;
    }

    return (
        <div>
            <PaperRow>
                <SalutationDropDown
                    onChange={(event: any) => setSalutation(event.target.value)}
                    value={salutation}
                    required={true}
                    error={Boolean(validationErrors.salutation)}
                    helperText={validationErrors.salutation}
                />
                <CustomTextField
                    label={translate('pages.companyContact.properties.title')}
                    value={title}
                    onChange={(value) => setTitle(value)}
                />
                <CustomTextField
                    label={translate('pages.companyContact.properties.name')}
                    value={name}
                    required={true}
                    onChange={(value) => setName(value)}
                    error={Boolean(validationErrors.name)}
                    helperText={validationErrors.name}
                />
                <CustomTextField
                    label={translate('pages.companyContact.properties.firstName')}
                    value={firstName}
                    required={true}
                    onChange={(value) => {
                        setFirstName(value)
                    }}
                    error={Boolean(validationErrors.firstName)}
                    helperText={validationErrors.firstName}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.zipCode')}
                    value={zipCode}
                    onChange={(value) => setZipCode(value)}
                    error={Boolean(validationErrors.zipCode)}
                    helperText={validationErrors.zipCode}
                />
                <CustomTextField
                    label={translate('misc.city')}
                    value={city}
                    onChange={(value) => setCity(value)}
                    error={Boolean(validationErrors.city)}
                    helperText={validationErrors.city}
                />
                <CustomTextField
                    label={translate('misc.address')}
                    value={address}
                    onChange={(value) => setAddress(value)}
                    error={Boolean(validationErrors.address)}
                    helperText={validationErrors.address}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('misc.phoneNumber')}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    error={Boolean(validationErrors.phoneNumber)}
                    helperText={validationErrors.phoneNumber}
                />
                <CustomTextField
                    label={translate('misc.mobileNumber')}
                    value={mobileNumber}
                    onChange={(value) => setMobileNumber(value)}
                    error={Boolean(validationErrors.mobileNumber)}
                    helperText={validationErrors.mobileNumber}
                />
                <CustomTextField
                    label={translate('pages.administration.mailAndLogin')}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    error={Boolean(validationErrors.email)}
                    helperText={validationErrors.email}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    label={translate('pages.administration.newPassword')}
                    value={newPassword}
                    onChange={(value) => setNewPassword(value)}
                    error={Boolean(validationErrors.newPassword)}
                    helperText={validationErrors.newPassword}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.administration.renewPassword"}
                    checked={renewPassword}
                    onChange={() => setRenewPassword(!renewPassword)}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    label={translate('misc.comment')}
                    value={comment}
                    onChange={(value) => setComment(value)}
                    error={Boolean(validationErrors.comment)}
                    helperText={validationErrors.comment}
                />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: save,
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </div>
    );
}

export default EmployeeForm;
