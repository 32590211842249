import React from 'react';
import CustomDialogHeader from '../../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../../components/CustomDialog/CustomDialogBody';
import { Dialog } from '@material-ui/core';
import CandidateDocumentForm from '../../componentsLegacy/CandidateDocumentForm';
import { ICandidateDocument } from '../../../../../definitions/entities.definition';

interface IProps {
    open: boolean;
    document?: null|ICandidateDocument;
    onClose: () => void;
}

const CandidateDocumentEditView: React.FC<IProps> = (props) => {
    const open = props.open;
    const document = props.document;
    const onClose = () => {props.onClose()}

    if (!open || !document) {
        return null;
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CustomDialogHeader string={"Dokument bearbeiten"} onClose={props.onClose} />
            <CustomDialogBody>
                <CandidateDocumentForm initialDocument={document} onClose={onClose} />
            </CustomDialogBody>
        </Dialog>
    );
};

export default CandidateDocumentEditView;
