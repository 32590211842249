import { EIcons, IconFactory } from '../Icons/IconFactory';
import { EActivityType, TActivityType } from '../../definitions/entities.definition';
import React from 'react';
import { translate } from '../../translation/translate.utils';
import { Tooltip } from '@material-ui/core';

interface IProps {
    type: TActivityType;
}

const ActivityTypeCellRenderer: React.FC<IProps> = (props) => {
    switch (props.type) {
        case EActivityType.info:
            return (
                <Tooltip title={translate('pages.activity.typeValue.info')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Info)}
                </Tooltip>
            );
        case EActivityType.phoneNotReached:
            return (
                <Tooltip title={translate('pages.activity.typeValue.phone')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.PhoneDisabled)}
                </Tooltip>
            );
        case EActivityType.mobileMessage:
            return (
                <Tooltip title={translate('pages.activity.typeValue.mobile_message')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.SmartPhone)}
                </Tooltip>
            );
        case EActivityType.mobileMessageReceived:
            return (
                <Tooltip title={translate('pages.activity.typeValue.mobile_message_received')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.SmartPhone)}
                </Tooltip>
            );
        case EActivityType.phone:
            return (
                <Tooltip title={translate('pages.activity.typeValue.phone')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Phone)}
                </Tooltip>
            );
        case EActivityType.phoneIncoming:
            return (
                <Tooltip title={translate('pages.activity.typeValue.phone_incoming')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Phone)}
                </Tooltip>
            );
        case EActivityType.mail:
        case EActivityType.systemMail:
            return (
                <Tooltip title={translate('pages.activity.typeValue.mail')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Send)}
                </Tooltip>
            );
        case EActivityType.mailReceived:
            return (
                <Tooltip title={translate('pages.activity.typeValue.mail_received')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.EMail)}
                </Tooltip>
            );
        case EActivityType.personal:
            return (
                <Tooltip title={translate('pages.activity.typeValue.personal')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Face)}
                </Tooltip>
            );
        case EActivityType.interview:
        case EActivityType.candidateInterview:
            return (
                <Tooltip title={translate('pages.activity.typeValue.interview')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Calendar)}
                </Tooltip>
            );
        case EActivityType.feedback:
            return (
                <Tooltip title={translate('pages.activity.typeValue.feedback')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Comment)}
                </Tooltip>
            );
        case EActivityType.systemInfo:
            return (
                <Tooltip title={translate('pages.activity.typeValue.system_info')} placement="top-start">
                    {IconFactory.getIconComponent(EIcons.Info)}
                </Tooltip>
            );
        default:
            return null;
    }
};

export default ActivityTypeCellRenderer;
