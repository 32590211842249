import React from 'react';
import { IJobTemplate } from '../../../../definitions/entities.definition';
import CustomAutocompleteDropDown from './CustomAutocompleteDropDown';
import { useLoadJobTemplates } from './JobTemplateAutocompleteDropDown.hook';

interface IProps {
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    value?: IJobTemplate;
    label?: string;
}

const JobTemplateAutocompleteDropDown: React.FC<IProps> = (props) => {
    const jobTemplates = useLoadJobTemplates();

    return (
        <CustomAutocompleteDropDown
            options={jobTemplates}
            defaultValue={props.value}
            onChange={props.onChange}
            label={'Berufsgruppe'}
            getOptionLabel={(contact: IJobTemplate) => `${contact.name}`}
        />
    );
};

export default JobTemplateAutocompleteDropDown;
