import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getApplicationsForCompanyFromStore,
    getCompanyPageRequestObjectForApplicationsInEditView,
} from '../../../../../selectors/application.selectors';
import { useEffect } from 'react';
import { getToken } from '../../../../../selectors/app.selectors';
import { getApplicationListForCompanyAction } from '../../../../../redux/entities/entities.actions';

export const useCompanyApplicationListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.applicationsLoading);
    const reload = useSelector((store: IStore) => store.ui.reloadApplications);

    const requestObject = useSelector(getCompanyPageRequestObjectForApplicationsInEditView);
    const applications = useSelector((store: IStore) => getApplicationsForCompanyFromStore(store, companyId));

    useEffect(() => {
        dispatch(getApplicationListForCompanyAction(token, companyId, requestObject));
    }, [companyId]);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getApplicationListForCompanyAction(token, companyId, requestObject));
        }
    }, [reload]);

    return {loading, applications, total: applications.length};
}
