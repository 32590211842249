import React from 'react';
import {
    EEntities,
    IActivity,
    ICandidate,
    ICompanyContact, ICompanyContactPartial,
    IEmployee, ISuccessActivity,
    TActivityType,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import { IStore } from '../../../definitions/redux/store.definitions';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import { getActivityListAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { getToken } from '../../../selectors/app.selectors';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import DateTimeCellRenderer from '../../../components/CustomCellRenderer/DateTimeCellRenderer';
import ActivityTypeCellRenderer from '../../../components/CustomCellRenderer/ActivityTypeCellRenderer';
import ActivityDescriptionCellRenderer from '../../../components/CustomCellRenderer/ActivityDescriptionCellRenderer';
import ActivityFurtherCellRenderer from './ActivityFurtherCellRenderer';
import { setUIActivityListViewSortingOptionAction, setUiActivityToEditAction } from '../../../redux/ui/ui.actions';
import {
    getActivitiesForListViewFromStore,
    getActivityRequestObjectForListView,
} from '../../../selectors/activity.selectors';
import MinutesCellRenderer from '../../../components/CustomCellRenderer/MinutesCellRenderer';
import { getHoursStringByMinutes } from '../../../utils/date.utils';
import CompanyContactCellRenderer from '../../../components/CustomCellRenderer/CompanyContactCellRenderer';

const ActivityListViewList: React.FC = () => {
    const dispatch = useDispatch();
    const activities = useSelector(getActivitiesForListViewFromStore);
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const requestObject = useSelector(getActivityRequestObjectForListView);
    const totalTime = useSelector((store: IStore) => store.entities.activities.totalTimeOrderListView)

    const columnConfig: ICustomTableColumnConfig = {
        type: {
            header: translate('pages.activity.properties.type'),
            property: "type",
            width: 60,
            cellRenderer: (type: TActivityType) => <ActivityTypeCellRenderer type={type} />,
        },
        dateTime: {
            header: translate('pages.activity.properties.dateTime'),
            property: "dateTime",
            width: 170,
            cellRenderer: (dateTime: string) => <DateTimeCellRenderer date={dateTime} />,
        },
        estimatedTime: {
            align: ECellAlign.left,
            header: 'Zeit',
            width: 80,
            property: "estimatedTime",
            cellRenderer: (value: number) => {
                return <MinutesCellRenderer minutes={value} />
            }
        },
        userInput: {
            header: translate('pages.activity.properties.description'),
            property: "userInput",
            flex: 2,
            cellRenderer: (value: string, activity: TBrainzEntity) => {
                const activityCast = activity as IActivity;
                return <ActivityDescriptionCellRenderer activity={activityCast} />;
            },
        },
        further: {
            header: 'Prozess/Vorschlag',
            property: 'id',
            flex: 1,
            cellRenderer: (id: number, activity: TBrainzEntity) => {
                const activityCast = activity as IActivity;
                return <ActivityFurtherCellRenderer activity={activityCast} />;
            }
        },
        candidate: {
            header: translate('pages.activity.properties.candidate'),
            property: "candidate",
            flex: 1,
            cellRenderer: (candidate: null | ICandidate) => {
                if (candidate !== null) {
                    return <CandidateCellRenderer candidate={candidate} />;
                }

                return translate('misc.noInformation');
            },
        },
        employee: {
            header: translate('misc.employeeShort') + ' | AP',
            property: "employee",
            align: ECellAlign.center,
            width: 140,
            cellRenderer: (employee: null|IEmployee, entity) => {
                const entityCast = entity as ISuccessActivity;

                if (!employee) {
                    return <CompanyContactCellRenderer companyContact={entityCast.createdByUser.person as ICompanyContactPartial} />;
                }
                return <EmployeeCellRenderer employee={employee} />;
            },
        },
        contactPerson: {
            header: 'Mit AP',
            property: "companyContact",
            flex: 1,
            cellRenderer: (companyContact?: ICompanyContact) => {
                if (companyContact) {
                    return `${companyContact.firstName} ${companyContact.name}`;
                }

                return translate('misc.noInformation');
            },
        },
    };

    return (
        <CustomTable
            style={{marginLeft:10, marginRight:10}}
            config={{
                entity: EEntities.ActivityList,
                footerText: 'Gesamt: ' + getHoursStringByMinutes(totalTime),
                loading,
                columnConfig,
                sortedEntries: activities,
                onRowDoubleClick: (activityId: number) => dispatch(setUiActivityToEditAction(activityId)),
                count: activities.length,
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setUIActivityListViewSortingOptionAction(propertyToSort, sortOption)),
                },
                onReload: () => {
                    dispatch(getActivityListAction(
                        token,
                        requestObject,
                    ));
                },
            }}
        />
    );
};

export default ActivityListViewList;
