import React, { memo } from 'react';
import { ICompanyContactPartial } from '../../definitions/entities.definition';
import { useDispatch } from 'react-redux';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import styles from './CompanyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { setUiCloseTopDrawer, setUiCompanyContactToEditAction } from '../../redux/ui/ui.actions';

interface IProps {
    companyContact: ICompanyContactPartial;
    onDelete?: () => void;
    hideIcon?: boolean;
}

const CompanyContactCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const companyContact = props.companyContact;
    const hideIcon = props.hideIcon || false;

    return (
        <Tooltip title={companyContact.fullName} placement="top-start">
            <Chip
                key={companyContact.id}
                icon={hideIcon ? undefined : IconFactory.getIconComponent(EIcons.Supervisor, {color: darker })}
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                color="primary"
                label={companyContact.fullName}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiCompanyContactToEditAction(companyContact.id))
                }}
            />
        </Tooltip>
    );
};

export default memo(CompanyContactCellRenderer);
