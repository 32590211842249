import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { addCompanyLocationAction, updateCompanyLocationAction } from '../../../redux/entities/entities.actions';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import { ICompanyLocationForApi } from '../../../definitions/entities.definition';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import autobind from 'autobind-decorator';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';

interface IProps extends PropsFromRedux {
    onClose: () => void;
}

type TState = {
    address: string;
    zipCode: string;
    city: string;
    comment: string;
    differentCompanyName?: string;
}

class CompanyLocationForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const initialLocation = props.initialCompanyLocation;

        this.state = {
            address: initialLocation?.address || '',
            zipCode: initialLocation?.zipCode || '',
            city: initialLocation?.city || '',
            comment: initialLocation?.comment || '',
            differentCompanyName: initialLocation?.differentCompanyName || ''
        }
    }

    @autobind
    save() {
        const companyContact: ICompanyLocationForApi = {
            address: this.state.address,
            zipCode: this.state.zipCode,
            city: this.state.city,
            comment: this.state.comment,
            differentCompanyName: this.state.differentCompanyName,
            companyId: this.props.addData.companyId > 0 ? this.props.addData.companyId : this.props.initialCompanyLocation.company.id
        };

        if (this.props.addData.companyId) {
            this.props.addCompanyLocationAction(this.props.token, companyContact);
            return;
        }

        companyContact.id = this.props.initialCompanyLocation.id;
        this.props.updateCompanyLocationAction(this.props.token, companyContact);
        return;
    }

    render() {
        return (
            <div>
                <PaperRow>
                    <CustomTextField
                        width={100}
                        label={translate('misc.zipCode')}
                        value={this.state.zipCode}
                        required={true}
                        onChange={(value) => {
                            this.setState({
                                zipCode: value
                            })
                        }}
                        error={Boolean(this.props.validationErrors.zipCode)}
                        helperText={this.props.validationErrors.zipCode}
                    />
                    <CustomTextField
                        label={translate('misc.city')}
                        value={this.state.city}
                        required={true}
                        onChange={(value) => {
                            this.setState({
                                city: value
                            })
                        }}
                        error={Boolean(this.props.validationErrors.city)}
                        helperText={this.props.validationErrors.city}
                    />
                    <CustomTextField
                        label={translate('misc.address')}
                        value={this.state.address}
                        onChange={(value) => {
                            this.setState({
                                address: value
                            })
                        }}
                        error={Boolean(this.props.validationErrors.address)}
                        helperText={this.props.validationErrors.address}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('pages.companyLocation.differentCompanyName')}
                        value={this.state.differentCompanyName}
                        onChange={(value) => {
                            this.setState({
                                differentCompanyName: value
                            })
                        }}
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('misc.comment')}
                        multiline
                        rows={8}
                        value={this.state.comment}
                        onChange={(value) => {
                            this.setState({
                                comment: value
                            })
                        }}
                    />
                </PaperRow>
                <CustomDialogActions
                    onClose={this.props.onClose}
                    buttons={[{
                        void: this.save,
                        label: translate('misc.buttons.saveAndClose')
                    }]}
                />
            </div>
        );
    }
}

const mapState = (store: IStore) => {
    return ({
        token: getToken(store),
        validationErrors: getValidationErrorsFromStore(store),
        loggedInEmployee: getLoggedInEmployeeFromStore(store),
        initialCompanyLocation: store.entities.companyLocations.byId[store.ui.editCompanyLocationId],
        addData: store.ui.addCompanyLocationData,
    });
}

const mapDispatch = {
    updateCompanyLocationAction,
    addCompanyLocationAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CompanyLocationForm);
