import React, { useState } from 'react';
import styles from './CustomSidePanelList.module.css';
import CustomTextField from '../../../CustomInput/CustomTextField';

const searchFilter = (searchValue: string) => {
    const filterElements = document.querySelectorAll('.MuiCollapse-wrapperInner > .MuiListItem-root, .MuiCollapse-wrapperInner > .MuiListItem-container');

    if (searchValue === '') {
        filterElements.forEach(e => e.classList.remove(styles.hide));
        return;
    }

    for (let index = 0; index < filterElements.length; index++) {
        const filterElement = filterElements[index];
        //@ts-ignore-next-line
        const filterText = filterElement.querySelector('.MuiListItemText-primary').innerHTML;
        const showFilter = filterText.toUpperCase().indexOf(searchValue.toUpperCase()) > -1;

        if (showFilter) {
            filterElement.classList.remove(styles.hide);
            continue;
        }

        filterElement.classList.add(styles.hide);
    }
}

const CustomListSearch: React.FC = () => {
    const [innerValue, setInnerValue] = useState("");

    return <>
        <div className={"flex paddingBottom10 flexAutoGrow"}>
            <CustomTextField
                autoFocus
                style={{flex: 1}}
                size={"small"}
                placeholder={"Filter suchen"}
                value={innerValue}
                clearButton
                onChange={(value) => {
                    setInnerValue(value);
                    searchFilter(value);
                }}
            />
        </div>
    </>
}

export default React.memo(CustomListSearch);
