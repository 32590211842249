import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { resetFormValidation } from '../../../redux/error/error.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { setTodoAddDialogOpenAction, setTodoToEditAction } from '../../../redux/todo/todo.actions';
import TodoAddForm from './components/TodoAddForm';
import { setCalendarPageAppointment } from '../../../redux/calendar/calendar.actions';
import { momentToDate } from '../../../utils/date.utils';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';

const TodoAddView: React.FC = () => {
    const dispatch = useDispatch();
    const addTodo = useSelector((state: IStore) => state.todo.addTodo);
    const appointment = useSelector((store: IStore) => store.calendarPage.appointment);

    if (!addTodo && !appointment) {
        return null;
    }

    const onClose = () => {
        dispatch(setCalendarPageAppointment(undefined));
        dispatch(setTodoToEditAction(0));
        dispatch(setTodoAddDialogOpenAction(false));
        dispatch(resetFormValidation());
    };

    return (
        <CustomDialog onClose={onClose} open={true} maxWidth={"md"}>
            <CustomDialogHeader translationString={'pages.todo.add'} onClose={onClose} />
            <div style={{ height: "100%", overflow: "auto" }}>
                <TodoAddForm onClose={onClose} initialDueDate={appointment ? momentToDate(appointment) : undefined} />
            </div>
        </CustomDialog>
    );
}
export default TodoAddView;

