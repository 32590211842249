import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDeleteDialog from '../../../../../components/CustomDialog/CustomDeleteDialog';
import { deleteCandidateDocumentAction } from '../../../../../redux/entities/entities.actions';
import { getToken } from '../../../../../selectors/app.selectors';

interface IProps {
    open: boolean;
    documentId?: number;
    onClose: (deleted:boolean) => void;
}

const CandidateDocumentDeleteView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = props.open;
    const deleteDocumentId = props.documentId;
    const onClose = () => {props.onClose(false)}

    if (!open || !deleteDocumentId) {
        return null;
    }

    const onDelete = () => {
        dispatch(deleteCandidateDocumentAction(
            token,
            deleteDocumentId
        ));
        props.onClose(true);
    };

    return (
        <CustomDeleteDialog
            open={open}
            onClose={onClose}
            onDelete={onDelete}
            titleTranslation={"pages.activity.deleteDialog.title"}
            bodyTranslation={"pages.activity.deleteDialog.body"}
        />
    );
};

export default CandidateDocumentDeleteView;
