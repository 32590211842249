import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeListFromStore, getEmployeeListRequestObject } from '../../../selectors/employee.selectors';
import { EEntities } from '../../../definitions/entities.definition';
import { getDefaultCreatedColumn, INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { ESortingOptions } from '../../../definitions/components.definitions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { IStore } from '../../../definitions/redux/store.definitions';
import { translate } from '../../../translation/translate.utils';
import {
    setAdministrationPageEmployeeToEditAction,
    setAdministrationPageFilterAction,
    setAdministrationPageSortingOptionAction,
} from '../../../redux/administrationPage/administrationPage.actions';
import { getToken } from '../../../selectors/app.selectors';
import { getEmployeeListAction } from '../../../redux/entities/entities.actions';

const EmployeeList: React.FC = () => {
    const dispatch = useDispatch();
    const employees = useSelector(getEmployeeListFromStore);
    const loading = useSelector((store: IStore) => store.ui.employeesLoading);
    const filter = useSelector((store: IStore) => store.administrationPage.employeeFilter);
    const totalRecords = useSelector((store: IStore) => store.entities.employees.total);
    const requestObject = useSelector(getEmployeeListRequestObject);
    const token = useSelector(getToken);

    return (
        <>
            <CustomTable
                config={{
                    entity: EEntities.Employee,
                    onRowDoubleClick: (employeeId: number) => dispatch(setAdministrationPageEmployeeToEditAction(employeeId)),
                    columnConfig: {
                        name: {
                            header: translate('pages.companyContact.properties.name'),
                            property: "name",
                        },
                        firstName: {
                            header: translate('pages.companyContact.properties.firstName'),
                            property: "firstName",
                        },
                        created: getDefaultCreatedColumn(),
                    },
                    sortedEntries: employees,
                    loading: loading,
                    scrollCallback: () => {
                        if (filter.limit && filter.limit < totalRecords) {
                            dispatch(setAdministrationPageFilterAction({
                                start: 0,
                                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                            }));
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setAdministrationPageSortingOptionAction(propertyToSort, sortOption)),
                    },
                    onReload: () => {
                        dispatch(getEmployeeListAction(token, requestObject));
                    },
                    count: employees.length,
                    total: totalRecords
                }}
            />
        </>
    );
}

export default EmployeeList;
