import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from '@material-ui/core';
import { getSuggestionDuplicatesRouteConfig } from '../../../../requests/routes';
import { ICandidateListModel, ISuggestion, ISuggestionListModel } from '../../../../definitions/entities.definition';
import { getToken } from '../../../../selectors/app.selectors';
import { ClientApi } from '../../../../requests/ClientApi';

export const useCreateSuggestionForVacancyHook = (name: null|string, firstName: null|string, externalCandidateReference: null|string) => {
    const token = useSelector(getToken);
    const [duplicateCandidates, setDuplicateCandidates] = useState<ICandidateListModel[]>([]);
    const [matchingSuggestions, setMatchingSuggestions] = useState<ISuggestionListModel[]>([]);

    const changeHandler = (callable: () => void) => {
        callable();
    }
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 500), []
    );

    const doRequest = (name: null|string, firstName: null|string, externalCandidateReference: null|string) => {
        ClientApi.request(getSuggestionDuplicatesRouteConfig, {
            token,
            externalCandidateReference,
            name,
            firstName
        }).then((result: {candidatesWithSameName: ICandidateListModel[], matchingSuggestions: ISuggestionListModel[]}) => {
            setDuplicateCandidates(result.candidatesWithSameName);
            setMatchingSuggestions(result.matchingSuggestions);
            return;
        });
    }

    useEffect(() => {
        const externalCheck = externalCandidateReference && externalCandidateReference.length > 3;
        const nameCheck = name && name.length > 3 && firstName && firstName.length > 3;

        if (!externalCheck && !nameCheck) {
            return;
        }
        debouncedChangeHandler(() => doRequest(name, firstName, externalCandidateReference))
    }, [externalCandidateReference, name, firstName]);

    return {matchingSuggestions, duplicateCandidates}
}
