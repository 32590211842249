import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import ChatPanel from '../../../Shared/Chat/ChatPanel';
import { getActivityChatListForRecruitingAction } from '../../../../redux/entities/entities.actions';
import { getRecruitingPageActivitiesRequestObject } from '../../../../selectors/activity.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import FastSearchPanelActivityList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelActivityList';
import RecruitingAddActivityView from './RecruitingAddActivityView';
import { setRecruitingPageActivityListFilterAction } from '../../../../redux/recruitingPage/recruitingPage.actions';

interface IProps {
    recruitingId: number;
}

const RecruitingViewChatPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingId = props.recruitingId;

    const [openAddActivity, setOpenAddActivity] = useState(false);
    const filter = useSelector((store: IStore) => store.recruitingPage.activityFilter);
    const sorting = useSelector((store: IStore) => store.recruitingPage.activitySorting);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const chat = useSelector((store: IStore) => store.entities.activityChat.byRecruiting[recruitingId]);
    const requestObject = useSelector(getRecruitingPageActivitiesRequestObject);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const total = useSelector((store: IStore) => store.entities.activityChat.byRecruiting[recruitingId]?.total);
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const loadChat = () => {
        if (loading) {
            return;
        }
        dispatch(getActivityChatListForRecruitingAction(token, recruitingId, requestObject));
        setInitialLoaded(true);
    }

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getActivityChatListForRecruitingAction(token, recruitingId, requestObject));
        }
    }, [reload]);

    useEffect(() => {
        if (!initialLoaded) {
            return;
        }
        loadChat();
    }, [JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        loadChat();
    }, []);

    if (!chat) {
        return <><CustomBackdropLoadingInsideDiv open={true}/></>
    }

    return <>
        <RecruitingAddActivityView
            open={openAddActivity}
            onClose={() => setOpenAddActivity(false)}
            recruitingId={recruitingId}
        />
        <CustomBackdropLoadingInsideDiv open={loading}/>
        <div className={"padding5"}>
            <FastSearchPanelActivityList
                fastSearch={''}
                setFastSearch={(fastSearch) => dispatch(setRecruitingPageActivityListFilterAction({fastSearch}))}
                addAction={() => setOpenAddActivity(true)}
            />
        </div>
        <ChatPanel
            chat={chat}
            total={total}
            onReload={() => dispatch(getActivityChatListForRecruitingAction(token, recruitingId, requestObject))}
            hideSenderReceiverForInterview
        />
    </>;
};

export default RecruitingViewChatPanel;
