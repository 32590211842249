import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EHomeOfficeDesire } from '../../../definitions/entities.definition';
import CustomCheckboxInput from '../CustomCheckboxInput';

interface IProps {
    onChange: (value: EHomeOfficeDesire[]) => void;
    value: EHomeOfficeDesire[];
}

const HomeOfficeDesireMultipleDropDown: React.FC<IProps> = (props) => {
    const homeOfficeDesireValues: EHomeOfficeDesire[] = [
        EHomeOfficeDesire.no,
        EHomeOfficeDesire.nice_to_have,
        EHomeOfficeDesire.partially,
        EHomeOfficeDesire.complete
    ];

    let menuItems = homeOfficeDesireValues.map((homeOfficeDesire: EHomeOfficeDesire, index: number) => (
        <MenuItem key={index + 1} value={homeOfficeDesire}>
            <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(homeOfficeDesire) > -1)} />
            {translate('misc.homeOfficeDesireValue.' + homeOfficeDesire)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={-1} value={-1}>
            <CustomCheckboxInput checked={Boolean(props.value && props.value.indexOf(-1) > -1)} />
            {translate('misc.noInformation')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                console.log(event.target.value);
                props.onChange(event.target.value);
            }}
            multiple
            value={props.value}
            id={"home-office-multiple-dropdown"}
            label={translate('misc.homeOfficeDesire')}
            renderValue={(selected) =>
                selected
                    .map((status) => <>{translate('misc.homeOfficeDesireValue.' + status)}<br/></>)
            }
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default HomeOfficeDesireMultipleDropDown;
