import React, { memo } from 'react';
import { ICandidateProfiles } from '../../definitions/entities.definition';
import { API_URL } from '../../requests/routes';
import { useSelector } from 'react-redux';
import { getToken } from '../../selectors/app.selectors';
import { fixUrl } from '../../utils/application.utils';
import { COLOR_TEXT } from '../../theme/theme';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    profiles?: ICandidateProfiles;
}

const CandidateProfilesRenderer: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const profiles = props.profiles;

    return <>
        {profiles && profiles.cvId &&
        <div className={'profileItemContainer'} onClick={(event) => {
            event.stopPropagation();
            window.open(`${API_URL}/candidate-document/${profiles.cvId}/preview?Authorization=${token}`);
        }}>
            <img width={22} src={'/lebenslauf.png'} style={{cursor: 'pointer'}} title={'Lebenslauf'}/>
        </div>
        }
        {profiles && profiles.internalProfileId &&
        <div className={'profileItemContainer'} onClick={(event) => {
            event.stopPropagation();
            window.open(`${API_URL}/candidate-document/${profiles.internalProfileId}/preview?Authorization=${token}`);
        }}>
            <img width={22} src={'/peagle.png'} style={{cursor: 'pointer'}} title={'Internes Profil'}/>
        </div>
        }
        {profiles && profiles.githubUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.githubUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/github.png'} title={'GitHub'}/>
            </a>
        </div>
        }
        {profiles && profiles.linkedInUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.linkedInUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/linkedIn.png'} title={'LinkedIn'}/>
            </a>
        </div>
        }
        {profiles && profiles.xingUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.xingUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/xing.png'} title={'Xing'}/>
            </a>
        </div>
        }
        {profiles && profiles.freelancerMapUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.freelancerMapUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/freelancer_map.jpg'} title={'Freelancermap'}/>
            </a>
        </div>
        }
        {profiles && profiles.arbeitsamtUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.arbeitsamtUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/arbeitsagentur.png'} title={'Arbeitsamt'}/>
            </a>
        </div>
        }
        {profiles && profiles.furtherUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.furtherUrl)} className={'alignVerticalCenter'} title={"Weitere URL"} style={{color: COLOR_TEXT}}>
                {IconFactory.getIconComponent(EIcons.Public, {color: 'white'})}
            </a>
        </div>
        }
        {profiles && profiles.homepageUrl &&
        <div className={'profileItemContainer'}>
            <a target="new" href={fixUrl(profiles.homepageUrl)} className={'alignVerticalCenter'}>
                <img width={22} src={'/homepage.png'} title={'Homepage'}/>
            </a>
        </div>
        }
    </>;
};

export default memo(CandidateProfilesRenderer);
