import React from 'react';
import {
    changeVacancySuggestionOrder,
    getSuggestionsForVacancyAction,
    updateSuggestionAction,
} from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { DropResult } from 'react-beautiful-dnd';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { useVacancySuggestionListHook } from './VacancySuggestionList.hooks';
import { getVacancyPageRequestObjectForSuggestionsInEditView } from '../../../../../selectors/recruitingSuggestion.selectors';
import SuggestionList from '../../../../Shared/SuggestionList/SuggestionList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    vacancyId: number;
    archived: boolean;
    collapseOrExpandItems: 'expand'|'collapse';
}

const VacancySuggestionList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const order = useSelector((store: IStore) => store.entities.recruitingSuggestion.byVacancy[vacancyId]);
    const suggestionRequestObject = useSelector(getVacancyPageRequestObjectForSuggestionsInEditView);
    const { loading, suggestions } = useVacancySuggestionListHook(vacancyId);
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionOfVacancyFilter);
    const hideGroupHeader = filter.status !== undefined && !filter.groupByProgressStatus;

    const onDragEnd = (result: DropResult) => {
        const suggestion = suggestions[result.source.index];
        if (!result.destination) {
            return;
        }

        const newRanking = suggestions[result.destination.index].ranking;
        if (newRanking === suggestion.ranking) {
            return;
        }

        const newOrder = order;
        const [reorderedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, reorderedItem);

        dispatch(updateSuggestionAction(
            token, {
                id: suggestion.id,
                ranking: newRanking
            }
        ));
        dispatch(changeVacancySuggestionOrder(newOrder, vacancyId));
    };

    return <SuggestionList
        suggestions={suggestions}
        parentView={EEntityView.vacancy}
        total={suggestions.length}
        loading={loading}
        onDragEnd={onDragEnd}
        hideGroupHeader={hideGroupHeader}
        groupBy={filter.groupByProgressStatus ? 'progressStatus' : 'status'}
        onReload={() => {
            dispatch(getSuggestionsForVacancyAction(token, vacancyId, suggestionRequestObject));
        }}
    />
}

export default VacancySuggestionList;
