import React from 'react';
import { ILanguage, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getLanguageListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomMultipleAutocomplete from '../CustomMultipleAutocomplete';

interface IProps {
    value: ILanguage[]|number[];
    onChange: (value: ILanguage[]) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    onlyWithWebVacancies?: boolean;
}

const LanguageMultipleAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: TBrainzEntity[]) => {
        props.onChange(value as ILanguage[]);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ILanguage).name;
    }

    return <CustomMultipleAutocomplete
        id={"language-multiple-autocomplete"}
        label={props.label || translate('misc.languages')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getLanguageListRouteConfig}
        getOptionLabel={getOptionLabel}
        routeParams={{
            onlyWithWebVacancies: props.onlyWithWebVacancies
        }}
    />;
}

export default React.memo(LanguageMultipleAutocomplete);
