import {
    ISetAdministrationPageActiveTabAction,
    ISetAdministrationPageAddDialogAction,
    ISetAdministrationPageEmployeeToEditAction,
    ISetAdministrationPageFastSearchAction,
    ISetAdministrationPageFilterAction,
    ISetAdministrationPageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    IAdministrationPageState,
    preloadedStateAdministrationPage,
} from '../../definitions/administrationPage.definitions';
import {
    ADMINISTRATION_PAGE_SET_ACTIVE_TAB,
    ADMINISTRATION_PAGE_SET_ADD_DIALOG,
    ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT,
    ADMINISTRATION_PAGE_SET_FAST_SEARCH,
    ADMINISTRATION_PAGE_SET_FILTER,
    ADMINISTRATION_PAGE_SET_SORTING_OPTION,
} from './administrationPage.actions';
import { ENTITIES_POST_EMPLOYEE_FULFILLED, ENTITIES_PUT_EMPLOYEE_FULFILLED } from '../entities/entities.actions';

const administrationPageReducer = (state = preloadedStateAdministrationPage, action: TReducerAction): IAdministrationPageState => {
    switch (action.type) {
        case ENTITIES_PUT_EMPLOYEE_FULFILLED:
        case ENTITIES_POST_EMPLOYEE_FULFILLED: {
            return {
                ...state,
                addEmployeeDialogOpen: false,
                employeeToEdit: 0,
            };
        }
        case ADMINISTRATION_PAGE_SET_ACTIVE_TAB: {
            const actionCast = action as ISetAdministrationPageActiveTabAction;

            return {
                ...state,
                activeTab: actionCast.payload
            }
        }
        case ADMINISTRATION_PAGE_SET_FILTER: {
            const actionCast = action as ISetAdministrationPageFilterAction;

            return {
                ...state,
                employeeFilter: {
                    ...state.employeeFilter,
                    ...actionCast.payload
                },
            }
        }
        case ADMINISTRATION_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetAdministrationPageSortingOptionAction;

            return {
                ...state,
                employeeSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case ADMINISTRATION_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetAdministrationPageFastSearchAction;

            return {
                ...state,
                employeeFilter: {
                    ...state.employeeFilter,
                    fastSearch: actionCast.payload
                },
            }
        }
        case ADMINISTRATION_PAGE_SET_EMPLOYEE_TO_EDIT: {
            const actionCast = action as ISetAdministrationPageEmployeeToEditAction;

            return {
                ...state,
                employeeToEdit: actionCast.payload,
            };
        }
        case ADMINISTRATION_PAGE_SET_ADD_DIALOG: {
            const actionCast = action as ISetAdministrationPageAddDialogAction;

            return {
                ...state,
                addEmployeeDialogOpen: actionCast.payload,
            };
        }
        default:
            return {
                ...state
            }
    }
}

export default administrationPageReducer;
