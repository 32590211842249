import { ESortingOptions } from '../../definitions/components.definitions';
import { IApplicationFilter, IApplicationFilterMenu } from '../../definitions/applicationPage.definitions';
import {
    ISetApplicationPageApplicationToDeclineAction,
    ISetApplicationPageApplicationToEditAction,
    ISetApplicationPageFastSearchAction,
    ISetApplicationPageFilterAction,
    ISetApplicationPageFilterMenuAction,
    ISetApplicationPageReloadAction,
    ISetApplicationPageSortingOptionAction,
} from '../../definitions/redux/actions.definition';

export const APPLICATION_PAGE_SET_FILTER = "APPLICATION_PAGE_SET_FILTER";
export const APPLICATION_PAGE_RESET_FILTER = "APPLICATION_PAGE_RESET_FILTER";
export const APPLICATION_PAGE_SET_SORTING_OPTION = "APPLICATION_PAGE_SET_SORTING_OPTION";
export const APPLICATION_PAGE_SET_FAST_SEARCH = "APPLICATION_PAGE_SET_FAST_SEARCH";
export const APPLICATION_PAGE_SET_FILTER_MENU = "APPLICATION_PAGE_SET_FILTER_MENU";
export const APPLICATION_PAGE_SET_APPLICATION_TO_EDIT = "APPLICATION_PAGE_SET_APPLICATION_TO_EDIT";
export const APPLICATION_PAGE_SET_APPLICATION_TO_DECLINE = "APPLICATION_PAGE_SET_APPLICATION_TO_DECLINE";
export const APPLICATION_PAGE_RELOAD = "APPLICATION_PAGE_RELOAD";

export const setApplicationPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetApplicationPageSortingOptionAction => ({
    type: APPLICATION_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setApplicationPageReload = (): ISetApplicationPageReloadAction => ({
    type: APPLICATION_PAGE_RELOAD,
});


export const setApplicationPageEditAction = (applicationId: number): ISetApplicationPageApplicationToEditAction => ({
    type: APPLICATION_PAGE_SET_APPLICATION_TO_EDIT,
    payload: applicationId,
});

export const setApplicationPageDeclineAction = (applicationId: number): ISetApplicationPageApplicationToDeclineAction => ({
    type: APPLICATION_PAGE_SET_APPLICATION_TO_DECLINE,
    payload: applicationId,
});

export const setApplicationPageFilterAction = (filter: Partial<IApplicationFilter>): ISetApplicationPageFilterAction => ({
    type: APPLICATION_PAGE_SET_FILTER,
    payload: filter,
});

export const resetApplicationPageFilterAction = () => ({
    type: APPLICATION_PAGE_RESET_FILTER,
});

export const setApplicationPageFastSearchAction = (fastSearchValue: string): ISetApplicationPageFastSearchAction => ({
    type: APPLICATION_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setApplicationPageFilterMenuAction = (filterMenu: Partial<IApplicationFilterMenu>): ISetApplicationPageFilterMenuAction => ({
    type: APPLICATION_PAGE_SET_FILTER_MENU,
    payload: filterMenu,
});
