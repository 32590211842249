import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { translate } from '../../translation/translate.utils';

interface IProps {
    checked: boolean;
    onChange?: (event: any, value: boolean) => void;
    labelTranslation?: string;
    label?: ReactNode|string;
    withoutMargin?: boolean;
    disabled?: boolean;
}


const CustomCheckboxInput: React.FC<IProps> = (props) => {
    return (
        <FormControlLabel
            control={<Checkbox
                color={"primary"}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
            />}
            label={props.label ? props.label : props.labelTranslation ? translate(props.labelTranslation) : ''}
        />
    );
};

export default CustomCheckboxInput;
