import React from 'react';
import { EEntities, ICompanyDocument } from '../../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../../translation/translate.utils';
import { getDocumentsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../../definitions/components.definitions';
import { COLOR_BLUE } from '../../../../../theme/theme';
import { API_URL } from '../../../../../requests/routes';
import { getCompanyPageRequestObjectForDocumentsInEditView } from '../../../../../selectors/companyDocument.selectors';
import {
    setCompanyPageDocumentSortingOptionAction,
    setCompanyPageDocumentToDeleteAction,
    setCompanyPageDocumentToEditAction,
} from '../../../../../redux/companyPage/companyPage.actions';
import { getDefaultCreatedColumn } from '../../../../../utils/components.utils';
import { useCompanyDocumentListHook } from './CompanyDocumentList.hooks';

interface IProps {
    companyId: number;
}

const CompanyDocumentsList: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const documentsRequestObject = useSelector(getCompanyPageRequestObjectForDocumentsInEditView);

    const { loading, documents, total} = useCompanyDocumentListHook(companyId);

    return (
        <CustomTable
            config={{
                color: COLOR_BLUE,
                entity: EEntities.CompanyDocument,
                columnConfig: {
                    fileName: {
                        header: translate('pages.companyDocument.properties.fileName'),
                        property: "displayFileName",
                    },
                    type: {
                        header: translate('pages.companyDocument.properties.type'),
                        property: "type",
                        cellRenderer: (type: string) => {
                            return translate('pages.companyDocument.typeValue.' + type);
                        },
                    },
                    created: getDefaultCreatedColumn(),
                },
                sortedEntries: documents,
                listActionButtons: [{
                    action: (documentId: number) => dispatch(setCompanyPageDocumentToEditAction(documentId)),
                    translationKey: "tables.openEditView",
                    icon: EIcons.Edit,
                }, {
                    action: (documentId: number) => dispatch(setCompanyPageDocumentToDeleteAction(documentId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                }, {
                    action: (documentId: number, document) => {
                        const companyDocument = document as ICompanyDocument;
                        window.open(`${API_URL}/company-document/${companyDocument.id}/preview?Authorization=${token}`)
                    },
                    translationKey: "tables.startDownload",
                    icon: EIcons.Download,
                }],
                onRowDoubleClick: (documentId: number, document) => {
                    const companyDocument = document as ICompanyDocument;
                        window.open(`${API_URL}/company-document/${companyDocument.id}/preview?Authorization=${token}`)
                },
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCompanyPageDocumentSortingOptionAction(propertyToSort, sortOption)),
                },
                loading: loading,
                count: documents.length,
                total: total,
                onReload: () => {
                    dispatch(getDocumentsForCompanyAction(
                        token,
                        companyId,
                        documentsRequestObject
                    ));
                }
            }}
        />
    );
};

export default CompanyDocumentsList;
