import React from 'react';
import { Backdrop, CircularProgress, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_TEXT } from '../../theme/theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 99999,
            color: COLOR_TEXT,
        },
    })
);

interface IProps {
    open: boolean;
}

const CustomBackdropLoading: React.FC<IProps> = (props) => {
    const styles = useStyles();

    if (!props.open) {
        return null;
    }

    return (
        <Backdrop className={styles.backdrop} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default React.memo(CustomBackdropLoading);
