import React from 'react';
import { Rating } from '@material-ui/lab';

interface IProps {
    rating: number;
}

const SuggestionRating: React.FC<IProps> = (props) => {
    const rating = props.rating;

    return <Rating max={3} value={rating/2} readOnly />
}

export default React.memo(SuggestionRating);
