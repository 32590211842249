import React from 'react';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import CustomCursorDiv from '../misc/CustomCursorDiv';
import { ESortingOptions, ITableHeaderCallbacks } from '../../definitions/components.definitions';
import { EEntities } from '../../definitions/entities.definition';

interface IProps {
    entity: EEntities;
    tableHeaderCallbacks: ITableHeaderCallbacks;
    propertyToSort: string;
    sorting: ESortingOptions;
    color?: string;
}

const getArrowIconBySorting = (sorting: undefined|ESortingOptions) => {
    const color = 'var(--brainz-most-darkest)';
    if (sorting === ESortingOptions.Ascending) {
        return IconFactory.getIconComponent(EIcons.ArrowUp, {color});
    }
    if (sorting === ESortingOptions.Descending) {
        return IconFactory.getIconComponent(EIcons.ArrowDown, {color});
    }

    return IconFactory.getIconComponent(EIcons.ArrowLeft, {color: 'var(--brainz-color-text-table-and-tab-headers)'});
};

const CustomTableHeaderCellSortingButton: React.FC<IProps> = (props) => {
    const sorting = props.sorting;
    const arrowIcon = getArrowIconBySorting(sorting);

    if (sorting && sorting !== 'NONE') {
        return <><CustomCursorDiv>{arrowIcon}</CustomCursorDiv></>;
    }

    return (
        <div className={"hoverContent"}>
            <CustomCursorDiv>{arrowIcon}</CustomCursorDiv>
        </div>
    );
};

export default CustomTableHeaderCellSortingButton;
