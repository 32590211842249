import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(
    createStyles({
        root: {
            cursor: "pointer",
        },
    })
);

const CustomCursorDiv: React.FC = (props) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
};

export default CustomCursorDiv;
