import { useEffect, useState } from 'react';
import { getContactsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getCompanyContactsForCompany } from '../../../../../selectors/companyContacts.selectors';
import { getCompanyPageRequestObjectForCompanyContactsInEditView } from '../../../../../selectors/company.selectors';

export const useCompanyContactListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyContactsLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForCompanyContactsInEditView);
    const sorting = useSelector((store: IStore) => store.companyPage.companyContactSorting);
    const contacts = useSelector((store: IStore) => getCompanyContactsForCompany(store, companyId));

    useEffect(() => {
        dispatch(getContactsForCompanyAction(token, companyId, requestObject));
        setInitialLoading(false);
    }, [companyId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getContactsForCompanyAction(token, companyId, requestObject));
        }
    }, [JSON.stringify(sorting)]);

    return {loading, contacts, total: contacts.length};
}
