import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { IApplication } from '../../../definitions/entities.definition';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { getFormattedDateString } from '../../../utils/date.utils';
import { translate } from '../../../translation/translate.utils';
import { setApplicationPageEditAction } from '../../../redux/applicationPage/applicationPage.actions';
import { IStore } from '../../../definitions/redux/store.definitions';

interface IProps {
    application: IApplication;
    parentView: 'candidate'|'vacancy'|'company';
}

const RenderApplicationListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const parentView = props.parentView;
    const application = props.application;
    const expandOrCollapseItems = useSelector((store: IStore) => store.ui.expandOrCollapseApplicationListItems);
    const [showDetails, setShowDetails] = useState(expandOrCollapseItems === 'expand');

    useEffect(() => {
        setShowDetails(expandOrCollapseItems === 'expand');
    }, [expandOrCollapseItems])

    return <>
        <div>
            <Card
                className={styles.root} style={{cursor: 'default'}}
                onClick={() => {
                    dispatch(setApplicationPageEditAction(application.id));
                }}
            >
                <CardHeader
                    title={
                        <>
                            <div className={"flexContainerRow"}>
                                <div className={"flex1 flexContainerRow alignVerticalCenter"}>
                                    <CandidateSourceRenderer source={application.candidate.source} />&nbsp;
                                    {parentView !== 'candidate' &&
                                        <> | {application.candidate.fullName} | </>
                                    }
                                    {parentView !== 'vacancy' && application.vacancy &&
                                        <>{application.vacancy.title}
                                            <>
                                                {parentView !== 'company' &&
                                                    <>({application.vacancy.company.name})</>
                                                }
                                            </>
                                        </>
                                    }
                                </div>
                                <div className={"flexAutoGrow flexContainerRow alignVerticalCenter alignHorizontalRight"}>
                                    {parentView !== 'candidate' &&
                                        <CandidateProfilesRenderer profiles={application.candidateProfiles} />
                                    }
                                    {!showDetails &&
                                        <CustomClickableIcon
                                            tooltip={"Weitere Daten anzeigen"}
                                            onClick={() => setShowDetails(true)}
                                            icon={EIcons.Fullscreen}
                                            iconStyle={{width: '1.5em', height: '1.5em'}}
                                        />
                                    }
                                    {showDetails &&
                                        <CustomClickableIcon
                                            tooltip={"Weitere Daten ausblenden"}
                                            onClick={() => setShowDetails(false)}
                                            icon={EIcons.FullscreenExit}
                                            iconStyle={{width: '1.5em', height: '1.5em'}}
                                        />
                                    }
                                </div>
                            </div>
                            {showDetails &&
                            <>
                                <Divider style={{margin: 2, height: 1}}/>
                                <div className={"flex"}>
                                    <div className={"flexContainerRow alignVerticalCenter flex1"}>
                                        {IconFactory.getIconComponent(EIcons.Calendar)} Beworben am: {getFormattedDateString(application.created)} (Status: {translate('pages.application.statusValue.' + application.status)})
                                    </div>
                                </div>
                                {application.candidate.phoneNumber &&
                                <>
                                    <Divider style={{margin: 2, height: 1}}/>
                                    <div className={"formRowColumn"}>
                                        <div className={"formLabel"}>Telefon:</div>
                                        <div className={"formContent"}>
                                            <Nl2brCellRenderer value={application.candidate.phoneNumber}/>
                                        </div>
                                    </div>
                                </>
                                }
                            </>
                            }
                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </div>
    </>;
}

export default RenderApplicationListItem;
