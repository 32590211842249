import { createTheme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

export const darkest = '#23272a';
export const darker = '#2c2f33';
export const dark = '#56585b';
export const darkLight = '#ccc';

export const COLOR_TEXT = 'rgb(233, 237, 239)';
export const COLOR_TEXT_SECONDARY = '#bbbbbb';
export const COLOR_TABLE_AND_TAB_HEADERS = 'rgb(233, 237, 239)';
export const COLOR_INPUT = COLOR_TEXT_SECONDARY;
export const COLOR_INPUT_DISABLED = '#777';

export const COLOR_YELLOW = '#E9C46A';
export const COLOR_ORANGE = '#F4A261';
export const COLOR_RED = '#EB886F';
export const COLOR_BLUE = '#0087B8';
export const COLOR_GREEN = '#00a884';
export const COLOR_GREEN_LIGHT = '#2A9D8F';//GREEN
export const COLOR_PURPLE = '#6B5FAD';

const ordinaryPalette = {
    secondary: {
        main: darker,
    },
    background: {
        paper: darkest,
    },
    text: {
        primary: COLOR_TEXT_SECONDARY,
        secondary: COLOR_TEXT,
    },
};

const overrides: Overrides = {
    MuiListItem: {
        button: {
            '&.Mui-selected': {
                background: `var(--brainz-darker) !important`,
                color: `${COLOR_TEXT} !important`,
            },
            '&:hover': {
                background: 'var(--brainz-darker) !important',
                color: `${COLOR_TEXT} !important`,
            },
        },
    },
    MuiCardHeader: {
        root: {
            padding: 10
        }
    },
    MuiLink: {
        root: {
            color: COLOR_TEXT
        }
    },
    MuiCheckbox: {
        root: {
            color: COLOR_INPUT
        }
    },
    MuiInputLabel: {
        root: {
            color: COLOR_INPUT,
            '&.Mui-disabled': {
                color: COLOR_INPUT_DISABLED,
            },
        },
    },
    MuiFormLabel: {
        root: {
            color: COLOR_INPUT,
            '&.Mui-focused': {
                color: `${COLOR_TEXT} !important`,
            },
            '&.Mui-disabled': {
                color: COLOR_INPUT_DISABLED,
            },
        },
    },
    MuiOutlinedInput: {
        root: {
            '&:hover $notchedOutline': {
                borderColor: COLOR_TEXT,
            },
            '&$focused $notchedOutline': {
                borderColor: COLOR_TEXT,
            },
            '&.Mui-disabled $notchedOutline': {
                borderColor: COLOR_INPUT_DISABLED,
            },
        },
        notchedOutline: {
            borderColor: COLOR_INPUT,
        },
        disabled: {
            borderColor: 'red !important'
        }
    },
    MuiTypography: {
        body1: {
            fontSize: 14,
            fontWeight: 'unset',
        },
    },
    MuiIconButton: {
        root: {
            color: COLOR_TEXT_SECONDARY,
        },
    },
    MuiSelect: {
        icon: {
            position: 'relative',
            color: COLOR_TEXT_SECONDARY,
            marginLeft: -22,
            marginTop: -30,
        },
        iconOutlined: {
            position: 'relative',
            color: COLOR_TEXT_SECONDARY,
            marginLeft: -22,
            marginTop: -30
        }
    },
    MuiButton: {
        root: {
            textTransform: 'unset',
            '&.Mui-disabled': {
                color: `${COLOR_TEXT_SECONDARY} !important`,
            },
        },
        containedPrimary: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    MuiTooltip: {
        tooltip: {
            fontSize: '.875rem',
            color: COLOR_TEXT,
            boxShadow: '0 1px 14px 0 rgb(0 0 0 / 14%) !important',
        },
    },
    MuiDialogTitle: {
        root: {
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 5,
            paddingBottom: 5
        },
    },
    MuiAppBar: {
        colorPrimary: {
            color: COLOR_TEXT,
        },
    },
    MuiTab: {
        root: {
            fontWeight: 'unset',
            fontSize: 16,
            textTransform: 'unset',
        },
        wrapper: {
            color: COLOR_TABLE_AND_TAB_HEADERS,
            fontWeight: 'unset',
        },

    },
};

export const theme = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_BLUE,
        },
        ...ordinaryPalette,
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});

export const themeYellow = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_YELLOW,
        },
        ...ordinaryPalette,
    },
});

export const themeBlue = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_BLUE,
        },
        ...ordinaryPalette,
    },
});

export const themePurple = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_PURPLE,
        },
        ...ordinaryPalette,
    },
});

export const themeRed = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_RED,
        },
        ...ordinaryPalette,
    },
});

export const themeDark = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: dark,
        },
        ...ordinaryPalette,
    },
});

export const themeOrange = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_ORANGE,
        },
        ...ordinaryPalette,
    },
});

export const themeGreen = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_GREEN,
        },
        ...ordinaryPalette,
    },
});

export const themeGreenLight = createTheme({
    overrides: overrides,
    palette: {
        primary: {
            main: COLOR_GREEN,
        },
        ...ordinaryPalette,
    },
});

export default theme;
