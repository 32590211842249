import React from 'react';
import { EEntities } from '../../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCompanyPageLocationSortingOptionAction,
    setCompanyPageLocationToDeleteAction,
} from '../../../../../redux/companyPage/companyPage.actions';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../../translation/translate.utils';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import { ESortingOptions } from '../../../../../definitions/components.definitions';
import { setUiCompanyLocationToEditAction } from '../../../../../redux/ui/ui.actions';
import { getToken } from '../../../../../selectors/app.selectors';
import { getCompanyPageRequestObjectForCompanyLocationsInEditView } from '../../../../../selectors/company.selectors';
import { getLocationsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { COLOR_BLUE } from '../../../../../theme/theme';
import { useCompanyLocationListHook } from './CompanyLocationList.hooks';

interface IProps {
    companyId: number;
}

const CompanyLocationList: React.FC<IProps> = (props) => {
    const companyId = props.companyId;
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyLocationsRequestObject = useSelector(getCompanyPageRequestObjectForCompanyLocationsInEditView);

    const { loading, locations, total} = useCompanyLocationListHook(companyId);

    return (
        <CustomTable
            config={{
                color: COLOR_BLUE,
                entity: EEntities.CompanyLocation,
                loading,
                columnConfig: {
                    zipCode: {
                        header: translate('misc.zipCode'),
                        property: 'zipCode',
                        width: 160,
                        // @ts-ignore-next-line
                        cellRenderer: (zipCode: string, contact: ICompanyContact) => {
                            if (!zipCode && !contact.city) {
                                return <div>-</div>;
                            }
                            return (
                                <div className={"flexContainerColumn"}>
                                    <div>{zipCode}</div>
                                    <div className={'formContent'}>{contact.city}</div>
                                </div>
                            );
                        }
                    },
                    address: {
                        header: translate('misc.address'),
                        property: "address",
                    },
                },
                sortedEntries: locations,
                listActionButtons: [{
                    action: (locationId: number) => dispatch(setCompanyPageLocationToDeleteAction(locationId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                }],
                onRowDoubleClick: (locationId: number) => dispatch(setUiCompanyLocationToEditAction(locationId)),
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setCompanyPageLocationSortingOptionAction(propertyToSort, sortOption)),
                },
                total: total,
                onReload: () => {
                    dispatch(getLocationsForCompanyAction(
                        token,
                        companyId,
                        companyLocationsRequestObject
                    ));
                }
            }}
        />
    );
};

export default CompanyLocationList;
