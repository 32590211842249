import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { TSalutation } from '../../../definitions/entities.definition';

interface IProps {
    onChange?: TMouseEventFunction;
    value: 0 | TSalutation;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

export const salutations: TSalutation[] = ["mr", "mrs"];

const SalutationDropDown: React.FC<IProps> = (props) => {
    let menuItems = salutations.map((salutation: TSalutation, index: number) => (
        <MenuItem key={index + 1} value={salutation}>
            {translate('misc.' + salutation)}
        </MenuItem>
    ));

    if (!props.required) {
        menuItems = [
            <MenuItem key={0} value={0}>
                {translate('misc.choose')}
            </MenuItem>,
            ...menuItems,
        ];
    }

    return (
        <CustomDropDownInput
            width={props.required ? 100 : 150}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
            disabled={props.disabled}
            onChange={props.onChange}
            value={props.value || 0}
            id={"salutation-dropdown"}
            label={translate('misc.salutation')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default SalutationDropDown;
