import { IApiBaseFilter, ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import {
    ETermsOfPayment,
    ICompany,
    ICompanyContactPartial,
    ICompanyLocationPartial,
    ICompanyPartial,
} from './entities.definition';
import { IGroupedRecruitingEvaluationData } from './dashboardPage.definitions';
import moment from 'moment';
import { momentToDate } from '../utils/date.utils';

export enum EBillingPageTabs {
    tabDashboard,
    tabInvoices,
}

export enum EDocumentType {
    invoice
}

export enum EDocumentStatus {
    open,
    send,
    cancelled,
    closed,
    remind,
    first_dunning,
    second_dunning,
    last_dunning
}

export interface IInvoiceFilter extends Partial<IApiBaseFilter> {
    dateFrom?: string;
    dateTo?: string;
    companyId?: number;
    status?: EDocumentStatus[]
}

export const preloadedInvoiceFilter: IInvoiceFilter = {
    start: 0,
    limit: 50,
}

export interface IInvoiceFilterMenu extends Partial<IApiBaseFilter> {
    dateFrom?: string;
    dateTo?: string;
    company?: ICompany;
    status: EDocumentStatus[]
}

export const preloadedInvoiceFilterMenu: IInvoiceFilterMenu = {
    start: 0,
    limit: 50,
}

export enum EInvoiceType {
    MANUAL_INVOICE,
    DEFAULT_DOWN_INVOICE,
    DEFAULT_CLOSING_INVOICE,
    HEADHUNTING_FIRST_INVOICE,
    HEADHUNTING_SECOND_INVOICE,
    HEADHUNTING_CLOSING_INVOICE,
    PROJECT_INVOICE,
    DEFAULT_INVOICE
}

export interface IBillingPageState {
    addInvoice: boolean;
    invoiceToDelete: number;
    invoiceToEdit: number;
    sortingInvoiceList: ISorting;
    filterInvoiceList: IInvoiceFilter,
    filterMenuInvoiceList: IInvoiceFilterMenu,
    activeTab: EBillingPageTabs;
    invoiceToPreview?: number;
    generateViewOpen: boolean;
    addPaymentForInvoiceId: number;
    reloadInvoiceList: boolean;
    invoiceForm: IInvoiceFormState;
    groupedEvaluationData: {
        byEmployee: IGroupedRecruitingEvaluationData[];
        byCandidateSource: IGroupedRecruitingEvaluationData[];
        byBranch: IGroupedRecruitingEvaluationData[];
        byCompany: IGroupedRecruitingEvaluationData[];
        byMonth: IGroupedRecruitingEvaluationData[];
        byMonthSummedUp: IGroupedRecruitingEvaluationData[];
    };
    chartYear: number;
    recalculateInvoiceForm: boolean;
}

export const initialInvoiceFormState: IInvoiceFormState = {
    numberDisabled: true,
    remindOfPaymentCreated: false,
    remindOfPaymentDueDate: null,
    remindOfPaymentDate: null,
    items: [],
    totalNet: 0,
    discountInPercent: 0,
    discountSum: 0,
    vat: 19,
    vatSum: 0,
    totalGross: 0,
    totalNetAfterDiscount: 0,
    termsOfPaymentId: ETermsOfPayment.Days14,
    date: new Date(),
    dueDate: momentToDate(moment(new Date()).add(14, 'days'))
}

export interface IInvoiceFormState {
    id?: number;
    numberDisabled: boolean,
    number?: string;
    date?: Date;
    dueDate?: Date;
    company?: ICompanyPartial;
    companyLocation?: ICompanyLocationPartial;
    companyContact?: ICompanyContactPartial;
    companyName?: string;
    address?: string;
    addressLine2?: string;
    zipCode?: string;
    city?: string;
    totalNet: number;
    discountInPercent: number;
    discountSum: number;
    totalNetAfterDiscount: number;
    vat: number;
    vatSum: number;
    totalGross: number;
    comment?: string;
    openToPay?: number;
    items: IInvoiceItemForm[];
    termsOfPaymentId: ETermsOfPayment;
    remindOfPaymentCreated: boolean;
    remindOfPaymentDate: null|Date;
    remindOfPaymentDueDate: null|Date;
}

export interface IInvoiceItemForm {
    id?: number;
    ranking: number;
    title: string;
    comment?: string;
    amount: number;
    price: number;
    priceTotal?: number;
}

export interface IInvoiceGoalBurnUpChartData {
    month: number;
    targetCommission?: number;
    monthTotalCommission?: number;
    totalCommission?: number;
}

export const preloadedStateBillingPage: IBillingPageState = {
    addInvoice: false,
    invoiceToEdit: 0,
    invoiceForm: initialInvoiceFormState,
    filterInvoiceList: preloadedInvoiceFilter,
    filterMenuInvoiceList: preloadedInvoiceFilterMenu,
    sortingInvoiceList: {
        'date': ESortingOptions.Descending
    },
    activeTab: EBillingPageTabs.tabDashboard,
    generateViewOpen: false,
    addPaymentForInvoiceId: 0,
    reloadInvoiceList: false,
    groupedEvaluationData: {
        byBranch: [],
        byCandidateSource: [],
        byEmployee: [],
        byMonth: [],
        byMonthSummedUp: [],
        byCompany: []
    },
    chartYear: (new Date()).getFullYear(),
    recalculateInvoiceForm: false,
    invoiceToDelete: 0
}
