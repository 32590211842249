import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../selectors/app.selectors';
import { deleteSkillAction } from '../../redux/entities/entities.actions';
import {
    setSkillPageSkillToEditAction,
    setSkillPageTemplateToDeleteAction,
} from '../../redux/skillPage/skillPage.actions';

const SkillDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.skillPage.skillToDelete));
    const idToDelete = useSelector((store: IStore) => store.skillPage.skillToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setSkillPageTemplateToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteSkillAction(token, idToDelete));
                dispatch(setSkillPageTemplateToDeleteAction(0));
                dispatch(setSkillPageSkillToEditAction(0));
            }}
            titleTranslation={"pages.skill.deleteDialog.title"}
            bodyTranslation={"pages.skill.deleteDialog.body"}
        />
    );
};

export default SkillDeleteView;
