import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getApplicationsForCandidateFromStore,
    getCandidatePageRequestObjectForApplicationsInEditView,
} from '../../../../../selectors/application.selectors';
import { useEffect } from 'react';
import { getToken } from '../../../../../selectors/app.selectors';
import { getApplicationListForCandidateAction } from '../../../../../redux/entities/entities.actions';

export const useCandidateApplicationListHook = (candidateId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.applicationsLoading);
    const reload = useSelector((store: IStore) => store.ui.reloadApplications);

    const requestObject = useSelector(getCandidatePageRequestObjectForApplicationsInEditView);
    const applications = useSelector((store: IStore) => getApplicationsForCandidateFromStore(store, candidateId));

    useEffect(() => {
        dispatch(getApplicationListForCandidateAction(token, candidateId, requestObject));
    }, [candidateId]);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getApplicationListForCandidateAction(token, candidateId, requestObject));
        }
    }, [reload]);

    return {loading, applications, total: applications.length};
}
