import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityListForWebVacancyAction } from '../../../../redux/entities/entities.actions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getToken } from '../../../../selectors/app.selectors';
import {
    getActivitiesForWebVacancyFromStore,
    getWebVacancyPageActivitiesRequestObject,
} from '../../../../selectors/activity.selectors';

export const useWebVacancyActivityListHook = (webVacancyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getWebVacancyPageActivitiesRequestObject);
    const activityFastSearch = useSelector((store: IStore) => store.webVacancyPage.activityFastSearch);
    const activities = useSelector((store: IStore) => getActivitiesForWebVacancyFromStore(store, webVacancyId));
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    useEffect(() => {
        dispatch(getActivityListForWebVacancyAction(token, webVacancyId, requestObject));
        setInitialLoading(false);
    }, [webVacancyId]);

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getActivityListForWebVacancyAction(token, webVacancyId, requestObject));
        }
    }, [reload]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getActivityListForWebVacancyAction(token, webVacancyId, requestObject));
        }
    }, [activityFastSearch]);

    return {loading, activities};
}
