import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getActivityChatListAction } from '../../../redux/entities/entities.actions';
import { getActivityPageRequestObject } from '../../../selectors/activity.selectors';
import ChatPanel from '../../Shared/Chat/ChatPanel';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import { INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { setActivityPageFilterAction } from '../../../redux/activityOverviewPage/activityOverviewPage.actions';

const ActivityOverviewChatPanel: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const filter = useSelector((store: IStore) => store.activityPage.filter);
    const sorting = useSelector((store: IStore) => store.activityPage.sorting);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const chat = useSelector((store: IStore) => store.entities.activityChat.global);
    const requestObject = useSelector(getActivityPageRequestObject);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const count = useSelector((store: IStore) => store.entities.activityChat.global?.nodes.length);
    const total = useSelector((store: IStore) => store.entities.activityChat.global?.total);
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const loadChat = () => {
        if (loading) {
            return;
        }
        dispatch(getActivityChatListAction(token, requestObject));
        setInitialLoaded(true);
    }

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getActivityChatListAction(token, requestObject));
        }
    }, [reload]);

    useEffect(() => {
        if (!initialLoaded) {
            return;
        }
        loadChat();
    }, [JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        loadChat();
    }, []);

    if (!chat) {
        return <><CustomBackdropLoadingInsideDiv open={true}/></>
    }

    const scrollCallback = () => {
        if (filter.limit && filter.limit < total) {
            dispatch(setActivityPageFilterAction({
                start: 0,
                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
            }));
        }
    }

    return <>
        <CustomBackdropLoadingInsideDiv open={loading}/>
        <ChatPanel
            chat={chat}
            count={count}
            total={total || 0}
            loading={loading}
            scrollCallback={scrollCallback}
            onReload={() => dispatch(getActivityChatListAction(token, requestObject))}
        />
    </>;
};

export default ActivityOverviewChatPanel;
