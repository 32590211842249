import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ESuggestionDeclineReason } from '../../../definitions/entities.definition';

interface IProps {
    onChange?: (value: ESuggestionDeclineReason) => void;
    value?: ESuggestionDeclineReason|null;
    label?: string;
}

const SuggestionDeclineReasonDropDown: React.FC<IProps> = (props) => {
    const reasonValues: ESuggestionDeclineReason[] = [
        ESuggestionDeclineReason.REASON_MISSING_FEEDBACK,
        ESuggestionDeclineReason.REASON_NOT_MATCHING,
        ESuggestionDeclineReason.REASON_NO_INTEREST,
        ESuggestionDeclineReason.REASON_CONDITIONS,
        ESuggestionDeclineReason.REASON_OTHER
    ];

    let menuItems = reasonValues.map((reason: ESuggestionDeclineReason, index: number) => (
        <MenuItem key={index + 1} value={reason}>
            {translate('pages.vacancy.declineSuggestionReasonValues.' + reason)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (!props.onChange) {
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value || 0}
            id={"decline-suggestion-reason-dropdown"}
            label={props.label || 'Grund der Absage'}
            required
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default SuggestionDeclineReasonDropDown;
