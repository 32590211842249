import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ISkillTreeNode } from '../../../definitions/entities.definition';
import SkillTreeItem from './SkillTreeItem';

interface IProps {
    initialExpanded: string[];
    selected?: string[];
    selectable?: boolean;
    treeNodes: ISkillTreeNode[];
    onSelect?: (treeNode: ISkillTreeNode) => void;
    onDeselect?: (treeNode: ISkillTreeNode) => void;
}
const useStyles = makeStyles({
    root: {
        maxWidth: 700,
        flexGrow: 1,
    },
});

const SkillTreeView: React.FC<IProps> = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        //@ts-ignore
        if (props.selectable && event.target.tagName === 'INPUT') {
            return;
        }

        setExpanded(nodeIds);
    };

    useEffect(() => {
        setExpanded(props.initialExpanded);
    }, [props.initialExpanded])

    const renderNode = (treeNode: ISkillTreeNode) => {
        return (
            <SkillTreeItem
                nodeId={treeNode.id.toString()}
                key={treeNode.id}
                node={treeNode}
                selectable={props.selectable}
                selected={props.selected ? props.selected.includes(treeNode.id.toString()) : false }
                onCheck={props.onSelect}
                onUncheck={props.onDeselect}
            >
                {treeNode.children.map((childTreeNode) => renderNode(childTreeNode))}
            </SkillTreeItem>
        )
    }

    return (
        <>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24, paddingRight: 20 }} />}
                expanded={expanded}
                onNodeToggle={handleToggle}
                style={{overflowY: "auto", overflowX: "hidden", paddingRight: 10}}
            >
                {props.treeNodes.map((treeNode) => renderNode(treeNode))}
            </TreeView>
        </>
    );
}

export default SkillTreeView;
