import React from 'react';
import { COLOR_GREEN, COLOR_ORANGE, COLOR_YELLOW } from '../../../theme/theme';
import { ArgumentAxis, Chart, LineSeries, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Axis, BarSeries, EventTracker, ScatterSeries, SplineSeries, ValueScale } from '@devexpress/dx-react-chart';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    setDashboardPageChartSelectionAction,
    setDashboardPageChartSelectionRecruitingsViewOpenAction,
} from '../../../redux/dashboardPage/dashboardPage.actions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { IGoalBurnUpChartData } from '../../../definitions/dashboardPage.definitions';

interface IProps {
    chartData: IGoalBurnUpChartData[],
    index: 'Commission' | 'Contracts',
}

const GoalBurnUpChart: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const chartSettings = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    return (
        <>
            <Chart data={props.chartData} height={380} >
                <ValueScale name={"target" + props.index} />
                <ValueScale name={"total" + props.index} />

                <ArgumentAxis labelComponent={(labelProps: Axis.LabelProps) => <ValueAxis.Label {...labelProps} style={{fill: 'var(--brainz-color-text-secondary)'}}/>}/>

                <LineSeries valueField={"target" + props.index} argumentField={"month"} scaleName={"total" + props.index} />
                <SplineSeries valueField={"total" + props.index} argumentField={"month"} scaleName={"total" + props.index} color={COLOR_ORANGE}/>
                <ScatterSeries valueField={"total" + props.index} argumentField={"month"} scaleName={"total" + props.index} color={COLOR_ORANGE}/>

                <SplineSeries valueField={"lastYearTotal" + props.index} argumentField={"month"} scaleName={"total" + props.index} color={COLOR_GREEN}/>
                <ScatterSeries valueField={"lastYearTotal" + props.index} argumentField={"month"} scaleName={"total" + props.index} color={COLOR_GREEN}/>

                <BarSeries valueField={"monthTotal" + props.index} argumentField="month" scaleName={"total" + props.index} barWidth={10} color={COLOR_YELLOW} />

                <ValueAxis
                    scaleName={"total" + props.index}
                    showGrid={false}
                    showLine
                    showTicks
                    labelComponent={(labelProps: Axis.LabelProps) => <ValueAxis.Label {...labelProps} style={{fill: 'var(--brainz-color-text-secondary)'}}/>}
                />
                <EventTracker onClick={(targetData) => {
                    if (targetData.targets.length === 0) {
                        return;
                    }
                    const data = props.chartData[targetData.targets[0].point];

                    dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(true));
                    dispatch(setDashboardPageChartSelectionAction({
                        employeeId: chartSettings.employeeId,
                        employeeName: chartSettings.employeeId ? loggedInEmployee.firstName + ' ' + loggedInEmployee.name : undefined,
                        month: data.month
                    }));
                }}/>
                <Tooltip contentComponent={(contentProps) => {
                    const data = props.chartData[contentProps.targetItem.point];

                    let monthValue: string|number = data.monthTotalContracts ?? 0;
                    let lastYearMonthValue: string|number = data.lastYearMonthTotalContracts ?? 0;
                    let totalValue: string|number = data.totalContracts ?? 0;
                    let lastYearTotalValue: string|number = data.lastYearTotalContracts ?? 0;
                    let targetValue: string|number = data.targetContracts ?? 0;

                    if (chartSettings.byCommission) {
                        monthValue = data.monthTotalCommission ? data.monthTotalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                        totalValue = data.totalCommission ? data.totalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                        targetValue = data.targetCommission ? data.targetCommission.toLocaleString("de-DE") + ' €' : '0 €';
                        lastYearMonthValue = data.lastYearMonthTotalCommission ? data.lastYearMonthTotalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                        lastYearTotalValue = data.lastYearTotalCommission ? data.lastYearTotalCommission.toLocaleString("de-DE") + ' €' : '0 €';
                    }

                    return (
                        <>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td width={100} align={"right"}>{chartSettings.year}</td>
                                        { chartSettings.showLastYear && <td width={100} align={"right"}>{chartSettings.year - 1}</td> }
                                    </tr>
                                    <tr>
                                        <td>Monat:</td>
                                        <td align={"right"}>{monthValue}</td>
                                        { chartSettings.showLastYear && <td align={"right"}>{lastYearMonthValue}</td> }
                                    </tr>
                                    <tr>
                                        <td>Ist:</td>
                                        <td align={"right"}>{totalValue}</td>
                                        { chartSettings.showLastYear && <td align={"right"}>{lastYearTotalValue}</td> }
                                    </tr>
                                    <tr>
                                        <td>Soll:</td>
                                        <td align={"right"}>{targetValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )
                }}/>
            </Chart>
        </>

    );
}

export default GoalBurnUpChart;
