import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomRadioInput from '../../../../components/CustomInput/CustomRadioInput';
import LegacyCandidateAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCandidateAutocompleteDropDown';
import { ICompany, IMail, IRecruitingPartial, IVacancyPartial } from '../../../../definitions/entities.definition';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import OpenRecruitingAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/OpenRecruitingAutocompleteDropDown';
import { ClientApi } from '../../../../requests/ClientApi';
import { postAssignMailRouteConfig } from '../../../../requests/routes';
import { getToken } from '../../../../selectors/app.selectors';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';

interface IProps {
    mailId: number;
    open: boolean;
    onClose: () => void;
    reload: (mail: number) => void;
}

const AssignMailView: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);

    const [record, setRecord] = useState<'recruiting'|'vacancy'|'company'|'candidate'>('recruiting');
    const [candidateId, setCandidateId] = useState<undefined|number>(undefined);
    const [recruiting, setRecruiting] = useState<undefined|IRecruitingPartial>(undefined);
    const [vacancy, setVacancy] = useState<null|IVacancyPartial>(null);
    const [company, setCompany] = useState<undefined|ICompany>(undefined);
    const [errors, setErrors] = useState<{vacancy?: string, company?: string, candidateId?: string, recruiting?: string}>({});

    const onSave = () => {
        setErrors({});
        if (record === 'recruiting' && !recruiting) {
            setErrors({
                recruiting: 'This value should not be blank'
            });
            return
        }
        if (record === 'candidate' && !candidateId) {
            setErrors({
                candidateId: 'This value should not be blank'
            });
            return
        }
        if (record === 'vacancy' && !vacancy) {
            setErrors({
                vacancy: 'This value should not be blank'
            });
            return
        }
        if (record === 'company' && !company) {
            setErrors({
                company: 'This value should not be blank'
            });
            return
        }

        let entityId;
        switch (record) {
            case 'recruiting':
                entityId = recruiting?.id;
                break;
            case 'vacancy':
                entityId = vacancy?.id;
                break;
            case 'candidate':
                entityId = candidateId;
                break;
            case 'company':
                entityId = company?.id;
                break;
        }

        ClientApi.request(postAssignMailRouteConfig, {
            token,
            id: props.mailId,
            assignToEntity: record,
            entityId
        }).then((response: IMail) => {
            props.reload(response.id);
            props.onClose();
        });
    }

    if (!props.open) {
        return null;
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CustomDialogHeader string="E-Mail mit Datensatz verknüpfen" onClose={props.onClose} />
            <PaperRow>
                <CustomRadioInput checked={record === 'recruiting'} label={"Prozess"} onChange={() => setRecord('recruiting')}/>
                <CustomRadioInput checked={record === 'vacancy'} label={"Vakanz"} onChange={() => setRecord('vacancy')}/>
                <CustomRadioInput checked={record === 'company'} label={"Firma"} onChange={() => setRecord('company')}/>
                <CustomRadioInput checked={record === 'candidate'} label={"Kandidat"} onChange={() => setRecord('candidate')}/>
            </PaperRow>
            {record === 'recruiting' &&
                <PaperRow>
                    <OpenRecruitingAutocompleteDropDown
                        value={recruiting}
                        error={Boolean(errors.recruiting)}
                        helperText={errors.recruiting}
                        required
                        onChange={(event, value: IRecruitingPartial) => setRecruiting(value)}
                    />
                </PaperRow>
            }
            {record === 'vacancy' &&
                <PaperRow>
                    <VacancyAutocomplete
                        value={vacancy}
                        error={Boolean(errors.vacancy)}
                        helperText={errors.vacancy}
                        required
                        onChange={(value) => setVacancy(value)}
                    />
                </PaperRow>
            }
            {record === 'company' &&
                <PaperRow>
                    <LegacyCompanyAutocompleteDropDown
                        value={company}
                        error={Boolean(errors.company)}
                        helperText={errors.company}
                        required
                        onChange={(event, value: ICompany) => setCompany(value)}
                    />
                </PaperRow>
            }
            {record === 'candidate' &&
                <PaperRow>
                    <LegacyCandidateAutocompleteDropDown
                        value={candidateId}
                        error={Boolean(errors.candidateId)}
                        helperText={errors.candidateId}
                        required
                        onChange={(event: any, value) => {
                            if (value) {
                                setCandidateId(value.id)
                            } else {
                                setCandidateId(0);
                            }
                        }}
                    />
                </PaperRow>
            }
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: () => onSave(),
                    label: 'Verknüpfung hinzufügen'
                }]}
            />
        </Dialog>
    );
};

export default AssignMailView;
