import React, { PropsWithChildren } from 'react';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { darker } from '../../theme/theme';

interface IProps extends PropsWithChildren<any> {
    buttonConfig?: {
        text: string;
        action: () => void;
    }
    maxWidth?: number;
}

const CustomInfo: React.FC<IProps> = (props: IProps) => {
    return <Alert
        style={{background: '#92AECF', color: darker, maxWidth: props.maxWidth ? props.maxWidth : 'unset'}}
        className={"flex"}
        variant={"filled"}
        severity="info"
        action={props.buttonConfig &&
            <Button color="secondary" size="small" onClick={props.buttonConfig?.action}>
                {props.buttonConfig?.text}
            </Button>
        }
    >
        {props.children}
    </Alert>
}

export default CustomInfo;
