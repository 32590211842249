import { IStore } from '../definitions/redux/store.definitions';
import { IMail, IMailGroup } from '../definitions/entities.definition';
import _ from 'underscore';

export const getMailsFromStore = (store: IStore) => {
    const newsById = store.entities.mail.byId;
    const order = store.entities.mail.order;
    const mails: IMail[] = [];

    order.forEach((mailId) => {
        const mail = newsById[mailId];
        mails.push(mail);
    });

    return mails;
};

export const getMailsTotalCountFromStore = (store: IStore) => {
    return store.entities.mail.total;
}

export const groupMailsByDate = (mails: IMail[]): IMailGroup => {
    return _.groupBy(mails, function (mail) {
        return mail.dateTime.substr(0, 10);
    });
}
