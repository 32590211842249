import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkIfViewIsAlreadyPinned, getEditViewEntity } from '../../../selectors/ui.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { IconButton, Tooltip } from '@material-ui/core';
import {
    setUiCloseCurrentEditView,
    setUiCloseView,
    setUiPinView,
    setUiSearchCandidateView,
    setUiSearchVacancyView,
} from '../../../redux/ui/ui.actions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { EEntityView, IEditViewState } from '../../../definitions/ui.definitions';
import { registerInfo } from '../../../redux/error/error.actions';
import { getEditViewUrl, getEntityViewTitle } from '../../../utils/ui.utils';
import { themeGreen, themeOrange, themeRed } from '../../../theme/theme';
import { setWebVacancyPageIdToImportAction } from '../../../redux/webVacancyPage/webVacancyPage.actions';
import { IVacancy } from '../../../definitions/entities.definition';
import VacancyStatisticView from '../../RecruitingPage/components/components/VacancyStatisticView';

interface IProps {
    editView: IEditViewState;
}

const EntityViewActionsBar: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const editView = props.editView;
    const view = editView.view;
    const entityId = editView.entityId;
    const [statisticViewOpen, setStatisticViewOpen] = useState(false);

    const editViewEntity = useSelector((store: IStore) => getEditViewEntity(store, view, entityId));
    const isAlreadyPinned = useSelector((store: IStore) => checkIfViewIsAlreadyPinned(store, {
        view,
        entityId
    }));

    if (!editViewEntity) {
        return null;
    }

    const title = getEntityViewTitle(view, editViewEntity);

    const getEntityActionElements = () => {
        if (view === EEntityView.candidate) {
            return <>
                <Tooltip title={"Internen Suchlauf starten - Stellen finden"}>
                    <IconButton onClick={() => dispatch(setUiSearchVacancyView(editViewEntity.id))}>
                        {IconFactory.getIconComponent(EIcons.Work, {color: themeGreen.palette.primary.main})}
                    </IconButton>
                </Tooltip>
            </>
        }

        if (view === EEntityView.vacancy) {
            const entityCast = editViewEntity as IVacancy;
            return <>
                <VacancyStatisticView open={statisticViewOpen} vacancy={entityCast} onClose={() => setStatisticViewOpen(false)}/>
                <Tooltip title={"Internen Suchlauf starten - Kandidaten finden"}>
                    <IconButton onClick={() => dispatch(setUiSearchCandidateView(editViewEntity.id))}>
                        {IconFactory.getIconComponent(EIcons.People, {color: themeOrange.palette.primary.main})}
                    </IconButton>
                </Tooltip>
                <Tooltip  title={"Auswertung öffnen"}>
                    <IconButton onClick={() => setStatisticViewOpen(true)}>
                        {IconFactory.getIconComponent(EIcons.BarChart, {color: themeOrange.palette.primary.main})}
                    </IconButton>
                </Tooltip>
            </>
        }

        if (view === EEntityView.webVacancy) {
            return <>
                <Tooltip title={"Internen Suchlauf starten - Kandidaten finden"}>
                    <IconButton onClick={() => {
                        dispatch(setUiSearchCandidateView(undefined, editViewEntity.id));
                    }}>
                        {IconFactory.getIconComponent(EIcons.People, {color: themeRed.palette.primary.main})}
                    </IconButton>
                </Tooltip>
                <Tooltip  title={"Stelle importieren"}>
                    <IconButton onClick={() => {
                        dispatch(setWebVacancyPageIdToImportAction(editViewEntity.id));
                        dispatch(setUiCloseCurrentEditView());
                    }}>
                        {IconFactory.getIconComponent(EIcons.SaveAlt, {color: themeRed.palette.primary.main})}
                    </IconButton>
                </Tooltip>
            </>
        }

        return null;
    }

    return <>
        {getEntityActionElements()}
        <Tooltip title={"Link zum teilen kopieren"}>
            <IconButton onClick={() => {
                navigator.clipboard.writeText(getEditViewUrl(editView, title));
                dispatch(registerInfo('Url wurde kopiert'))
            }}>
                {IconFactory.getIconComponent(EIcons.Share)}
            </IconButton>
        </Tooltip>
        { !isAlreadyPinned &&
            <Tooltip title={"Fenster pinnen"}>
                <IconButton onClick={() => dispatch(setUiPinView(view, entityId, title as string))}>
                    {IconFactory.getIconComponent(EIcons.Bookmark)}
                </IconButton>
            </Tooltip>
        }
        <Tooltip title={"Datensatz schliessen"}>
            <IconButton onClick={() => dispatch(setUiCloseView(view, entityId))}>
                {IconFactory.getIconComponent(EIcons.Close)}
            </IconButton>
        </Tooltip>
    </>;
}

export default EntityViewActionsBar
