import React, { useEffect } from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDashboardWidgetPaper from '../../../components/CustomPaper/CustomDashboardWidgetPaper';
import { EIcons } from '../../../components/Icons/IconFactory';
import { COLOR_ORANGE } from '../../../theme/theme';
import GoalBurnUpChartFilterButtons from './GoalBurnUpChartFilterButtons';
import GoalBurnUpChart from './GoalBurnUpChart';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getBurnupChartData } from '../../../utils/dashboard.utils';
import {
    EGroupEvaluationGroupBy,
    getRecruitingGroupedEvaluationAction,
    getRecruitingGroupedEvaluationForLastYearAction,
    getTargetByMonthListAction,
} from '../../../redux/entities/entities.actions';
import { getLoggedInEmployeeFromStore, isOnlyShowMineModeActive } from '../../../selectors/employee.selectors';

const GoalBurnUpChartView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const chartSettings = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const year = useSelector((state: IStore) => state.dashboardPage.chartYear);
    const chartData = getBurnupChartData();
    const onlyShowMine = useSelector(isOnlyShowMineModeActive);

    useEffect(() => {
        if (chartSettings) {
            dispatch(getRecruitingGroupedEvaluationAction(
                token,
                year,
                EGroupEvaluationGroupBy.GROUP_BY_MONTH,
                onlyShowMine ? loggedInEmployee.id : undefined
            ));
            dispatch(getRecruitingGroupedEvaluationAction(
                token,
                year,
                EGroupEvaluationGroupBy.GROUP_BY_MONTH_SUMMED_UP,
                onlyShowMine ? loggedInEmployee.id : undefined
            ));
            dispatch(getRecruitingGroupedEvaluationForLastYearAction(
                token,
                year - 1,
                EGroupEvaluationGroupBy.GROUP_BY_MONTH,
                onlyShowMine ? loggedInEmployee.id : undefined
            ));
            dispatch(getRecruitingGroupedEvaluationForLastYearAction(
                token,
                year -1,
                EGroupEvaluationGroupBy.GROUP_BY_MONTH_SUMMED_UP,
                onlyShowMine ? loggedInEmployee.id : undefined
            ));
            dispatch(getTargetByMonthListAction(
                token,
                year,
                onlyShowMine ? loggedInEmployee.id : undefined
            ));
        }
    }, [dispatch, chartSettings.employeeId, onlyShowMine]);

    return (
        <>
            <CustomDashboardWidgetPaper
                icon={EIcons.Flag}
                headline={translate('pages.dashboard.burnUpGoalChart') + ' (' + (onlyShowMine ? 'Persönliches Ziel' : 'Team-Ziel') + ')'}
                iconBackgroundColor={COLOR_ORANGE}
                headlineElement={<GoalBurnUpChartFilterButtons />}
                style={{minWidth: 950}}
            >
                <div style={{flex: 1}}>
                    <div style={{display: chartSettings.byCommission ? 'block' : 'none'}}>
                        <GoalBurnUpChart chartData={chartData} index={'Commission'}/>
                    </div>
                    <div style={{display: chartSettings.byCommission ? 'none' : 'block'}}>
                        <GoalBurnUpChart chartData={chartData} index={'Contracts'}/>
                    </div>
                </div>
            </CustomDashboardWidgetPaper>
        </>
    );
}

export default GoalBurnUpChartView;
