import React, { useEffect, useState } from 'react';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import styles from './CustomDateRange.module.css';
import CustomDialogActions from '../../CustomInput/CustomDialogActions';
import { IDateRangeFilterConfig } from '../../../definitions/filter.definitions';
import { objectsAreEqual } from '../../../utils/application.utils';
import CustomDateRangeForm from './CustomDateRangeForm';

interface IProps {
    anchorEl: null | HTMLElement;
    removeAnchor: () => void;
    config: IDateRangeFilterConfig;
}

const CustomDateRangePopper: React.FC<IProps> = (props) => {
    const config = props.config;
    const [filter, setFilter] = useState(config.filter);

    const resetEnabled = config.resetEnabled;
    const applyEnabled = !objectsAreEqual(filter, props.config.filter);

    const anchorEl = props.anchorEl;
    const handleClose = () => {
        props.removeAnchor();
    };

    useEffect(() => {
        setFilter(config.filter);
    }, [config.filter])

    const submit = () => {
        config.setFilter(filter);
        handleClose();
    }

    const reset = () => {
        config.reset();
        handleClose();
    }

    if (!anchorEl) {
        return null;
    }

    return <>
        <Popper open={true} anchorEl={anchorEl} placement={'right'} >
            <ClickAwayListener onClickAway={handleClose} mouseEvent={"onMouseUp"}>
                <Paper square variant={"elevation"} className={styles.popper}>
                    <div className={"padding5"}>
                        <CustomDateRangeForm
                            filter={filter}
                            setFilter={setFilter}
                            filterByFields={config.filterByFields}
                        />
                    </div>
                    <CustomDialogActions
                        onClose={handleClose}
                        buttons={[{
                            void: reset,
                            disabled: !resetEnabled,
                            label: "Zurücksetzen"
                        }, {
                            void: submit,
                            disabled: !applyEnabled,
                            label: "Anwenden"
                        }]}
                    />
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>;
};

export default React.memo(CustomDateRangePopper);
