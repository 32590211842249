import { MenuItem } from '@material-ui/core';
import React from 'react';
import { EDateRangeMode, IDateRangeFilterFieldConfig } from '../../../definitions/filter.definitions';
import CustomDropDownInput from '../../CustomInput/CustomDropDownInput/CustomDropDownInput';

interface IProps {
    onChange: (value: string) => void;
    value?: string;
    values: IDateRangeFilterFieldConfig[]
}

const DateRangeFieldDropDown: React.FC<IProps> = (props) => {
    const values = props.values;
    const menuItems = values.map((field) => (
        <MenuItem key={field.property} value={field.property}>
            {field.label}
        </MenuItem>
    ));

    return (
        <CustomDropDownInput
            onChange={(event) => {
                props.onChange(event.target.value);
            }}
            value={props.value || EDateRangeMode.all}
            id={"date-range-field-dropdown"}
            label={"zu berücksichtigende Information"}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default React.memo(DateRangeFieldDropDown);
