import React, { useState } from 'react';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import { translate } from '../../../../../translation/translate.utils';
import { DropzoneDialogBase, FileObject } from 'material-ui-dropzone';
import IconButton from '@material-ui/core/IconButton';
import PaperRow from '../../../../../components/CustomPaper/PaperRow';
import CandidateDocumentTypeDropDown
    from '../../../../../components/CustomInput/CustomDropDownInput/CandidateDocumentTypeDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { addCandidateDocumentAction } from '../../../../../redux/entities/entities.actions';
import {
    ECandidateDocumentType,
    ICandidateDocument,
    ICandidateDocumentForApi,
} from '../../../../../definitions/entities.definition';
import { getToken } from '../../../../../selectors/app.selectors';

interface IProps {
    open: boolean;
    onClose: () => void;
    successCallback: (response: ICandidateDocument[]) => void;
    candidateId: number;
}

const CandidateDocumentUploadView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const [fileObjects, setFileObjects] = React.useState([]);

    const [type, setType] = useState(ECandidateDocumentType.CV);

    const onSave = () => {
        const documents: ICandidateDocumentForApi[] = [];

        fileObjects.forEach((fileObject: FileObject) => {
            documents.push({
                fileContent: fileObject.data,
                type: type,
                displayFileName: fileObject.file.name,
                candidateId,
            });
        });

        dispatch(addCandidateDocumentAction(token, documents, props.successCallback));
        setFileObjects([]);
        return;
    };

    const dialogTitle = () => (
        <>
            <span>Upload file</span>
            <IconButton style={{ right: "12px", top: "8px", position: "absolute" }} onClick={() => props.onClose()}>
                {IconFactory.getIconComponent(EIcons.Close)}
            </IconButton>
            <div>
                <PaperRow>
                    <CandidateDocumentTypeDropDown value={type} onChange={(event) => setType(event.target.value)} />
                </PaperRow>
            </div>
        </>
    );

    return (
        <>
            <DropzoneDialogBase
                fullWidth={true}
                maxWidth={"lg"}
                dialogTitle={dialogTitle()}
                acceptedFiles={["image/*", "application/pdf", "application/word", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
                fileObjects={fileObjects}
                cancelButtonText={translate('misc.buttons.close')}
                submitButtonText={translate('misc.buttons.save')}
                previewChipProps={{ color: "primary" }}
                maxFileSize={25000000}
                filesLimit={10}
                open={props.open}
                onAdd={(newFileObjs) => {
                    // @ts-ignore-next-line
                    setFileObjects([].concat(fileObjects, newFileObjs));
                }}
                onDelete={(deleteFileObj) => {
                    setFileObjects(fileObjects.filter((fileObject: FileObject) => fileObject.file !== deleteFileObj.file));
                }}
                onClose={() => props.onClose()}
                onSave={() => onSave()}
                useChipsForPreview={true}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
        </>
    );
};

export default CandidateDocumentUploadView;
