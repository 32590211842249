import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageYourRecruitingsViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { getRecruitingsForEmployeeAction } from '../../../redux/entities/entities.actions';
import EmployeeTodayRecruitingsTable from './tables/EmployeeRecruitingsTable';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { buildRequestObject } from '../../../selectors/entity.selectors';

const EmployeeRecruitingsView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const employee = useSelector(getLoggedInEmployeeFromStore);
    const open = useSelector((store: IStore) => store.dashboardPage.yourRecruitingsViewOpen);
    const handleClose = () => dispatch(setDashboardPageYourRecruitingsViewOpenAction(false));
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingEmployeeRecruitings);

    useEffect(() => {
        if (open) {
            dispatch(getRecruitingsForEmployeeAction(token, employee.id, buildRequestObject(sorting)));
        }
    }, [dispatch, open, JSON.stringify(sorting)]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader translationString={"pages.dashboard.yourRecruitings"} onClose={handleClose} />
                <EmployeeTodayRecruitingsTable />
            </CustomDialog>
        </>
    );
};

export default EmployeeRecruitingsView;
