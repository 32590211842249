import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageBirthdayCandidatesViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { getCandidatesWithBirthdayAction } from '../../../redux/entities/entities.actions';
import BirthdayCandidatesTable from './tables/BirthdayCandidatesTable';
import { buildRequestObject } from '../../../selectors/entity.selectors';
import CreateBirthdayCongratulationMailView from '../../CandidatePage/components/CreateBirthdayCongratulationMailView';

const BirthdayCandidatesView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector((store: IStore) => store.actor.token);
    const open = useSelector((store: IStore) => store.dashboardPage.candidateBirthdayViewOpen);
    const handleClose = () => dispatch(setDashboardPageBirthdayCandidatesViewOpenAction(false));
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingCandidateBirthdays);

    useEffect(() => {
        if (open) {
            dispatch(getCandidatesWithBirthdayAction(token, buildRequestObject(sorting)));
        }
    }, [dispatch, open, JSON.stringify(sorting)]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog fullWidth onClose={handleClose} open={open}>
                <CustomDialogHeader translationString={"pages.dashboard.candidatesWithBirthday"} onClose={handleClose} />
                <CreateBirthdayCongratulationMailView
                    reload={() => dispatch(getCandidatesWithBirthdayAction(token, buildRequestObject(sorting)))}
                />
                <BirthdayCandidatesTable />
            </CustomDialog>
        </>
    );
};

export default BirthdayCandidatesView;
