import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { ESuggestionStatus, IEmployee } from '../../../../definitions/entities.definition';
import {
    setVacancyPageSuggestionListViewFilterAction,
    setVacancyPageSuggestionListViewFilterMenuAction,
    setVacancyPageSuggestionListViewResetFilterAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import SuggestionStatusDropDown from '../../../CustomInput/CustomDropDownInput/SuggestionStatusDropDown';
import CandidateSourceDropDown from '../../../CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import SuggestionDeclineReasonDropDown from '../../../CustomInput/CustomDropDownInput/SuggestionDeclineReasonDropDown';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuSuggestion: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.vacancyPage.suggestionListViewFilter);
    const filterMenu = useSelector((store: IStore) => store.vacancyPage.suggestionListViewFilterMenu);

    const submit = () => {
        dispatch(
            setVacancyPageSuggestionListViewFilterAction({
                start: filter.start,
                limit: filter.limit,
                declineReason: filterMenu.declineReason,
                source: filterMenu.source,
                status: filterMenu.status,
                responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
                dateRange: filterMenu.dateRange
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(setVacancyPageSuggestionListViewResetFilterAction());
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.responsibleEmployee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setVacancyPageSuggestionListViewFilterMenuAction({
                                responsibleEmployee: value
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <SuggestionStatusDropDown
                    value={filterMenu.status}
                    onChange={(event) =>
                        dispatch(
                            setVacancyPageSuggestionListViewFilterMenuAction({
                                status: event.target.value
                            })
                        )
                    }
                />
            </MenuItem>
            {filterMenu.status === ESuggestionStatus.DECLINED &&
                <MenuItem>
                    <SuggestionDeclineReasonDropDown
                        label={"Grund der Absage"}
                        value={filterMenu.declineReason}
                        onChange={(declineReason) =>
                            dispatch(
                                setVacancyPageSuggestionListViewFilterMenuAction({
                                    declineReason
                                })
                            )
                        }
                    />
                </MenuItem>
            }
            <MenuItem>
                <CandidateSourceDropDown
                    value={filterMenu.source}
                    onChange={(event) =>
                        dispatch(
                            setVacancyPageSuggestionListViewFilterMenuAction({
                                source: event.target.value
                            })
                        )
                    }
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuSuggestion;
