import React, { memo } from 'react';
import { IActivity } from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';

interface IProps {
    activity: IActivity;
}

const ActivityVacancyCellRenderer: React.FC<IProps> = (props) => {
    if (props.activity.vacancy) {
        return <VacancyCellRenderer vacancy={props.activity.vacancy} />;
    }
    if (props.activity.recruiting !== null) {
        return (
            <>
                {translate('pages.candidate.initiativeApplication')}
            </>
        );
    }

    return (<>
        {translate('misc.noInformation')}
    </>);

};

export default memo(ActivityVacancyCellRenderer);
