import moment, { Moment } from 'moment';

export enum ECalendarView {
    WEEK,
    MONTH,
    WEEK_WITH_WEEKEND
}

export interface ICalendarFilter {
    employeeIds?: number[];
}

export interface ICalendarTodoFilter {
    employeeId: number;
    fastSearch?: string;
}

export const preloadedCalendarState: ICalendarState = {
    calendarNavigationOpen: false,
    openTodoViewOpen: false,
    view: ECalendarView.WEEK,
    appointment: undefined,
    scrollEnabled: false,
    currentDate: moment().format('YYYY-MM-DD'),
    calendarFilter: {},
    todoFilter: {
        employeeId: 0
    }
};

export interface ICalendarState {
    calendarNavigationOpen: boolean;
    openTodoViewOpen: boolean;
    view: ECalendarView;
    appointment?: Moment;
    scrollEnabled: boolean;
    currentDate: string;
    calendarFilter: ICalendarFilter;
    todoFilter: ICalendarTodoFilter;
}
