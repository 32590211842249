import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setAdministrationPageAddDialogOpenAction,
    setAdministrationPageFastSearchValueAction,
} from '../../../../redux/administrationPage/administrationPage.actions';

const FastSearchPanelAdministrationPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchValue = useSelector((store: IStore) => store.administrationPage.employeeFilter.fastSearch);

    const configuration: IAppBarConfig = {
        entity: EEntities.Employee,
        value: fastSearchValue ?? '',
        emptyValue: translate('pages.administration.fastSearchEmployee'),
        fastSearchCallback: (value: string) => dispatch(setAdministrationPageFastSearchValueAction(value)),
        actionButtons: [{
            action: () => dispatch(setAdministrationPageAddDialogOpenAction(true)),
            icon: EIcons.AddBox,
            tooltip: translate('misc.add')
        }],
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelAdministrationPage;
