import React from 'react';
import { IEmployeePartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import CustomAutocomplete from '../CustomAutocomplete';
import { getEmployeeListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';

interface IProps {
    value: IEmployeePartial|number|null;
    onChange: (value: IEmployeePartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    size?: 'small'|'medium';
}

const EmployeeAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as IEmployeePartial);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as IEmployeePartial).fullName;
    }

    return <CustomAutocomplete
        id={"employee-autocomplete"}
        label={props.label || translate('misc.employee')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        size={props.size}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getEmployeeListRouteConfig}
        getOptionLabel={getOptionLabel}
        routeParams={{
            orderBy: 'firstName',
            orderDirection: 'DESC',
            start: 0,
            limit: 0,
            hidden: false,
            active: true
        }}
    />;
}

export default React.memo(EmployeeAutocomplete);
