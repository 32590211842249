import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { EArchivePageTabs } from '../../../../definitions/archivePage.definitions';
import FastSearchPanelArchivVacancyList from './components/FastSearchPanelArchivVacancyList';
import FastSearchPanelArchivRecruitingsFinishList from './components/FastSearchPanelArchivRecruitingsFinishList';
import FastSearchPanelArchivRecruitingsTrialList from './components/FastSearchPanelArchivRecruitingsTrialList';
import FastSearchPanelArchivRecruitingsAbortList from './components/FastSearchPanelArchivRecruitingsAbortList';

const FastSearchPanelActivityPage: React.FC = () => {
    const archiveTab = useSelector((store: IStore) => store.archivePage.activeTab);

    if (archiveTab === EArchivePageTabs.tabVacancies) {
        return <FastSearchPanelArchivVacancyList/>
    }

    if (archiveTab === EArchivePageTabs.tabRecruitingsAbort) {
        return <FastSearchPanelArchivRecruitingsAbortList/>
    }

    if (archiveTab === EArchivePageTabs.tabRecruitingsFinished) {
        return <FastSearchPanelArchivRecruitingsFinishList/>
    }

    if (archiveTab === EArchivePageTabs.tabRecruitingsTrialPeriodFailed) {
        return <FastSearchPanelArchivRecruitingsTrialList/>
    }

    return null;
}

export default FastSearchPanelActivityPage;
