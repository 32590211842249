import React from 'react';
import { IChat } from '../../../definitions/entities.definition';
import ChatNode from './ChatNode';
import _ from 'underscore';
import { getFormattedDateWithDayString } from '../../../utils/date.utils';
import styles from './Chat.module.css';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import CustomDetectScrolledToEndDiv from '../../../components/CustomDetectScrolledToEnd/CustomDetectScrolledToEndDiv';

interface IProps {
    chat: IChat;
    total: number;
    count?: number;
    hideSenderReceiverForInterview?: boolean;
    onReload: () => void;
    loading?: boolean;
    scrollCallback?: () => void;
}

const ChatPanel: React.FC<IProps> = (props) => {
    const hideSenderReceiverForInterview = props.hideSenderReceiverForInterview || false;
    const chatNodes = props.chat.nodes;
    const chatNodesGroupedByDate = _.groupBy(chatNodes, function (node) {
        return node.date;
    });
    const count = props.count;
    const total = props.total;
    const loading = props.loading || false;

    return <>
        <div className={"flexContainerColumn padding5 overflow flex1"}>
            <CustomDetectScrolledToEndDiv
                className={"overflow flex1"}
                id={"activityOverviewChat"}
                loading={loading}
                scrollCallback={props.scrollCallback} style={{
                    borderLeft: '1px solid var(--brainz-darkest)',
                    borderRight: '1px solid var(--brainz-darkest)',
                    borderTop: '1px solid var(--brainz-darkest)',
                }}
            >
                <div className={"flexContainerColumn overflow padding5 flex1"}>
                    {Object.keys(chatNodesGroupedByDate).map((date) => {
                        const chatNodes = chatNodesGroupedByDate[date];
                        return <>
                            <span className={styles.dateHeading}>{getFormattedDateWithDayString(date)}</span>
                            {chatNodes.map((node) => <ChatNode
                                hideSenderReceiverForInterview={hideSenderReceiverForInterview}
                                key={node.id}
                                node={node}
                            />)}
                        </>
                    })}
                </div>
            </CustomDetectScrolledToEndDiv>
            <CustomTableFooter
                totalCount={total}
                count={count}
                onReload={props.onReload}
            />
        </div>
    </>
}

export default ChatPanel;
