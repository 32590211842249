import { useEffect, useState } from 'react';
import { getWebVacancyListOfCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getWebVacancyListOfCompanyFromStore } from '../../../../../selectors/webVacancy.selectors';

export const useCompanyWebVacancyListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.webVacanciesLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const sorting = useSelector((store: IStore) => store.companyPage.companyLocationSorting);
    const webVacancies = useSelector((store: IStore) => getWebVacancyListOfCompanyFromStore(store, companyId));

    useEffect(() => {
        dispatch(getWebVacancyListOfCompanyAction(token, companyId));
        setInitialLoading(false);
    }, [companyId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getWebVacancyListOfCompanyAction(token, companyId));
        }
    }, [JSON.stringify(sorting)]);

    return {loading, webVacancies, total: webVacancies.length};
}
