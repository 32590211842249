import React from 'react';
import { ICandidatePartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getCandidatePartialListRouteConfig, getCandidatePartialRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomLivesearch from '../CustomLivesearch';

interface IProps {
    value: ICandidatePartial|number|null;
    onChange: (value: ICandidatePartial|null) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    size?: 'small'|'medium';
}

const CandidateAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: TBrainzEntity|null) => {
        props.onChange(value as ICandidatePartial);
    }

    const getOptionLabel = (record: TBrainzEntity) => {
        return (record as ICandidatePartial).fullName;
    }

    return <CustomLivesearch
        id={"candidate-autocomplete"}
        label={props.label || translate('misc.candidate')}
        value={value}
        size={props.size}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCandidatePartialListRouteConfig}
        entityRouteConfig={getCandidatePartialRouteConfig}
        entityRouteParam={"candidateId"}
        getOptionLabel={getOptionLabel}
        routeParams={{
            orderBy: 'firstName',
            orderDirection: 'DESC',
            start: 0,
            limit: 50
        }}
    />;
}

export default React.memo(CandidateAutocomplete);
