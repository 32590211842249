import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../selectors/app.selectors';
import { deleteInvoiceAction } from '../../redux/entities/entities.actions';
import { setBillingPageInvoiceToDeleteAction } from '../../redux/billingPage/billingPage.actions';

const InvoiceDeleteView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.billingPage.invoiceToDelete));
    const idToDelete = useSelector((store: IStore) => store.billingPage.invoiceToDelete);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={() => dispatch(setBillingPageInvoiceToDeleteAction(0))}
            onDelete={() => {
                dispatch(deleteInvoiceAction(token, idToDelete));
                dispatch(setBillingPageInvoiceToDeleteAction(0));
            }}
            titleTranslation={"pages.billing.deleteDialog.title"}
            bodyTranslation={"pages.billing.deleteDialog.body"}
        />
    );
};

export default InvoiceDeleteView;
