import React, { ReactNode } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IProps {
    list: ReactNode;
    preview: ReactNode;
    previewOpen: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
            display: "flex",
        },
        previewContainer: {
            flex: 1,
        },
    })
);

const ListAndPreview: React.FC<IProps> = (props) => {
    const styles = useStyles();

    return (
        <>
            {
                props.previewOpen
                    ? <div className={styles.root}>
                        <div className={styles.previewContainer}>{props.preview}</div>
                    </div>
                    : <>{props.list}</>
            }
        </>
    );
};

export default ListAndPreview;
