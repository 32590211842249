import React from 'react';
import { EFilterPanelType, TFilterPanelType } from '../../../definitions/filter.definitions';
import CustomOpenFilterPanelButton from '../../CustomFilter/CustomSideFilter/CustomOpenFilterPanelButton';
import { EEntities } from '../../../definitions/entities.definition';
import CustomFilterMenu from '../../CustomFilter/CustomPopupFilterMenu/CustomFilterMenu';

interface IProps {
    entity: EEntities;
    filterType: TFilterPanelType;
}

const CustomFastSearchPanelFilter: React.FC<IProps> = (props) => {
    return <>
        {props.filterType === EFilterPanelType.sidePanel &&
            <CustomOpenFilterPanelButton entity={props.entity} />
        }
        {props.filterType === EFilterPanelType.popupMenu &&
            <CustomFilterMenu entity={props.entity} />
        }
    </>
}

export default React.memo(CustomFastSearchPanelFilter);
