import React, { useEffect, useState } from 'react';
import CustomPaper from '../CustomPaper/CustomPaper';
import PaperRow from '../CustomPaper/PaperRow';
import CustomButton from '../CustomInput/CustomButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CustomTypography from '../CustomTypography/CustomTypography';
import { useDispatch, useSelector } from 'react-redux';
import { actorLogin, actorTokenLoginAction } from '../../redux/actor/actor.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomBackdropLoading from '../CustomBackdrop/CustomBackdropLoading';
import jwtDecode from 'jwt-decode';
import { IJwtData } from '../../definitions/app.definitions';
import moment from 'moment';
import { translate } from '../../translation/translate.utils';
import CustomTextField from '../CustomInput/CustomTextField';
import { addOnEnterEventHandler } from '../../utils/components.utils';
import LogoutExpireView from './LogoutExpireView';

interface IProps {
    tokenFromStorage: string | null;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            background:
                "linear-gradient(24deg, rgba(233,196,106,1) 0%, rgba(244,162,97,1) 13%, rgba(231,111,81,1) 32%, rgba(42,157,143,1) 61%, rgba(38,70,83,1) 84%)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        loginButton: {
            width: '100%',
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        },
    })
);

const Login: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const loginLoading = useSelector((store: IStore) => store.ui.loginLoading);

    addOnEnterEventHandler(() => dispatch(actorLogin(login, password)));

    useEffect(() => {
        if (props.tokenFromStorage) {
            const tokenData = jwtDecode(props.tokenFromStorage) as IJwtData;
            const expire = moment(tokenData.expire).unix();
            const now = moment().unix();
            const tokenValid = now < expire;

            if (tokenValid) {
                dispatch(actorTokenLoginAction(props.tokenFromStorage));
                return;
            }
        }
    }, [dispatch, props.tokenFromStorage]);

    return (
        <div className={styles.root}>
            <CustomBackdropLoading open={loginLoading} />
            <CustomPaper>
                <CustomTypography style={{ textAlign: "center" }} variant={"h4"} translation={"login.headline"} />
                <PaperRow>
                    <CustomTextField
                        label={'Login'}
                        value={login}
                        onChange={(value) => setLogin(value)}
                        autoFocus
                    />
                </PaperRow>
                <PaperRow>
                    <CustomTextField
                        label={translate('login.password')}
                        type={"password"}
                        value={password}
                        onChange={(value) => setPassword(value)}
                    />
                </PaperRow>
                <PaperRow>
                    <div style={{width: '100%'}}>
                        <CustomButton
                            onClick={() => {
                                dispatch(actorLogin(login, password));
                            }}
                            label={translate("login.submitButton")}
                        />
                    </div>
                </PaperRow>
            </CustomPaper>
            <LogoutExpireView />
        </div>
    );
};

export default Login;
