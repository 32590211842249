import React, { useState } from 'react';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import { getApplicationListForCompanyAction } from '../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getCompanyPageRequestObjectForApplicationsInEditView } from '../../../../selectors/application.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import FastSearchPanelApplicationList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelApplicationList';
import AddApplicationView from '../../../ApplicationPage/AddApplicationView';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiExpandCollapseApplicationListItem } from '../../../../redux/ui/ui.actions';
import CompanyApplicationList from './components/CompanyApplicationList';

interface IProps {
    companyId: number;
}

const CompanyApplicationPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const companyId = props.companyId;
    const requestObject = useSelector(getCompanyPageRequestObjectForApplicationsInEditView);
    const total = useSelector((store: IStore) => store.entities.applications.totalByCompany[companyId]);
    const [addApplicationViewOpen, setAddApplicationViewOpen] = useState(false);
    const collapseOrExpandAll = useSelector((store: IStore) => store.ui.expandOrCollapseApplicationListItems);

    return <>
        <AddApplicationView
            companyId={companyId}
            onClose={() => setAddApplicationViewOpen(false)}
            open={addApplicationViewOpen}
        />
        <div className={"flexColumn padding5"}>
            <FastSearchPanelApplicationList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                actionButtons={[{
                    action: () => setAddApplicationViewOpen(true),
                    tooltip: "Neue Bewerbung anlegen",
                    icon: EIcons.Add
                }, {
                    action: () => dispatch(setUiExpandCollapseApplicationListItem(collapseOrExpandAll === 'expand' ? 'collapse' : 'expand')),
                    tooltip: collapseOrExpandAll === 'expand' ? 'Alle zuklappen' : 'Alle aufklappen',
                    icon: collapseOrExpandAll === 'expand' ? EIcons.FullscreenExit : EIcons.Fullscreen
                }]}
            />
            <CompanyApplicationList companyId={companyId}/>
            <CustomTableFooter
                style={{height: 35}}
                text={'Bewerbungen: ' + total}
                onReload={() => {
                    dispatch(getApplicationListForCompanyAction(token, companyId, requestObject));
                }}
            />
        </div>
    </>;
}

export default React.memo(CompanyApplicationPanel);
