import { IStore } from '../definitions/redux/store.definitions';
import { EEntityView, IEditViewState } from '../definitions/ui.definitions';
import { checkViewIsEqual } from '../components/CustomInput/CustomDatePicker';
import { TBrainzEntity } from '../definitions/entities.definition';

export const getCurrentEditViewFromStore = (store: IStore): null|IEditViewState => {
    const views = store.ui.openViews;

    if (views.length === 0) {
        return null;
    }

    return views[0];
};

export const getOpenModals = (store: IStore) => {
    const modalStates = {
        recruitingAlertChangeResponsibleModal: Boolean(store.recruitingPage.alertChangeRecruitingResponsible?.alert),
        congratulationModal: Boolean(store.ui.openCongratulation),
        encouragementModal: Boolean(store.ui.openEncouragement),
        startRecruitingModal: store.recruitingPage.startRecruitingView.open,
        addSuggestionForCandidateModal: store.recruitingPage.addSuggestionView.open,
        addSuggestionForVacancyModal: store.vacancyPage.addSuggestionView,
        activityDeleteModal: Boolean(store.ui.deleteActivityId),
        activityAddEditModal: store.ui.addActivityData.add || Boolean(store.ui.editActivityId),
        skillDeleteModal: Boolean(store.skillPage.skillToDelete),
        companyContactAddModal: Boolean(store.ui.addCompanyContactData.companyId),
        companyContactEditModal: Boolean(store.ui.editCompanyContactId),
        companyContactDeleteModal: Boolean(store.companyPage.companyContactToDelete),
        companyLocationAddEditModal: Boolean(store.ui.addCompanyLocationData.companyId) || Boolean(store.ui.editCompanyLocationId),
        vacancyAddModal: store.vacancyPage.addVacancyDialogOpen,
        todoEditModal: Boolean(store.todo.todoToEdit),
        todoAddModal: store.todo.addTodo,
        companyEditModal: Boolean(store.companyPage.companyToEdit),
        todoDeleteModal: Boolean(store.todo.todoToDelete),
        candidateDeleteModal: Boolean(store.candidatePage.candidateToDelete),
        invoiceDeleteModal: Boolean(store.billingPage.invoiceToDelete),
        invoiceAddEditModal: store.billingPage.addInvoice || Boolean(store.billingPage.invoiceToEdit),
        recruitingDeleteModal: Boolean(store.recruitingPage.recruitingToDelete),
        vacancyDeleteModal: Boolean(store.vacancyPage.vacancyToDelete),
        vacancyArchiveModal: Boolean(store.vacancyPage.vacancyToArchive),
        changePasswordModal: store.ui.renewPassword,
        skillAddEditModal: Boolean(store.ui.entityToCreate) || Boolean(store.skillPage.skillToEdit),
        externalSkillAddEditModal: Boolean(store.skillPage.externalSkillToEdit),
        suggestionDeleteModal: Boolean(store.vacancyPage.suggestionToDelete),
        suggestionDeclineModal: Boolean(store.vacancyPage.suggestionToDecline.id),
        setJobTemplateModal: Boolean(store.jobTemplatePage.setJobTemplateForJobTitle),
        assignCandidateToSuggestionModal: Boolean(store.recruitingPage.assignCandidateToSuggestionData.recruitingSuggestionId),
        declineApplicationModal: Boolean(store.applicationPage.applicationToDecline),
        editApplicationModal: Boolean(store.applicationPage.applicationToEdit),
        activityListModal: store.ui.activityListViewOpen,
        suggestionAddActionModal: Boolean(store.vacancyPage.suggestionToAddAction),
        vacancySearchCandidate: Boolean(store.ui.searchForVacancyId > 0),
        webVacancySearchCandidate: Boolean(store.ui.searchForWebVacancyId > 0),
        candidateSearchVacancy: Boolean(store.ui.searchForCandidateId > 0)
    };

    const openModals: string[] = [];
    Object.entries(modalStates).map((entry) => {
        const modal = entry[0];
        const open = entry[1];

        if (open) {
            openModals.push(modal);
        }
    });

    return openModals;
}

export const checkIfAnyModalIsOpen = (store: IStore) => {
    const openModals = getOpenModals(store);
    return openModals.length > 0;
}

export const checkEditViewIsOpen = (store: IStore): boolean => {
    const views = store.ui.openViews;

    return views.length !== 0;
};

export const checkIfViewIsAlreadyPinned = (store: IStore, view: Partial<IEditViewState>): boolean => {
    const pinnedViews = store.ui.pinnedViews;

    const equals = pinnedViews.filter((pinnedView) => {
        return checkViewIsEqual(view, pinnedView);
    });

    return equals.length > 0;
}

export const getEditViewEntity = (store: IStore, view: EEntityView, entityId: number): null|TBrainzEntity => {
    switch (view) {
        case EEntityView.vacancy:
            return store.entities.vacancies.byId[entityId];
        case EEntityView.suggestion:
            return store.entities.recruitingSuggestion.byId[entityId];
        case EEntityView.company:
            return store.entities.companies.byId[entityId];
        case EEntityView.webVacancy:
            return store.entities.webVacancy.byId[entityId];
        case EEntityView.candidate:
            return store.entities.candidates.byId[entityId];
        case EEntityView.recruiting:
            return store.entities.recruiting.byId[entityId];
    }

    return null;
}

export const getCurrentEditViewEntity = (store: IStore): null|TBrainzEntity => {
    const views = store.ui.openViews;

    if (views.length === 0) {
        return null;
    }

    const currentView = views[0];

    return getEditViewEntity(store, currentView.view, currentView.entityId);
}

export const getCurrentEditViewEnum = (store: IStore): null|EEntityView => {
    const views = store.ui.openViews;

    if (views.length === 0) {
        return null;
    }

    return views[0].view;
}

