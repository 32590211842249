import React from 'react';
import { EEntities, IApplication, ICandidate, IVacancy } from '../../definitions/entities.definition';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getApplicationListAction } from '../../redux/entities/entities.actions';
import CustomTable from '../../components/CustomTable/CustomTable';
import { translate } from '../../translation/translate.utils';
import {
    getDefaultCompanyColumn,
    getDefaultCreatedColumn,
    getDefaultEmployeeColumn,
    INFINITE_SCROLL_INTERVAL,
} from '../../utils/components.utils';
import CandidateCellRenderer from '../../components/CustomCellRenderer/CandidateCellRenderer';
import { ESortingOptions } from '../../definitions/components.definitions';
import { EIcons } from '../../components/Icons/IconFactory';
import autobind from 'autobind-decorator';
import { getApplicationsFromStore, getApplicationsRequestObject } from '../../selectors/application.selectors';
import {
    setApplicationPageDeclineAction,
    setApplicationPageEditAction,
    setApplicationPageFilterAction,
    setApplicationPageSortingOptionAction,
} from '../../redux/applicationPage/applicationPage.actions';
import VacancyCellRenderer from '../../components/CustomCellRenderer/VacancyCellRenderer';
import { setRecruitingPageStartRecruitingAction } from '../../redux/recruitingPage/recruitingPage.actions';
import { inspectApplication } from '../../utils/application.utils';
import { COLOR_ORANGE } from '../../theme/theme';
import UrlCellRenderer from '../../components/CustomCellRenderer/UrlCellRenderer';

class ApplicationPage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount = () => {
        if (!this.props.initialActivitiesLoaded) {
            this.loadApplications();
        }
    };

    @autobind
    loadApplications() {
        console.log(this.props.requestObject);
        this.props.getApplicationListAction(
            this.props.token,
            this.props.requestObject
        );
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        const needToFetch =
            JSON.stringify(this.props.sorting) !== JSON.stringify(prevProps.sorting) ||
            JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter) ||
            (this.props.reload && !prevProps.reload);

        if (needToFetch) {
            this.loadApplications();
        }
    }

    openEditViewAction = (applicationId: number) => this.props.openEditViewAction(applicationId);
    closeEditViewAction = () => this.props.openEditViewAction(0);

    render() {
        return (
            <>
                <CustomTable
                    config={{
                        color: COLOR_ORANGE,
                        entity: EEntities.Application,
                        columnConfig: {
                            status: {
                                header: translate('misc.status'),
                                property: "status",
                                width: 70,
                                cellRenderer: (value) => {
                                    return translate('pages.application.statusValue.' + value);
                                }
                            },
                            candidate: {
                                header: translate('pages.activity.properties.candidate'),
                                property: "candidate",
                                orderByProperty: "candidate.firstName",
                                flex: 1,
                                cellRenderer: (candidate: null | ICandidate) => {
                                    if (candidate) {
                                        return <CandidateCellRenderer candidate={candidate} />;
                                    }

                                    return translate('misc.noInformation');
                                },
                            },
                            company: getDefaultCompanyColumn(),
                            vacancy: {
                                header: 'Vakanz | Url',
                                property: "vacancy",
                                orderByProperty: "vacancy.title",
                                flex: 1,
                                cellRenderer: (vacancy: null | IVacancy, entity) => {
                                    if (vacancy) {
                                        return <VacancyCellRenderer vacancy={vacancy} />
                                    }

                                    const application = entity as IApplication;

                                    return <UrlCellRenderer url={application.url} value={application.url}/>;
                                },
                            },
                            created: getDefaultCreatedColumn(translate('pages.application.appliedAt')),
                            employee: getDefaultEmployeeColumn()
                        },
                        sortedEntries: this.props.sortedEntries,
                        loading: this.props.loading,
                        scrollCallback: () => {
                            if (this.props.filter.limit && this.props.filter.limit < this.props.totalRecords) {
                                this.props.setFilterAction({
                                    start: 0,
                                    limit: this.props.filter.limit + INFINITE_SCROLL_INTERVAL,
                                });
                            }
                        },
                        listActionButtons: [{
                            action: (applicationId, entity) => {
                                const application = entity as IApplication;
                                this.props.setRecruitingPageStartRecruitingAction({
                                    open: true,
                                    candidateId: application.candidate.id,
                                    vacancyId: application.vacancy?.id,
                                    applicationId: applicationId
                                });
                            },
                            translationKey: "pages.application.accept",
                            icon: EIcons.ThumbUp,
                        }, {
                            action: (applicationId) => {
                                inspectApplication(applicationId);
                            },
                            translationKey: "pages.application.inspect",
                            icon: EIcons.Feedback,
                        }, {
                            action: (applicationId) => {
                                this.props.setApplicationPageDeclineAction(applicationId)
                            },
                            translationKey: "pages.application.decline",
                            icon: EIcons.ThumbDown,
                        }],
                        onRowDoubleClick: this.openEditViewAction,
                        tableHeaderCallbacks: {
                            setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                                this.props.setSortingOptionAction(propertyToSort, sortOption),
                        },
                        onReload: () => this.loadApplications(),
                        count: this.props.count,
                        total: this.props.total
                    }}
                />
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    count: store.entities.applications.order.length,
    total: store.entities.applications.total,
    token: store.actor.token,
    loading: store.ui.applicationsLoading,
    sortedEntries: getApplicationsFromStore(store),
    filter: store.applicationPage.filter,
    sorting: store.applicationPage.sorting,
    requestObject: getApplicationsRequestObject(store),
    initialActivitiesLoaded: store.applicationPage.initialApplicationsLoaded,
    totalRecords: store.entities.applications.total,
    reload: store.applicationPage.reload
});

const mapDispatch = {
    setSortingOptionAction: setApplicationPageSortingOptionAction,
    getApplicationListAction,
    setFilterAction: setApplicationPageFilterAction,
    openEditViewAction: setApplicationPageEditAction,
    setRecruitingPageStartRecruitingAction,
    setApplicationPageDeclineAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ApplicationPage);

