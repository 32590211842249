import { IStore } from '../definitions/redux/store.definitions';
import { IJobTemplate, IJobTitle } from '../definitions/entities.definition';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';

export const getJobTemplateListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.jobTemplatePage.sorting;
    const filter = store.jobTemplatePage.filter;

    return buildRequestObject(sorting, filter);
};

export const getJobTemplateListFromStore = (store: IStore) => {
    const order = store.entities.jobTemplate.order;
    const jobTemplatesById = store.entities.jobTemplate.byId;

    return order.reduce((accumulator: IJobTemplate[], employeeId) => {
        accumulator.push(jobTemplatesById[employeeId]);

        return accumulator;
    }, []);
};

export const getJobTemplateNameListFromStore = (store: IStore) => {
    return store.entities.jobTemplate.names;
};

export const getJobTitleListFromStore = (store: IStore) => {
    const order = store.entities.jobTitle.order;
    const jobTitlesById = store.entities.jobTitle.byId;

    return order.reduce((accumulator: IJobTitle[], employeeId) => {
        accumulator.push(jobTitlesById[employeeId]);

        return accumulator;
    }, []);
};

export const getJobTitleListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.jobTemplatePage.jobTitleSorting;
    const filter = store.jobTemplatePage.jobTitleFilter;

    return buildRequestObject(sorting, filter);
};
