import React from 'react';
import { useSelector } from 'react-redux';
import RecruitingAlertChangeResponsibleView
    from './Pages/RecruitingPage/components/RecruitingAlertChangeResponsibleView';
import CongratulationView from './Pages/RecruitingPage/components/CongratulationView';
import EncouragementView from './Pages/RecruitingPage/components/EncouragementView';
import StartRecruitingView from './Pages/RecruitingPage/StartRecruitingView';
import ActivityDeleteView from './Pages/Shared/Activity/ActivityDeleteView';
import ActivityAddEditView from './Pages/Shared/Activity/ActivityAddEditView';
import SkillDeleteView from './Pages/AdministrationPage/SkillDeleteView';
import CompanyContactAddView from './Pages/Shared/CompanyContact/CompanyContactAddView';
import CompanyContactDeleteView from './Pages/Shared/CompanyContact/CompanyContactDeleteView';
import CompanyLocationAddEditView from './Pages/Shared/CompanyLocation/CompanyLocationAddEditView';
import VacancyAddView from './Pages/RecruitingPage/VacancyAddView';
import TodoEditView from './Pages/Shared/Todo/TodoEditView';
import TodoAddView from './Pages/Shared/Todo/TodoAddView';
import TodoDeleteView from './Pages/App/RightDrawer/Todo/TodoDeleteView';
import CandidateDeleteView from './Pages/CandidatePage/components/CandidateDeleteView';
import InvoiceDeleteView from './Pages/BillingPage/InvoiceDeleteView';
import InvoiceAddEditView from './Pages/BillingPage/components/components/InvoiceAddEditView';
import RecruitingDeleteView from './Pages/RecruitingPage/RecruitingDeleteView';
import ChangePasswordView from './components/Login/ChangePasswordView';
import SkillAddEditView from './Pages/AdministrationPage/SkillAddEditView';
import ExternalSkillAddEditView from './Pages/AdministrationPage/ExternalSkillAddEditView';
import SuggestionDeleteView from './Pages/RecruitingPage/SuggestionDeleteView';
import SuggestionDeclineView from './Pages/RecruitingPage/SuggestionDeclineView';
import SetJobTemplateView from './Pages/AdministrationPage/SetJobTemplateView';
import AssignCandidateToSuggestionView from './Pages/RecruitingPage/AssignCandidateToSuggestionView';
import DeclineApplicationView from './Pages/ApplicationPage/components/DeclineApplicationView';
import EditApplicationView from './Pages/ApplicationPage/components/EditApplicationView';
import ActivityListView from './Pages/Shared/Activity/ActivityListView';
import SuggestionAddActionView from './Pages/RecruitingPage/SuggestionAddActionView';
import { getOpenModals } from './selectors/ui.selectors';
import SearchVacancyView from './Pages/EditViews/Candidate/SearchVacancyView';
import SearchCandidateByVacancyView from './Pages/EditViews/Vacancy/SearchCandidateByVacancyView';
import SearchCandidateByWebVacancyView from './Pages/EditViews/WebVacancy/SearchCandidateByWebVacancyView';
import AddSuggestionForCandidateView from './Pages/RecruitingPage/AddSuggestionForCandidateView';
import CompanyContactEditView from './Pages/Shared/CompanyContact/CompanyContactEditView';
import VacancyDeleteView from './Pages/RecruitingPage/VacancyDeleteView';
import VacancyArchiveView from './Pages/RecruitingPage/VacancyArchiveView';
import AddSuggestionForVacancyView from './Pages/RecruitingPage/components/components/AddSuggestionForVacancyView';

const AppLoggedInModals: React.FC = () => {
    const openModals = useSelector(getOpenModals);

    return <>
        {openModals.map((modal) => {
            switch (modal) {
                case 'recruitingAlertChangeResponsibleModal':
                    return <RecruitingAlertChangeResponsibleView key={modal}/>;
                case 'congratulationModal':
                    return <CongratulationView key={modal}/>;
                case 'encouragementModal':
                    return <EncouragementView key={modal}/>;
                case 'startRecruitingModal':
                    return <StartRecruitingView key={modal}/>;
                case 'addSuggestionForCandidateModal':
                    return <AddSuggestionForCandidateView key={modal}/>;
                case 'addSuggestionForVacancyModal':
                    return <AddSuggestionForVacancyView key={modal}/>;
                case 'activityDeleteModal':
                    return <ActivityDeleteView key={modal}/>;
                case 'activityAddEditModal':
                    return <ActivityAddEditView key={modal}/>;
                case 'skillDeleteModal':
                    return <SkillDeleteView key={modal}/>;
                case 'companyContactAddModal':
                    return <CompanyContactAddView key={modal}/>;
                case 'companyContactEditModal':
                    return <CompanyContactEditView key={modal}/>;
                case 'companyContactDeleteModal':
                    return <CompanyContactDeleteView key={modal}/>;
                case 'companyLocationAddEditModal':
                    return <CompanyLocationAddEditView key={modal}/>;
                case 'vacancyAddModal':
                    return <VacancyAddView key={modal}/>;
                case 'todoEditModal':
                    return <TodoEditView key={modal}/>;
                case 'todoAddModal':
                    return <TodoAddView key={modal}/>;
                case 'todoDeleteModal':
                    return <TodoDeleteView key={modal}/>;
                case 'candidateDeleteModal':
                    return <CandidateDeleteView key={modal}/>;
                case 'invoiceDeleteModal':
                    return <InvoiceDeleteView key={modal}/>;
                case 'invoiceAddEditModal':
                    return <InvoiceAddEditView key={modal}/>;
                case 'recruitingDeleteModal':
                    return <RecruitingDeleteView key={modal}/>;
                case 'vacancyDeleteModal':
                    return <VacancyDeleteView key={modal}/>;
                case 'vacancyArchiveModal':
                    return <VacancyArchiveView key={modal}/>;
                case 'changePasswordModal':
                    return <ChangePasswordView key={modal}/>;
                case 'skillAddEditModal':
                    return <SkillAddEditView key={modal}/>;
                case 'externalSkillAddEditModal':
                    return <ExternalSkillAddEditView key={modal}/>;
                case 'suggestionDeleteModal':
                    return <SuggestionDeleteView key={modal}/>;
                case 'suggestionDeclineModal':
                    return <SuggestionDeclineView key={modal}/>;
                case 'setJobTemplateModal':
                    return <SetJobTemplateView key={modal}/>;
                case 'assignCandidateToSuggestionModal':
                    return <AssignCandidateToSuggestionView key={modal}/>;
                case 'declineApplicationModal':
                    return <DeclineApplicationView key={modal}/>;
                case 'editApplicationModal':
                    return <EditApplicationView key={modal}/>;
                case 'activityListModal':
                    return <ActivityListView key={modal}/>;
                case 'suggestionAddActionModal':
                    return <SuggestionAddActionView key={modal}/>;
                case 'vacancySearchCandidate':
                    return <SearchCandidateByVacancyView key={modal}/>
                case 'webVacancySearchCandidate':
                    return <SearchCandidateByWebVacancyView key={modal}/>
                case 'candidateSearchVacancy':
                    return <SearchVacancyView key={modal}/>
            }
        })}
    </>;
};

export default AppLoggedInModals;
