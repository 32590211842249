import { IApiBaseFilter, ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import { IBranch, IEmployee } from './entities.definition';
import { IRecruitingFilter, IRecruitingFilterMenu } from './recruitingPage.definitions';

export const defaultCompanyPageFilter: ICompanyFilter = {
    fastSearch: '',
    start: 0,
    limit: 50
}
export const defaultCompanyPageFilterMenu: ICompanyFilterMenu = {
}

export const preloadedStateCompanyPage: ICompanyPageState = {
    initialCompaniesLoaded: false,
    editViewLoading: false,
    reloadCompany: false,
    sorting: {
        name: ESortingOptions.Ascending
    },
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    activityFilter: {
        start: 0,
        limit: 50
    },
    invoiceSorting: {
        date: ESortingOptions.Descending,
    },
    applicationSorting: {
        created: ESortingOptions.Descending
    },
    invoiceFilter: {
        start: 0,
        limit: 50
    },
    recruitingSorting: {
        'contractBeginningDate': ESortingOptions.Descending
    },
    filterRecruitingList: {
        start: 0,
        limit: 50,
    },
    filterMenuRecruitingList: {},
    filter: defaultCompanyPageFilter,
    filterMenu: defaultCompanyPageFilterMenu,
    companyToEdit: 0,
    activityToDelete: 0,
    addDialogOpen: false,
    companyToDelete: 0,
    companyContactToDelete: 0,
    locationToDelete: 0,
    companyContactSorting: {},
    companyLocationSorting: {},
    companyVacancySorting: {
        'created': ESortingOptions.Descending
    },
    companyVacancyToDelete: 0,
    documentSorting: {},
    documentToDelete: 0,
    currentDocumentId: 0
};

export interface ICompanyPageState {
    initialCompaniesLoaded: boolean;
    recruitingSorting: ISorting;
    filterRecruitingList: IRecruitingFilter;
    filterMenuRecruitingList: IRecruitingFilterMenu;
    invoiceSorting: ISorting;
    applicationSorting: ISorting;
    invoiceFilter: IApiBaseFilter;
    editViewLoading: boolean;
    reloadCompany: boolean;
    sorting: ISorting;
    filter: ICompanyFilter;
    filterMenu: ICompanyFilterMenu;
    companyToEdit: number;
    companyToDelete: number;
    addDialogOpen: boolean;
    activitySorting: ISorting;
    activityFilter: IApiBaseFilter;
    activityToDelete: number;
    companyLocationSorting: ISorting;
    locationToDelete: number;
    companyContactSorting: ISorting;
    companyContactToDelete: number;
    companyVacancySorting: ISorting;
    companyVacancyToDelete: number;
    documentSorting: ISorting;
    documentToDelete: number;
    currentDocumentId: number;
}

export interface ICompanyFilter extends Partial<IApiBaseFilter> {
    employeeSizeCategory?: number;
    branchIds?: number[];
    responsibleEmployeeId?: number;
    isCustomer?: boolean;
    rating?: number;
}

export interface ICompanyFilterMenu {
    employeeSizeCategory?: number;
    branches?: IBranch[];
    responsibleEmployee?: IEmployee;
    isCustomer?: boolean;
    rating?: number;
}
