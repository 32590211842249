import React, { PropsWithChildren } from 'react';
import { detectScrollEnd } from '../../utils/components.utils';

interface IProps extends PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>{
    id: string;
    scrollCallback?: () => void;
    loading: boolean;
}

const CustomDetectScrolledToEndDiv: React.FC<IProps> = (props) => {
    const id = props.id;
    const loading = props.loading;

    const onScroll = () => {
        const scrolledToBottom = detectScrollEnd(id);

        if (scrolledToBottom && !loading && props.scrollCallback) {
            props.scrollCallback();
        }
    };

    return <>
        <div onScroll={onScroll} {...props} id={id}>
            {props.children}
        </div>
    </>
}

export default CustomDetectScrolledToEndDiv;
