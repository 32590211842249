import moment, { Moment } from 'moment';
import { checkIfDatesOverlap } from './components.utils';
import { ITodoWithDue } from '../definitions/entities.definition';

export const getUtcDate = (dateString: string, format = "YYYY-MM-DD") => {
    return moment.utc(new Date(`${dateString} UTC`)).format(format);
};

export const getUtcMoment = (dateString: string) => {
    return moment.utc(new Date(`${dateString} UTC`));
};

export interface ICalendarWeekdays {
    monday: Moment;
    tuesday: Moment;
    wednesday: Moment;
    thursday: Moment;
    friday: Moment;
    saturday: Moment;
    sunday: Moment;
}

export const getWeekDatesForDateString = (dateString: string) => {
    const utc = getUtcMoment(dateString);

    return {
        monday: utc.weekday(0).format("YYYY-MM-DD"),
        tuesday: utc.weekday(1).format("YYYY-MM-DD"),
        wednesday: utc.weekday(2).format("YYYY-MM-DD"),
        thursday: utc.weekday(3).format("YYYY-MM-DD"),
        friday: utc.weekday(4).format("YYYY-MM-DD"),
        saturday: utc.weekday(5).format("YYYY-MM-DD"),
        sunday: utc.weekday(6).format("YYYY-MM-DD"),
    };
};

export const getWeekDatesForCalendarHeader = (dateString: string) => {
    const utc = getUtcMoment(dateString);

    return {
        monday: utc.weekday(0).format("DD"),
        tuesday: utc.weekday(1).format("DD"),
        wednesday: utc.weekday(2).format("DD"),
        thursday: utc.weekday(3).format("DD"),
        friday: utc.weekday(4).format("DD"),
        saturday: utc.weekday(5).format("DD"),
        sunday: utc.weekday(6).format("DD"),
    };
};

export const calcColumnWidth = (withWeekend: boolean) => {
    const calendarBodyElement = document.getElementById("calendar_body");
    if (!calendarBodyElement) {
        return 100;
    }

    const calendarWidth = calendarBodyElement.clientWidth;
    const scrollBarWidth = 12;
    const timeColumnWidth = 40;
    return (calendarWidth - scrollBarWidth - timeColumnWidth) / (withWeekend ? 7 : 5);
}

export const getAppointmentGroupings = (appointments: ITodoWithDue[]) => {
    const appointmentGrouping: number[][] = [];

    if (appointments) {
        appointments.forEach((appointment) => {
            const thisAppointmentOverlapsWith: number[] = [];

            appointments?.forEach((innerAppointment) => {
                if (innerAppointment.id !== appointment.id) {
                    const overlaps = checkIfDatesOverlap(appointment, innerAppointment);

                    if (overlaps) {
                        thisAppointmentOverlapsWith.push(innerAppointment.id);
                    }
                }
            });

            let groupCreatedAlready = false;

            appointmentGrouping.forEach((group) => {
                if (group.includes(appointment.id)) {
                    groupCreatedAlready = true;
                }
            });

            if (!groupCreatedAlready) {
                appointmentGrouping.push([appointment.id, ...thisAppointmentOverlapsWith]);
            }
        });
    }

    return appointmentGrouping;
};

export const buildTimeForDate = (date: Date) => {
    let hours = String(date.getHours());
    let minutes = String(date.getMinutes());
    let seconds = String(date.getSeconds());

    if (hours.length < 2) {
        hours = `0${hours}`;
    }
    if (minutes.length < 2) {
        minutes = `0${minutes}`;
    }
    if (seconds.length < 2) {
        seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
};
