import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import NumberDropDown from '../../../CustomInput/CustomDropDownInput/NumberDropDown';
import { setDashboardPageFilterAction } from '../../../../redux/dashboardPage/dashboardPage.actions';
import { EDashboardPageTable } from '../../../../definitions/dashboardPage.definitions';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterRecruitingsOfLast7Days: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.dashboardPage.filterRecruitingOfLast7Days);

    const [days, setDays] = useState(filter.days);

    const submit = () => {
        dispatch(
            setDashboardPageFilterAction(EDashboardPageTable.recruitingsOfLast7Days, {
                days
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        setDays(7);
                        dispatch(
                            setDashboardPageFilterAction(EDashboardPageTable.recruitingsOfLast7Days, {
                                days: 7
                            })
                        )
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <NumberDropDown
                    onChange={(event) => setDays(event.target.value)}
                    value={days}
                    required
                    stepSize={1}
                    label={"Anzahl der Tage"}
                    max={100}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterRecruitingsOfLast7Days;
