import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { EActivityType, TActivityType } from '../../../definitions/entities.definition';

interface IProps {
    onChange?: TMouseEventFunction;
    value?: TActivityType;
    disableSystemInfo?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
}

const ActivityTypeDropDown: React.FC<IProps> = (props) => {
    const activityTypes: TActivityType[] = [
        EActivityType.info,
        EActivityType.phone,
        EActivityType.phoneIncoming,
        EActivityType.phoneNotReached,
        EActivityType.mobileMessage,
        EActivityType.mobileMessageReceived,
        EActivityType.mail,
        EActivityType.mailReceived,
        EActivityType.personal,
        EActivityType.systemInfo,
        EActivityType.systemMail
    ];
    let menuItems = activityTypes.map((type: TActivityType, index: number) => (
        <MenuItem disabled={props.disableSystemInfo && (type === EActivityType.systemInfo || type === EActivityType.systemMail)} key={index + 1} value={type}>
            {translate('pages.activity.typeValue.' + type)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            disabled={props.disabled}
            id={"activity-type-dropdown"}
            error={props.error}
            helperText={props.helperText}
            label={translate('pages.activity.properties.type')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default ActivityTypeDropDown;
