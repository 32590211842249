import {
    IPutSkillAction,
    ISetSkillPageExternalSkillToEditAction,
    ISetSkillPageFilterAction,
    ISetSkillPageFilterMenuAction,
    ISetSkillPageSkillToDeleteAction,
    ISetSkillPageSkillToEditAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { ISkillPageState, preloadedStateSkillPage } from '../../definitions/skillPage.definitions';
import {
    SKILL_PAGE_RESET_FILTER,
    SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT,
    SKILL_PAGE_SET_FILTER,
    SKILL_PAGE_SET_FILTER_MENU,
    SKILL_PAGE_SET_SKILL_TO_DELETE,
    SKILL_PAGE_SET_SKILL_TO_EDIT,
} from './skillPage.actions';
import {
    ENTITIES_DELETE_SKILL_FULFILLED,
    ENTITIES_GET_EXTERNAL_SKILL_FULFILLED,
    ENTITIES_GET_EXTERNAL_SKILL_PENDING,
    ENTITIES_GET_EXTERNAL_SKILL_REJECTED,
    ENTITIES_GET_SKILL_FULFILLED,
    ENTITIES_GET_SKILL_PENDING,
    ENTITIES_GET_SKILL_REJECTED,
    ENTITIES_GET_SKILL_TREE_FULFILLED,
    ENTITIES_POST_SKILL_FULFILLED,
    ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED,
    ENTITIES_PUT_SKILL_FULFILLED,
} from '../entities/entities.actions';

const skillPageReducer = (state = preloadedStateSkillPage, action: TReducerAction): ISkillPageState => {
    switch (action.type) {
        case ENTITIES_POST_SKILL_FULFILLED: {
            return {
                ...state,
                addDialogOpen: false,
                reload: true,
                reloadTree: true,
                editViewLoading: false
            }
        }
        case ENTITIES_DELETE_SKILL_FULFILLED: {
            return {
                ...state,
                reload: true,
                editViewLoading: false,
                skillToDelete: 0
            }
        }
        case ENTITIES_GET_EXTERNAL_SKILL_PENDING: {
            return {
                ...state,
                externalSkillEditViewLoading: true
            }
        }
        case ENTITIES_GET_EXTERNAL_SKILL_REJECTED:
        case ENTITIES_GET_EXTERNAL_SKILL_FULFILLED: {
            return {
                ...state,
                externalSkillEditViewLoading: false
            }
        }
        case ENTITIES_GET_SKILL_PENDING: {
            return {
                ...state,
                editViewLoading: true
            }
        }
        case ENTITIES_GET_SKILL_REJECTED:
        case ENTITIES_GET_SKILL_FULFILLED: {
            return {
                ...state,
                editViewLoading: false
            }
        }
        case ENTITIES_PUT_EXTERNAL_SKILL_FULFILLED: {
            return {
                ...state,
                externalSkillEditViewLoading: false,
                externalSkillToEdit: 0
            }
        }
        case ENTITIES_PUT_SKILL_FULFILLED: {
            const actionCast = action as IPutSkillAction;

            return {
                ...state,
                reload: true,
                reloadTree: actionCast.meta.reload,
                editViewLoading: false,
                skillToEdit: (actionCast.meta.closeEditView) ? 0 : state.skillToEdit,
            }
        }
        case ENTITIES_GET_SKILL_TREE_FULFILLED: {
            return {
                ...state,
                reload: false
            }
        }
        case SKILL_PAGE_RESET_FILTER: {
            return {
                ...state,
                filter: preloadedStateSkillPage.filter,
                filterMenu: preloadedStateSkillPage.filterMenu
            }
        }
        case SKILL_PAGE_SET_FILTER: {
            const actionCast = action as ISetSkillPageFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload
                }
            }
        }
        case SKILL_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetSkillPageFilterMenuAction;

            return {
                ...state,
                filterMenu: {
                    ...state.filterMenu,
                    ...actionCast.payload
                }
            }
        }
        case SKILL_PAGE_SET_EXTERNAL_SKILL_TO_EDIT: {
            const actionCast = action as ISetSkillPageExternalSkillToEditAction;

            return {
                ...state,
                externalSkillToEdit: actionCast.payload
            }
        }
        case SKILL_PAGE_SET_SKILL_TO_EDIT: {
            const actionCast = action as ISetSkillPageSkillToEditAction;

            return {
                ...state,
                skillToEdit: actionCast.payload
            }
        }
        case SKILL_PAGE_SET_SKILL_TO_DELETE: {
            const actionCast = action as ISetSkillPageSkillToDeleteAction;

            return {
                ...state,
                skillToDelete: actionCast.payload
            }
        }
    }

    return state;
}

export default skillPageReducer;
