import React from 'react';
import { Badge, IconButton, styled, Tooltip } from '@material-ui/core';
import theme, { dark } from '../../../theme/theme';
import AccountMenuItem from './MenuItem/AccountMenuItem';
import GlobalSearchMenuItem from './MenuItem/GlobalSearchMenuItem';
import { setUiRightDrawerOpenAction } from '../../../redux/ui/ui.actions';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { ERightDrawerViews } from '../../../definitions/ui.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';

export const StyledTopBarBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        color: theme.palette.background.paper,
        backgroundColor: dark,
    },
});

const DefaultTopBarRight: React.FC = () => {
    const dispatch = useDispatch();
    const countUnreadNews = useSelector((store: IStore) => store.ui.countUnreadNews);
    const countUnreadTodos = useSelector((store: IStore) => store.ui.countUnreadTodos);
    const countUnreadMails = useSelector((store: IStore) => store.ui.countUnreadMails);

    const openRightDrawer = (drawer: ERightDrawerViews) => {
        dispatch(setUiRightDrawerOpenAction(drawer));
    }

    return <>
        <GlobalSearchMenuItem/>
        <Tooltip title={'Neuigkeiten'} placement="bottom">
            <IconButton onClick={() => openRightDrawer(ERightDrawerViews.viewNews)}>
                <StyledTopBarBadge color="primary" badgeContent={countUnreadNews}>
                    {IconFactory.getIconComponent(EIcons.LocalLibrary, {color: 'var(--brainz-color-app-bar-right-drawer-icons)'})}
                </StyledTopBarBadge>
            </IconButton>
        </Tooltip>
        <Tooltip title={'Mails'} placement="bottom">
            <IconButton onClick={() => openRightDrawer(ERightDrawerViews.viewMails)}>
                <StyledTopBarBadge color="primary" badgeContent={countUnreadMails}>
                    {IconFactory.getIconComponent(EIcons.EMail, {color: 'var(--brainz-color-app-bar-right-drawer-icons)'})}
                </StyledTopBarBadge>
            </IconButton>
        </Tooltip>
        <Tooltip title={'Todos'} placement="bottom">
            <IconButton onClick={() => openRightDrawer(ERightDrawerViews.viewTodos)}>
                <StyledTopBarBadge color="primary" badgeContent={countUnreadTodos}>
                    {IconFactory.getIconComponent(EIcons.Assign, {color: 'var(--brainz-color-app-bar-right-drawer-icons)'})}
                </StyledTopBarBadge>
            </IconButton>
        </Tooltip>
        <AccountMenuItem/>
    </>;
};

export default React.memo(DefaultTopBarRight);
