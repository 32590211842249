import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ERecruitingAbortedBy, ERecruitingDeclineReason } from '../../../definitions/entities.definition';

interface IProps {
    onChange?: (value: ERecruitingDeclineReason) => void;
    value?: ERecruitingDeclineReason|null;
    label?: string;
    error?: boolean;
    helperText?: string;
    abortedBy?: ERecruitingAbortedBy;
}

const RecruitingDeclineReasonDropDown: React.FC<IProps> = (props) => {
    const candidateReasonValues: ERecruitingDeclineReason[] = [
        ERecruitingDeclineReason.REASON_COMPANY_NOT_MATCHING,
        ERecruitingDeclineReason.REASON_CONDITIONS,
        ERecruitingDeclineReason.REASON_BETTER_OFFER,
        ERecruitingDeclineReason.REASON_DURATION_TOO_LONG,
        ERecruitingDeclineReason.REASON_OTHER,
    ];

    const companyReasonValues: ERecruitingDeclineReason[] = [
        ERecruitingDeclineReason.REASON_MISSING_KNOWLEDGE,
        ERecruitingDeclineReason.REASON_CANDIDATE_TYPUS_NOT_MATCHING,
        ERecruitingDeclineReason.REASON_CONDITIONS,
        ERecruitingDeclineReason.REASON_OTHER_CANDIDATE,
        ERecruitingDeclineReason.REASON_OTHER,
    ];

    const getMenuItems = (reasons?: ERecruitingDeclineReason[]) => {
        if (reasons === undefined) {
            return [
                <MenuItem key={0} value={0}>
                    wähle zunächst aus wer abgesagt hat
                </MenuItem>,
            ]
        }

        return [
            <MenuItem key={0} value={0}>
                {translate('misc.choose')}
            </MenuItem>,
            ...reasons.map((reason: ERecruitingDeclineReason, index: number) => (
                <MenuItem key={index + 1} value={reason}>
                    {translate('pages.vacancy.declineRecruitingReasonValues.' + reason)}
                </MenuItem>
            )),
        ];
    }

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (!props.onChange) {
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value || 0}
            id={"decline-recruiting-reason-dropdown"}
            label={props.label || translate('misc.reason')}
            required
            error={props.error}
            helperText={props.helperText}
        >
            {getMenuItems(props.abortedBy === ERecruitingAbortedBy.CANDIDATE ? candidateReasonValues : props.abortedBy === ERecruitingAbortedBy.COMPANY ? companyReasonValues : undefined)}
        </CustomDropDownInput>
    );
};

export default RecruitingDeclineReasonDropDown;
