import { useEffect, useState } from 'react';
import { getAllVacanciesOfCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import { getCompanyPageRequestObjectForVacanciesInEditView } from '../../../../../selectors/company.selectors';
import { getCompanyVacanciesForCompany } from '../../../../../selectors/companyVacancy.selectors';

export const useCompanyVacancyListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyVacanciesLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForVacanciesInEditView);
    const sorting = useSelector((store: IStore) => store.companyPage.companyVacancySorting);
    const vacancies = useSelector((store: IStore) => getCompanyVacanciesForCompany(store, companyId));

    useEffect(() => {
        dispatch(getAllVacanciesOfCompanyAction(token, companyId, requestObject));
        setInitialLoading(false);
    }, [companyId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getAllVacanciesOfCompanyAction(token, companyId, requestObject));
        }
    }, [JSON.stringify(sorting)]);

    return {loading, vacancies, total: vacancies.length};
}
