import {
    ECandidateSource,
    ICandidateForApi,
    ICandidateListModel,
    IEmployee,
    ISkill,
    TBrainzEntity,
} from '../definitions/entities.definition';
import React, { ReactNode } from 'react';
import { Tooltip } from '@material-ui/core';
import { ECellAlign, ICustomTableColumnConfig } from '../definitions/components.definitions';
import { translate } from '../translation/translate.utils';
import DateDiffCellRenderer from '../components/CustomCellRenderer/DateDiffCellRenderer';
import YesNoCellRenderer from '../components/CustomCellRenderer/YesNoCellRenderer';
import EmployeeCellRenderer from '../components/CustomCellRenderer/EmployeeCellRenderer';
import Chip from '@material-ui/core/Chip';
import { ICandidateFilterApiProperties } from '../definitions/candidatePage.definitions';
import CandidateSourceRenderer from '../components/CustomCellRenderer/CandidateSourceRenderer';
import { ISkillMatchResult } from '../definitions/matching.definitions';
import MatchingSkillsCellRenderer from '../components/CustomCellRenderer/MatchingSkillsCellRenderer';
import CandidateInformationColumnCellRenderer
    from '../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import { Rating } from '@material-ui/lab';
import { EIcons, IconFactory } from '../components/Icons/IconFactory';
import { COLOR_GREEN } from '../theme/theme';
import { addSuggestionAction } from '../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInEmployeeFromStore } from '../selectors/employee.selectors';

export const renderCandidateColored = (candidate: ICandidateListModel, value: ReactNode | string) => {
    if (candidate.hasOpenRecruiting) {
        return <>
            <Tooltip title={'Kandidat befindet sich im Prozess'}>
                <div style={{color: 'var(--brainz-color-green)'}}>{value}</div>
            </Tooltip>
        </>;
    }

    if (candidate.hasOpenSuggestion) {
        return <>
            <Tooltip title={'Kandidat ist als Favorit hinterlegt'}>
                <div style={{color: 'var(--brainz-color-yellow)'}}>{value}</div>
            </Tooltip>
        </>;
    }

    if (candidate.hasContractRecruiting) {
        return <>
            <Tooltip title={'Kandidat wurde vor kurzer Zeit vermittelt'}>
                <div style={{color: 'var(--brainz-color-blue)'}}>{value}</div>
            </Tooltip>
        </>;
    }

    return value;
};

const getAwayOrNearBirthdayString = (daysAwayFromBirthday?: number) => {
    const birthdayIsNear = daysAwayFromBirthday !== undefined && daysAwayFromBirthday >= -14 && daysAwayFromBirthday <= 14;

    if (!birthdayIsNear) {
        return null;
    }

    if (daysAwayFromBirthday === 0) {
        return 'Heute';
    }
    if (daysAwayFromBirthday === 1) {
        return 'Morgen';
    }
    if (daysAwayFromBirthday === -1) {
        return 'Gestern';
    }
    if (daysAwayFromBirthday < -1) {
        return 'Vor ' + (daysAwayFromBirthday * -1) + ' Tagen';
    }
    if (daysAwayFromBirthday > 1) {
        return 'In ' + daysAwayFromBirthday + ' Tagen';
    }

    return null;
};

export const getDefaultColumnConfigForCandidateTable = (
    token: string,
    filter: ICandidateFilterApiProperties,
    updateCandidate: (candidate: Partial<ICandidateForApi>) => void,
    showMatchedSkillsColumn?: boolean,
    optionalSkills?: ISkill[],
    vacancyId?: number
): ICustomTableColumnConfig => {
    const dispatch = useDispatch();
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const columnConfig: ICustomTableColumnConfig = {
        isSuggested: {
            header: <>Favorit</>,
            property: 'isSuggested',
            width: 70,
            disableOrder: true,
            align: ECellAlign.center,
            cellRenderer: (isSuggested: boolean, entity) => {
                const candidateListModel = entity as ICandidateListModel;

                return <Tooltip title={"Als Favorit speichern"}>
                    <Rating
                        name={"isSuggested" + entity.id}
                        size={'large'}
                        value={isSuggested ? 1 : 0}
                        max={1}
                        icon={IconFactory.getIconComponent(EIcons.Bookmark, {color: COLOR_GREEN, width: 26, height: 26})}
                        emptyIcon={IconFactory.getIconComponent(EIcons.Bookmark, {color: 'rgba(0, 0, 0, 0.26)', width: 26, height: 26})}
                        onChange={() => {
                            dispatch(addSuggestionAction(token, {
                                candidateId: candidateListModel.id,
                                source: ECandidateSource.brainz,
                                vacancyId: vacancyId,
                                suggestedByEmployeeId:loggedInEmployee.id,
                                responsibleEmployeeId: loggedInEmployee.id
                            }));
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Tooltip>
            }
        },
        followupDate: {
            header: 'Wiedervorlage',
            property: 'followupDate',
            width: 130,
            align: ECellAlign.right,
            cellRenderer: (followupDate?: null | string) => {
                if (!followupDate) {
                    return translate('misc.noInformation');
                }
                return <DateDiffCellRenderer date={followupDate}/>;
            },
        },
        fullName: {
            header: translate('pages.candidate.properties.name') + ' / ' + translate('pages.candidate.properties.currentJobDescription'),
            property: 'fullName',
            orderByProperty: 'firstName',
            width: 300,
            cellRenderer: (value, entity: TBrainzEntity) => {
                return <CandidateInformationColumnCellRenderer
                    candidate={entity as ICandidateListModel}
                    updateCandidate={updateCandidate}
                    token={token}
                />
            },
        },
        expectedSalaryString: {
            header: <>Gehalts-<br/>vorstellung</>,
            property: 'expectedSalaryString',
            orderByProperty: 'expectedSalary',
            align: ECellAlign.right,
            width: 80
        },
        age: {
            header: translate('pages.candidate.properties.age'),
            property: 'age',
            width: 50,
            align: ECellAlign.center,
            cellRenderer: (age: number, entity: TBrainzEntity) => {
                if (age === 0 || !age) {
                    return <div>-</div>;
                }

                const candidate = entity as ICandidateListModel;
                const awayOrNearString = getAwayOrNearBirthdayString(candidate.daysAwayFromBirthday);

                return <div className={"flexContainerColumn"}>
                    {age}
                    {(awayOrNearString !== null) &&
                        <div className={'formContent'}>
                            {awayOrNearString}
                        </div>
                    }
                </div>;
            },
        },
        skills: {
            header: translate('pages.candidate.properties.skills'),
            property: 'skills',
            align: ECellAlign.center,
            width: 45,
        },
        level: {
            header: <>Erfahrungs-Grad</>,
            property: 'level',
            width: 150,
            cellRenderer: (value) => {
                if (value) {
                    return translate('misc.candidateLevelValue.' + value);
                }
            }
        },
        educationLevel: {
            header: <>Bildungs-<br/>Grad</>,
            property: 'educationLevel',
            width: 80,
            cellRenderer: (value) => {
                if (value) {
                    return translate('misc.educationLevelValue.' + value);
                }
            }
        },
        yearsOfWorkExperience: {
            header: <>Berufs-<br/>Jahre</>,
            property: 'yearsOfWorkExperience',
            align: ECellAlign.center,
            width: 80,
            cellRenderer: (value) => {
                if (value) {
                    return translate('misc.workExperienceValue.' + value);
                }
            }
        },
        zipCode: {
            header: translate('misc.zipCode'),
            property: 'zipCode',
            width: 60,
            // @ts-ignore-next-line
            cellRenderer: (zipCode: string, candidate: ICandidateListModel) => {
                if (!zipCode && !candidate.city) {
                    return <div>-</div>;
                }
                return (
                    <div className={"flexContainerColumn"}>
                        <div>{zipCode}</div>
                        <div className={'formContent'}>{candidate.city}</div>
                    </div>
                );
            }
        },
        source: {
            header: translate('misc.source'),
            property: 'source',
            width: 60,
            align: ECellAlign.center,
            cellRenderer: (source: ECandidateSource) => {
                return <CandidateSourceRenderer source={source}/>
            }
        },
        created: {
            header: <>{translate('misc.lastActivity')}<br/>Erstellt|Beworben am</>,
            property: "candidate.created",
            align: ECellAlign.right,
            width: 180,
            cellRenderer: (lastActivity: string, entity) => {
                const candidate = entity as ICandidateListModel;

                return <>
                    <div className={"flexContainerColumn"}>
                        <DateDiffCellRenderer date={candidate.lastActivity} />
                        <div className={"formContent"}><DateDiffCellRenderer date={candidate.created} /></div>
                    </div>
                </>
            },
        },
        hasContactWithRecruiter: {
            header: translate('pages.candidate.contactWithRecruiter'),
            property: 'hasContactWithRecruiter',
            align: ECellAlign.center,
            disableOrder: true,
            width: 50,
            cellRenderer: (created: boolean) => <YesNoCellRenderer value={created}/>,
        },
        distanceText: {
            header: <>Distanz/<br/>Fahrtzeit</>,
            property: 'distanceText',
            width: 70,
            cellRenderer: (value, entity) => {
                const candidate = entity as ICandidateListModel;
                return (
                    <div className={"flexContainerColumn"}>
                        <div>{candidate.distanceText}</div>
                        <div className={'formContent'}>{candidate.durationText}</div>
                    </div>
                );
            }
        },
        matchedSkills: {
            disableOrder: true,
            header: translate('pages.candidate.matchedSkills'),
            property: "matchedSkills",
            width: 400,
            cellRenderer: (matchResult: ISkillMatchResult) => {
                if (!matchResult || !matchResult.matches || matchResult.matches.length === 0) {
                    return <>Keine Matches</>;
                }
                return <MatchingSkillsCellRenderer skills={matchResult.matches} />;
            },
        },
        matchedOptionalSkillIds: {
            header: translate('pages.candidate.properties.matchOptionalSkills'),
            property: 'matchedOptionalSkillIds',
            flex: 2,
            cellRenderer: (matchedSkills: null | number[]) => {
                if (matchedSkills === null || !optionalSkills) {
                    return null;
                }

                const chipElements = optionalSkills.map((skill: ISkill, index: number) => {
                    if (matchedSkills.includes(skill.id)) {
                        return <Chip key={index} color={'primary'} label={skill.name}/>;
                    }

                    return <Chip key={index} label={skill.name}/>;
                });

                return <div className={"tableCell gap5 flexContainerColumn alignHorizontalLeft"}>{chipElements}</div>;
            },
        },
        responsible: {
            header: translate('misc.employeeShort'),
            property: 'responsible',
            align: ECellAlign.left,
            cellRenderer: (employee: IEmployee) => {
                if (!employee) {
                    return <>-</>;
                }
                return <EmployeeCellRenderer employee={employee}/>;
            },
        },
    };

    if (vacancyId) {
        delete columnConfig.responsible;
        delete columnConfig.source;
        delete columnConfig.created;
        delete columnConfig.isSuggested;
    }

    if (!vacancyId) {
        delete columnConfig.isSuggested;
    }

    if (!showMatchedSkillsColumn) {
        delete columnConfig.matchedSkills;
    }
    if (showMatchedSkillsColumn) {
        delete columnConfig.skills;
    }
    if (!filter.onlyFollowups) {
        delete columnConfig.followupDate;
    }

    if (!filter.distanceSearchKm || !filter.distanceSearchCompanyLocationId) {
        delete columnConfig.distanceText;
    }

    if (!filter.optionalSkillIds || filter.optionalSkillIds.length === 0) {
        delete columnConfig.matchedOptionalSkillIds;
    }

    return columnConfig;
};
