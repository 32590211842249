import { MenuItem } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ECandidateLevel } from '../../../definitions/entities.definition';

interface IProps {
    onChange: (value?: ECandidateLevel|null) => void;
    value?: ECandidateLevel|null;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
}

const CandidateLevelDropDown: React.FC<IProps> = (props) => {
    const candidateLevelValues: ECandidateLevel[] = [
        ECandidateLevel.junior,
        ECandidateLevel.professional,
        ECandidateLevel.senior
    ];

    let menuItems = candidateLevelValues.map((candidateLevel: ECandidateLevel, index: number) => (
        <MenuItem key={index + 1} value={candidateLevel}>
            {translate('misc.candidateLevelValue.' + candidateLevel)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={-1} value={-1}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(undefined);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value !== undefined && props.value !== null ? props.value : -1}
            id={"candidate-level-dropdown"}
            label={"Erfahrungs-Grad"}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default CandidateLevelDropDown;
