import { IStore } from '../../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { EEntities } from '../../../definitions/entities.definition';
import { getActivityListAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { setUICloseSuggestionListViewAction } from '../../../redux/ui/ui.actions';
import { getActivityRequestObjectForListView } from '../../../selectors/activity.selectors';
import ActivityListViewList from './ActivityListViewList';

const ActivityListView: React.FC = () => {
    const token = useSelector(getToken);
    const requestObject = useSelector(getActivityRequestObjectForListView);
    const open = useSelector((store: IStore) => store.ui.activityListViewOpen);
    const filter = useSelector((store: IStore) => store.ui.activityListViewFilter);
    const sorting = useSelector((store: IStore) => store.ui.activityListViewSorting);
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setUICloseSuggestionListViewAction());
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        dispatch(getActivityListAction(
            token,
            requestObject
        ));
    }, [open, JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        if (!reload || !open) {
            return;
        }

        dispatch(getActivityListAction(
            token,
            requestObject
        ));
    }, [reload]);

    if (!open) {
        return null;
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={onClose}
                fullWidth
            >
                <CustomDialogHeader
                    string={translate("misc.activities")}
                    filterMenu={EEntities.ActivityList}
                    onClose={onClose}
                />
                    <ActivityListViewList />
                <CustomDialogActions
                    onClose={onClose}
                />
            </CustomDialog>
        </>
    );
};

export default ActivityListView;
