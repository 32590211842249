import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: (value?: boolean) => void;
    value?: boolean;
    label: string;
    error?: boolean;
    required?: boolean;
}

const YesNoDropDown: React.FC<IProps> = (props) => {
    let selectedValue = -1;
    if (props.value === true) {
        selectedValue = 1;
    }
    if (props.value === false) {
        selectedValue = 0;
    }
    return (
        <>
            <CustomDropDownInput
                id={"years-no-dropdown" + props.label}
                onChange={(event) => {
                    const value = event.target.value;

                    if (value === 1) {
                        props.onChange(true);
                        return;
                    }

                    if (value === 0) {
                        props.onChange(false);
                        return;
                    }

                    if (value === -1) {
                        props.onChange();
                        return;
                    }
                }}
                value={selectedValue}
                label={props.label}
                error={props.error}
                required={props.required}
            >
                <MenuItem key={0} value={-1}>
                    {translate('misc.choose')}
                </MenuItem>
                <MenuItem key={1} value={1}>
                    {translate('misc.yes')}
                </MenuItem>
                <MenuItem key={2} value={0}>
                    {translate('misc.no')}
                </MenuItem>
            </CustomDropDownInput>
        </>
    );
};

export default YesNoDropDown;
