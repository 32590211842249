import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { translate } from '../../../../translation/translate.utils';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiCompanyContactToEditAction } from '../../../../redux/ui/ui.actions';
import { setCompanyPageCompanyContactToDeleteAction } from '../../../../redux/companyPage/companyPage.actions';
import { getCompanyContactsWithoutBirthdateFromStore } from '../../../../selectors/companyContacts.selectors';
import { getContactsWithoutBirthDateAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { getDefaultCompanyColumn, getDefaultCreatedColumn } from '../../../../utils/components.utils';

const NoBirthdateCompanyContactsTable: React.FC = () => {
    const dispatch = useDispatch();
    const sortedEntries = useSelector(getCompanyContactsWithoutBirthdateFromStore);
    const loading = useSelector((store: IStore) => store.ui.companyContactsWithoutBirthDateLoading);
    const token = useSelector(getToken);

    return (
        <CustomTable
            config={{
                entity: EEntities.CompanyContactBirthday,
                sortedEntries: sortedEntries,
                columnConfig: {
                    name: {
                        header: translate('pages.companyContact.properties.name'),
                        property: "name",
                    },
                    firstName: {
                        header: translate('pages.companyContact.properties.firstName'),
                        property: "firstName",
                    },
                    company: getDefaultCompanyColumn(),
                    phoneNumber: {
                        header: translate('misc.phoneNumber'),
                        property: "phoneNumber",
                    },
                    created: getDefaultCreatedColumn()
                },
                loading: loading,
                count: sortedEntries.length,
                total: sortedEntries.length,
                onReload: () => {
                    dispatch(getContactsWithoutBirthDateAction(token));
                },
                listActionButtons: [
                    {
                        action: (contactId: number) => dispatch(setCompanyPageCompanyContactToDeleteAction(contactId)),
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    },
                ],
                onRowDoubleClick: (contactId: number) => dispatch(setUiCompanyContactToEditAction(contactId)),
            }}
        />
    );
}

export default NoBirthdateCompanyContactsTable;

