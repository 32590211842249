import React, { useState } from 'react';
import { ELanguageLevel, ICandidateLanguage, ILanguage } from '../../../../../definitions/entities.definition';
import CustomDialogHeader from '../../../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../../../../components/CustomDialog/CustomDialogBody';
import PaperRow from '../../../../../components/CustomPaper/PaperRow';
import { translate } from '../../../../../translation/translate.utils';
import CustomDialogActions from '../../../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../../../components/CustomDialog/CustomDialog';
import CustomButton from '../../../../../components/CustomInput/CustomButton';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import LanguageLevelDropDown from '../../../../../components/CustomInput/CustomDropDownInput/LanguageLevelDropDown';
import { EIcons } from '../../../../../components/Icons/IconFactory';
import TopBarButton from '../../../../../components/ListAndPreview/TopBarButton';
import { onAddLanguagesToCandidate } from '../../../../../utils/candidate.utils';
import LegacyLanguageAutocompleteDropDown
    from '../../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyLanguageAutocompleteDropDown';

interface IProps {
    candidateId?: number;
    onClose: () => void;
    open: boolean;
    languages: ICandidateLanguage[];
    setLanguages: (languages: ICandidateLanguage[]) => void;
}

const CandidateEditLanguagesView: React.FC<IProps> = (props) => {
    const [languageToAdd, setLanguageToAdd] = useState<ILanguage|undefined>(undefined);

    const onRemoveLanguage = (removeLanguageId: number) => {
        props.setLanguages(props.languages.filter((candidateLanguage: ICandidateLanguage) => removeLanguageId !== candidateLanguage.language.id))
    };

    const onSetLanguageLevel = (languageId: number, level: ELanguageLevel) => {
        props.setLanguages(props.languages.map((candidateLanguage: ICandidateLanguage) => {
            if (candidateLanguage.language.id === languageId) {
                return {
                    ...candidateLanguage,
                    level
                }
            }
            return candidateLanguage;
        }));
    }

    return (
        <CustomDialog maxWidth={"md"} open={props.open} onClose={props.onClose}>
            <CustomDialogHeader translationString={'misc.editLanguages'} onClose={props.onClose} />
            <CustomDialogBody>
                <PaperRow>
                    <div style={{flex: 3}}>
                        <LegacyLanguageAutocompleteDropDown
                            value={languageToAdd}
                            onChange={(event, value) => setLanguageToAdd(value)}
                        />
                    </div>
                    <div style={{flex:1}}>
                        <CustomButton
                            label={translate('misc.add')}
                            onClick={() => {
                                if (!languageToAdd) {
                                    return;
                                }

                                onAddLanguagesToCandidate(
                                    [{language: languageToAdd, level: ELanguageLevel.good}],
                                    props.languages,
                                    props.setLanguages,
                                    props.candidateId ? {
                                        id: props.candidateId
                                    } : undefined
                                );
                            }}
                        />
                    </div>
                </PaperRow>
                <div className={"flexColumn"} style={{padding: 8}}>
                    {props.languages.map((candidateLanguage, index) =>
                        <div className={"flex flexCentered"} key={index}>
                            <CustomTextField
                                label={translate('misc.language')}
                                value={candidateLanguage.language.name}
                                disabled
                            />
                            <LanguageLevelDropDown
                                onChange={(event) => onSetLanguageLevel(
                                    candidateLanguage.language.id,
                                    event.target.value
                                )}
                                value={candidateLanguage.level}
                            />
                            <div style={{marginTop: 15, marginRight: 10}}>
                                <TopBarButton
                                    onClick={() => onRemoveLanguage(candidateLanguage.language.id)}
                                    icon={EIcons.Delete}
                                    label={translate('misc.buttons.delete')}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </CustomDialogBody>
            <CustomDialogActions
                buttons={[{
                    void: () => {
                        props.onClose();
                    },
                    color: 'secondary',
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </CustomDialog>
    );
}

export default CandidateEditLanguagesView;
