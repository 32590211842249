import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import CustomTextField from '../../CustomTextField';
import { translate } from '../../../../translation/translate.utils';

interface IProps {
    id?: string;
    options: any[];
    label: string;
    onAdd?: () => void;
    getOptionLabel: (option: any) => string;
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
    //@todo value rausbauen. Komponente handelt sich selber, mit defaultValue arbeiten
    value?: any;
    defaultValue?: any;
    disabled?: boolean;
    flex?: number;
    multiple?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    autoFocus?: boolean;
}

const CustomAutocompleteDropDown: React.FC<IProps> = (props) => {
    let selectedOption: any;
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (!value && props.defaultValue) {
            if (props.value !== undefined && props.value !== null) {
                let selectedId: number = props.value;
                if (typeof props.value !== 'number') {
                    selectedId = props.value["id"];
                }

                selectedOption = props.options.find((option: any) => selectedId === option.id);
            }

            if (props.defaultValue !== undefined && props.defaultValue !== null) {
                let selectedId: number = props.defaultValue;
                if (typeof props.defaultValue !== 'number') {
                    selectedId = props.defaultValue["id"];
                }

                selectedOption = props.options.find((option: any) => {
                    return selectedId === option.id
                });
            }

            if (!selectedOption) {
                selectedOption = props.defaultValue;
            }

            if (selectedOption) {
                setValue(selectedOption);
            }
        }
    }, [value, props.defaultValue, props.options]);

    if (!value && props.defaultValue) {
        return null;
    }

    return (
        <Autocomplete
            multiple={props.multiple}
            disabled={props.disabled}
            id={props.id}
            value={selectedOption}
            defaultValue={value}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            noOptionsText={translate('misc.noData')}
            onChange={props.onChange}
            style={{flex: 1}}
            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    autoFocus={props.autoFocus}
                    label={props.label}
                    error={props.error}
                    required={props.required}
                    helperText={props.helperText}
                    onAddNewEntryCallback={props.onAdd}
                />
            )}
        />
    );
}

export default CustomAutocompleteDropDown;
