import React from 'react';
import { useSelector } from 'react-redux';
import { getEditViewEntity } from '../../../selectors/ui.selectors';
import { Typography } from '@material-ui/core';
import { IEditViewState } from '../../../definitions/ui.definitions';
import CustomCellTopBar from '../../../components/ListAndPreview/CustomCellTopBar';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getEntityViewTitle } from '../../../utils/ui.utils';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import { IVacancyPartial } from '../../../definitions/entities.definition';

interface IProps {
    editView: IEditViewState;
}

const EntityViewTitleBar: React.FC<IProps> = (props) => {
    const editView = props.editView;
    const editViewEntity = useSelector((store: IStore) => getEditViewEntity(store, editView.view, editView.entityId));

    if (!editViewEntity) {
        return null;
    }

    return <>
        <Typography style={{ marginLeft: 10, minWidth: 400, fontSize: 16, color: 'var(--brainz-color-app-bar-heading)' }} variant="h6" noWrap>
            {getEntityViewTitle(editView.view, editViewEntity)}
            <div className={"formContent flexContainerRow alignVerticalCenter"}>
                <CustomCellTopBar
                    //@ts-ignore
                    candidate={editViewEntity.candidate}
                    //@ts-ignore
                    company={editViewEntity.company}
                    //@ts-ignore
                    vacancy={editViewEntity.vacancy}
                    //@ts-ignore
                    webVacancy={editViewEntity.webVacancy}
                    //@ts-ignore
                    employee={editViewEntity.responsibleEmployee || editViewEntity.employee || editViewEntity.responsible}
                    //@ts-ignore
                    recruiting={editViewEntity.recruiting}
                    //@ts-ignore
                    recruitingSuggestion={editViewEntity.suggestion || editViewEntity.recruitingSuggestion}
                    //@ts-ignore
                    created={editViewEntity.created}
                />
                {
                    //@ts-ignore
                    editViewEntity.vacancies &&
                    <>

                        {
                            //@ts-ignore
                            (editViewEntity.vacancies as IVacancyPartial[]).map((vacancy) => <div key={vacancy.id} style={{paddingLeft: 5}}>
                                <VacancyCellRenderer key={vacancy.id} vacancy={vacancy} />
                            </div>)
                        }
                    </>
                }
            </div>
        </Typography>
    </>;
}

export default React.memo(EntityViewTitleBar)
