import { useEffect, useState } from 'react';
import { getRecruitingsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getCompanyPageRequestObjectForRecruitingsInEditView,
    getRecruitingsForCompany,
} from '../../../../../selectors/recruiting.selectors';

export const useCompanyRecruitingListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.companyRecruitingsLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForRecruitingsInEditView);
    const forceReload = useSelector((store: IStore) => store.vacancyPage.reloadRecruitingsOfVacancy);
    const filter = useSelector((store: IStore) => store.companyPage.filterRecruitingList);
    const recruitings = useSelector((store: IStore) => getRecruitingsForCompany(store, companyId));

    useEffect(() => {
        if (!loading) {
            dispatch(getRecruitingsForCompanyAction(token, companyId, requestObject));
            setInitialLoading(false);
        }
    }, [forceReload]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getRecruitingsForCompanyAction(token, companyId, requestObject));
        }
    }, [JSON.stringify(filter)]);

    return {loading, recruitings, total: recruitings.length};
}
