import { IStore } from '../definitions/redux/store.definitions';
import { IRecruitingDocument } from '../definitions/entities.definition';
import { buildRequestObject } from './entity.selectors';

export const getDocumentsForRecruiting = (store: IStore, recruitingId: number) => {
    const documentsById = store.entities.recruitingDocuments.byId;
    const documentIdsByRecruitingId = store.entities.recruitingDocuments.byRecruiting[recruitingId] || [];

    const documents: IRecruitingDocument[] = [];

    documentIdsByRecruitingId.forEach((documentId) => documents.push(documentsById[documentId]));

    return documents;
};

export const getRecruitingPageRequestObjectForDocumentsInEditView = (store: IStore) => {
    const sorting = store.recruitingPage.documentSorting;
    const filter = { start: 0, limit: 50, fastSearch: "" };

    return buildRequestObject(sorting, filter);
};
