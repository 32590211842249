import { ClientApi } from '../requests/ClientApi';
import { putDeclineApplicationRouteConfig } from '../requests/routes';
import { setApplicationPageReload } from '../redux/applicationPage/applicationPage.actions';
import { registerSuccess } from '../redux/error/error.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../selectors/app.selectors';

export const inspectApplication = (applicationId: number): void => {
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    ClientApi.request(putDeclineApplicationRouteConfig, {
        token,
        applicationId,
    }).then(() => {
        dispatch(setApplicationPageReload());
        dispatch(registerSuccess());
    });
}

export const objectsAreEqual = (object1: object, object2: object) => {
    return JSON.stringify(object1, Object.keys(object1).sort()) === JSON.stringify(object2, Object.keys(object2).sort())
}

export const fixUrl = (url: string): string => {
    return url.includes('http') ? url : 'https://' + url
}
