import React, { useCallback } from 'react';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import PaperRow from '../../../CustomPaper/PaperRow';
import YearsOfWorkExperienceDropDown from '../../../CustomInput/CustomDropDownInput/YearsOfWorkExperienceDropDown';

interface IProps {
    value?: string;
    onChange: (value?: string) => void;
}

const WorkExperienceFilter: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value?: string) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const renderValue = useCallback(() => {
        if (value === undefined) {
            return null;
        }

        return <>
            {translate('misc.workExperienceValue.' + value)}
        </>;
    }, [value]);

    const deletable = Boolean(props.value);

    return <>
        <CustomFilterItem
            title={"Arbeitserfahrung"}
            icon={EIcons.Work}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            width={300}
            autoClose
        >
            <PaperRow>
                <YearsOfWorkExperienceDropDown
                    onChange={(event: any) => onChange(event.target.value)}
                    value={value}
                    autoFocus
                />
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(WorkExperienceFilter);
