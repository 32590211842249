import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postApplicationAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import VacancyAutocomplete from '../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import { registerError } from '../../redux/error/error.actions';
import { translate } from '../../translation/translate.utils';
import { getLoggedInEmployeeFromStore } from '../../selectors/employee.selectors';
import CustomButton from '../../components/CustomInput/CustomButton';
import CandidateAutocomplete from '../../components/CustomInput/CustomAutocomplete/components/CandidateAutocomplete';

interface IProps {
    open: boolean;
    candidateId?: number;
    vacancyId?: number;
    companyId?: number;
    onClose: () => void;
}

const AddApplicationView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = props.open;
    const [candidateId, setCandidateId] = useState<number|null>(props.candidateId || null);
    const [vacancyId, setVacancyId] = useState<number|null>(props.vacancyId || null);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const onClose = () => {
        setVacancyId(null);
        props.onClose();
    };

    const onSave = () => {
        if (!vacancyId) {
            dispatch(registerError('Bitte eine Vakanz auswählen'));
            return;
        }
        if (!candidateId) {
            dispatch(registerError('Bitte einen Kandidaten auswählen'));
            return;
        }
        dispatch(postApplicationAction(token, candidateId, vacancyId, loggedInEmployee.id));
        onClose();
    }

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogHeader string={"Bewerbung für Kandidaten anlegen"} onClose={onClose} />
            <div className={"flexContainerRow flex1 gap5 padding10"}>
                {!props.vacancyId &&
                    <VacancyAutocomplete
                        value={vacancyId}
                        required
                        onChange={(value) => {
                            if (value === null) {
                                setVacancyId(null);
                                return;
                            }
                            setVacancyId(value.id);
                        }}
                    />
                }
                {!props.candidateId &&
                    <CandidateAutocomplete
                        value={candidateId}
                        required
                        onChange={(value) => {
                            if (value === null) {
                                setCandidateId(null);
                                return;
                            }
                            setCandidateId(value.id);
                        }}
                    />
                }

                <div style={{width: 80, marginTop: 5}}>
                    <CustomButton
                        color={"primary"}
                        onClick={onSave}
                        label={translate('misc.buttons.save')}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default AddApplicationView;
