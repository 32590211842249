import React, { PropsWithChildren, ReactElement } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { TBrainzEntity } from '../../definitions/entities.definition';

interface IProps extends PropsWithChildren<any>{
    onDragEnd: (result:DropResult) => void;
    items: TBrainzEntity[];
    rootClassName?: string;
    renderItem: (entity: TBrainzEntity, index: number) => ReactElement;
    renderAdditional?: (entity: TBrainzEntity) => ReactElement;
    isDragDisabled?: (entity: TBrainzEntity) => boolean;
}

const CustomDragDropContext: React.FC<IProps> = (props) => {
    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) =>
                    <div
                        className={props.rootClassName}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {
                            props.items.map((entity, index) => (
                                <>
                                    {props.renderAdditional && props.renderAdditional(entity) }
                                    <Draggable
                                        isDragDisabled={props.isDragDisabled ? props.isDragDisabled(entity) : false}
                                        key={entity.id}
                                        draggableId={(entity.id as number).toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {props.renderItem(entity, index)}
                                            </div>
                                        )}
                                    </Draggable>
                                </>
                            ))
                        }
                    </div>
                }
            </Droppable>
        </DragDropContext>
    )
}

export default CustomDragDropContext;
