import { MenuItem } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ECandidateLevel, EEducationLevel } from '../../../definitions/entities.definition';

interface IProps {
    onChange: (value?: ECandidateLevel|null) => void;
    value?: ECandidateLevel|null;
    error?: boolean;
    helperText?: ReactNode;
    required?: boolean;
}

const EducationLevelDropDown: React.FC<IProps> = (props) => {
    const educationLevelValues: EEducationLevel[] = [
        EEducationLevel.education,
        EEducationLevel.study,
        EEducationLevel.careerChanger
    ];

    let menuItems = educationLevelValues.map((candidateLevel: EEducationLevel, index: number) => (
        <MenuItem key={index + 1} value={candidateLevel}>
            {translate('misc.educationLevelValue.' + candidateLevel)}
        </MenuItem>
    ));

    menuItems = [
        <MenuItem key={-1} value={-1}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={(event) => {
                if (event.target.value === -1) {
                    props.onChange(undefined);
                    return;
                }
                props.onChange(event.target.value);
            }}
            value={props.value !== undefined && props.value !== null ? props.value : -1}
            id={"education-level-dropdown"}
            label={"Bildungs-Grad"}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default EducationLevelDropDown;
