import { Divider } from '@material-ui/core';
import React from 'react';

const CustomQuickFilterDivider: React.FC = () => {
    return <Divider
        orientation={"vertical"}
        style={{height: 34, marginTop: 1, alignSelf: 'start', backgroundColor: 'rgba(233, 237, 239, 0.2)'}}
    />;
}

export default React.memo(CustomQuickFilterDivider);
