import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { ERecruitingStatus } from '../../../definitions/entities.definition';
import {
    getActivityChatListForRecruitingAction,
    getDocumentsForRecruitingAction,
    getInvoiceListOfRecruitingAction,
    getRecruitingAction,
} from '../../../redux/entities/entities.actions';
import { getRecruitingPageActivitiesRequestObject } from '../../../selectors/activity.selectors';
import RecruitingInvoiceList from '../../RecruitingPage/components/components/RecruitingInvoiceList';
import { getRecruitingPageInvoiceListRequestObject } from '../../../selectors/billing.selectors';
import RecruitingDocumentList from '../../RecruitingPage/components/components/RecruitingDocumentList';
import RecruitingDocumentUpload from '../../RecruitingPage/components/components/RecruitingDocumenUpload';
import RecruitingCreateCongratulationMailView from '../../RecruitingPage/RecruitingCreateCongratulationMailView';
import RecruitingViewChatPanel from './components/RecruitingViewChatPanel';
import { themeOrange } from '../../../theme/theme';
import CustomTabContainer from '../../../components/CustomTabContainer/CustomTabContainer';
import { ITab } from '../../../definitions/components.definitions';
import CustomBackdropLoadingInsideDiv from '../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import RecruitingEditForm from '../../RecruitingPage/components/components/RecruitingEditForm';
import { getRecruitingPageRequestObjectForDocumentsInEditView } from '../../../selectors/recruitingDocument.selectors';

interface IProps {
    id: number;
}

const RecruitingView: React.FC<IProps> = (props) => {
    const recruitingId = props.id;
    const recruiting = useSelector((store: IStore) => store.entities.recruiting.byId[recruitingId]);
    const activityRequestObject = useSelector(getRecruitingPageActivitiesRequestObject);
    const invoiceRequestObject = useSelector(getRecruitingPageInvoiceListRequestObject);
    const documentsRequestObject = useSelector(getRecruitingPageRequestObjectForDocumentsInEditView);
    const loadingActivities = useSelector((store: IStore) => store.ui.activitiesLoading);
    const activityFilter = useSelector((store: IStore) => store.recruitingPage.activityFilter);
    const reloadActivities = useSelector((store: IStore) => store.ui.reloadActivityGrids);
    const [activeTab, setActiveTab] = useState(0);

    const token = useSelector(getToken);
    const dispatch = useDispatch();

    useEffect(() => {
        if (recruitingId> 0) {
            dispatch(getRecruitingAction(token, recruitingId));
        }
    }, [recruitingId]);

    useEffect(() => {
        if (recruitingId > 0) {
            dispatch(getActivityChatListForRecruitingAction(token, recruitingId, activityRequestObject));
            dispatch(getInvoiceListOfRecruitingAction(token, recruitingId, invoiceRequestObject));
            dispatch(getDocumentsForRecruitingAction(token, recruitingId, documentsRequestObject));
        }
    }, [recruitingId]);

    useEffect(() => {
        if (recruitingId > 0 && !loadingActivities) {
            dispatch(getActivityChatListForRecruitingAction(token, recruitingId, activityRequestObject));
            return;
        }
    }, [JSON.stringify(activityFilter)]);

    useEffect(() => {
        if (recruitingId > 0 && reloadActivities && !loadingActivities) {
            dispatch(getActivityChatListForRecruitingAction(token, recruitingId, activityRequestObject));
            return;
        }
    }, [reloadActivities]);

    if (recruitingId > 0 && !recruiting) {
        return <>
            <div className={"flexContainerRow flex"} style={{width: 600}}>
                <CustomBackdropLoadingInsideDiv open={true}/>
            </div>
        </>;
    }

    if (!recruiting) {
        return null;
    }

    const tabs: ITab[] = [{
        label: 'Historie',
        component: <>
            <RecruitingViewChatPanel recruitingId={recruitingId}/>
        </>
    }, {
        label: 'Stammdaten',
        component: <>
            <RecruitingEditForm recruiting={recruiting}/>
        </>
    }, {
        label: 'Dokumente',
        component: <>
            <RecruitingDocumentUpload recruitingId={recruitingId}/>
            <RecruitingDocumentList recruitingId={recruitingId}/>
        </>
    }, {
        label: 'Rechnungen',
        component: <>
            <RecruitingInvoiceList recruitingId={recruitingId}/>
        </>
    }];

    if (!recruiting.vacancy) {
        delete tabs[2];
    }

    if (recruiting.status !== ERecruitingStatus.STATUS_CONTRACT && recruiting.status !== ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD && recruiting.status !== ERecruitingStatus.STATUS_FINISH) {
        delete tabs[2];
        delete tabs[3];
    }

    return <>
        <RecruitingCreateCongratulationMailView/>
        <div className={'flexContainerRow flex padding5'} style={{minWidth: 820}}>
            <CustomTabContainer
                activeTab={activeTab}
                roundBorder
                onTabChange={(tab) => setActiveTab(tab)}
                color={themeOrange.palette.primary.main}
                tabs={tabs}
            />
        </div>
    </>;
};

export default RecruitingView;
