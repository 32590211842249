import React from 'react';
import { TSalutation } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import CustomRadioGroupInput from '../../../CustomInput/CustomRadioGroupInput';

interface IProps {
    value?: TSalutation;
    onChange: (value?: TSalutation) => void;
}

const SalutationFilter: React.FC<IProps> = (props) => {
    const salutation = props.value;

    const onChange = (value: TSalutation) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const deletable = salutation !== undefined;
    const valueRendered = (deletable) ? translate('misc.' + salutation) : null;

    return <>
        <CustomFilterItem
            title={"Anrede"}
            icon={EIcons.Wc}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={"auto"}
            autoClose
        >
            <CustomRadioGroupInput
                value={salutation}
                onChange={(salutation) => onChange(salutation as undefined|TSalutation)}
                states={[{
                    value: 'mr',
                    label: translate('misc.mr')
                }, {
                    value: 'mrs',
                    label: translate('misc.mrs')
                }]}
                dontCareOption
            />
        </CustomFilterItem>
    </>;
};

export default React.memo(SalutationFilter);
