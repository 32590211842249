import {
    EActivityModule,
    EEntities,
    EFeedbackType, EInterviewType,
    ICandidatePartial,
    ICompanyPartial,
    IEmployee,
    IEmployeePartial,
    IRecruitingPartial,
    IVacancyPartial,
} from './entities.definition';
import { ERoutes, ISorting } from './app.definitions';
import { IActivityFilter, IActivityFilterMenu } from './activityPage.definitions';
import { ESortingOptions, IAppBarActionButtonConfig } from './components.definitions';
import { IFilterPanelConfig, TFilterPanelType } from './filter.definitions';

export interface IAppBarConfig {
    value: string;
    emptyValue: string;
    entity: EEntities;
    fastSearchCallback?: (value: string) => void;
    actionButtons?: IAppBarActionButtonConfig[];
    furtherActionButtons?: IAppBarActionButtonConfig[];
    filterPanelType?: TFilterPanelType;
    filterPanel?: IFilterPanelConfig; //habe es jetzt doch nicht dynamisch generiert, aber möchte diesen Ansatz noch nicht verwerfen
}

export interface IActivityAddData {
    add: boolean;
    isInterview?: boolean;
    isFeedback?: boolean;
    feedbackType?: EFeedbackType;
    module?: EActivityModule;
    companyId?: number;
    applicationId?: number;
    candidateId?: number;
    recruitingId?: number;
    vacancyId?: number;
    webVacancyId?: number;
    contactId?: number;
    interviewType?: EInterviewType;
    suggestionId?: number;
    todoId?: number;
    preSelectedEmployee?: IEmployee|IEmployeePartial;
    company?: ICompanyPartial,
    candidate?: ICandidatePartial,
    recruiting?: IRecruitingPartial,
    vacancy?: IVacancyPartial,
}

export interface ISuggestSkillsActionData {
    open: boolean;
    title: string;
    vacancyId?: number;
    candidateId?: number;
}

export interface ICompanyContactAddData {
    companyId: number
}

export interface ICompanyLocationAddData {
    companyId: number
}

export enum ERightDrawerViews {
    viewTodos,
    viewNews,
    viewMails
}

export enum EEntityView {
    vacancy,
    webVacancy,
    candidate,
    recruiting,
    company,
    suggestion
}

export interface IEditViewState {
    view: EEntityView;
    entityId: number;
    parentView?: EEntityView;
    initialTab?: number;
}

export interface IPinnedViewState extends Omit<IEditViewState, 'initialTab'>{
    title: string;
}

export interface IUiState {
    splitCandidateEditView: boolean;
    showOnlyMyDataActive: boolean;
    searchForVacancyId: number;
    searchForWebVacancyId: number;
    searchForCandidateId: number;
    openViews: IEditViewState[];
    pinnedViews: IPinnedViewState[];
    filterPanelsOpened: EEntities[];
    forceExitFastEdit: boolean;
    candidateVacancySearchLoading: boolean;
    importCandidate: boolean;
    editCellId?: string;
    addActivityData: IActivityAddData;
    suggestSkillsData: ISuggestSkillsActionData;
    openCongratulation: boolean;
    openEncouragement: boolean;
    editActivityId: number;
    deleteActivityId: number;
    deleteActivityIsMailAssignment?: boolean;
    addCompanyContactData: ICompanyContactAddData;
    editCompanyContactId: number;
    addCompanyLocationData: ICompanyLocationAddData;
    editCompanyLocationId: number;
    reloadActivityGrids: boolean;
    renewPassword: boolean;
    expireModal: boolean;
    todosOfEmployeeLoading: boolean;
    todosLoading: boolean;
    newsLoading: boolean;
    mailsLoading: boolean;
    skillSuggestionsLoading: boolean;
    initialLoaded: {
        dashboardData: boolean;
        skills: boolean;
        companyLocations: boolean;
        branches: boolean;
        jobTemplates: boolean;
    };
    topDrawerOpen: boolean;
    rightDrawerView?: ERightDrawerViews;
    headerBarTitle: string;
    currentRoute?: ERoutes;
    logOutDialogOpen: boolean;
    loginLoading: boolean;
    entityToCreate: undefined | EEntities;
    companiesLoading: boolean;
    employeesLoading: boolean;
    jobTemplatesLoading: boolean;
    jobTitlesLoading: boolean;
    skillTreeLoading: boolean;
    invoicesLoading: boolean;
    companyMetricsDataLoading: boolean;
    invoicesOfRecruitingLoading: boolean;
    invoicesOfCompanyLoading: boolean;
    documentsOfCompanyLoading: boolean;
    documentsOfCandidateLoading: boolean;
    webVacanciesLoading: boolean;
    activitiesLoading: boolean;
    applicationsLoading: boolean;
    vacancySuggestionsLoading: boolean;
    candidateSuggestionsLoading: boolean;
    suggestionListViewLoading: boolean;
    reloadApplications: boolean;
    vacancyRecruitingsLoading: boolean;
    chartSelectionRecruitingsLoading: boolean;
    candidateRecruitingsLoading: boolean;
    companyRecruitingsLoading: boolean;
    companyVacanciesLoading: boolean;
    companyLocationsLoading: boolean;
    companyContactsLoading: boolean;
    openVacanciesLoading: boolean;
    archivedVacanciesLoading: boolean;
    candidatesLoading: boolean;
    tableBodyIsLoading: boolean;
    recruitingsLoading: boolean;
    filterMenuBadgeCount: number;
    clickedRow: {
        [entity: number]: number
    };
    candidatesNewLoading: boolean;
    candidatesOpenApplicationsLoading: boolean;
    candidatesBirthdayLoading: boolean;
    topSuggestionsLoading: boolean;
    recruitingsOfLast7DaysLoading: boolean;
    companyContactsBirthdayLoading: boolean;
    companyContactsWithoutBirthDateLoading: boolean;
    employeeRecruitingsLoading: boolean;
    countUnreadNews: number;
    countUnreadTodos: number;
    countUnreadMails: number;
    saving: boolean;
    activityListViewSorting: ISorting;
    activityListViewOpen: boolean;
    activityListViewFilter: IActivityFilter;
    activityListViewFilterMenu: IActivityFilterMenu;
    expandOrCollapseSuggestionListItems: 'collapse'|'expand';
    expandOrCollapseRecruitingListItems: 'collapse'|'expand';
    expandOrCollapseApplicationListItems: 'collapse'|'expand';
}

export const preloadedStateUi: IUiState = {
    splitCandidateEditView: false,
    showOnlyMyDataActive: true,
    expandOrCollapseSuggestionListItems: 'collapse',
    expandOrCollapseRecruitingListItems: 'collapse',
    expandOrCollapseApplicationListItems: 'expand',
    searchForVacancyId: 0,
    searchForWebVacancyId: 0,
    searchForCandidateId: 0,
    openViews: [],
    topDrawerOpen: false,
    pinnedViews: [],
    filterPanelsOpened: [],
    forceExitFastEdit: false,
    activityListViewFilter: {},
    activityListViewFilterMenu: {},
    activityListViewOpen: false,
    activityListViewSorting: {
        'dateTime': ESortingOptions.Descending
    },
    candidateVacancySearchLoading: false,
    documentsOfCandidateLoading: false,
    documentsOfCompanyLoading: false,
    jobTemplatesLoading: false,
    jobTitlesLoading: false,
    companyMetricsDataLoading: false,
    applicationsLoading: false,
    reloadApplications: false,
    importCandidate: false,
    invoicesOfRecruitingLoading: false,
    invoicesOfCompanyLoading: false,
    openCongratulation: false,
    recruitingsOfLast7DaysLoading: false,
    skillSuggestionsLoading: false,
    suggestionListViewLoading: false,
    suggestSkillsData: {
        open: false,
        title: ''
    },
    invoicesLoading: false,
    openEncouragement: false,
    companyRecruitingsLoading: false,
    addActivityData: {
        add: false
    },
    addCompanyContactData: {
        companyId: 0
    },
    addCompanyLocationData: {
        companyId: 0
    },
    deleteActivityId: 0,
    deleteActivityIsMailAssignment: false,
    editActivityId: 0,
    editCompanyContactId: 0,
    topSuggestionsLoading: false,
    editCompanyLocationId: 0,
    todosOfEmployeeLoading: false,
    todosLoading: false,
    skillTreeLoading: false,
    webVacanciesLoading: false,
    newsLoading: false,
    mailsLoading: false,
    reloadActivityGrids: false,
    renewPassword: false,
    expireModal: false,
    employeesLoading: false,
    chartSelectionRecruitingsLoading: false,
    candidatesNewLoading: false,
    candidatesOpenApplicationsLoading: false,
    filterMenuBadgeCount: 0,
    initialLoaded: {
        dashboardData: false,
        skills: false,
        companyLocations: false,
        branches: false,
        jobTemplates: false
    },
    headerBarTitle: 'brainz',
    logOutDialogOpen: false,
    loginLoading: false,
    entityToCreate: undefined,
    companiesLoading: false,
    companyVacanciesLoading: false,
    activitiesLoading: false,
    companyContactsLoading: false,
    companyLocationsLoading: false,
    openVacanciesLoading: false,
    archivedVacanciesLoading: false,
    candidatesLoading: false,
    tableBodyIsLoading: false,
    recruitingsLoading: false,
    clickedRow: {},
    vacancySuggestionsLoading: false,
    candidateSuggestionsLoading: false,
    vacancyRecruitingsLoading: false,
    candidateRecruitingsLoading: false,
    candidatesBirthdayLoading: false,
    companyContactsBirthdayLoading: false,
    companyContactsWithoutBirthDateLoading: false,
    employeeRecruitingsLoading: false,
    countUnreadNews: 0,
    countUnreadTodos: 0,
    countUnreadMails: 0,
    saving: false
};
