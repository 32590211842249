import React from 'react';
import { IMail, ISkillTreeNode } from '../../../../definitions/entities.definition';
import styles from './MailElement.module.css';
import { getFormattedTimeString } from '../../../../utils/date.utils';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { markMailAsReadAction } from '../../../../redux/entities/entities.actions';
import { useDrag } from 'react-dnd';
import { ClientApi } from '../../../../requests/ClientApi';
import { putMoveMailRouteConfig } from '../../../../requests/routes';

interface IProps {
    mail: IMail
    openMail: (mailId: number) => void;
    reloadFolders: () => void;
}

const MailElement: React.FC<IProps> = (props) => {
    const mail = props.mail;
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const getFromString = (mail: IMail) => {
        if (mail.candidate) {
            return (
                <div style={{display: "flex", verticalAlign: "middle", alignItems: "center"}}>
                    {IconFactory.getIconComponent(EIcons.People)}&nbsp;
                    {mail.candidate.fullName}
                </div>
            );
        }

        if (mail.company) {
            return (
                <>
                    <div className={"flex flexCentered"}>
                        {IconFactory.getIconComponent(EIcons.Apartment)}&nbsp;
                        {mail.company.name}
                    </div>
                    { mail.companyContact &&
                        <div className={"flex flexCentered"} style={{marginTop: -5}}>
                            <br/>{mail.companyContact.fullName}
                        </div>
                    }
                </>
            );
        }

        return '';
    };

    const onClick = () => {
        props.openMail(mail.id);
        if (!mail.read) {
            dispatch(markMailAsReadAction(token, mail.id, props.reloadFolders));
        }
    }

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'mail',
        item: {
            mail: props.mail
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (!dropResult) {
                return;
            }

            ClientApi.request(putMoveMailRouteConfig, {
                token,
                id: item.mail.id,
                //@ts-ignore-next-line
                folderId: dropResult.folderId
            }).then((result: {tree: ISkillTreeNode[]}) => {
                console.log("JO");
            });
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [props.mail]);

    return (
        <>
            <div
                ref={drag}
                role="mail"
                className={clsx(styles.root, {
                [styles.rootUnassigned]: !mail.assigned,
                [styles.dragging]: isDragging
            })}>
                <div className={clsx({
                    [styles.rootUnread]: !mail.read
                })} onClick={onClick}>
                    <div className={styles.header}>
                        <div className={styles.headerTitle}>
                            {getFromString(mail)}
                        </div>
                        <div className={styles.headerDate}>
                            {mail.hasAttachments && IconFactory.getIconComponent(EIcons.Attachment)}&nbsp;
                            {getFormattedTimeString(mail.dateTime)}
                        </div>
                    </div>
                    <div className={styles.innerRoot}>
                        {mail.subject}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MailElement;
