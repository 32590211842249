import React from 'react';
import { translate } from '../../../translation/translate.utils';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageBurnUpSettingsAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import { userHasRole } from '../../../selectors/employee.selectors';
import { ROLE_ADMIN } from '../../../definitions/actor.definitions';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';

const GoalBurnUpChartFilterButtons: React.FC = () => {
    const showLastYear = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings.showLastYear);
    const showTwoYearsAgo = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings.showTwoYearsAgo);
    const showGoalByCommission = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings.byCommission);
    const goalBurnupChartSettings = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings);
    const dispatch = useDispatch();
    const userIsAdmin = userHasRole(ROLE_ADMIN);

    const toggleShowLastYear = () => {
        dispatch(setDashboardPageBurnUpSettingsAction({
            ...goalBurnupChartSettings,
            showLastYear: !showLastYear
        }))
    }

    const toggleByCommission = () => {
        dispatch(setDashboardPageBurnUpSettingsAction({
            ...goalBurnupChartSettings,
            byCommission: !showGoalByCommission
        }))
    }

    return <>
        <div className={"flexContainerRow alignHorizontalRight flexVerticalCentered"} style={{marginTop: 5}}>
            <CustomCheckboxInput
                checked={showLastYear}
                onChange={toggleShowLastYear}
                label={((new Date()).getFullYear() - 1)}
            />
            { userIsAdmin &&
                <CustomCheckboxInput
                    checked={showGoalByCommission}
                    onChange={toggleByCommission}
                    label={translate('pages.dashboard.byCommission')}
                />
            }
        </div>
    </>;
}

export default GoalBurnUpChartFilterButtons;
