import { IStore } from '../definitions/redux/store.definitions';
import { ICompanyContact } from '../definitions/entities.definition';

export const getCompanyContactsForCompany = (store: IStore, companyId?: number) => {
    if (!companyId) {
        return [];
    }

    const contactsByCompany = store.entities.companyContacts.byCompany[companyId];
    const contacts = store.entities.companyContacts.byId;

    const companyContacts: ICompanyContact[] = [];

    if (!contactsByCompany) {
        return [];
    }

    contactsByCompany.forEach((companyId) => companyContacts.push(contacts[companyId]));

    return companyContacts;
};

export const getCompanyContactsWithBirthdayFromStore = (store: IStore): ICompanyContact[] => {
    const order = store.entities.companyContacts.orderBirthday;
    const candidatesById = store.entities.companyContacts.byId;

    return order.reduce((accumulator: ICompanyContact[], candidateId) => {
        accumulator.push(candidatesById[candidateId]);

        return accumulator;
    }, []);
};

export const getCompanyContactsWithoutBirthdateFromStore = (store: IStore): ICompanyContact[] => {
    const order = store.entities.companyContacts.orderNoBirthdate;
    const contactsById = store.entities.companyContacts.byId;

    return order.reduce((accumulator: ICompanyContact[], contactId) => {
        if (!contactsById[contactId].birthDate) {
            accumulator.push(contactsById[contactId]);
        }

        return accumulator;
    }, []);
};

export const getCompanyContactsForDropdown = (store: IStore, companyId?: number) => {
    if (!companyId) {
        return [];
    }

    const contactsByCompany = store.entities.companyContacts.byCompanyForDropdown[companyId];

    if (!contactsByCompany) {
        return [];
    }

    return contactsByCompany;
};
