import React from 'react';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    EEntities,
    IActivity,
    IEmployee,
    TActivityType,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import ActivityDescriptionCellRenderer from '../../../components/CustomCellRenderer/ActivityDescriptionCellRenderer';
import { setVacancyPageActivitySortingOptionAction } from '../../../redux/vacancyPage/vacancyPage.actions';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import {
    getActivitiesForApplicationsFromStore,
    getApplicationPageActivitiesRequestObject,
} from '../../../selectors/activity.selectors';
import { isActivityDeleteAble } from '../../../utils/activity.utils';
import { setUiActivityToDeleteAction, setUiActivityToEditAction } from '../../../redux/ui/ui.actions';
import { EIcons } from '../../../components/Icons/IconFactory';
import { getActivityListForApplicationAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ESortingOptions } from '../../../definitions/components.definitions';
import ActivityTypeCellRenderer from '../../../components/CustomCellRenderer/ActivityTypeCellRenderer';
import CustomTable from '../../../components/CustomTable/CustomTable';
import DateTimeCellRenderer from '../../../components/CustomCellRenderer/DateTimeCellRenderer';
import { getToken } from '../../../selectors/app.selectors';
import { themeOrange } from '../../../theme/theme';

const ApplicationActivityList: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const activities = useSelector(getActivitiesForApplicationsFromStore);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const activityRequestObject = useSelector(getApplicationPageActivitiesRequestObject);
    const currentApplicationId = useSelector((state: IStore) => state.applicationPage.applicationToEdit);
    const total = useSelector((store: IStore) => store.entities.activities.totalByApplication[currentApplicationId] || 0);

    return (
        <CustomTable
            style={{margin: 10}}
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.SuggestionActivity,
                loading,
                columnConfig: {
                    type: {
                        header: translate('pages.activity.properties.type'),
                        property: "type",
                        width: 60,
                        cellRenderer: (type: TActivityType) => <ActivityTypeCellRenderer type={type} />,
                    },
                    dateTime: {
                        header: translate('pages.activity.properties.dateTime'),
                        property: "dateTime",
                        width: 170,
                        cellRenderer: (dateTime: string) => <DateTimeCellRenderer date={dateTime} />,
                    },
                    userInput: {
                        header: translate('pages.activity.properties.description'),
                        property: "userInput",
                        flex: 2,
                        cellRenderer: (value: string, activity: TBrainzEntity) => {
                            const activityCast = activity as IActivity;
                            return <ActivityDescriptionCellRenderer activity={activityCast} />;
                        },
                    },
                    employee: {
                        header: translate('misc.employeeShort'),
                        property: "employee",
                        width: 60,
                        cellRenderer: (employee: IEmployee) => {
                            return <EmployeeCellRenderer employee={employee} />;
                        },
                    }
                },
                sortedEntries: activities,
                onRowDoubleClick: (activityId: number) => dispatch(setUiActivityToEditAction(activityId)),
                listActionButtons: [{
                    action: (activityId: number) => dispatch(setUiActivityToDeleteAction(activityId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                    condition: (activity: TBrainzEntity) => {
                        const activityCast = activity as IActivity;
                        return isActivityDeleteAble(activityCast);
                    }
                }],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setVacancyPageActivitySortingOptionAction(propertyToSort, sortOption)),
                },
                count: activities.length,
                total,
                onReload: () => {
                    dispatch(getActivityListForApplicationAction(
                        token,
                        currentApplicationId,
                        activityRequestObject
                    ));
                }
            }}
        />
    );
};

export default ApplicationActivityList;
