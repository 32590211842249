import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../selectors/app.selectors';
import { updateVacancyAction } from '../../redux/entities/entities.actions';
import { setVacancyPageVacancyToArchiveAction } from '../../redux/vacancyPage/vacancyPage.actions';

const VacancyArchiveView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.vacancyToArchive));
    const idToArchive = useSelector((store: IStore) => store.vacancyPage.vacancyToArchive);
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            deleteTranslation={'pages.vacancy.archiveDialog.title'}
            onClose={() => dispatch(setVacancyPageVacancyToArchiveAction(0))}
            onDelete={() => {
                dispatch(updateVacancyAction(
                    token,
                    {
                        id: idToArchive,
                        completed: true
                    }
                ))
                dispatch(setVacancyPageVacancyToArchiveAction(0));
            }}
            titleTranslation={"pages.vacancy.archiveDialog.title"}
            bodyTranslation={"pages.vacancy.archiveDialog.body"}
        />
    );
};

export default VacancyArchiveView;
