import React, { useCallback } from 'react';
import DateRangeModeDropDown from '../../CustomInput/CustomDropDownInput/DateRangeModeDropDown';
import {
    EDateRangeMode,
    IDateRangeFilterApiProperties,
    IDateRangeFilterFieldConfig,
} from '../../../definitions/filter.definitions';
import CustomDatePicker from '../../CustomInput/CustomDatePicker';
import CustomNumberInput from '../../CustomInput/CustomNumberInput';
import PaperRow from '../../CustomPaper/PaperRow';
import DateRangeFieldDropDown from './DateRangeFieldDropDown';
import moment from 'moment';

interface IProps {
    filter: IDateRangeFilterApiProperties;
    setFilter: (filter: IDateRangeFilterApiProperties) => void;
    filterByFields?: IDateRangeFilterFieldConfig[];
    label?: string;
}

const CustomDateRangeForm: React.FC<IProps> = (props) => {
    const filter = props.filter;
    const filterByFields = props.filterByFields;
    const mode = filter.mode;
    const year = filter.year;
    const dateFrom = filter.dateFrom ? new Date(filter.dateFrom) : undefined;
    const dateTo = filter.dateTo ? new Date(filter.dateTo) : undefined;
    const lastCount = filter.lastDateRangeCount;
    const property = filter.property
    const label = props.label;

    const onChangeRangeMode = (mode: EDateRangeMode) => {
        const newFilter: IDateRangeFilterApiProperties = {
            ...filter,
            mode,
            lastDateRangeCount: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            year: undefined
        }

        if (mode === EDateRangeMode.lastDays) {
            newFilter.lastDateRangeCount = 7;
        }

        if (mode === EDateRangeMode.lastWeeks) {
            newFilter.lastDateRangeCount = 2;
        }

        if (mode === EDateRangeMode.lastMonth) {
            newFilter.lastDateRangeCount = 3;
        }

        if (mode === EDateRangeMode.year) {
            newFilter.year = (new Date()).getFullYear();
        }

        props.setFilter(newFilter);
    }

    const setDateFrom = (dateFrom: Date|undefined) => {
        props.setFilter({
            ...filter,
            dateFrom: dateFrom ? moment(dateFrom).format("Y-MM-DD"): undefined
        });
    }

    const setDateTo = (dateTo: Date|undefined) => {
        props.setFilter({
            ...filter,
            dateTo: dateTo ? moment(dateTo).format("Y-MM-DD"): undefined
        });
    }

    const setLastCount = (lastDateRangeCount: number|undefined) => {
        props.setFilter({
            ...filter,
            lastDateRangeCount
        });
    }

    const setYear = (year: number|undefined) => {
        props.setFilter({
            ...filter,
            year
        });
    }

    const setProperty = (property: string) => {
        props.setFilter({
            ...filter,
            property
        });
    }

    const getFromToComponent = useCallback(() => {
        if (mode !== EDateRangeMode.range) {
            return null;
        }

        return <>
            <PaperRow>
                <CustomDatePicker
                    label={"Von"}
                    onChange={setDateFrom}
                    value={dateFrom}
                />
                <CustomDatePicker
                    label={"Bis"}
                    onChange={setDateTo}
                    value={dateTo}
                />
            </PaperRow>
        </>
    }, [mode, dateFrom, dateTo]);

    const getLastCountOrYearComponent = useCallback(() => {
        if (mode === EDateRangeMode.all || mode == EDateRangeMode.range) {
            return null;
        }

        if (mode === EDateRangeMode.year) {
            return <>
                <div className={"flexUnConfigured"} style={{width: 130}}>
                    <CustomNumberInput value={year} onChange={setYear} label={"Jahr"} />
                </div>
            </>
        }

        const lastCountLabel = mode === EDateRangeMode.lastMonth ? 'Monate' : ((mode === EDateRangeMode.lastWeeks) ? 'Wochen' : 'Tage');

        return <>
            <div className={"flexUnConfigured"} style={{width: 130}}>
                <CustomNumberInput value={lastCount} onChange={setLastCount} label={"Anzahl " + lastCountLabel} />
            </div>
        </>
    }, [mode, lastCount, dateFrom, dateTo, year]);

    return <>
        <>
            <PaperRow>
                <DateRangeModeDropDown
                    label={label}
                    value={mode}
                    onChange={onChangeRangeMode}
                />
                {getLastCountOrYearComponent()}
            </PaperRow>
            {getFromToComponent()}
            {(filterByFields && filterByFields.length > 1 && mode !== EDateRangeMode.all) &&
                <PaperRow>
                    <DateRangeFieldDropDown
                        value={property}
                        onChange={setProperty}
                        values={filterByFields}
                    />
                </PaperRow>
            }
        </>
    </>;
};

export default React.memo(CustomDateRangeForm);
