import React, { PropsWithChildren } from 'react';
import styles from './CustomSidePanelList.module.css';
import { Collapse, ListItem, ListItemSecondaryAction, ListSubheader } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';

interface IProps extends PropsWithChildren<any> {
    header: string;
}

const CustomListSection: React.FC<IProps> = (props) => {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return <>
        <ListItem button className={styles.subHeaderContainer} onClick={handleClick}>
            <ListSubheader component="div" id="nested-list-subheader" className={styles.subHeader}>
                { props.header }
            </ListSubheader>
            <ListItemSecondaryAction>
                {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            {props.children}
        </Collapse>
    </>
}

export default React.memo(CustomListSection);
