import {
    IGetCandidateAction,
    IGetCandidateBirthdayWishMissingAction,
    IPostCandidateAction,
    IPutCandidateAction,
    ISetCandidateEditViewActiveTabAction,
    ISetCandidatePageAddDialogAction,
    ISetCandidatePageCandidateToDeleteAction,
    ISetCandidatePageCreateBirthdayMailAction,
    ISetCandidatePageCvResultToImportAction,
    ISetCandidatePageDocumentSortingOptionAction,
    ISetCandidatePageDocumentToEditAction,
    ISetCandidatePageEditViewActivityFastSearchAction,
    ISetCandidatePageEditViewActivitySortingOptionAction,
    ISetCandidatePageFastSearchAction,
    ISetCandidatePageFilterAction,
    ISetCandidatePageFilterMenuAction,
    ISetCandidatePageRecruitingListFilterAction,
    ISetCandidatePageRecruitingListFilterMenuAction,
    ISetCandidatePageRecruitingSortingOptionAction,
    ISetCandidatePageSortingOptionAction,
    ISetCandidatePageToggleFieldForIgnoreValidation,
    ISetCandidatePageVacancySearchFilterAction,
    ISetCandidatePageVacancySearchFilterMenuAction,
    ISetCandidatePageVacancySearchSortingOptionAction,
    ISetCloseViewAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import { ICandidatePageState, preloadedStateCandidatePage } from '../../definitions/candidatePage.definitions';
import {
    CANDIDATE_PAGE_RESET_FIELDS_FOR_IGNORE_VALIDATION,
    CANDIDATE_PAGE_RESET_FILTER,
    CANDIDATE_PAGE_RESET_VACANCY_SEARCH_FILTER,
    CANDIDATE_PAGE_SET_ACTIVE_TAB_OF_EDIT_CANDIDATE_VIEW,
    CANDIDATE_PAGE_SET_ADD_DIALOG,
    CANDIDATE_PAGE_SET_CANDIDATE_TO_DELETE,
    CANDIDATE_PAGE_SET_CREATE_BIRTHDAY_MAIL,
    CANDIDATE_PAGE_SET_CV_RESULT_TO_IMPORT,
    CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER,
    CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    CANDIDATE_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    CANDIDATE_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION,
    CANDIDATE_PAGE_SET_FAST_SEARCH,
    CANDIDATE_PAGE_SET_FILTER,
    CANDIDATE_PAGE_SET_FILTER_MENU,
    CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER,
    CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER_MENU,
    CANDIDATE_PAGE_SET_RECRUITING_LIST_SORTING,
    CANDIDATE_PAGE_SET_SORTING_OPTION,
    CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER,
    CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER_MENU,
    CANDIDATE_PAGE_SET_VACANCY_SEARCH_SORTING,
    CANDIDATE_PAGE_TOGGLE_FIELD_FOR_IGNORE_VALIDATION,
} from './candidatePage.actions';
import {
    ENTITIES_DELETE_CANDIDATE_FULFILLED,
    ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_FULFILLED,
    ENTITIES_GET_CANDIDATE_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_FULFILLED,
    ENTITIES_GET_CANDIDATE_LIST_PENDING,
    ENTITIES_GET_CANDIDATE_PENDING,
    ENTITIES_POST_CANDIDATE_FULFILLED,
    ENTITIES_POST_CANDIDATE_PENDING,
    ENTITIES_POST_CANDIDATE_REJECTED,
    ENTITIES_PUT_CANDIDATE_FULFILLED,
    ENTITIES_PUT_CANDIDATE_PENDING,
    ENTITIES_PUT_CANDIDATE_REJECTED,
} from '../entities/entities.actions';
import { ESortingOptions } from '../../definitions/components.definitions';
import { UI_SET_CLOSE_VIEW, UI_SET_SHOW_ONLY_MINE } from '../ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

const candidatePageReducer = (state = preloadedStateCandidatePage, action: TReducerAction): ICandidatePageState => {
    switch (action.type) {
        case CANDIDATE_PAGE_RESET_FIELDS_FOR_IGNORE_VALIDATION: {
            return {
                ...state,
                formFieldsIgnoredForValidation: []
            };
        }
        case UI_SET_SHOW_ONLY_MINE: {
            return {
                ...state,
                reloadList: true
            }
        }
        case UI_SET_CLOSE_VIEW: {
            const actionCast = action as ISetCloseViewAction;

            if (actionCast.payload.view === EEntityView.candidate) {
                return {
                    ...state,
                    activityFilter: preloadedStateCandidatePage.activityFilter
                }
            }

            return {
                ...state
            }
        }
        case CANDIDATE_PAGE_TOGGLE_FIELD_FOR_IGNORE_VALIDATION: {
            const actionCast = action as ISetCandidatePageToggleFieldForIgnoreValidation;

            if (state.formFieldsIgnoredForValidation.includes(actionCast.payload)) {
                return {
                    ...state,
                    formFieldsIgnoredForValidation: state.formFieldsIgnoredForValidation.filter((field) => field !== actionCast.payload)
                };
            }

            return {
                ...state,
                formFieldsIgnoredForValidation: [
                    ...state.formFieldsIgnoredForValidation,
                    actionCast.payload
                ]
            };
        }

        case CANDIDATE_PAGE_SET_VACANCY_SEARCH_SORTING: {
            const actionCast = action as ISetCandidatePageVacancySearchSortingOptionAction;
            return {
                ...state,
                sortingVacancyList: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }

        case CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER: {
            const actionCast = action as ISetCandidatePageVacancySearchFilterAction;

            return {
                ...state,
                filterVacancyList: {
                    ...state.filterVacancyList,
                    ...actionCast.payload
                }
            }
        }
        case CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER_MENU: {
            const actionCast = action as ISetCandidatePageVacancySearchFilterMenuAction;

            return {
                ...state,
                filterMenuVacancyList: {
                    ...state.filterMenuVacancyList,
                    ...actionCast.payload
                }
            }
        }

        case CANDIDATE_PAGE_RESET_VACANCY_SEARCH_FILTER: {
            return {
                ...state,
                filterVacancyList: {
                    searchForCandidateId: state.filterVacancyList.searchForCandidateId,
                    start: 0,
                    limit: 50,
                    distance: 30,
                    showHidden: false,
                    skillIds: []
                },
                filterMenuVacancyList: {
                    distance: 30,
                    showHidden: false,
                    skills: []
                }
            };
        }

        case CANDIDATE_PAGE_SET_CV_RESULT_TO_IMPORT: {
            const actionCast = action as ISetCandidatePageCvResultToImportAction;

            return {
                ...state,
                importFromCvParser: actionCast.payload?.cvParserResult,
                importCvFile: actionCast.payload?.cvDocument
            }
        }
        case CANDIDATE_PAGE_SET_CREATE_BIRTHDAY_MAIL: {
            const actionCast = action as ISetCandidatePageCreateBirthdayMailAction;

            return {
                ...state,
                createBirthdayMailForCandidate: actionCast.payload
            }
        }
        case CANDIDATE_PAGE_SET_ACTIVE_TAB_OF_EDIT_CANDIDATE_VIEW: {
            const actionCast = action as ISetCandidateEditViewActiveTabAction;

            return {
                ...state,
                activeTabCandidateEditView: actionCast.payload
            }
        }
        case CANDIDATE_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetCandidatePageSortingOptionAction;
            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case CANDIDATE_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetCandidatePageFastSearchAction;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case CANDIDATE_PAGE_RESET_FILTER: {
            return {
                ...state,
                filter: preloadedStateCandidatePage.filter,
                filterMenu: preloadedStateCandidatePage.filterMenu,
            };
        }
        case CANDIDATE_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetCandidatePageFilterMenuAction;

            return {
                ...state,
                filterMenu: {
                    ...state.filterMenu,
                    ...actionCast.payload,
                },
            };
        }
        case ENTITIES_GET_CANDIDATE_BIRTHDAY_WISH_MISSING_FULFILLED: {
            const actionCast = action as IGetCandidateBirthdayWishMissingAction;

            return {
                ...state,
                birthdayWishMissing: actionCast.payload
            };
        }
        case CANDIDATE_PAGE_SET_FILTER: {
            const actionCast = action as ISetCandidatePageFilterAction;

            if (actionCast.payload.sorting) {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        ...actionCast.payload.filter,
                    },
                    sorting: actionCast.payload.sorting
                };
            }

            const newState = {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload.filter,
                }
            };

            return newState;
        }
        case CANDIDATE_PAGE_SET_ADD_DIALOG: {
            const actionCast = action as ISetCandidatePageAddDialogAction;
            return {
                ...state,
                addDialogOpen: actionCast.payload,
            };
        }
        case ENTITIES_POST_CANDIDATE_FULFILLED: {
            const actionCast = action as IPostCandidateAction;

            return {
                ...state,
                editViewLoading: false,
                addDialogOpen: false,
                importCvFile: undefined,
                importFromCvParser: undefined,
                filterVacancyList: {
                    searchForCandidateId: actionCast.payload.id,
                    distance: 30,
                    start: 0,
                    limit: 50
                }
            };
        }
        case ENTITIES_PUT_CANDIDATE_FULFILLED: {
            const actionCast = action as IPutCandidateAction;

            return {
                ...state,
                editViewLoading: false,
                currentDocumentId: 0,
                birthdayWishMissing: false
            };
        }
        case CANDIDATE_PAGE_SET_CANDIDATE_TO_DELETE: {
            const actionCast = action as ISetCandidatePageCandidateToDeleteAction;
            return {
                ...state,
                candidateToDelete: actionCast.payload,
            };
        }
        case ENTITIES_DELETE_CANDIDATE_FULFILLED: {
            return {
                ...state,
                candidateToDelete: 0,
            };
        }
        case ENTITIES_GET_CANDIDATE_LIST_PENDING: {
            return {
                ...state,
                reloadList: false
            }
        }
        case ENTITIES_GET_CANDIDATE_LIST_FULFILLED: {
            return {
                ...state,
                initialCandidatesLoaded: true
            };
        }

        /**
         * DATENSATZ ZUM BEARBEITEN LADEN JA/NEIN
         */
        case ENTITIES_POST_CANDIDATE_PENDING:
        case ENTITIES_PUT_CANDIDATE_PENDING:
        case ENTITIES_GET_CANDIDATE_PENDING: {
            return {
                ...state,
                editViewLoading: true,
            };
        }
        case ENTITIES_POST_CANDIDATE_REJECTED:
        case ENTITIES_PUT_CANDIDATE_REJECTED:
        case ENTITIES_GET_CANDIDATE_FULFILLED: {
            const actionCast = action as IGetCandidateAction;

            return {
                ...state,
                editViewLoading: false,
                filterVacancyList: {
                    ...state.filterVacancyList,
                    searchForCandidateId: actionCast.payload.id,
                    distance: 30,
                    start: 0,
                    limit: 50
                },
                filterMenuVacancyList: {
                    ...state.filterMenuVacancyList,
                    distance: 30
                },
                sortingVacancyList: {
                    'lastActivity': ESortingOptions.Descending
                }
            };
        }

        /**
         * RECRUITINGS
         */
        case CANDIDATE_PAGE_SET_RECRUITING_LIST_SORTING: {
            const actionCast = action as ISetCandidatePageRecruitingSortingOptionAction;
            return {
                ...state,
                recruitingSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }

        case CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER: {
            const actionCast = action as ISetCandidatePageRecruitingListFilterAction;

            return {
                ...state,
                filterRecruitingList: {
                    ...state.filterRecruitingList,
                    ...actionCast.payload
                }
            }
        }
        case CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER_MENU: {
            const actionCast = action as ISetCandidatePageRecruitingListFilterMenuAction;

            return {
                ...state,
                filterMenuRecruitingList: {
                    ...state.filterMenuRecruitingList,
                    ...actionCast.payload
                }
            }
        }

        /**
         * ACTIVITY
         */
        case CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION: {
            const actionCast = action as ISetCandidatePageEditViewActivitySortingOptionAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER: {
            const actionCast = action as ISetCandidatePageEditViewActivityFastSearchAction;
            return {
                ...state,
                activityFilter: {
                    ...state.activityFilter,
                    ...actionCast.payload
                }
            };
        }

        /**
         * DOCUMENTS
         */
        case CANDIDATE_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION: {
            const actionCast = action as ISetCandidatePageDocumentSortingOptionAction;
            return {
                ...state,
                documentSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case CANDIDATE_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT: {
            const actionCast = action as ISetCandidatePageDocumentToEditAction;
            return {
                ...state,
                currentDocumentId: actionCast.payload,
            };
        }
        default:
            break;
    }

    return state;
};

export default candidatePageReducer;
