import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { resetFormValidation } from '../../../redux/error/error.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { setTodoAddDialogOpenAction, setTodoToEditAction } from '../../../redux/todo/todo.actions';
import TodoEditViewBody from './TodoEditViewBody';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { getTodoAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import CustomCellTopBar from '../../../components/ListAndPreview/CustomCellTopBar';

const TodoEditView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const editTodo = useSelector((state: IStore) => Boolean(state.todo.todoToEdit));
    const editTodoId = useSelector((state: IStore) => state.todo.todoToEdit);
    const todo = useSelector((state: IStore) => state.entities.todo.currentRecord);

    useEffect(() => {
        if (editTodo && !todo) {
            dispatch(getTodoAction(
                token,
                editTodoId
            ))
        }
    }, [editTodo, todo]);

    if (!editTodo) {
        return null;
    }

    const onClose = () => {
        dispatch(setTodoToEditAction(0));
        dispatch(setTodoAddDialogOpenAction(false));
        dispatch(resetFormValidation());
    };

    if (!todo) {
        return (
            <CustomBackdropLoading open={true}/>
        );
    }

    return (
        <CustomDialog  maxWidth={"md"} onClose={onClose} open={true}>
            <CustomDialogHeader translationString={'pages.todo.edit'} onClose={onClose} >
                <CustomCellTopBar
                    candidate={todo.candidate}
                    company={todo.company}
                    vacancy={todo.vacancy}
                    employee={todo.employee}
                    recruiting={todo.recruiting}
                />
            </CustomDialogHeader>
            <TodoEditViewBody todo={todo} onClose={onClose}/>
        </CustomDialog>
    );
}
export default TodoEditView;

