import React from 'react';
import { useWebVacancyActivityListHook } from './WebVacancyActivityList.hooks';
import RenderActivityListItem from '../../RenderActivityListItem';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    webVacancyId: number;
}

const WebVacancyActivityList: React.FC<IProps> = (props) => {
    const candidateId = props.webVacancyId;
    const { loading, activities } = useWebVacancyActivityListHook(candidateId);

    return <>
        <div className={"overflow flex1 marginTop10 flexContainerColumn gap5"}>
            <CustomBackdropLoadingInsideDiv open={loading} />
            {activities.map((activity) => <RenderActivityListItem key={activity.id} activity={activity} />)}
        </div>
    </>;
};

export default WebVacancyActivityList;
