import React, { useEffect, useState } from 'react';
import CustomInfo from './CustomInfo';
import { translate } from '../../translation/translate.utils';
import { useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getValidationErrorsFromStore } from '../../selectors/app.selectors';

interface IProps {
    translationKey: string;
}

const CustomValidationErrors: React.FC<IProps> = (props) => {
    const translationKey = props.translationKey;
    const hasErrors = useSelector((store: IStore) => Object.keys(store.error.validation).length > 0);
    const errors = useSelector(getValidationErrorsFromStore);
    const [hideErrors, setHideErrors] = useState(false);

    useEffect(() => {
        setHideErrors(false);
    }, [JSON.stringify(errors)])

    if (!hasErrors || hideErrors) {
        return null;
    }

    return <>
        <div className={"flexContainerColumn padding5"}>
            <CustomInfo buttonConfig={{
                action: () => setHideErrors(true),
                text: 'Ausblenden'
            }}>
                <h4 style={{margin:0}}>
                    {translate('misc.formHasErrors')}
                </h4>
                {
                    Object.entries(errors).map((error) => {
                        const field = error[0];
                        let fieldTranslation = translate(translationKey + '.' + field);
                        if (fieldTranslation === translationKey + '.' + field) {
                            fieldTranslation = translate(translationKey + '.properties.' + field);
                            if (fieldTranslation === translationKey + '.properties.' + field) {
                                fieldTranslation = translate('misc.' + field);
                            }
                        }

                        const errorCleaned = error[1].replaceAll('.', '').replaceAll(' ', '_').toLowerCase();
                        return <div key={error[0]}>
                            {fieldTranslation}
                            -
                            {translate('error.' + errorCleaned)}
                        </div>
                    })
                }
            </CustomInfo>
        </div>
    </>
}

export default CustomValidationErrors;
