import { IStore } from '../definitions/redux/store.definitions';
import { IVacancyListModel } from '../definitions/entities.definition';

export const getCompanyVacanciesForCompany = (store: IStore, companyId: number) => {
    const vacanciesByCompany = store.entities.vacancies.byCompany[companyId];
    const vacancies = store.entities.vacancies.listModelById;

    const companyVacancies: IVacancyListModel[] = [];

    if (!vacanciesByCompany) {
        return [];
    }

    vacanciesByCompany.forEach((companyId) => {
        if (vacancies[companyId]) {
            companyVacancies.push(vacancies[companyId])
        }
    });

    return companyVacancies;
};
