import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { translate } from '../../../../translation/translate.utils';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import CustomNumberInput from '../../../CustomInput/CustomNumberInput';
import {
    resetSkillPageFilterAction,
    setSkillPageFilterAction,
    setSkillPageFilterMenuAction,
} from '../../../../redux/skillPage/skillPage.actions';
import ValueDropDown from '../../../CustomInput/CustomDropDownInput/ValueDropDown';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuSkillTree: React.FC<IProps> = (props) => {
    const filterMenu = useSelector((store: IStore) => store.skillPage.filterMenu);
    const styles = useStyles();
    const dispatch = useDispatch();

    const submit = () => {
        dispatch(
            setSkillPageFilterAction({
                usedCountFrom: filterMenu.usageFilter === 'range' ? filterMenu.usedCountFrom : undefined,
                usedCountTo: filterMenu.usageFilter === 'range' ? filterMenu.usedCountTo : undefined,
                onlyWithUsages: filterMenu.usageFilter === 'with',
                onlyWithoutUsages: filterMenu.usageFilter === 'without',
                onlyWithKeywords: filterMenu.keywordsFilter === 'with',
                onlyWithoutKeywords: filterMenu.keywordsFilter === 'without'
            })
        );
        props.removeAnchor();
    };

    const resetStates = () => {
        dispatch(resetSkillPageFilterAction());
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton color="secondary" onClick={resetStates} label={translate("misc.buttons.clearFilters")} />
            </MenuItem>
            <MenuItem>
                <ValueDropDown
                    onChange={(value) =>
                        dispatch(
                            setSkillPageFilterMenuAction({
                                usageFilter: value as 'with'|'without'|'range'
                            })
                        )
                    }
                    value={filterMenu.usageFilter}
                    values={[{
                        value: 'with',
                        label: 'Nur mit Zuweisung/en anzeigen'
                    }, {
                        value: 'without',
                        label: 'Nur ohne Zuweisung/en anzeigen'
                    }, {
                        value: 'range',
                        label: 'Nur Zuweisungen in Bereich anzeigen'
                    }]}
                    label={'Filterung nach Zuweisung'}
                    allowEmpty
                />
            </MenuItem>
            { filterMenu.usageFilter === 'range' &&
                <MenuItem>
                    <CustomNumberInput
                        value={filterMenu.usedCountFrom}
                        onChange={(usedCountFrom) =>
                            dispatch(
                                setSkillPageFilterMenuAction({
                                    usedCountFrom
                                })
                            )
                        }
                        label={translate('pages.skill.usedCountFrom')}
                        max={999}
                    />
                    <CustomNumberInput
                        value={filterMenu.usedCountTo}
                        onChange={(usedCountTo) =>
                            dispatch(
                                setSkillPageFilterMenuAction({
                                    usedCountTo
                                })
                            )
                        }
                        label={translate('pages.skill.usedCountTo')}
                        max={999}
                    />
                </MenuItem>
            }
            <MenuItem>
                <ValueDropDown
                    onChange={(value) =>
                        dispatch(
                            setSkillPageFilterMenuAction({
                                keywordsFilter: value as 'with'|'without'
                            })
                        )
                    }
                    value={filterMenu.keywordsFilter}
                    values={[{
                        value: 'with',
                        label: 'Nur mit Keywords anzeigen'
                    }, {
                        value: 'without',
                        label: 'Nur ohne Keywords anzeigen'
                    }]}
                    label={'Filterung nach Keywords'}
                    allowEmpty
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuSkillTree;
