import React from 'react';
import { ISkill, ISkillPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import SkillMultipleAutocomplete from '../components/SkillMultipleAutocomplete';

interface IProps {
    onChange: (value: ISkillPartial[]) => void;
    value?: ISkillPartial[];
    addEnabled?: boolean;
    label?: string;
    skills?: ISkill[];
    autoFocus?: boolean;
}

const LegacySkillAutocompleteMultipleDropDown: React.FC<IProps> = (props) => {
    return <SkillMultipleAutocomplete
        autoFocus={props.autoFocus}
        label={props.label ? props.label : translate('pages.candidate.properties.skills')}
        value={props.value || []}
        onChange={props.onChange}
    />;
};

export default LegacySkillAutocompleteMultipleDropDown;
