import React from 'react';
import { IEmployeePartial } from '../../../../definitions/entities.definition';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import EmployeeAutocomplete from '../../../CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';

interface IProps {
    value: null|IEmployeePartial;
    onChange: (value: null|IEmployeePartial) => void;
}

const EmployeeFilter: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value: null|IEmployeePartial) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange(null);
    }

    const deletable = Boolean(value);
    const valueRendered = (deletable) ? value?.fullName : null;

    return <>
        <CustomFilterItem
            title={translate('pages.vacancy.properties.responsibleEmployee')}
            icon={EIcons.People}
            renderedValue={valueRendered}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
            autoClose
        >
            <EmployeeAutocomplete
                autoFocus
                label={translate('pages.vacancy.properties.responsibleEmployee')}
                value={value}
                onChange={(value: null|IEmployeePartial) => onChange(value)}
            />
        </CustomFilterItem>
    </>;
};

export default React.memo(EmployeeFilter);
