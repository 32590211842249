import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import ActivityForm from './ActivityForm';
import { setUiActivityAddDataAction, setUiActivityToEditAction } from '../../../redux/ui/ui.actions';
import { resetFormValidation } from '../../../redux/error/error.actions';
import InterviewForm from './InterviewForm';
import { EActivityAction, EActivityType } from '../../../definitions/entities.definition';
import FeedbackForm from './FeedbackForm';
import CustomCellTopBar from '../../../components/ListAndPreview/CustomCellTopBar';
import { getActivityAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import ActivityDisplaySystemMail from './ActivityDisplaySystemMail';
import ActivityDisplayMailboxMail from './ActivityDisplayMailboxMail';
import { setMailingDetailViewClosedAction } from '../../../redux/mailing/mailing.actions';

const ActivityAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const addActivity = useSelector((state: IStore) => state.ui.addActivityData.add);
    const editActivity = useSelector((state: IStore) => Boolean(state.ui.editActivityId));
    const addActivityData = useSelector((state: IStore) => state.ui.addActivityData);

    const editActivityId = useSelector((state: IStore) => state.ui.editActivityId);
    const initialActivity = useSelector((state: IStore) => state.entities.activities.byId[state.ui.editActivityId]);
    const isInterview = useSelector((state: IStore) => {
        return state.ui.addActivityData.isInterview || (initialActivity && initialActivity.type === EActivityType.interview);
    });
    const isFeedback = useSelector((state: IStore) => {
        return state.ui.addActivityData.isFeedback || (initialActivity && initialActivity.type === EActivityType.feedback);
    });

    const renderSystemMail = initialActivity && initialActivity?.type === EActivityType.systemMail;
    const renderMailboxMail = initialActivity && (initialActivity.action === EActivityAction.mailboxMailReceived || initialActivity.action === EActivityAction.mailboxMailSend);

    useEffect(() => {
        if (editActivity && !initialActivity) {
            dispatch(getActivityAction(
                token,
                editActivityId
            ))
        }
    }, [editActivity, initialActivity, editActivityId]);

    if (editActivity && !initialActivity) {
        return null;
    }

    const open = addActivity || editActivity;
    let title = 'pages.activity.add';
    if (addActivity && isInterview) {
        title = 'pages.recruiting.addInterview';
    }
    if (addActivity && isFeedback) {
        title = 'pages.recruiting.addFeedback';
    }

    if (editActivity) {
        title = 'pages.activity.edit';
    }
    if (editActivity && isInterview) {
        title = 'pages.recruiting.editInterview';
    }
    if (editActivity && isFeedback) {
        title = 'pages.recruiting.editFeedback';
    }
    if (renderSystemMail || renderMailboxMail) {
        title = 'pages.activity.showMail';
    }

    const onClose = () => {
        dispatch(setMailingDetailViewClosedAction());
        dispatch(setUiActivityAddDataAction({add: false}));
        dispatch(setUiActivityToEditAction(0));
        dispatch(resetFormValidation());
    };

    return (
        <CustomDialog maxWidth={"md"} onClose={onClose} open={open}>
            <CustomDialogHeader translationString={title} onClose={onClose}>
                <CustomCellTopBar
                    candidate={editActivity ? initialActivity.candidate : addActivityData.candidate}
                    company={editActivity ? initialActivity.company : addActivityData.company}
                    vacancy={editActivity ? initialActivity.vacancy : addActivityData.vacancy}
                    recruiting={editActivity ? initialActivity.recruiting : addActivityData.recruiting}
                    recruitingSuggestion={editActivity ? initialActivity.recruitingSuggestion : undefined}
                />
            </CustomDialogHeader>
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                {isInterview && <InterviewForm onClose={onClose} /> }
                {isFeedback && <FeedbackForm onClose={onClose} /> }
                {!renderMailboxMail && !renderSystemMail && !isInterview && !isFeedback && <ActivityForm onClose={onClose} /> }
                {renderSystemMail && <ActivityDisplaySystemMail onClose={onClose} activity={initialActivity} />}
                {renderMailboxMail && <ActivityDisplayMailboxMail onClose={onClose} mailId={initialActivity.mail.id} />}
            </div>
        </CustomDialog>
    );
}
export default ActivityAddEditView;

