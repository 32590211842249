import { ISorting } from './app.definitions';
import { ESortingOptions } from './components.definitions';
import { ECandidateSource } from './entities.definition';

export interface IDashboardFilterRecruitingsOfLast7Days {
    days: number;
}

export interface IDashboardPageState {
    topSuggestionsViewOpen: boolean;
    newCandidateViewOpen: boolean;
    candidateBirthdayViewOpen: boolean;
    companyContactsBirthdayViewOpen: boolean;
    todosViewOpen: boolean;
    yourTodayAppointmentsViewOpen: boolean;
    yourRecruitingsViewOpen: boolean;
    recruitingsOfLast7DaysViewOpen: boolean;
    chartSelectionRecruitingsViewOpen: boolean;
    chartSelection: IChartSelection,
    employeeData: IAnalyticsEmployeeData;
    reloadData: boolean;
    goalBurnUpChartSettings: IGoalBurnUpChartSettings;
    target: IGroupedRecruitingEvaluationData[];
    groupedEvaluationData: {
        byEmployee: IGroupedRecruitingEvaluationData[];
        byCandidateSource: IGroupedRecruitingEvaluationData[];
        byBranch: IGroupedRecruitingEvaluationData[];
        byCompany: IGroupedRecruitingEvaluationData[];
        byMonth: IGroupedRecruitingEvaluationData[];
        byMonthSummedUp: IGroupedRecruitingEvaluationData[];
    };
    groupedEvaluationDataLastYear: {
        byMonth: IGroupedRecruitingEvaluationData[];
        byMonthSummedUp: IGroupedRecruitingEvaluationData[];
    },
    contractsGroupedByCommission: boolean;
    chartYear: number;
    sortingRecruitingOfLast7Days: ISorting;
    sortingEmployeeRecruitings: ISorting;
    sortingTopSuggestions: ISorting;
    sortingCandidateBirthdays: ISorting;
    sortingOpenApplications: ISorting;
    sortingContactPersonBirthdays: ISorting;
    filterRecruitingOfLast7Days: IDashboardFilterRecruitingsOfLast7Days,
    filterMenuRecruitingOfLast7Days: IDashboardFilterRecruitingsOfLast7Days,
}

export enum EDashboardPageTable {
    topFavorites,
    yourRecruitings,
    recruitingsOfLast7Days,
    candidateBirthdays,
    contactPersonBirthdays,
    openApplications
}

export const preloadedStateDashboardPage: IDashboardPageState = {
    topSuggestionsViewOpen: false,
    newCandidateViewOpen: false,
    recruitingsOfLast7DaysViewOpen: false,
    candidateBirthdayViewOpen: false,
    companyContactsBirthdayViewOpen: false,
    todosViewOpen: false,
    yourTodayAppointmentsViewOpen: false,
    yourRecruitingsViewOpen: false,
    chartSelectionRecruitingsViewOpen: false,
    chartSelection: {},
    employeeData: {
        countRecruitingsOfLast7Days: 0,
        countActiveVacancies: 0,
        countBirthdayCandidates: 0,
        countBirthdayCompanyContacts: 0,
        countOpenApplications: 0,
        countOpenRecruitings: 0,
        countTodayAppointments: 0,
        countTopSuggestions: 0,
        employeeId: 0,
        successRate: 0,
        countNewWebVacancies: 0,
        averageCommission: 0,
        averageCommissionLastYear: 0
    },
    reloadData: false,
    target: [],
    groupedEvaluationData: {
        byCompany: [],
        byEmployee: [],
        byMonth: [],
        byMonthSummedUp: [],
        byBranch: [],
        byCandidateSource: []
    },
    groupedEvaluationDataLastYear: {
        byMonth: [],
        byMonthSummedUp: []
    },
    goalBurnUpChartSettings: {
        year: (new Date()).getFullYear(),
        byCommission: false,
        showLastYear: false,
        showTwoYearsAgo: false
    },
    contractsGroupedByCommission: true,
    chartYear: (new Date()).getFullYear(),
    filterRecruitingOfLast7Days: {
        days: 7
    },
    filterMenuRecruitingOfLast7Days: {
        days: 7
    },
    sortingRecruitingOfLast7Days: {
        "created": ESortingOptions.Descending
    },
    sortingCandidateBirthdays: {
        "nextBirthday": ESortingOptions.Ascending
    },
    sortingContactPersonBirthdays: {},
    sortingEmployeeRecruitings: {
        "created": ESortingOptions.Descending
    },
    sortingOpenApplications: {},
    sortingTopSuggestions: {
        "created": ESortingOptions.Descending
    }
};

export interface IGoalBurnUpChartSettings {
    showLastYear: boolean;
    showTwoYearsAgo: boolean;
    byCommission: boolean;
    employeeId?: number;
    year: number;
}

export interface IGoalBurnUpChartData {
    month: number;
    targetCommission?: number;
    monthTotalCommission?: number;
    totalCommission?: number;
    targetContracts?: number;
    monthTotalContracts?: number;
    totalContracts?: number;

    lastYearMonthTotalCommission?: number;
    lastYearMonthTotalContracts?: number;
    lastYearTotalCommission?: number;
    lastYearTotalContracts?: number;
}

export interface IGroupedRecruitingEvaluationData {
    groupId: number;
    groupTitle: string;
    contracts?: number;
    commission?: number;
}

export interface IGroupedTimePerEmployeeData {
    groupId: number;
    groupTitle: string;
    time: number;
    percent: number;
}

export interface IGroupedSuggestionWithStatusEvaluationData {
    groupId: number;
    groupTitle: string;
    count: number;
    countStatusOpen: number;
    countStatusDeclined: number;
    countStatusAccepted: number;
}

export interface IGroupedSuggestionWithSourceEvaluationData {
    groupId: number;
    groupTitle: string;
    count: number;
    countSources: {
        [index: string]: number
    }
}

export interface IChartSelection {
    employeeId?: number;
    employeeName?: string;
    month?: number;
    year?: number;
    noProjects?: boolean;
    companyId?: number;
    companyName?: string;
    branchId?: number;
    branchName?: string;
    candidateSource?: ECandidateSource;
}

export interface IAnalyticsEmployeeData {
    employeeId: number;
    countActiveVacancies: number;
    countTodayAppointments: number;
    countOpenRecruitings: number;
    countRecruitingsOfLast7Days: number;
    countBirthdayCandidates: number;
    countBirthdayCompanyContacts: number;
    countOpenApplications: number;
    countTopSuggestions: number;
    successRate: number;
    countNewWebVacancies: number;
    averageCommission: number;
    averageCommissionLastYear: number;
}
