import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { addSkillAction, updateSkillAction } from '../../redux/entities/entities.actions';
import { setCreateNewEntityDialogAction } from '../../redux/ui/ui.actions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import PaperRow from '../../components/CustomPaper/PaperRow';
import CustomTextField from '../../components/CustomInput/CustomTextField';
import { translate } from '../../translation/translate.utils';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { IJobTemplate, ISkill, ISkillPartial } from '../../definitions/entities.definition';
import SkillAutocompleteDropDown
    from '../../components/CustomInput/CustomAutocomplete/Legacy/SkillAutocompleteDropDown';
import { useLoadSkill } from './SkillAddEditView.hook';
import {
    setSkillPageSkillToEditAction,
    setSkillPageTemplateToDeleteAction,
} from '../../redux/skillPage/skillPage.actions';
import CustomCheckboxInput from '../../components/CustomInput/CustomCheckboxInput';
import CustomBackdropLoading from '../../components/CustomBackdrop/CustomBackdropLoading';
import JobTemplateAutocompleteDropDown
    from '../../components/CustomInput/CustomAutocomplete/Legacy/JobTemplateAutocompleteDropDown';
import SelectSkillInput from '../Shared/Skill/SelectSkillInput';

const SkillAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const isAddAction = useSelector((store: IStore) => Boolean(store.ui.entityToCreate));
    const isEditAction = useSelector((store: IStore) => Boolean(store.skillPage.skillToEdit));
    const isEditViewLoading = useSelector((store: IStore) => store.skillPage.editViewLoading);
    const initialSkill = useLoadSkill();

    const token = useSelector((store: IStore) => store.actor.token);
    const [name, setName] = useState<string|undefined>();
    const [keywords, setKeywords] = useState<string|undefined>('');
    const [parentSkill, setParentSkill] = useState<undefined|ISkillPartial>();
    const [replace, setReplace] = useState(false);
    const [addToTemplate, setAddToTemplate] = useState(false);
    const [addToTemplateId, setAddToTemplateId] = useState<number|undefined>();
    const [deleteAssignments, setDeleteAssignments] = useState(false);
    const [replaceWithSkill, setReplaceWithSkill] = useState<undefined|ISkill>();
    const [deleteOldSkill, setDeleteOldSkill] = useState(false);

    const onSave = (closeWindow: boolean) => {
        if (isAddAction) {
            dispatch(addSkillAction(token, {
                name,
                keywords,
                parentSkillId: parentSkill ? parentSkill.id : undefined
            }));
            return;
        }

        dispatch(updateSkillAction(token, {
            id: initialSkill.skill.id,
            name,
            keywords,
            parentSkillId: parentSkill ? parentSkill.id : undefined,
            deleteOldSkill,
            replace,
            replaceWithSkillId: replaceWithSkill ? replaceWithSkill.id : undefined,
            deleteAssignments,
            addToTemplateId
        }, closeWindow, JSON.stringify(initialSkill.skill.parentSkill) !== JSON.stringify(parentSkill)));
    }

    useEffect(() => {
        setName(initialSkill?.skill.name);
        setParentSkill(initialSkill?.skill.parentSkill);
        setKeywords(initialSkill?.skill.keywords);
        setReplace(false);
        setReplaceWithSkill(undefined);
        setDeleteOldSkill(false);
        setDeleteAssignments(false);
        setAddToTemplate(false);
        setAddToTemplateId(undefined);
    }, [initialSkill])

    const deletable = isEditAction && initialSkill?.skill.usedByCandidates === 0 && initialSkill?.skill.usedByVacancies === 0 && !initialSkill.hasChildren;

    const onClose = () => {
        setName("");
        setParentSkill(undefined);
        setKeywords("");
        setReplace(false);
        setReplaceWithSkill(undefined);
        setDeleteOldSkill(false);
        setDeleteAssignments(false);
        setAddToTemplate(false);
        setAddToTemplateId(undefined);
        dispatch(setCreateNewEntityDialogAction(undefined));
        dispatch(setSkillPageSkillToEditAction(0));
    }

    return (
        <CustomDialog fullWidth open={isAddAction || isEditAction} onClose={onClose}>
            <CustomDialogHeader translationString={isAddAction ? 'misc.addSkill' : 'misc.editSkill'} onClose={onClose} />
            <CustomBackdropLoading open={isEditViewLoading} />
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.skill.nameOfSkill')}
                            value={name}
                            onChange={(value) => setName(value)}
                        />
                    </PaperRow>
                    <PaperRow>
                        <SelectSkillInput
                            value={parentSkill}
                            onChange={(skill) => {
                                setParentSkill(skill)
                            }}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.skill.keywordsOfSkill')}
                            multiline
                            rows={8}
                            value={keywords}
                            onChange={(value) => setKeywords(value)}
                            helperText={<>
                                Hinweis und Beispiele. <br/>
                                * 1) Eingabe: Php, Javascript. <br/>
                                *   - Match: php, javascript <br/>
                                *   - Kein Match: &quot;php-entwickler&quot;, &quot;php-5.4&quot; <br/>
                                * 2) Eingabe: Php-, Javascript-.  <br/>
                                *   - Match &quot;php-entwickler&quot;, &quot;javascript-framework&quot;, &quot;php-5.4&quot; <br/>
                                * 3) Eingabe: [User experience] (WICHTIG: Eckigen Klammern) <br/>
                                *   - Erklärung: Sobald die Worte innerhalb der Klammer in einem Satz vorkommen, gibt es einen Match <br/>
                                *   - Match &quot;User-Interface und -Experience&quot;
                            </>}
                        />
                    </PaperRow>
                    {
                        isEditAction &&
                        <PaperRow>
                            <CustomCheckboxInput
                                label={'Skill ersetzen'}
                                checked={replace}
                                onChange={() => setReplace(!replace)}
                            />
                        </PaperRow>
                    }
                    {
                        (isEditAction && replace) &&
                        <>
                            <PaperRow>
                                <SkillAutocompleteDropDown
                                    label={'Ersetzen mit'}
                                    value={replaceWithSkill}
                                    onChange={(event, value) => setReplaceWithSkill(value)}
                                />
                            </PaperRow>
                            <PaperRow>
                                <CustomCheckboxInput
                                    label={'Skill nach Ersetzung löschen'}
                                    checked={deleteOldSkill}
                                    onChange={() => setDeleteOldSkill(!deleteOldSkill)}
                                />
                            </PaperRow>
                        </>

                    }
                    {
                        isEditAction &&
                        <>
                            <PaperRow>
                                <CustomCheckboxInput
                                    label={'Alle Zuweisungen bei Kandidaten und Vakanzen entfernen'}
                                    checked={deleteAssignments}
                                    onChange={() => setDeleteAssignments(!deleteAssignments)}
                                />
                            </PaperRow>
                            <PaperRow>
                                <CustomCheckboxInput
                                    label={'Skill einer Berufsgruppe hinzufügen'}
                                    checked={addToTemplate}
                                    onChange={() => setAddToTemplate(!addToTemplate)}
                                />
                            </PaperRow>
                        </>
                    }
                    {
                        (isEditAction && addToTemplate &&
                            <PaperRow>
                                <JobTemplateAutocompleteDropDown
                                    onChange={(event, value: IJobTemplate) => setAddToTemplateId(value.id) }
                                />
                            </PaperRow>
                        )
                    }
                </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => dispatch(setSkillPageTemplateToDeleteAction(initialSkill.skill.id)),
                    hidden: !deletable,
                    label: translate('misc.buttons.delete')
                }, {
                    void: () => onSave(false),
                    hidden: isAddAction || replace,
                    label: translate('misc.buttons.save')
                }, {
                    void: () => onSave(true),
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </CustomDialog>
    );
}

export default SkillAddEditView;
