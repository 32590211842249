import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexWrap: "wrap",
            userSelect: "none",
            overflow: "hidden",
            paddingBottom: 5,
            marginLeft: 8
        },
    })
);

const TopBarButtonPanelWithPadding: React.FC = (props) => {
    const styles = useStyles();

    return <div className={styles.root}>{props.children}</div>;
};

export default TopBarButtonPanelWithPadding;
