import React from 'react';
import { MenuItem } from '@material-ui/core';
import CustomDropDownInput from './CustomDropDownInput';
import { translate } from '../../../translation/translate.utils';

interface IProps {
    onChange: any;
    value: number;
    required?: boolean;
}
export const EEmployeeSizeCategory: { [key: number]: string } = Object.freeze({
    1: "1 - 10",
    2: "10 - 100",
    3: "100 - 1000",
    4: "1000 - 10000",
    5: "> 10000",
});

const EmployeeSizeDropDown: React.FC<IProps> = (props) => {
    let menuItems = Object.entries(EEmployeeSizeCategory).map((entry, index) => {
        const indexProp = entry[0];
        const value = entry[1];

        return (
            <MenuItem key={index + 1} value={indexProp}>
                {value}
            </MenuItem>
        );
    });
    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.chooseEmployeeSize')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value ?? 0}
            id={"employeeSize-dropdown"}
            required={props.required}
            label={translate('pages.company.properties.employeeSizeCategory')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default EmployeeSizeDropDown;
