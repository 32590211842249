import React from 'react';
import { IGroupedTimePerEmployeeData } from '../../definitions/dashboardPage.definitions';
import { getEmployeeColor } from '../../utils/components.utils';
import { Tooltip } from '@material-ui/core';
import { getHoursStringByMinutes } from '../../utils/date.utils';
import { useDispatch } from 'react-redux';
import { setUIActivityListViewOpenAction } from '../../redux/ui/ui.actions';

interface IProps {
    times: IGroupedTimePerEmployeeData[];
    vacancyId?: number;
    recruitingId?: number;
}

const EmployeeTimeCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    return (
        <div className={"flex flexCentered"} style={{height: '100%'}}>
            {props.times.map((time, index) => {
                const borderRadius = props.times.length === 1 ? '16px 16px 16px 16px' : index === 0 ? '16px 0px 0px 16px' : index === props.times.length-1 ? '0px 16px 16px 0px' : undefined;

                return <Tooltip title={time.groupTitle + ' (' + getHoursStringByMinutes(time.time) + ' Std.)'} key={index} >
                    <div
                        style={{borderRadius, cursor: 'pointer', width: time.percent + '%', background: getEmployeeColor(time.groupId)}}
                        onClick={() => dispatch(setUIActivityListViewOpenAction({
                            vacancyId: props.vacancyId,
                            recruitingId: props.recruitingId,
                            employeeId: time.groupId
                        }))}
                    >
                        &nbsp;
                    </div>
                </Tooltip>
            })}
        </div>
    );
};

export default EmployeeTimeCellRenderer;
