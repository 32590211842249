import React, { useState } from 'react';
import CandidateDocumentUploadView from '../../EditViews/Candidate/components/components/CandidateDocumenUploadView';
import CustomMultipleAutocomplete from '../../../components/CustomInput/CustomAutocomplete/CustomMultipleAutocomplete';
import { ECandidateDocumentType, ICandidateDocument, TBrainzEntity } from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import { API_URL, getCandidateDocumentsForCandidateRouteConfig } from '../../../requests/routes';
import { getFormattedDateString } from '../../../utils/date.utils';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import { useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';

interface IProps {
    initialDocumentIds: number[];
    setSelectedDocuments: (documents: ICandidateDocument[]) => void;
    candidateId: number
    error?: boolean;
    helperText?: string;
}

const RecruitingSharedDocumentAutocomplete: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState<ICandidateDocument[]>([]);
    const candidateId = props.candidateId;

    return <>
        <CandidateDocumentUploadView
            candidateId={candidateId}
            open={uploadOpen}
            onClose={() => {
                setUploadOpen(false);
            }}
            successCallback={(response) => {
                setUploadOpen(false);
                setSelectedDocuments([...selectedDocuments, response[0]]);
            }}
        />
        <CustomMultipleAutocomplete
            id={"share-document-autocomplete"}
            label={"Dokumente für Kunden zur Einsicht freigeben"}
            value={selectedDocuments.length > 0 ? selectedDocuments : props.initialDocumentIds}
            error={props.error}
            helperText={props.helperText}
            onChange={(entities) => {
                setSelectedDocuments(entities as ICandidateDocument[]);
                props.setSelectedDocuments(entities as ICandidateDocument[]);
            }}
            filter={(entity) => {
                const entityCast = entity as ICandidateDocument
                console.log(entityCast);
                console.log( entityCast.type !== ECandidateDocumentType.PHOTO);
                return entityCast.type !== ECandidateDocumentType.PHOTO;
            }}
            groupBy={(option) => {
                const document = option as ICandidateDocument;

                return translate('pages.candidateDocument.typeValuePlural.' + document.type);
            }}
            routeConfig={getCandidateDocumentsForCandidateRouteConfig}
            getOptionLabel={(option: TBrainzEntity|null) => {
                const document = option as ICandidateDocument;

                return getFormattedDateString(document.created) + ': ' + document.displayFileName;
            }}
            renderOption={(option: TBrainzEntity|null) => {
                const document = option as ICandidateDocument;

                return <div className={"flexContainerRow flex1"}>
                    {getFormattedDateString(document.created) + ': ' + document.displayFileName}
                    <CustomClickableIcon
                        icon={EIcons.Launch}
                        className={"alignHorizontalRight"}
                        onClick={(event) => {
                            event.stopPropagation();
                            window.open(`${API_URL}/candidate-document/${document.id}/preview?Authorization=${token}`);
                        }}
                    >öffnen</CustomClickableIcon>
                </div> ;
            }}
            required
            onAdd={() => setUploadOpen(true)}
            routeParams={{
                candidateId
            }}
        />
    </>
}

export default RecruitingSharedDocumentAutocomplete;
