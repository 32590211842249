import React from 'react';
import { ICandidatePartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CandidateAutocomplete from '../components/CandidateAutocomplete';

interface IProps {
    value?: ICandidatePartial|number;
    onChange: (event: React.ChangeEvent<{}>, value: any) => void;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const LegacyCandidateAutocompleteDropDown: React.FC<IProps> = (props) => {
    return <CandidateAutocomplete
        required={props.required}
        value={props.value === undefined  ? null : props.value}
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        onChange={(value) => {
            //@ts-ignore
            props.onChange({}, value);
        }}
        label={translate('misc.candidate')}
    />
};

export default LegacyCandidateAutocompleteDropDown;
