import React, { useState } from 'react';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { translate } from '../../../../translation/translate.utils';
import CustomNumberInput from '../../../CustomInput/CustomNumberInput';
import PaperRow from '../../../CustomPaper/PaperRow';
import { EIcons } from '../../../Icons/IconFactory';
import CustomTextField from '../../../CustomInput/CustomTextField';

interface IProps {
    location?: string;
    distance?: number;
    onChange: (location?: string, distance?: number) => void;
}

const RadiusFilter: React.FC<IProps> = (props) => {
    const [location, setLocation] = useState(props.location);
    const [distance, setDistance] = useState(props.distance);

    const dataConsistent = JSON.stringify(location) === JSON.stringify(props.location) && JSON.stringify(distance) === JSON.stringify(props.distance);

    const submit = () => {
        props.onChange(location, distance);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const deletable = (props.location !== undefined && props.location.trim() !== '') && props.distance !== undefined && props.distance > 0;
    const renderValue = () => {
        if (deletable) {
            //@ts-ignore
            return <>max. {distance} km Radius bis {props.location}</>
        }

        return null;
    }

    return <>
        <CustomFilterItem
            title={"Adresse"}
            icon={EIcons.LocationOn}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            showDialog
            submit={submit}
            dataConsistent={dataConsistent}
            width={400}
        >
            <PaperRow>
                <CustomTextField
                    label={'Standort / Adresse'}
                    value={location}
                    onChange={setLocation}
                />
            </PaperRow>
            <PaperRow>
                <CustomNumberInput
                    value={distance}
                    onChange={setDistance}
                    label={translate('pages.candidate.filter.distanceInKm')}
                />
            </PaperRow>
        </CustomFilterItem>
    </>
};

export default React.memo(RadiusFilter);
