import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import PaperRow from '../../components/CustomPaper/PaperRow';
import { Dialog } from '@material-ui/core';
import StartRecruitingForm from './components/StartRecruitingForm';
import { setRecruitingPageStartRecruitingAction } from '../../redux/recruitingPage/recruitingPage.actions';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';

const StartRecruitingView: React.FC = () => {
    const open = useSelector((store: IStore) => store.recruitingPage.startRecruitingView.open);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setRecruitingPageStartRecruitingAction({
            open: false,
            candidateId: undefined,
            vacancyId: undefined,
            applicationId: undefined,
            webVacancyId: undefined,
            companyId: undefined
        }));
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} maxWidth={"sm"} fullWidth onClose={onClose}>
            <CustomDialogHeader translationString="pages.vacancy.startRecruiting" onClose={onClose} />
            <CustomDialogBody>
                <StartRecruitingForm onClose={onClose}/>
            </CustomDialogBody>
        </Dialog>
    );
};

export default StartRecruitingView;
