import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { IMailboxFolderTreeNode } from '../../../../definitions/entities.definition';
import FolderTreeItem from './FolderTreeItem';
import { setMailingFilterAction } from '../../../../redux/mailing/mailing.actions';
import { useDispatch } from 'react-redux';

interface IProps {
    treeNodes: IMailboxFolderTreeNode[];
    onSelect?: (treeNode: IMailboxFolderTreeNode) => void;
    defaultSelected: string;
}
const useStyles = makeStyles({
    root: {
        maxWidth: 700,
        flexGrow: 1,
    },
});

const FolderTreeView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const renderNode = (treeNode: IMailboxFolderTreeNode) => {
        return (
            <FolderTreeItem
                nodeId={treeNode.id.toString()}
                key={treeNode.id}
                node={treeNode}
                onSelect2={(node) => dispatch(setMailingFilterAction({folderId: node.id}))}
            >
                {treeNode.children.map((childTreeNode) => renderNode(childTreeNode))}
            </FolderTreeItem>
        )
    }

    return (
        <>
            <TreeView
                defaultSelected={props.defaultSelected}
                className={classes.root}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24, paddingRight: 20 }} />}
                expanded={expanded}
                onNodeToggle={handleToggle}
                style={{paddingRight: 10}}
            >
                {props.treeNodes.map((treeNode) => renderNode(treeNode))}
            </TreeView>
        </>
    );
}

export default FolderTreeView;
