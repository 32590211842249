import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: number;
    required?: boolean;
    error?: boolean;
    helperText?: string;
}

const AbcRatingDropDown: React.FC<IProps> = (props) => {
    const menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        <MenuItem key={1} value={1}>
            A
        </MenuItem>,
        <MenuItem key={2} value={2}>
            B
        </MenuItem>,
        <MenuItem key={3} value={3}>
            C
        </MenuItem>,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            required={props.required}
            value={props.value}
            id={"abc-rating-dropdown"}
            error={props.error}
            helperText={props.helperText}
            label={translate('pages.company.properties.rating')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default AbcRatingDropDown;
