import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ISkillMatch } from '../../definitions/matching.definitions';
import StarIcon from '@material-ui/icons/Star';
import { COLOR_YELLOW } from '../../theme/theme';
import { Tooltip } from '@material-ui/core';

interface IProps {
    skills: ISkillMatch[];
}

const MatchedSkillsCellRenderer: React.FC<IProps> = (props) => {
    const chipStyles = makeStyles((theme) => ({
        colorPrimary: {
            backgroundColor: theme.palette.primary.main,
        },
    }));
    const inputStyles = chipStyles();

    const starIconGold = <StarIcon style={{color: COLOR_YELLOW}} />;
    const starIconSilver = <StarIcon style={{color: 'silver'}} />;

    const chipElements = props.skills.map((skill: ISkillMatch, index: number) => (
        <Tooltip key={index} title={skill.name} placement="top-start">
            <Chip
                key={index}
                icon={skill.candidateImportantSkill && skill.vacancyImportantSkill ? starIconGold : skill.vacancyImportantSkill ? starIconSilver : undefined}
                classes={inputStyles}
                className={"ellipseText"}
                style={{maxWidth: 150}}
                label={<div className={"content ellipseText"} dangerouslySetInnerHTML={{__html:skill.name}}/>}
            />
        </Tooltip>
    ));

    return <div className={"tableCell gap5 flexContainerRow flexWrap alignHorizontalLeft"}>{chipElements}</div>;
};

export default MatchedSkillsCellRenderer;
