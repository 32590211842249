import React from 'react';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    className?: string;
    color?: string;
    icon: EIcons;
    iconStyle?: object
    tooltip?: string;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        iconContainer: {
            "&:hover svg": {
                color: theme.palette.primary.main + "!important",
            },
            "&:hover": {
                color: theme.palette.primary.main + "!important",
            },
            display: "flex",
            alignItems: "center",
            verticalAlign: "middle"
        },
        root: {
            display: "flex",
            marginRight: 4,
            alignItems: "center",
            verticalAlign: "middle"
        },
    })
);

const CustomHoverIcon: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const className = props.className ? `${props.className} ${styles.root}` : styles.root;

    const getButton = () => {
        return (
            <div className={className}>
                <div className={styles.iconContainer}>
                    {props.color ? IconFactory.getIconComponent(props.icon, {color: props.color, ...props.iconStyle}) : IconFactory.getIconComponent(props.icon, props.iconStyle)}
                    {props.children}
                </div>
            </div>
        );
    }


    if (props.tooltip) {
        return <Tooltip title={props.tooltip}>
            {getButton()}
        </Tooltip>;
    }

    return getButton();
};

export default CustomHoverIcon;
