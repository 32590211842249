import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../translation/translate.utils';
import { Dialog } from '@material-ui/core';
import { ClientApi } from '../../../requests/ClientApi';
import { getBirthdayMailTemplateRouteConfig, postBirthdayMailRouteConfig } from '../../../requests/routes';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { registerError, registerSuccess } from '../../../redux/error/error.actions';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import { IVacancyPartial } from '../../../definitions/entities.definition';
import { setCandidatePageCreateBirthdayMailAction } from '../../../redux/candidatePage/candidatePage.actions';
import VacancyAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';

interface IProps {
    reload: () => void;
}

const CreateBirthdayCongratulationMailView: React.FC<IProps> = (props) => {
    const open = useSelector((store: IStore) => Boolean(store.candidatePage.createBirthdayMailForCandidate));
    const idToCreateMailFor = useSelector((store: IStore) => store.candidatePage.createBirthdayMailForCandidate);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [template, setTemplate] = useState('');
    const [useEnglish, setUseEnglish] = useState(false);
    const [useFormalTerm, setUseFormalTerm] = useState(false);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const [vacancy, setVacancy] = useState<null|IVacancyPartial>(null);

    const loadMailTemplates = () => {
        ClientApi.request(getBirthdayMailTemplateRouteConfig, {
            token,
            candidateId: idToCreateMailFor,
            languageId: useEnglish ? 38 : 33,
            vacancyId: vacancy ? vacancy.id : undefined,
            useFormalTerm
        }).then((result: {
            template: string,
            text: string,
            languageId: number,
            useFormalTerm: boolean
        }) => {
            setText(result.text);
            setTemplate(result.template);
            if (!initialLoaded) {
                setUseFormalTerm(result.useFormalTerm);
                setUseEnglish(result.languageId === 38);
            }

            setInitialLoaded(true);
        }).catch((error) => {
            if (error !== 'Candidate has no E-Mail Address') {
                dispatch(registerError(error));
                return;
            }

            dispatch(registerError('Kandidat hat keine E-Mail Adresse hinterlegt'));
            onClose();
            return;
        });
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        loadMailTemplates();
    }, [dispatch, idToCreateMailFor]);

    useEffect(() => {
        if (!open || !initialLoaded) {
            return;
        }
        loadMailTemplates();
    }, [useFormalTerm, useEnglish, vacancy]);

    const onSave = () => {
        ClientApi.request(postBirthdayMailRouteConfig, {
            token,
            candidateId: idToCreateMailFor,
            vacancyId: vacancy ? vacancy.id : undefined,
            text
        }).then((result) => {
            dispatch(registerSuccess());
            props.reload();
            onClose();
        });
    }

    const onClose = () => {
        dispatch(setCandidatePageCreateBirthdayMailAction(0));
    }

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
            <CustomDialogHeader translationString={"pages.recruiting.createCongratulationMail"} onClose={onClose} />
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <PaperRow>
                        <CustomCheckboxInput
                            label={"Formele Ansprache (Sie) nutzen"}
                            checked={useFormalTerm}
                            onChange={() => setUseFormalTerm(!useFormalTerm)}
                        />
                        <CustomCheckboxInput
                            label={"Englisches Anschreiben nutzen"}
                            checked={useEnglish}
                            onChange={() => setUseEnglish(!useEnglish)}
                        />
                    </PaperRow>
                    <PaperRow>
                        <VacancyAutocomplete
                            label={translate('misc.vacancy')}
                            value={vacancy}
                            onlyWithUrl
                            required
                            onChange={(value) => setVacancy(value)}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomTextField
                            multiline
                            rows={15}
                            value={text}
                            onChange={(value) => setText(value)}
                        />
                    </PaperRow>
                    <div className="content" dangerouslySetInnerHTML={{__html: template.replace('{template}', text)}}></div>
                </div>
            <CustomDialogActions
                onClose={onClose}
                buttons={[{
                    void: () => onSave(),
                    label: translate('pages.recruiting.sendCongratulationMail')
                }]}
            />
        </Dialog>
    );
};

export default CreateBirthdayCongratulationMailView;
