import React from 'react';
import { getFormattedDateString, getFormattedDateWithDayString } from '../../utils/date.utils';

interface IProps {
    date: null|string;
    hideDay?: boolean;
}

const DateCellRenderer: React.FC<IProps> = (props) => {
    const hideDay = props.hideDay || false;
    if (props.date === null) {
        return null;
    }

    if (hideDay) {
        return <div>{getFormattedDateString(props.date)}</div>;
    }
    return <div>{getFormattedDateWithDayString(props.date)}</div>;
};

export default DateCellRenderer;
