import {
    ISetMailingCloseDetailViewAction,
    ISetMailingFilterAction,
    ISetMailingReloadAction,
} from '../../definitions/redux/actions.definition';
import { IMailingFilter } from '../../definitions/mailing.definitions';

export const MAILING_SET_FILTER = "MAILING_SET_FILTER";
export const MAILING_SET_RELOAD = "MAILING_SET_RELOAD";
export const MAILING_CLOSE_DETAIL_VIEW = "MAILING_CLOSE_DETAIL_VIEW";

export const setMailingFilterAction = (filter: Partial<IMailingFilter>): ISetMailingFilterAction => ({
    type: MAILING_SET_FILTER,
    payload: filter
});

export const setMailingReloadAction = (): ISetMailingReloadAction => ({
    type: MAILING_SET_RELOAD,
});

export const setMailingDetailViewClosedAction = (): ISetMailingCloseDetailViewAction => ({
    type: MAILING_CLOSE_DETAIL_VIEW,
});

