import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { resetFormValidation } from '../../../../redux/error/error.actions';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import CustomDialogHeader from '../../../../components/CustomDialog/CustomDialogHeader';
import {
    setAdministrationPageAddDialogOpenAction,
    setAdministrationPageEmployeeToEditAction,
} from '../../../../redux/administrationPage/administrationPage.actions';
import EmployeeForm from './EmployeeForm';

const EmployeeAddEditView: React.FC = () => {
    const dispatch = useDispatch();
    const addEmployee = useSelector((store: IStore) => store.administrationPage.addEmployeeDialogOpen);
    const editEmployee = useSelector((store: IStore) => Boolean(store.administrationPage.employeeToEdit));

    const onClose = () => {
        dispatch(setAdministrationPageAddDialogOpenAction(false));
        dispatch(setAdministrationPageEmployeeToEditAction(0));
        dispatch(resetFormValidation());
    }

    const open = addEmployee || editEmployee;

    return (
        <CustomDialog fullWidth onClose={onClose} open={open}>
            <CustomDialogHeader translationString={addEmployee ? "pages.administration.addEmployee" : "pages.administration.editEmployee"} onClose={onClose} />
            <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                <EmployeeForm onClose={onClose} />
            </div>
        </CustomDialog>
    );
}
export default EmployeeAddEditView;

