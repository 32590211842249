import { useEffect, useState } from 'react';
import { getDocumentsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { IStore } from '../../../../../definitions/redux/store.definitions';
import {
    getCompanyPageRequestObjectForDocumentsInEditView,
    getDocumentsForCompany,
} from '../../../../../selectors/companyDocument.selectors';

export const useCompanyDocumentListHook = (companyId: number) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const loading = useSelector((store: IStore) => store.ui.documentsOfCompanyLoading);

    const [initialLoading, setInitialLoading] = useState(true);
    const requestObject = useSelector(getCompanyPageRequestObjectForDocumentsInEditView);
    const sorting = useSelector((store: IStore) => store.companyPage.documentSorting);
    const documents = useSelector((store: IStore) => getDocumentsForCompany(store, companyId));

    useEffect(() => {
        dispatch(getDocumentsForCompanyAction(token, companyId, requestObject));
        setInitialLoading(false);
    }, [companyId]);

    useEffect(() => {
        if (!loading && !initialLoading) {
            dispatch(getDocumentsForCompanyAction(token, companyId, requestObject));
        }
    }, [JSON.stringify(sorting)]);

    return {loading, documents, total: documents.length};
}
