import React from 'react';
import { useCandidateApplicationListHook } from './CandidateApplicationList.hooks';
import ApplicationList from '../../../../Shared/ApplicationList/ApplicationList';

interface IProps {
    candidateId: number;
}

const CandidateApplicationList: React.FC<IProps> = (props) => {
    const candidateId = props.candidateId;
    const { loading, applications, total } = useCandidateApplicationListHook(candidateId);

    return <>
        <ApplicationList
            applications={applications}
            loading={loading}
            total={total}
            parentView={'candidate'}
        />
    </>;
}

export default CandidateApplicationList;
