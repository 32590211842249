import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: TMouseEventFunction;
    value?: number;
    label: string;
    max: number;
    stepSize?: number;
    error?: boolean;
    helperText?: string;
    required?: boolean;
}

const NumberDropDown: React.FC<IProps> = (props) => {
    const elements = [
        <MenuItem key={0} value={0}>
            &nbsp;
        </MenuItem>
    ];
    for (let number = props.stepSize || 1; number <= props.max; number+= props.stepSize || 1){
        elements.push(
            <MenuItem key={number} value={number}>
                {number}
            </MenuItem>
        );
    }

    return (
        <>
            <CustomDropDownInput
                required={props.required}
                id={"number-dropdown" + props.label}
                width={200}
                onChange={props.onChange}
                value={props.value}
                label={props.label}
                error={props.error}
                helperText={props.helperText}
            >
                {elements}
            </CustomDropDownInput>
        </>
    );
};

export default NumberDropDown;
