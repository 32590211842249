import React from 'react';
import { useCandidateRecruitingListHook } from './CandidateRecruitingList.hooks';
import { getRecruitingsForCandidateAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { getCandidatePageRequestObjectForRecruitingsInEditView } from '../../../../../selectors/recruiting.selectors';
import RecruitingList from '../../../../Shared/RecruitingList/RecruitingList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    candidateId: number;
}

const CandidateRecruitingList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingRequestObject = useSelector(getCandidatePageRequestObjectForRecruitingsInEditView);
    const candidateId = props.candidateId;

    const { loading, recruitings, total} = useCandidateRecruitingListHook(candidateId);

    return <RecruitingList
        recruitings={recruitings}
        total={total}
        loading={loading}
        parentView={EEntityView.candidate}
        onReload={() => {
            dispatch(getRecruitingsForCandidateAction(token, candidateId, recruitingRequestObject));
        }}
    />
}

export default CandidateRecruitingList;
