import React, { useState } from 'react';
import { Chip } from '@material-ui/core';
import { IEntitySkill, ISkillPartial } from '../../../../../definitions/entities.definition';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { COLOR_RED, COLOR_YELLOW } from '../../../../../theme/theme';
import {
    setSkillPageExternalSkillToEditAction,
    setSkillPageSkillToEditAction,
} from '../../../../../redux/skillPage/skillPage.actions';
import { useDispatch } from 'react-redux';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';

interface IProps {
    skill: IEntitySkill;
    onRemove: (skill: ISkillPartial) => void;
    onSetTop: (skill: ISkillPartial) => void;
}

const CandidateSkillElement: React.FC<IProps> = (props) => {
    const [onHover, setOnHover] = useState(false);
    const dispatch = useDispatch();

    const starIcon = <StarIcon onMouseOut={() => setOnHover(false)} style={{color: COLOR_YELLOW}} />;
    const starBorderIcon = <StarBorderIcon onMouseOver={() => setOnHover(true)}  />;

    const getLabel = (skill: IEntitySkill) => {
        if (skill.skill) {
            return <div className={"flex flexCentered"}>
                <img width={22} src={"/lebenslauf.png"} title={"Aus Lebenslauf"}/>&nbsp;
                {skill.skill.name}
            </div>;
        }

        return <div className={"flex flexCentered"}>
            <img width={22} src={"/linkedIn.png"} title={"Aus LinkedIn"}/>&nbsp;
            {skill.externalSkill.skill &&
                <>{skill.externalSkill?.name}</>
            }
            {!skill.externalSkill.skill &&
                <i style={{color: COLOR_RED}}>{skill.externalSkill?.name}</i>
            }
        </div>;
    }

    return <>
        <Chip
            color={props.skill.addedByParser ? 'primary' : 'default'}
            icon={onHover || props.skill.importantSkill ? starIcon : starBorderIcon }
            label={getLabel(props.skill)}
            onClick={(event) => {
                //@ts-ignore
                if (event.nativeEvent.layerX <= 30) {
                    props.onSetTop(props.skill.skill);
                    return;
                }

                if (props.skill.externalSkill) {
                    dispatch(setSkillPageExternalSkillToEditAction(props.skill.externalSkill.id));
                    return;
                }
                dispatch(setSkillPageSkillToEditAction(props.skill.skill.id));
            }}
            deleteIcon={IconFactory.getIconComponentUnstyled(EIcons.Cancel)}
            onMouseOut={() => setOnHover(false)}
            onDelete={() => props.onRemove(props.skill.skill)}
        />
    </>;
}

export default CandidateSkillElement
