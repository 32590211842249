import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import BaseCandidateFilterPanel from './BaseCandidateFilterPanel';
import {
    setVacancyPageCandidateSearchFilterAction,
    setVacancyPageCandidateSearchFilterMenuAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import {
    preloadedCandidatePageDateRangeFilter,
    preloaededCandidatePageFilterMenu,
} from '../../../../definitions/candidatePage.definitions';

const CandidateSearchInVacancyFilterPanel: React.FC = () => {
    const currentSorting = useSelector((store: IStore) => store.vacancyPage.sortingCandidateList);
    const filter = useSelector((store: IStore) => store.vacancyPage.filterCandidateList);
    const filterMenuInStore = useSelector((store: IStore) => store.vacancyPage.filterMenuCandidateList);
    const vacancyId = useSelector((store: IStore) => store.ui.searchForVacancyId);
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.listModelById[vacancyId]);

    const [initialFilterMenu, setInitialFilterMenu] = useState(preloaededCandidatePageFilterMenu);
    useEffect(() => {
        setInitialFilterMenu(filterMenuInStore);
    }, [vacancy?.id])

    return (
        <>
            <BaseCandidateFilterPanel
                filterMenuInStore={filterMenuInStore}
                setFilterMenuInStore={setVacancyPageCandidateSearchFilterMenuAction}
                filter={filter}
                setFilterAction={setVacancyPageCandidateSearchFilterAction}
                currentSorting={currentSorting}
                initialFilterMenu={initialFilterMenu}
                initialDateRangeFilter={preloadedCandidatePageDateRangeFilter}
                companyId={vacancy?.company.id}
            />
        </>
    );
}

export default React.memo(CandidateSearchInVacancyFilterPanel);
