import React, { useState } from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateCandidateDocumentAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { ICandidateDocument, ICandidateDocumentForApi } from '../../../../definitions/entities.definition';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import { getCurrentCandidateIdFromStore } from '../../../../selectors/candidate.selectors';
import CandidateDocumentTypeDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/CandidateDocumentTypeDropDown';


interface IProps {
    onClose: () => void;
    initialDocument: ICandidateDocument;
}

const CandidateDocumentForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const candidateId = useSelector(getCurrentCandidateIdFromStore);
    const [displayFileName, setDisplayFileName] = useState(props.initialDocument.displayFileName);
    const [type, setType] = useState(props.initialDocument.type);

    const onSave = () => {
        const document: ICandidateDocumentForApi = {
            id: props.initialDocument.id,
            displayFileName,
            candidateId,
            type,
        };

        dispatch(updateCandidateDocumentAction(token, document));
        props.onClose();
        return;
    };

    return (
        <>
            <PaperRow>
                <CustomTextField
                    label={translate('pages.candidateDocument.properties.displayFileName')}
                    value={displayFileName}
                    onChange={(value) => setDisplayFileName(value)}
                />
            </PaperRow>
            <PaperRow>
                <CandidateDocumentTypeDropDown value={type} onChange={(event) => setType(event.target.value)} />
            </PaperRow>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </>
    );
};

export default CandidateDocumentForm;
