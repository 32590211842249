import { useSelector } from 'react-redux';
import { IStore } from '../definitions/redux/store.definitions';
import { getLoggedInUserFromStore } from './actor.selectors';

export const getToken = (store: IStore) => store.actor.token;
export const useToken = () => useSelector((store: IStore) => store.actor.token);

export const isAnyMainListLoading = (store: IStore) => {
    return store.ui.activitiesLoading ||
        store.ui.companiesLoading ||
        store.ui.openVacanciesLoading ||
        store.ui.recruitingsLoading ||
        store.ui.candidatesLoading ||
        store.ui.companiesLoading;
};

export const useCurrentEmployeePasswordRenew = () => {
    const user = useSelector(getLoggedInUserFromStore);

    if (!user) {
        return false;
    }

    return user.renewPassword;
};

export const useChangePasswordData = () => {
    const user = useSelector(getLoggedInUserFromStore);

    return { userId: user.id, userEmail: user.login };
};
export const getValidationErrorsFromStore = (store: IStore): { [key: string]: string } => {
    return store.error.validation;
};
export const getSuccessFromStore = (store: IStore) => {
    return store.error.success;
}
