import React from 'react';
import { EEntities, IInvoice } from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { translate } from '../../../../translation/translate.utils';
import { IStore } from '../../../../definitions/redux/store.definitions';
import DateCellRenderer from '../../../../components/CustomCellRenderer/DateCellRenderer';
import { getToken } from '../../../../selectors/app.selectors';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { ECellAlign } from '../../../../definitions/components.definitions';
import { getInvoiceListOfRecruitingAction } from '../../../../redux/entities/entities.actions';
import { EDocumentStatus, EInvoiceType } from '../../../../definitions/billingPage.definitions';
import DocumentStatusCellRenderer from '../../../../components/CustomCalendar/DocumentStatusCellRenderer';
import InvoiceTypeCellRenderer from '../../../../components/CustomCellRenderer/InvoiceTypeCellRenderer';
import CurrencyCellRenderer from '../../../../components/CustomCellRenderer/CurrencyCellRenderer';
import {
    setBillingPageAddPaymentForInvoiceAction,
    setBillingPageInvoiceToEditAction,
} from '../../../../redux/billingPage/billingPage.actions';
import {
    getInvoiceListOfRecruitingFromStore,
    getRecruitingPageInvoiceListRequestObject,
} from '../../../../selectors/billing.selectors';
import { themeOrange } from '../../../../theme/theme';
import { userHasRole } from '../../../../selectors/employee.selectors';
import { ROLE_BILLING } from '../../../../definitions/actor.definitions';
import { API_URL } from '../../../../requests/routes';

interface IProps {
    recruitingId: number;
}

const RecruitingInvoiceList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const recruitingId = props.recruitingId;
    const invoices = useSelector((store: IStore) => getInvoiceListOfRecruitingFromStore(store, recruitingId));
    const loading = useSelector((store: IStore) => store.ui.invoicesOfRecruitingLoading);
    const requestObject = useSelector(getRecruitingPageInvoiceListRequestObject);
    const token = useSelector(getToken);

    if (!userHasRole(ROLE_BILLING)) {
        return null;
    }

    return (
        <CustomTable
            config={{
                color: themeOrange.palette.primary.main,
                entity: EEntities.RecruitingInvoice,
                columnConfig: {
                    number: {
                        header: translate('pages.billing.invoiceNumber'),
                        property: "number",
                        width: 150,
                    },
                    status: {
                        header: translate('misc.status'),
                        property: "status",
                        width: 120,
                        cellRenderer: (status: EDocumentStatus) => {
                            return <DocumentStatusCellRenderer status={status} />
                        }
                    },
                    invoiceType: {
                        header: 'Rechnungs-Typ',
                        property: 'invoiceType',
                        cellRenderer: (invoiceType: EInvoiceType) => {
                            return <InvoiceTypeCellRenderer invoiceType={invoiceType} />
                        }
                    },
                    date: {
                        header: translate('misc.date'),
                        property: "date",
                        width: 120,
                        cellRenderer: (dateTime: string) => <DateCellRenderer date={dateTime} />,
                    },
                    dueDate: {
                        header: translate('misc.dueDate'),
                        property: "dueDate",
                        width: 120,
                        cellRenderer: (dateTime: string) => <DateCellRenderer date={dateTime} />,
                    },
                    totalNet: {
                        header: translate('pages.billing.totalNet'),
                        property: "totalNet",
                        align: ECellAlign.right,
                        width: 120,
                        cellRenderer: (value: number) => <CurrencyCellRenderer value={value} />
                    },
                },
                sortedEntries: invoices,
                loading: loading,
                onRowDoubleClick: (invoiceId: number) => dispatch(setBillingPageInvoiceToEditAction(
                    invoiceId
                )),
                listActionButtons: [{
                    action: (invoiceId: number, entity) => {
                        const invoice = entity as IInvoice;
                        window.open(`${API_URL}/invoice/${invoice.id}/print?Authorization=${token}`)
                    },
                    translationKey: "pages.billing.printDocument",
                    icon: EIcons.Print,
                }, {
                    action: (invoiceId: number) => dispatch(setBillingPageAddPaymentForInvoiceAction(invoiceId)),
                    translationKey: "pages.billing.addPayment",
                    icon: EIcons.Money,
                }],
                onReload: () => {
                    dispatch(getInvoiceListOfRecruitingAction(token, recruitingId, requestObject));
                },
                count: invoices.length,
                total: invoices.length
            }}
        />
    );
};

export default RecruitingInvoiceList;
