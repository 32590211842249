import { IStore } from '../definitions/redux/store.definitions';
import { IActivity } from '../definitions/entities.definition';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';

export const getActivitiesFromStore = (store: IStore): IActivity[] => {
    const order = store.entities.activities.order;
    const activitiesById = store.entities.activities.byId;

    return order.reduce((accumulator: IActivity[], activityId) => {
        accumulator.push(activitiesById[activityId]);

        return accumulator;
    }, []);
};

export const getActivitiesForCandidateFromStore = (store: IStore, candidateId: number) => {
    const activitiesById = store.entities.activities.byId;
    const activityIdsByCandidateId = store.entities.activities.byCandidate[candidateId] || [];

    const activities: IActivity[] = [];

    activityIdsByCandidateId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForTodoFromStore = (store: IStore) => {
    const currentTodoId = store.todo.todoToEdit;
    const activitiesById = store.entities.activities.byId;
    const activityIdsByTodoId = store.entities.activities.byTodo[currentTodoId] || [];

    const activities: IActivity[] = [];

    activityIdsByTodoId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForVacancyFromStore = (store: IStore, id: number) => {
    const activitiesById = store.entities.activities.byId;
    const activityIdsByVacancyId = store.entities.activities.byVacancy[id] || [];

    const activities: IActivity[] = [];

    activityIdsByVacancyId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForWebVacancyFromStore = (store: IStore, webVacancyId: number) => {
    const activitiesById = store.entities.activities.byId;
    const activityIdsByVacancyId = store.entities.activities.byWebVacancy[webVacancyId] || [];

    const activities: IActivity[] = [];

    activityIdsByVacancyId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForApplicationsFromStore = (store: IStore) => {
    const currentApplicationId = store.applicationPage.applicationToEdit;
    const activitiesById = store.entities.activities.byId;
    const activityIdsByVacancyId = store.entities.activities.byApplications[currentApplicationId] || [];

    const activities: IActivity[] = [];

    activityIdsByVacancyId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForCompanyFromStore = (store: IStore, companyId: number) => {
    const activitiesById = store.entities.activities.byId;
    const activityIdsByCompanyId = store.entities.activities.byCompany[companyId] || [];

    const activities: IActivity[] = [];

    activityIdsByCompanyId.forEach((activityId) => activities.push(activitiesById[activityId]));

    return activities;
};

export const getActivitiesForRecruitingFromStore = (store: IStore, recruitingId: number): IActivity[] => {
    const activitiesById = store.entities.activities.byId;
    const activityIdsByRecruitingId = store.entities.activities.byRecruiting[recruitingId] || [];

    const activities: IActivity[] = [];

    activityIdsByRecruitingId.forEach((activityId) => {
        activities.push(activitiesById[activityId]);
    });

    return activities;
};

export const getTodoActivityListRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.todo.activitySorting,
        { start: 0, limit: 50, fastSearch: "" }
    );
};

export const getCandidatePageActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.candidatePage.activitySorting,
        store.candidatePage.activityFilter
    );
};

export const getCompanyPageActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.companyPage.activitySorting,
        store.companyPage.activityFilter
    );
};

export const getRecruitingPageActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.recruitingPage.activitySorting,
        store.recruitingPage.activityFilter
    );
};

export const getActivitiesForListViewFromStore = (store: IStore): IActivity[]  => {
    const order = store.entities.activities.orderListView;
    const activities = store.entities.activities.byId;

    return order.reduce((accumulator: IActivity[], suggestionId) => {
        accumulator.push(activities[suggestionId]);

        return accumulator;
    }, []);
};

export const getActivityRequestObjectForListView = (store: IStore) => {
    const sorting = store.ui.activityListViewSorting;
    const filter = store.ui.activityListViewFilter;
    filter.start = 0;
    filter.limit = 0;

    return buildRequestObject(sorting, filter);
};

export const getActivityPageRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    return buildRequestObject(
        store.activityPage.sorting,
        {
            ...store.activityPage.filter,
            considerShowOnlyMineSetting: true
        }
    );
};

export const getVacancyPageActivitiesRequestObject = (store: IStore) => {
    const activitySorting = store.vacancyPage.activitySorting;
    const activityFilter = {
        start: 0,
        limit: 50,
        fastSearch: store.vacancyPage.activityFilter.fastSearch
    };

    return buildRequestObject(activitySorting, activityFilter);
};

export const getWebVacancyPageActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.webVacancyPage.activitySorting,
        { start: 0, limit: 50, fastSearch: store.webVacancyPage.activityFastSearch }
    );
};

export const getVacancyPageSuggestionActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.vacancyPage.suggestionActivitySorting,
        store.vacancyPage.suggestionActivityFilter
    );
};

export const getApplicationPageActivitiesRequestObject = (store: IStore) => {
    return buildRequestObject(
        store.applicationPage.activitySorting,
        { start: 0, limit: 50, fastSearch: "" }
    );
};

