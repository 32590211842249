import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormHelperText } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { translate } from '../../../../translation/translate.utils';
import CustomTextField from '../../CustomTextField';
import { BigDataListBox } from './BigDataListBox';
import Chip from '@material-ui/core/Chip';

interface IProps {
    options: any[];
    onAdd?: () => void;
    value?: any[];
    onChange: (value: any[]) => void;
    label: string;
    getOptionLabel: (value: any) => string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    autoFocus?: boolean;
}

const CustomMultipleAutocompleteDropDown: React.FC<IProps> = (props) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    return (
        <>
            <Autocomplete
                multiple
                value={props.value}
                style={{flex: 1}}
                limitTags={4}
                options={props.options}
                //@ts-ignore
                ListboxComponent={BigDataListBox}
                onChange={(event, value) => props.onChange(value)}
                getOptionLabel={props.getOptionLabel}
                renderOption={(option, {selected}) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                        />
                        {props.getOptionLabel(option)}
                    </React.Fragment>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            label={props.getOptionLabel(option)}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <CustomTextField
                        {...params}
                        fullWidth
                        autoFocus={props.autoFocus}
                        required={props.required}
                        label={props.label}
                        placeholder={translate('misc.searchForValue')}
                        error={props.error}
                        onAddNewEntryCallback={props.onAdd}
                    />
                )}
            />
            {props.error && <FormHelperText error={true}>{props.helperText}</FormHelperText>}
        </>
    );
};

export default CustomMultipleAutocompleteDropDown;
