import React, { useCallback, useState } from 'react';
import CustomFilterItem from '../../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../../Icons/IconFactory';
import PaperRow from '../../../../CustomPaper/PaperRow';
import CustomTextField from '../../../../CustomInput/CustomTextField';
import { debounce } from '@material-ui/core';

interface IProps {
    title: string;
    icon: EIcons;
    value?: string;
    onChange: (value?: string) => void;
}

const NumberRangeFilter: React.FC<IProps> = (props) => {
    const title = props.title;
    const icon = props.icon;
    const [value, setValue] = useState(props.value);

    const submit = (submitValue?: string) => {
        props.onChange(submitValue);
    }

    const debouncedChangeHandler = useCallback(
        debounce(submit, 300), []
    );

    const onChange = (value?: string) => {
        setValue(value);
        debouncedChangeHandler(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const deletable = Boolean(value);

    return <>
        <CustomFilterItem
            title={title}
            icon={icon}
            renderedValue={value}
            active={deletable}
            removeFilterAction={resetFilter}
            width={400}
        >
            <PaperRow>
                <CustomTextField
                    autoFocus
                    label={title}
                    value={value}
                    onChange={onChange}
                />
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(NumberRangeFilter);
