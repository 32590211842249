import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ClientApi } from '../../requests/ClientApi';
import { getCandidateProfilesRouteConfig } from '../../requests/routes';
import { ICandidateProfiles } from '../../definitions/entities.definition';
import { getToken } from '../../selectors/app.selectors';

export const useLoadProfiles = (tooltipOpen: boolean, candidateId: number) => {
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState<ICandidateProfiles|undefined>(undefined);

    useEffect(() => {
        if (!tooltipOpen) {
            return;
        }

        setLoading(true);
        ClientApi.request(getCandidateProfilesRouteConfig, {
            token,
            candidateId,
        }).then((result) => {
            setLoading(false);
            setProfiles(result);
        });
    }, [tooltipOpen]);

    return {
        profiles,
        loading
    };
}
