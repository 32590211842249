import {
    ISetApplicationPageApplicationToDeclineAction,
    ISetApplicationPageApplicationToEditAction,
    ISetApplicationPageFastSearchAction,
    ISetApplicationPageFilterAction,
    ISetApplicationPageFilterMenuAction,
    ISetApplicationPageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED,
    ENTITIES_GET_APPLICATION_LIST_FULFILLED,
    ENTITIES_POST_RECRUITING_FULFILLED,
} from '../entities/entities.actions';
import { IApplicationPageState, preloadedStateApplicationPage } from '../../definitions/applicationPage.definitions';
import {
    APPLICATION_PAGE_RELOAD,
    APPLICATION_PAGE_RESET_FILTER,
    APPLICATION_PAGE_SET_APPLICATION_TO_DECLINE,
    APPLICATION_PAGE_SET_APPLICATION_TO_EDIT,
    APPLICATION_PAGE_SET_FAST_SEARCH,
    APPLICATION_PAGE_SET_FILTER,
    APPLICATION_PAGE_SET_FILTER_MENU,
    APPLICATION_PAGE_SET_SORTING_OPTION,
} from './applicationPage.actions';

const applicationPageReducer = (state = preloadedStateApplicationPage, action: TReducerAction): IApplicationPageState => {
    switch (action.type) {
        case ENTITIES_POST_RECRUITING_FULFILLED: {
            return {
                ...state,
                applicationToEdit: 0
            }
        }
        case APPLICATION_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetApplicationPageSortingOptionAction;
            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case APPLICATION_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetApplicationPageFastSearchAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case APPLICATION_PAGE_RESET_FILTER: {
            return {
                ...state,
                filter: preloadedStateApplicationPage.filter,
                filterMenu: preloadedStateApplicationPage.filterMenu
            }
        }
        case APPLICATION_PAGE_SET_FILTER: {
            const actionCast = action as ISetApplicationPageFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload,
                },
            };
        }

        case APPLICATION_PAGE_SET_APPLICATION_TO_EDIT: {
            const actionCast = action as ISetApplicationPageApplicationToEditAction;

            return {
                ...state,
                applicationToEdit: actionCast.payload
            };
        }
        case APPLICATION_PAGE_SET_APPLICATION_TO_DECLINE: {
            const actionCast = action as ISetApplicationPageApplicationToDeclineAction;

            return {
                ...state,
                applicationToDecline: actionCast.payload
            };
        }
        case APPLICATION_PAGE_RELOAD: {
            return {
                ...state,
                reload: true
            }
        }
        case ENTITIES_GET_APPLICATION_LIST_FULFILLED: {
            return {
                ...state,
                initialApplicationsLoaded: true,
                reload: false
            };
        }
        case ENTITIES_GET_APPLICATION_LIST_BY_VACANCY_FULFILLED: {
            return {
                ...state,
                reload: false
            }
        }
        case APPLICATION_PAGE_SET_FILTER_MENU: {
            const actionCast = action as ISetApplicationPageFilterMenuAction;

            return {
                ...state,
                filterMenu: { ...state.filterMenu, ...actionCast.payload },
            };
        }
        default:
            break;
    }

    return state;
};

export default applicationPageReducer;
