import React from 'react';
import { EDocumentStatus } from '../../definitions/billingPage.definitions';
import { translate } from '../../translation/translate.utils';

interface IProps {
    status: EDocumentStatus;
}

const DocumentStatusCellRenderer: React.FC<IProps> = (props) => {
    return (
        <>
            {translate('pages.billing.documentStatusValues.' + props.status)}
        </>
    );
};

export default DocumentStatusCellRenderer;
