import React, { useState } from 'react';
import { EEntities, IInvoiceItem, TBrainzEntity } from '../../../../definitions/entities.definition';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CurrencyCellRenderer from '../../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { ECellAlign } from '../../../../definitions/components.definitions';
import InvoiceItemAddEditView from './InvoiceItemAddEditView';
import { translate } from '../../../../translation/translate.utils';
import TopBarButton from '../../../../components/ListAndPreview/TopBarButton';
import TopBarPanel from '../../../../components/ListAndPreview/TopBarPanel';
import { IInvoiceItemForm } from '../../../../definitions/billingPage.definitions';
import { setBillingPageDeleteInvoiceItemAction } from '../../../../redux/billingPage/billingPage.actions';
import { useDispatch } from 'react-redux';

interface IProps {
    items: IInvoiceItemForm[];
}

const InvoiceItemsView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [addItem, setAddItem] = useState(false);
    const [editItem, setEditItem] = useState<undefined|IInvoiceItem>(undefined);
    const [editItemIndex, setEditItemIndex] = useState<undefined|number>(undefined);

    return (
        <div style={{padding: 10}}>
            <InvoiceItemAddEditView
                addItem={addItem}
                editItem={editItem}
                editItemIndex={editItemIndex}
                onClose={() => {
                    setAddItem(false);
                    setEditItem(undefined);
                    setEditItemIndex(undefined);
                }}
            />
            <TopBarPanel>
                <TopBarButton
                    onClick={() => setAddItem(true)}
                    icon={EIcons.Add}
                    label={"Position hinzufügen"}
                />
            </TopBarPanel>
            <CustomTable
                config={{
                    entity: EEntities.InvoiceItems,
                    columnConfig: {
                        ranking: {
                            header: '#',
                            property: "ranking",
                            width: 60,
                        },
                        title: {
                            header: 'Bezeichnung',
                            property: "title",
                        },
                        amount: {
                            header: translate('pages.billing.amount'),
                            property: "amount",
                            width: 60,
                        },
                        price: {
                            header: translate('pages.billing.price'),
                            align: ECellAlign.right,
                            property: "price",
                            width: 100,
                            cellRenderer: (value) => {
                                if (!value) {
                                    return null;
                                }
                                return <CurrencyCellRenderer value={value} />
                            }
                        },
                        priceTotal: {
                            header: 'Gesamtpreis',
                            align: ECellAlign.right,
                            property: "priceTotal",
                            width: 100,
                            cellRenderer: (value) => {
                                if (!value) {
                                    return null;
                                }
                                return <CurrencyCellRenderer value={value} />
                            }
                        },
                    },
                    sortedEntries: props.items,
                    loading: false,
                    onRowDoubleClick: (invoiceId: number, item: TBrainzEntity, index: number) => {
                        setEditItem(item as IInvoiceItem);
                        setEditItemIndex(index);
                        setAddItem(true);
                    },
                    listActionButtons: [{
                        action: (invoiceId: number, item: TBrainzEntity, index: number) => {
                            dispatch(setBillingPageDeleteInvoiceItemAction(index));
                        },
                        translationKey: "tables.openDeleteView",
                        icon: EIcons.Delete,
                    }],
                    count: props.items.length,
                    total: props.items.length
                }}
            />
        </div>
    );
}

export default InvoiceItemsView;
