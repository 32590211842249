import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setCandidatePageFilterAction,
    setCandidatePageFilterMenuAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import BaseCandidateFilterPanel from './BaseCandidateFilterPanel';
import {
    preloadedCandidatePageDateRangeFilter,
    preloaededCandidatePageFilterMenu,
} from '../../../../definitions/candidatePage.definitions';

const CandidateFilterPanel: React.FC = () => {
    const currentSorting = useSelector((store: IStore) => store.candidatePage.sorting);
    const filter = useSelector((store: IStore) => store.candidatePage.filter);
    const filterMenuInStore = useSelector((store: IStore) => store.candidatePage.filterMenu);

    return (
        <>
            <BaseCandidateFilterPanel
                filterMenuInStore={filterMenuInStore}
                setFilterMenuInStore={setCandidatePageFilterMenuAction}
                filter={filter}
                setFilterAction={setCandidatePageFilterAction}
                currentSorting={currentSorting}
                initialFilterMenu={preloaededCandidatePageFilterMenu}
                initialDateRangeFilter={preloadedCandidatePageDateRangeFilter}
            />
        </>
    );
}

export default React.memo(CandidateFilterPanel);
