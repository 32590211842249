import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { TNoticePeriod } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: string;
}

const NoticePeriodDropDown: React.FC<IProps> = (props) => {
    const noticePeriodValues: TNoticePeriod[] = ["no", "4_weeks", "6_weeks", "8_weeks", "12_weeks", "more_than_12_weeks"];

    let menuItems = noticePeriodValues.map((noticePeriod: TNoticePeriod, index: number) => (
        <MenuItem key={index + 1} value={noticePeriod}>
            {translate('misc.noticePeriodValue.' + noticePeriod)}
        </MenuItem>
    ));
    menuItems = [
        <MenuItem key={0} value={0}>
            {translate('misc.choose')}
        </MenuItem>,
        ...menuItems,
    ];

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            id={"notice-period-dropdown"}
            label={translate('misc.noticePeriod')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default NoticePeriodDropDown;
