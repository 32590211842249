import React from 'react';
import { useDispatch } from 'react-redux';
import { ArgumentAxis, Chart, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { IGroupedSuggestionWithStatusEvaluationData } from '../../../../../definitions/dashboardPage.definitions';
import { Animation, Axis, BarSeries, EventTracker, Stack, ValueScale } from '@devexpress/dx-react-chart';
import { translate } from '../../../../../translation/translate.utils';
import { setVacancyPageSuggestionListViewOpenAction } from '../../../../../redux/vacancyPage/vacancyPage.actions';
import { ECandidateSource } from '../../../../../definitions/entities.definition';
import { COLOR_GREEN, COLOR_RED, COLOR_YELLOW } from '../../../../../theme/theme';

interface IProps {
    vacancyId: number;
    chartData: IGroupedSuggestionWithStatusEvaluationData[];
}
const SuggestionsByCandidateSourceBarChart: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const chartData = props.chartData;
    const barWidth = 1/10 * chartData.length;

    return (
        <>
            <Chart data={chartData} height={400}>
                <ValueScale name={"count"} />
                <ValueAxis
                    showGrid={false}
                    showLine
                    labelComponent={(labelProps: Axis.LabelProps) => {
                        if (labelProps.text.toString().indexOf('.') !== -1 && labelProps.text.toString().indexOf('.0') === -1) {
                            return null;
                        }

                        return <ValueAxis.Label
                            {...labelProps}
                            text={labelProps.text.toString().replace('.0', '')}
                            style={{fill: 'var(--brainz-color-text-secondary)'}}
                        />
                    }}
                />
                <ArgumentAxis
                    labelComponent={(labelProps: Axis.LabelProps) =>
                        <ValueAxis.Label
                            {...labelProps}
                            text={translate('pages.vacancy.externalCandidateSourceValues.' + labelProps.text)}
                            style={{fill: 'var(--brainz-color-text-secondary)'}}
                        />
                    }
                />
                <BarSeries
                    name={"countStatusDeclined"}
                    valueField={"countStatusDeclined"}
                    argumentField={"groupTitle"}
                    barWidth={barWidth}
                    color={COLOR_RED}
                />
                <BarSeries
                    name={"countStatusAccepted"}
                    valueField={"countStatusAccepted"}
                    argumentField={"groupTitle"}
                    barWidth={barWidth}
                    color={COLOR_GREEN}
                />
                <BarSeries
                    name={"countStatusOpen"}
                    valueField={"countStatusOpen"}
                    argumentField={"groupTitle"}
                    barWidth={barWidth}
                    color={COLOR_YELLOW}
                />
                <EventTracker onClick={(targetData) => {
                    if (targetData.targets.length === 0) {
                        return;
                    }
                    const data = props.chartData[targetData.targets[0].point];

                    dispatch(setVacancyPageSuggestionListViewOpenAction({
                        source: data.groupTitle as ECandidateSource
                    }));
                }}/>
                <Animation />
                <Stack stacks={[
                    {series: ['countStatusDeclined', 'countStatusAccepted', 'countStatusOpen']}
                ]} />
                <Tooltip contentComponent={(props) => {
                    const data = chartData[props.targetItem.point];
                    return (
                        <>
                            <div>{translate('pages.vacancy.externalCandidateSourceValues.' + data.groupTitle)}</div>
                            <div>Gesamt: {data.count}</div>
                            {data.countStatusOpen > 0 && <div><span style={{fontSize:20, color: COLOR_YELLOW}}>&#x2022;</span> aktiv: {data.countStatusOpen}</div>}
                            {data.countStatusAccepted > 0 && <div><span style={{fontSize:20, color: COLOR_GREEN}}>&#x2022;</span> angenommen: {data.countStatusAccepted}</div>}
                            {data.countStatusDeclined > 0 && <div><span style={{fontSize:20, color: COLOR_RED}}>&#x2022;</span> abgelehnt: {data.countStatusDeclined}</div>}
                        </>
                    )
                }}/>
            </Chart>
        </>

    );
}

export default SuggestionsByCandidateSourceBarChart;
