import React, { useCallback, useEffect, useState } from 'react';
import { EEntities, IJobTitle } from '../../../definitions/entities.definition';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { ClientApi } from '../../../requests/ClientApi';
import { getJobTitleListRouteConfig } from '../../../requests/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomDumbTextField from '../../../components/CustomInput/CustomDumbTextField';
import { debounce } from '@material-ui/core';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import SelectJobTitleViewSearchList from './SelectJobTitleViewSearchList';
import SelectJobTitleViewSelectedList from './SelectJobTitleViewSelectedList';
import CustomFilterMenu from '../../../components/CustomFilter/CustomPopupFilterMenu/CustomFilterMenu';
import TopBarPanel from '../../../components/ListAndPreview/TopBarPanel';
import { IJobTitleFilter, IJobTitleFilterMenu } from '../../../definitions/jobTemplatePage.definitions';

interface IProps {
    open: boolean;
    onClose: () => void;
    initialJobTitles?: IJobTitle[];
    setJobTitles: (jobTitles: IJobTitle[]) => void;
}

const SelectJobTitleView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [loading, setLoading] = useState(false);
    const [jobTitles, setJobTitles] = useState<IJobTitle[]>([]);
    const [jobTitlesTotal, setJobTitlesTotal] = useState(0);
    const [selectedJobTitles, setSelectedJobTitles] = useState(props.initialJobTitles || []);
    const [addAll, setAddAll] = useState(false);
    const [filter, setFilter] = useState<IJobTitleFilter>({
        start: 0,
        limit: 50,
        onlyWithoutJobTemplate: true,
        skillIds: []
    });
    const [filterMenu, setFilterMenu] = useState<IJobTitleFilterMenu>({
        onlyWithoutJobTemplate: true,
        skills: []
    });

    useEffect(() => {
        setSelectedJobTitles(props.initialJobTitles || []);
    }, [props.initialJobTitles])

    const load = () => {
        setLoading(true);
        ClientApi.request(getJobTitleListRouteConfig, {
            token,
            filterFastSearch: filter.fastSearch,
            onlyWithoutJobTemplate: filter.onlyWithoutJobTemplate,
            skillIds: filter.skillIds,
            start: filter.start,
            limit: filter.limit
        }).then((result: {records: IJobTitle[], total: number}) => {
            setLoading(false);
            if (addAll) {
                setSelectedJobTitles([
                    ...selectedJobTitles,
                    ...result.records
                ]);
                setJobTitles([]);
                setAddAll(false);
                return;
            }

            const selectedJobTitleIds = selectedJobTitles.map((jobTitle) => jobTitle.id);
            setJobTitles(
                result.records.filter((jobTitle) => selectedJobTitleIds.indexOf(jobTitle.id) === -1)
            );
            setJobTitlesTotal(result.total);
        });
    }

    useEffect(() => {
        if (props.open) {
            load()
        }
    }, [dispatch, props.open, filter]);

    const changeHandler = (callable: () => void) => {
        callable();
    }

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    const addJobTitle = (addJobTitle: IJobTitle) => {
        setSelectedJobTitles([
            ...selectedJobTitles,
            addJobTitle
        ]);
        setJobTitles(jobTitles.filter((jobTitle) => jobTitle.id !== addJobTitle.id));
    }

    const removeJobTitle = (jobTitleToRemove: IJobTitle) => {
        setSelectedJobTitles(selectedJobTitles.filter((jobTitle) => jobTitle.id !== jobTitleToRemove.id))
    }

    const onDrop = (dropResult: DropResult) => {
        if (dropResult.source.droppableId === EEntities.JobTitleSelectSearch.toString()) {
            addJobTitle(jobTitles[dropResult.source.index]);
            return;
        }

        const jobTitleToRemove = selectedJobTitles[dropResult.source.index];
        removeJobTitle(jobTitleToRemove);
    }

    const onClose = () => {
        props.onClose();
        setFilter({
            start: 0,
            limit: 50,
            onlyWithoutJobTemplate: true,
            skillIds: []
        });
        setFilterMenu({
            onlyWithoutJobTemplate: true,
            skills: []
        });
        setSelectedJobTitles([]);
    }

    return (
        <CustomDialog fullWidth open={props.open} onClose={props.onClose}>
            <CustomDialogHeader translationString={'misc.selectJobTitles'} onClose={props.onClose} />
            <PaperRow>
                <TopBarPanel>
                    <CustomDumbTextField
                        autoFocus
                        label={translate('misc.fastSearch')}
                        style={{width: 500}}
                        onChange={(event) => {
                            debouncedChangeHandler(() => setFilter({
                                ...filter,
                                fastSearch: event.target.value
                            }));
                        }}
                    />
                    <CustomFilterMenu
                        entity={EEntities.JobTitleSelectSearch}
                    />
                </TopBarPanel>
            </PaperRow>
            <div style ={{
                display: "flex",
                flex: "1 1 0%",
                overflow: "hidden",
                padding: 10
            }}>
                <DragDropContext onDragEnd={onDrop}>
                    <SelectJobTitleViewSearchList
                        onReload={load}
                        loading={loading}
                        jobTitles={jobTitles || []}
                        addJobTitle={addJobTitle}
                        total={jobTitlesTotal}
                        filter={filter}
                        setFilter={setFilter}
                    />
                    <div style={{padding: 5}}>&nbsp;</div>
                    <SelectJobTitleViewSelectedList
                        jobTitles={selectedJobTitles || []}
                        setSelectedJobTitles={setSelectedJobTitles}
                        removeJobTitle={removeJobTitle}
                    />
                </DragDropContext>
            </div>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    hidden: (filter.fastSearch === undefined || filter.fastSearch === '') && filter.skillIds.length === 0,
                    color: 'primary',
                    label: 'Alle ' + jobTitlesTotal + ' Job-Titel übernehmen',
                    void: () => {
                        setAddAll(true);
                        setFilter({
                            ...filter,
                            limit: jobTitlesTotal
                        });
                    }
                }, {
                    void: () => {
                        props.setJobTitles(selectedJobTitles);
                        props.onClose();
                    },
                    color: 'secondary',
                    label: translate('misc.useJobTitles'),
                }]}
            />
        </CustomDialog>
    );
}

export default SelectJobTitleView
