import { debounce, IconButton, InputBase, Paper } from '@material-ui/core';
import styles from '../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel.module.css';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerAppRecruitingFilterAction } from '../../../redux/customerApp/customerApp.actions';
import { translate } from '../../../translation/translate.utils';

const RecruitingPageTopBar: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchCallback = (fastSearch: string) => {
        dispatch(setCustomerAppRecruitingFilterAction({fastSearch}));
    }

    const [innerValue, setInnerValue] = useState("");

    const changeHandler = (fastSearch: string) => {
        fastSearchCallback(fastSearch);
    };

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );

    return <div id={"fastSearchContainer"} className={"flex2 alignVerticalCenter"}>
        <Paper variant="elevation" elevation={3} className={styles.root + " flex flexCentered gap5"}>
            <InputBase
                role="presentation"
                autoFocus
                inputProps={{
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    role: "presentation"
                }}
                className={styles.input}
                placeholder={translate('customerApp.fastSearchRecruitingEmptyText')}
                value={innerValue}
                onChange={(event) => {
                    setInnerValue(event.target.value);
                    debouncedChangeHandler(event.target.value);
                }}
            />
            <IconButton className={styles.iconButton} aria-label="search">
                {IconFactory.getIconComponent(EIcons.Search, {color: "var(--brainz-color-app-bar-filter)"})}
            </IconButton>
        </Paper>
    </div>
}

export default RecruitingPageTopBar
