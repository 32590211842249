import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CandidateSuggestionList from './components/CandidateSuggestionList';
import { setRecruitingPageAddSuggestionAction } from '../../../../redux/recruitingPage/recruitingPage.actions';
import FastSearchPanelSuggestionList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelSuggestionList';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { setUiExpandCollapseSuggestionListItem } from '../../../../redux/ui/ui.actions';

interface IProps {
    candidateId: number;
}

const CandidateSuggestionPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const candidateId = props.candidateId;
    const collapseOrExpandAll = useSelector((store: IStore) => store.ui.expandOrCollapseSuggestionListItems);

    return <>
        <div className={"flexColumn padding5"}>
            <FastSearchPanelSuggestionList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                actionButtons={[{
                    action: () => dispatch(setRecruitingPageAddSuggestionAction({
                        open: true,
                        candidateId: props.candidateId
                    })),
                    tooltip: 'Neuen Favoriten hinzufügen',
                    icon: EIcons.Add
                }, {
                    action: () => dispatch(setUiExpandCollapseSuggestionListItem(collapseOrExpandAll === 'expand' ? 'collapse' : 'expand')),
                    tooltip: collapseOrExpandAll === 'expand' ? 'Alle zuklappen' : 'Alle aufklappen',
                    icon: collapseOrExpandAll === 'expand' ? EIcons.FullscreenExit : EIcons.Fullscreen
                }]}
            />
            <CandidateSuggestionList candidateId={candidateId}/>
        </div>
    </>;
}

export default React.memo(CandidateSuggestionPanel);
