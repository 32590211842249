import React from 'react';
import {
    EActivityModule,
    ECandidateSource,
    EEntities,
    ERecruitingStatus,
    ERecruitingType,
    ICandidate,
    IEmployee,
    IRecruitingInterviewInformation,
    IRecruitingListModel,
    IVacancy,
    TBrainzEntity,
} from '../../../definitions/entities.definition';
import { translate } from '../../../translation/translate.utils';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import {
    setRecruitingPageFilterAction,
    setRecruitingPageRecruitingToContractAction,
    setRecruitingPageRecruitingToDeclineAction,
    setRecruitingPageRecruitingToDeleteAction,
    setRecruitingPageSortingOptionAction,
} from '../../../redux/recruitingPage/recruitingPage.actions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { connect, ConnectedProps } from 'react-redux';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import DateTimeCellRenderer from '../../../components/CustomCellRenderer/DateTimeCellRenderer';
import {
    ECellAlign,
    ESortingOptions,
    ICustomTableColumn,
    ICustomTableColumnConfig,
} from '../../../definitions/components.definitions';
import autobind from 'autobind-decorator';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getAllRecruitingAction, updateRecruitingAction } from '../../../redux/entities/entities.actions';
import { getRecruitingPageRequestObject } from '../../../selectors/recruiting.selectors';
import { getToken } from '../../../selectors/app.selectors';
import {
    getDefaultCompanyColumn,
    getDefaultCreatedColumn,
    getDefaultLastActivityColumn,
    INFINITE_SCROLL_INTERVAL,
} from '../../../utils/components.utils';
import {
    setArchivePageFilterAction,
    setArchivePageSortingOptionAction,
} from '../../../redux/archivePage/archivePage.actions';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { Link, Tooltip } from '@material-ui/core';
import EmployeeTimeCellRenderer from '../../../components/CustomCellRenderer/EmployeeTimeCellRenderer';
import { ERecruitingPageTabs } from '../../../definitions/recruitingPage.definitions';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { setUiActivityAddDataAction, setUiActivityToEditAction, setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import WebVacancyCellRenderer from '../../../components/CustomCellRenderer/WebVacancyCellRenderer';
import UrlCellRenderer from '../../../components/CustomCellRenderer/UrlCellRenderer';
import { COLOR_RED } from '../../../theme/theme';
import CustomClickableDiv from '../../../components/CustomInput/CustomClickableDiv';
import RecruitingInterviewsCellRenderer from '../../../components/CustomCellRenderer/RecruitingInterviewsCellRenderer';
import { ICustomerRecruitingListModel } from '../../../definitions/entities.customer.definitions';
import RecruitingStatusTimelineCellRenderer
    from '../../../PagesCustomer/RecruitingPage/components/components/RecruitingStatusTimelineCellRenderer';

interface IProps extends PropsFromRedux {
    recruitings: IRecruitingListModel[];
    status: ERecruitingStatus;
    tab: ERecruitingPageTabs;
    entity: EEntities;
}

type Columns =
    'lastActivity'
    | 'actions'
    | 'status'
    | 'candidate'
    | 'vacancy'
    | 'company'
    | 'dateOfAbort'
    | 'abortedBy'
    | 'responsibleEmployee'
    | 'expectedSalary'
    | 'possibleCommission'
    | 'commission'
    | 'openCommission';

type RecruitingColumnConfig = ICustomTableColumnConfig & {
    [column in Columns]: ICustomTableColumn;
};

class RecruitingList extends React.PureComponent<IProps> {
    columnConfig: RecruitingColumnConfig;

    constructor(props: IProps) {
        super(props);

        this.columnConfig = {
            actions: {
                header: <>Status<br/>ändern</>,
                property: 'status',
                width: 80,
                align: ECellAlign.center,
                disableOrder: true,
                cellRenderer: (status: ERecruitingStatus, entity) => {
                    const recruiting = entity as IRecruitingListModel;

                    if (status === ERecruitingStatus.STATUS_SUGGEST) {
                        return <>
                            <CustomClickableIcon
                                tooltip={"Prozess auf Status Absage ändern"}
                                onClick={() => this.props.setRecruitingPageRecruitingToDeclineAction(entity.id)} icon={EIcons.ThumbDown}
                            />
                            <CustomClickableIcon
                                tooltip={"Prozess auf Status Vorstellungsgespräch ändern (ohne Termin)"}
                                onClick={() => this.props.updateRecruitingAction(this.props.token, {
                                    id: recruiting.id,
                                    status: ERecruitingStatus.STATUS_INTERVIEW
                                })}
                                icon={EIcons.Group}
                            />
                            <CustomClickableIcon
                                tooltip={"Prozess auf Status Vorstellungsgespräch ändern (mit Termin)"}
                                onClick={() => this.props.setUiActivityAddDataAction({
                                    add: true,
                                    module: EActivityModule.recruiting,
                                    companyId: recruiting.company.id,
                                    vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                    candidateId: recruiting.candidate.id,
                                    recruitingId: recruiting.id,
                                    preSelectedEmployee: recruiting.responsibleEmployee,
                                    isInterview: true,
                                })}
                                icon={EIcons.Calendar}
                            />
                        </>
                    }

                    if (status === ERecruitingStatus.STATUS_INTERVIEW) {
                        return <>
                            <CustomClickableIcon
                                tooltip={"Prozess abbrechen"}
                                onClick={() => this.props.setRecruitingPageRecruitingToDeclineAction(entity.id)}
                                icon={EIcons.ThumbDown}
                            />
                            {
                                recruiting.interviewActionIsRequired && <>
                                    <CustomClickableIcon
                                        tooltip={"Vorstellungsgespräch hinzufügen"}
                                        onClick={() => this.props.setUiActivityAddDataAction({
                                            add: true,
                                            module: EActivityModule.recruiting,
                                            companyId: recruiting.company.id,
                                            vacancyId: recruiting.vacancy ? recruiting.vacancy.id : undefined,
                                            candidateId: recruiting.candidate.id,
                                            recruitingId: recruiting.id,
                                            preSelectedEmployee: recruiting.responsibleEmployee,
                                            isInterview: true,
                                        })}
                                        icon={EIcons.Calendar}
                                    />
                                </>
                            }
                            <CustomClickableIcon
                                tooltip={"Prozess auf Status Vermittelt ändern"}
                                onClick={() => this.props.setRecruitingPageRecruitingToContractAction(entity.id)}
                                icon={EIcons.ThumbUp}
                            />
                        </>
                    }
                }
            },
            status: {
                header: 'Status',
                property: "interviews",
                width: 300,
                orderByProperty: "nextAppointment",
                cellRenderer: (interviews: IRecruitingInterviewInformation[], entity) => {
                    const entityCast = entity as ICustomerRecruitingListModel;
                    return <RecruitingStatusTimelineCellRenderer recruiting={entityCast} allowOpenInterview/>
                },
            },
            dateOfAbort: {
                header: translate('pages.recruiting.dateOfAbort'),
                property: "dateOfAbort",
                width: 130,
                cellRenderer: (date: string) => {
                    return (<DateTimeCellRenderer date={date} />)
                },
            },
            type: {
                header: '#',
                property: 'type',
                width: 40,
                disableOrder: true,
                align: ECellAlign.center,
                cellRenderer: (type: ERecruitingType) => {
                    if (type !== ERecruitingType.TYPE_PROJECT) {
                        return (
                            <Tooltip title={"Festanstellung"}>
                                {IconFactory.getIconComponent(EIcons.Work)}
                            </Tooltip>
                        );
                    }

                    return (
                        <Tooltip title={"Projekt"}>
                            {IconFactory.getIconComponent(EIcons.AvTimer)}
                        </Tooltip>
                    );
                }
            },
            source: {
                header: translate('misc.source'),
                property: 'source',
                width: 60,
                align: ECellAlign.center,
                cellRenderer: (source: ECandidateSource) => {
                    return <CandidateSourceRenderer source={source}/>
                }
            },
            abortedBy: {
                header: translate('pages.recruiting.abortedBy'),
                property: "abortedBy",
                width: 130,
                cellRenderer: (abortedBy: string) => {
                    if (abortedBy) {
                        return translate('misc.' + abortedBy);
                    }
                    return translate('misc.noInformation');
                },
            },
            company: getDefaultCompanyColumn(),
            vacancy: {
                header: translate('pages.activity.properties.vacancy') + ' | ' + translate('misc.webVacancy') + ' | URL',
                property: "vacancy",
                cellRenderer: (vacancy: null | IVacancy, entity) => {
                    const recruiting = entity as IRecruitingListModel;

                    if (vacancy) {
                        return <>
                            <VacancyCellRenderer vacancy={vacancy} />
                        </>;
                    }

                    if (recruiting.webVacancy) {
                        return <>
                            <WebVacancyCellRenderer id={recruiting.webVacancy.id} title={recruiting.webVacancy.title} />
                        </>;
                    }

                    if (recruiting.url) {
                        return <>
                            <UrlCellRenderer url={recruiting.url} value={"URL öffnen"} />
                        </>;
                    }
                },
            },
            candidate: {
                header: translate('pages.activity.properties.candidate'),
                property: "candidate",
                cellRenderer: (candidate: ICandidate) => (
                    <CandidateCellRenderer candidate={candidate}/>
                ),
            },
            expectedSalary: {
                header: translate('pages.candidate.properties.expectedSalary'),
                property: "expectedSalaryString",
                align: ECellAlign.right,
                width: 200,
                cellRenderer: (expectedSalary: string, entity: TBrainzEntity) => {
                    const recruiting = entity as IRecruitingListModel;

                    return <>
                        <div className={"flexContainerColumn"}>
                            <div>
                                {expectedSalary ? expectedSalary + ' €' : 'Keine Angabe'}
                            </div>
                            {recruiting.desiredYearSalary &&
                                <div className={'formContent'}>
                                    Vorgeschlagen für: {recruiting.desiredYearSalary/1000}k €
                                </div>
                            }
                        </div>
                    </>;
                },
            },
            possibleCommission: {
                header: translate('pages.vacancy.possibleCommission'),
                property: "possibleCommission",
                width: 100,
                align: ECellAlign.right,
                cellRenderer: (commission: number) => {
                    if (commission) {
                        return (<CurrencyCellRenderer roundedToThousand value={commission} />)
                    }
                    return '-';
                },
            },
            commission: {
                header: 'Provision Gesamt & abgerechnet',
                property: "possibleCommission",
                width: 120,
                align: ECellAlign.right,
                cellRenderer: (commission: number, entity) => {
                    const recruiting = entity as IRecruitingListModel;

                    if (recruiting.type !== ERecruitingType.TYPE_PROJECT && commission && commission > 0) {
                        return <>
                            <div className={"flexContainerColumn"}>
                                <div>
                                    <CurrencyCellRenderer roundedToThousand value={commission} />
                                </div>
                                <div className={'formContent'}>
                                    <CurrencyCellRenderer roundedToThousand value={recruiting.billedCommission} />
                                </div>
                            </div>
                        </>;
                    }

                    if (commission) {
                        return (<CurrencyCellRenderer roundedToThousand value={commission} />)
                    }
                    return '-';
                },
            },
            openCommission: {
                header: <div className="content" dangerouslySetInnerHTML={{__html: translate('pages.recruiting.openCommission')}} />,
                property: "openCommission",
                orderByProperty: "endOfTrialPeriod",
                width: 100,
                align: ECellAlign.right,
                cellRenderer: (commission: number, entity) => {
                    const recruiting = entity as IRecruitingListModel;

                    if (recruiting.type !== ERecruitingType.TYPE_PROJECT && commission && commission > 0) {
                        return <>
                            <div className={"flexContainerColumn"}>
                                <div>
                                    <CurrencyCellRenderer roundedToThousand value={commission} />
                                </div>
                                <div className={'formContent'}>
                                    <DateDiffCellRenderer date={recruiting.endOfTrialPeriod as string}/>
                                </div>
                            </div>
                        </>;
                    }

                    return '-';
                }
            },
            lastActivity: getDefaultLastActivityColumn(),
            responsibleEmployee: {
                header: translate('misc.employeeShort'),
                property: "responsibleEmployee",
                align: ECellAlign.center,
                width: 100,
                cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                    const recruitingCast = recruiting as IRecruitingListModel;

                    return (
                        <>
                            <div className={"flex flexCentered"}>
                                {recruitingCast.recommendationBy &&
                                    <Tooltip title={translate('pages.recruiting.recommendationBy') + ': ' + recruitingCast.recommendationBy}>
                                        <div>
                                            {IconFactory.getIconComponent(EIcons.Flag)}&nbsp;
                                        </div>
                                    </Tooltip>
                                }
                                <EmployeeCellRenderer employee={responsibleEmployee} />
                                { (recruitingCast.suggestedBy && recruitingCast.suggestedBy.id !== responsibleEmployee.id) &&
                                    <>
                                        <h1 style={{margin:0,fontWeight: "normal"}}>&nbsp;|&nbsp;</h1>
                                        <EmployeeCellRenderer employee={recruitingCast.suggestedBy} />
                                    </>
                                }
                            </div>
                        </>
                    );
                },
            },
            employeeTimes: {
                header: 'Beteiligung',
                property: "responsibleEmployee",
                width: 120,
                cellRenderer: (responsibleEmployee: IEmployee, recruiting: TBrainzEntity) => {
                    const recruitingCast = recruiting as IRecruitingListModel;

                    return <div style={{height: 30, flex: 1}}>
                        <EmployeeTimeCellRenderer times={recruitingCast.employeeTimes} recruitingId={recruitingCast.id} />
                    </div>
                }
            },
        };

        const { status } = this.props;
        const columnConfig: Partial<RecruitingColumnConfig> = this.columnConfig;

        if (status === ERecruitingStatus.STATUS_SUGGEST) {
            delete columnConfig.dateOfAbort;
            delete columnConfig.abortedBy;
            delete columnConfig.openCommission;
            delete columnConfig.commission;
        }

        if (status === ERecruitingStatus.STATUS_INTERVIEW) {
            delete columnConfig.dateOfAbort;
            delete columnConfig.abortedBy;
            delete columnConfig.openCommission;
            delete columnConfig.commission;
        }

        if (status === ERecruitingStatus.STATUS_CONTRACT || status === ERecruitingStatus.STATUS_FINISH || status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD) {
            delete columnConfig.actions;
            delete columnConfig.lastActivity;
            delete columnConfig.dateOfAbort;
            delete columnConfig.abortedBy;
            delete columnConfig.possibleCommission;
        }

        if (status === ERecruitingStatus.STATUS_ABORT) {
            delete columnConfig.actions;
            delete columnConfig.lastActivity;
            delete columnConfig.openCommission;
            delete columnConfig.commission;
        }
    }

    @autobind
    handleRowDoubleClick(recruitingId: number, entity: TBrainzEntity) {
        const recruiting = entity as IRecruitingListModel;
        this.props.setUiEditView(EEntityView.recruiting, recruitingId);
    }

    componentDidMount() {
        const isRecruitingPage = this.props.status === ERecruitingStatus.STATUS_INTERVIEW || this.props.status === ERecruitingStatus.STATUS_CONTRACT || this.props.status === ERecruitingStatus.STATUS_SUGGEST;
        if (!isRecruitingPage) {
            this.props.getAllRecruitingAction(
                this.props.token,
                this.props.requestObject,
                this.props.status
            );
        }
    }

    /*shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
        return !nextProps.recruitingViewOpen && nextProps.activeTab === this.props.tab;
    }*/

    render() {
        const columnConfigCast = this.columnConfig as unknown as ICustomTableColumnConfig;
        const isRecruitingPage = this.props.status === ERecruitingStatus.STATUS_INTERVIEW || this.props.status === ERecruitingStatus.STATUS_CONTRACT || this.props.status === ERecruitingStatus.STATUS_SUGGEST;

        return <>
            <CustomTable
                config={{
                    loading: this.props.loading,
                    //color: isRecruitingPage ? COLOR_ORANGE : dark,
                    entity: this.props.entity,
                    listActionButtons: [{
                        action: (id) =>  this.props.setRecruitingPageRecruitingToDeleteAction(id),
                        icon: EIcons.Delete,
                        translationKey: 'pages.recruiting.deleteDialog.title'
                    }],
                    columnConfig: columnConfigCast,
                    sortedEntries: this.props.recruitings,
                    onRowDoubleClick: this.handleRowDoubleClick,
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                            if (isRecruitingPage) {
                                this.props.setRecruitingPageSortingOptionAction(propertyToSort, sortOption)
                                return;
                            }

                            this.props.setArchivePageSortingOptionAction(propertyToSort, sortOption);
                            return;
                        }
                    },
                    scrollCallback: () => {
                        if (this.props.filter.limit && this.props.filter.limit < this.props.total) {
                            if (isRecruitingPage) {
                                this.props.setRecruitingPageFilterAction({
                                    start: 0,
                                    limit: this.props.filter.limit + INFINITE_SCROLL_INTERVAL,
                                }, false);
                                return;
                            }

                            this.props.setArchivePageFilterAction({
                                start: 0,
                                limit: this.props.filter.limit + INFINITE_SCROLL_INTERVAL,
                            });
                            return;
                        }
                    },
                    count: this.props.count,
                    total: this.props.total,
                    onReload: () => this.props.getAllRecruitingAction(
                        this.props.token,
                        this.props.requestObject,
                        this.props.status
                    ),
                    footerText: this.props.status === ERecruitingStatus.STATUS_CONTRACT ? 'Summe ausstehender Provision: ' + this.props.openCommissionSum : undefined
                }}
            />
        </>;
    }
}

//@ts-ignore
const mapState = (store: IStore, props) => {
    let count = 0;
    let total = 0;
    const filter = store.recruitingPage.filterRecruitingsGlobal;

    switch (props.status) {
        case ERecruitingStatus.STATUS_CONTRACT:
            count = store.entities.recruiting.orderContract.length;
            total = store.entities.recruiting.totalContract;
            filter.start = store.recruitingPage.filterTabRecruitingsContract.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsContract.limit;
            break;
        case ERecruitingStatus.STATUS_INTERVIEW:
            count = store.entities.recruiting.orderInterview.length;
            total = store.entities.recruiting.totalInterview;
            filter.start = store.recruitingPage.filterTabRecruitingsInterview.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsInterview.limit;
            break;
        case ERecruitingStatus.STATUS_SUGGEST:
            count = store.entities.recruiting.orderSuggest.length;
            total = store.entities.recruiting.totalSuggest;
            filter.start = store.recruitingPage.filterTabRecruitingsSuggest.start;
            filter.limit = store.recruitingPage.filterTabRecruitingsSuggest.limit;
            break;
        case ERecruitingStatus.STATUS_ABORT:
            count = store.entities.recruiting.orderAbort.length;
            total = store.entities.recruiting.totalAbort;
            filter.start = store.archivePage.filterTabRecruitingsAbort.start;
            filter.limit = store.archivePage.filterTabRecruitingsAbort.limit;
            break;
        case ERecruitingStatus.STATUS_FINISH:
            count = store.entities.recruiting.orderFinish.length;
            total = store.entities.recruiting.totalFinish;
            filter.start = store.archivePage.filterTabRecruitingsFinish.start;
            filter.limit = store.archivePage.filterTabRecruitingsFinish.limit;
            break;
        case ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD:
            count = store.entities.recruiting.orderTrialPeriodFailed.length;
            total = store.entities.recruiting.totalTrialPeriodFailed;
            filter.start = store.archivePage.filterTabRecruitingsTrialNotFinished.start;
            filter.limit = store.archivePage.filterTabRecruitingsTrialNotFinished.limit;
            break;
    }

    return ({
        token: getToken(store),
        recruitingViewOpen: store.ui.openViews.length > 0 && store.ui.openViews[0].view === EEntityView.recruiting,
        count,
        total,
        filter,
        openCommissionSum: store.entities.recruiting.openCommissionSumContract,
        requestObject: getRecruitingPageRequestObject(store, props.status),
        loading: store.ui.recruitingsLoading,
        activeTab: store.recruitingPage.activeTab
    });
}

const mapDispatch = {
    setRecruitingPageSortingOptionAction,
    setUiEditView,
    getAllRecruitingAction,
    setRecruitingPageFilterAction,
    setArchivePageFilterAction,
    setArchivePageSortingOptionAction,
    setRecruitingPageRecruitingToDeclineAction,
    setRecruitingPageRecruitingToContractAction,
    setUiActivityAddDataAction,
    setUiActivityToEditAction,
    setRecruitingPageRecruitingToDeleteAction,
    updateRecruitingAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RecruitingList);
