import {
    ISetArchivePageActiveTabAction,
    ISetArchivePageFastSearchAction,
    ISetArchivePageFilterAction,
    ISetArchivePageSortingOptionAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    EArchivePageTabs,
    IArchivePageState,
    preloadedStateArchivePage,
} from '../../definitions/archivePage.definitions';
import {
    ARCHIVE_PAGE_SET_ACTIVE_TAB,
    ARCHIVE_PAGE_SET_FAST_SEARCH,
    ARCHIVE_PAGE_SET_FILTER,
    ARCHIVE_PAGE_SET_SORTING_OPTION,
} from './archivePage.actions';

const archivePageReducer = (state = preloadedStateArchivePage, action: TReducerAction): IArchivePageState => {
    switch (action.type) {
        case ARCHIVE_PAGE_SET_ACTIVE_TAB: {
            const actionCast = action as ISetArchivePageActiveTabAction;

            return {
                ...state,
                activeTab: actionCast.payload
            }
        }
        case ARCHIVE_PAGE_SET_SORTING_OPTION: {
            const actionCast = action as ISetArchivePageSortingOptionAction;

            switch (state.activeTab) {
                case EArchivePageTabs.tabVacancies:
                    return {
                        ...state,
                        sortingVacancyList: {
                            [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                        },
                    };
                case EArchivePageTabs.tabRecruitingsAbort:
                    return {
                        ...state,
                        sortingTabRecruitingsAbort: {
                            [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                        },
                    };
                case EArchivePageTabs.tabRecruitingsFinished:
                    return {
                        ...state,
                        sortingTabRecruitingsFinish: {
                            [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                        },
                    };
                case EArchivePageTabs.tabRecruitingsTrialPeriodFailed:
                    return {
                        ...state,
                        sortingTabRecruitingsTrialNotFinished: {
                            [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                        },
                    };
            }

            return {
                ...state
            };
        }
        case ARCHIVE_PAGE_SET_FAST_SEARCH: {
            const actionCast = action as ISetArchivePageFastSearchAction;

            switch (state.activeTab) {
                case EArchivePageTabs.tabVacancies:
                    return {
                        ...state,
                        filterVacancyList: {
                            ...state.filterVacancyList,
                            fastSearch: actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsAbort:
                    return {
                        ...state,
                        filterTabRecruitingsAbort: {
                            ...state.filterTabRecruitingsAbort,
                            fastSearch: actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsFinished:
                    return {
                        ...state,
                        filterTabRecruitingsFinish: {
                            ...state.filterTabRecruitingsFinish,
                            fastSearch: actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsTrialPeriodFailed:
                    return {
                        ...state,
                        filterTabRecruitingsTrialNotFinished: {
                            ...state.filterTabRecruitingsTrialNotFinished,
                            fastSearch: actionCast.payload
                        },
                    };
            }

            return {
                ...state
            };
        }
        case ARCHIVE_PAGE_SET_FILTER: {
            const actionCast = action as ISetArchivePageFilterAction;

            switch (state.activeTab) {
                case EArchivePageTabs.tabVacancies:
                    return {
                        ...state,
                        filterVacancyList: {
                            ...state.filterVacancyList,
                            ...actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsAbort:
                    return {
                        ...state,
                        filterTabRecruitingsAbort: {
                            ...state.filterTabRecruitingsAbort,
                            ...actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsFinished:
                    return {
                        ...state,
                        filterTabRecruitingsFinish: {
                            ...state.filterTabRecruitingsFinish,
                            ...actionCast.payload
                        },
                    };
                case EArchivePageTabs.tabRecruitingsTrialPeriodFailed:
                    return {
                        ...state,
                        filterTabRecruitingsTrialNotFinished: {
                            ...state.filterTabRecruitingsTrialNotFinished,
                            ...actionCast.payload
                        },
                    };
            }

            return {
                ...state
            };
        }
    }

    return state;
};

export default archivePageReducer;
