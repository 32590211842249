import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { getVacanciesForCandidateSearchAction } from '../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import SearchVacancyList from './components/SearchVacancyList';
import SearchVacancyFilterPanel from './components/SearchVacancyFilterPanel';
import { getCandidatePageVacancySearchListRequestObject } from '../../../selectors/vacancy.selectors';
import { setUiSearchVacancyView } from '../../../redux/ui/ui.actions';
import { getCurrentEditViewEnum } from '../../../selectors/ui.selectors';
import { EEntityView } from '../../../definitions/ui.definitions';

const SearchVacancyView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => store.ui.searchForCandidateId > 0);
    const currentEditView = useSelector(getCurrentEditViewEnum);
    const candidateId = useSelector((store: IStore) => store.ui.searchForCandidateId);
    const candidate = useSelector((store: IStore) => store.entities.candidates.byId[candidateId]);
    const requestObject = useSelector(getCandidatePageVacancySearchListRequestObject);

    const onClose = () => {
        dispatch(setUiSearchVacancyView(0));
    }

    useEffect(() => {
        if (!open) {
            return;
        }

        dispatch(getVacanciesForCandidateSearchAction(
            token,
            requestObject
        ));
    }, [open, JSON.stringify(requestObject)]);

    if (!open || !candidate || currentEditView === EEntityView.vacancy || currentEditView === EEntityView.webVacancy) {
        return null;
    }

    return <>
        <CustomDialog fullScreen fullWidth open={open} onClose={onClose}>
            <CustomDialogHeader string={<>Interner Suchlauf für Kandidat <br/><div className={"formContent"}> {candidate.fullName}</div></>} onClose={onClose}>
                <SearchVacancyFilterPanel />
            </CustomDialogHeader>
            <div className={"DialogContent"}>
                <SearchVacancyList candidateId={candidateId}/>
            </div>
        </CustomDialog>
    </>;
};

export default SearchVacancyView;
