import {
    IPostJobTemplateAction,
    IPutJobTemplateAction,
    ISetJobTemplatePageAddDialogAction,
    ISetJobTemplatePageFilterAction,
    ISetJobTemplatePageJobTitleFilterAction,
    ISetJobTemplatePageJobTitleFilterMenuAction,
    ISetJobTemplatePageJobTitleSortingOptionAction,
    ISetJobTemplatePageSetJobTemplateAction,
    ISetJobTemplatePageSortingOptionAction,
    ISetJobTemplatePageTemplateToEditAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    JOB_TEMPLATE_PAGE_RESET_JOB_TITLE_FILTER,
    JOB_TEMPLATE_PAGE_SET_ADD_DIALOG,
    JOB_TEMPLATE_PAGE_SET_FILTER,
    JOB_TEMPLATE_PAGE_SET_JOB_TEMPLATE,
    JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER,
    JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER_MENU,
    JOB_TEMPLATE_PAGE_SET_JOB_TITLE_SORTING,
    JOB_TEMPLATE_PAGE_SET_SORTING,
    JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT,
} from './jobTemplatePage.actions';
import { IJobTemplatePageState, preloadedStateJobTemplatePage } from '../../definitions/jobTemplatePage.definitions';
import {
    ENTITIES_GET_JOB_TEMPLATE_FULFILLED,
    ENTITIES_GET_JOB_TEMPLATE_PENDING,
    ENTITIES_GET_JOB_TITLE_LIST_FULFILLED,
    ENTITIES_GET_JOB_TITLE_LIST_REJECTED,
    ENTITIES_POST_JOB_TEMPLATE_FULFILLED,
    ENTITIES_POST_JOB_TEMPLATE_PENDING,
    ENTITIES_POST_JOB_TEMPLATE_REJECTED,
    ENTITIES_PUT_JOB_TEMPLATE_FULFILLED,
    ENTITIES_PUT_JOB_TEMPLATE_PENDING,
    ENTITIES_PUT_JOB_TEMPLATE_REJECTED,
    ENTITIES_PUT_JOB_TITLE_FULFILLED,
} from '../entities/entities.actions';

const jobTemplatePageReducer = (state = preloadedStateJobTemplatePage, action: TReducerAction): IJobTemplatePageState => {
    switch (action.type) {
        case JOB_TEMPLATE_PAGE_SET_SORTING: {
            const actionCast = action as ISetJobTemplatePageSortingOptionAction;

            return {
                ...state,
                sorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption
                }
            }
        }
        case JOB_TEMPLATE_PAGE_SET_JOB_TITLE_SORTING: {
            const actionCast = action as ISetJobTemplatePageJobTitleSortingOptionAction;

            return {
                ...state,
                jobTitleSorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption
                }
            }
        }
        case JOB_TEMPLATE_PAGE_SET_FILTER: {
            const actionCast = action as ISetJobTemplatePageFilterAction;

            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...actionCast.payload
                }
            }
        }
        case JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER: {
            const actionCast = action as ISetJobTemplatePageJobTitleFilterAction;

            return {
                ...state,
                jobTitleFilter: {
                    ...state.jobTitleFilter,
                    ...actionCast.payload
                }
            }
        }
        case JOB_TEMPLATE_PAGE_SET_JOB_TITLE_FILTER_MENU: {
            const actionCast = action as ISetJobTemplatePageJobTitleFilterMenuAction;

            return {
                ...state,
                jobTitleFilterMenu: {
                    ...state.jobTitleFilterMenu,
                    ...actionCast.payload
                }
            }
        }
        case JOB_TEMPLATE_PAGE_RESET_JOB_TITLE_FILTER: {
            return {
                ...state,
                jobTitleFilter: {
                    ...preloadedStateJobTemplatePage.jobTitleFilter
                },
                jobTitleFilterMenu: {
                    ...preloadedStateJobTemplatePage.jobTitleFilterMenu
                }
            }
        }
        case JOB_TEMPLATE_PAGE_SET_TEMPLATE_TO_EDIT: {
            const actionCast = action as ISetJobTemplatePageTemplateToEditAction;

            return {
                ...state,
                templateToEdit: actionCast.payload
            }
        }
        case JOB_TEMPLATE_PAGE_SET_ADD_DIALOG: {
            const actionCast = action as ISetJobTemplatePageAddDialogAction;

            return {
                ...state,
                addDialogOpen: actionCast.payload
            }
        }
        case JOB_TEMPLATE_PAGE_SET_JOB_TEMPLATE: {
            const actionCast = action as ISetJobTemplatePageSetJobTemplateAction;

            return {
                ...state,
                setJobTemplateForJobTitle: actionCast.payload.jobTitleId
            }
        }

        case ENTITIES_POST_JOB_TEMPLATE_PENDING:
        case ENTITIES_PUT_JOB_TEMPLATE_PENDING:
        case ENTITIES_GET_JOB_TEMPLATE_PENDING: {
            return {
                ...state,
                editViewLoading: true,
            };
        }
        case ENTITIES_POST_JOB_TEMPLATE_REJECTED:
        case ENTITIES_PUT_JOB_TEMPLATE_REJECTED:
        case ENTITIES_GET_JOB_TEMPLATE_FULFILLED: {
            return {
                ...state,
                editViewLoading: false,
            };
        }
        case ENTITIES_POST_JOB_TEMPLATE_FULFILLED: {
            const actionCast = action as IPostJobTemplateAction;

            return {
                ...state,
                editViewLoading: false,
                addDialogOpen: false,
                templateToEdit: actionCast.payload.id,
            };
        }

        case ENTITIES_PUT_JOB_TEMPLATE_FULFILLED: {
            const actionCast = action as IPutJobTemplateAction;

            return {
                ...state,
                editViewLoading: false,
                templateToEdit: (actionCast.meta.closeEditView) ? 0 : state.templateToEdit
            };
        }

        case ENTITIES_GET_JOB_TITLE_LIST_REJECTED:
        case ENTITIES_GET_JOB_TITLE_LIST_FULFILLED: {
            return {
                ...state,
                reloadJobTitles: false
            };
        }
        case ENTITIES_PUT_JOB_TITLE_FULFILLED: {
            return {
                ...state,
                reloadJobTitles: true
            };
        }
    }
    return state;
};

export default jobTemplatePageReducer;
