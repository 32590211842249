import {
    IPutTodoAction,
    ISetTodoAddDialogOpenAction,
    ISetTodoSortingAction,
    ISetTodoToDeleteAction,
    ISetTodoToEditAction,
    IToggleDueTodoHeaderAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    TODO_SET_ACTIVITY_SORTING,
    TODO_SET_ADD_DIALOG_OPEN,
    TODO_SET_TODO_TO_DELETE,
    TODO_SET_TODO_TO_EDIT,
    TODO_TOGGLE_DUE_TODO_HEADER,
} from './todo.actions';
import {
    ENTITIES_GET_DUE_TODO_LIST_FULFILLED,
    ENTITIES_GET_TODO_LIST_FULFILLED,
    ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED,
    ENTITIES_POST_ACTIVITY_FULFILLED,
    ENTITIES_POST_TODO_FULFILLED,
    ENTITIES_PUT_ACTIVITY_FULFILLED,
    ENTITIES_PUT_TODO_FULFILLED,
    ENTITIES_PUT_TODO_ORDER_REJECTED,
} from '../entities/entities.actions';
import { ITodoState, preloadedTodoState } from '../../definitions/todo.definitions';

const todoReducer = (state = preloadedTodoState, action: TReducerAction): ITodoState => {
    switch (action.type) {
        case ENTITIES_POST_ACTIVITY_FULFILLED:
        case ENTITIES_PUT_ACTIVITY_FULFILLED: {
            return {
                ...state,
                reloadTodos: true
            }
        }
        case ENTITIES_PUT_TODO_FULFILLED: {
            const actionCast = action as IPutTodoAction;

            if (actionCast.meta.closeEditView) {
                return {
                    ...state,
                    reloadTodos: true,
                    todoToEdit: 0,
                    addTodo: false
                }
            }

            return {
                ...state
            };
        }
        case ENTITIES_PUT_TODO_ORDER_REJECTED:
        case ENTITIES_POST_TODO_FULFILLED: {
            return {
                ...state,
                reloadTodos: true,
                todoToEdit: 0,
                addTodo: false
            }
        }
        case TODO_SET_TODO_TO_EDIT: {
            const actionCast = action as ISetTodoToEditAction;

            return {
                ...state,
                todoToEdit: actionCast.payload
            };
        }
        case TODO_SET_TODO_TO_DELETE: {
            const actionCast = action as ISetTodoToDeleteAction;

            return {
                ...state,
                todoToDelete: actionCast.payload
            };
        }
        case TODO_SET_ADD_DIALOG_OPEN: {
            const actionCast = action as ISetTodoAddDialogOpenAction;

            return {
                ...state,
                addTodo: actionCast.payload
            };
        }
        case ENTITIES_GET_TODO_LIST_FULFILLED:
        case ENTITIES_GET_DUE_TODO_LIST_FULFILLED:
        case ENTITIES_GET_TODO_LIST_OF_EMPLOYEE_FULFILLED: {
            return {
                ...state,
                reloadTodos: false
            }
        }
        case TODO_SET_ACTIVITY_SORTING: {
            const actionCast = action as ISetTodoSortingAction;
            return {
                ...state,
                activitySorting: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case TODO_TOGGLE_DUE_TODO_HEADER: {
            const actionCast = action as IToggleDueTodoHeaderAction;
            const currentState = state.employeeDueTodoCollapsedState[actionCast.payload];

            return {
                ...state,
                employeeDueTodoCollapsedState: {
                    ...state.employeeDueTodoCollapsedState,
                    [actionCast.payload]: !currentState
                }
            }
        }
    }

    return {
        ...state
    };
}

export default todoReducer;
