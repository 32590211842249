import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EEntities, IJobTitle, ISkillPartial } from '../../../definitions/entities.definition';
import { INFINITE_SCROLL_INTERVAL } from '../../../utils/components.utils';
import { ESortingOptions } from '../../../definitions/components.definitions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { getJobTemplateListAction } from '../../../redux/entities/entities.actions';
import { getJobTemplateListFromStore, getJobTemplateListRequestObject } from '../../../selectors/jobTemplate.selectors';
import {
    setJobTemplatePageFilterAction,
    setJobTemplatePageSortingOptionAction,
    setJobTemplatePageTemplateToEditAction,
} from '../../../redux/jobTemplate/jobTemplatePage.actions';

const JobTemplateList: React.FC = () => {
    const dispatch = useDispatch();
    const jobTemplates = useSelector(getJobTemplateListFromStore);
    const loading = useSelector((store: IStore) => store.ui.jobTemplatesLoading);
    const filter = useSelector((store: IStore) => store.jobTemplatePage.filter);
    const totalRecords = useSelector((store: IStore) => store.entities.jobTemplate.total);
    const requestObject = useSelector(getJobTemplateListRequestObject);
    const token = useSelector(getToken);

    return (
        <>
            <CustomTable
                config={{
                    entity: EEntities.JobTemplate,
                    onRowDoubleClick: (employeeId: number) => dispatch(setJobTemplatePageTemplateToEditAction(employeeId)),
                    columnConfig: {
                        name: {
                            header: 'Name',
                            property: "name",
                        },
                        skills: {
                            header: 'Skills',
                            property: "skills",
                            cellRenderer: (value: ISkillPartial[]) => {
                                return value.length;
                            }
                        },
                        jobTitles: {
                            header: 'Job-Titel',
                            property: "jobTitles",
                            cellRenderer: (value: IJobTitle[]) => {
                                return value.length;
                            }
                        },
                    },
                    sortedEntries: jobTemplates,
                    loading: loading,
                    scrollCallback: () => {
                        if (filter.limit && filter.limit < totalRecords) {
                            dispatch(setJobTemplatePageFilterAction({
                                start: 0,
                                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
                            }));
                        }
                    },
                    tableHeaderCallbacks: {
                        setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                            dispatch(setJobTemplatePageSortingOptionAction(propertyToSort, sortOption)),
                    },
                    onReload: () => {
                        dispatch(getJobTemplateListAction(token, requestObject));
                    },
                    count: jobTemplates.length,
                    total: totalRecords
                }}
            />
        </>
    );
}

export default JobTemplateList;
