import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

interface IProps {
    onClick: React.MouseEventHandler;
    className?: string;
    style?: React.CSSProperties;
    id?: string;
    flex?: boolean;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            cursor: "pointer",
        }
    })
);
const CustomClickableDiv: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const className = props.className ? `${props.className} ${styles.root}` : styles.root;

    return (
        <div
            id={props.id}
            style={props.style}
            onClick={(event) => {
                event.stopPropagation();
                props.onClick(event);
            }}
            className={className}
        >
            {props.children}
        </div>
    );
};

export default CustomClickableDiv;
