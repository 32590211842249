import { MenuItem } from '@material-ui/core';
import React from 'react';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';

interface IProps {
    onChange: (value?: boolean) => void;
    value?: boolean;
    label: string;
    yesText?: string;
    noText?: string;
    allText?: string;
}

const YesNoShowAllDropDown: React.FC<IProps> = (props) => {
    let value = -1;

    if (props.value === true) {
        value = 1;
    }

    if (props.value === false) {
        value = 0;
    }

    return (
        <CustomDropDownInput
            id={"years-no-showAll-dropdown" + props.label}
            onChange={(event) => {
                let boolValue = undefined;
                if (event.target.value === 1) {
                    boolValue = true;
                }
                if (event.target.value === 0) {
                    boolValue = false;
                }

                props.onChange(boolValue);
            }}
            value={value}
            label={props.label}
        >
            <MenuItem key={1} value={1}>
                {props.yesText ?? translate('misc.yes')}
            </MenuItem>
            <MenuItem key={2} value={0}>
                {props.noText ?? translate('misc.no')}
            </MenuItem>
            <MenuItem key={3} value={-1}>
                {props.allText ?? translate('misc.showAll')}
            </MenuItem>
        </CustomDropDownInput>
    );
};

export default YesNoShowAllDropDown;
