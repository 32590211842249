import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { getWebVacancyPageActivitiesRequestObject } from '../../../selectors/activity.selectors';
import { getActivityListForWebVacancyAction } from '../../../redux/entities/entities.actions';
import { EActivityModule } from '../../../definitions/entities.definition';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import { getToken } from '../../../selectors/app.selectors';
import { setUiActivityAddDataAction } from '../../../redux/ui/ui.actions';
import FastSearchPanelActivityList from '../../App/TopBar/FastSearchPanel/FastSearchPanelActivityList';
import WebVacancyActivityList from './components/WebVacancyActivityList';

interface IProps {
    webVacancyId: number;
}

const WebVacancyActivityPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const webVacancyId = props.webVacancyId;
    const webVacancy = useSelector((store: IStore) => store.entities.webVacancy.byId[webVacancyId]);
    const activityRequestObject = useSelector(getWebVacancyPageActivitiesRequestObject);

    return <>
        <div className={"flexColumn padding5"}>
            <FastSearchPanelActivityList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                addAction={() => dispatch(setUiActivityAddDataAction({
                    add: true,
                    module: EActivityModule.webVacancy,
                    webVacancyId: webVacancy.id,
                    companyId: webVacancy.company.id
                }))}
            />
            <WebVacancyActivityList webVacancyId={webVacancyId}/>
            <CustomTableFooter
                style={{height: 35}}
                onReload={() => {
                    dispatch(getActivityListForWebVacancyAction(token, webVacancyId, activityRequestObject));
                }}
            />
        </div>
    </>;
}

export default React.memo(WebVacancyActivityPanel);
