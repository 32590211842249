import React from 'react';
import { DialogActions, Divider } from '@material-ui/core';
import CustomButton from './CustomButton';
import { ISplitButtonConfig } from '../../definitions/components.definitions';
import CustomSplitButton from './CustomSplitButton';
import { translate } from '../../translation/translate.utils';

export interface IActionButton {
    void?: () => void;
    color?: 'primary'|'secondary';
    disabled?: boolean;
    hidden?: boolean;
    tooltip?: string;
    label: string;
    options?: ISplitButtonConfig[]
}

interface IProps {
    onClose?: () => void;
    buttons?: IActionButton[];
    divider?: boolean;
}

const CustomDialogActions: React.FC<IProps> = (props) => {
    const divider = props.divider;
    return (
        <>
            {divider &&
                <Divider orientation={"horizontal"} style={{marginTop: 5, width: '100%', backgroundColor: 'var(--brainz-darkest)'}}/>
            }
            <DialogActions>
                <div className={"flexUnConfigured gap10"}>
                    {props.buttons && props.buttons.map((buttonConfig, index) => {
                        if (buttonConfig.hidden) {
                            return null;
                        }
                        if (buttonConfig.options) {
                            return (
                                <CustomSplitButton
                                    key={index}
                                    label={buttonConfig.label}
                                    options={buttonConfig.options}
                                />
                            );
                        }

                        return (
                            <CustomButton
                                tooltip={buttonConfig.tooltip}
                                disabled={buttonConfig.disabled}
                                key={index}
                                color={buttonConfig.color || "primary"}
                                onClick={buttonConfig.void as () => void}
                                label={buttonConfig.label}
                            />
                        );
                    })}
                    {props.onClose && (
                        <CustomButton
                            color={"secondary"}
                            onClick={props.onClose as () => void}
                            label={translate("misc.buttons.close")}
                        />
                    )}
                </div>
            </DialogActions>
        </>
    );
};

export default CustomDialogActions;
