import React from 'react';
import { ICompanyPartial, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getCompanyListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomMultipleAutocomplete from '../CustomMultipleAutocomplete';

interface IProps {
    value: ICompanyPartial[]|number[];
    onChange: (value: ICompanyPartial[]) => void;
    label?: string;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    onlyWithWebVacancies?: boolean;
    onlyWithRecruitings?: boolean;
}

const CompanyMultipleAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const onlyWithWebVacancies = props.onlyWithWebVacancies;
    const onlyWithRecruitings = props.onlyWithRecruitings;

    const onChange = (value: TBrainzEntity[]) => {
        props.onChange(value as ICompanyPartial[]);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as ICompanyPartial).name;
    }

    return <CustomMultipleAutocomplete
        id={"company-multiple-autocomplete"}
        label={props.label || translate('misc.companies')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getCompanyListRouteConfig}
        getOptionLabel={getOptionLabel}
        routeParams={{
            start: 0,
            limit: 0,
            orderBy: 'name',
            orderDirection: 'ASC',
            onlyWithCrawler: onlyWithWebVacancies,
            onlyWithRecruitings: onlyWithRecruitings
        }}
    />;
}

export default React.memo(CompanyMultipleAutocomplete);
