import React, { useCallback, useEffect, useState } from 'react';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import { translate } from '../../../../translation/translate.utils';
import SelectMultipleSkillView from '../../../Shared/Skill/SelectMultipleSkillView';
import {
    EEntities,
    IEntitySkill,
    IJobTemplate,
    IJobTemplateForApi,
    IJobTitle,
    ISkillPartial,
} from '../../../../definitions/entities.definition';
import TopBarButtonPanelWithPadding from '../../../../components/ListAndPreview/TopBarButtonPanelWithPadding';
import TopBarButton from '../../../../components/ListAndPreview/TopBarButton';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { sortSkills } from '../../../../utils/skills.utils';
import SelectJobTitleView from '../../SelectJobTitle/SelectJobTitleView';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import { addJobTemplateAction, updateJobTemplateAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import { setSkillPageSkillToEditAction } from '../../../../redux/skillPage/skillPage.actions';
import CustomDumbTextField from '../../../../components/CustomInput/CustomDumbTextField';
import { debounce } from '@material-ui/core';
import CustomTabContainer from '../../../../components/CustomTabContainer/CustomTabContainer';
import { themeDark } from '../../../../theme/theme';

interface IProps {
    onClose: () => void;
    initialJobTemplate?: IJobTemplate;
}

const JobTemplateForm: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [addSkillViewOpen, setAddSkillViewOpen] = useState(false);
    const [addJobTitleViewOpen, setAddJobTitleViewOpen] = useState(false);
    const [name, setName]= useState(props.initialJobTemplate?.name || '');
    const [skills, setSkills] = useState(props.initialJobTemplate?.skills ? sortSkills(props.initialJobTemplate.skills) : []);
    const [jobTitles, setJobTitles] = useState(props.initialJobTemplate?.jobTitles.sort((a, b) => a.title.localeCompare(b.title)) || []);
    const [jobTitleFastSearch, setJobTitleFastSearch] = useState('');
    const [filteredJobTitles, setFilteredJobTitles] = useState<IJobTitle[]>([]);

    useEffect(() => {
        if (!props.initialJobTemplate) {
            return;
        }

        setName(props.initialJobTemplate.name);
        setSkills(sortSkills(props.initialJobTemplate.skills));
        setJobTitles(props.initialJobTemplate.jobTitles);
    }, [props.initialJobTemplate]);

    useEffect(() => {
        setFilteredJobTitles(
            jobTitles.filter((jobTitle) => jobTitle.title.indexOf(jobTitleFastSearch) > -1)
        )
    }, [jobTitleFastSearch]);

    const save = (closeWindow: boolean) => {
        const jobTemplate: IJobTemplateForApi = {
            skills: skills.map((skill) => {
                return {
                    skillId: skill.skill.id,
                }
            }),
            jobTitleIds: jobTitles.map((jobTitle) => jobTitle.id),
            name
        }

        if (!props.initialJobTemplate) {
            dispatch(addJobTemplateAction(token, jobTemplate, closeWindow));
            return;
        }

        jobTemplate.id = props.initialJobTemplate.id;
        dispatch(updateJobTemplateAction(token, jobTemplate, closeWindow));
        return;
    }

    const removeJobTitle = (jobTitleToRemove: IJobTitle) => {
        setJobTitles(jobTitles.filter((jobTitle) => jobTitle.id !== jobTitleToRemove.id))
        setFilteredJobTitles(filteredJobTitles.filter((jobTitle) => jobTitle.id !== jobTitleToRemove.id))
    }

    const removeSkill = (skillToRemove: ISkillPartial) => {
        setSkills(skills.filter((skill) => skill.skill.id !== skillToRemove.id))
    }

    const debouncedJobTitleFastSearchHandler = useCallback(
        debounce((value: string) => {
            setJobTitleFastSearch(value);
        }, 300), []
    );

    return (
        <>
            <div style={{display: "flex", flexDirection: "column", overflow: "hidden", padding: 10, flex: "1 1 auto"}}>
                <div style={{minHeight: 80, display: "flex"}}>
                    <CustomTextField
                        label={"Name der Berufsgruppe"}
                        value={name}
                        onChange={(value) => setName(value)}
                    />
                </div>
                <CustomTabContainer
                    color={themeDark.palette.primary.main}
                    tabs={[{
                        label: `${translate('misc.skills')} (${skills.length})`,
                        component: (
                            <>
                                <TopBarButtonPanelWithPadding>
                                    <TopBarButton
                                        onClick={() => {
                                            setAddSkillViewOpen(true);
                                        }}
                                        icon={EIcons.Add}
                                        label={translate('misc.addSkill')}
                                    />
                                </TopBarButtonPanelWithPadding>
                                <CustomTable
                                    style={{height: '100%'}}
                                    config={{
                                        entity: EEntities.JobTemplateSkills,
                                        columnConfig: {
                                            edit: {
                                                header: IconFactory.getIconComponent(EIcons.Edit),
                                                width: 40,
                                                property: 'id',
                                                cellRenderer: (value: number, entity) => {
                                                    const skill = entity as IEntitySkill;
                                                    return <CustomClickableIcon
                                                        onClick={() => dispatch(setSkillPageSkillToEditAction(skill.skill.id))}
                                                        icon={EIcons.Edit}
                                                    />
                                                }
                                            },
                                            skill: {
                                                header: 'Skill',
                                                property: "skill.name",
                                            },
                                            keywords: {
                                                header: 'Keywords',
                                                property: "skill.keywords"
                                            },
                                            remove: {
                                                header: IconFactory.getIconComponent(EIcons.Delete),
                                                width: 40,
                                                property: 'id',
                                                cellRenderer: (value: number, entity) => {
                                                    const skill = entity as IEntitySkill;
                                                    return <CustomClickableIcon onClick={() => removeSkill(skill.skill)} icon={EIcons.Delete} />
                                                }
                                            }
                                        },
                                        sortedEntries: skills,
                                        count: skills.length,
                                    }}
                                />
                            </>

                        )
                    }, {
                        label: `${translate('misc.jobTitles')} (${jobTitleFastSearch ? filteredJobTitles.length : jobTitles.length})`,
                        component: (
                            <>
                                <TopBarButtonPanelWithPadding>
                                    <TopBarButton
                                        onClick={() => {
                                            setAddJobTitleViewOpen(true);
                                        }}
                                        icon={EIcons.Add}
                                        label={translate('misc.addJobTitle')}
                                    />
                                    <div style={{width: 300, paddingLeft: 10}}>
                                        <CustomDumbTextField
                                            size={"small"}
                                            label={"Schnellsuche"}
                                            onChange={(event) => debouncedJobTitleFastSearchHandler(event.target.value)}
                                        />
                                    </div>
                                </TopBarButtonPanelWithPadding>
                                <CustomTable
                                    style={{height: '100%'}}
                                    config={{
                                        entity: EEntities.JobTemplateJobTitles,
                                        columnConfig: {
                                            jobTitle: {
                                                header: 'Job-Titel',
                                                property: "title",
                                            },
                                            remove: {
                                                header: IconFactory.getIconComponent(EIcons.Delete),
                                                width: 40,
                                                property: 'id',
                                                cellRenderer: (value: number, entity) => {
                                                    const jobTitle = entity as IJobTitle;
                                                    return <CustomClickableIcon onClick={() => removeJobTitle(jobTitle)} icon={EIcons.Delete} />
                                                }
                                            }
                                        },
                                        sortedEntries: jobTitleFastSearch ? filteredJobTitles : jobTitles,
                                        count: jobTitleFastSearch ? filteredJobTitles.length : jobTitles.length,
                                    }}
                                />
                            </>
                        )
                    }]}
                />
            </div>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: () => save(false),
                    label: translate('misc.buttons.save')
                }, {
                    void: () => save(true),
                    label: translate('misc.buttons.saveAndClose'),
                    hidden: props.initialJobTemplate === undefined
                }]}
            />
            <SelectMultipleSkillView
                open={addSkillViewOpen}
                onClose={() => setAddSkillViewOpen(false)}
                initialSkills={skills}
                saveSkills={setSkills}
            />
            <SelectJobTitleView
                open={addJobTitleViewOpen}
                onClose={() => setAddJobTitleViewOpen(false)}
                setJobTitles={setJobTitles}
                initialJobTitles={jobTitles}
            />
        </>
    );
}

export default JobTemplateForm;
