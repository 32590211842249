import React, { useCallback, useState } from 'react';
import CustomFilterItem from '../../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../../Icons/IconFactory';
import PaperRow from '../../../../CustomPaper/PaperRow';
import CustomNumberInput from '../../../../CustomInput/CustomNumberInput';

interface IProps {
    title: string;
    icon: EIcons;
    fromLabel: string;
    toLabel: string;
    valueLabel: string;
    from?: number;
    to?: number;
    onChange: (from?: number, to?: number) => void;
}

const NumberRangeFilter: React.FC<IProps> = (props) => {
    const title = props.title;
    const icon = props.icon;
    const fromLabel = props.fromLabel;
    const toLabel = props.toLabel;
    const valueLabel = props.valueLabel;
    const [from, setFrom] = useState(props.from);
    const [to, setTo] = useState(props.to);

    const submit = () => {
        props.onChange(from, to);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const renderValue = useCallback(() => {
        if (props.to === undefined && props.from === undefined) {
            return null;
        }

        if (props.to !== undefined && props.from !== undefined) {
            return <>
                Von: {props.from} {valueLabel}, Bis: {props.to} {valueLabel}
            </>
        }
        if (props.to !== undefined) {
            return <>
                Bis: {props.to} {valueLabel}
            </>
        }
        if (props.from !== undefined) {
            return <>
                Von: {props.from} {valueLabel}
            </>
        }
    }, [props.from, props.to, props.valueLabel]);

    const deletable = Boolean(props.from || props.to);
    const dataConsistent = props.from === from && props.to === to;

    return <>
        <CustomFilterItem
            title={title}
            icon={icon}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            width={"auto"}
            showDialog
            submit={submit}
            dataConsistent={dataConsistent}
        >
            <PaperRow>
                <CustomNumberInput
                    autoFocus
                    value={from}
                    onChange={setFrom}
                    label={fromLabel}
                />
                <CustomNumberInput
                    value={to}
                    onChange={setTo}
                    label={toLabel}
                    max={100}
                />
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(NumberRangeFilter);
