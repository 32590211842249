import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useEffect } from 'react';
import { getContactsForDropdownAction } from '../../../../redux/entities/entities.actions';
import { getCompanyContactsForDropdown } from '../../../../selectors/companyContacts.selectors';

export const useLoadContactsOfCompany = (companyId: number) => {
    const token = useSelector(getToken);
    const companyContacts = useSelector((store: IStore) => getCompanyContactsForDropdown(store, companyId));
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyId) {
            dispatch(getContactsForDropdownAction(token, companyId));
        }
    }, [companyId, token, dispatch]);

    return companyContacts;
}
