import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { setCompanyPageAddDialogOpenAction } from '../../redux/companyPage/companyPage.actions';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CompanyEditForm from '../EditViews/Company/components/CompanyEditForm';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { resetFormValidation } from '../../redux/error/error.actions';

const CompanyAddView: React.FC = () => {
    const open = useSelector((store: IStore) => store.companyPage.addDialogOpen);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(resetFormValidation());
        dispatch(setCompanyPageAddDialogOpenAction(false));
    };

    return (
        <CustomDialog maxWidth={"md"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString="pages.company.add" onClose={onClose} />
            <CompanyEditForm companyId={0} />
        </CustomDialog>
    );
};

export default CompanyAddView;
