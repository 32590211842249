export type TCustomerRecruitingListStatus = 'running'|'actionRequired'|'declined'|'contract';
export type TCustomerRecruitingListGroupBy = 'vacancy';

export interface ICustomerAppRecruitingFilter {
    fastSearch: string,
    status?: TCustomerRecruitingListStatus
}

export interface ICustomerAppState {
    recruiting: {
        filter: ICustomerAppRecruitingFilter
    }
}
export const preloadedStateCustomerApp: ICustomerAppState = {
    recruiting: {
        filter: {
            fastSearch: '',
            status: 'running'
        }
    }
}
