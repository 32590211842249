import React, { memo, ReactNode } from 'react';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import CustomTableRow from './CustomTableRow';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    ICustomTableColumnConfig,
    IListActionButtonConfig,
    TListAction,
} from '../../definitions/components.definitions';
import { createCustomTableCellsHook, detectScrollEnd } from '../../utils/components.utils';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import CustomBackdropLoadingInsideDiv from '../CustomBackdrop/CustomBackdropLoadingInsideDiv';

interface IProps {
    loading?: boolean;
    listActionButtons?: IListActionButtonConfig[];
    listActionHeading?: (entity: TBrainzEntity) => string;
    columnConfig: ICustomTableColumnConfig;
    sortedEntries: TBrainzEntity[];
    emptyText?: string|ReactNode;
    scrollCallback?: () => void;
    onDoubleClick?: TListAction;
    onClick?: TListAction;
    entity: EEntities;
    isRecordDraggable?: (record: TBrainzEntity) => boolean;
    denyVerticalCenter?: boolean;
}

const useStyles = makeStyles(
    createStyles({
        root: {
            height: "100%",
            overflowY: "scroll",
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        },
    })
);

export const TABLE_BODY_ID = "tableBody-";

const CustomTableBodyDragAndDrop: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const id = `${TABLE_BODY_ID}${props.entity}`;

    const onScroll = () => {
        const scrolledToBottom = detectScrollEnd(id);

        if (scrolledToBottom && props.scrollCallback) {
            if (props.loading) {
                return;
            }

            props.scrollCallback();
        }
    };

    return (
        <Droppable droppableId={props.entity.toString()}>
            {(provided) => (
                <div
                    id={id}
                    className={styles.root}
                    onScroll={onScroll}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {props.loading &&
                        <CustomBackdropLoadingInsideDiv open/>
                    }
                    {props.sortedEntries.map((record: TBrainzEntity, index) => {
                            const coloring = index % 2 === 0;

                            const cells = createCustomTableCellsHook(
                                props.denyVerticalCenter || false,
                                props.entity,
                                props.columnConfig,
                                record
                            );

                            const isDraggable = (props.isRecordDraggable) ? props.isRecordDraggable(record) : true;

                            return (
                                <Draggable
                                    key={record.id}
                                    isDragDisabled={!isDraggable}
                                    draggableId={(record.id as number).toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <CustomTableRow
                                                    index={index}
                                                    entity={props.entity}
                                                    onDoubleClick={props.onDoubleClick}
                                                    onClick={props.onClick}
                                                    entry={record}
                                                    key={index}
                                                    coloring={coloring}
                                                    isDragging={snapshot.isDragging}
                                                    listActionButtons={props.listActionButtons}
                                                    listActionHeading={props.listActionHeading}
                                                >
                                                    {cells}
                                                </CustomTableRow>
                                            </div>
                                        </>
                                    )}
                                </Draggable>
                            )
                        })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}

export default memo(CustomTableBodyDragAndDrop);
