import React from 'react';
import Login from './components/Login/Login';
import { EStorageItem } from './definitions/app.definitions';
import theme from './theme/theme';
import { ThemeProvider } from '@material-ui/core';

const AppNotLoggedIn: React.FC = () => {
    const tokenFromStorage = localStorage.getItem(EStorageItem.Token);

    return <>
        <div className="App">
            <ThemeProvider theme={theme}>
                <Login tokenFromStorage={tokenFromStorage}/>
            </ThemeProvider>
        </div>
    </>
}

export default React.memo(AppNotLoggedIn);
