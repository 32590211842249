import { Button, Tooltip } from '@material-ui/core';
import React, { PropsWithChildren, ReactNode } from 'react';

interface IProps extends PropsWithChildren<any>{
    onClick: VoidFunction;
    isActive?: boolean;
    disabled?: boolean;
    tooltip?: ReactNode;
}

const CustomQuickFilterButton: React.FC<IProps> = (props) => {
    const disabled = props.disabled || false;
    const tooltip = props.tooltip;
    const active = props.isActive || false;

    const style: React.CSSProperties = active ? {} : {
        borderColor: 'rgba(233, 237, 239, 0.2)',
        opacity: disabled ? 0.5 : 1,
        color: 'rgb(187, 187, 187)',
        maxHeight: 36
    };
    const variant = active ? 'contained' : 'outlined';
    const color = active ? 'default' : 'inherit';
    const onClick = () => {
        if (disabled) {
            return;
        }
        props.onClick();
    }

    const getButton = () => {
        return <Button
            variant={variant}
            color={color}
            onClick={onClick}
            style={style}
        >
            {props.children}
        </Button>;
    }

    if (tooltip) {
        return <>
            <Tooltip title={tooltip}>
                {getButton()}
            </Tooltip>
        </>
    }

    return <>
        {getButton()}
    </>
};

export default CustomQuickFilterButton;
