import { ICustomerAppState, preloadedStateCustomerApp } from '../../definitions/customerApp.definitions';
import { ISetCustomerAppRecruitingFilterAction, TCustomerAppReducerAction } from './customerApp.actions';

const customerAppReducer = (state = preloadedStateCustomerApp, action: TCustomerAppReducerAction): ICustomerAppState => {
    switch (action.type) {
        case 'CUSTOMER_APP_SET_RECRUITING_FILTER': {
            const actionCast = action as ISetCustomerAppRecruitingFilterAction;

            return {
                ...state,
                recruiting: {
                    ...state.recruiting,
                    filter: {
                        ...state.recruiting.filter,
                        ...actionCast.payload
                    }
                }
            }
        }
        default:
            return state;
    }
}

export default customerAppReducer;
