import React, { ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { consumeError, registerError } from '../../redux/error/error.actions';

class ErrorBoundary extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error);
        console.log(error.message);
    }

    render() {
        return this.props.children;
    }
}

const mapState = (store: IStore) => ({
    hasError: store.error.hasError,
    errorMessage: store.error.errorMessage,
});

const mapDispatch = {
    registerError,
    consumeError,
};

export default connect(mapState, mapDispatch)(ErrorBoundary);
