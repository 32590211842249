import React, { useState } from 'react';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import PaperRow from '../../../CustomPaper/PaperRow';
import { EIcons } from '../../../Icons/IconFactory';
import {
    EDateRangeMode,
    ERecruitingInformationFilterType,
    IDateRangeFilterApiProperties,
    IRecruitingInformationFilterMenu,
} from '../../../../definitions/filter.definitions';
import { objectsAreEqual } from '../../../../utils/application.utils';
import CompanyMultipleAutocomplete
    from '../../../CustomInput/CustomAutocomplete/components/CompanyMultipleAutocomplete';
import EmployeeMultipleAutocomplete
    from '../../../CustomInput/CustomAutocomplete/components/EmployeeMultipleAutocomplete';
import CustomDateRangeForm from '../../CustomDateRangeConfig/CustomDateRangeForm';
import RecruitingInformationTypeDropDown
    from '../../../CustomInput/CustomDropDownInput/RecruitingInformationTypeDropDown';
import RecruitingAbortedByDropDown from '../../../CustomInput/CustomDropDownInput/RecruitingAbortedByDropDown';
import RecruitingStatusDropDown from '../../../CustomInput/CustomDropDownInput/RecruitingStatusDropDown';
import { ERecruitingStatus } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import YesNoDropDown from '../../../CustomInput/CustomDropDownInput/YesNoDropDown';
import { getFormattedDateString } from '../../../../utils/date.utils';
import CustomInfo from '../../../CustomComponent/CustomInfo';

interface IProps {
    filter: Partial<IRecruitingInformationFilterMenu>;
    setFilter: (filter?: IRecruitingInformationFilterMenu) => void;
}

const getDateRangeFilterText = (dateRangeFilter: IDateRangeFilterApiProperties) => {
    if (dateRangeFilter.mode === EDateRangeMode.all) {
        return null;
    }

    if (dateRangeFilter.mode === EDateRangeMode.range) {
        return <>
            Zeitraum:
            {dateRangeFilter.dateFrom &&
                <>von: {getFormattedDateString(dateRangeFilter.dateFrom)} </>
            }
            {dateRangeFilter.dateTo &&
                <>bis: {getFormattedDateString(dateRangeFilter.dateTo)} </>
            }
        </>
    }

    if (dateRangeFilter.mode === EDateRangeMode.year) {
        return <>
            Zeitraum: Jahr {dateRangeFilter.year}
        </>
    }

    let label = 'Tage';
    if (dateRangeFilter.mode === EDateRangeMode.lastWeeks) {
        label = 'Wochen';
    }
    if (dateRangeFilter.mode === EDateRangeMode.lastMonth) {
        label = 'Monate';
    }

    return <>
        Zeitraum: Letzten {dateRangeFilter.lastDateRangeCount} {label}
    </>
}

const RecruitingInformationFilter: React.FC<IProps> = (props) => {
    const [filter, setFilter] = useState(props.filter);
    const initialDateRangeFilter: IDateRangeFilterApiProperties = {
        mode: EDateRangeMode.all,
        property: 'created'
    }

    const type = filter?.type;
    const companies = filter?.companies || [];
    const employees = filter?.employees || [];
    const abortedBy = filter?.abortedBy;
    const abortedAfter = filter?.abortedAfter;
    const finishedTrialPeriod = filter?.finishedTrialPeriod;
    const dateRangeFilter = filter?.dateRange || initialDateRangeFilter;

    const dataConsistent = filter !== undefined && props.filter !== undefined && objectsAreEqual(filter, props.filter) && (!filter.dateRange || objectsAreEqual(filter.dateRange, props.filter.dateRange));
    const dataValid = filter !== undefined && filter.type !== undefined;

    const submit = () => {
        props.setFilter(filter as IRecruitingInformationFilterMenu);
    }

    const resetFilter = () => {
        props.setFilter();
    }

    const deletable = props.filter && props.filter.type !== undefined;
    const renderValue = () => {
        if (deletable) {
            //@ts-ignore
            return <>
                {translate('misc.recruitingFilterType.' + type)}
                {(type === ERecruitingInformationFilterType.aborted) &&
                    <>
                        {abortedAfter &&
                            <><br/>Absage nach: {translate('pages.recruiting.statusValues.' + abortedAfter)}</>
                        }
                        {abortedBy &&
                            <><br/>Absage von: {translate('misc.' + abortedBy)}</>
                        }
                    </>
                }
                {companies.length > 0 &&
                    <><br/>Firmen: {companies.map((company) => company.name).join(', ')}</>
                }
                {employees.length > 0 &&
                    <><br/>Mitarbeiter: {employees.map((employee) => employee.fullName).join(', ')}</>
                }
                {dateRangeFilter.mode !== EDateRangeMode.all &&
                    <><br/>{getDateRangeFilterText(dateRangeFilter)}</>
                }
            </>
        }

        return null;
    }

    return <>
        <CustomFilterItem
            title={"Prozess-Informationen"}
            icon={EIcons.ThumbsUpDown}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            showDialog
            submit={submit}
            dataConsistent={dataConsistent}
            dataValid={dataValid}
            width={540}
        >
            <PaperRow>
                <RecruitingInformationTypeDropDown
                    value={type}
                    onChange={(type) => {
                        setFilter({
                            ...filter,
                            type
                        })
                    }}
                />
            </PaperRow>
            <CustomDateRangeForm
                filter={dateRangeFilter}
                setFilter={(dateRange) => {
                    setFilter({
                        ...filter,
                        dateRange
                    })
                }}
                label={"Prozesse im folgenden Zeitraum berücksichtigen"}
            />
            {type === ERecruitingInformationFilterType.neverSuggested &&
                <PaperRow>
                    <CustomInfo maxWidth={500}>
                        Bedenke das dieser Filter in Verbindung mit weiteren Filtern mehr Sinn ergibt.<br/>
                        Zum Beispiel könntest du den Zeitraum für die Kandidaten einschränken und so alle Kandidaten aus dem Vorjahr sehen die nicht vorgeschlagen wurden.
                    </CustomInfo>
                </PaperRow>
            }
            {type === ERecruitingInformationFilterType.aborted &&
                <PaperRow>
                    <RecruitingAbortedByDropDown
                        value={abortedBy}
                        onChange={(event) => {
                            setFilter({
                                ...filter,
                                abortedBy: event.target.value
                            })
                        }}
                        emptyValue={"egal"}
                    />
                    <RecruitingStatusDropDown
                        value={abortedAfter}
                        onChange={(event) => {
                            setFilter({
                                ...filter,
                                abortedAfter: event.target.value
                            })
                        }}
                        label={"Absage nach"}
                        emptyValue={"egal"}
                        values={[ERecruitingStatus.STATUS_SUGGEST, ERecruitingStatus.STATUS_INTERVIEW]}
                    />
                </PaperRow>
            }
            { type === ERecruitingInformationFilterType.withContract &&
            <PaperRow>
                <YesNoDropDown
                    onChange={(finishedTrialPeriod) => {
                        setFilter({
                            ...filter,
                            finishedTrialPeriod
                        })
                    }}
                    label={"Hat Probezeit bestanden"}
                    value={finishedTrialPeriod}
                />
            </PaperRow>
            }
            { (type !== ERecruitingInformationFilterType.neverSuggested) &&
            <>
                <PaperRow>
                    <CompanyMultipleAutocomplete
                        value={companies}
                        onChange={(companies) => {
                            setFilter({
                                ...filter,
                                companies
                            })
                        }}
                        label={"Nur Prozesse mit Firma berücksichtigen"}
                        onlyWithRecruitings
                    />
                </PaperRow>
                <PaperRow>
                    <EmployeeMultipleAutocomplete
                        value={employees}
                        onChange={(employees) => {
                            setFilter({
                                ...filter,
                                employees
                            })
                        }}
                        label={"Nur Prozesse mit Mitarbeiter berücksichtigen"}
                    />
                </PaperRow>
            </>
            }
        </CustomFilterItem>
    </>
};

export default React.memo(RecruitingInformationFilter);
