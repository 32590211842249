import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomBackdropLoading from '../../../components/CustomBackdrop/CustomBackdropLoading';
import SkillTreeView from '../../Shared/Skill/SkillTreeView';
import CustomTableFooter from '../../../components/CustomTable/CustomTableFooter';
import { getSkillTreeAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';
import { getIdsFromTree } from '../../../utils/skills.utils';

const SkillTreeTab: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const treeNodes = useSelector((store: IStore) => store.entities.skills.tree);
    const [expanded, setExpanded] = useState<string[]>([]);
    const loadingSkillTree = useSelector((store: IStore) => store.ui.skillTreeLoading);
    const loading = useSelector((store: IStore) => store.ui.skillTreeLoading);
    const filter = useSelector((store: IStore) => store.skillPage.filter);
    const treeTotal = useSelector((store: IStore) => store.entities.skills.treeTotal);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (filter.filterFastSearch !== '' && filter.filterFastSearch !== undefined && filter.filterFastSearch.length >= 3) {
            setExpanded(getIdsFromTree(treeNodes));
        } else {
            setExpanded([]);
        }
    }, [loadingSkillTree])

    return(
        <>
            <CustomBackdropLoading open={loading} />
            <div style={{flex: 1, overflow: "auto"}}>
                <SkillTreeView
                    initialExpanded={expanded}
                    treeNodes={treeNodes}
                    onSelect={(treeNode) => console.log(treeNode)}
                />
            </div>
            <CustomTableFooter
                totalCount={treeTotal}
                onReload={() => dispatch(getSkillTreeAction(token, filter))}
            />
        </>
    )
}

export default SkillTreeTab;
