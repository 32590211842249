import { IApiBaseFilter, ISorting } from './app.definitions';
import { EApplicationStatus, ICompany, IEmployee } from './entities.definition';
import { ESortingOptions } from './components.definitions';


export const preloadedApplicationFilter: IApplicationFilter = {
    fastSearch: '',
    dateFrom: '',
    dateTo: '',
    start: 0,
    limit: 50,
    status: [EApplicationStatus.open, EApplicationStatus.inspection]
}

export const preloadedApplicationFilterMenu: IApplicationFilterMenu = {
    dateFrom: '',
    dateTo: '',
    status: [EApplicationStatus.open, EApplicationStatus.inspection]
}

export const preloadedStateApplicationPage: IApplicationPageState = {
    initialApplicationsLoaded: false,
    sorting: {
        created: ESortingOptions.Descending,
    },
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    applicationToEdit: 0,
    filter: preloadedApplicationFilter,
    filterMenu: preloadedApplicationFilterMenu,
    applicationToDecline: 0,
    reload: false
};

export interface IApplicationPageState {
    initialApplicationsLoaded: boolean;
    applicationToEdit: number;
    applicationToDecline: number;
    sorting: ISorting;
    activitySorting: ISorting;
    filter: IApplicationFilter;
    filterMenu: IApplicationFilterMenu;
    reload: boolean;
}

export interface IApplicationFilter extends Partial<IApiBaseFilter> {
    employeeId?: number;
    dateFrom?: string;
    dateTo?: string;
    companyId?: number;
    status?: EApplicationStatus[];
}

export interface IApplicationFilterMenu {
    employee?: IEmployee;
    dateFrom?: string;
    dateTo?: string;
    company?: ICompany;
    status?: EApplicationStatus[];
}
