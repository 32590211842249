import React, { useState } from 'react';
import { ISkillPartial } from '../../../definitions/entities.definition';
import SelectSkillView from './SelectSkillView';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { useDispatch } from 'react-redux';

interface IProps {
    value?: ISkillPartial;
    onChange: (skill?: ISkillPartial) => void;
}

const SelectSkillInput: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    return (
        <>

            <SelectSkillView
                value={props.value}
                open={open}
                onChange={props.onChange}
                onClose={() => {
                    setOpen(false)
                }}
            />
            <CustomTextField
                label={"Skill"}
                style={{flex: 2}}
                value={props.value?.name}
                aria-readonly
                name={"skill"}
                onClick={() => {
                    setOpen(true);
                }}
            />
        </>
    );
}

export default SelectSkillInput
