import React from 'react';
import { getRecruitingsForCompanyAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { getCompanyPageRequestObjectForRecruitingsInEditView } from '../../../../../selectors/recruiting.selectors';
import { useCompanyRecruitingListHook } from './CompanyRecruitingList.hooks';
import RecruitingList from '../../../../Shared/RecruitingList/RecruitingList';
import { EEntityView } from '../../../../../definitions/ui.definitions';

interface IProps {
    companyId: number;
}

const CompanyRecruitingList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const recruitingRequestObject = useSelector(getCompanyPageRequestObjectForRecruitingsInEditView);
    const companyId = props.companyId;

    const { loading, recruitings, total} = useCompanyRecruitingListHook(companyId);

    return <>
        <RecruitingList
            recruitings={recruitings}
            total={total}
            loading={loading}
            parentView={EEntityView.company}
            onReload={() => {
                dispatch(getRecruitingsForCompanyAction(token, companyId, recruitingRequestObject));
            }}
        />
    </>
}

export default CompanyRecruitingList;
