import { ESortingOptions } from './components.definitions';
import { IVacancyFilter } from './vacancyPage.definitions';
import { IApiBaseFilter, ISorting } from './app.definitions';

export enum EArchivePageTabs {
    tabVacancies,
    tabRecruitingsFinished,
    tabRecruitingsAbort,
    tabRecruitingsTrialPeriodFailed
}

export interface IArchivePageState {
    vacanciesLoaded: boolean;
    sortingVacancyList: ISorting;
    filterVacancyList: IVacancyFilter;
    activeTab: EArchivePageTabs;
    sortingTabRecruitingsAbort: ISorting;
    filterTabRecruitingsAbort: IApiBaseFilter;
    sortingTabRecruitingsTrialNotFinished: ISorting;
    filterTabRecruitingsTrialNotFinished: IApiBaseFilter;
    sortingTabRecruitingsFinish: ISorting;
    filterTabRecruitingsFinish: IApiBaseFilter;
}

export const preloadedStateArchivePage: IArchivePageState = {
    vacanciesLoaded: false,
    sortingVacancyList: {
        'vacancy.created': ESortingOptions.Descending
    },
    filterVacancyList: {
        fastSearch: '',
        start: 0,
        limit: 50,
        responsibleEmployeeId: 0
    },
    activeTab: EArchivePageTabs.tabVacancies,
    sortingTabRecruitingsAbort: {
        'dateOfAbort': ESortingOptions.Descending
    },
    filterTabRecruitingsAbort: {
        start: 0,
        limit: 50
    },
    sortingTabRecruitingsTrialNotFinished: {
        'created': ESortingOptions.Descending
    },
    filterTabRecruitingsTrialNotFinished: {
        start: 0,
        limit: 50
    },
    sortingTabRecruitingsFinish: {
        'created': ESortingOptions.Descending
    },
    filterTabRecruitingsFinish: {
        start: 0,
        limit: 50
    },
};
