import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectsAreEqual } from '../../../../utils/application.utils';
import { IDateRangeFilterApiProperties } from '../../../../definitions/filter.definitions';
import CustomList from '../CustomSidePanelList/CustomList';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setWebVacancyPageFilterAction,
    setWebVacancyPageFilterMenuAction,
} from '../../../../redux/webVacancyPage/webVacancyPage.actions';
import {
    preloadedStateWebVacancyDateRangeFilter,
    preloadedStateWebVacancyPage,
} from '../../../../definitions/webVacancyPage.definitions';
import RadiusFilter from '../Filter/RadiusFilter';
import CustomListSection from '../CustomSidePanelList/CustomListSection';
import BoolFilter from '../Filter/Abstract/BoolFilter';
import { EIcons } from '../../../Icons/IconFactory';
import LanguageFilter from '../Filter/LanguageFilter';
import { translate } from '../../../../translation/translate.utils';
import EmployeeFilter from '../Filter/EmployeeFilter';
import CompaniesFilter from '../Filter/CompaniesFilter';

const WebVacancyFilterPanel: React.FC = () => {
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.webVacancyPage.filter);
    const filterMenuInStore = useSelector((store: IStore) => store.webVacancyPage.filterMenu);
    const setFilterAction = setWebVacancyPageFilterAction;
    const setFilterMenuInStoreAction = setWebVacancyPageFilterMenuAction;
    const initialFilterMenu = preloadedStateWebVacancyPage.filterMenu;
    const initialDateRangeFilter = preloadedStateWebVacancyDateRangeFilter;

    const [filterMenu, setFilterMenu] = useState(filterMenuInStore);
    const [executeSubmit, setExecuteSubmit] = useState(false);

    const submit = () => {
        dispatch(setFilterMenuInStoreAction(filterMenu));
        dispatch(setFilterAction({
            fastSearch: filter.fastSearch,
            start: filter.start,
            limit: filter.limit,
            responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
            branchIds: filterMenu.branches?.map(({ id }) => id),
            skillIds: filterMenu.skills?.map(({ id }) => id),
            companyIds: filterMenu.companies?.map(({ id }) => id),
            onlyWithContracts: filterMenu.onlyWithContracts,
            location: filterMenu.location,
            radius: filterMenu.radius,
            onlyPartTime: filterMenu.onlyPartTime,
            remote: filterMenu.remote,
            showHidden: filterMenu.showHidden,
            onlyEqualToVacancyTitles: filterMenu.onlyEqualToVacancyTitles
        }));
    };

    useEffect(() => {
        if (executeSubmit) {
            submit();
            setExecuteSubmit(false);
        }
    }, [executeSubmit])

    const reset = () => {
        setFilterMenu(initialFilterMenu);
        setExecuteSubmit(true);
    };

    const resetDateRange = () => {
        dispatch(setFilterAction({
            ...filter,
            ...initialDateRangeFilter
        }));
    };

    const dateRangeFilter: IDateRangeFilterApiProperties = {
        mode: filter.dateRangeFilter.mode,
        dateFrom: filter.dateRangeFilter.dateFrom,
        dateTo: filter.dateRangeFilter.dateTo,
        lastDateRangeCount: filter.dateRangeFilter.lastDateRangeCount,
        property: filter.dateRangeFilter.property
    };

    const resetEnabled = !objectsAreEqual(filterMenu, initialFilterMenu);
    const resetDateRangeEnabled = !objectsAreEqual(dateRangeFilter, initialDateRangeFilter);

    return (
        <>
            <CustomList
                reset={reset}
                resetEnabled={resetEnabled}
                dateRangeConfig={{
                    setFilter: (dateRangeFilter: IDateRangeFilterApiProperties) => {
                        dispatch(setFilterAction({
                            ...filter,
                            dateRangeFilter
                        }));
                    },
                    filter: dateRangeFilter,
                    filterByFields: [{
                        label: 'Online seit',
                        property: 'onlineSince'
                    }],
                    resetEnabled: resetDateRangeEnabled,
                    reset: resetDateRange
                }}
            >
                <CustomListSection header={"Basis-Filter"}>
                    <RadiusFilter
                        location={filterMenu.location}
                        distance={filterMenu.radius}
                        onChange={(location, radius) => {
                            setFilterMenu({
                                ...filterMenu,
                                location,
                                radius
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <LanguageFilter
                        value={filterMenu.languages}
                        onChange={(languages) => {
                            setFilterMenu({
                                ...filterMenu,
                                languages
                            });
                            setExecuteSubmit(true);
                        }}
                        onlyWithWebVacancies
                    />
                    <CompaniesFilter
                        value={filterMenu.companies}
                        onChange={(companies) => {
                            setFilterMenu({
                                ...filterMenu,
                                companies
                            });
                            setExecuteSubmit(true);
                        }}
                        onlyWithWebVacancies
                    />
                    <EmployeeFilter
                        value={filterMenu.responsibleEmployee}
                        onChange={(responsibleEmployee) => {
                            setFilterMenu({
                                ...filterMenu,
                                responsibleEmployee
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                </CustomListSection>
                <CustomListSection header={"Ja/Nein-Filter"}>
                    <BoolFilter
                        title={"Nur Remote-Stellen"}
                        icon={EIcons.Home}
                        active={filterMenu.remote === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                remote: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={"Nur nicht Remote-Stellen"}
                        icon={EIcons.Home}
                        active={filterMenu.remote === false}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                remote: active ? false : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={translate('pages.webVacancy.showOnlyPartTime')}
                        icon={EIcons.AvTimer}
                        active={filterMenu.onlyPartTime === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                onlyPartTime: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={translate('pages.webVacancy.showOnlyWithContracts')}
                        icon={EIcons.EmojiEvents}
                        active={filterMenu.onlyWithContracts === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                onlyWithContracts: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                    />
                    <BoolFilter
                        title={'Nur relevante Stellen'}
                        icon={EIcons.Bookmark}
                        active={filterMenu.onlyEqualToVacancyTitles === true}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                onlyEqualToVacancyTitles: active ? true : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                        tooltip={"Relevant heisst das nur Stellen mit Job-Titeln angezeigt werden welche ähnlich zu den Titeln unserer betreuten Vakanzen sind"}
                    />
                    <BoolFilter
                        title={'Nicht relevante Stellen'}
                        icon={EIcons.Bookmark}
                        active={filterMenu.onlyEqualToVacancyTitles === false}
                        setActive={(active) => {
                            setFilterMenu({
                                ...filterMenu,
                                onlyEqualToVacancyTitles: active ? false : undefined,
                            });
                            setExecuteSubmit(true);
                        }}
                        tooltip={"Relevant heisst das nur Stellen mit Job-Titeln angezeigt werden welche ähnlich zu den Titeln unserer betreuten Vakanzen sind"}
                    />
                </CustomListSection>
            </CustomList>
        </>
    )
};

export default React.memo(WebVacancyFilterPanel);
