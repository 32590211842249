import React, { memo } from 'react';
import { ISuggestionPartial } from '../../definitions/entities.definition';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { useDispatch } from 'react-redux';
import styles from './VacancyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { translate } from '../../translation/translate.utils';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

interface IProps {
    recruitingSuggestion: ISuggestionPartial;
    onDelete?: () => void;
    displayFullName?: boolean;
    hideIcon?: boolean;
}

const SuggestionCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const hideIcon = props.hideIcon || false;
    const label = props.displayFullName ? props.recruitingSuggestion.fullName : translate('misc.openSuggestion');

    return (
        <Tooltip title={label} placement="top-start">
            <Chip
                icon={hideIcon ? undefined : IconFactory.getIconComponent(EIcons.Star, {color: darker})}
                color="primary"
                className={styles.chip}
                deleteIcon={props.onDelete ? IconFactory.getIconComponent(EIcons.Close, {color: darker}) : undefined}
                onDelete={props.onDelete}
                label={label}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.suggestion, props.recruitingSuggestion.id));
                }}
            />
        </Tooltip>
    );
};

export default memo(SuggestionCellRenderer);
