import React from 'react';
import { Badge } from '@material-ui/core';
import { EIcons, IconFactory } from '../../Icons/IconFactory';

interface IProps {
    count: number;
}

const ContractsCountElement: React.FC<IProps> = (props) => {
    if (props.count === 1) {
        return IconFactory.getIconComponent(EIcons.EmojiEvents, {color: 'GOLD'});
    }

    if (props.count > 1) {
        return (
            <Badge color="primary" badgeContent={props.count}>
                { IconFactory.getIconComponent(EIcons.EmojiEvents, {color: 'GOLD'}) }
            </Badge>
        );
    }

    return (<div>-</div>);
};

export default ContractsCountElement;
