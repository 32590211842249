import React from 'react';
import { Menu } from '@material-ui/core';

interface IProps {
    anchorEl: null | HTMLElement;
    removeAnchor: () => void;
    className?: string;
    children: any;
}

const CustomMenu: React.FC<IProps> = (props) => {
    const { anchorEl } = props;
    const handleClose = () => {
        props.removeAnchor();
    };

    if (!anchorEl) {
        return null;
    }

    return (
        <Menu
            style={{marginLeft: 25, marginTop: 30}}
            className={props.className}
            keepMounted
            anchorEl={anchorEl}
            open={true}
            onClose={handleClose}>
            {props.children}
        </Menu>
    );
};

export default CustomMenu;
