import React from 'react';
import {
    EEntities,
    ICandidate,
    IRecruitingInterviewInformation,
    IRecruitingListModel,
    IVacancy,
} from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import { getRecruitingsForEmployee } from '../../../../selectors/recruiting.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import DateTimeCellRenderer from '../../../../components/CustomCellRenderer/DateTimeCellRenderer';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import { getRecruitingsForEmployeeAction } from '../../../../redux/entities/entities.actions';
import { ECellAlign, ESortingOptions } from '../../../../definitions/components.definitions';
import { setDashboardPageSortingOptionAction } from '../../../../redux/dashboardPage/dashboardPage.actions';
import { EDashboardPageTable } from '../../../../definitions/dashboardPage.definitions';
import { buildRequestObject } from '../../../../selectors/entity.selectors';
import { getDefaultCompanyColumn, getDefaultLastActivityColumn } from '../../../../utils/components.utils';
import { EEntityView } from '../../../../definitions/ui.definitions';
import { setUiEditView } from '../../../../redux/ui/ui.actions';
import RecruitingInterviewsCellRenderer
    from '../../../../components/CustomCellRenderer/RecruitingInterviewsCellRenderer';

const EmployeeTodayRecruitingsTable: React.FC = () => {
    const dispatch = useDispatch();
    const sortedEntries = useSelector(getRecruitingsForEmployee);
    const loading = useSelector((store: IStore) => store.ui.employeeRecruitingsLoading);
    const token = useSelector((store: IStore) => store.actor.token);
    const employee = useSelector(getLoggedInEmployeeFromStore);
    const sorting = useSelector((store: IStore) => store.dashboardPage.sortingEmployeeRecruitings);

    return (
        <CustomTable
            style={{margin: 5}}
            config={{
                entity: EEntities.EmployeeRecruiting,
                sortedEntries: sortedEntries,
                onRowDoubleClick: (recruitingId: number, entity) => {
                    const recruiting = entity as IRecruitingListModel;
                    dispatch(setUiEditView(EEntityView.recruiting, recruitingId));
                },
                columnConfig: {
                                        status: {
                        header: translate('pages.recruiting.status'),
                        property: 'status',
                        width: 230,
                        cellRenderer: (status: string) => {
                            return translate('pages.recruiting.statusValues.' + status);
                        },
                    },
                    interviews: {
                        header: 'Vorstellungsgespräche',
                        property: "interviews",
                        orderByProperty: "nextAppointment",
                        width: 250,
                        align: ECellAlign.right,
                        cellRenderer: (interviews: IRecruitingInterviewInformation[], entity) => {
                            const recruiting = entity as IRecruitingListModel;
                            return <RecruitingInterviewsCellRenderer recruiting={recruiting}/>
                        },
                    },
                    candidate: {
                        header: translate('pages.activity.properties.candidate'),
                        property: 'candidate',
                        flex: 1,
                        cellRenderer: (candidate: null | ICandidate) => {
                            if (candidate) {
                                return <CandidateCellRenderer candidate={candidate}/>;
                            }

                            return translate('misc.noInformation');
                        },
                    },
                    company: getDefaultCompanyColumn(),
                    vacancy: {
                        header: translate('pages.activity.properties.vacancy'),
                        property: 'vacancy',
                        flex: 1,
                        cellRenderer: (vacancy: null | IVacancy) => {
                            if (vacancy) {
                                return <VacancyCellRenderer vacancy={vacancy}/>;
                            }

                            return null;
                        },
                    },
                    lastActivity: getDefaultLastActivityColumn(),
                },
                loading: loading,
                count: sortedEntries.length,
                total: sortedEntries.length,
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) => {
                        dispatch(setDashboardPageSortingOptionAction(
                            EDashboardPageTable.yourRecruitings,
                            propertyToSort,
                            sortOption
                        ));
                        return;
                    }
                },
                onReload: () => {
                    dispatch(getRecruitingsForEmployeeAction(token, employee.id, buildRequestObject(sorting)));
                },
            }}
        />
    );
};

export default EmployeeTodayRecruitingsTable;
