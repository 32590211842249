import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { IAppBarActionButtonConfig } from '../../../definitions/components.definitions';
import CustomTypography from '../../CustomTypography/CustomTypography';
import { EIcons, IconFactory } from '../../Icons/IconFactory';

interface IProps {
    buttons: IAppBarActionButtonConfig[];
}

const CustomFastSearchPanelFurtherActionButton: React.FC<IProps> = (props) => {
    const [menuPosition, setMenuPosition] = useState<any>(null);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (menuPosition) {
            return;
        }
        setMenuPosition({
            top: event.pageY,
            left: event.pageX,
        });
    };

    const handleItemClick = (event: React.MouseEvent) => {
        setMenuPosition(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                {IconFactory.getIconComponent(EIcons.MoreIcon)}
            </IconButton>
            <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
            >
                {props.buttons.map((actionButton: IAppBarActionButtonConfig, index) => {
                    return (
                        <MenuItem
                            key={"actionButton" + index}
                            onClick={(event) => {
                                event.stopPropagation();
                                actionButton.action();
                                handleItemClick(event);
                            }}
                        >
                            {IconFactory.getIconComponent(actionButton.icon)}&nbsp;
                            <CustomTypography value={actionButton.tooltip} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default CustomFastSearchPanelFurtherActionButton;
