import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMail } from '../../../../definitions/entities.definition';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import { getMailListAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';
import PaperRowWithScrollContainer from '../../../../components/CustomPaper/PaperRowScrollContainer';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { getFormattedDateWithDayString } from '../../../../utils/date.utils';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';
import { getMailsFromStore, getMailsTotalCountFromStore, groupMailsByDate } from '../../../../selectors/mail.selectors';
import MailElement from './MailElement';

interface IProps {
    openMail: (mailId: number) => void;
    reloadFolders: () => void;
}

const MailList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const records = useSelector((store: IStore) => getMailsFromStore(store));
    const recordsGroupedByDate = groupMailsByDate(records);
    const token = useSelector(getToken);
    const loading = useSelector((state: IStore) => state.ui.mailsLoading);
    const filter = useSelector((state: IStore) => state.mailing.filter);
    const total = useSelector((store: IStore) => getMailsTotalCountFromStore(store));

    return (
        <>
            <PaperRowWithScrollContainer>
                {Object.keys(recordsGroupedByDate).map((dateTime) => {
                    const records = recordsGroupedByDate[dateTime];

                    return <>
                        <div style={{width: "100%", marginTop: 10, paddingBottom: 10, textAlign: "right", marginRight: 5}}>
                            <CustomTypography
                                variant={'body2'}
                                value={getFormattedDateWithDayString(dateTime, false)}
                            />
                        </div>
                        {
                            records.map((mail: IMail) => <MailElement
                                key={mail.id}
                                mail={mail}
                                openMail={props.openMail}
                                reloadFolders={props.reloadFolders}
                            />)
                        }
                    </>;

                })}
            </PaperRowWithScrollContainer>
            <CustomTableFooter
                count={records.length}
                totalCount={total}
                loading={loading}
                onReload={() => {
                    dispatch(getMailListAction(token, filter));
                }}
            />
        </>
    );
}

export default MailList;
