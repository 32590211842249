import React, { useCallback } from 'react';
import CustomFilterItem from '../CustomSidePanelList/CustomFilterItem';
import { EIcons } from '../../../Icons/IconFactory';
import { translate } from '../../../../translation/translate.utils';
import PaperRow from '../../../CustomPaper/PaperRow';
import { EHomeOfficeDesire } from '../../../../definitions/entities.definition';
import HomeOfficeDesireMultipleDropDown
    from '../../../CustomInput/CustomDropDownInput/HomeOfficeDesireMultipleDropDown';

interface IProps {
    value: EHomeOfficeDesire[];
    onChange: (value: EHomeOfficeDesire[]) => void;
}

const HomeOfficeDesireFilter: React.FC<IProps> = (props) => {
    const value = props.value;

    const onChange = (value?: string) => {
        props.onChange(value);
    }

    const resetFilter = () => {
        props.onChange();
    }

    const renderValue = useCallback(() => {
        if (value.length === 0) {
            return null;
        }

        return <>
            {value
                .map((status) => <>{translate('misc.homeOfficeDesireValue.' + status)}<br/></>)
            }
        </>;
    }, [value]);

    const deletable = props.value.length > 0;

    return <>
        <CustomFilterItem
            title={"Home-Office-Wunsch"}
            icon={EIcons.Home}
            renderedValue={renderValue()}
            active={deletable}
            removeFilterAction={resetFilter}
            width={300}
            autoClose
        >
            <PaperRow>
                <HomeOfficeDesireMultipleDropDown
                    onChange={(value) => onChange(value)}
                    value={value}
                />
            </PaperRow>
        </CustomFilterItem>
    </>;
};

export default React.memo(HomeOfficeDesireFilter);
