import React from 'react';
import { getFormattedDateWithDayString } from '../../utils/date.utils';

interface IProps {
    date: string;
}

const DateTimeCellRenderer: React.FC<IProps> = (props) => {
    if (props.date === null) {
        return null;
    }
    return <div>{getFormattedDateWithDayString(props.date, true)}</div>;
};

export default DateTimeCellRenderer;
