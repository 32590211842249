import React from 'react';
import { ICompany, ICompanyPartial } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import CompanyMultipleAutocomplete from '../components/CompanyMultipleAutocomplete';

interface IProps {
    value?: ICompany[]|ICompanyPartial[];
    onChange: (value: ICompanyPartial[]) => void;
    onlyWithCrawler?: boolean;
}

const LegacyCompanyMultipleAutocompleteDropDown: React.FC<IProps> = (props) => {
    return (
        <CompanyMultipleAutocomplete
            value={props.value === undefined  ? [] : props.value}
            onChange={props.onChange}
            label={translate('misc.companies')}
        />
    );
};

export default LegacyCompanyMultipleAutocompleteDropDown;
