import { IErrorAction, IErrorApiAction, IInfoAction, TReducerAction } from '../../definitions/redux/actions.definition';
import { IErrorState, preloadedStateError } from '../../definitions/error.definitions';
import {
    CONSUME_ERROR,
    CONSUME_INFO,
    CONSUME_SUCCESS,
    REGISTER_ERROR,
    REGISTER_INFO,
    REGISTER_SUCCESS,
    RESET_FORM_VALIDATION,
} from './error.actions';
import {
    ENTITIES_POST_CANDIDATE_PENDING,
    ENTITIES_POST_VACANCY_PENDING,
    ENTITIES_PUT_CANDIDATE,
    ENTITIES_PUT_VACANCY,
} from '../entities/entities.actions';
import { translate } from '../../translation/translate.utils';
import { UI_SET_CLOSE_VIEW } from '../ui/ui.actions';

const actionsToResetValidation = [
    ENTITIES_POST_CANDIDATE_PENDING,
    ENTITIES_PUT_CANDIDATE,
    ENTITIES_POST_VACANCY_PENDING,
    ENTITIES_PUT_VACANCY,
    RESET_FORM_VALIDATION
];

const errorReducer = (state = preloadedStateError, action: TReducerAction): IErrorState => {
    if (actionsToResetValidation.includes(action.type)) {
        return { ...state, validation: {} };
    }

    if (action.type.includes("_FULFILLED") && (action.type.includes("PUT") || action.type.includes("DELETE") || action.type.includes("POST"))) {
        return {
            ...state,
            validation: {},
            success: true
        };
    }

    if (action.type === UI_SET_CLOSE_VIEW) {
        return {
            ...state,
            validation: {}
        };
    }

    if (action.type.includes("_REJECTED")) {
        const actionCast = action as IErrorApiAction;
        const message = actionCast.payload.errorMessage || actionCast.payload;

        if (message) {
            const cast = message as string;
            if (cast && cast.includes("validationErrors: ")) {
                const replacedCast = cast.replace("validationErrors: ", "");

                return {
                    ...state,
                    validation: JSON.parse(replacedCast),
                    hasError: true,
                    errorMessage: translate('misc.formHasErrors')
                };
            }
        }

        return {
            ...state,
            hasError: true,
            errorMessage: message as any,
        };
    }

    if (action.type === REGISTER_INFO) {
        const actionCast = action as IInfoAction;
        return {
            ...state,
            hasInfo: true,
            infoMessage: actionCast.payload,
        };
    }

    if (action.type === REGISTER_ERROR) {
        const actionCast = action as IErrorAction;
        return {
            ...state,
            hasError: true,
            errorMessage: actionCast.payload,
        };
    }

    if (action.type === REGISTER_SUCCESS) {
        return {
            ...state,
            success: true,
        };
    }

    if (action.type === CONSUME_ERROR || action.type === CONSUME_SUCCESS || action.type === CONSUME_INFO) {
        return {
            ...state,
            hasError: false,
            hasInfo: false,
            errorMessage: '',
            infoMessage: '',
            success: false
        };
    }

    return state;
};

export default errorReducer;
