import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import { EIcons } from '../Icons/IconFactory';
import CustomClickableIcon from './CustomClickableIcon';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

interface IProps extends Omit<OutlinedTextFieldProps, "variant"> {
    onAddNewEntryCallback?: () => void;
    type?: string;
    onChange?: (value: any) => void;
    clearButton?: boolean;
    multiline?: boolean;
    rows?: number;
    autoFocus?: boolean;
    flex?: number;
    width?: number;
    InputProps?: Partial<OutlinedInputProps>;
}

const CustomTextField: React.FC<IProps> = (props) => {
    const addAdornmentElement = !props.onAddNewEntryCallback ? undefined : (
        <InputAdornment position="start">
            <CustomClickableIcon onClick={props.onAddNewEntryCallback} icon={EIcons.Add}/>
        </InputAdornment>
    );

    const deleteAdornmentElement = (props.clearButton && props.value) ? <>
        <InputAdornment style={{ color: "var(--brainz-color-text-secondary)" }} position="end">
            <CustomClickableIcon
                onClick={() => {
                    if (props.onChange) {
                        props.onChange("");
                    }
                }}
                icon={EIcons.Close}
            />
        </InputAdornment>
    </> : undefined;

    let adornmentToUse = undefined;
    if (props.InputProps?.startAdornment && addAdornmentElement) {
        adornmentToUse = (
            <>
                <div style={{ float: "left" }}>{addAdornmentElement}</div>
                <div style={{ float: "left" }}>{props.InputProps.startAdornment}</div>
            </>
        );
    } else if (props.InputProps?.startAdornment) {
        adornmentToUse = props.InputProps.startAdornment;
    } else if (addAdornmentElement) {
        adornmentToUse = addAdornmentElement;
    }

    const textFieldProperties = { ...props };
    delete textFieldProperties.onAddNewEntryCallback;
    const endAdornment = props.clearButton ? deleteAdornmentElement : props.InputProps?.endAdornment;

    let inlineStyles: React.CSSProperties = {
        flex: 1
    };

    if (props.flex) {
        inlineStyles = {
            flex: props.flex
        };
    }

    if (props.width) {
        inlineStyles = {
            width: props.width
        };
    }

    return (
        <TextField
            {...textFieldProperties}
            multiline={props.multiline}
            rows={props.rows}
            type={props.type ? props.type : "text"}
            autoComplete={"off"}
            role={"presentation"}
            onChange={(event) => {
                if (props.onChange) {
                    props.onChange(event.target.value);
                }
            }}
            helperText={props.error ? props.helperText : ''}
            InputProps={{
                autoComplete: 'off',
                'aria-autocomplete': 'none',
                role: 'presentation',
                ...props.InputProps,
                startAdornment: adornmentToUse,
                endAdornment,
            }}
            style={inlineStyles}
            variant={"outlined"}
            autoFocus={props.autoFocus}
            value={props.value || ""}
            className={'inputFullWidth'}
        />
    );
};

export default CustomTextField;
