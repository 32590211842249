import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import {
    ETodo,
    ICandidatePartial,
    ICompanyContactPartial,
    ICompanyPartial,
    IEmployee,
    IEmployeePartial,
    ITodo,
    ITodoForApi,
    IVacancyPartial,
} from '../../../../definitions/entities.definition';
import { updateTodoAction } from '../../../../redux/entities/entities.actions';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import CustomDateTimePicker from '../../../../components/CustomInput/CustomDateTimePicker';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import { getDateStringForApi, momentToDate } from '../../../../utils/date.utils';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import LegacyCompanyVacancyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyVacancyAutocompleteDropDown';
import CompanyContactAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyContactAutocompleteDropDown';
import { setUiCompanyContactAddDataAction } from '../../../../redux/ui/ui.actions';
import LegacyCandidateAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCandidateAutocompleteDropDown';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import { setTodoToDeleteAction } from '../../../../redux/todo/todo.actions';
import Moment from 'moment';

interface IProps {
    onClose: () => void;
    todo: ITodo;
}

const TodoEditForm: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const validationErrors = useSelector(getValidationErrorsFromStore);

    const [employee, setEmployee] = useState<IEmployee|IEmployeePartial>(props.todo.employee);
    const [company, setCompany] = useState<ICompanyPartial|undefined>(props.todo.company);
    const [vacancy, setVacancy] = useState<IVacancyPartial|undefined>(props.todo.vacancy);
    const [companyContact, setCompanyContact] = useState<ICompanyContactPartial|undefined>(props.todo.companyContact);
    const [candidate, setCandidate] = useState<ICandidatePartial|undefined>(props.todo.candidate);
    const [dueDate, setDueDate] = useState<Date|undefined>(props.todo.dueDate ? new Date(props.todo.dueDate) : undefined);
    const [dueDateDifference, setDueDateDifference] = useState<undefined|Moment.Duration>();
    const [dueDateTo, setDueDateTo] = useState<Date|undefined>(props.todo.dueDateTo ? new Date(props.todo.dueDateTo) : undefined);
    const [description, setDescription] = useState(props.todo.description);
    const [completed, setCompleted] = useState(props.todo.completed);
    const [flexibleTime, setDueDateFlexibleTime] = useState(props.todo.flexibleTime);
    const [shareWithEmployees, setShareWithEmployees] = useState<IEmployeePartial[]>(props.todo.shareWithEmployees);

    useEffect(() => {
        const dueDateMoment = Moment(dueDate);
        const dueDateToMoment = Moment(dueDateTo);

        if (dueDateMoment.isValid() && dueDateToMoment.isValid()) {
            const duration = Moment.duration(dueDateToMoment.diff(dueDateMoment))
            setDueDateDifference(duration);
        }
    }, [dueDate, dueDateTo]);

    const save = (closeEditView: boolean) => {
        const todo: ITodoForApi = {
            id: props.todo.id,
            employeeId: employee?.id || 0,
            companyId: company?.id || undefined,
            candidateId: candidate?.id || undefined,
            vacancyId: vacancy?.id || undefined,
            companyContactId: companyContact?.id || undefined,
            completed,
            dueDate: dueDate ? getDateStringForApi(dueDate) : undefined,
            dueDateTo: dueDateTo ? getDateStringForApi(dueDateTo) : undefined,
            flexibleTime,
            description,
            shareWithEmployeeIds: shareWithEmployees.map((employee) => employee.id)
        };

        dispatch(updateTodoAction(token, todo, closeEditView));
        return;
    }

    return (
        <div>
            <PaperRow>
                <LegacyEmployeeAutocompleteDropDown
                    value={employee}
                    label={translate('misc.responsibleEmployee')}
                    onChange={(event, value) => setEmployee(value)}
                    error={Boolean(validationErrors.responsibleId)}
                    helperText={validationErrors.responsibleId}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.todo.flexibleTime"}
                    checked={flexibleTime}
                    onChange={() => setDueDateFlexibleTime(!flexibleTime)}
                />
                {flexibleTime &&
                    <CustomDatePicker
                        label={translate('pages.todo.dueDateAt')}
                        value={dueDate}
                        onChange={(value) => setDueDate(value)}
                        error={Boolean(validationErrors.dueDate)}
                        helperText={validationErrors.dueDate}
                    />
                }
                {!flexibleTime &&
                <>
                    <CustomDateTimePicker
                        label={translate('pages.todo.dueDate')}
                        value={dueDate}
                        onChange={(value) => {
                            setDueDate(value);

                            if (!Moment(value).isValid()) {
                                return;
                            }

                            if (!dueDateTo) {
                                setDueDateTo(momentToDate(Moment(value).add(30, 'm')));
                            } else {
                                setDueDateTo(momentToDate(Moment(value).add(dueDateDifference)));
                            }
                        }}
                        error={Boolean(validationErrors.dueDate)}
                        helperText={validationErrors.dueDate}
                    />
                    <CustomDateTimePicker
                        label={translate('pages.todo.dueDateTo')}
                        value={dueDateTo}
                        onChange={(value) => setDueDateTo(value)}
                        error={Boolean(validationErrors.dueDateTo)}
                        helperText={validationErrors.dueDateTo}
                    />
                </>
                }
            </PaperRow>
            <PaperRow>
                <LegacyEmployeeAutocompleteDropDown
                    label={'Teilen mit'}
                    multiple={true}
                    value={shareWithEmployees}
                    onChange={(event, value: IEmployeePartial[]) => setShareWithEmployees(value)}
                />
            </PaperRow>
            { props.todo.todoEnum === ETodo.manual &&
            <>
                <PaperRow>
                    <LegacyCompanyAutocompleteDropDown
                        value={company}
                        onChange={(event, value) => setCompany(value)}
                    />
                    <LegacyCompanyVacancyAutocompleteDropDown
                        label={translate('misc.vacancy')}
                        value={vacancy}
                        onChange={(event, value) => setVacancy(value)}
                        companyId={company ? company.id : 0}
                    />
                    <CompanyContactAutocompleteDropDown
                        onAdd={company ? () => {
                            dispatch(setUiCompanyContactAddDataAction({
                                companyId: company.id
                            }));
                        } : undefined}
                        value={companyContact}
                        onChange={(event, value) => setCompanyContact(value)}
                        companyId={company ? company.id : 0}
                    />
                    <LegacyCandidateAutocompleteDropDown
                        value={candidate?.id}
                        onChange={(event, value) => setCandidate(value)}
                    />
                </PaperRow>
            </>
            }
            <PaperRow>
                <CustomTextField
                    multiline
                    rows={10}
                    label={translate('pages.todo.description')}
                    value={description}
                    onChange={(value) => setDescription(value)}
                    error={Boolean(validationErrors.description)}
                    helperText={validationErrors.description}
                />
            </PaperRow>
            {props.todo.todoEnum === ETodo.manual &&
                <PaperRow>
                    <CustomCheckboxInput
                        labelTranslation={"pages.todo.completed"}
                        checked={completed}
                        onChange={() => setCompleted(!completed)}
                    />
                </PaperRow>
            }
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: () => dispatch(setTodoToDeleteAction(props.todo.id)),
                    label: translate('misc.buttons.delete')
                }, {
                    void: () => save(true),
                    label: translate('misc.buttons.saveAndClose')
                }]}
            />
        </div>
    );
}

export default TodoEditForm;
