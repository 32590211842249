import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { debounce } from '@material-ui/core';
import { setCandidatePageVacancySearchFilterAction } from '../../../../redux/candidatePage/candidatePage.actions';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';

const SearchVacancyFilterPanel: React.FC = () => {
    const dispatch = useDispatch();
    const [fastSearch, setFastSearch] = useState('');

    const changeHandler = (value: string) => {
        dispatch(setCandidatePageVacancySearchFilterAction({fastSearch: value}));
    };
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 300), []
    );


    const configuration: IAppBarConfig = {
        entity: EEntities.CandidateVacancySearch,
        value: fastSearch ?? '',
        emptyValue: translate('pages.candidate.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => {
            setFastSearch(value);
            debouncedChangeHandler(value);
        },
        filterPanelType: EFilterPanelType.sidePanel
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default SearchVacancyFilterPanel;
