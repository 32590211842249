import React, { useEffect, useState } from 'react';
import RecruitingList from './components/RecruitingList';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { ClientApi } from '../../requests/ClientApi';
import { getToken } from '../../selectors/app.selectors';
import { getCustomerRecruitingListRouteConfig } from '../../requests/routes.customer';
import { ICustomerRecruitingListModel } from '../../definitions/entities.customer.definitions';
import RecruitingPageFilterPanel from './components/RecruitingPageFilterPanel';
import { setCustomerAppRecruitingFilterAction } from '../../redux/customerApp/customerApp.actions';

const RecruitingPage: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const filter = useSelector((store: IStore) => store.customerApp.recruiting.filter);

    const [records, setRecords] = useState<ICustomerRecruitingListModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalRunning, setTotalRunning] = useState(0);
    const [totalDeclined, setTotalDeclined] = useState(0);
    const [totalContract, setTotalContract] = useState(0);
    const [totalActionRequired, setTotalActionRequired] = useState(0);

    interface IResponse {
        records: ICustomerRecruitingListModel[],
        total: number,
        totalRunning: number,
        totalDeclined: number,
        totalContract: number
    }

    const load = () => {
        setLoading(true);
        ClientApi.request(getCustomerRecruitingListRouteConfig, {
            token,
            statusFilter: filter.status ? filter.status : undefined,
            filterFastSearch: filter.fastSearch,
        }).then((result: IResponse) => {
            setLoading(false);
            setRecords(result.records);
            setTotalRunning(result.totalRunning);
            setTotalDeclined(result.totalDeclined);
            setTotalContract(result.totalContract);
            if (filter.status === 'running') {
                setTotalActionRequired(result.records.filter((record) => record.customerActionRequired).length);
            }
            if (filter.status === 'actionRequired') {
                setTotalActionRequired(result.records.length);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [filter]);

    return <>
        <div className={"flexContainerColumn flex1"} style={{overflow: 'hidden'}}>
            <div className={"flexContainerRow"} style={{marginLeft: 6}}>
                <RecruitingPageFilterPanel
                    disabled={filter.fastSearch.trim().length > 0}
                    countDeclined={totalDeclined}
                    countContract={totalContract}
                    countRunning={totalRunning}
                    countActionRequired={totalActionRequired}
                    filter={filter}
                    setFilterAction={(filter) => dispatch(setCustomerAppRecruitingFilterAction(filter))}
                />
            </div>
            <RecruitingList
                loading={loading}
                records={records}
                onReload={load}
            />
        </div>
    </>;
}

export default RecruitingPage;
